/* @format - for Prettier */
import axiosInstance from "../../../api/axios";
import React, {useState} from "react";
import { connect } from "react-redux";
import { Grid, Row, Col , Modal , Button,  Table, Glyphicon} from "react-bootstrap";
import { history } from "../../../App";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import moment from "moment";
import { GlyphButton } from "../../Admin";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  doGreenBagCallHistory,
  doAddNotesGreenBag,
  doDeleteNoteGreenBag,
  deleteGreenBagCallHistory
} from "../../../firebase/db";
import { Customers,GreenBagCustomers } from "../../../actions2";
const StyledRow = styled(Row)`
  margin-bottom: 0px !important;
`;
const StyledCol = styled(Col)`
  padding: 0px;
  margin-bottom: 20px;
`;
const AccordionContainer = styled.div`
  margin: 20px 0px !important;
`;
const Header = styled.div`
  display: flex;
`;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  height: 40px;
  margin: 10px 0px 0px 10px;
  float: right;
`;

const EditButton = styled(Button)`
  padding: 5px 20px;
  height: 35px;
  margin: 0px 0px 0px 10px;
  float: right;
`;
const DeleteButton = styled(Button)`
  float: right;
`;
const CloseButton = styled(Button)`
  float: left;
`;
const StyledNotes = styled.div`
  border: 1px solid black;
  height: 60px;
  padding: 5px 10px 5px 10px;
  font-style: italic;
`;
/*eslint-disable */
function CustomerData({
  closeAccount,
  setCustomer,
  customer: {
    ["Account Number"]: accountNumber,
    ["recurringCustomer"]: recurringCustomer,
    ["isExempt"]: isExempt,
    ["billFrequency"]: billFrequency,
    ["wantsEInvoice"]: wantsEInvoice,
    ["recycling_customer"]: recycling_customer,
    ["isInactive"]: isInactive,
    pickup_day,
    phone_number,
    name,
    rate,
    status,
    contact_name,
    email,
    companyName,
    toterSerialNumbers,
    phone_numbers,
    service_address,
    billing_address,
    credit_card,
    credit_card_name,
    notes,
    callHistory,
    uid,
  }}){
    // if(notes){
    //   notes = Object.entries(notes).sort((a,b) => a[1].date > b[1].date ? 1 : -1);
    // }
    // if(callHistory){
    //   callHistory = Object.entries(callHistory).sort((a,b) => a[1].date > b[1].date ? 1 : -1);
    // }
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [modalTitle, setModalTitle] = useState("Add");
  const [noteValue, setNoteValue] = useState("");
  const [noteUid, setNoteUid] = useState("");
  const [callShow, setCallShow] = useState(false);
  const [startCallDate, setStartCallDate] = useState(new Date());
  const [modalCallTitle, setModalCallTitle] = useState("Add");
  const [noteCallValue, setNoteCallValue] = useState("");
  const [noteCallUid, setNoteCallUid] = useState("");

  const [show_close_modal , setshow_close_modal] = useState(false)

  const handleCloseAccountAction = ()=>{
    var cust = {
      ["Account Number"]: uid,
      ["recurringCustomer"]: recurringCustomer == undefined ? "" : recurringCustomer,
      ["isExempt"]: isExempt == undefined ? "" : isExempt,
      ["billFrequency"]: billFrequency == undefined ? "" : billFrequency,
      ["wantsEInvoice"]: wantsEInvoice == undefined ? "" : wantsEInvoice,
      ["recycling_customer"]: recycling_customer == undefined ? "" : recycling_customer,
      ["isInactive"]: isInactive == undefined ? "" : isInactive,
      ["pickup_day"]: pickup_day == undefined ? "" : pickup_day,
      ["phone_number"]: phone_number == undefined ? "" : phone_number,
      ["name"]: name == undefined ? "" : name,
      ["rate"]: rate == undefined ? "" : rate,
      ["status"]: status == undefined ? "" : status,
      ["contact_name"]: contact_name == undefined ? "" : contact_name,
      ["email"]: email == undefined ? "" : email,
      ["companyName"]: companyName == undefined ? "" : companyName,
      ["toterSerialNumbers"]: toterSerialNumbers == undefined ? "" : toterSerialNumbers,
      ["phone_numbers"]: phone_numbers == undefined ? "" : phone_numbers,
      ["service_address"]: service_address == undefined ? "" : service_address,
      ["billing_address"]: billing_address == undefined ? "" : billing_address,
      ["credit_card"]: credit_card == undefined ? "" : credit_card,
      ["credit_card_name"]: credit_card_name == undefined ? "" : credit_card_name,
      ["notes"]: notes == undefined ? "" : notes,
      ["callHistory"]: callHistory == undefined ? "" : callHistory,
      ["uid"]: uid == undefined ? "" : uid
    }
    console.log(cust)
    closeAccount(cust)
    handleCloseModal()
    history.push('/ClosedAccounts')
    
  }

  const handleCloseModal = () =>{
    setshow_close_modal(false)
  }

  const handleCloseAccount = ()=>{
    setshow_close_modal(true)
  }
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleClose = () => {
    setShow(!show);
    setStartDate("");
    setNoteValue("");
    setNoteUid(undefined);
  };
  const handleStartDateCAllChange = (date) => {
    setStartCallDate(date);
  };
  const handleCallClose = () => {
    setCallShow(!callShow);
    setStartCallDate("");
    setNoteCallValue("");
    setNoteCallUid(undefined);
  };

  const saveCallNotes = async () => {
    if (!startCallDate) {
      alert("Please choose the date")
      return
    }
    else {
      if (noteCallUid) {
        const res = await axiosInstance.patch(`/api/customers/update/call_history/${noteCallUid}`, {
          call_history: {
            date: startCallDate,
            note: noteCallValue
          }
        });
        if (res.status === 200) {
          setCustomer(uid);
        }
      } else {
        const res = await axiosInstance.post(`/api/customers/add/call_history`, {
          call_history: {
            customer_id: uid,
            date: startCallDate,
            note: noteCallValue
          }
        })
        if (res.status === 201) {
          setCustomer(uid);
        }
      }
      setCallShow(false);
      setStartCallDate("");
      setNoteCallValue("");
      setNoteCallUid(undefined);
    }
  };

  const deleteCallNote = async (id) => {
    let res = await axiosInstance.delete(`/api/customers/delete/call_history/${id}`);
    if (res.status === 200)
      setCustomer(uid);
    handleDeleteCallClose();
  };

  const doUpdateCallNote = (customer) => {
    let date = customer.date ? moment(customer.date).toDate() : "";
    setModalCallTitle("Update");
    setStartCallDate(date);
    setNoteCallValue(customer.note);
    setNoteCallUid(customer.uid);
    setCallShow(true);
  };

  const saveNotes = async () => {
    if(noteUid) {
      const res = await axiosInstance.patch(`/api/customers/update/note/${noteUid}`, {
        note: { date: startDate, note: noteValue }
      });
      if (res.status === 200) {
        setCustomer(uid);
      }
    } else {
      const res = await axiosInstance.post(`/api/customers/add/note`, {
        note: {
          customer_id: uid,
          date: startDate,
          note: noteValue,
        }
      })
      if (res.status === 201) {
        setCustomer(uid);
      } 
    }
    setShow(false);
    setStartDate("");
    setNoteValue("");
    setNoteUid(undefined);
  };

  const deleteNote = async (id) => {
    let res = await axiosInstance.delete(`/api/customers/delete/note/${id}`);
    if(res.status === 200)
      setCustomer(uid);
    handleDeleteClose();
  };

  const doUpdateNote = (note) => {
     let date = note.date ? moment(note.date).toDate() : "";
    setModalTitle("Update");
    setStartDate(date);
    setNoteValue(note.note);
    setNoteUid(note.uid);
    setShow(true);  
  };

  const [showCallDelete, setShowCallDelete] = useState({
    key: false,
    uid: "",
  });

  const handleDeleteCallClose = () => {
    setShowCallDelete({
      key: false,
      uid: "",
    });
  };

  const [showDelete, setShowDelete] = useState({
    key: false,
    uid: "",
  });

  const handleDeleteClose = () => {
    setShowDelete({
      key: false,
      uid: "",
    });
  };


  return(
    <Grid>
    <StyledRow>
      <StyledCol sm={4} md={4}>
        <h4>Customer Information</h4>
        <div>Customer Name: {name}</div>
      </StyledCol>
      <StyledCol sm={4} md={4}>
        <h4>Contact Information</h4>
        <div>Phone Number: {phone_number}</div>
      </StyledCol>
    </StyledRow>
    <StyledRow>
      <StyledCol sm={4} md={4}>
        <StyledRow>
          <h4>Service Address:</h4>
        </StyledRow>
        <StyledRow>
          <div>
            {service_address.street}
          </div>
          <div>
            {service_address.city + ", "} {service_address.state} {service_address.zipcode}
          </div>
        </StyledRow>
      </StyledCol>
      <StyledCol sm={4} md={4}>
        <StyledRow>
          <h4>Pickup Info:</h4>
        </StyledRow>
        <StyledRow>
          <div>{pickup_day}</div>
        </StyledRow>
      </StyledCol>
    </StyledRow>
    <StyledRow>
                <StyledCol sm={4} md={4}>
                  <Button onClick={handleCloseAccount}>
                    Close Account
                  </Button>
                </StyledCol>
            
              </StyledRow>
    <StyledRow>
    <Modal
              show={show_close_modal}
              onHide={handleCloseModal}
            >
              <Modal.Header>
                <h4>Closing this account will permanently delete this customer from the Customers list.</h4>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p className="font-weight-bold">Are you sure you want to close this account?</p> 
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button bsStyle="primary" onClick={handleCloseAccountAction}>
                  Yes
                </Button>
                <Button onClick={handleCloseModal}>No</Button>
              </Modal.Footer>
            </Modal>
    </StyledRow>
    <StyledCol>
          <StyledRow>
            <AccordionContainer>
              <Accordion allowZeroExpanded="true">
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <label>Notes ({notes !== undefined ? notes.length : 0})</label>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Header>
                      <div style={{ width: "100%" }}>
                        <ToolButton
                          onClick={() => {
                            setShow(true);
                            setModalTitle("Add");
                          }}
                          bsStyle="primary"
                        >
                          + New
                        </ToolButton>
                      </div>
                    </Header>
                    <br />
                    <Table responsive hover bordered id="print">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Note</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                          {notes.map((note) => {
                            if (note.uid !== undefined) {
                              return (
                                <tr key={note.uid}>
                                  <td>
                                    <div>
                                      {note.date
                                        ? note.date
                                        : "---"}
                                    </div>
                                  </td>
                                  <td>
                                    <div>{note.note}</div>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div>
                                        <EditButton
                                          onClick={() => doUpdateNote(note)}
                                          bsStyle="primary"
                                        >
                                          Edit
                                        </EditButton>
                                      </div>
                                      <div>
                                        <GlyphButton
                                          onClick={() =>
                                            setShowDelete({
                                              key: true,
                                              uid: note.uid,
                                            })
                                          }
                                        >
                                          <Glyphicon glyph="remove" />
                                        </GlyphButton>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          })}
                      </tbody>
                    </Table>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </AccordionContainer>
          </StyledRow>
          <StyledRow>
            <AccordionContainer>
              <Accordion allowZeroExpanded="true">
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <label>Call History ({callHistory !== undefined ? callHistory?.length : 0})</label>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Header>
                      <div style={{ width: "100%" }}>
                        <ToolButton
                          onClick={() => {
                            setCallShow(true);
                            setModalCallTitle("Add");
                          }}
                          bsStyle="primary"
                        >
                          + New
                        </ToolButton>
                      </div>
                    </Header>
                    <br />
                    <Table responsive hover bordered id="print">
                      <thead>
                        <tr>
                          <th>Call Date</th>
                          <th>Note</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {callHistory.map((call) => {
                        if (call?.uid !== undefined) {
                          return (
                            <tr key={call?.uid}>
                              <td>
                                <div>
                                  {call.date
                                    ? call.date
                                    : "---"}
                                </div>
                              </td>
                              <td>
                                <div>{call.note}</div>
                              </td>
                              <td>
                                <div className="d-flex gap-2">
                                  <div>
                                    <EditButton
                                      onClick={() => doUpdateCallNote(call)}
                                      bsStyle="primary"
                                    >
                                      Edit
                                    </EditButton>
                                  </div>
                                  <div>
                                    <GlyphButton
                                      onClick={() =>
                                        setShowCallDelete({
                                          key: true,
                                          uid: call.uid,
                                        })
                                      }
                                    >
                                      <Glyphicon glyph="remove" />
                                    </GlyphButton>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                      </tbody>
                    </Table>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </AccordionContainer>
          </StyledRow>
        </StyledCol>
    
        <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>{modalTitle} Notes</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label>Select Date (required)</label>
              <br />
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </div>

            <div class="form-group">
              <label for="noteArea">Enter Note (required)</label>

              <textarea
                className="form-control"
                id="noteArea"
                rows="3"
                value={noteValue}
                onChange={(e) => setNoteValue(e.target.value)}
              ></textarea>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={() => handleClose()}>Cancel</CloseButton>
          <ToolButton
            onClick={saveNotes}
            bsStyle="primary"
            disabled={noteValue && startDate? false : true}
          >
            Save
          </ToolButton>
        </Modal.Footer>
      </Modal>

      <Modal bsSize="small" show={showDelete.key} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Delete Note </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to delete this Note?</div>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={handleDeleteClose}>Close</CloseButton>
          <DeleteButton
            bsStyle="danger"
            onClick={() => deleteNote(showDelete.uid)}
          >
            Delete
          </DeleteButton>
        </Modal.Footer>
      </Modal>
      <Modal show={callShow} onHide={() => handleCallClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>{modalCallTitle} Notes</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label>Select Call Date (required)</label>
              <br />
              <DatePicker
                selected={startCallDate}
                onChange={handleStartDateCAllChange}
                required
              />
            </div>

            <div class="form-group">
              <label for="noteArea">Enter Note (required)</label>

              <textarea
                className="form-control"
                id="noteArea"
                rows="3"
                value={noteCallValue}
                onChange={(e) => setNoteCallValue(e.target.value)}
              ></textarea>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={() => handleCallClose()}>Cancel</CloseButton>
          <ToolButton
            onClick={saveCallNotes}
            bsStyle="primary"
            disabled={noteCallValue && startCallDate ? false : true}
          >
            Save
          </ToolButton>
        </Modal.Footer>
      </Modal>
      <Modal
        bsSize="small"
        show={showCallDelete.key}
        onHide={handleDeleteCallClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Delete Note </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to delete this Note?</div>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={handleDeleteCallClose}>Close</CloseButton>
          <DeleteButton
            bsStyle="danger"
            onClick={() => deleteCallNote(showCallDelete.uid)}
          >
            Delete
          </DeleteButton>
        </Modal.Footer>
      </Modal>
  </Grid>
  )
}

// const CustomerData = ({
//   customer: {
//     service_address,
//     name,
//     phone_number,
//     pickup_day
//   }
// }) =>
//   (
// <Grid>
//     <StyledRow>
//       <StyledCol sm={4} md={4}>
//         <h4>Customer Information</h4>
//         <div>Customer Name: {name}</div>
//       </StyledCol>
//       <StyledCol sm={4} md={4}>
//         <h4>Contact Information</h4>
//         <div>Phone Number: {phone_number}</div>
//       </StyledCol>
//     </StyledRow>
//     <StyledRow>
//       <StyledCol sm={4} md={4}>
//         <StyledRow>
//           <h4>Service Address:</h4>
//         </StyledRow>
//         <StyledRow>
//           <div>
//             {service_address.street}
//           </div>
//           <div>
//             {service_address.city + ", "} {service_address.state} {service_address.zipcode}
//           </div>
//         </StyledRow>
//       </StyledCol>
//       <StyledCol sm={4} md={4}>
//         <StyledRow>
//           <h4>Pickup Info:</h4>
//         </StyledRow>
//         <StyledRow>
//           <div>{pickup_day}</div>
//         </StyledRow>
//       </StyledCol>
//     </StyledRow>
    

    
//   </Grid>
//   )


const mapStateToProps = state => {
  console.log(state.greenBag.customer,"test data")
  return {
    customer: state.greenBag.customer
  };
};

const dispatchToProps = dispatch =>{
  return {
    closeAccount: (customer) => dispatch(GreenBagCustomers.closeAccount(customer)),
    setCustomer: (uid) => dispatch(GreenBagCustomers.getCustomer(uid)),
  }
}
// const mapDispatchToProps = (dispatch) => ({
//   setCustomer: (uid) => dispatch(ResidentialCustomers.getCustomer(uid)),
// });


export default connect(mapStateToProps,dispatchToProps)(CustomerData);
