/* @format - for Prettier */
import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Select from "react-select";
import { Redirect, Link } from "react-router-dom";
import InputMoment from "input-moment";
import "input-moment/dist/input-moment.css";
import moment from "moment";
import styled from "styled-components";
import {
  Glyphicon,
  FormGroup,
  InputGroup,
  FormControl,
  Button,
  Table,
  Modal,
  Grid,
  Row,
  Col,
} from "react-bootstrap";
import { Customers, NewCastleCustomers } from "../../../actions2";
import { DeniedWrapper } from "../../../styled";
import { history } from "../../../App";
import classNames from "classnames";

const GlyphButton = styled(Button)`
  width: 100%;
  color: red !important;
  border: none;
`;

const GlyphTd = styled.td`
  z-index: 100;
  width: 50px;
`;

const Wrapper = styled.div`
  width: 60vw;
  margin: 0 auto;
`;

const TitleBox = styled(Row)`
  margin-bottom: 25px !important;
  border-bottom: 1px solid black;
`;

const StyledRow = styled(Row)`
  margin-bottom: 20px !important;
`;

const StyledCol = styled(Col)`
  padding: 0px;
`;

const StyledDatePicker = styled(InputMoment)`
  width: 100% !important;
`;

const StyledSaveButton = styled.div`
  display: block !important;
  background-color: #1385e5 !important;
  padding: 12px 24px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  border-radius: 3px;
  cursor: pointer;
`;

const SaveButton = classNames({
  "im-btn": true,
  "ion-checkmark": true,
});

const StyledAmount = styled.td`
  text-align: end;
`;

const DeleteButton = styled(Button)`
  float: right;
`;

const CloseButton = styled(Button)`
  float: left;
`;

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      yearOptions: [
        {
          value: "2020",
          label: "2020",
        },
        {
          value: "2021",
          label: "2021",
        },
        {
          value: "2022",
          label: "2022",
        },
        {
          value: "2023",
          label: "2023",
        },
      ],
      quarterOptions: [
        {
          value: "1st Quarter",
          label: "1st Quarter",
        },
        {
          value: "2nd Quarter",
          label: "2nd Quarter",
        },
        {
          value: "3rd Quarter",
          label: "3rd Quarter",
        },
        {
          value: "4th Quarter",
          label: "4th Quarter",
        },
      ],
      selectedHistory: {
        uid: null,
      },
      // states for paid or charged modal
      show: false,
      moment: moment(),
      old_amount: 0,
      default_amount: 1.0,
      form: "",
      form_description: "",
      payment_source: "",
      quarter: "",
      year: "",
      // state for Invoice Modal
      show_invoice_dialog: false,
      due_date: moment(),

      // states for Suspended Modal
      show_suspended_modal: false,
      note: "",

      // state for delete Invoice Modal
      delete_show: false,
      selected_invoice_uid: 0,
    };
  }

  handleInvoiceDeleteClose = () => {
    this.setState({ delete_show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({
      show: false,
      moment: moment(),
      old_amount: 0,
      default_amount: this.props.customer?.rate,
      form: "",
      form_description: "",
      selectedHistory: {
        uid: null,
      },
    });
  };

  handleChange = (moment) => {
    this.setState({ moment });
  };

  handleAmountChange = (e) => {
    this.setState({ default_amount: e.target.value });
  };

  handlePaymentSourceChange = (e) => {
    this.setState({ payment_source: e.target.value });
  };

  handleSave = () => {
    this.props.changePaymentHistory(
      this.props.customer.uid,
      this.state.selectedHistory.uid,
      parseFloat(this.state.default_amount),
      this.state.payment_source,
      this.state.year.value,
      this.state.quarter.value,
      this.state.form_description,
      this.state.moment.format("L")
    );
    // if (this.state.form === "Paid") {
    //   this.props.makePayment(
    //     this.props.customer,
    //     this.state.moment.format("L"),
    //     parseFloat(this.state.default_amount, 10),
    //     parseFloat(this.state.old_amount, 10),
    //     this.state.form_description,
    //     this.state.selectedHistory.uid
    //   );
    // } else {
    //   this.props.charge(
    //     this.props.customer,
    //     this.state.moment.format("L"),
    //     parseFloat(this.state.default_amount, 10),
    //     parseFloat(this.state.old_amount, 10),
    //     this.state.form_description,
    //     this.state.selectedHistory.uid
    //   );
    // }
    this.handleClose();
  };
  handlePayment = () => {
    this.setState({ form: "Paid" });
    this.handleShow();
  };
  handleCharge = () => {
    this.setState({ form: "Charged" });
    this.handleShow();
  };
  handleFormDescription = (e) => {
    this.setState({ form_description: e.target.value });
  };
  quarterChanged = (e) => {
    this.setState({ quarter: e });
  };

  yearChanged = (e) => {
    this.setState({ year: e });
  };

  handleRemoveModal = (history) => {
    // this.props.removeInvoiceHistory(history.uid, this.props.customer.uid)
    this.setState({
      selected_invoice_uid: history.uid,
      delete_show: true,
    });
  };

  handleRemove = () => {
    this.setState({
      delete_show: false,
    });
    this.props.removeInvoiceHistory(
      this.state.selected_invoice_uid,
      this.props.customer.uid
    );
  };

  handleEdit = (history) => {
    this.setState({
      old_amount: history.amount.replace(/[^0-9.]/g, ""),
      default_amount: history.amount.replace(/[^0-9.]/g, ""),
      form_description: history.description,
      moment: moment(history.time, "MM/DD/YYYY"),
      selectedHistory: history,
      payment_source:
        history.paymentSource === undefined
          ? "In Office"
          : history.paymentSource,
      quarter: {
        value: history.quarter,
        label: history.quarter,
      },
      year: {
        value: history.year ? history.year : "",
        label: history.year ? history.year : "",
      },
    });
    this.handleShow();
  };

  handleHideInvoiceDialog = () => {
    this.setState({ show_invoice_dialog: false });
  };

  handleShowInvoiceDialog = () => {
    this.handleHideInvoiceDialog();
  };

  handleInvoiceDueDateChange = (moment) => {
    this.setState({ due_date: moment });
  };

  handleInvoiceSave = () => {
    this.props.updateInvoiceDates(
      this.props.customer,
      this.state.moment.format("L"),
      this.state.due_date.format("L"),
      this.state.selectedHistory.uid
    );
    this.handleHideInvoiceDialog();
  };

  handleSendInvoice = () => {
    history.push("/Invoice/" + this.state.selectedHistory.uid);
  };

  // Suspend Account
  handleSuspendAccount = () => {
    this.setState({ show_suspended_modal: true });
  };

  handleActivateAccount = () => {
    this.props.unsuspend(
      this.props.customer,
      this.state.note,
      this.props.customer.amount_due
    );
  };

  handleNoteChanged = (e) => {
    this.setState({ note: e.target.value });
  };

  handleSaveSuspended = () => {
    this.props.suspend(this.props.customer, this.state.note);
    this.handleCloseSuspendModal();
  };

  handleCancelSuspend = () => {
    this.handleCloseSuspendModal();
  };

  handleCloseSuspendModal = () => {
    this.setState({ show_suspended_modal: false });
  };
  formatDecimal = (number) =>{
    let numind = number.indexOf('.')
    let numlen = number.slice(numind)
    if (numlen.length > 2) return `${number.toString()}`
    return `${number.toString()}0`
  }

  render() {
    if (this.props.authorized) {
      if (this.props.customer === null) {
        return <Redirect to="/NewCastle/Customers" />;
      } else {
        const { Status } = this.props.customer;
        const sortedHistory = _.sortBy(
          this.props.customer.history,
          (history) => {
            let data = history.time.split("/");
            return [data[2], data[0], data[1]].join("-");
          }
        ).reverse();

        return (
          <Grid>
            <Wrapper>
              <TitleBox>
                <StyledCol sm={5} md={6}>
                  <h3>{this.props.customer["Resident Name"]}</h3>
                </StyledCol>
                <StyledCol sm={5} md={6}>
                  <h4>Quarterly Status: {Status}</h4>
                </StyledCol>
                {/* <StyledCol sm={3} md={3} smOffset={1} mdOffset={1}>
                  <h4>Amount Due: ${amount_due}</h4>
                </StyledCol> */}
              </TitleBox>
              <StyledRow>
                <Table responsive condensed striped bordered>
                  <thead>
                    <tr>
                      <th>Date Paid</th>
                      <th>Amount Paid</th>
                      <th>Payment Source</th>
                      <th>Transaction ID</th>
                      <th>Description</th>
                      <th>Year</th>
                      <th>Quarter</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(sortedHistory, (history, index) => {
                       if(history.amount !== undefined){
                        history.amount =
                           history?.amount?.toString().indexOf(".") !== -1
                             ? this.formatDecimal(history.amount.toString())
                             : history.amount.toString() + ".00";

                         history.amount =
                           typeof history.amount === String
                             ? Number(history.amount)
                             : history.amount;
                      } 
                      return (
                        <tr key={index}>
                          <td onClick={() => this.handleEdit(history)}>
                            {history.time}
                          </td>
                          {history.amount ? (
                            <StyledAmount
                              onClick={() => this.handleEdit(history)}
                            >
                              {" "}
                              {history.amount}{" "}
                            </StyledAmount>
                          ) : (
                            <td onClick={() => this.handleEdit(history)} />
                          )}
                          {history.paymentSource ? (
                            <td onClick={() => this.handleEdit(history)}>
                              {history.paymentSource}
                            </td>
                          ) : (
                            <td onClick={() => this.handleEdit(history)}>
                              In Office
                            </td>
                          )}
                          {history.transaction_id ? (
                            <td>{history.transaction_id}</td>
                          ) : (
                            <td />
                          )} 
                          {history.description ? (
                            <td onClick={() => this.handleEdit(history)}>
                              {" "}
                              {history.description}{" "}
                            </td>
                          ) : (
                            [
                              history.status === "Invoice Sent" ? (
                                [
                                  history.sent ? (
                                    <td
                                      onClick={() => this.handleEdit(history)}
                                    >
                                      Sent
                                    </td>
                                  ) : (
                                    <td
                                      onClick={() => this.handleEdit(history)}
                                    >
                                      Waiting to send invoice
                                    </td>
                                  ),
                                ]
                              ) : (
                                <td onClick={() => this.handleEdit(history)} />
                              ),
                            ]
                          )}
                          {history.year ? (
                            <td onClick={() => this.handleEdit(history)}>
                              {history.year}
                            </td>
                          ) : (
                            <td onClick={() => this.handleEdit(history)} />
                          )}
                          {history.quarter ? (
                            <td onClick={() => this.handleEdit(history)}>
                              {history.quarter}
                            </td>
                          ) : (
                            <td onClick={() => this.handleEdit(history)} />
                          )}
                          <GlyphTd className="hidden-print">
                            <GlyphButton
                              onClick={() => this.handleRemoveModal(history)}
                            >
                              <Glyphicon glyph="remove" />
                            </GlyphButton>
                          </GlyphTd>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </StyledRow>
              {/* <StyledRow>
                <StyledCol sm={3} md={3}>
                  <Button onClick={this.handlePayment}> Add New Payment</Button>
                </StyledCol>
                <StyledCol sm={3} md={3}>
                  <Button onClick={this.handleCharge}> Add New Charge</Button>
                </StyledCol>
                <StyledCol sm={3} md={3}>
                  <Button
                    disabled={Status === "Suspended"}
                    onClick={this.handleSuspendAccount}
                  >
                    Suspend Account
                  </Button>
                </StyledCol>
                <StyledCol sm={3} md={3}>
                  <Button
                    disabled={Status !== "Suspended"}
                    onClick={this.handleActivateAccount}
                  >
                    Activate Account
                  </Button>
                </StyledCol>
              </StyledRow> */}
              {/* <StyledRow>
                <StyledCol sm={6} md={6}>
                  <Link
                    to={
                      "/Invoice/" +
                      (!this.state.selectedHistory
                        ? this.state.selectedHistory.uid
                        : "new")
                    }
                  >
                    Create New Invoice
                  </Link>
                </StyledCol>
              </StyledRow> */}
              <StyledRow>
                <Link to="/NewCastle/Customer">
                  <div> {"<--"} Back To Customer </div>
                </Link>
              </StyledRow>
            </Wrapper>
            <Modal show={this.state.show} onHide={this.handleClose} bsSize="sm">
              <Modal.Body>
                <StyledRow>
                  <div>Amount Paid</div>
                  <FormGroup>
                    <InputGroup>
                      <InputGroup.Addon>$</InputGroup.Addon>
                      <FormControl
                        type="text"
                        value={this.state.default_amount}
                        onChange={this.handleAmountChange}
                      />
                      {/* <InputGroup.Addon>.00</InputGroup.Addon> */}
                    </InputGroup>
                  </FormGroup>
                </StyledRow>
                <StyledRow>
                  <div style={{ marginTop: "-20px" }}>Payment Source</div>
                  <FormGroup>
                    <InputGroup>
                      <InputGroup.Addon></InputGroup.Addon>
                      <FormControl
                        type="text"
                        value={this.state.payment_source}
                        onChange={this.handlePaymentSourceChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </StyledRow>
                <StyledRow>
                  <div style={{ marginTop: "-20px" }}>Year</div>
                  <Select
                    options={this.state.yearOptions}
                    value={this.state.year}
                    onChange={this.yearChanged}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                </StyledRow>
                <StyledRow>
                  <div style={{ marginTop: "0px" }}>Quarter</div>
                  <Select
                    options={this.state.quarterOptions}
                    value={this.state.quarter}
                    onChange={this.quarterChanged}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                </StyledRow>
                <StyledRow>
                  <div style={{ marginTop: "-10px" }}>Description</div>
                  <FormControl
                    type="text"
                    componentClass="textarea"
                    rows="5"
                    onChange={this.handleFormDescription}
                    value={this.state.form_description}
                  />
                </StyledRow>
                <StyledRow>
                  <div>Date Paid</div>
                  <StyledDatePicker
                    moment={this.state.moment}
                    onChange={this.handleChange}
                    onSave={this.handleSave}
                  />
                </StyledRow>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.show_invoice_dialog}
              onHide={this.handleHideInvoiceDialog}
            >
              <Modal.Body>
                <StyledRow>
                  <StyledCol sm={6} md={6}>
                    <h3 className="text-center">Start Date</h3>
                    <StyledDatePicker
                      moment={this.state.moment}
                      onChange={this.handleChange}
                    />
                  </StyledCol>
                  <StyledCol sm={6} md={6}>
                    <h3 className="text-center">Due Date</h3>
                    <StyledDatePicker
                      moment={this.state.due_date}
                      onChange={this.handleInvoiceDueDateChange}
                    />
                  </StyledCol>
                </StyledRow>
                <StyledRow>
                  <StyledSaveButton
                    className={SaveButton}
                    onClick={this.handleInvoiceSave}
                  >
                    Save
                  </StyledSaveButton>
                </StyledRow>
                <StyledRow>
                  <StyledSaveButton onClick={this.handleSendInvoice}>
                    <Glyphicon glyph="list" />
                    <span style={{ padding: "6px" }}>Details</span>
                  </StyledSaveButton>
                </StyledRow>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.show_suspended_modal}
              onHide={this.handleCloseSuspendModal}
            >
              <Modal.Body>
                <div>
                  Please enter a note as to why account is being suspended
                </div>
                <FormControl
                  type="text"
                  componentClass="textarea"
                  rows="3"
                  onChange={this.handleNoteChanged}
                  value={this.state.note}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button bsStyle="primary" onClick={this.handleSaveSuspended}>
                  Save
                </Button>
                <Button onClick={this.handleCancelSuspend}>Cancel</Button>
              </Modal.Footer>
            </Modal>
            <Modal
              bsSize="small"
              show={this.state.delete_show}
              onHide={this.handleInvoiceDeleteClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Delete </span>
                  <span>
                    {this.state.customer ? this.state.customer.name : " "}
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>Are you sure you want to delete this history?</div>
              </Modal.Body>
              <Modal.Footer>
                <CloseButton onClick={this.handleInvoiceDeleteClose}>
                  Close
                </CloseButton>
                <DeleteButton bsStyle="danger" onClick={this.handleRemove}>
                  Delete
                </DeleteButton>
              </Modal.Footer>
            </Modal>
          </Grid>
        );
      }
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}
const mapStateToProps = (state) => ({
  authorized: state.user?.pages?.history,
  customer: state.newcastle.customer,
});

const mapDispatchToProps = (dispatch) => ({
  changePaymentHistory: (
    customer_uid,
    history_uid,
    amount,
    paymentSource,
    year,
    quarter,
    description,
    time
  ) =>
    dispatch(
      NewCastleCustomers.changePaymentHistory(
        customer_uid,
        history_uid,
        amount,
        paymentSource,
        year,
        quarter,
        description,
        time
      )
    ),
  makePayment: (
    customer,
    time,
    new_amount,
    old_amount,
    description,
    historyId
  ) =>
    dispatch(
      Customers.makePayment(
        customer,
        time,
        new_amount,
        old_amount,
        description,
        historyId
      )
    ),
  suspend: (customer, note) =>
    dispatch(Customers.suspendCustomer(customer, note)),
  unsuspend: (customer, note, amount_due) =>
    dispatch(Customers.unsuspendCustomer(customer, note, amount_due)),
  charge: (customer, time, new_amount, old_amount, description, historyId) =>
    dispatch(
      Customers.chargeCustomer(
        customer,
        time,
        new_amount,
        old_amount,
        description,
        historyId
      )
    ),
  updateInvoiceDates: (customer, time, due_date, historyId) =>
    dispatch(
      Customers.updateInvoiceDates(customer, time, due_date, historyId)
    ),
  removeInvoiceHistory: (uid, customer_id) =>
    dispatch(Customers.removeInvoiceHistory(uid, customer_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
