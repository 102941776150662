/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import { Button, Modal, ToggleButton } from 'react-bootstrap';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {reduxForm, FieldArray, formValueSelector, change} from 'redux-form';
import {Routes} from '../../actions2';
import {
  StopTable,
  StopForm,
  StopReorder,
  StopRemove,
  Header,
  RouteList
} from './components';
import {StyledForm} from './styled-components';
import toastr from 'toastr';
import moment from "moment";
import {DeniedWrapper} from '../../styled';
import { pdfService } from '../../services/pdf';
import { AppCustomer } from '../../utils/customer';

const RouteWrapper = styled.div`
  margin-top: 2vh;
  width: 90%;
  margin: 0 auto;
`;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  height: 40px;
  margin: 10px 0px 0px 10px;
  float: right;
`;

const CloseButton = styled(Button)`
  float: left;
`;

class Route extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'view',
      shownStops: [],
      editingUID: 'new',
      selectedRoutes: [],
      note:"",
      underline:false,
      italicize:false,
      bold:false,
      printCoverLettersDisabled: false,
      showPrintCoverModal:false,
      selectedStops: [],
      showAlert: false,
      errMsg: '',
      customers: []
    };
    this.changeFullAddress = this.changeFullAddress.bind(this)
    this.changeEditingItem = this.changeEditingItem.bind(this)
  }

  changeEditingItem(uid) {
    this.setState({
      editingUID: uid
    })
  }
  resetRoute = () => {
    alert(`RESETTING ROUTE STATUS - ${this.props.route.name}`);
    console.log(this.props.route.stops)
    this.props.resetStops(this.props.route.stops)
  };
  printCoverLetterModalHandle = () => {
    this.setState({
      showPrintCoverModal: true,
    });
  };
  toggleUnderline = () => {
    this.setState({
      underline: !this.state.underline,
    });
  };
  toggleItalicize = () => {
    this.setState({
      italicize: !this.state.italicize,
    });
  };
  toggleBold = () => {
    this.setState({
      bold: !this.state.bold,
    });
  };
  changeFullAddress(index, address) {
    let params = address.split('--')
    let stops = this.props.stops
    stops[index]['address'] = {}
    stops[index]['address'].street = params[0]
    stops[index]['address'].city = params[1]
    stops[index]['address'].state = params[2]
    stops[index]['address'].zipcode = params[3]

    this.props.change("RouteForm", "stops", stops);
  }

  componentDidMount() {
    this.setState({
      shownStops: this.props.route.stops,
    });
    if (this.props.customerList.length === 0)
      this.props.getCustomerList();
          
    if (this.props.dumpsterAddresses.length === 0)
      this.props.getDumpsterAddresseses();

    //console.log(this.props, "routes");
    //console.log(Object.keys(this.props.routes).length, "lenght");
  }

  changeMode = mode => {
    this.setState({
      editingUID: 'new'
    })
    if (mode === 'save') {
      this.save();
      this.props.reset();
    } else  if (mode === 'confirm') {
      if (this.state.selectedRoutes.length) {
        this.moveStops();
        this.changeMode('view');
        this.props.reset();
      } else {
        this.setState({ showAlert: true, errMsg: 'You should select at least 1 route'})
      }
    } else {
      if (mode === 'move' && !this.state.selectedStops.length) {
        this.setState({showAlert: true, errMsg: 'You should select at least 1 stop'});
      } else {
        this.props.reset();
        this.setState({mode});
      }
    }
  };

  remove = (stopId) => {
    this.props.removeStop(stopId, this.props.route.uid);
    const shownStops = _.filter(this.state.shownStops, stop => {
      return stop.uid !== stopId;
    });
    this.setState({shownStops});
  };

  onSelectRoutes = (routes) => {
    this.setState({ selectedRoutes: [ ...routes ]});
  }

  handleClose = () => {
    this.setState({showAlert: false});
  };

  onSelectStops = (stops) => {
    this.setState({ selectedStops: [...stops] });
  }

  move = (stopId, dir) => {
    let stops = this.state.shownStops.slice();
    let index = '';
    let moved = {};
    _.forEach(stops, (stop, i) => {
      index = stop.uid === stopId ? i : index;
    });
    if (index + dir >= 0 && index + dir < stops.length) {
      moved = stops.splice(index, 1)[0];
      stops.splice(index + dir, 0, moved);
    }
    this.setState({shownStops: stops});
  };

  moveStops = () => {
    this.props.moveStops(this.state.selectedRoutes, this.state.selectedStops, this.props.route.uid);
  }

  onChangeStopsOrder = (stops) => {
    this.props.reorderStops(stops, this.props.route);
  }

  save = () => {
    if (this.state.mode === 'reorder') {
      this.props.reorderStops(this.state.shownStops, this.props.route);
    } else if (this.state.mode === 'edit') {
      const length = Object.keys(this.props.routes).length + 1;
      this.props.saveRoute(
        this.props.formValues.values,
        this.props.formValues.fields,
        length
      );
      setTimeout(() => {
        this.props.setRoute(this.props.uid)
      }, 100)
      this.setState({
        shownStops: this.props.formValues.values.stops.slice(),
      });
    }
    this.changeMode('view');
  };

  renderErrModal = () => (
    <Modal
      bsSize="small"
      show={this.state.showAlert}
      onHide={this.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span>No item hasn't chosen</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{this.state.errMsg}</div>
      </Modal.Body>
      <Modal.Footer>
        <CloseButton onClick={this.handleClose}>Close</CloseButton>
      </Modal.Footer>
    </Modal>
  )


  printAllCoverLetters = async () => {
    toastr.info("Printing all cover letters may take some time...", "Downloading...");
    // const customerList = this.props.route.stops.map((stop) => stop.customerInfo);
    const customerList = this.props.route.stops.map((stop) => {
      const customerInfo = { ...AppCustomer(stop.customer), service_address: stop.address };
      return customerInfo;
    });
    this.setState({
      printCoverLettersDisabled: true
    })
    await pdfService.individual_route_coverletter_pdf_download(
      customerList,
      this.state.note,
      moment().format('MM/DD/YYYY'),
      null,
      this.state.underline,
      this.state.bold,
      this.state.italicize
    );
    toastr.success("All cover letters printed!", "Success!")
    this.setState({
      printCoverLettersDisabled: false,
      note: '',
      underline: false,
      bold: false,
      italicize: false,
      showPrintCoverModal: false
    })
  };
  render() {

    if (this.props.authorized) {
      if (
        this.props.route === undefined ||
        this.props.route.name === undefined
      ) {
        return <Redirect to="Routes" />;
      }
      if (this.state.mode === 'view') {
        /* Just displays the Route */
        return (
          <>
          <div>
            <Button
              // onClick={this.printAllCoverLetters}
              onClick={this.printCoverLetterModalHandle}
              // bsStyle="primary"
              disabled={this.state.printCoverLettersDisabled}
              style={{position:"absolute",right:"60px"}}
              // onClick={this.editRoutes}
              className="ml-2"
              // hidden={this.state.mode === "edit"}
            >
              {" "}
              Print Cover Letters{" "}
            </Button>
            <Button
              // onClick={this.printAllCoverLetters}
              onClick={this.resetRoute}
              // bsStyle="primary"
              // onClick={this.editRoutes}
              className="ml-2"
              // hidden={this.state.mode === "edit"}
            >
              {" "}
              RESET ROUTE STATUS{" "}
            </Button>
          </div>
          <RouteWrapper>
            <Header
              type="view"
              mode={this.state.mode}
              changeMode={this.changeMode}
            />
            <StopTable stops={this.props.route.stops} onSelectStops={this.onSelectStops} changeStopsOrder={this.onChangeStopsOrder} />
            {this.renderErrModal()}
          </RouteWrapper>
          <Modal show={this.state.showPrintCoverModal} onHide={this.handlePrintCover}>
            <Modal.Body>
              <h3>Print Cover Letters</h3>
              <div className="form-group">
            <label htmlFor="noteArea">Enter Note</label>
            <textarea
              className="form-control"
              id="noteArea"
              rows="3"
              value={this.state.note}
              onChange={(e) => this.setState({...this.state,note:e.target.value})}
            ></textarea>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",marginTop:20}}>
              <div style={{display:"flex",alignItems:"center"}}>
                  <h5 style={{paddingRight:10}}>Underline</h5>
                  <ToggleButton onChange={this.toggleUnderline} checked={this.state.underline} value={this.state.underline} type="checkbox" style={{border:"none",padding:0}} />
              </div>
              <div style={{display:"flex",alignItems:"center"}}>
                  <h5 style={{paddingRight:10}}>Bold</h5>
                  <ToggleButton onChange={this.toggleBold} checked={this.state.bold} value={this.state.bold} type="checkbox" style={{border:"none",padding:0}}/>
              </div>
              <div style={{display:"flex",alignItems:"center"}}>
                  <h5 style={{paddingRight:10}}>Italicize</h5>
                  <ToggleButton onChange={this.toggleItalicize} checked={this.state.italicize} value={this.state.italicize} type="checkbox" style={{border:"none",padding:0}} size={24}/>
              </div>
            </div>
            </div>

              {/* <Select
                options={this.state.quarterOptions}
                value={this.state.select_quarter}
                onChange={this.quarterChanged}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              /> */}
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                disabled={this.state.printCoverLettersDisabled}
                bsStyle="primary"
                onClick={this.printAllCoverLetters}
                id="printBtn"
              >
                Generate Cover Letters
              </ToolButton>
            </Modal.Footer>
          </Modal>
          </>
        );
      } else if (this.state.mode === 'edit') {
        /* Allows the Route to be editted */
        return (
          <StyledForm onSubmit={this.props.handleSubmit(this.save)}>
            <Header
              type="form"
              changeMode={this.changeMode}
              submitting={this.props.submitting}
            />
            <FieldArray
              name="stops"
              customers={this.props.customerList}
              dumpsterAddresses = {
                this.props.dumpsterAddresses
              }
              adding={true}
              stops={this.props.stops}
              changeFullAddress={(index, address) => this.changeFullAddress(index, address)}
              editingUID={this.state.editingUID}
              changeEditingItem={(uid) => this.changeEditingItem(uid)}
              component={StopForm}
            />
          </StyledForm>
        );
      } else if (this.state.mode === 'reorder') {
        /* Used to Reorder stops */
        return (
          <RouteWrapper>
            <Header type="reorder" changeMode={this.changeMode} />
            <StopReorder move={this.move} stops={this.state.shownStops} />
          </RouteWrapper>
        );
      } else if (this.state.mode === 'delete') {
        /* Used to Delete Stops */
        return (
          <RouteWrapper>
            <Header type="delete" changeMode={this.changeMode} />
            <StopRemove remove={this.remove} stops={this.state.shownStops} allStops={this.props.route.stops}/>
          </RouteWrapper>
        );
      } else if (this.state.mode === 'move'){
        return (
          <RouteWrapper>
            <Header type="move" changeMode={this.changeMode} />
            <RouteList 
              onSelectRoutes={this.onSelectRoutes} 
              routes = {
                _.filter(this.props.routes, (route) => route.uid !== this.props.route.uid)
              }
            />
            {this.renderErrModal()}
          </RouteWrapper>
        )
      } else {
        return <RouteWrapper> Route Not Found </RouteWrapper>;
      }
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = state => ({
  authorized: state.user?.pages?.routes,
  route: state.currentRoute,
  uid: state.currentRouteUid,
  routes: state.routes,
  formValues: state.form.RouteForm,
  customerList: state.customerList,
  dumpsterAddresses: state.dumpsterAddresses,
});

const mapDispatchToProps = dispatch => ({
  change,
  reorderStops: (stops, route) => dispatch(Routes.reorderStop(stops, route)),
  setRoute: uid => dispatch(Routes.getRoute(uid)),
  saveRoute: (route, touched, priorities) => dispatch(Routes.saveRoute(route, touched, priorities)),
  resetStops: (stops) => dispatch(Routes.resetStops(stops)),
  moveStops: (routes, stops, route) => dispatch(Routes.moveStops(routes, stops, route)),
  removeStop: (stopId, routeId) =>
    dispatch(Routes.removeStop(stopId, routeId)),
  getCustomerList: () => dispatch(Routes.getCustomerList()),
  getDumpsterAddresseses: () => dispatch(Routes.getDumpsterAddresseses()),
});

function scrollToFirstError(validationResult) {
    let stops = validationResult.stops
    for (let i = 0; i < stops.length; i++) {
        if (stops[i]) {
            const el = document.querySelector('[name="stops[' + i + '].name"]');
            const elRow = el.closest('tr');
            if (elRow) {
                const position = elRow.getBoundingClientRect().top + document.documentElement.scrollTop;
                const offset = 20;
                window.scrollTo({ top: position - offset, behavior: 'smooth' });
                return false;
            }
        }
    }
    return false;
}

Route = reduxForm({
  form: 'RouteForm',
    enableReinitialize: true,
    onSubmitFail: scrollToFirstError
})(Route);

const selector = formValueSelector('RouteForm')

Route = connect(
  state => {
    const stops = selector(state, 'stops')
    return {
      stops: stops,
      initialValues: state.currentRoute,
    }
  }
)(Route);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Route);
