/* @format - for Prettier */
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap'
import { setUser } from '../../actions/Users'
import { NewCastleCustomers as NewCastleCustomersActions } from '../../actions2'
import axiosInstance from '../../api/axios'
import LocalStorage from '../../services/LocalStorage'

class Header extends React.Component {
  handleLogout = () => {
    axiosInstance.post('api/auth/logout').then(() => {
      LocalStorage.storeData('eiken_user', null);
      window.dispatchEvent(new Event('storage'));
      this.props.history.push('/Login')
    });
  }

  componentDidMount() {
    this.props.getCurrentBillingPeriod()
    localStorage.setItem('remember_newCastle_search_key', '')
    localStorage.setItem('remember_customer_search_key', '')
    localStorage.setItem('remember_residential_search_key', '')
    localStorage.setItem('remember_westmayfield_search_key', '')
    localStorage.setItem('remember_green_bag_search_key', '')
    localStorage.setItem('remember_job_submission_search_key', '')
  }

  render() {
    let routes = !(this.props.authorized && this.props.authorized.routes)
    let customers = !(this.props.authorized && this.props.authorized.customers)
    let admin = !(this.props.authorized && this.props.authorized.admin)
    console.log('authorized', this.props.authorized);
    return (
      <Navbar>
        <Navbar.Header />
        <Nav>
          <LinkContainer to="/Admin">
            <NavItem disabled={admin} active>Admin</NavItem>
          </LinkContainer>
          <LinkContainer to="/Routes">
            <NavItem disabled={routes}>Routes</NavItem>
          </LinkContainer>
          <LinkContainer to="/Customers">
            <NavItem disabled={customers}>Commercial Customers</NavItem>
          </LinkContainer>
          <LinkContainer to="/Residential/Customers">
            <NavItem disabled={customers}>Residential Customers</NavItem>
          </LinkContainer>
          <LinkContainer to="/WestMayfield/Customers">
            <NavItem disabled={customers}>West Mayfield Customers</NavItem>
          </LinkContainer>
          <NavDropdown title="Reports" id="nav-dropdown">
            <LinkContainer to="/Reports/due_accounts">
              <NavItem disabled={routes}>Past Due Accounts</NavItem>
            </LinkContainer>
            <LinkContainer to="/Reports/residential">
              <NavItem disabled={routes}>Residential Report</NavItem>
            </LinkContainer>
            <LinkContainer to="/Reports/expiring_contracts">
              <NavItem disabled={routes}>Expiring Contracts Report</NavItem>
            </LinkContainer>
            <LinkContainer to="/Reports/profit">
              <NavItem disabled={routes}>Profit Report</NavItem>
            </LinkContainer>
            <LinkContainer to="/Reports/invoices">
              <NavItem disabled={routes}>Search Invoices</NavItem>
            </LinkContainer>
            <LinkContainer to="/Reports/ResidentialPricing">
              <NavItem disabled={routes}>Pricing Report (RES)</NavItem>
            </LinkContainer>
            <LinkContainer to="/Reports/CommercialPricing">
              <NavItem disabled={routes}>Pricing Report (COMM)</NavItem>
            </LinkContainer>
          </NavDropdown>
          <LinkContainer to="/NewCastle/Customers">
            <NavItem disabled={customers}>New Castle Customers</NavItem>
          </LinkContainer>
          <LinkContainer to="/NewCastle2/Customers">
            <NavItem disabled={customers}>New Castle Customers 2.0</NavItem>
          </LinkContainer>
          <LinkContainer to="/EastButler/Customers">
            <NavItem disabled={customers}>East Butler Customers</NavItem>
          </LinkContainer>
          <LinkContainer to="/SlipperyRock/Customers">
            <NavItem disabled={customers}>Slippery Rock Customers</NavItem>
          </LinkContainer>
          <LinkContainer to="/ClosedAccounts">
            <NavItem disabled={customers}>Closed Accounts</NavItem>
          </LinkContainer>
          <LinkContainer to="/GreenBagCustomers">
            <NavItem disabled={customers}>Green Bag Customers</NavItem>
          </LinkContainer>
          <LinkContainer to="/JobSubmissions">
            <NavItem disabled={admin}>Job App Submissions</NavItem>
          </LinkContainer>
          <LinkContainer to="/CustomerPortal">
            <NavItem disabled={admin}>Customer Portal Admin</NavItem>
          </LinkContainer>
        </Nav>
        {this.props.logOut ? (
          <Nav pullRight>
            <NavItem onClick={this.handleLogout}>Logout</NavItem>
          </Nav>
        ) : (
          <div />
        )}
      </Navbar>
    )
  }
}

const mapStateToProps = (state) => ({
  authorized: state.user?.pages,
  logOut: state.user?.email
})

const mapDispatchToProps = (dispatch) => ({
  getCurrentBillingPeriod: () =>
    dispatch(NewCastleCustomersActions.getCurrentBillingPeriod()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
