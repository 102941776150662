/* @format - for Prettier */
import React from 'react';
import styled from 'styled-components';
import {Field} from 'redux-form';
import {Table} from 'react-bootstrap';
import {required} from '../../../renderComponents';
import {ErrorText} from '../../../styled';

const StyledInput = styled.input`
  width: 80%;
`;

const StyledSpan = styled.span`
  padding-left: 10px;
`;

const renderField = ({input, label, type, meta: {touched, error}}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledInput {...input} type={type} placeholder="" />
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
);

const UsersForm = ({fields, meta: {error, submitFailed}}) => (
  <div>
    <Table responsive condensed hover bordered>
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Privileges</th>
        </tr>
      </thead>
      <tbody>
        {fields.map((user, index) => (
          <tr key={index}>
            <td>
              <Field
                name={`${user}.first_name`}
                type="text"
                component={renderField}
                validate={[required]}
              />
            </td>
            <td>
              <Field
                name={`${user}.last_name`}
                type="text"
                component={renderField}
                validate={[required]}
              />
            </td>
            <td>
              <div>
                <Field
                  name={`${user}.pages.admin`}
                  component="input"
                  type="checkbox"
                />
                <StyledSpan>Admin</StyledSpan>
              </div>
              <div>
                <Field
                  name={`${user}.pages.customers`}
                  component="input"
                  type="checkbox"
                />
                <StyledSpan>Customers</StyledSpan>
              </div>
              <div>
                <Field
                  name={`${user}.pages.history`}
                  component="input"
                  type="checkbox"
                />
                <StyledSpan>History</StyledSpan>
              </div>
              <div>
                <Field
                  name={`${user}.pages.invoices`}
                  component="input"
                  type="checkbox"
                />
                <StyledSpan>Invoices</StyledSpan>
              </div>
              <div>
                <Field
                  name={`${user}.pages.routes`}
                  component="input"
                  type="checkbox"
                />
                <StyledSpan>Routes</StyledSpan>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

export default UsersForm;
