import React, { useState } from 'react';

import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { Button, Glyphicon, Modal, ProgressBar } from 'react-bootstrap';
import Select from 'react-select';

import moment from 'moment';
import { pdfService } from '../../services/pdf';

const Wrapper = styled.div``;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  margin: 10px;
  margin-right: 0;
`;

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`;

const PrintProgress = styled(ProgressBar)`
  margin-top: 20px;
`;

const PrintOpenInvoices = ({ invoices }) => {
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);
  const [exporting, setExporting] = useState(false);
  const [dueDate, setDueDate] = useState(null);

  const handlePrint = async () => {
    setExporting(true)

    await pdfService.newcastle_customer_invoice_pdf_download(
      invoices,
      moment().format('MM/DD/YYYY'),
      dueDate,
      setProgress,
    );

    setExporting(false)
    setShow(false)
  };

  const handleHide = () => {
    if (!exporting) setShow(false);
  };

  return (
    <>
      {invoices === undefined ? (
        <Redirect to="Reports/invoices" />
      ) : (
        <Wrapper>
          <Modal show={show} onHide={handleHide}>
            <Modal.Body>
              {exporting ? (
                <>
                  <h3>Downloading...</h3>
                  <p>This may take some time.</p>
                  <PrintProgress active striped now={progress} label={`${progress}%`} />
                </>
              ) : (
                <>
                  <h3>Download Open Invoices as PDF</h3>
                  <Select
                    disabled={exporting}
                    onChange={(v) => {
                      setDueDate(v.value);
                    }}
                    options={[
                      {
                        value: moment().month(11).date(15).format('MM/DD/YYYY'),
                        label: '1st Quarter',
                      },
                      {
                        value: moment().month(2).date(15).format('MM/DD/YYYY'),
                        label: '2nd Quarter',
                      },
                      {
                        value: moment().month(5).date(15).format('MM/DD/YYYY'),
                        label: '3rd Quarter',
                      },
                      {
                        value: moment().month(8).date(15).format('MM/DD/YYYY'),
                        label: '4th Quarter',
                      },
                    ]}
                  />
                </>
              )}
              <ToolButton disabled={!dueDate} bsStyle="primary" onClick={handlePrint} id="printBtn">
                Print
              </ToolButton>
            </Modal.Body>
          </Modal>
          <ToolButton
            disabled={exporting}
            bsStyle="primary"
            id="printBtn"
            onClick={() => setShow(true)}
          >
            <AddGlyph glyph="print" />
            Download All Open Invoices
          </ToolButton>
        </Wrapper>
      )}
    </>
  );
};

export default PrintOpenInvoices;
