/* @format - for Pretter */
const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');
require('firebase/storage');
require('firebase/functions');

const devConfig = {
  apiKey: 'AIzaSyBIFaW8RNFtcNnz0x3y4mcOwIePXqT4O-A',
  authDomain: 'aiken-refuse-dev-database.firebaseapp.com',
  databaseURL: 'https://aiken-refuse-dev-database.firebaseio.com',
  projectId: 'aiken-refuse-dev-database',
  storageBucket: 'aiken-refuse-dev-database.appspot.com',
  messagingSenderId: '77996601113',
};

const stageConfig = {
  apiKey: 'AIzaSyD8pLYw8w3c8pzyExXln8y19EQbpz5qhyA',
  authDomain: 'aiken-refuse-staging-database.firebaseapp.com',
  databaseURL: 'https://aiken-refuse-staging-database.firebaseio.com',
  projectId: 'aiken-refuse-staging-database',
  storageBucket: 'aiken-refuse-staging-database.appspot.com',
  messagingSenderId: '687998861546',
};

const prodConfig = {
    apiKey: 'AIzaSyAhtAV6B1DqBWmhxOM6T4sQw324QNmlUGc',
    authDomain: 'aiken-refuse-prod-database.firebaseapp.com',
    databaseURL: 'https://aiken-refuse-prod-database.firebaseio.com',
    projectId: 'aiken-refuse-prod-database',
    storageBucket: 'aiken-refuse-prod-database.appspot.com',
    messagingSenderId: '774382392442',
  
};
if (!firebase.apps.length) {
  if(process.env.REACT_APP_SECRET_CODE === 'Dev')
    firebase.initializeApp(devConfig); // Change this to switch between different database enviroments 
  if(process.env.REACT_APP_SECRET_CODE === 'Stage')
    firebase.initializeApp(stageConfig); // Change this to switch between different database enviroments 
  if(process.env.REACT_APP_SECRET_CODE === 'Prod')
    firebase.initializeApp(prodConfig); // Change this to switch between different database enviroments     
}

const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions();
export {db, auth, storage, functions};
