/* @format - for Prettier */
import React from "react";
import styled from "styled-components";
import { Field } from "redux-form";
import { Grid, Row, Col } from "react-bootstrap";
import {
  renderField,
  renderState,
  renderWeekField,
  normalizePhone,
  required,
  number,
} from "../../../renderComponents";

const StyledGrid = styled(Grid)`
  padding: 0px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 7px !important;
`;

const StyledCol = styled(Col)`
  padding: 0px;
`;

const CustomerForm = ({ meta: { error, submitFailed } }) => (
  <StyledGrid>
    <StyledRow>
      <StyledCol sm={4} md={4}>
        <StyledRow>
          <h4>Customer Information</h4>
        </StyledRow>
        <StyledRow>
          <Field
            name="name"
            label="Name:"
            type="text"
            component={renderField}
            validate={[required]}
          />
        </StyledRow>
      </StyledCol>
      <StyledCol sm={4} md={4}>
        <StyledRow>
          <h4>Contact Information: </h4>
        </StyledRow>
        <StyledRow>
          <Field
            name="phone_number"
            label="Phone Number:"
            type="text"
            component={renderField}
            normalize={normalizePhone}
            //validate={[required]}
          />
        </StyledRow>
      </StyledCol>
    </StyledRow>
    <StyledRow>
      <StyledCol sm={4} md={4}>
        <StyledRow>
          <h4>Service Address:</h4>
        </StyledRow>
        <StyledRow>
          <Field
            name={`service_address.street`}
            type="text"
            component={renderField}
            label="Street:"
            validate={[required]}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name={`service_address.city`}
            type="text"
            component={renderField}
            label="City:"
            className="large"
            validate={[required]}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name={`service_address.state`}
            type="text"
            component={renderState}
            label="State:"
            className="large"
            validate={[required]}
          />
        </StyledRow>
        <StyledRow>
          <StyledCol sm={4} md={4}>
            <Field
              name={`service_address.zipcode`}
              type="text"
              component={renderField}
              label="ZIP:"
              validate={[required, number]}
              className="Zipcode"
            />
          </StyledCol>
        </StyledRow>
      </StyledCol>
      <StyledCol sm={4} md={4}>
        <StyledRow>
          <h4>Pickup Info:</h4>
        </StyledRow>
        <StyledRow>
          <Field
            name="pickup_day"
            type="text"
            component={renderWeekField}
            label="Pickup Day:"
            validate={[required]}
          />
        </StyledRow>
      </StyledCol>
    </StyledRow>
  </StyledGrid>
);

export default CustomerForm;
