/* @format - for Prettier */
import React from "react";
import ReactExport from "react-data-export";
import _ from "lodash";
import Select from "react-select";
import styled from "styled-components";
import { connect } from "react-redux";
import toastr from "toastr";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Table, Modal, Button, Glyphicon, ProgressBar } from "react-bootstrap";
import { Customers as CustomersActions } from "../../actions2";
import { monthOptions, quarterOptions, yearOptions } from '../../utils/index';
import { DeniedWrapper } from "../../styled";
import printHtmlElement from "print-html-element";
import worker from "workerize-loader!../NewCastle/worker"; // eslint-disable-line import/no-webpack-loader-syntax
import AccordionPaidCustomers from "./AccordionPaidCustomers";
import DatePicker from "react-datepicker";

import moment from "moment";
import PrintAllInvoices from "./PrintAllInvoices";
import { CustomerTypes } from "../../utils/customer";
import axiosInstance from "../../api/axios";
const axios = require("axios");
const pdfService = require("../../services/pdf").pdfService;

const CustomersWrapper = styled.div`
  width: 90%;
  max-width: 1168px;
  margin: 0 auto;
`;

const StyledSelect = styled.select`
  margin-bottom: 10px;
`;

const PrintProgress = styled(ProgressBar)`
  margin-top: 20px;
`;

const AccordionContainer = styled.div`
  margin: 20px 0px !important;
`;

const GlyphButton = styled(Button)`
  width: 100%;
  color: red !important;
  border: none;
`;

const GlyphTd = styled.td`
  width: 50px;
`;

const GlyphTh = styled.th`
  width: 50px;
`;

const DeleteButton = styled(Button)`
  float: right;
`;

const CloseButton = styled(Button)`
  float: left;
`;

const CloseModalButton = styled(Button)``;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  height: 40px;
  margin: 10px 0px 0px 10px;
  float: right;
`;

const ActionButton = styled(Button)`
  margin: 0px 0px 0px 10px;
  float: right;
`;

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`;

const Header = styled.div`
  display: flex;
`;

const CloseMoadlButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Search = styled.input`
  width: 200px;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid lightgrey;
`;

const ExportWrapper = styled.div`
  span {
    width: 100%;
  }
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Customers extends React.Component {
  state = {
    month_modal_show: false,
    dueDate: new Date(),
    print_month_modal_show: false,
    print_quarterly_modal_show: false,
    print_quarterly_einvoice_modal_show: false,
    print_month_einvoice_modal_show: false,
    spreadsheet_upload: false,
    selectedFile: null,
    show: false,
    customer: null,
    searchText: "",
    progress: 0,
    quarter_progress: 0,
    showBillAll: false,
    showBillAll1: false,
    progressing_show: false,
    quarter_progressing_show: false,
    modal_quarter: "",
    select_quarter: "",
    selected_month_customer: {},
    selected_year: {},
    selected_month: {},
    month_modal_quarter: "",
    month_select_quarter: "",
    quarter: "",
    eInvoicesForQuarterlyResults: [],
    eInvoicesForQuarterlyTotal: 0,
    quarterOptions: [
      {
        value: "1st Quarter",
        label: "1st Quarter",
      },
      {
        value: "2nd Quarter",
        label: "2nd Quarter",
      },
      {
        value: "3rd Quarter",
        label: "3rd Quarter",
      },
      {
        value: "4th Quarter",
        label: "4th Quarter",
      },
    ],
    serviceQuarterOptions: [
      {
        value: "JAN, FEB, MAR",
        label: "JAN, FEB, MAR",
      },
      {
        value: "APR, MAY, JUN",
        label: "APR, MAY, JUN",
      },
      {
        value: "JUL, AUG, SEP",
        label: "JUL, AUG, SEP",
      },
      {
        value: "OCT, NOV, DEC",
        label: "OCT, NOV, DEC",
      },
    ],
    monthOptions: [],
  };

  quarterChanged = (e) => {
    this.setState({
      modal_quarter: e.value,
      select_quarter: e,
    });
  };

  billForMonth = (customer) => {
    this.setState({
      month_modal_show: true,
      selected_month_customer: customer,
    });
  };

  spreadsheetUpload = () => {
    this.setState({
      spreadsheet_upload: true,
    });
  };

  handleSpreadsheetUploadClose = () => {
    this.setState({
      spreadsheet_upload: false,
    });
  };

  // On file select (from the pop up)
  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  // On file upload (from the pop up)
  onFileUpload = (event) => {
    this.setState({
      spreadsheet_upload: false,
    });
    this.props.updateCustomerPrices(this.state.selectedFile);
  };

  handleBillMonth = () => {
    this.setState({
      month_modal_show: false,
    });
    this.props.billMonthCommercialCustomer(
      this.state.selected_month_customer,
      this.state.selected_month,
      this.state.selected_year
    );
  };

  monthChanged = () => {
    this.setState({
      month_modal_quarter: "", //e.value
      month_select_quarter: "", //e
    });
  };

  handleBillFilter = () => {
    const modal_quarter = this.state.modal_quarter;
    this.setState({
      showBillAll: false,
      quarter: modal_quarter,
    });
    this.props.billAllCommercialCustomers(this.state.selected_month, this.state.selected_year);
  };

  handleBillFilter1 = () => {
    const modal_quarter = this.state.modal_quarter;
    this.setState({
      showBillAll1: false,
      quarter: modal_quarter,
    });
    this.props.billAllRecuringCustomers(this.state.selected_month, this.state.selected_year);
  };

  componentDidMount() {
    this.props.getCustomers();
    this.worker = worker();
    this.worker.addEventListener("message", this.onMessage);
    let mapping_months = [
      "",
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    let months = [];
    for (let i = 1; i <= 12; i += 1) {
      months.push({
        value: mapping_months[i],
        label: mapping_months[i],
      });
    }
    this.setState({
      monthOptions: months,
      searchText: localStorage.getItem("remember_customer_search_key"),
    });
    const inputEl = document.getElementById("search-input");
    if(inputEl)
      inputEl.value = localStorage.getItem(
        "remember_customer_search_key"
      ) ||'';
  }

  componentWillUnmount() {
    this.worker.removeEventListener("message", this.onMessage);
  }

  handleShow = (customer) => {
    this.setState({ show: true, customer });
  };

  printForQuarterly = (customer) => {
    this.setState({
      print_quarterly_modal_show: true,
    });
  };

  handleQuarterlyPrint = async () => {
    this.setState({
      print_quarterly_modal_show: false,
    });
    const quarterlyCustomers = _.filter(this.props.customers, (customer) => {
      if (customer["billFrequency"] === "monthly") return false;
      if (customer["isExempt"] === "Yes") return false;
      if (customer['isInactive'] === 'Yes') return false;
      if (customer["recurringCustomer"] === "Yes") return false;
      if (customer["wantsEInvoice"] === "Yes") return false;
      return true;
    });
    console.log("quarterlyCustomers", quarterlyCustomers);
    pdfService.commercial_customer_invoice_pdf_download(
      quarterlyCustomers,
      "all quarterly",
      this.state.dueDate,
      "",
      "",
      this.state.select_quarter.value,
      "",
      "",
    );
  };

  printForMonth = (customer) => {
    this.setState({
      print_month_modal_show: true,
      selected_month_customer: customer,
    });
  };

  handlePrintMonth = async () => {
    this.setState({
      print_month_modal_show: false,
    });
    pdfService.commercial_customer_invoice_pdf_download(
      [this.state.selected_month_customer],
      "montly",
      this.state.dueDate,
      "",
      "",
      this.state.month_modal_quarter,
      "",
      "",

    );
  };

  timer = (ms) => {
    return new Promise((res) => setTimeout(res, ms));
  };

  printForQuarterlyEinvoice = (customer) => {
    this.setState({
      print_quarterly_einvoice_modal_show: true,
    });
  };

  eInvoicesForQuarterlyWithEInvoice = async () => {
    this.setState({
      print_quarterly_einvoice_modal_show: false,
    });
    const eInvoicesForQuarterlyWithEInvoice = _.filter(
      this.props.customers,
      (customer) => {
        if (customer["billFrequency"] === "monthly") return false;
        if (customer["isExempt"] === "Yes") return false;
        if (customer["recurringCustomer"] === "Yes") return false;
        if (
          customer["wantsEInvoice"] === undefined ||
          customer["wantsEInvoice"] === "No"
        )
          return false;
        return true;
      }
    );

    this.setState({
      quarter_progressing_show: true,
      quarter_progress: 0,
    });

    const Length = eInvoicesForQuarterlyWithEInvoice.length;
    let eInvoicesForQuarterlyResults = [];
    this.setState({
      eInvoicesForQuarterlyTotal: Length,
    });
    for (let i = 0; i < Length; i += 1) {
      await this.timer(200);
      const customer = eInvoicesForQuarterlyWithEInvoice[i];
      const base64 = await pdfService.commercial_customer_invoice_pdf_download(
        [customer],
        "base64",
        this.state.dueDate,
        "",
        "",
        this.state.select_quarter.value,
        "",
        "",
  
      );
      let sender_email = customer.email;
      if (process.env.REACT_APP_ENVIRONMENT === "development")
        sender_email = "afebbraro@atsapllc.com";
      let data = {
        index: i,
        email: sender_email,
        content: base64,
      };
      axiosInstance.post('/api/customers/send_eInvoiceMail', {
        ...data
      })
        .then((res) => {
          eInvoicesForQuarterlyResults.push({
            customer: eInvoicesForQuarterlyWithEInvoice[res.data.id],
            success: res.data.success,
          });
        })
        .catch((err) => {
          eInvoicesForQuarterlyResults.push({
            customer: customer,
            success: 0,
          });
        })
        .finally(() => {
          this.setState({
            eInvoicesForQuarterlyResults: eInvoicesForQuarterlyResults,
            quarter_progress: parseInt(
              (eInvoicesForQuarterlyResults.length * 100) / Length
            ),
          });
          if (eInvoicesForQuarterlyResults.length === Length)
            toastr.success("Successfully Sent E-mails.", "Success");
        });
    }
  };

  closeQuarterlyEmailModal = () => {
    this.setState({
      quarter_progressing_show: false,
      quarter_progress: 0,
      eInvoicesForQuarterlyResults: [],
    });
  };

  printEInvoiceMonth = (customer) => {
    this.setState({
      print_month_einvoice_modal_show: true,
      selected_month_customer: customer,
    });
  };

  eInvoiceForMonth = async () => {
    this.setState({
      print_month_einvoice_modal_show: false,
    });
    const customer = this.state.selected_month_customer;
    this.setState({
      quarter_progressing_show: true,
      quarter_progress: 0,
    });
    const base64 = await pdfService.commercial_customer_invoice_pdf_download(
      [customer],
      "base64",
      this.state.dueDate,
      "",
      "",
      this.state.month_modal_quarter,
      "",
      "",

    );
    this.setState({
      quarter_progress: 50,
    });
    let sender_email = customer.email;
    if (process.env.REACT_APP_ENVIRONMENT === "development")
      sender_email = "afebbraro@atsapllc.com";
    let data = {
      index: 0,
      email: sender_email,
      content: base64,
    };
    console.log("e-invoice: ",data);
    axiosInstance.post('/api/customers/send_eInvoiceMail', {
      ...data
    })
      .then((res) => {
        if (res.data.success)
          toastr.success("Successfully Sent E-mail.", "Success");
        else
          toastr.error("Unexpected Error Happend!. Please try again", "Error");
      })
      .catch((err) => {
        toastr.error("Unexpected Error Happend!. Please try again", "Error");
      })
      .finally(() => {
        this.setState({
          quarter_progress: 100,
        });
        setTimeout(() => {
          this.setState({
            quarter_progressing_show: false,
          });
        }, 1000);
      });
  };

  onMessage = (message) => {
    if (message.data.done) {
      this.onProcessComplete();
    }
    if (message.data.file) {
      this.setState({
        progress: message.data.progress,
      });
      this.onPdfGenerate(message.data.file, message.data.batch);
    }
  };

  onProcessComplete = () => {
    setTimeout(() => {
      this.setState({
        progress: 0,
        progressing_show: false,
      });
    }, 2000);
  };

  onPdfGenerate = (blob, batch) => {
    const data = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = data;
    link.download = `commercial_${moment().format("MMDDYYYY")}_${batch}.pdf`;
    link.click();
    link.remove();
  };

  billAllHandle = () => {
    this.setState({
      showBillAll: true,
    });
  };

  billAllHandle1 = () => {
    this.setState({
      showBillAll1: true,
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleRemove = () => {
    this.props.removeCustomer(this.state.customer.uid);
    this.setState({ show: false });
  };

  handleSearchInput = (evt) => {
    this.setState({ searchText: evt.target.value.toLowerCase() });
    localStorage.setItem(
      "remember_customer_search_key",
      evt.target.value.toLowerCase()
    );
  };

  handlePrint = () => {
    var element = document.getElementById("print");
    printHtmlElement.printElement(element, { pageTitle: "Customers" });
  };

  filterResult = (customer, searchText) => {
    try {
      if (
        customer["Account Number"] &&
        customer["Account Number"].toLowerCase().includes(searchText)
      )
        return true;
      if (customer.name && customer.name.toLowerCase().includes(searchText))
        return true;
      if (customer.rate && customer.rate.toLowerCase().includes(searchText))
        return true;
      if (customer.status && customer.status.toLowerCase().includes(searchText))
        return true;
      if (
        customer.contact_name &&
        customer.contact_name.toLowerCase().includes(searchText)
      )
        return true;
      if (customer.email && customer.email.toLowerCase().includes(searchText))
        return true;
      if (
        customer.billing_address.city &&
        customer.billing_address.city.toLowerCase().includes(searchText)
      )
        return true;
      if (
        customer.billing_address.street &&
        customer.billing_address.street.toLowerCase().includes(searchText)
      )
        return true;
      if (
        customer.billing_address.zipcode &&
        customer.billing_address.zipcode.includes(searchText)
      )
        return true;
      if (
        customer.credit_card &&
        customer.credit_card.number.includes(searchText)
      )
        return true;
      if (
        customer.credit_card &&
        customer.credit_card.expiry.includes(searchText)
      )
        return true;
      if (customer.credit_card && customer.credit_card.cvc.includes(searchText))
        return true;
      if (
        customer.credit_card_name &&
        customer.credit_card_name.toLowerCase().includes(searchText)
      )
        return true;
      // if (customer.notes && customer.notes.toLowerCase().includes(searchText)) return true;
      let result = false;
      if (customer.phone_numbers) {
        _.map(customer.phone_numbers, (phone_number, index) => {
          if (!phone_number.type && phone_number.number) {
            if (phone_number.number.includes(searchText)) {
              result = true;
              return;
            }
          } else if (!phone_number.type && !phone_number.number) {
            if (customer.phone_numbers[index].includes(searchText)) {
              result = true;
              return;
            }
          } else {
            if (
              phone_number.number &&
              phone_number.number.includes(searchText)
            ) {
              result = true;
              return;
            }
          }
        });
      }
      if (customer.dumpster_addresses) {
        _.map(customer.dumpster_addresses, (dumpster_address, index) => {
          if (
            dumpster_address.city &&
            dumpster_address.city.toLowerCase().includes(searchText)
          ) {
            result = true;
            return;
          }
          if (
            dumpster_address.street &&
            dumpster_address.street.toLowerCase().includes(searchText)
          ) {
            result = true;
            return;
          }
          if (
            dumpster_address.zipcode &&
            dumpster_address.zipcode.includes(searchText)
          ) {
            result = true;
            return;
          }
        });
      }
      if (customer.dumpsters) {
        _.map(customer.dumpsters, (dumpster, index) => {
          if (dumpster.type && dumpster.type.includes(searchText)) {
            result = true;
            return;
          }
          if (dumpster.frequency && dumpster.frequency.includes(searchText)) {
            result = true;
            return;
          }
        });
      }
      return result;
    } catch (error) {
      return false;
    }
  };

  customerFun = () => {
    const customer = this.props.customers;
    customer.forEach((item) => {
      var z = "";
      var b = "";
  
      // Handle phone numbers
      if (item.phone_numbers) {
        item.phone_numbers.forEach((elem) => {
          z += elem.number + ",";
        });
        item.phoneNumber = z.replace(/,\s*$/, "");
      }
  
      // Handle billing addresses
      if (item.billing_address) {
        // Concatenate the address parts into a single string
        b = [
          item.billing_address.street,
          item.billing_address.city,
          item.billing_address.state,
          item.billing_address.zipcode
        ].filter(part => part).join(", "); // Filters out any undefined or null parts
        item.BillingAddress = b;
      }
    });
  
    return customer; // Ensure you return the modified customer array
  };

  render() {
    const { searchText } = this.state;
    const filteredCustomers = _.filter(this.props.customers, (customer) =>
      this.filterResult(customer, searchText.toLocaleLowerCase())
    );
    const quarterlyCustomers = _.filter(this.props.customers, (customer) => {
      if (customer["billFrequency"] === "monthly") return false;
      if (customer["isExempt"] === "Yes") return false;
      if (customer['isInactive'] === 'Yes') return false;
      if (customer["recurringCustomer"] === "Yes") return false;

      return true;
    });
    const montlyCustomers = _.filter(this.props.customers, (customer) => {
      if (customer["isExempt"] === "Yes") return false;
      if (customer['isInactive'] === 'Yes') return false;
      if (customer["recurringCustomer"] === "Yes") return false;
      if (customer["billFrequency"] === "monthly") return true;
      return false;
    });
    const exemptCustomers = _.filter(this.props.customers, (customer) => {
      if (customer["isExempt"] === "Yes") return true;
      return false;
    });

    const InactiveCustomers = _.filter(this.props.customers, (customer) => {
      if (customer["isInactive"] === "Yes") return true;
      return false;
    });

    const recurringCustomers = _.filter(this.props.customers, (customer) => {
      if (customer["recurringCustomer"] === "Yes") return true;
      return false;
    });

    // console.log("customer", this.props.customers);
    // console.log("gh", recurringCustomers);
    if (this.props.authorized) {
      return (
        <CustomersWrapper>
          <label>Number of Customers: {this.props.customers.length}</label>
          <Header>
            <div style={{ margin: "10px 0px", display: "flex" }}>
              <label
                htmlFor="search-input"
                style={{ marginRight: "5px", paddingTop: "10px" }}
              >
                Search:{" "}
              </label>
              <Search id="search-input" onBlur={this.handleSearchInput} />
              <Button style={{ marginLeft: 10 }} bsStyle="primary">
                <AddGlyph glyph="search" />
                Search
              </Button>
            </div>
            <div style={{ width: "100%" }}>
              <ExportWrapper>
                <ExcelFile
                  filename="COM Customers Contact Info"
                  element={
                    <ToolButton bsStyle="primary">
                      Export Contact Info
                    </ToolButton>
                  }
                >
                  <ExcelSheet
                    data={() => this.customerFun()}
                    name="COM Customers Contact Info"
                  >
                    <ExcelColumn
                      label="Account Number"
                      value="Account Number"
                    />
                    <ExcelColumn label="Name" value="name" />
                    <ExcelColumn label="Email" value="email" />
                    <ExcelColumn label="Phone" value="phoneNumber" />
                    <ExcelColumn label="Rate" value="rate" />
                    <ExcelColumn label="BillingAddress" value="BillingAddress" />
                  </ExcelSheet>
                </ExcelFile>
              </ExportWrapper>
              <ToolButton onClick={this.props.addCustomer} bsStyle="primary">
                <AddGlyph glyph="plus" />
                Add Customer
              </ToolButton>
              <ToolButton onClick={this.handlePrint} bsStyle="primary">
                <AddGlyph glyph="print" />
                Print
              </ToolButton>
              <ToolButton onClick={this.spreadsheetUpload} bsStyle="primary">
                <AddGlyph glyph="pencil" />
                Update Customer Prices
              </ToolButton>
            </div>
          </Header>

          <AccordionPaidCustomers
            type={CustomerTypes.commercial}
            isCommercial={true}
            setCustomer={this.props.setCustomer}
          />
          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>Quarterly Customers</label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Header>
                    <div style={{ width: "100%", display: 'flex', justifyContent: 'flex-end' }}>
                       <ToolButton
                        onClick={this.billAllHandle}
                        bsStyle="primary"
                      >
                        Bill All For Quarter
                      </ToolButton>
                     <PrintAllInvoices customers={this.props.customers} quarter={this.state.select_quarter.value}/>
                      <ToolButton
                        onClick={this.printForQuarterlyEinvoice}
                        bsStyle="primary"
                      >
                        Send E-Invoices
                      </ToolButton>
                    </div>
                  </Header>
                  <div style={{ width: "100%" }}>
                    <label className="mb-2">
                      Number of Quarterly Customers: {quarterlyCustomers.length}
                    </label>
                    <Table responsive hover bordered id="print">
                      <thead>
                        <tr>
                          <th>Account Number</th>
                          <th>Customer Name</th>
                          <th>Email Address</th>
                          <th>E-Invoice</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(quarterlyCustomers, (customer) => {
                          if (customer.uid !== undefined) {
                            return (
                              <tr
                                key={customer.uid}
                                onClick={() =>
                                  this.props.setCustomer(customer.uid)
                                }
                              >
                                <td>
                                  <div>{customer["Account Number"]}</div>
                                </td>
                                <td>
                                  <div>{customer.name}</div>
                                </td>
                                <td>
                                  <div>{customer.email}</div>
                                </td>
                                <td>
                                  <div>
                                    {customer["wantsEInvoice"] === undefined
                                      ? "No"
                                      : customer["wantsEInvoice"]}
                                  </div>
                                </td>
                                <td>
                                  <div>{customer.status}</div>
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </Table>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>Monthly Customers</label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <label className="mb-2">
                    Number of Monthly Customers: {montlyCustomers.length}
                  </label>
                  <Table responsive hover bordered id="print">
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer Name</th>
                        <th>Email Address</th>
                        <th>E-Invoice</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(montlyCustomers, (customer) => {
                        if (customer.uid !== undefined) {
                          return (
                            <tr key={customer.uid}>
                              <td
                                onClick={() =>
                                  this.props.setCustomer(customer.uid)
                                }
                              >
                                <div>{customer["Account Number"]}</div>
                              </td>
                              <td
                                onClick={() =>
                                  this.props.setCustomer(customer.uid)
                                }
                              >
                                <div>{customer.name}</div>
                              </td>
                              <td
                                onClick={() =>
                                  this.props.setCustomer(customer.uid)
                                }
                              >
                                <div>{customer.email}</div>
                              </td>
                              <td
                                onClick={() =>
                                  this.props.setCustomer(customer.uid)
                                }
                              >
                                <div>
                                  {customer["wantsEInvoice"] === undefined
                                    ? "No"
                                    : customer["wantsEInvoice"]}
                                </div>
                              </td>
                              <td
                                onClick={() =>
                                  this.props.setCustomer(customer.uid)
                                }
                              >
                                <div>{customer.status}</div>
                              </td>
                              <td>
                                <ActionButton
                                  onClick={() =>
                                    this.printEInvoiceMonth(customer)
                                  }
                                  bsStyle="primary"
                                >
                                  Send E-Invoice
                                </ActionButton>
                                <ActionButton
                                  onClick={() => this.printForMonth(customer)}
                                  bsStyle="primary"
                                >
                                  Print
                                </ActionButton>
                                <ActionButton
                                  onClick={() => this.billForMonth(customer)}
                                  bsStyle="primary"
                                >
                                  Bill For Month
                                </ActionButton>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>Exempt Customers</label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <label className="mb-2">
                    Number of Exempt Customers: {exemptCustomers.length}
                  </label>
                  <Table responsive hover bordered id="print">
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer Name</th>
                        <th>Email Address</th>
                        <th>E-Invoice</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(exemptCustomers, (customer) => {
                        if (customer.uid !== undefined) {
                          return (
                            <tr
                              key={customer.uid}
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <td>
                                <div>{customer["Account Number"]}</div>
                              </td>
                              <td>
                                <div>{customer.name}</div>
                              </td>
                              <td>
                                <div>{customer.email}</div>
                              </td>
                              <td>
                                <div>
                                  {customer["wantsEInvoice"] === undefined
                                    ? "No"
                                    : customer["wantsEInvoice"]}
                                </div>
                              </td>
                              <td>
                                <div>{customer.status}</div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>

          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>Inactive Customers</label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <label className="mb-2">
                    Number of Exempt Customers: {InactiveCustomers.length}
                  </label>
                  <Table responsive hover bordered id="print">
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer Name</th>
                        <th>Email Address</th>
                        <th>E-Invoice</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(InactiveCustomers, (customer) => {
                        if (customer.uid !== undefined) {
                          return (
                            <tr
                              key={customer.uid}
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <td>
                                <div>{customer["Account Number"]}</div>
                              </td>
                              <td>
                                <div>{customer.name}</div>
                              </td>
                              <td>
                                <div>{customer.email}</div>
                              </td>
                              <td>
                                <div>
                                  {customer["wantsEInvoice"] === undefined
                                    ? "No"
                                    : customer["wantsEInvoice"]}
                                </div>
                              </td>
                              <td>
                                <div>{customer.status}</div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>

          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>Recurring Customers</label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Header>
                    <div style={{ width: "100%" }}>
                      <ToolButton
                        onClick={this.billAllHandle1}
                        bsStyle="primary"
                      >
                        Bill All For Quarter
                      </ToolButton>
                    </div>
                  </Header>
                  <label className="mb-2">
                    Number of Recurring Customers: {recurringCustomers.length}
                  </label>
                  <Table responsive hover bordered id="print">
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer Name</th>
                        <th>Email Address</th>
                        <th>E-Invoice</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(recurringCustomers, (customer) => {
                        if (customer.uid !== undefined) {
                          return (
                            <tr
                              key={customer.uid}
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <td>
                                <div>{customer["Account Number"]}</div>
                              </td>
                              <td>
                                <div>{customer.name}</div>
                              </td>
                              <td>
                                <div>{customer.email}</div>
                              </td>
                              <td>
                                <div>
                                  {customer["wantsEInvoice"] === undefined
                                    ? "No"
                                    : customer["wantsEInvoice"]}
                                </div>
                              </td>
                              <td>
                                <div>{customer.status}</div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          <Table responsive hover bordered id="print">
            <thead>
              <tr>
                <th>Account Number</th>
                <th>Customer</th>
                <th>Email Address</th>
                <th width="200px">Phone Number(s)</th>
                <th>Dumpster Address</th>
                <th>Outstanding Balance</th>
                <th>Status</th>
                <GlyphTh className="hidden-print">Delete</GlyphTh>
              </tr>
            </thead>
            <tbody>
              {_.map(filteredCustomers, (customer) => {
                if (customer.uid !== undefined) {
                  return (
                    <tr key={customer.uid}>
                      <td onClick={() => this.props.setCustomer(customer.uid)}>
                        <div>{customer["Account Number"]}</div>
                      </td>
                      <td onClick={() => this.props.setCustomer(customer.uid)}>
                        <div>{customer.name}</div>
                      </td>
                      <td onClick={() => this.props.setCustomer(customer.uid)}>
                        <div>{customer.email}</div>
                      </td>
                      <td
                        className="phone-number"
                        onClick={() => this.props.setCustomer(customer.uid)}
                      >
                        <div>
                          {_.map(
                            customer.phone_numbers,
                            (phone_number, index) => {
                              if (!phone_number.type && phone_number.number) {
                                return (
                                  <li key={index}>
                                    {`Phone: ${phone_number.number}`}
                                  </li>
                                );
                              } else if (
                                !phone_number.type &&
                                !phone_number.number
                              ) {
                                return (
                                  <li key={index}>
                                    {`Phone: ${customer.phone_numbers[index]}`}
                                  </li>
                                );
                              } else {
                                return (
                                  <li key={index}>
                                    {`${phone_number.type}: ${phone_number.number}`}
                                  </li>
                                );
                              }
                            }
                          )}
                        </div>
                      </td>
                      <td
                        className="dumpster-address"
                        onClick={() => this.props.setCustomer(customer.uid)}
                      >
                        <div>
                          {customer.dumpster_addresses &&
                          customer.dumpster_addresses.length > 1
                            ? "Multiple"
                            : _.map(
                                customer.dumpster_addresses,
                                (dumpster_address, index) => {
                                  if (dumpster_address.street) {
                                    return (
                                      <div key={index}>
                                        {`${dumpster_address.street} ${dumpster_address.city}, ${dumpster_address.state} ${dumpster_address.zipcode}`}
                                      </div>
                                    );
                                  }
                                }
                              )}
                        </div>
                      </td>
                      <td>
                        { customer.amount_due < 0 &&
                          <div className={'success'}>(${parseFloat(customer.amount_due)?.toFixed(2)})</div>
                        }
                        { customer.amount_due > 0 &&
                          <div className={'error'}>${parseFloat(customer.amount_due)?.toFixed(2)}</div>
                        }
                        { customer.amount_due === '0' &&
                          <div>${parseFloat(customer.amount_due)?.toFixed(2)}</div>
                        }
                      </td>
                      <td onClick={() => this.props.setCustomer(customer.uid)}>
                        <div>{customer.status}</div>
                      </td>
                      <GlyphTd className="hidden-print">
                        <GlyphButton onClick={() => this.handleShow(customer)}>
                          <Glyphicon glyph="remove" />
                        </GlyphButton>
                      </GlyphTd>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <Modal
            bsSize="small"
            show={this.state.show}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <span>Delete </span>
                <span>
                  {this.state.customer ? this.state.customer.name : " "}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                Are you sure you want to delete this customer and all of their
                Stops?
              </div>
            </Modal.Body>
            <Modal.Footer>
              <CloseButton onClick={this.handleClose}>Close</CloseButton>
              <DeleteButton bsStyle="danger" onClick={this.handleRemove}>
                Delete
              </DeleteButton>
            </Modal.Footer>
          </Modal>

          {/* Print For Month */}
          <Modal show={this.state.print_month_modal_show}>
            <Modal.Body>
               {/* <h2>Choose a Service Month</h2>
               <div className="form-group" style={{marginTop:20}}>
                    <label>Select Month & Year For Records</label>
                    <br />
                    
                    <StyledSelect onChange={(month) => {this.setState({selected_month: month.target.value})}}>
                      <option value={false}>
                        Select Month
                      </option>

                      {monthOptions.map((monthOption) => (
                        <option
                          title="Print Monthly Invoice"
                          key={monthOption.value}
                          value={monthOption.value}
                        >
                          {monthOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                    <StyledSelect onChange={(year) => {this.setState({selected_year: year.target.value})}}>
                      <option value={false}>
                        Select Year
                      </option>

                      {yearOptions.map((yearOption) => (
                        <option
                          key={yearOption.value}
                          value={yearOption.value}
                        >
                          {yearOption.label}
                        </option>
                      ))}
                    </StyledSelect>
              </div> */}
              <div className="form-group" style={{marginTop:20}}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker 
                  selected={this.state.dueDate}
                  onChange={(date) => {this.setState({dueDate: date})}}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.month_modal_quarter.length}
                bsStyle="primary"
                onClick={this.handlePrintMonth}
                id="printBtn"
              >
                Print
              </ToolButton>
            </Modal.Footer>
          </Modal>

          {/* Print For Month E-Invoice*/}
          <Modal show={this.state.print_month_einvoice_modal_show}>
            <Modal.Body>
               <h2>Send E-Invoice</h2>
              {/* <Select
                options={this.state.monthOptions}
                value={this.state.month_select_quarter}
                onChange={this.monthChanged}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              /> */}

              {/* <div className="form-group" style={{marginTop:20}}>
                    <label>Select Month & Year For Records</label>
                    <br />
                    
                    <StyledSelect onChange={(month) => {this.setState({selected_month: month.target.value})}}>
                      <option value={false}>
                        Select Month
                      </option>

                      {monthOptions.map((monthOption) => (
                        <option
                          title="Print Monthly Invoice"
                          key={monthOption.value}
                          value={monthOption.value}
                        >
                          {monthOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                    <StyledSelect onChange={(year) => {this.setState({selected_year: year.target.value})}}>
                      <option value={false}>
                        Select Year
                      </option>

                      {yearOptions.map((yearOption) => (
                        <option
                          key={yearOption.value}
                          value={yearOption.value}
                        >
                          {yearOption.label}
                        </option>
                      ))}
                    </StyledSelect>
              </div> */}
              <div className="form-group" style={{marginTop:20}}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker 
                  selected={this.state.dueDate}
                  onChange={(date) => {this.setState({dueDate: date})}}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.month_modal_quarter.length}
                bsStyle="primary"
                onClick={this.eInvoiceForMonth}
                id="printBtn"
              >
                Send E-Invoice
              </ToolButton>
            </Modal.Footer>
          </Modal>

          {/* Print For Quarterly E-Invoice */}
          <Modal show={this.state.print_quarterly_einvoice_modal_show}>
            <Modal.Body>
                <h2>Send E-Invoices</h2>
              {/* <Select
                options={this.state.serviceQuarterOptions}
                value={this.state.select_quarter}
                onChange={this.quarterChanged}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              /> */}

                {/* <div className="form-group" style={{marginTop:20}}>
                    <label>Select Quarter & Year For Records</label>
                    <br />
                    
                    <StyledSelect onChange={(month) => {this.setState({selected_month: month.target.value})}}>
                      <option value={false}>
                        Select Quarter
                      </option>

                      {quarterOptions.map((quarterOption) => (
                        <option
                          title="Bill All for Quarter"
                          key={quarterOption.value}
                          value={quarterOption.value}
                        >
                          {quarterOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                    <StyledSelect onChange={(year) => {this.setState({selected_year: year.target.value})}}>
                      <option value={false}>
                        Select Year
                      </option>

                      {yearOptions.map((yearOption) => (
                        <option
                          key={yearOption.value}
                          value={yearOption.value}
                        >
                          {yearOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                </div> */}

              <div className="form-group" style={{marginTop:20}}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker 
                  selected={this.state.dueDate}
                  onChange={(date) => {this.setState({dueDate: date})}}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.modal_quarter.length}
                bsStyle="primary"
                onClick={this.eInvoicesForQuarterlyWithEInvoice}
                id="printBtn"
              >
                Send E-Invoices
              </ToolButton>
            </Modal.Footer>
          </Modal>

          {/* Print For Quarterly */}
          <Modal show={this.state.print_quarterly_modal_show}>
            <Modal.Body>
                {/* <h2>Choose a Service Quarter</h2> */}
              {/* <Select
                options={this.state.serviceQuarterOptions}
                value={this.state.select_quarter}
                onChange={this.quarterChanged}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              /> */}

                {/* <div className="form-group" style={{marginTop:20}}>
                    <label>Select Quarter & Year For Records</label>
                    <br />
                    
                    <StyledSelect onChange={(month) => {this.setState({selected_month: month.target.value})}}>
                      <option value={false}>
                        Select Quarter
                      </option>

                      {quarterOptions.map((quarterOption) => (
                        <option
                          title="Bill All for Quarter"
                          key={quarterOption.value}
                          value={quarterOption.value}
                        >
                          {quarterOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                    <StyledSelect onChange={(year) => {this.setState({selected_year: year.target.value})}}>
                      <option value={false}>
                        Select Year
                      </option>

                      {yearOptions.map((yearOption) => (
                        <option
                          key={yearOption.value}
                          value={yearOption.value}
                        >
                          {yearOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                </div> */}
              <div className="form-group" style={{marginTop:20}}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker 
                  selected={this.state.dueDate}
                  onChange={(date) => {this.setState({dueDate: date})}}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.modal_quarter.length}
                bsStyle="primary"
                onClick={this.handleQuarterlyPrint}
                id="printBtn"
              >
                Print All Invoices
              </ToolButton>
            </Modal.Footer>
          </Modal>

          {/* Bill For Month */}
          <Modal show={this.state.month_modal_show}>
            <Modal.Body>
                <h2>Bill for Month</h2>
              {/* <Select
                options={this.state.monthOptions}
                value={this.state.month_select_quarter}
                onChange={this.monthChanged}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              /> */}

              <div className="form-group" style={{marginTop:20}}>
                    <label>Select Month & Year For Records</label>
                    <br />
                    
                    <StyledSelect onChange={(month) => {this.setState({selected_month: month.target.value})}}>
                      <option value={false}>
                        Select Month
                      </option>

                      {monthOptions.map((monthOption) => (
                        <option
                          title="Print Monthly Invoice"
                          key={monthOption.value}
                          value={monthOption.value}
                        >
                          {monthOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                    <StyledSelect onChange={(year) => {this.setState({selected_year: year.target.value})}}>
                      <option value={false}>
                        Select Year
                      </option>

                      {yearOptions.map((yearOption) => (
                        <option
                          key={yearOption.value}
                          value={yearOption.value}
                        >
                          {yearOption.label}
                        </option>
                      ))}
                    </StyledSelect>
              </div>

              {/* <div className="form-group" style={{marginTop:20}}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker 
                  selected={this.state.dueDate}
                  onChange={(date) => {this.setState({dueDate: date})}}
                  required
                />
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.month_modal_quarter.length}
                bsStyle="primary"
                onClick={this.handleBillMonth}
                id="printBtn"
              >
                Bill
              </ToolButton>
            </Modal.Footer>
          </Modal>

          {/* Bill All For Quarter */}
          <Modal show={this.state.showBillAll} onHide={this.handleBillClose}>
            <Modal.Body>
               <h2>Bill All for Quarter</h2>
              {/* <Select
                options={this.state.quarterOptions}
                value={this.state.select_quarter}
                onChange={this.quarterChanged}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              /> */}

                <div className="form-group" style={{marginTop:20}}>
                    <label>Select Quarter & Year For Records</label>
                    <br />
                    
                    <StyledSelect onChange={(month) => {this.setState({selected_month: month.target.value})}}>
                      <option value={false}>
                        Select Quarter
                      </option>

                      {quarterOptions.map((quarterOption) => (
                        <option
                          title="Bill All for Quarter"
                          key={quarterOption.value}
                          value={quarterOption.value}
                        >
                          {quarterOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                    <StyledSelect onChange={(year) => {this.setState({selected_year: year.target.value})}}>
                      <option value={false}>
                        Select Year
                      </option>

                      {yearOptions.map((yearOption) => (
                        <option
                          key={yearOption.value}
                          value={yearOption.value}
                        >
                          {yearOption.label}
                        </option>
                      ))}
                    </StyledSelect>
              </div>
              {/* <div className="form-group" style={{marginTop:20}}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker 
                  selected={this.state.dueDate}
                  onChange={(date) => {this.setState({dueDate: date})}}
                  required
                />
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.modal_quarter.length}
                bsStyle="primary"
                onClick={this.handleBillFilter}
                id="printBtn"
              >
                Bill
              </ToolButton>
            </Modal.Footer>
          </Modal>
          
          {/* Bill All1 For Quarter */}
          <Modal show={this.state.showBillAll1} onHide={this.handleBillClose}>
            <Modal.Body>
               <h2>Bill All for Quarter</h2>
              {/* <Select
                options={this.state.quarterOptions}
                value={this.state.select_quarter}
                onChange={this.quarterChanged}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              /> */}

                <div className="form-group" style={{marginTop:20}}>
                    <label>Select Quarter & Year For Records</label>
                    <br />
                    
                    <StyledSelect onChange={(month) => {this.setState({selected_month: month.target.value})}}>
                      <option value={false}>
                        Select Quarter
                      </option>

                      {quarterOptions.map((quarterOption) => (
                        <option
                          title="Bill All for Quarter"
                          key={quarterOption.value}
                          value={quarterOption.value}
                        >
                          {quarterOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                    <StyledSelect onChange={(year) => {this.setState({selected_year: year.target.value})}}>
                      <option value={false}>
                        Select Year
                      </option>

                      {yearOptions.map((yearOption) => (
                        <option
                          key={yearOption.value}
                          value={yearOption.value}
                        >
                          {yearOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                </div>

              {/* <div className="form-group" style={{marginTop:20}}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker 
                  selected={this.state.dueDate}
                  onChange={(date) => {this.setState({dueDate: date})}}
                  required
                />
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.modal_quarter.length}
                bsStyle="primary"
                onClick={this.handleBillFilter1}
                id="printBtn"
              >
                Bill
              </ToolButton>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.spreadsheet_upload}
            onHide={this.handleSpreadsheetUploadClose}
          >
            <Modal.Body>
              <h3>Spreadsheet Upload</h3>
              <p>
                Please upload a spreadsheet containing the customer account
                numbers and new prices.
              </p>
              <br />
              <div>
                <input
                  type="file"
                  onChange={this.onFileChange}
                  accept=".xlsx"
                />
                <br />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                disabled={!this.state.selectedFile}
                bsStyle="primary"
                onClick={this.onFileUpload}
                id="printBtn"
              >
                Update Customer Prices
              </ToolButton>
            </Modal.Footer>
          </Modal>

          {/* Progressing Modal For Quarter*/}
          <Modal show={this.state.quarter_progressing_show}>
            <Modal.Body>
              <>
                <h3>Sending Email...</h3>
                <p>This may take some time.</p>
              </>
              <PrintProgress
                active
                striped
                now={this.state.quarter_progress}
                label={`${this.state.quarter_progress}%`}
              />
              {this.state.quarter_progress === 100 && (
                <div className="mt-2">
                  <label className="mb-2">
                    Number Of E-Invoices Customers:{" "}
                    {this.state.eInvoicesForQuarterlyTotal}
                  </label>
                  <Table responsive hover bordered id="print">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Account Number</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.eInvoicesForQuarterlyResults.map(
                        (item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.customer["Account Number"]}</td>
                              <td>
                                {item.success === 1 ? "Success" : "Failed"}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                  <CloseMoadlButtonContainer>
                    <CloseModalButton
                      onClick={this.closeQuarterlyEmailModal}
                      bsStyle="primary"
                    >
                      Close
                    </CloseModalButton>
                  </CloseMoadlButtonContainer>
                </div>
              )}
            </Modal.Body>
          </Modal>
          {/* Progressing Modal */}
          <Modal
            show={this.state.progressing_show}
            onHide={this.state.exporting ? () => {} : this.hidePrintModal}
          >
            <Modal.Body>
              <>
                <h3>Downloading...</h3>
                <p>This may take some time.</p>
              </>
              <PrintProgress
                active
                striped
                now={this.state.progress}
                label={`${this.state.progress}%`}
              />
            </Modal.Body>
          </Modal>
        </CustomersWrapper>
      );
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = (state) => ({
  customers: state.customers,
  authorized: state.user?.pages?.customers,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomer: (uid) => dispatch(CustomersActions.setCustomer(uid)),
  addCustomer: () => dispatch(CustomersActions.addCustomer()),
  removeCustomer: (customer) =>
    dispatch(CustomersActions.removeCustomer(customer)),
  billAllCommercialCustomers: (quarter,year) =>
    dispatch(CustomersActions.billAllCommercialCustomers(quarter,year)),

  billAllRecuringCustomers: (quarter,year) =>
    dispatch(CustomersActions.billAllRecuringCustomers(quarter,year)),

  billMonthCommercialCustomer: (customer, month, year) =>
    dispatch(CustomersActions.billMonthCommercialCustomer(customer, month, year)),
  getCustomers: () => dispatch(CustomersActions.getCustomers()),
  updateCustomerPrices: (file) =>
    dispatch(CustomersActions.updateCustomerPrices(file, "COMMERCIAL")),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
