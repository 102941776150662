/* @format - for Prettier */
import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Table, Button, Glyphicon } from 'react-bootstrap'
import { Customers as CustomersActions } from '../../actions2'
import { DeniedWrapper } from '../../styled'
import moment from 'moment'
import ReactExport from 'react-data-export'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const CustomersWrapper = styled.div`
  width: 80vw;
  margin: 0 auto;
  margin-top: 30px;
`

const ToolButton = styled(Button)`
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 15px;
`

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`

const Header = styled.div`
  margintop: '10px';
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ExportButton = (
  <ToolButton bsStyle="primary">
    <AddGlyph glyph="export" />
    Export to Excel
  </ToolButton>
)

class ExpiringContracts extends React.Component {
  state = {
    show: false,
    customer: null,
    searchText: ''
  }

  componentDidMount() {
    this.props.getCustomers()
  }

  render() {
    if (this.props.authorized) {
      // const filterUsers= ((this.props.customers||[]).filter(c=>{if(!c.isAutoRenewed){ return c}}))
      return (
        <CustomersWrapper>
          {/* <Header>
            <ExcelFile element={ExportButton} filename={moment().format('L')}>
              <ExcelSheet data={filterUsers.map(cus => ({
                name: cus.name,
                pricePerMonth:cus?.rate,
                dumpsterAddress: cus.dumpster_addresses === undefined ? `` : `${cus.dumpster_addresses[0].street}, ${cus.dumpster_addresses[0].city}, ${cus.dumpster_addresses[0].state}, ${cus.dumpster_addresses[0].zipcode}`,                
                expirationDate: `${cus.expirationMonth}/${cus.expirationDay}/${cus.expirationYear}`,
                contractTermLength: cus['Contract Term Length'],
                dumpsterInfo: cus?.dumpsters === undefined ? `` : cus?.dumpsters?.length===1 ? `${cus.dumpsters[0].number} / ${cus.dumpsters[0].type} / ${cus.dumpsters[0].frequency}`:"MULTIPLE DUMPSTERS",
              })).filter(c => Date.parse(c.expirationDate) < Date.parse(moment()) || Date.parse(c.expirationDate) < Date.parse(moment().add(30, 'days').calendar()) || c.expirationDate.includes('undefined') || c.contractTermLength === undefined)} name="Expiring Contracts">
                <ExcelColumn label="Customer" value="name" />
                <ExcelColumn label="Dumpster Address" value="dumpsterAddress" />
                <ExcelColumn label="Expiration Date" value="expirationDate" />
                <ExcelColumn label="Price Per Month" value="pricePerMonth" />
                <ExcelColumn label="Dumpster Info" value="dumpsterInfo" />
              </ExcelSheet>
            </ExcelFile>
          </Header> */}

          <Table hover bordered>
            <thead>
              <tr>
                <th>Customer</th>
                <th>Dumpster Address</th>
                <th>Expiration Date</th>
                <th>Expiration Status</th>
              </tr>
            </thead>
            <tbody>
              {_.map(this.props.customers, (customer) => {
                if(!customer?.isAutoRenewed){
                  customer.expirationDate = `${customer.expirationMonth}/${customer.expirationDay}/${customer.expirationYear}`
                  if (customer.expirationDate.includes('undefined')) {
                    var expDate = undefined;
                  } else {
                    var expDate = Date.parse(customer.expirationDate);
                  }
                  var nextDate = Date.parse(moment().add(30, 'days').calendar());
                  var today = Date.parse(moment());
                  if (expDate < today || expDate < nextDate || expDate === undefined || customer['Contract Term Length'] === "")
                  {
                    return (
                      <tr
                        key={customer.uid}
                        onClick={() => this.props.setCustomer(customer.uid)}
                      >
                        <td>{customer.name}</td>
                        {(customer.dumpster_addresses === undefined || customer.dumpster_addresses.length===0) ? 
                        <td></td> : 
                        <td>{customer.dumpster_addresses[0].street + `, ` + customer.dumpster_addresses[0].city + `, ` + customer.dumpster_addresses[0].state + `, ` + customer.dumpster_addresses[0].zipcode }</td> }
                        <td>
                          {customer.expirationMonth} / {customer.expirationDay} /{' '}
                          {customer.expirationYear}{' '}
                        </td>
                        {
                        expDate < today ?
                        <td><b style={{color:'red'}}>Expired</b></td> :
                        <td><b style={{color:'orange'}}>Expiring Soon</b></td> }
                      </tr>
                    )
                  } else {
                    return null
                  }
                }
              })}
            </tbody>
          </Table>
        </CustomersWrapper>
      )
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>
    }
  }
}

const mapStateToProps = (state) => ({
  customers: state.expiringCustomers || [],
  authorized: state.user?.pages?.customers
})

const mapDispatchToProps = (dispatch) => ({
  setCustomer: (uid) => dispatch(CustomersActions.setCustomer(uid)),
  getCustomers: () => dispatch(CustomersActions.getExpiringCustomers())
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpiringContracts)
