/* @format - for Prettier */
import React from "react";
import ReactExport from "react-data-export";
import _ from "lodash";
import styled from "styled-components";
import { connect } from "react-redux";
import { Table, Modal, Button, Glyphicon } from "react-bootstrap";
import { GreenBagCustomers as GreenBagCustomersActions} from "../../actions2";
import { DeniedWrapper } from "../../styled";
import printHtmlElement from 'print-html-element';
import worker from "workerize-loader!../NewCastle/worker"; // eslint-disable-line import/no-webpack-loader-syntax

import moment from "moment";

const CustomersWrapper = styled.div`
  width: 90%;
  max-width: 1168px;
  margin: 0 auto;
`;

const GlyphButton = styled(Button)`
  width: 100%;
  color: red !important;
  border: none;
`;

const GlyphTd = styled.td`
  width: 50px;
`;

const GlyphTh = styled.th`
  width: 50px;
`;

const DeleteButton = styled(Button)`
  float: right;
`;

const CloseButton = styled(Button)`
  float: left;
`;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  height: 40px;
  margin: 10px 0px 0px 10px;
  float: right;
`;

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`;

const Header = styled.div`
  display: flex;
`;

const Search = styled.input`
  width: 200px;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid lightgrey;
`;

const ExportWrapper = styled.div`
  span {
    width: 100%;
  }
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class GreenBagCustomers extends React.Component {
  state = {
    show: false,
    customer: null,
    searchText: "",
    progress: 0,
    progressing_show: false,
  };

  componentDidMount() {
    this.props.getCustomers();
    this.worker = worker();
    this.worker.addEventListener("message", this.onMessage);
    this.setState({
      searchText: localStorage.getItem('remember_green_bag_search_key')
    });
    document.getElementById("search-input").value = localStorage.getItem('remember_green_bag_search_key');
  }

  componentWillUnmount() {
    this.worker.removeEventListener("message", this.onMessage);
  }
  
  handleShow = customer => {
    this.setState({ show: true, customer });
  };

  onMessage = message => {
    if (message.data.done) {
      this.onProcessComplete();
    }
    if (message.data.file) {
      this.setState({
        progress: message.data.progress
      });
      this.onPdfGenerate(message.data.file, message.data.batch);
    }
  };

  onProcessComplete = () => {
    setTimeout(() => {
      this.setState({
        progress: 0,
        progressing_show: false,
      });
    }, 2000);
  };

  onPdfGenerate = (blob, batch) => {
    const data = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = data;
    link.download = `commercial_${moment().format("MMDDYYYY")}_${batch}.pdf`;
    link.click();
    link.remove();
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleRemove = () => {
    this.props.removeCustomer(this.state.customer.uid);
    this.setState({ show: false });
  };

  handleSearchInput = evt => {
    this.setState({ searchText: evt.target.value });
    localStorage.setItem('remember_green_bag_search_key', evt.target.value.toLowerCase())
  };

  handlePrint = () => {
    var element = document.getElementById('print');
    printHtmlElement.printElement(element, {pageTitle: 'Customers'});
  }

  filterResult = (customer, searchText) => {
    try {
      if (customer.name && customer.name.toLowerCase().includes(searchText)) return true;
      if (customer.service_address.city && customer.service_address.city.toLowerCase().includes(searchText)) return true;
      if (customer.service_address.street && customer.service_address.street.toLowerCase().includes(searchText)) return true;
      if (customer.service_address.zipcode && customer.service_address.zipcode.toString().includes(searchText)) return true;
      let result = false;
      return result;
    } catch (error) {
      console.log(error)
      return true;
    }
  }

  render() {
    const { searchText } = this.state;
    const filteredCustomers = _.filter(
      this.props.customers, customer => this.filterResult(customer, searchText.toLocaleLowerCase())
    );
    
    if (this.props.authorized) {
      return (
        <CustomersWrapper>
          <label>Number of Customers: {this.props.customers.length}</label>
          <Header>
            <div style={{margin: '10px 0px', display: 'flex'}}>
              <label htmlFor="search-input" style={{marginRight: '5px', paddingTop: '10px'}}>Search: </label>
              <Search id="search-input" onBlur={this.handleSearchInput} />
              <Button style={{marginLeft: 10}} bsStyle="primary">
                <AddGlyph glyph="search" />
                Search
              </Button>
            </div>
            <div style={{width: '100%'}}>
              <ExportWrapper>
                <ExcelFile
                  filename="Green Bag Customers Contact Info"
                  element={<ToolButton bsStyle="primary">Export Contact Info</ToolButton>}
                >
                  <ExcelSheet data={this.props.customers} name="Green Bag Customers Contact Info">
                    <ExcelColumn label="Name" value="name" />
                    <ExcelColumn label="Phone" value="phone_number" />
                  </ExcelSheet>
                </ExcelFile>
              </ExportWrapper>
              <ToolButton onClick={this.props.addCustomer} bsStyle="primary">
                <AddGlyph glyph="plus" />
                Add Customer
              </ToolButton>
              <ToolButton onClick={this.handlePrint} bsStyle="primary">
                <AddGlyph glyph="print" />
                Print
              </ToolButton>
            </div>
          </Header>
          <Table responsive hover bordered id="print">
            <thead>
              <tr>
                <th>Customer Name</th>
                <th>Service Address</th>
                <th>Phone Number</th>
                <th>Pick Up Day</th>
                <GlyphTh className="hidden-print">Delete</GlyphTh>
              </tr>
            </thead>
            <tbody>
              {_.map(filteredCustomers, customer => {
                if(customer.uid !== undefined) {
                    return (
                      <tr key={customer.uid}>
                        <td onClick={() => this.props.setCustomer(customer.uid)}>
                          <div>{customer.name}</div>
                        </td>
                        <td className="dumpster-address" onClick={() => this.props.setCustomer(customer.uid)}>
                          <div>
                          {
                            customer.service_address ? (
                              <div>
                                <div>{customer.service_address.street}</div>
                                <div>{`${customer.service_address.city}, ${customer.service_address.state} ${customer.service_address.zipcode}`}</div>
                              </div>
                            ) : ('')
                          }
                          </div>
                        </td>
                        <td className="phone-number" onClick={() => this.props.setCustomer(customer.uid)}>
                          <div>{customer.phone_number}</div>
                        </td>
                        <td onClick={() => this.props.setCustomer(customer.uid)}>
                          <div>{customer.pickup_day && customer.pickup_day}</div>
                        </td>
                        <GlyphTd className="hidden-print">
                          <GlyphButton onClick={() => this.handleShow(customer)}>
                            <Glyphicon glyph="remove" />
                          </GlyphButton>
                        </GlyphTd>
                      </tr>
                    );
                    }
              })}
            </tbody>
          </Table>
          <Modal
            bsSize="small"
            show={this.state.show}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <span>Delete </span>
                <span>
                  {this.state.customer ? this.state.customer.name : " "}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                Are you sure you want to delete this customer and all of their
                Stops?
              </div>
            </Modal.Body>
            <Modal.Footer>
              <CloseButton onClick={this.handleClose}>Close</CloseButton>
              <DeleteButton bsStyle="danger" onClick={this.handleRemove}>
                Delete
              </DeleteButton>
            </Modal.Footer>
          </Modal>    
        </CustomersWrapper>
      );
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = state => ({
  customers: state.greenBag.customers,
  authorized: state.user?.pages?.customers
});

const mapDispatchToProps = dispatch => ({
  setCustomer: uid => dispatch(GreenBagCustomersActions.setCustomer(uid)),
  addCustomer: () => dispatch(GreenBagCustomersActions.addCustomer()),
  removeCustomer: customer =>
    dispatch(GreenBagCustomersActions.removeCustomer(customer)),
  getCustomers: () => dispatch(GreenBagCustomersActions.getCustomers())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GreenBagCustomers);
