/* @format - for Prettier */
import React from "react";

import styled from "styled-components";
import { connect } from "react-redux";
import { Button, Glyphicon, Modal, Row, Col, Label } from "react-bootstrap";
import Select from "react-select";

import moment from "moment";

import { NewCastleCustomers as NewCastleCustomersActions } from "../../actions2";

import worker from "workerize-loader!./worker"; // eslint-disable-line import/no-webpack-loader-syntax
import { pdfService } from '../../services/pdf'

const Wrapper = styled.div``;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  margin: 10px;
  margin-right: 0;
`;

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`;

class PrintSingleInvoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      present_uid: '',
      show: false,
      generatingPDF: false,
      progress: 0,
      moment: moment(),
      old_amount: 0,
      default_amount: 1.0,
      form: "",
      form_description: "",

      // state for Invoice Modal
      show_invoice_dialog: false,
      dueDate: moment().format("MM/DD/YYYY"),

      // states for Suspended Modal
      show_suspended_modal: false,
      note: "",
      selectedHistory: {
        uid: null
      },
      recieptAddress: "",
      serviceLevel: ""
    };
  }

  print = async () => {
    this.setState({ exporting: true });
    await pdfService.newcastle_customer_invoice_pdf_download([this.props.customer], moment().format("MM/DD/YYYY"), this.state.dueDate);
    this.setState({
      exporting: false,
      show: false,
    });
  };

  togglePrint = uid => {

    // Creates the worker -- originally was in componentDidMount(), but was causing web page to crash so moved it down here. 
    this.worker = worker();
    console.log('worker', this.worker)
    this.worker.addEventListener("message", this.onMessage);
    
    this.setState({
      present_uid: uid
    })
    //TODO: Generate PDF For Download
    this.print();
  };

  showPrintModal = () => {
    this.setState({
      show: true
    });
  };

  hidePrintModal = () => {
    this.setState({
      show: false
    });
  };

  render() { 
      return (
        <Wrapper>
                <Modal
                    show={this.state.show}
                    onHide={this.state.exporting ? () => {} : this.hidePrintModal}
                >
                  {
                    this.state.exporting ? (
                      <Modal.Body>
                        <h3>Downloading...</h3>
                        <p>This may take some time.</p>
                      </Modal.Body>
                    ) : (
                      <>
                        <Modal.Header>Print Invoice</Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={12}>
                                    <Label>Print Invoice For: </Label>
                                    <h3>{this.props.customer["Resident Name"]}</h3>
                                </Col>
                            </Row>
                            <Select
                              disabled={this.state.exporting}
                              onChange={v => this.setState({ dueDate: v.value })}
                              options={[
                                {
                                  value: moment()
                                    .month(11)
                                    .date(15)
                                    .format("MM/DD/YYYY"),
                                  label: "1st Quarter"
                                },
                                {
                                  value: moment()
                                    .month(2)
                                    .date(15)
                                    .format("MM/DD/YYYY"),
                                  label: "2nd Quarter"
                                },
                                {
                                  value: moment()
                                    .month(5)
                                    .date(15)
                                    .format("MM/DD/YYYY"),
                                  label: "3rd Quarter"
                                },
                                {
                                  value: moment()
                                    .month(8)
                                    .date(15)
                                    .format("MM/DD/YYYY"),
                                  label: "4th Quarter"
                                }
                              ]}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.togglePrint(this.props.customer.uid)}> Print Invoice </Button>
                        </Modal.Footer>
                      </>
                    )
                  }
                
            </Modal>
            <ToolButton
                disabled={this.state.exporting}
                bsStyle="primary"
                id="printBtn"
                onClick={this.showPrintModal}
            >
                <AddGlyph glyph="print" />
                Print Invoice
            </ToolButton>
        </Wrapper>
      );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    getCustomers: () =>
      dispatch(NewCastleCustomersActions.getCustomersNewCastle()),
    billToggle: (uid, type) =>
      dispatch(NewCastleCustomersActions.billToggleNewCastle(uid, type)),
    setCustomer: uid =>
      dispatch(NewCastleCustomersActions.setNewCastleCustomer(uid)),
    addCustomer: () => dispatch(NewCastleCustomersActions.addNewCastleCustomer()),
  });

export default connect(mapStateToProps, mapDispatchToProps)(PrintSingleInvoice);
