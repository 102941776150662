import _ from "lodash";

import { history } from "../App";
import { ApiService } from "../api/apiService";

export const setApplicants = (jobs) => ({
  type: "SET_JOB_APPLICANTS",
  jobs,
});

export const setCurrentApplicant = (applicant) => ({
  type: "SET_CURRENT_JOB_APPLICANT",
  applicant,
});

/* Gets all the job submissions from the database */
export const getjobSubmissions = () => {
  return function (dispatch) {
    ApiService.readAll("job_submissions")
      .then((data) => {
        if(data && data.jobs)
          dispatch(setApplicants(data.jobs));
      })
      .catch((error) => console.log("error in getting job submissions", error));
  };
};
/* Gets all the customer portal users from the database */
export const getPortalUsers = () => {
  return function (dispatch) {
    ApiService.readAll("portal_users")
      .then((data) => {
        console.log("data", data);
        if(data && data.users){
          const users = _.sortBy(data.users, ["email"]);
          dispatch(setApplicants(users));
        }
       })
      .catch((error) => console.log("error in portal users", error));
  };
};

export const saveCustomerPortal = (user, acctNum) => {
  return function (dispatch) {
    var userNew = user;
    userNew['acc_number'] = acctNum;
    ApiService.update("portal_users", userNew.auth_id, {
      portalUser:  userNew
    })
      .then((updated) => {
        if(updated)
          console.log("UPDATED");
        //dispatch(setCurrentApplicant(applicant));
      })
      .catch((error) => console.log("error in updating customer portal user info", error));
  };
};

/* Gets individual job details from the database */
export const getApplicantDetails = (uid, redirect = "") => {
  return function (dispatch) {
    ApiService.readOne("job_submissions", uid)
      .then((data) => {
        dispatch(setCurrentApplicant(data.job));
        let url = "/Jobs/Applicant";
        if (redirect.length) url = `/Jobs/Applicant?redirect=${redirect}`;
        history.push(url);
      })
      .catch((error) => console.log("error in job details", error));
  };
};
