/* @format - for Prettier */
import React, { Component } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { createBrowserHistory } from 'history'
import { Users, init } from './actions2'
import {
  Navbar,
  Routes,
  OurRoute,
  Customers,
  ResidentialCustomers,
  Customer,
  ResidentialCustomer,
  Login,
  PrintRoute,
  History,
  ResidentialHistory,
  Invoice,
  Receipt,
  NewCastle2Receipt,
  ResidentialReceipt,
  WestMayfieldReceipt,
  SlipperyRockReceipt,
  EastButlerReceipt,
  DueAccounts,
  Profit,
  CommercialPricing,
  ResidentialPricing,
  Residential,
  Invoices,
  ResidentialInvoice,
  Admin,
  ExpiringContracts,
  NewCastleInvoices,
  NewCastleCustomer,
  NewCastleHistory,
  ClosedAccounts,
  GreenBagCustomers,
  GreenBagCustomer,
  Unauthorized,
  JobAppSubmissions,
  CustomerPortal,
  JobSubmissionDetails,
  ClosedAccountCustomer,
  ClosedAccountResidential,
  ClosedAccountWestMayfield,
  ClosedAccountSlippryRock,
  WestMayfieldCustomers,
  WestMayfieldCustomer,
  NewCastle2Customer,
  NewCastle2Customers,
  NewCastle2History,
  NewCastle2Invoice,
  ClosedAccountEastButler,
  ClosedAccountNewCastle2
} from './components'
import './App.css'
import WestMayfieldHistory from './components/WestMayfieldHistory'
import WestMayfieldInvoice from './components/WestMayfieldInvoice'
import EastButlerCustomers from './components/EastButlerCustomers'
import EastButlerCustomer from './components/EastButlerCustomer'
import EastButlerHistory from './components/EastButlerHistory'
import EastButlerInvoice from './components/EastButlerInvoice'
import SlipperyRockCustomers from './components/SlipperyRockCustomers'
import SlipperyRockCustomer from './components/SlipperyRockCustomer'
import SlipperyRockHistory from './components/SlipperyRockHistory'
import SlipperyRockInvoice from './components/SlipperyRockInvoice'
import LocalStorage from './services/LocalStorage'

export const history = createBrowserHistory()

class App extends Component {
  constructor(props) {
    super(props)
    const user = LocalStorage.getData('eiken_user');
    if(user)
      this.props.setuser(user);
    else 
      this.props.setuser(null);
    this.state = {
      status: user ? 'Logged-In' : 'Start-Up',
    }
  }

  storageListner = (e) => {
    const user = JSON.parse(e.currentTarget.localStorage['eiken_user'] || 'null');
    console.log('storage user', user);
    if (user && user.uid) {
      this.props.init(user.uid)
      this.setState({
        status: 'Logged-In',
      })
    } else {
      this.props.setuser(null)
      this.setState({
        status: 'Logged-Out',
      })
    }
  }
  componentDidMount() {
    if (typeof window != 'undefined') {
      window.addEventListener("storage", this.storageListner);
    }
  }
  componentWillUnmount() {
    if (typeof window != 'undefined') {
      window.removeEventListener("storage", this.storageListner);
    } 
  }
  // componentDidUpdate(prev) {
  //   if (this.props.user !== prev.user) {
  //     if (this.props.user !== undefined) {
  //       this.setState({ status: 'Logged-In' })
  //     } else {
  //       this.setState({ status: 'Logged-Out' })
  //     }
  //   }
  // }

  render() {
    if (this.state.status === 'Start-Up') {
      /* page is loading */
      return (
        <div className="App">
          <Router history={history}>
            <div>
              <Navbar />
              <Route path="/Login" component={Login} />
              <Route path="/Un_authorized" component={Unauthorized} />
              <Redirect from="*" to={'/Login'} />
            </div>
          </Router>
        </div>
      )
    } else if (this.state.status === 'Logged-In') {
      /* User is logged in */
      return (
        <div className="App">
          <Router history={history}>
            <div>
              <Navbar />
              <Switch>
                <Route path="/Admin" component={Admin} />
                <Route path="/Routes" component={Routes} />
                <Route path="/Route" component={OurRoute} />
                <Route path="/PrintRoute" component={PrintRoute} />
                <Route path="/Customers" component={Customers} />
                <Route path="/ClosedAccounts" component={ClosedAccounts} />
                <Route
                  path="/ClosedAccount/Residential"
                  component={ClosedAccountResidential}
                />
                <Route
                  path="/ClosedAccount/WestmayField"
                  component={ClosedAccountWestMayfield}
                />
                <Route
                  path="/ClosedAccount/SlipperyRock"
                  component={ClosedAccountSlippryRock}
                />
                <Route
                  path = "/ClosedAccount/EastButler"
                  component={ClosedAccountEastButler}
                />
                <Route
                  path = "/ClosedAccount/NewCastle2"
                  component={ClosedAccountNewCastle2}
                />
                <Route path="/ClosedAccount/Customer" component={ClosedAccountCustomer} />
                <Route path="/GreenBagCustomers" component={GreenBagCustomers} />
                <Route path="/Customer" component={Customer} />
                <Route path="/History" component={History} />
                <Route path="/Invoice/:historyId" component={Invoice} />
                <Route path="/Receipt/:ReceiptArray" component={Receipt} />
                <Route path="/NewCastle2Receipt/:ReceiptArray" component={NewCastle2Receipt} />
                <Route path="/ResidentialReceipt/:ReceiptArray" component={ResidentialReceipt} />
                <Route path="/WestMayfieldReceipt/:ReceiptArray" component={WestMayfieldReceipt} />
                <Route path="/EastButlerReceipt/:ReceiptArray" component={EastButlerReceipt} />
                <Route path="/SlipperyRockReceipt/:ReceiptArray" component={SlipperyRockReceipt} />
                <Route path="/Reports/due_accounts" component={DueAccounts} />
                {/* <Route path="/Reports/profit" component={Profit} /> */}
                <Route path="/Reports/profit"
                  component={() => {
                      window.location.href = 'https://lookerstudio.google.com/u/0/reporting/b4511658-a276-47b3-93c7-52aae714f23e/page/p_cfsujni9gd';
                      return null;
                  }}/>
                <Route path="/Reports/ResidentialPricing" component={ResidentialPricing} />
                <Route path="/Reports/CommercialPricing" component={CommercialPricing} />
                <Route
                  path="/Reports/expiring_contracts"
                  component={ExpiringContracts}
                />
                <Route path="/Reports/residential" component={Residential} />
                <Route path="/Reports/invoices" component={Invoices} />
                <Route
                  path="/ResidentialInvoice/:historyId"
                  component={ResidentialInvoice}
                />
                <Route
                  path="/NewCastle2Invoice/:historyId"
                  component={NewCastle2Invoice}
                />
                <Route
                  path="/WestMayfieldInvoice/:historyId"
                  component={WestMayfieldInvoice}
                />
                <Route
                  path="/EastButlerInvoice/:historyId"
                  component={EastButlerInvoice}
                />
                <Route
                  path="/SlipperyRockInvoice/:historyId"
                  component={SlipperyRockInvoice}
                />
                <Route
                  path="/Residential/Customers"
                  component={ResidentialCustomers}
                />
                  <Route
                    path="/Residential/Customer"
                    component={ResidentialCustomer}
                  />
                <Route
                  path="/NewCastle2/Customers"
                  component={NewCastle2Customers}
                />
                  <Route
                    path="/NewCastle2/Customer"
                    component={NewCastle2Customer}
                  />
                <Route
                  path="/WestMayfield/Customers"
                  component={WestMayfieldCustomers}
                />
                <Route
                  path="/WestMayfield/Customer"
                  component={WestMayfieldCustomer}
                />
                <Route
                  path="/EastButler/Customers"
                  component={EastButlerCustomers}
                />
                <Route
                  path="/SlipperyRock/Customers"
                  component={SlipperyRockCustomers}
                />
                <Route
                  path="/EastButler/Customer"
                  component={EastButlerCustomer}
                />
                <Route
                  path="/SlipperyRock/Customer"
                  component={SlipperyRockCustomer}
                />
                <Route
                  path="/EastButler/History"
                  component={EastButlerHistory}
                />
                <Route
                  path="/SlipperyRock/History"
                  component={SlipperyRockHistory}
                />
                <Route
                  path="/GreenBag/Customer"
                  component={GreenBagCustomer}
                />
                <Route
                  path="/Residential/History"
                  component={ResidentialHistory}
                />
                <Route
                  path="/NewCastle2/History"
                  component={NewCastle2History}
                />
                <Route
                  path="/WestMayfield/History"
                  component={WestMayfieldHistory}
                />
                {/* <Redirect to={"/Customers"} /> */}
                <Route
                  path="/NewCastle/Customers"
                  component={NewCastleInvoices}
                />
                <Route
                  path="/NewCastle/Customer"
                  component={NewCastleCustomer}
                />
                <Route path="/NewCastle/History" component={NewCastleHistory} />
                <Route path="/JobSubmissions" component={JobAppSubmissions} />
                <Route path="/CustomerPortal" component={CustomerPortal} />
                <Route path="/Jobs/Applicant" component={JobSubmissionDetails} />
                <Redirect from="/" to={'/Admin'} />
              </Switch>
            </div>
          </Router>
        </div>
      )
    } else {
      /* User is not logged in */
      return (
        <div className="App">
          <Router history={history}>
            <div>
              <Navbar />
              <Switch>
                <Route path="/Login" component={Login} />
                <Route path="/Un_authorized" component={Unauthorized} />
                <Redirect from="*" to={'/Login'} />
              </Switch>
            </div>
          </Router>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  init: (userId) => dispatch(init(userId)),
  setuser: (user) => dispatch(Users.setUser(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
