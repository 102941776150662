/* @format - for Prettier */
import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { connect } from "react-redux";
import { Table, Button, Glyphicon } from "react-bootstrap";
import { JobSubmissions as JobSubmissionsActions } from "../../actions2";
import { DeniedWrapper } from "../../styled";
import printHtmlElement from "print-html-element";

const JobSubmissionsWrapper = styled.div`
  width: 90%;
  max-width: 1168px;
  margin: 0 auto;
`;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  height: 40px;
  margin: 10px 0px 0px 10px;
  float: right;
`;

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`;

const Header = styled.div`
  display: flex;
`;

const Search = styled.input`
  width: 200px;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid lightgrey;
`;

class CustomerPortal extends React.Component {
  state = {
    searchText: "",
  };

  componentDidMount() {
    this.props.getPortalUsers();
    this.setState({
      searchText: localStorage.getItem("remember_job_submission_search_key"),
    });
    document.getElementById("search-input").value = localStorage.getItem(
      "remember_job_submission_search_key"
    );
  }

  handleSearchInput = (e) => {
    this.setState({ searchText: e.target.value });
    localStorage.setItem(
      "remember_job_submission_search_key",
      e.target.value.toLowerCase()
    );
  };

  handlePrint = () => {
    var element = document.getElementById("print");
    printHtmlElement.printElement(element, { pageTitle: "PortalUsers" });
  };

  filterResult = (portalUser, searchText) => {
      if (portalUser.email && portalUser.email.toLowerCase().includes(searchText))
        return true;
      return false;
  };

  render() {
    const { searchText } = this.state;
    const filteredPortalUsers = searchText.length > 0 ? _.filter(this.props.portalUsers, (portalUser) =>
      this.filterResult(portalUser, searchText.toLocaleLowerCase())
    ) : this.props.portalUsers;

    if (this.props.authorized) {
      return (
        <JobSubmissionsWrapper>
          <label>Number of Portal Customers: {this.props.portalUsers?.length}</label>
          <Header>
            <div style={{ margin: "10px 0px", display: "flex" }}>
              <label
                htmlFor="search-input"
                style={{ marginRight: "5px", paddingTop: "10px" }}
              >
                Search:{" "}
              </label>
              <Search id="search-input" onBlur={this.handleSearchInput} />
              <Button style={{ marginLeft: 10 }} bsStyle="primary">
                <AddGlyph glyph="search" />
                Search
              </Button>
            </div>
            <div style={{ width: "100%" }}>
              <ToolButton onClick={this.handlePrint} bsStyle="primary">
                <AddGlyph glyph="print" />
                Print
              </ToolButton>
            </div>
          </Header>
          <Table responsive hover bordered id="print">
            <thead>
              <tr>
                <th>Customer Email</th>
                <th>Customer's Linked Account Number</th>
              </tr>
            </thead>
            <tbody>
              {_.map(filteredPortalUsers, (portalUser) => {
                if (portalUser !== undefined) {
                  return (
                    <tr key={portalUser.uid}>
                      <td>
                        <div>{`${portalUser.email}`}</div>
                      </td>
                      <td
                        className="dumpster-address"
                      >
                        <div>
                           <input defaultValue={portalUser.acc_number}  
                           onBlur={(e) => {
                            console.log(e.target.value)
                            this.props.updateCustomerPortalUser(portalUser, e.target.value);
                           }}></input>
                        </div>
                      </td>
                      {/* <td>
                        <div>{`${portalUser.uid}`}</div>
                      </td> */}
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
        </JobSubmissionsWrapper>
      );
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = (state) => ({
  authorized: state.user?.pages?.customers,
  portalUsers: state.jobSubmissions.jobs,
});

const mapDispatchToProps = (dispatch) => ({
  getPortalUsers: () => dispatch(JobSubmissionsActions.getPortalUsers()),
  updateCustomerPortalUser: (portalUser,acctNum) =>
    dispatch(JobSubmissionsActions.saveCustomerPortal(portalUser,acctNum)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPortal);
