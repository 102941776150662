/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import {Button, Glyphicon} from 'react-bootstrap';
import Invoice from './Invoice';

const Wrapper = styled.div`
  margin-top: -75px;
`;

const RouteWrapper = styled.div`
  margin: 2vh 10vw 0vh 10vw;
`;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  margin: 10px 105px 10px 10px;
  float: right;
`;

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`;


class PrintOpenInvoices extends React.Component {
  render() {
    let componentRef = null;
    
    if (this.props.invoices === undefined) {
      return <Redirect to="Reports/invoices" />;
    } else {
      return (
        <Wrapper>
          <ReactToPrint
            trigger={() => (
              <ToolButton bsStyle="primary" id="printBtn">
                <AddGlyph glyph="print" />
                Print All Open Invoices
              </ToolButton>
            )}
            content={() => componentRef}
          />
          <RouteWrapper ref={el => (componentRef = el)}>
            {
              _.map(this.props.invoices, invoice => {
                return (
                  <Invoice invoice={invoice}/>
                );
              })
            }
          </RouteWrapper>
        </Wrapper>
      );
    }
  }
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrintOpenInvoices);
