/* @format - for Prettier */
import React, { useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Grid,
  Row,
  Col,
  Table,
  Modal,
  Glyphicon,
  Button,
} from "react-bootstrap";
import styled from "styled-components";
import { NewCastleCustomers } from "../../../../actions2";
// import { GlyphButton } from "../../Admin";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import moment from "moment";
import DatePicker from "react-datepicker";
import { deleteNewCastleCallHistory, doAddNotesNewCastle, doDeleteNoteNewCastle, doNewCastleCallHistory } from "../../../../firebase/db";
import { GlyphButton } from "../../../Admin";
import axiosInstance from "../../../../api/axios";

const StyledRow = styled(Row)`
  margin-bottom: 0px !important;
`;
const StyledCol = styled(Col)`
  padding: 0px;
  margin-bottom: 20px;
`;
const StyledNotes = styled.div`
  border: 1px solid black;
  height: 60px;
  padding: 5px 10px 5px 10px;
  font-style: italic;
`;

const Header = styled.div`
  display: flex;
`;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  height: 40px;
  margin: 10px 0px 0px 10px;
  float: right;
`;

const EditButton = styled(Button)`
  padding: 5px 20px;
  height: 35px;
  margin: 0px 0px 0px 10px;
  float: right;
`;

const AccordionContainer = styled.div`
  margin: 20px 0px !important;
`;

const AddAddressButton = styled(Button)`
  width: 200px;
  margin-top: 20px;
`;

const DeleteButton = styled(Button)`
  float: right;
`;
const CloseButton = styled(Button)`
  float: left;
`;
/*eslint-disable */
const CustomerData = ({
  customer: {
    ["Address"]: billingAddress,
    ["Physical Address"]: physicalAddress,
    ["Branch Code"]: branchCode,
    ["Business Type"]: businessType,
    // ["Carrier Route"]: carrierRoute,
    ["Resident Name"]: residentName,
    ["Account Number"]: accountNumber,
    ["City"]: city,
    ["Physical City"]: physicalCity,
    ["Employee Size"]: employeeSize,
    ["Executive First Name"]: executiveFirstName,
    ["Executive Gender"]: executiveGender,
    ["Executive Last Name"]: executiveLastName,
    ["Executive Title"]: executiveTitle,
    ["Fax Number Combined"]: faxNumberCombined,
    ["Location ZIP Code"]: billingZipCode,
    ["Physical ZIP"]: physicalZipCode,
    // ["Metro Area"]: metroArea,
    // ["Middle Initial"]: middleInitial,
    ["Phone"]: phone,
    ["Email"]: email,
    ["Primary SIC Code"]: primarySICCode,
    ["Primary SIC Description"]: primarySICDescription,
    // ["Professional Title"]: professionalTitle,
    // ["Salutation"]: salutation,
    ["State"]: state,
    ["Status"]: status,
    ["notes"]: notes,
    ["Physical State"]: physicalState,
    // ["Suffix"]: suffix,
    // ["Worksite Type"]: worksiteType,
    ["ZIP+4"]: zipPlus4,
    ["isExempt"]: isExempt,
    ["isRecurringCustomer"]: isRecurringCustomer,
    ["callHistory"]:callHistory,
    uid
  },
  setCustomer,
}) => {
  // if(notes){
  //   notes = Object.entries(notes).sort((a,b) => a[1].date > b[1].date ? 1 : -1);
  // }
  // if(callHistory){
  //   callHistory = Object.entries(callHistory).sort((a,b) => a[1].date > b[1].date ? 1 : -1);
  // }
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [modalTitle, setModalTitle] = useState("Add");
  const [noteValue, setNoteValue] = useState("");
  const [noteUid, setNoteUid] = useState("");
  const [callShow, setCallShow] = useState(false);
  const [startCallDate, setStartCallDate] = useState(new Date());
  const [modalCallTitle, setModalCallTitle] = useState("Add");
  const [noteCallValue, setNoteCallValue] = useState("");
  const [noteCallUid, setNoteCallUid] = useState("");
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleClose = () => {
    setShow(!show);
    setStartDate("");
    setNoteValue("");
    setNoteUid("");
  };
  const handleStartDateCAllChange = (date) => {
    setStartCallDate(date);
  };
  const handleCallClose = () => {
    setCallShow(!callShow);
    setStartCallDate("");
    setNoteCallValue("");
    setNoteCallUid("");
  };

  const saveCallNotes = async () => {
    if (!startCallDate) {
      alert("Please choose the date");
      return;
    } else {
      if (noteCallUid) {
        const res = await axiosInstance.patch(`/api/customers/update/call_history/${noteCallUid}`, {
          call_history: {
            date: startCallDate,
            note: noteCallValue
          }
        });
        if (res.status === 200) {
          setCustomer(uid);
        }
      } else {
        const res = await axiosInstance.post(`/api/customers/add/call_history`, {
          call_history: {
            customer_id: uid,
            date: startCallDate,
            note: noteCallValue
          }
        })
        if (res.status === 201) {
          setCustomer(uid);
        }
      }
      setCallShow(false);
      setStartCallDate("");
      setNoteCallValue("");
      setNoteCallUid("");
    }
  };

  const deleteCallNote = async (id) => {
    let res = await axiosInstance.delete(`/api/customers/delete/call_history/${id}`);
    if (res.status === 200)
      setCustomer(uid);
    handleDeleteCallClose();
  };

  const doUpdateCallNote = (customer) => {
    let date = customer.date ? moment(customer.date).toDate() : "";
    setModalCallTitle("Update");
    setStartCallDate(date);
    setNoteCallValue(customer.note);
    setNoteCallUid(customer.uid);
    setCallShow(true);
  };

  const saveNotes = async () => {
    if (noteUid) {
      const res = await axiosInstance.patch(`/api/customers/update/note/${noteUid}`, {
        note: {
          date: startDate,
          note: noteValue
        }
      });
      if (res.status === 200) {
        setCustomer(uid);
      }
    } else {
      const res = await axiosInstance.post(`/api/customers/add/note`, {
        note: {
          customer_id: uid,
          date: startDate,
          note: noteValue,
        }
      })
      if (res.status === 201) {
        setCustomer(uid);
      }
    }
    setShow(false);
    setStartDate("");
    setNoteValue("");
    setNoteUid("");
  };

  const deleteNote = async (id) => {
    let res = await axiosInstance.delete(`/api/customers/delete/note/${id}`);
    if(res.status === 200)
      setCustomer(uid);
    handleDeleteClose();
  };

  const doUpdateNote = (customer) => {
    let date = customer.date ? moment(customer.date).toDate() : "";
    setModalTitle("Update");
    setStartDate(date);
    setNoteValue(customer.note);
    setNoteUid(customer.uid);
    setShow(true);
  };

  const [showCallDelete, setShowCallDelete] = useState({
    key: false,
    uid: "",
  });

  const handleDeleteCallClose = () => {
    setShowCallDelete({
      key: false,
      uid: "",
    });
  };

  const [showDelete, setShowDelete] = useState({
    key: false,
    uid: "",
  });

  const handleDeleteClose = () => {
    setShowDelete({
      key: false,
      uid: "",
    });
  };
  return (
    <Grid>
      <StyledRow>
        <StyledCol sm={4} md={4}>
          <h4>Customer Information</h4>
          <div>Resident Name: {residentName}</div>
          {/* <div>Business Type: {businessType}</div> */}
          <div>Account Number: {accountNumber}</div>
          {/* <div>Employee Size: {employeeSize}</div> */}
          <div>Status: {status}</div>
          <div>
            Is Exempt: {(isExempt === undefined) | null ? "No" : isExempt}
          </div>
          <div>
            Is Recurring:{" "}
            {(isRecurringCustomer === undefined) | null
              ? "No"
              : isRecurringCustomer}
          </div>
        </StyledCol>
        <StyledCol sm={2} md={2} smOffset={1} mdOffset={1}>
          <h4>Contact Information</h4>
          <div>Phone Number: {phone}</div>
          <div>Email: {email}</div>
          <div>Fax Number: {faxNumberCombined}</div>

          {/* <h4>Executive</h4>
        <div>Title: {executiveTitle}</div>
        <div>First Name: {executiveFirstName}</div>
        <div>Last Name: {executiveLastName}</div>
        <div>Gender: {executiveGender}</div> */}
        </StyledCol>
        {/* <StyledCol sm={4} md={4} smOffset={1} mdOffset={1}>
        <StyledRow>
          <h4>Notes:</h4>
        </StyledRow>
        <StyledRow>
          <StyledNotes>{notes}</StyledNotes>
        </StyledRow>
      </StyledCol> */}
      </StyledRow>
      <StyledRow>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Billing Address:</h4>
          </StyledRow>
          <StyledRow>
            <div>{billingAddress}</div>
            <div>
              {`${city?.length>0 ? city + ", " : ""} ${state||''} ${billingZipCode||''}`}
            </div>
          </StyledRow>
        </StyledCol>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Service Address:</h4>
          </StyledRow>
          <StyledRow>
            <div>{physicalAddress}</div>
            <div>
              {
                `${physicalCity?.length>0 ? physicalCity + ", " : ""} ${physicalState||''} ${physicalZipCode||''}`
              }
            </div>
          </StyledRow>
        </StyledCol>
        {/* <StyledCol sm={2} md={2} smOffset={1} mdOffset={1}>
        <h4>SIC Info</h4>
        <div>Primary SIC Code: {primarySICCode}</div>
        <div>Primary SIC Description: {primarySICDescription}</div>
      </StyledCol> */}
      </StyledRow>

      <StyledRow>
        <StyledCol>
          <StyledRow>
            <AccordionContainer>
              <Accordion allowZeroExpanded="true">
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <label>Notes ({notes !== undefined ? notes?.length : 0})</label>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Header>
                      <div style={{ width: "100%" }}>
                        <ToolButton
                          onClick={() => {
                            setShow(true);
                            setModalTitle("Add");
                          }}
                          bsStyle="primary"
                        >
                          + New
                        </ToolButton>
                      </div>
                    </Header>
                    <br />
                    <Table responsive hover bordered id="print">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Note</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                          {notes.map((note) => {
                            if (note.uid !== undefined) {
                              return (
                                <tr key={note.uid}>
                                  <td>
                                    <div>
                                      {note.date
                                        ? note.date
                                        : "---"}
                                    </div>
                                  </td>
                                  <td>
                                    <div>{note.note}</div>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div>
                                        <EditButton
                                          onClick={() => doUpdateNote(note)}
                                          bsStyle="primary"
                                        >
                                          Edit
                                        </EditButton>
                                      </div>
                                      <div>
                                        <GlyphButton
                                          onClick={() =>
                                            setShowDelete({
                                              key: true,
                                              uid: note.uid,
                                            })
                                          }
                                        >
                                          <Glyphicon glyph="remove" />
                                        </GlyphButton>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          })}
                      </tbody>
                    </Table>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </AccordionContainer>
          </StyledRow>
          <StyledRow>
            <AccordionContainer>
              <Accordion allowZeroExpanded="true">
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <label>Call History ({callHistory !== undefined ? callHistory?.length : 0})</label>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Header>
                      <div style={{ width: "100%" }}>
                        <ToolButton
                          onClick={() => {
                            setCallShow(true);
                            setModalCallTitle("Add");
                          }}
                          bsStyle="primary"
                        >
                          + New
                        </ToolButton>
                      </div>
                    </Header>
                    <br />
                    <Table responsive hover bordered id="print">
                      <thead>
                        <tr>
                          <th>Call Date</th>
                          <th>Note</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {callHistory.map((call) => {
                        if (call?.uid !== undefined) {
                          return (
                            <tr key={call?.uid}>
                              <td>
                                <div>
                                  {call.date
                                    ? call.date
                                    : "---"}
                                </div>
                              </td>
                              <td>
                                <div>{call.note}</div>
                              </td>
                              <td>
                                <div className="d-flex gap-2">
                                  <div>
                                    <EditButton
                                      onClick={() => doUpdateCallNote(call)}
                                      bsStyle="primary"
                                    >
                                      Edit
                                    </EditButton>
                                  </div>
                                  <div>
                                    <GlyphButton
                                      onClick={() =>
                                        setShowCallDelete({
                                          key: true,
                                          uid: call.uid,
                                        })
                                      }
                                    >
                                      <Glyphicon glyph="remove" />
                                    </GlyphButton>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                      </tbody>
                    </Table>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </AccordionContainer>
          </StyledRow>
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <Link to="/NewCastle/History">History and Invoices</Link>
      </StyledRow>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>{modalTitle} Notes</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label>Select Date (required)</label>
              <br />
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </div>

            <div class="form-group">
              <label for="noteArea">Enter Note (required)</label>

              <textarea
                className="form-control"
                id="noteArea"
                rows="3"
                value={noteValue}
                onChange={(e) => setNoteValue(e.target.value)}
              ></textarea>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={() => handleClose()}>Cancel</CloseButton>
          <ToolButton
            onClick={saveNotes}
            bsStyle="primary"
            disabled={noteValue && startDate ? false : true}
          >
            Save
          </ToolButton>
        </Modal.Footer>
      </Modal>

      <Modal bsSize="small" show={showDelete.key} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Delete Note </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to delete this Note?</div>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={handleDeleteClose}>Close</CloseButton>
          <DeleteButton
            bsStyle="danger"
            onClick={() => deleteNote(showDelete.uid)}
          >
            Delete
          </DeleteButton>
        </Modal.Footer>
      </Modal>
      <Modal show={callShow} onHide={() => handleCallClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>{modalCallTitle} Notes</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label>Select Call Date (required)</label>
              <br />
              <DatePicker
                selected={startCallDate}
                onChange={handleStartDateCAllChange}
                required
              />
            </div>

            <div class="form-group">
              <label for="noteArea">Enter Note (required)</label>

              <textarea
                className="form-control"
                id="noteArea"
                rows="3"
                value={noteCallValue}
                onChange={(e) => setNoteCallValue(e.target.value)}
              ></textarea>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={() => handleCallClose()}>Cancel</CloseButton>
          <ToolButton
            onClick={saveCallNotes}
            bsStyle="primary"
            disabled={noteCallValue && startCallDate ? false : true}
          >
            Save
          </ToolButton>
        </Modal.Footer>
      </Modal>

      <Modal
        bsSize="small"
        show={showCallDelete.key}
        onHide={handleDeleteCallClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Delete Note </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to delete this Note?</div>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={handleDeleteCallClose}>Close</CloseButton>
          <DeleteButton
            bsStyle="danger"
            onClick={() => deleteCallNote(showCallDelete.uid)}
          >
            Delete
          </DeleteButton>
        </Modal.Footer>
      </Modal>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    customer: state.newcastle.customer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCustomer: (uid) => dispatch(NewCastleCustomers.getNewCastleCustomer(uid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerData);
