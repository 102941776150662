/* @format - for Prettier */
import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Redirect, Link, withRouter } from "react-router-dom";
import InputMoment from "input-moment";
import "input-moment/dist/input-moment.css";
import moment from "moment";
import styled from "styled-components";
import pdf1 from "./pdf-1.jpg";
import {
  Glyphicon,
  FormGroup,
  InputGroup,
  FormControl,
  Button,
  Table,
  Modal,
  Grid,
  Row,
  Col,
} from "react-bootstrap";
import { NewCastleCustomers2 } from "../../actions2";
import { DeniedWrapper } from "../../styled";
import { history } from "../../App";
import classNames from "classnames";

const WrapperWithSidebar = styled.div`
  flex: 1; /* Allow the content wrapper to take up remaining space */
  margin-left: 275px;
  padding: 20px;
`;

const Sidebar = styled.div`
  position: fixed;
  width: 250px;
  height: 655px;
  background-color: #337ab7;
  color: #333;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  border-radius: 10px; /* Add rounded edges */
  justifycontent: center;
`;
const SidebarButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
  text-decoration: none;
  color: black;
`;

var ReceiptArray = [];

const GridWithSidebar = styled(Grid)`
  /* Adjust this value to match the width of your sidebar */
  display: flex;
`;

const StyledColImg = styled(Col)`
  display: flex;
  padding: 0px 42px;
`;

const TitleBox = styled(Row)`
  margin-bottom: 25px !important;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
`;

const StyledRow = styled(Row)`
  margin-bottom: 20px !important;
`;

const StyledCol = styled(Col)`
  padding: 0px;
`;

const StyledDatePicker = styled(InputMoment)`
  width: 100% !important;
`;

const StyledSaveButton = styled.div`
  display: block !important;
  background-color: #1385e5 !important;
  padding: 12px 24px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  border-radius: 3px;
  cursor: pointer;
`;

const SaveButton = classNames({
  "im-btn": true,
  "ion-checkmark": true,
});

const StyledDeleteButton = styled(Button)`
  display: block;
  margin: 0 auto;
`;

const DeleteIcon = styled(Glyphicon)`
  margin-right: 5px;
`;

const StyledAmount = styled.td`
  text-align: end;
  color: ${(props) => (props.status === "paid" ? "#49be25" : "")}
  font-weight: ${(props) => (props.status === "paid" ? "bold" : "normal")}
`;

const StyledStatus = styled.td`
  color: ${(props) => (props.status === "paid" ? "#49be25" : "")}
  font-weight: ${(props) => (props.status === "paid" ? "bold" : "normal")}
`;

const StyledSelect = styled.select`
  margin-bottom: 30px;
`;
const PaymentTypes = ["Credit/Debit Card", "Check", "Cash"];

const paymentOptions = PaymentTypes.map((paymentType) => {
  return {
    label: paymentType,
    value: paymentType,
  };
});

const ReasonTypes = ["Unpaid Bill", "Other: Keep Suspended"];

const ReasonOptions = ReasonTypes.map((reasonType) => {
  return {
    label: reasonType,
    value: reasonType,
  };
});

class NewCastle2History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedHistory: {
        uid: null,
      },
      // states for paid or charged modal
      show: false,
      moment: moment(),
      old_amount: 0,
      default_amount: this.props.customer?.rate,
      form: "",
      form_description: "",
      type_of_payment: "",
      type_of_transaction: "",
      type_of_transaction: "",
      type_of_reason: "",

      // state for Invoice Modal
      show_invoice_dialog: false,
      due_date: moment(),

      // states for Suspended Modal
      show_suspended_modal: false,

      // states for Close Modal
      show_close_modal: false,

      note: "",

      apply_to_charge: null,
      editAmountDue: false,
      amount_due: 0,
      amountChanged: false,
    };
  }

  setTypeOfReason = (e) => {
    this.setState({ type_of_reason: e.target.value });
    console.log(this.state.type_of_reason);
  };
  setTypeOfPayment = (e) => {
    this.setState({ type_of_payment: e.target.value });
    console.log(this.state.type_of_payment);
  };
  handleTypeOfTransaction = (e) => {
    this.setState({ type_of_transaction: e.target.value });
  };
  changeAmountDue = (e) => {
    e.preventDefault();
    this.setState({
      editAmountDue: true,
    });
  };
  changeAmountDue_Save = (e) => {
    e.preventDefault();
    if (this.state.amountChanged) {
      this.props.changeAmountDue(
        this.props.customer.uid,
        this.state.amount_due
      );
    }
    this.setState({
      editAmountDue: false,
      amountChanged: false,
    });
  };
  changeAmountDue_Cancel = (e) => {
    e.preventDefault();
    this.setState({
      editAmountDue: false,
    });
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({
      show: false,
      moment: moment(),
      old_amount: 0,
      default_amount: this.props.customer?.rate,
      form: "",
      form_description: "",
      type_of_payment: "",
      type_of_transaction: "",
      type_of_reason: "",
      selectedHistory: {
        uid: null,
      },
    });
  };

  handleChange = (moment) => {
    this.setState({ moment });
  };

  handleAmountChange = (e) => {
    this.setState({ default_amount: e.target.value });
  };

  handleSave = () => {
    if (this.state.form === "Paid") {
      this.setState({
        amount_due:parseFloat(this.state.amount_due)-parseFloat(this.state.default_amount),
      });
      this.props.makePayment(
        this.props.customer,
        this.state.moment.format("L"),
        parseFloat(this.state.default_amount, 10),
        parseFloat(this.state.old_amount, 10),
        this.state.form_description,
        this.state.selectedHistory.uid,
        this.state.apply_to_charge,
        this.state.type_of_payment,
        this.state.type_of_transaction
      );
      this.handleClose();
    } else if (this.state.form === "Charge") {
      this.setState({
        amount_due: parseFloat(this.state.default_amount, 10) + parseFloat(this.state.amount_due),
      });
      this.props.charge(
        this.props.customer,
        this.state.moment.format("L"),
        parseFloat(this.state.default_amount, 10),
        parseFloat(this.state.old_amount, 10),
        this.state.service_description || "",
        this.state.form_description || "",
        this.state.selectedHistory.uid,
        this.state.type_of_payment,
        this.state.type_of_transaction
      );
      this.handleClose();
    } else if (this.state.form === "Refund") {
      this.setState({
        amount_due: parseFloat(this.state.amount_due) - parseFloat(this.state.default_amount, 10),
      });
      this.props.makePayment(
        this.props.customer,
        this.state.moment.format("L"),
        parseFloat(this.state.default_amount, 10),
        parseFloat(this.state.old_amount, 10),
        this.state.form_description,
        this.state.selectedHistory.uid,
        this.state.apply_to_charge,
        this.state.type_of_payment,
        this.state.type_of_transaction
      );
      this.handleClose();
    } else if (this.state.form === "Credit") {
      this.setState({
        amount_due: parseFloat(this.state.amount_due) - parseFloat(this.state.default_amount, 10),
      });
      this.props.makePayment(
        this.props.customer,
        this.state.moment.format("L"),
        parseFloat(this.state.default_amount, 10),
        parseFloat(this.state.old_amount, 10),
        this.state.form_description,
        this.state.selectedHistory.uid,
        this.state.apply_to_charge,
        this.state.type_of_payment,
        this.state.type_of_transaction
      );
      this.handleClose();
    }
  };
  handlePayment = () => {
    this.setState({
      form: "Paid",
      type_of_transaction: "Paid",
    });
    this.handleShow();
  };
  handleCharge = () => {
    this.setState({
      form: "Charge",
      type_of_transaction: "Charge",
    });
    this.handleShow();
  };
  handleRefund = () => {
    this.setState({
      form: "Refund",
      type_of_transaction: "Refund",
    });
    this.handleShow();
  };
  handleCredit = () => {
    this.setState({
      form: "Credit",
      type_of_transaction: "Credit",
    });
    this.handleShow();
  };
  handleFormDescription = (e) => {
    this.setState({ form_description: e.target.value });
  };
  handleServiceDescription = (e) => {
    this.setState({ service_description: e.target.value });
  };

  handleEdit = (history) => {
    if (history.status === "paid") {
      this.setState({
        form: "Paid",
        transaction_type: "Paid",
        old_amount: history.amount.replace(/[^0-9.]/g, ""),
        default_amount: history.amount.replace(/[^0-9.]/g, ""),
        form_description: history.description,
        moment: moment(history.time, "MM/DD/YYYY"),
        selectedHistory: history,
      });
      this.handleShow();
    } else if (history.status === "charged") {
      this.setState({
        form: "Charge",
        transaction_type: "Charge",
        old_amount: history.amount.replace(/[^0-9.]/g, ""),
        default_amount: history.amount.replace(/[^0-9.]/g, ""),
        form_description: history.description,
        moment: moment(history.time, "MM/DD/YYYY"),
        selectedHistory: history,
        service_description: history.service_description || ``,
      });
      this.handleShow();
    } else if (history.status === "Invoice Sent") {
      console.log(history);
      this.setState({
        show_invoice_dialog: true,
        moment: moment(history.time, "MM/DD/YYYY"),
        due_date: moment(history.due_date, "MM/DD/YYYY"),
        selectedHistory: history,
      });
      this.handleShow();
    } else if (history.status === "Refund") {
      this.setState({
        form: "Refund",
        type_of_transaction: "Refund",
        old_amount: history.amount.replace(/[^0-9.]/g, ""),
        default_amount: history.amount.replace(/[^0-9.]/g, ""),
        form_description: history.description,
        moment: moment(history.time, "MM/DD/YYYY"),
        selectedHistory: history,
      });
      this.handleShow();
    } else if (history.status === "Credit") {
      this.setState({
        form: "Credit",
        type_of_transaction: "Credit",
        old_amount: history.amount.replace(/[^0-9.]/g, ""),
        default_amount: history.amount.replace(/[^0-9.]/g, ""),
        form_description: history.description,
        moment: moment(history.time, "MM/DD/YYYY"),
        selectedHistory: history,
      });
      this.handleShow();
    }
  };

  handleHideInvoiceDialog = () => {
    this.setState({ show_invoice_dialog: false });
  };

  handleShowInvoiceDialog = () => {
    this.handleHideInvoiceDialog();
  };

  handleInvoiceDueDateChange = (moment) => {
    this.setState({ due_date: moment });
  };

  handleCloseAccountAction = () => {
    this.props.closeAccount(this.props.customer);
    this.handleCloseModal();
    this.props.history.push("/ClosedAccounts");
  };

  handleInvoiceSave = () => {
    this.props.updateInvoiceDates(
      this.props.customer,
      this.state.moment.format("L"),
      this.state.due_date.format("L"),
      this.state.selectedHistory.uid
    );
    this.handleHideInvoiceDialog();
  };

  handleSendInvoice = () => {
    history.push("/Invoice/" + this.state.selectedHistory.uid);
  };

  // Suspend Account
  handleSuspendAccount = () => {
    this.setState({ show_suspended_modal: true });
  };

  // Close Account
  handleCloseAccount = () => {
    this.setState({ show_close_modal: true });
  };

  handleActivateAccount = () => {
    this.props.unsuspend(
      this.props.customer,
      this.state.note,
      this.props.customer.amount_due,
      this.state.type_of_reason
    );
    this.setState({ type_of_reason: "" });
  };

  handleNoteChanged = (e) => {
    this.setState({ note: e.target.value });
  };

  handleReceiptArray = (e) => {
    var aa = document.querySelectorAll("input[type=checkbox]");
    var ReceiptArray = [];
    for (var checkbox in aa) {
      if (aa[checkbox].checked) {
        ReceiptArray.push(aa[checkbox].id);
      }
    }
    if(ReceiptArray.length===0) {
      alert("Select atleast one checkbox.");
      return;
    }
    this.props.history.push("/NewCastle2Receipt/" + ReceiptArray);

  };

  handleSaveSuspended = () => {
    if (
      (this.state.type_of_reason === false) |
      (this.state.type_of_reason === "")
    ) {
      alert("Please select a valid reason.");
      return;
    } else {
      this.props.suspend(
        this.props.customer,
        this.state.note,
        this.state.type_of_reason
      );
      this.handleCloseSuspendModal();
    }
  };

  handleCancelSuspend = () => {
    this.handleCloseSuspendModal();
  };

  handleCloseSuspendModal = () => {
    this.setState({ show_suspended_modal: false });
  };

  handleCloseModal = () => {
    this.setState({ show_close_modal: false });
  };

  handleDeleteChargeOrPayment = async () => {
    const amount = parseFloat(
      this.state.selectedHistory.amount.replace(/[^0-9.-]/g, "")
    );
    console.log("+++++>>>", this.state.selectedHistory);
    console.log(amount);

    await this.props.deleteChargeOrPayment(
      this.props.customer,
      -amount,
      this.state.selectedHistory.uid
    );
    this.handleCloseDeleteChargeOrPaymentModal();
    this.setState({ show: false, selectedHistory: { uid: null } });
  };

  handleCancelDeleteChargeOrPayment = () => {
    this.handleCloseDeleteChargeOrPaymentModal();
  };

  handleShowDeleteChargeOrPaymentModal = () => {
    this.setState({ show_delete_modal: true, show: false });
  };
  handleCloseDeleteChargeOrPaymentModal = () => {
    this.setState({ show_delete_modal: false, show: true });
  };

  handleApplyToCharge = (e) => {
    const v = e.currentTarget.value;
    this.setState({
      apply_to_charge: v ? v : null,
    });
  };
  formatDecimal = (number) => {
    let numind = number.indexOf(".");
    let numlen = number.slice(numind);
    if (numlen.length > 2) return `${number.toString()}`;
    return `${number.toString()}0`;
  };
  componentDidMount() {
    this.setState({
      amount_due: parseFloat(this.props.customer.amount_due),
      type_of_reason: this.props.customer.type_of_reason
        ? this.props.customer.type_of_reason
        : "",
    });
    this.props.setCustomer(this.props.customer.uid);
  }
  changeAmountDueValue = (e) => {
    this.setState({
      amount_due: e.target.value
        .toString()
        .split(".")
        .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
        .join("."),
      amountChanged: true,
    });
  };

  render() {
        console.log("amount_due:", this.state.amount_due);

    let histories =
      this.props.customer.history === undefined
        ? []
        : this.props.customer.history;
    // for (var key in histories) {
    //   histories[key] = Object.assign(histories[key], {
    //     uid: key,
    //   });
    // }
    const sortedHistory = histories;
    // _.sortBy(histories, (history) => {
    //   let data = history.time.split("/");
    //   return [data[2], data[0], data[1]].join("-");
    // }).reverse();
    const openCharges =
      (sortedHistory,
      _.reduce(
        this.props.customer.history,
        (acc, val, key) => {
          if (val.status === "charge") {
            acc.push({
              uid: key,
              ...val,
            });
          }

          return acc;
        },
        []
      ));

    if (this.props.authorized) {
      if(!this.props.customer?.name) {
        return <Redirect to="Customers" />;
      } else {
        const { name, status, amount_due } = this.props.customer;
        return (
          <GridWithSidebar>
            <Sidebar>
              <StyledRow
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#337ab7",
                }}
              >
                <StyledCol>
                  <img src={pdf1} alt="pdf1image" />
                </StyledCol>
              </StyledRow>

              <StyledRow>
                <StyledCol sm={20} md={20}></StyledCol>
                <StyledCol sm={20} md={20}></StyledCol>
                <StyledCol sm={20} md={20}>
                  <SidebarButton>
                    <Link to="/NewCastle2/Customer">Back To Customer</Link>
                  </SidebarButton>
                </StyledCol>

                <StyledCol sm={20} md={20}></StyledCol>
                <StyledCol sm={20} md={20}></StyledCol>
                <StyledCol sm={20} md={20}>
                  <SidebarButton>
                    <Link
                      to={
                        "/NewCastle2Invoice/" +
                        (!this.state.selectedHistory
                          ? this.state.selectedHistory.uid
                          : "new")
                      }
                    >
                      Create New Invoice
                    </Link>
                  </SidebarButton>
                </StyledCol>

                <StyledCol sm={20} md={20}></StyledCol>
                <StyledCol sm={20} md={20}></StyledCol>
                <StyledCol sm={20} md={20}>
                  <SidebarButton onClick={this.handleReceiptArray}>
                    Print/Email Receipt
                  </SidebarButton>
                </StyledCol>
              </StyledRow>

              <StyledRow>
                <StyledCol sm={20} md={20}>
                  <SidebarButton onClick={this.handlePayment}>
                    {" "}
                    Add New Payment
                  </SidebarButton>
                </StyledCol>
                <StyledCol sm={20} md={20}>
                  <SidebarButton onClick={this.handleCharge}>
                    {" "}
                    Add New Charge
                  </SidebarButton>
                </StyledCol>
                <StyledCol sm={20} md={20}>
                  <SidebarButton onClick={this.handleRefund}>
                    {" "}
                    Add New Refund
                  </SidebarButton>
                </StyledCol>
                <StyledCol sm={20} md={20}>
                  <SidebarButton onClick={this.handleCredit}>
                    {" "}
                    Add New Credit
                  </SidebarButton>
                </StyledCol>
              </StyledRow>

              <StyledCol sm={20} md={20}>
                <SidebarButton onClick={this.handleSuspendAccount}>
                  Suspend Account
                </SidebarButton>
              </StyledCol>

              <StyledCol sm={20} md={20}></StyledCol>
              <StyledCol sm={20} md={20}></StyledCol>
              <StyledCol sm={20} md={20}>
                <SidebarButton onClick={this.handleCloseAccount}>
                  Close Account
                </SidebarButton>
              </StyledCol>

              <StyledCol sm={20} md={20}></StyledCol>
              <StyledCol sm={20} md={20}></StyledCol>
              <StyledCol sm={20} md={20}>
                <SidebarButton onClick={this.handleActivateAccount}>
                  Activate Account
                </SidebarButton>
              </StyledCol>
            </Sidebar>
            <WrapperWithSidebar>
              <TitleBox>
                <StyledCol sm={4} md={4}>
                  <h3>{name}</h3>
                </StyledCol>
                <StyledColImg sm={2} md={2}>
                  <img src={pdf1} alt="pdf1image" />
                </StyledColImg>
                <StyledCol sm={3} md={3}>
                  <h4>
                    Status:{" "}
                    <span className="font-weight-bold">
                      {status.toLowerCase() !== "suspended"
                        ? status
                        : `${status} - Suspend Reason: ${
                            this.props.customer.type_of_reason
                              ? this.props.customer.type_of_reason
                              : ""
                          }`}
                    </span>
                  </h4>
                </StyledCol>
                <StyledCol sm={3} md={3}>
                  <h4>
                    Amount Due:
                    {this.state.editAmountDue === false ? (
                      <>
                        <span className="font-weight-bold ml-1">
                          ${amount_due && parseFloat(amount_due)?.toFixed(2)}{" "}
                          <p className="d-inline normal-font">
                            <a
                              href="/"
                              className="ml-2"
                              onClick={this.changeAmountDue}
                            >
                              Edit
                            </a>
                          </p>
                        </span>
                      </>
                    ) : (
                      <>
                        <h5>
                          <div className="ml-2 d-flex align-items-center">
                            <input
                              type="number"
                              value={this.state.amount_due}
                              onChange={this.changeAmountDueValue}
                            ></input>
                            <a
                              href="/"
                              className="ml-2"
                              onClick={this.changeAmountDue_Save}
                            >
                              Save
                            </a>
                            <a
                              href="/"
                              className="ml-2"
                              onClick={this.changeAmountDue_Cancel}
                            >
                              Cancel
                            </a>
                          </div>
                        </h5>
                      </>
                    )}
                  </h4>
                </StyledCol>
              </TitleBox>
              <StyledRow>
                <Table responsive condensed striped bordered>
                  <thead>
                    <tr>
                      <th>SELECT FOR RECEIPT</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Amount</th>
                      <th>Payment Type</th>
                      <th>Transaction Type</th>
                      <th>Transaction ID</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(sortedHistory, (history, index) => {
                      if (history.amount !== undefined) {
                        history.amount =
                          history?.amount?.toString().indexOf(".") !== -1
                            ? this.formatDecimal(history.amount.toString())
                            : history.amount.toString() + ".00";

                        history.amount =
                          typeof history.amount === String
                            ? Number(history.amount)
                            : history.amount;
                      }
                      return (
                        <tr key={history.uid}>
                          <td>
                            <input type="checkbox" id={history.uid}></input>
                          </td>
                          <td onClick={() => this.handleEdit(history)}>
                            {history.time}
                          </td>
                          <StyledStatus status={history.status}>
                            {history.status}
                          </StyledStatus>
                          {history.amount ? (
                            <StyledAmount status={history.status}>
                              {" "}
                              {history.amount}{" "}
                            </StyledAmount>
                          ) : (
                            <td />
                          )}
                          <td onClick={() => this.handleEdit(history)}>
                            {history.type_of_payment}
                          </td>{" "}
                          {/* Display the typeOfPayment */}
                          <td onClick={() => this.handleEdit(history)}>
                            {history.type_of_transaction}
                          </td>
                          {history.transaction_id ? (
                            <td onClick={() => this.handleEdit(history)}>
                              {history.transaction_id}
                            </td>
                          ) : (
                            <td />
                          )}
                          {history.description ? (
                            <td onClick={() => this.handleEdit(history)}>
                              {" "}
                              {history.description}{" "}
                            </td>
                          ) : (
                            [
                              history.status === "Invoice Sent" ? (
                                [
                                  history.sent ? (
                                    <td>Sent</td>
                                  ) : (
                                    <td>Waiting to send invoice</td>
                                  ),
                                ]
                              ) : (
                                <td />
                              ),
                            ]
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </StyledRow>
            </WrapperWithSidebar>

            <Modal show={this.state.show} onHide={this.handleClose} bsSize="sm">
              <Modal.Body>
                <StyledRow>
                  <div>Amount {this.state.form}</div>
                  <FormGroup>
                    <InputGroup>
                      <InputGroup.Addon>$</InputGroup.Addon>
                      <FormControl
                        type="text"
                        value={this.state.default_amount}
                        onChange={this.handleAmountChange}
                      />
                      {/* <InputGroup.Addon>.00</InputGroup.Addon> */}
                    </InputGroup>
                  </FormGroup>
                </StyledRow>{" "}
                {this.state.form === "Paid" && (
                  <StyledRow>
                    <div>Apply to charge (optional)?</div>
                    <StyledSelect onChange={this.handleApplyToCharge}>
                      <option
                        selected={!this.state.apply_to_charge}
                        value={false}
                      >
                        No
                      </option>

                      {_.map(openCharges, (charge) => (
                        <option
                          key={charge.uid}
                          value={charge.uid}
                          selected={charge.uid === this.state.apply_to_charge}
                        >
                          {
                            // eslint-disable-next-line
                            charge.amount.replace(/[^0-9\.\$]/, "")
                          }
                          {` - ${moment(charge.due_date).format("MM/DD/YYYY")}`}
                          {charge.service_description
                            ? ` | ${charge.service_description}`
                            : ""}
                        </option>
                      ))}
                    </StyledSelect>
                  </StyledRow>
                )}
                <StyledRow>
                  <div>Type of Payment</div>
                  <StyledSelect
                    onChange={this.setTypeOfPayment}
                    value={this.state.type_of_payment}
                  >
                    <option value={false}>Select Payment Type</option>

                    {paymentOptions.map((paymentOption) => (
                      <option
                        key={paymentOption.value}
                        value={paymentOption.value}
                      >
                        {paymentOption.label}
                      </option>
                    ))}
                  </StyledSelect>
                </StyledRow>
                <StyledRow>
                  <div>Service</div>
                  <FormControl
                    type="text"
                    onChange={this.handleServiceDescription}
                    value={this.state.service_description}
                  />
                </StyledRow>
                <StyledRow>
                  <div>Notes to Customer Service for extra fees</div>
                  <FormControl
                    type="text"
                    componentClass="textarea"
                    rows="3"
                    onChange={this.handleFormDescription}
                    value={this.state.form_description}
                  />
                </StyledRow>
                <StyledRow>
                  <StyledDatePicker
                    moment={this.state.moment}
                    onChange={this.handleChange}
                    onSave={this.handleSave}
                  />
                </StyledRow>
                <StyledRow>
                  <StyledDeleteButton
                    onClick={this.handleShowDeleteChargeOrPaymentModal}
                    bsStyle="danger"
                  >
                    <DeleteIcon glyph="trash" />
                    Delete
                  </StyledDeleteButton>
                </StyledRow>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.show_invoice_dialog}
              onHide={this.handleHideInvoiceDialog}
            >
              <Modal.Body>
                <StyledRow>
                  <StyledCol sm={6} md={6}>
                    <h3 className="text-center">Start Date</h3>
                    <StyledDatePicker
                      moment={this.state.moment}
                      onChange={this.handleChange}
                    />
                  </StyledCol>
                  <StyledCol sm={6} md={6}>
                    <h3 className="text-center">Due Date</h3>
                    <StyledDatePicker
                      moment={this.state.due_date}
                      onChange={this.handleInvoiceDueDateChange}
                    />
                  </StyledCol>
                </StyledRow>
                <StyledRow>
                  <StyledSaveButton
                    className={SaveButton}
                    onClick={this.handleInvoiceSave}
                  >
                    Save
                  </StyledSaveButton>
                </StyledRow>
                <StyledRow>
                  <StyledSaveButton onClick={this.handleSendInvoice}>
                    <Glyphicon glyph="list" />
                    <span style={{ padding: "6px" }}>Details</span>
                  </StyledSaveButton>
                </StyledRow>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.show_close_modal}
              onHide={this.handleCloseModal}
            >
              <Modal.Header>
                <h4>
                  Closing this account will permanently delete this customer
                  from the Customers list.
                </h4>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <p className="font-weight-bold">
                    Are you sure you want to close this account?
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  bsStyle="primary"
                  onClick={this.handleCloseAccountAction}
                >
                  Yes
                </Button>
                <Button onClick={this.handleCloseModal}>No</Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={this.state.show_suspended_modal}
              // onHide={this.handleCloseSuspendModal}
            >
              <Modal.Body>
                <StyledRow>
                  <div>Reason For Suspension</div>
                  <StyledSelect
                    onChange={this.setTypeOfReason}
                    value={this.state.type_of_reason}
                  >
                    <option value={false}>Select Reason</option>

                    {ReasonOptions.map((reasonType) => (
                      <option key={reasonType.value} value={reasonType.value}>
                        {reasonType.label}
                      </option>
                    ))}
                  </StyledSelect>
                </StyledRow>
                <StyledRow>
                  <div>
                    Please enter a note as to why account is being suspended
                  </div>
                  <FormControl
                    type="text"
                    componentClass="textarea"
                    rows="3"
                    onChange={this.handleNoteChanged}
                    value={this.state.note}
                  />
                </StyledRow>
              </Modal.Body>
              <Modal.Footer>
                <Button bsStyle="primary" onClick={this.handleSaveSuspended}>
                  Save
                </Button>
                <Button onClick={this.handleCancelSuspend}>Cancel</Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={this.state.show_delete_modal}
              onHide={this.handleCloseDeleteChargeOrPaymentModal}
            >
              <Modal.Body>
                <div>
                  Are you sure you want to delete this charge / payment?
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  bsStyle="danger"
                  onClick={this.handleDeleteChargeOrPayment}
                >
                  Yes, delete
                </Button>
                <Button onClick={this.handleCancelDeleteChargeOrPayment}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </GridWithSidebar>
        );
      }
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}
const mapStateToProps = (state) => ({
  authorized: state.user?.pages?.history,
  customer: state.newcastle.customer,
});

const mapDispatchToProps = (dispatch) => ({
  makePayment: (
    customer,
    time,
    new_amount,
    old_amount,
    description,
    historyId,
    apply_to_charge,
    type_of_payment,
    type_of_transaction
  ) =>
    dispatch(
      NewCastleCustomers2.makePayment(
        customer,
        time,
        new_amount,
        old_amount,
        description,
        historyId,
        apply_to_charge,
        type_of_payment,
        type_of_transaction
      )
    ),
  suspend: (customer, note, type_of_reason) =>
    dispatch(NewCastleCustomers2.suspendCustomer(customer, note, type_of_reason)),
  unsuspend: (customer, note, amount_due, type_of_reason) =>
    dispatch(
      NewCastleCustomers2.unsuspendCustomer(customer, note, amount_due, type_of_reason)
    ),
  changeAmountDue: (uid, amount_due, type_of_reason) =>
    dispatch(NewCastleCustomers2.changeAmountDue(uid, amount_due, type_of_reason)),
  charge: (
    customer,
    time,
    new_amount,
    old_amount,
    service_description,
    description,
    historyId,
    type_of_payment,
    type_of_transaction,
    type_of_reason
  ) =>
    dispatch(
      NewCastleCustomers2.chargeCustomer(
        customer,
        time,
        new_amount,
        old_amount,
        service_description,
        description,
        historyId,
        type_of_payment,
        type_of_transaction,
        type_of_reason
      )
    ),
  updateInvoiceDates: (customer, time, due_date, historyId, type_of_reason) =>
    dispatch(
      NewCastleCustomers2.updateInvoiceDates(
        customer,
        time,
        due_date,
        historyId,
        type_of_reason
      )
    ),
  deleteChargeOrPayment: (customer, amount, historyId, type_of_reason) =>
    dispatch(
      NewCastleCustomers2.deleteChargeOrPayment(customer, amount, historyId, type_of_reason)
    ),
  closeAccount: (customer) =>
    dispatch(NewCastleCustomers2.closeAccount(customer)),
  setCustomer: (uid) => dispatch(NewCastleCustomers2.getCustomer(uid)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewCastle2History)
);
