/* @format - for Prettier */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Grid,
  Row,
  Col,
  Table,
  Modal,
  Glyphicon,
  Button,
} from "react-bootstrap";
import styled from "styled-components";
import { ResidentialCustomers, WestMayfieldCustomers } from "../../../actions2";
import moment from "moment";
import DatePicker from "react-datepicker";
import { GlyphButton } from "../../Admin";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  deleteClosedCallHistory,
  deleteResidentialCallHistory,
  doAddNotesClosed,
  doAddNotesResidential,
  doClosedCallHistory,
  doDeleteNoteClosed,
  doDeleteNoteResidential,
  doResidentialCallHistory,
} from "../../../firebase/db";

const StyledRow = styled(Row)`
  margin-bottom: 0px !important;
`;
const StyledCol = styled(Col)`
  padding: 0px;
  margin-bottom: 20px;
`;
const Expiry = styled.span`
  padding-right: 10px;
`;

const StyledNotes = styled.div`
  border: 1px solid black;
  height: 60px;
  padding: 5px 10px 5px 10px;
  font-style: italic;
`;

const Header = styled.div`
  display: flex;
`;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  height: 40px;
  margin: 10px 0px 0px 10px;
  float: right;
`;

const EditButton = styled(Button)`
  padding: 5px 20px;
  height: 35px;
  margin: 0px 0px 0px 10px;
  float: right;
`;

const AccordionContainer = styled.div`
  margin: 20px 0px !important;
`;

const AddAddressButton = styled(Button)`
  width: 200px;
  margin-top: 20px;
`;

const DeleteButton = styled(Button)`
  float: right;
`;
const CloseButton = styled(Button)`
  float: left;
`;
/*eslint-disable */
const CustomerData = ({
  setCustomer,
  routes,
  customer: {
    ["acc_number"]: acc_number,
    ["recurringCustomer"]: recurringCustomer,
    ["isExempt"]: isExempt,
    ["billFrequency"]: billFrequency,
    ["wantsEInvoice"]: wantsEInvoice,
    ["recycling_customer"]: recycling_customer,
    ["isInactive"]: isInactive,
    pickup_day,
    name,
    rate,
    status,
    contact_name,
    email,
    companyName,
    toterSerialNumbers,
    phone_numbers,
    service_address,
    billing_address,
    credit_card,
    credit_card_name,
    notes,
    call_history,
    uid,
    stops,
  },
}) => {
  if(notes){
    notes = Object.entries(notes).sort((a,b) => a[1].date > b[1].date ? 1 : -1);
  }
  if(call_history){
    call_history = Object.entries(call_history).sort((a,b) => a[1].date > b[1].date ? 1 : -1);
  }
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [modalTitle, setModalTitle] = useState("Add");
  const [noteValue, setNoteValue] = useState("");
  const [noteUid, setNoteUid] = useState("");
  const [callShow, setCallShow] = useState(false);
  const [startCallDate, setStartCallDate] = useState(new Date());
  const [modalCallTitle, setModalCallTitle] = useState("Add");
  const [noteCallValue, setNoteCallValue] = useState("");
  const [noteCallUid, setNoteCallUid] = useState("");
  const [route, setRoute] = useState([]);
  useEffect(() => {
    if (stops && routes) {
      const arr=routes.filter(route=>route?.stops?.indexOf(Object.keys(stops)[0])>=0);
      setRoute(arr)
    }
  }, [stops])
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleClose = () => {
    setShow(!show);
    setStartDate("");
    setNoteValue("");
    setNoteUid("");
  };
  const handleStartDateCAllChange = (date) => {
    setStartCallDate(date);
  };
  const handleCallClose = () => {
    setCallShow(!callShow);
    setStartCallDate("");
    setNoteCallValue("");
    setNoteCallUid("");
  };

  const saveCallNotes = async () => {
    if (!startCallDate) {
      alert("Please choose the date");
      return;
    } else {
      let obj = {
        date: startCallDate,
        note: noteCallValue,
      };

      let res = doClosedCallHistory(uid, obj, noteCallUid);
      console.log(res, uid);
      setCustomer(uid);
      setCallShow(false);
      setStartCallDate("");
      setNoteCallValue("");
      setNoteCallUid("");
    }
  };

  const deleteCallNote = async (id) => {
    let res = await deleteClosedCallHistory(uid, id);
    setCustomer(uid);
    handleDeleteCallClose();
  };

  const doUpdateCallNote = (customer) => {
    let date = customer.date ? moment(customer.date).toDate() : "";
    setModalCallTitle("Update");
    setStartCallDate(date);
    setNoteCallValue(customer.note);
    setNoteCallUid(customer.uid);
    setCallShow(true);
  };

  const saveNotes = async () => {
    let obj = {
      date: startDate,
      note: noteValue,
    };

    let res = doAddNotesClosed(uid, obj, noteUid,);
    console.log(res, uid);
    setCustomer(uid);
    setShow(false);
    setStartDate("");
    setNoteValue("");
    setNoteUid("");
  };

  const deleteNote = async (id) => {
    let res = await doDeleteNoteClosed(uid, id);
    setCustomer(uid);
    handleDeleteClose();
  };

  const doUpdateNote = (customer) => {
    let date = customer.date ? moment(customer.date).toDate() : "";
    setModalTitle("Update");
    setStartDate(date);
    setNoteValue(customer.note);
    setNoteUid(customer.uid);
    setShow(true);
  };

  const [showCallDelete, setShowCallDelete] = useState({
    key: false,
    uid: "",
  });

  const handleDeleteCallClose = () => {
    setShowCallDelete({
      key: false,
      uid: "",
    });
  };

  const [showDelete, setShowDelete] = useState({
    key: false,
    uid: "",
  });

  const handleDeleteClose = () => {
    setShowDelete({
      key: false,
      uid: "",
    });
  };


  return (
    <Grid>
      <StyledRow>
        <StyledCol sm={4} md={4}>
          <h4>Customer Information:</h4>
          <div>
            Name: <span className="uppercase">{name}</span>
          </div>
          <div>
            Rate: <span className="uppercase">${rate} per month</span>
          </div>
          <div>
            Account Number: <span className="uppercase">{acc_number}</span>
          </div>
          <div>
            Recurring Customer:{" "}
            <span className="uppercase">
              {(recurringCustomer === undefined) | null
                ? "No"
                : recurringCustomer}
            </span>
          </div>
          <div>
            Is Exempt:{" "}
            <span className="uppercase">
              {(isExempt === undefined) | null ? "No" : isExempt}
            </span>
          </div>
          <div>
            Is Inactive:{" "}
            <span className="uppercase">
              {(isInactive === undefined) | null ? "No" : isInactive}
            </span>
          </div>
          <div>
            Bill Frequency:{" "}
            <span className="uppercase">
              {(billFrequency === undefined) | null
                ? "quarterly"
                : billFrequency}
            </span>
          </div>
          <div>
            Wants E-Invoice?{" "}
            <span className="uppercase">
              {(wantsEInvoice === undefined) | null ? "No" : wantsEInvoice}
            </span>
          </div>
          <div>
          Recycling Customer?{" "}
            <span className="uppercase">
              {(recycling_customer === undefined) | null ? "No" : recycling_customer}
            </span>
          </div>
          <div>
            Status:{" "}
            <span className="uppercase">{status ? status : "Unpaid"}</span>
          </div>
        </StyledCol>
        <StyledCol sm={4} md={4} smOffset={1} mdOffset={1}>
          <h4>Contact Information: </h4>
          <div>
            Name: <span className="uppercase">{contact_name}</span>
          </div>
          <div>
            Email: <span className="uppercase">{email}</span>
          </div>
          <div>
            Company Name: <span className="uppercase">{(companyName === undefined) | null  ? '' : companyName } </span>
          </div>
          <div>
          Toter Serial Numbers: <span className="uppercase">{(toterSerialNumbers === undefined) | null  ? '' : toterSerialNumbers}</span>
          </div>
        </StyledCol>
        <StyledCol sm={2} md={2} smOffset={1} mdOffset={1}>
          <h4>Phone Numbers:</h4>
          {_.map(phone_numbers, (phone_number, index) => {
            if (!phone_number.type && phone_number.number) {
              return (
                <li key={index}>
                  Phone:{" "}
                  <span className="uppercase">{phone_number.number}</span>
                </li>
              );
            } else if (!phone_number.type && !phone_number.number) {
              return (
                <li key={index}>
                  Phone:{" "}
                  <span className="uppercase">{phone_number.number}</span>
                </li>
              );
            } else {
              return (
                <li key={index}>
                  {phone_number.type}:{" "}
                  <span className="uppercase">{phone_number.number}</span>
                </li>
              );
            }
          })}
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Service Address:</h4>
          </StyledRow>
          <StyledRow>
            <div className="uppercase"> {service_address?.street} </div>
            <div className="uppercase">
              {" "}
              {service_address?.city}, {service_address?.state}{" "}
              {service_address?.zipcode}{" "}
            </div>
          </StyledRow>
        </StyledCol>
        <StyledCol sm={4} md={4} smOffset={1} mdOffset={1}>
          <StyledRow>
            <h4>Billing Address:</h4>
          </StyledRow>
          <StyledRow>
            <div className="uppercase"> {billing_address?.street} </div>
            <div className="uppercase">
              {" "}
              {billing_address?.city}, {billing_address?.state}{" "}
              {billing_address?.zipcode}{" "}
            </div>
          </StyledRow>
        </StyledCol>
        <StyledCol sm={2} md={2} smOffset={1} mdOffset={1}>
          <h4>Pick-Up Info:</h4>
          <div className="uppercase">{pickup_day}</div>
        </StyledCol>
        <StyledCol sm={2} md={2} smOffset={1} mdOffset={1}>
          <h4>Routes:</h4>
          {route?.map((each,index)=>(
             <div>
              <li key={index}>
                <span className="uppercase">{each.name}</span>
              </li>
             </div>
          ))}
    
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol>
          <StyledRow>
            <AccordionContainer>
              <Accordion allowZeroExpanded="true">
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <label>Notes ({notes !== undefined ? notes?.length : 0})</label>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Header>
                      <div style={{ width: "100%" }}>
                        <ToolButton
                          onClick={() => {
                            setShow(true);
                            setModalTitle("Add");
                          }}
                          bsStyle="primary"
                        >
                          + New
                        </ToolButton>
                      </div>
                    </Header>
                    <br />
                    <Table responsive hover bordered id="print">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Note</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(notes, (note) => {
                          if (note[1]?.uid !== undefined) {
                            return (
                              <tr key={note[1]?.uid}>
                                <td>
                                  <div>
                                    {note[1].date
                                      ? moment
                                          .parseZone(new Date(note[1].date))
                                          .format("LL")
                                      : "---"}
                                  </div>
                                </td>
                                <td>
                                  <div>{note[1].note}</div>
                                </td>
                                <td>
                                  <div className="d-flex gap-2">
                                    <div>
                                      <EditButton
                                        onClick={() => doUpdateNote(note[1])}
                                        bsStyle="primary"
                                      >
                                        Edit
                                      </EditButton>
                                    </div>
                                    <div>
                                      <GlyphButton
                                        onClick={() =>
                                          setShowDelete({
                                            key: true,
                                            uid: note[1].uid,
                                          })
                                        }
                                      >
                                        <Glyphicon glyph="remove" />
                                      </GlyphButton>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        }).reverse(notes)}
                      </tbody>
                    </Table>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </AccordionContainer>
          </StyledRow>
          <StyledRow>
            <AccordionContainer>
              <Accordion allowZeroExpanded="true">
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <label>Call History ({call_history !== undefined ? call_history?.length : 0})</label>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Header>
                      <div style={{ width: "100%" }}>
                        <ToolButton
                          onClick={() => {
                            setCallShow(true);
                            setModalCallTitle("Add");
                          }}
                          bsStyle="primary"
                        >
                          + New
                        </ToolButton>
                      </div>
                    </Header>
                    <br />
                    <Table responsive hover bordered id="print">
                      <thead>
                        <tr>
                          <th>Call Date</th>
                          <th>Note</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(call_history, (call) => {
                          if (call[1]?.uid !== undefined) {
                            return (
                              <tr key={call[1]?.uid}>
                                <td>
                                  <div>
                                    {call[1].date
                                      ? moment
                                          .parseZone(new Date(call[1].date))
                                          .format("LL")
                                      : "---"}
                                  </div>
                                </td>
                                <td>
                                  <div>{call[1].note}</div>
                                </td>
                                <td>
                                  <div className="d-flex gap-2">
                                    <div>
                                      <EditButton
                                        onClick={() =>
                                          doUpdateCallNote(call[1])
                                        }
                                        bsStyle="primary"
                                      >
                                        Edit
                                      </EditButton>
                                    </div>
                                    <div>
                                      <GlyphButton
                                        onClick={() =>
                                          setShowCallDelete({
                                            key: true,
                                            uid: call[1].uid,
                                          })
                                        }
                                      >
                                        <Glyphicon glyph="remove" />
                                      </GlyphButton>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        }).reverse(call_history)}
                      </tbody>
                    </Table>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </AccordionContainer>
          </StyledRow>
        </StyledCol>
      </StyledRow>

      <StyledRow>
        <Link Link to = "/WestMayfield/History">
          History and Invoices</Link>
      </StyledRow>

      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>{modalTitle} Notes</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label>Select Date</label>
              <br />
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </div>

            <div class="form-group">
              <label for="noteArea">Enter Note</label>

              <textarea
                className="form-control"
                id="noteArea"
                rows="3"
                value={noteValue}
                onChange={(e) => setNoteValue(e.target.value)}
              ></textarea>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={() => handleClose()}>Cancel</CloseButton>
          <ToolButton
            onClick={saveNotes}
            bsStyle="primary"
            disabled={noteValue ? false : true}
          >
            Save
          </ToolButton>
        </Modal.Footer>
      </Modal>

      <Modal bsSize="small" show={showDelete.key} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Delete Note </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to delete this Note?</div>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={handleDeleteClose}>Close</CloseButton>
          <DeleteButton
            bsStyle="danger"
            onClick={() => deleteNote(showDelete.uid)}
          >
            Delete
          </DeleteButton>
        </Modal.Footer>
      </Modal>
      <Modal show={callShow} onHide={() => handleCallClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>{modalCallTitle} Notes</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label>Select Call Date</label>
              <br />
              <DatePicker
                selected={startCallDate}
                onChange={handleStartDateCAllChange}
                required
              />
            </div>

            <div class="form-group">
              <label for="noteArea">Enter Note</label>

              <textarea
                className="form-control"
                id="noteArea"
                rows="3"
                value={noteCallValue}
                onChange={(e) => setNoteCallValue(e.target.value)}
              ></textarea>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={() => handleCallClose()}>Cancel</CloseButton>
          <ToolButton
            onClick={saveCallNotes}
            bsStyle="primary"
            disabled={noteCallValue ? false : true}
          >
            Save
          </ToolButton>
        </Modal.Footer>
      </Modal>

      <Modal
        bsSize="small"
        show={showCallDelete.key}
        onHide={handleDeleteCallClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Delete Note </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to delete this Note?</div>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={handleDeleteCallClose}>Close</CloseButton>
          <DeleteButton
            bsStyle="danger"
            onClick={() => deleteCallNote(showCallDelete.uid)}
          >
            Delete
          </DeleteButton>
        </Modal.Footer>
      </Modal>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  customer: state.westmayfield.customer,
  routes:state.routes,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomer: (uid) => dispatch(WestMayfieldCustomers.getCustomer(uid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerData);
