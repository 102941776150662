/* @format - for Prettier */
import _ from "lodash";
import moment from "moment";
import { db, firebase } from "../firebase";
import { history } from "../App";
import { ApiService } from "../api/apiService";
import { _sortBy, CustomerTypes, DBCustomer, AppCustomer } from "../utils/customer";
import axiosInstance from "../api/axios";

export const setCustomers = customers => ({
  type: "SET_GREENBAG_CUSTOMERS",
  customers
});

export const setCurrentCustomer = customer => ({
  type: "SET_CURRENT_GREENBAG_CUSTOMER",
  customer
});

/* Gets all the customers in the database */
export const getCustomers = () => {
  return function(dispatch) {
    ApiService.readAll('customers', null, CustomerTypes.greenbag)
      .then(data => {
        console.log('data in getCustomers', data);
        if (data) {
          console.log('data in getCustomers', data.customers);
          let customers = _.map(data.customers, (customer) => AppCustomer(customer, true));
          customers = _sortBy(customers, ["name"]);
          dispatch(setCustomers(customers));
        }
      })
  };
};

export const saveCustomer = (values, customerId) => {
  return function(dispatch) {
    let updates = {};
    _.forEach(values, (value, key) => {
      if (value !== undefined)
        updates[key] = value;
    });
    ApiService.update('customers', customerId, {
      customer: DBCustomer(updates)
    })
    .then((updated) => {
      if (updated) {
        dispatch(getCustomer(customerId));
        history.push("/GreenBag/Customer");
      }
    });
  };
};

/* Gets a the customer in the database */
export const getCustomer = uid => {
  return function (dispatch) {
    ApiService.readOne('customers', uid)
    .then(data => {
      let customer = data?.customer;
      console.log('customer in getCustomer', customer);
      if(customer)
        dispatch(setCurrentCustomer(AppCustomer(customer, true)));
    })
  };
};

/* Gets a the customer in the database then goes to that page */
export const setCustomer = (uid, redirect = '') => {
  return function (dispatch) {
  ApiService.readOne('customers', uid)
    .then(data => {
      let customer = data?.customer;
      console.log('customer in getCustomer', customer);
      if (customer){
        dispatch(setCurrentCustomer(AppCustomer(customer, true)));
        let url = '/GreenBag/Customer'
        if (redirect.length) url = `/GreenBag/Customer?redirect=${redirect}`
        history.push(url);
      }
    });
  };
};

export const addCustomer = () => {
  return async function(dispatch) {
    let data = {
      name: "New Customer",
      contact_name: "",
      email: "",
      type: CustomerTypes.greenbag,
      rate: "",
      amount_due: 0,
      status: "Not paid",
      recurringCustomer: "No",
      isExempt: "No",
      isAutoRenewed: false,
      isInactive: "No"
    };
    const {
      customer
    } = await ApiService.create('customers', {
      customer: DBCustomer(data)
    })
    dispatch(setCurrentCustomer(AppCustomer(customer, true)));
    history.push("/GreenBag/Customer");
  }
};

export const removeCustomer = customerId => {
  return function (dispatch) {
    ApiService.delete('customers', customerId)
    .then((deleted) => {
      if(deleted)
        dispatch(getCustomers());
    });
  };
};

export const closeAccount = (customer) => {
  return function (dispatch) {
    axiosInstance.post(`/api/customers/account/close`, { customerId: customer.uid, is_closed: 1 })
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCustomers());
      }
    })
  };
}

