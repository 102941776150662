/* @format - for Prettier */
import _ from "lodash";
import moment from "moment";
import readXlsxFile from 'read-excel-file';
import toastr from 'toastr';

import {
  db,
  firebase
} from "../firebase";
import {
  history
} from "../App";
import {
  ApiService
} from "../api/apiService";
import { _sortBy, CustomerTypes, DBCustomer, AppCustomer } from "../utils/customer";
import axiosInstance from "../api/axios";

export const setCustomers = customers => ({
  type: "SET_CUSTOMERS",
  customers
});

export const setClosedAccounts = customers => ({
  type: "SET_CLOSED_ACCOUNTS",
  customers
});

export const setPastDueCustomers = pastDueCustomers => ({
  type: "SET_PAST_DUE_CUSTOMERS",
  pastDueCustomers
});
export const setExpiringCustomers = expCustomers => ({
  type: "SET_EXPIRING_CUSTOMERS",
  expiringCustomers: expCustomers
});

export const setCurrentCustomer = customer => ({
  type: "SET_CURRENT_CUSTOMER",
  customer
});

export const setInvoices = invoices => ({
  type: "SET_INVOICES",
  invoices
});

export const changeAmountDue = (uid, amount_due) => {
  return function (dispatch) {
    axiosInstance.post(`/api/customers/update/amount_due`, {
      customerId: uid,
      amount_due
    })
    .then((res) => {
      if(res.status === 200)
        dispatch(getCustomer(uid));
    });
  }
}

/* Gets all the customers in the database */
export const getCustomers = () => {
  return function (dispatch) {
    ApiService.readAll('customers', null, CustomerTypes.commercial)
      .then(data => {
        console.log('data in getCustomers', data);
        if (data) {
          console.log('data in getCustomers', data.customers);
          let customers = _.map(data.customers, (customer) => AppCustomer(customer, true)); 
          customers = _sortBy(customers, ["name"]);
          dispatch(setCustomers(customers));
        }
      })
  };
};

/* Gets all the closed Accounts in the database */
export const getClosedAccounts = () => {
  return function (dispatch) {
      axiosInstance.get(`/api/customers/closed_accounts`)
      .then(res => {
        // console.log('data in getCustomers', data);
        if (res.data && res.data.customers) {
          console.log('data in getClosedAccounts', res.data.customers);
          let customers = _.map(res.data.customers, (customer) => AppCustomer(customer, true));
          dispatch(setClosedAccounts(customers));
        }
      })
      .catch(error => console.log("error in getClosedAccounts", error));
  };
};

export const getPastDueCustomers = () => {
  return function (dispatch) {
    axiosInstance.get(`/api/customers/past_due`)
      .then(res => {
        let pastDueCustomers = _.map(res.data.customers, (customer) => AppCustomer(customer, true));
        pastDueCustomers = _sortBy(pastDueCustomers, ["name"]);
        console.log('pastDueCustomers', pastDueCustomers);
        dispatch(setPastDueCustomers(pastDueCustomers));
      })
      .catch(error => console.log("error in getPastDueCustomers", error));
  };
};
export const getExpiringCustomers = () => {
  return function (dispatch) {
    axiosInstance.get(`/api/customers/expiring_contracts`)
      .then(res => {
        let expCustomers = _.map(res.data.customers, (customer) => AppCustomer(customer, true));
        expCustomers = _sortBy(expCustomers, ["name"]);
        console.log('expiring_contracts', expCustomers);
        dispatch(setExpiringCustomers(expCustomers));
      })
      .catch(error => console.log("error in getPastDueCustomers", error));
  };
};

export const getInvoices = () => {
  return function (dispatch) {
    axiosInstance.get(`/api/customers/invoices/all`)
      .then(res => {
        if(res.status === 200) {
          let invoices = res.data.invoices || [];
          dispatch(setInvoices(invoices));
        }
      })
      .catch(error => console.log("error in getInvoices", error));
  };
};

export const saveCustomer = (values, customerId, listId, name) => {
  // console.log("values", values, "customerId", customerId, "lQ`FSZZFZFC istId", listId, "name", name);
  return function (dispatch) {
    let updates = {};
    _.forEach(values, (value, key) => {
      if (value !== undefined && value !== null) {
        updates[key] = value;
        console.log("error", key, value)
        if (key === "email" && value) {
          if (listId && listId.length > 0) {
            ApiService.delete('customers', 'mail_list/'+listId)
          }
          if (value.length > 0) {
            axiosInstance.post('/api/customers/add/mail_list', {
              email: value,
              name: name,
              customer_id: customerId
            })
            .catch(error => console.log("error", error, error.message));
          } else {
            updates[`mail_list_id`] = "";
          }
        }
      }
    });
    console.log('updates', updates);
    let data = DBCustomer(updates);
    console.log('data', data);
    ApiService.update('customers', customerId, {
      customer: DBCustomer(updates)
    })
    .then((updated) => {
      if(updated) {
        dispatch(getCustomer(customerId));
        history.push("/Customer");
      }
    })
  };
};

/* Gets a the customer in the database */
export const getCustomer = uid => {
  return function (dispatch) {
    ApiService.readOne('customers', uid)
    .then(data => {
      let customer = data?.customer;
      console.log('customer in getCustomer', customer);
      if(customer)
        dispatch(setCurrentCustomer(AppCustomer(customer, true)));
    })
  };
};

/* Gets a the customer in the database then goes to that page */
export const setCustomer = (uid, redirect = '') => {
  return function (dispatch) {
  ApiService.readOne('customers', uid)
    .then(data => {
      let customer = data?.customer;
      console.log('customer in getCustomer', customer);
      if (customer){
        dispatch(setCurrentCustomer(AppCustomer(customer, true)));
        let url = '/Customer'
        if (redirect.length) url = `/Customer?redirect=${redirect}`
        history.push(url);
      }
    });
  };
};

/* Gets a the customer in the database then goes to that page */
export const setClosedAccountCustomer = (customer, redirect = '') => {
  return function (dispatch) {
    ApiService.readOne('customers', customer.uid)
    .then(data => {
      let customer = data?.customer;
      if (customer) {
        dispatch(setCurrentCustomer(customer));
        let url = '/ClosedAccount/Customer'
        if (redirect.length) url = `/ClosedAccount/Customer?redirect=${redirect}`
        history.push(url);
      }
    });
  };
};

export const reopenClosedCustomer = customerId => {
  return function (dispatch) {
    axiosInstance.post(`/api/customers/account/close`, {
      customerId, is_closed: 0
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch(getClosedAccounts());
      }
    })
  };
}

export const addCustomer = () => {
  return async function (dispatch) {
    let data = {
      name: "New Customer",
      contact_name: "",
      email: "",
      type: CustomerTypes.commercial,
      rate: "",
      amount_due: 0,
      status: "Not paid",
      recurringCustomer: "No",
      isExempt: "No",
      isAutoRenewed: false,
      isInactive: "No"
    };
    const resData = await ApiService.create('customers', {customer: DBCustomer(data)})
    if(resData && resData.customer) {
      dispatch(setCurrentCustomer(AppCustomer(resData.customer, true)));
      history.push("/Customer");
    }
  };
};

export const billAllRecuringCustomers = (quarter, year) => {
  return function (dispatch) {
    axiosInstance.post(`/api/customers/bill/recurring/${CustomerTypes.commercial}`, { quarter, year })
      .then((res) => {
        if (res.status === 200)
          dispatch(getCustomers());
      })
      .catch(error => console.log("error in billAllrecuuringCustomers", error));
  };
};


export const billAllCommercialCustomers = (quarter, year) => {
  return function (dispatch) {
    axiosInstance.post(`/api/customers/bill/${CustomerTypes.commercial}`, {
        quarter,
        year
      })
      .then((res) => {
        if (res.status === 200)
          dispatch(getCustomers());
      })
      .catch(error => console.log("error in billAllrecuuringCustomers", error));
  };
};

export const billMonthCommercialCustomer = (customer, month, year) => {
  return function (dispatch) {
    axiosInstance.post(`/api/customers/bill_monthly`, {
        customerId: customer.uid,
        month,
        year
      })
      .then((res) => {
        if (res.status === 200)
          dispatch(getCustomers());
      })
      .catch(error => console.log("error in billMonthCommercialCustomer", error));
  };
};

export const updateCustomerPrices = (file, path) => {
  return function (dispatch) {
    const updates = [];
    let invalidFormatting = false;
    readXlsxFile(file).then((rows) => {
      invalidFormatting = rows.length === 0;
      rows.forEach((row, index) => {
        if (index !== 0) {
          const newPrice = row[3];
          const accountNumber = row[0];
          if (newPrice !== undefined && typeof (newPrice) === "number") {
            updates.push({
              accountNumber: accountNumber.toString(),
              rate: newPrice.toFixed(2)
            })
          } else {
            invalidFormatting = true;
          }
        }
      })
      if (invalidFormatting) {
        toastr.warning('It seems there are some errors with formatting in the spreadsheet. Some records might not have updated correctly. Please double check the sheet.', 'Warning')
      } else {
        axiosInstance.post(`/api/customers/update_prices`, { prices: updates })
        .then((res) => {
          if (res.status === 200) 
            toastr.success('Customer prices updated successfully.', 'Success')
        })
        .catch(error => console.log("error when updating customer prices ", error));
      }
    })
  };
};

export const removeCustomer = customerId => {
  return function (dispatch) {
    ApiService.delete('customers', customerId)
    .then((deleted) => {
      if(deleted)
        dispatch(getCustomers());
    });
  };
};

export const sendInvoice = (
  customerId,
  historyId,
  date,
  due_date,
  sent,
  amount,
  invoiceName
) => {
  return function (dispatch) {
    axiosInstance.post(`/api/customers/send_invoice`, {
        customerId,
        historyId,
        time: moment(date, "MM/DD/YYYY").toISOString(),
        due_date,
        sent,
        amount,
        invoiceName,
      })
      .then((res) => {
        if(res.status === 200)
          dispatch(getCustomer(customerId));
      })
      .catch(error => console.log("error in send invoice"));
  };
};

export const showInvoice = (customerId, historyId) => {
  return function (dispatch) {
    ApiService.readOne('customers', customerId)
      .then(data => {
        let customer = data?.customer;
        if (customer)
          dispatch(setCurrentCustomer(customer));
        history.push(`/Invoice/${historyId}`);
      })
      .catch(error => console.log("error in getCustomer", error));
  };
};

export const suspendCustomer = (customer, note, type_of_reason) => {
  return function (dispatch) {

    let time = fixTimeStamp(new Date().toISOString());
    axiosInstance.post(`/api/customers/account/suspend`, {
        customerId: customer.uid,
        status: 'Suspended',
        reason: type_of_reason,
        time: time,
        note
      })
      .then((res) => {
        if(res.status === 200) {
          dispatch(getCustomer(customer.uid));
        }
      })
      .catch(error => console.log("error in supsend customer"));
  };
};

export const unsuspendCustomer = (customer, note, amount_due, type_of_reason) => {
  return function (dispatch) {
      let status = "";
      if (amount_due <= 0 && (type_of_reason === undefined || type_of_reason !== "Other: Keep Suspended")) {
        status = "Paid";
      } else {
        status = "Charged";
      }
      let time = fixTimeStamp(new Date().toISOString());
      axiosInstance.post(`/api/customers/account/suspend`, {
          customerId: customer.uid,
          status: status,
          reason: type_of_reason,
          time: time,
          note
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(getCustomer(customer.uid));
          }
        })
        .catch(error => console.log("error in unsupsend customer"));
  };
};

export const makePayment = (
  customer,
  time,
  amount_paid,
  old_amount,
  description,
  historyId,
  apply_to_charge,
  type_of_payment,
  type_of_transaction
) => {
  return function (dispatch) {
    console.log({historyId});
    // Get the date from the string
    time = fixTimeStamp(time);
    const amount_due = parseFloat(customer.amount_due, 10) - amount_paid + old_amount;
    axiosInstance.post('api/customers/make_payment', {
      customerId: customer.uid,
      historyId: Number(historyId),
      amount_due,
      amount_paid,
      description,
      reason: customer.type_of_reason,
      type_of_payment,
      type_of_transaction,
      time,
      apply_to_charge,
    })
    .then((res) => {
      if(res.status === 200)
        dispatch(getCustomer(customer.uid));
    })
    .catch(error => console.log("error in make payment", error));
  };
};

export const fixTimeStamp = (time) => {
  // Get the current date and time
  let now = new Date();

  // Get the date from the string
  let timeString = time;
  let dateObject = new Date(timeString);

  // Set the date object's year, month, and day to match the current time
  dateObject.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());

  // Now dateObject has both the specific date and the exact current time
  let timestamp = dateObject.getTime();
  let dateTimeString = dateObject.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
  return dateTimeString; //time is now a timestamp
  
}

export const chargeCustomer = (
  customer,
  time,
  amount_charged,
  old_amount,
  service_description,
  description,
  historyId,
  type_of_payment,
  type_of_transaction
) => {
  return function (dispatch) {
    let amount_due = customer.amount_due;
    amount_due =
      parseFloat(amount_due, 10) +
      parseFloat(amount_charged, 10) -
      parseFloat(old_amount, 10);

      // Get the current date and time
      let now = new Date();

      // Get the date from the string
      let timeString = time;
      let dateObject = new Date(timeString);

      // Set the date object's year, month, and day to match the current time
      dateObject.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());

      // Now dateObject has both the specific date and the exact current time
      // Get the date from the string
      time = fixTimeStamp(time);

      axiosInstance.post('api/customers/charge', {
        customerId: customer.uid,
        historyId: Number(historyId),
        amount_due: amount_due.toFixed(2),
        status: customer.status,
        amount_charged,
        description,
        service_description,
        type_of_payment,
        type_of_transaction,
        time: time,
      })
      .then((res) => {
        if(res.status === 200)
          dispatch(getCustomer(customer.uid));
      })
      .catch(error => console.log("error in charge customer", error));
  };
};

export const updateInvoiceDates = (customer, time, due_date, historyId) => {
  console.log("historyId", historyId);
  return function (dispatch) {
    ApiService.update('customers', 'history/' + historyId, {
      history: {
        time,
        due_date
      }
    })
      .then((res) => {
        if(res.status === 200)
          dispatch(getCustomer(customer.uid));
      })
      .catch(error => console.log("error in update invoice dates"));
  };
};

export const deleteChargeOrPayment = (customer, amount, historyId) => {
  return function (dispatch) {
        const {
          uid,
          amount_due,
          type_of_reason
        } = customer;
        let updates = {};
        let amountDue = +amount_due - +amount;
        if(amountDue < 0) amountDue = 0;
        updates[`amount_due`] = amountDue.toFixed(2);

        if (type_of_reason !== "Other: Keep Suspended") {
          updates[`status`] = amountDue <= 0 ? "Paid" : "Charged";
        }
        ApiService.delete('customers', 'history/'+historyId)
        .then((deleted) => {
          if(deleted)
            ApiService.update('customers', uid, {customer: updates}).then((updated) => {
              if(updated)
                dispatch(getCustomer(uid));
            });
        })
        .catch(error => console.log("error in delete charge or payment"));
  };
};

export const closeAccount = (customer) => {
  return function (dispatch) {
    axiosInstance.post(`/api/customers/account/close`, {
        customerId: customer.uid, is_closed: 1
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCustomers());
        }
      })
  };
}
