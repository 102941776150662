import jsPDF from "jspdf";
import moment from "moment";
import _ from "lodash";
import {
  logoImage,
  newCastleCustomerCoverLetterBackgroundImage,
  visaMasterImage,
} from "./base64_images.js";
import { ApiService } from "../api/apiService.js";
import axiosInstance from "../api/axios.js";

// Function to show user prompt
function showUserPrompt(message) {
  const promptDiv = document.createElement('div');
  promptDiv.id = 'userPrompt';
  promptDiv.style.position = 'fixed';
  promptDiv.style.top = '50%';
  promptDiv.style.left = '50%';
  promptDiv.style.transform = 'translate(-50%, -50%)';
  promptDiv.style.padding = '20px';
  promptDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
  promptDiv.style.color = 'white';
  promptDiv.style.zIndex = '1000';
  promptDiv.style.fontFamily = 'Arial, sans-serif';
  promptDiv.style.fontSize = '16px';
  
  const messageSpan = document.createElement('span');
  messageSpan.innerText = message;
  
  const dotsSpan = document.createElement('span');
  dotsSpan.id = 'dots';
  dotsSpan.style.display = 'inline-block';
  dotsSpan.style.width = '1em';
  dotsSpan.style.textAlign = 'left';
  
  promptDiv.appendChild(messageSpan);
  promptDiv.appendChild(dotsSpan);
  
  document.body.appendChild(promptDiv);
  
  // Animate the dots
  let dots = '';
  setInterval(() => {
    dots = dots.length < 3 ? dots + '.' : '';
    dotsSpan.innerText = dots;
  }, 500);
}



// Function to close user prompt
function closeUserPrompt() {
  const promptDiv = document.getElementById('userPrompt');
  if (promptDiv) {
    document.body.removeChild(promptDiv);
  }
}

function current_month_quarter() {
  return new Promise((resolve, reject) => {
    var today = new Date();
    var quarter = Math.floor(today.getMonth() / 3);
    var month = today.getMonth();
    const mapping_quarter = [
      "JAN, FEB, MAR",
      "APR, MAY, JUN",
      "JUL, AUG, SEP",
      "OCT, NOV, DEC",
    ];
    const mapping_month = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    resolve({
      month: mapping_month[month],
      quarter: mapping_quarter[quarter],
    });
  });
}

function charged_items(customer) {
    let all_histories = customer.history || [];
    console.log(all_histories,'all histories')
    let charges = [],
      pays = [];
    let paid = 0,
      charged = 0;
    for (let i = 0; i < all_histories.length; i++) {
      let history = all_histories[i];
      let amount = parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10);
      if(isNaN(amount)) amount = 0.0;
      if (history.status === "Invoice Sent" && !history.paid) {
        paid -= amount;
        console.log("return true");
        break;
      }
      if (history.status === "charged") {
        charged += amount;
        charges.push(history);
      } else if (history.status === "paid") {
        pays.push(history);
        paid += amount;
      }
    }
    charges.reverse();
    pays.reverse();
    while (true) {
      if (charges.length === 0) break;
      let am = parseFloat(charges[0].amount.replace(/[^0-9.]/g, ""));
      if(isNaN(am)) am = 0.0;
      if (charged >= am && paid > 0) {
        paid -= am;
        charged -= am;
        charges.shift();
      } else break;
    }
    let array_charges = [];
    for (let i = 0; i < charges.length; i += 1) {
      const item = charges[i];
      let amount = item["amount"].replace("- ", "");
      amount  = parseFloat(amount.replace(/[^0-9.]/g, ""))
      if(!isNaN(amount))
        amount = amount.toFixed(2);
      else amount = "0.00";
      array_charges.push([
        item["time"],
        item["service_description"],
        item["description"],
        amount,
      ]);
    }
    let current_time = moment().format("MM/DD/YYYY");
    const dumpster_addresses = customer.dumpster_addresses;
    const dumpsters = customer.dumpsters;
    let array_dumpsters = [];
    if (dumpster_addresses && dumpster_addresses.length > 0) {
      for (let i = 0; i < dumpster_addresses.length; i += 1) {
        const dumpster_address = dumpster_addresses[i];
        const description = `Service At: ${dumpster_address.street}, ${dumpster_address.city}, ${dumpster_address.state} ${dumpster_address.zipcode}`;
        let service_size = "";
        if (dumpsters && dumpsters.length > 0) {
          // console.log(dumpsters,'my custoemrs in invoice pdf')
          for (let j = 0; j < dumpsters.length; j += 1) {
            const dumpster = dumpsters[j];
            if (i === Number(dumpster.address)) {
              let number = "";
              if (Number(dumpster.number) > 1) {
                number = `(${dumpster.number}X) `;
              }
              if (service_size.length > 0) service_size += " / ";
              service_size += `${number} ${dumpster.type}, ${dumpster.frequency}`;
            }
          }
        }
        array_dumpsters.push([current_time, service_size, description]);
      }
    }
    return {
      charged: charged,
      charges: array_charges,
      dumpsters: array_dumpsters,
    }
}

function toUpperCase(item) {
  if (item === undefined) return undefined;
  else return item.toString().toUpperCase();
}

async function commercial_customer_invoice_pdf_download(
  customers,
  name,
  due_date = "",
  state_time = "",
  customer_number = "",
  billFrequencyLabel = "",
  month_quarter = "",
  additionalText,
  invoice_no,
  setProgress
) {
    const customer_ids = customers.map((customer) => customer.uid);
    console.log(customer_ids, 'customer ids');
    const res = await axiosInstance.post("/api/customers/histories", {
      customer_ids,
    });
    if(res.status === 200) {
      const histories = res.data.histories;
      console.log(histories.length, 'total histories');
      if(histories.length === customers.length)
        for (let i = 0; i < customers.length; i++) {
          customers[i].history = histories[i] || [];
        }
    }
    
  //let statement_time = state_time == "" ? moment().format("MM/DD/YYYY"): state_time.toString() <-- this is the old code changed 08/07/23
  let formatted_due_date = moment(due_date, "MM/DD/YYYY").format("MM/DD/YYYY");
  let statement_time = moment().format("MM/DD/YYYY");
  if (state_time.length) statement_time = state_time;
  var doc = new jsPDF();
  for (let it = 0; it < customers.length; it += 1) {
    var data = customers[it];
    let customer_NO = data["Account Number"];
    if (customer_number.length) customer_NO = customer_number;
    var height = 3;
    doc.addImage(logoImage, "JPEG", 15, height + 5, 40, 15);
    doc.setFontType("bold");
    doc.setFontSize(8);
    doc.setDrawColor(80, 80, 80);
    height += 25;
    doc.text(18, height, "AIKEN REFUSE, Inc.");
    doc.setFontType("normal");
    height += 4;
    doc.text(18, height, "1613 Wampum Road - Ellwood City, PA 16117");
    doc.setFontType("bold");
    doc.text(18, height + 5.5, "724-758-9400 -- info@aikenrefuse.com");

    doc.setFontType("normal");
    doc.text(140, height, "INVOICE DATE:");
    doc.text(186, height, statement_time.toString());
    doc.text(140, height + 4, "DUE DATE:");
    doc.text(186, height + 4, formatted_due_date.toString());
    //doc.text(172, height + 4, 'DUE UPON RECEIPT')
    doc.text(140, height + 8, "CUSTOMER No:");
    doc.text(
      184.5,
      height + 8,
      customer_NO === undefined ? "" : toUpperCase(customer_NO.toString())
    );

    doc.text(140, height + 13, "INVOICE No:");
    doc.text(
      184.5,
      height + 13,
      invoice_no === undefined ? "" : toUpperCase(invoice_no.toString())
    );

    doc.text(140, height + 17, "CHECK No:");
    doc.line(170, height + 17, 200, height + 17);

    doc.text(140, height + 22, "AMOUNT");
    doc.text(140, height + 25, "ENCLOSED:");
    doc.line(170, height + 25, 200, height + 25);

    height += 35;
    doc.text(
      18,
      height,
      data["name"] === undefined ? "" : toUpperCase(data["name"])
    );
    doc.text(
      18,
      height + 4,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["street"])
    );
    doc.text(
      18,
      height + 8,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["city"]) +
            ", " +
            toUpperCase(data["billing_address"]["state"]) +
            " " +
            toUpperCase(data["billing_address"]["zipcode"])
    );

    height -= 3;
    doc.setFontType("bold");
    if (month_quarter === "") {
      month_quarter = await current_month_quarter();
      // const month_quarter = await current_month_quarter()
      const billFrequency = data.billFrequency;
      var service_label = "SERVICE QUARTER:";
      var m_q = month_quarter.quarter;
      if (billFrequency === "monthly") {
        service_label = "SERVICE MONTH:";
        m_q = month_quarter.month;
      }
    } else {
      const billFrequency = data.billFrequency;
      var service_label = "SERVICE QUARTER:";
      var m_q = month_quarter;
      if (billFrequency === "monthly") {
        service_label = "SERVICE MONTH:";
        m_q = month_quarter;
      }
    }

    if (billFrequencyLabel.length) m_q = billFrequencyLabel;
    var dumpster_addresses = data.dumpster_addresses;
    if (dumpster_addresses && dumpster_addresses.length === 1) {
      service_label = "FOR SERVICE AT:";
    }
    doc.text(140, height, service_label);
    doc.setFontType("normal");
    if (dumpster_addresses && dumpster_addresses.length > 1) {
      doc.text(140, height + 4, toUpperCase(m_q));
    } else {
      doc.text(
        140,
        height + 4,
        data["name"] === undefined ? "" : toUpperCase(data["name"])
      );
      if (dumpster_addresses) {
        doc.text(
          140,
          height + 8,
          dumpster_addresses[0] === undefined
            ? ""
            : toUpperCase(dumpster_addresses[0]["street"])
        );
        doc.text(
          140,
          height + 12,
          dumpster_addresses[0] === undefined
            ? ""
            : toUpperCase(dumpster_addresses[0]["city"]) +
                ", " +
                toUpperCase(dumpster_addresses[0]["state"]) +
                " " +
                toUpperCase(dumpster_addresses[0]["zipcode"])
        );
      }
    }
    height += 30;
    doc.setFontType("bold");
    doc.setFontSize(10);
    doc.text(10, height, "SERVICE DESCRIPTION");
    doc.setFontSize(8);
    const { dumpsters, charged, charges } = await charged_items(data);
    // console.log(res,'my res,a')
    if (dumpster_addresses && dumpster_addresses.length > 1) {
      var rdata = dumpsters;
      var rec_length = rdata.length;
      var rec_s = 4;
      if (rec_length > 4) rec_s = rec_length;
      doc.rect(6, height + 3, 198, 9 * (rec_s + 1));
      doc.line(6, height + 12, 204.25, height + 12);
      doc.setDrawColor(180, 180, 180);
      for (let i = 0; i < rec_s - 1; i += 1) {
        doc.line(6, height + 21 + 9 * i, 204.25, height + 21 + 9 * i);
      }

      var width_table = [6, 25, 95];
      doc.line(
        width_table[1],
        height + 3,
        width_table[1],
        height + 3 + 9 * (rec_s + 1)
      );
      doc.line(
        width_table[2],
        height + 3,
        width_table[2],
        height + 3 + 9 * (rec_s + 1)
      );

      doc.text(12, height + 8.5, "DATE");
      doc.text(50, height + 8.5, "SERVICE SIZE");
      doc.text(140, height + 8.5, "DESCRIPTION");

      doc.setFontType("normal");

      for (let i = 0; i < rdata.length; i += 1) {
        for (let j = 0; j < 3; j += 1) {
          if (j !== 1)
            doc.text(
              width_table[j] + 3,
              height + 18 + 9 * i,
              rdata[i][j] === undefined ? "" : toUpperCase(rdata[i][j])
            );
        }
        const j = 1;
        // one line
        const length = rdata[i][j].length;
        if (length < 45 || length > 90) {
          doc.text(
            width_table[j] + 3,
            height + 18 + 9 * i,
            rdata[i][j] === undefined ? "" : toUpperCase(rdata[i][j])
          );
        }
        // two line
        else {
          let k = 45;
          for (; k > 0; k -= 1) {
            if (rdata[i][j][k] === " ") break;
          }
          doc.text(
            width_table[j] + 3,
            height + 16 + 9 * i,
            rdata[i][j] === undefined
              ? ""
              : toUpperCase(rdata[i][j].substring(0, k + 1))
          );
          doc.text(
            width_table[j] + 3,
            height + 20 + 9 * i,
            rdata[i][j] === undefined
              ? ""
              : toUpperCase(rdata[i][j].substring(k + 1, length))
          );
        }
      }
      height += 9 * (rec_s + 1) + 11;
    } else {
      var rdata2 = charges;
      if (rdata2.length <= 4) {
        doc.rect(8, height + 3, 193, 45);
      } else {
        doc.rect(8, height + 3, 193, 9 * (rdata2.length + 1));
      }
      doc.line(8, height + 12, 201, height + 12);
      doc.setDrawColor(180, 180, 180);
      if (rdata2.length <= 4) {
        doc.line(8, height + 21, 201, height + 21);
        doc.line(8, height + 30, 201, height + 30);
        doc.line(8, height + 39, 201, height + 39);
      } else {
        for (let i = 0; i < rdata2.length; i += 1) {
          doc.line(
            8,
            height + 12 + 9 * (i + 1),
            201,
            height + 12 + 9 * (i + 1)
          );
        }
      }

      var width_table2 = [8, 40, 100, 150];
      if (rdata2.length <= 4) {
        doc.line(width_table2[1], height + 3, width_table2[1], height + 48);
        doc.line(width_table2[2], height + 3, width_table2[2], height + 48);
        doc.line(width_table2[3], height + 3, width_table2[3], height + 48);
      } else {
        doc.line(
          width_table2[1],
          height + 3,
          width_table2[1],
          height + 12 + 9 * rdata2.length
        );
        doc.line(
          width_table2[2],
          height + 3,
          width_table2[2],
          height + 12 + 9 * rdata2.length
        );
        doc.line(
          width_table2[3],
          height + 3,
          width_table2[3],
          height + 12 + 9 * rdata2.length
        );
      }

      doc.text(20, height + 8.5, "DATE");
      doc.text(60, height + 8.5, "SERVICE SIZE");
      doc.text(115, height + 8.5, "DESCRIPTION");
      doc.text(168, height + 8.5, "AMOUNT");

      console.log(rdata2, "invoices");

      doc.setFontType("normal");
      for (let i = 0; i < rdata2.length; i += 1) {
        for (let j = 0; j < rdata2[i].length; j++) {
          // console.log(rdata2[i][j], "my jsskjflsdjfl");
          doc.text(
            width_table2[j] + 4,
            height + 18 + 9 * i,
            rdata2[i][j] === undefined ? "" : toUpperCase(rdata2[i][j])
          );
        }
      }
      if (rdata2.length <= 4) {
        height += 56;
      } else {
        height += 12 + 9 * (rdata2.length + 1);
      }
    }

    doc.setFontType("bold");
    doc.setFontSize(10);
    doc.text(142, height, "TOTAL BALANCE DUE:");
    let total_dollar = data.amount_due;

    if (isNaN(data.amount_due) || data.amount_due === undefined)
      total_dollar = "$0.00";

    const formatDecimal = (number) => {
      let numind = number.indexOf(".");
      let numlen = number.slice(numind);
      if (numlen.length > 2) return `${number.toString()}`;
      return `${number.toString()}0`;
    };
    if(data.amount_due == null) total_dollar = "0";
    if (data.amount_due !== undefined && data.amount_due !== null) {
      total_dollar =
        total_dollar.toString().indexOf(".") !== -1
          ? formatDecimal(total_dollar.toString())
          : total_dollar.toString() + ".00";
    
      if (total_dollar.indexOf("$") !== -1) {
        total_dollar =
          typeof total_dollar == "string"
            ? `${Number(total_dollar)}`
            : `${total_dollar}`;
      } else {
        total_dollar =
          typeof total_dollar == "string"
            ? `$${Number(total_dollar)}`
            : `$${total_dollar}`;
      }
    }    
    doc.text(200 - 1.9 * total_dollar.length, height, total_dollar);
    doc.setFontSize(8);

    doc.setFontType("bold");
    doc.setFontSize(10);
    doc.text(10, height, "Additional Notes: ");
    doc.setFontSize(8);
    doc.text(10, height + 5, additionalText);

    height = 222;
    doc.setDrawColor(70, 70, 70);
    for (let i = 1; i < 39; i += 1) {
      if (i >= 4 && i <= 8) continue;
      if (i >= 17 && i <= 22) continue;
      if (i >= 31 && i <= 35) continue;
      doc.line(8 + i * 5, height, i * 5 + 5, height);
    }
    doc.setFontSize(6.5);
    doc.text(28, height + 1, "DETACH HERE");
    doc.text(96, height + 1, "DETACH HERE");
    doc.text(164, height + 1, "DETACH HERE");
    doc.setFontSize(8);
    doc.setDrawColor(80, 80, 80);
    doc.setFontType("normal");
    height += 9;
    doc.setFontSize(9);
    doc.text(50, height, "IF paying by");
    doc.text(78, height, ", PLEASE RETURN this ");
    doc.text(130, height, " with your Payment");
    doc.setFontType("bold");
    doc.text(68, height, "Check");
    doc.text(113, height, "Remittance");
    doc.setFontSize(8);
    doc.setFontType("normal");
    height += 12;
    doc.text(18, height, "BILL TO:");
    doc.text(
      18,
      height + 4,
      data["name"] === undefined ? "" : toUpperCase(data["name"])
    );
    doc.text(
      18,
      height + 8,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["street"])
    );
    doc.text(
      18,
      height + 12,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["city"]) +
            ", " +
            toUpperCase(data["billing_address"]["state"]) +
            " " +
            toUpperCase(data["billing_address"]["zipcode"])
    );

    doc.text(85, height, service_label);
    if (dumpster_addresses && dumpster_addresses.length > 1) {
      doc.text(85, height + 4, toUpperCase(m_q));
    } else {
      doc.text(
        85,
        height + 4,
        data["name"] === undefined ? "" : toUpperCase(data["name"])
      );
      if (dumpster_addresses) {
        doc.text(
          85,
          height + 8,
          dumpster_addresses[0] === undefined
            ? ""
            : toUpperCase(dumpster_addresses[0]["street"])
        );
        doc.text(
          85,
          height + 12,
          dumpster_addresses[0] === undefined
            ? ""
            : toUpperCase(dumpster_addresses[0]["city"]) +
                ", " +
                toUpperCase(dumpster_addresses[0]["state"]) +
                " " +
                toUpperCase(dumpster_addresses[0]["zipcode"])
        );
      }
    }

    doc.text(140, height, "INVOICE DATE:");
    doc.text(186, height, statement_time.toString());
    doc.text(140, height + 4, "DUE DATE:");
    doc.text(186, height + 4, formatted_due_date.toString());
    //doc.text(172, height + 4, 'DUE UPON RECEIPT')
    doc.text(140, height + 8, "CUSTOMER No:");
    doc.text(
      184.5,
      height + 8,
      customer_NO === undefined ? "" : toUpperCase(customer_NO.toString())
    );
    doc.text(140, height + 12.5, "CHECK No:");
    doc.line(170, height + 12.5, 200, height + 12.5);
    doc.text(140, height + 18, "AMOUNT");
    doc.text(140, height + 21, "ENCLOSED:");
    doc.line(170, height + 21, 200, height + 21);
    doc.setFontType("bold");
    doc.text(140, height + 29, "TOTAL DUE:");
    doc.text(200 - 1.9 * total_dollar.length, height + 29, total_dollar);

    height += 22;

    height += 15;

    height += 7;
    doc.setFontType("normal");
    doc.setFontSize(8);
    doc.text(
      30,
      height,
      "IMPORTANT NOTICE: PAYMENTS NOT RECEIVED BY THE DUE DATE WILL RESULT IN A DISRUPTION OF SERVICE"
    );
    // doc.text(66, height, 'Recurring Credit Card Program')
    doc.text(
      28,
      height + 3,
      "If you are already enrolled in the Recurring Credit Card Program there is no need to remit payment. If you would like to enroll or"
    );
    doc.text(
      55,
      height + 6,
      "make changes for a Recurring Credit Card, please contact our office at (724) 758-9400. "
    );
    doc.setFontType("bold");
    doc.text(85, height + 9, " Thank you for your business!");
    if (it !== customers.length - 1) {
      doc.addPage();
    }
  }
  if (name === "base64") {
    return doc.output("datauristring");
  } else {
    let fileName = "";
    if (name === "montly")
      fileName = `Invoice montly - ${customers[0]["name"]}.pdf`;
    else fileName = `Invoice ${name}.pdf`;
    doc.save(fileName);
  }
}

async function residential_customer_invoice_pdf_download(
  customers,
  name,
  due_date = "",
  state_date = "",
  customer_number = "",
  setProgress
) {

  showUserPrompt('Downloading please do not close or refresh window...');
  //  if(due_date === ''){
  //    const current = moment();
  //    due_date = current.add(30, 'days').format('MM/DD/YYYY');
  //  }
    const customer_ids = customers.map((customer) => customer.uid);
    console.log(customer_ids, 'customer ids');

    const res = await axiosInstance.post("/api/customers/histories", {
      customer_ids,
    });
    if (res.status === 200) {
      const histories = res.data.histories;
      console.log(histories.length, 'total histories');
      if (histories.length === customers.length)
        for (let i = 0; i < customers.length; i++) {
          customers[i].history = histories[i] || [];
        }
    }
    if (setProgress){
      setProgress(100);

    }
      

  let formatted_due_date = moment(due_date, "MM/DD/YYYY").format("MM/DD/YYYY"); //tried adding this to get it to work but its still giving error
  let statement_date = moment().format("MM/DD/YYYY");
  if (state_date.length) statement_date = state_date;
  var doc = new jsPDF();
  for (let it = 0; it < customers.length; it += 1) {
    var data = customers[it];
    let customer_NO = data["Account Number"];
    if (customer_number.length) customer_NO = customer_number;
    var height = 3;
    doc.addImage(logoImage, "JPEG", 15, height + 5, 40, 15);
    doc.setFontType("bold");
    doc.setFontSize(8);
    doc.setDrawColor(80, 80, 80);
    height += 25;
    doc.text(18, height, "AIKEN REFUSE, Inc.");
    doc.setFontType("normal");
    height += 4;
    doc.text(18, height, "1613 Wampum Road - Ellwood City, PA 16117");
    doc.setFontType("bold");
    doc.text(18, height + 5.5, "724-758-9400 -- info@aikenrefuse.com");

    doc.setFontType("normal");
    doc.text(140, height, "STATEMENT DATE:");
    doc.text(186, height, statement_date.toString()); //this line or formatted_due_date is wrong
    doc.text(140, height + 4, "DUE DATE:");
    doc.text(186, height + 4, formatted_due_date.toString()); //might be this line giving us issues
    doc.text(140, height + 8, "CUSTOMER No:");
    doc.text(
      182,
      height + 8,
      customer_NO === undefined ? "" : toUpperCase(customer_NO.toString())
    );
    doc.text(140, height + 13, "CHECK No:");
    doc.line(170, height + 13, 200, height + 13);
    doc.text(140, height + 18, "AMOUNT");
    doc.text(140, height + 21, "ENCLOSED:");
    doc.line(170, height + 21, 200, height + 21);

    height += 35;
    doc.text(
      18,
      height,
      data["name"] === undefined ? "" : toUpperCase(data["name"])
    );
    doc.text(
      18,
      height + 4,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["street"])
    );
    doc.text(
      18,
      height + 8,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["city"]) +
            ", " +
            toUpperCase(data["billing_address"]["state"]) +
            " " +
            toUpperCase(data["billing_address"]["zipcode"])
    );

    height -= 3;
    doc.setFontType("bold");
    doc.text(140, height, "FOR SERVICE AT:");
    doc.setFontType("normal");
    doc.text(
      140,
      height + 4,
      data["name"] === undefined ? "" : toUpperCase(data["name"])
    );
    doc.text(
      140,
      height + 8,
      data["service_address"] === undefined
        ? ""
        : toUpperCase(data["service_address"]["street"])
    );
    doc.text(
      140,
      height + 12,
      data["service_address"] === undefined
        ? ""
        : toUpperCase(data["service_address"]["city"]) +
            ", " +
            toUpperCase(data["service_address"]["state"]) +
            " " +
            toUpperCase(data["service_address"]["zipcode"])
    );

    const {
      dumpsters,
      charged,
      charges
    } = await charged_items(data);
    var rdata = charges;

    height += 30;
    doc.setFontType("bold");
    doc.setFontSize(10);
    doc.text(10, height, "SERVICE DESCRIPTION");
    doc.setFontSize(8);
    if (rdata.length <= 4) {
      doc.rect(8, height + 3, 193, 45);
    } else {
      doc.rect(8, height + 3, 193, 9 * (rdata.length + 1));
    }
    doc.line(8, height + 12, 201, height + 12);
    doc.setDrawColor(180, 180, 180);
    if (rdata.length <= 4) {
      doc.line(8, height + 21, 201, height + 21);
      doc.line(8, height + 30, 201, height + 30);
      doc.line(8, height + 39, 201, height + 39);
    } else {
      for (let i = 0; i < rdata.length; i += 1) {
        doc.line(8, height + 12 + 9 * (i + 1), 201, height + 12 + 9 * (i + 1));
      }
    }

    var width_table = [8, 40, 100];
    if (rdata.length <= 4) {
      doc.line(width_table[1], height + 3, width_table[1], height + 48);
      doc.line(width_table[2], height + 3, width_table[2], height + 48);
    } else {
      doc.line(
        width_table[1],
        height + 3,
        width_table[1],
        height + 12 + 9 * rdata.length
      );
      doc.line(
        width_table[2],
        height + 3,
        width_table[2],
        height + 12 + 9 * rdata.length
      );
    }

    doc.text(20, height + 8.5, "DATE");
    doc.text(60, height + 8.5, "SERVICE SIZE");
    doc.text(142, height + 8.5, "DESCRIPTION");

    doc.setFontType("normal");
    for (let i = 0; i < rdata.length; i += 1) {
      for (let j = 0; j < 3; j += 1) {
        doc.text(
          width_table[j] + 3,
          height + 18 + 9 * i,
          rdata[i][j] === undefined ? "" : toUpperCase(rdata[i][j])
        );
      }
    }

    if (rdata.length <= 4) {
      height += 56;
    } else {
      height += 12 + 9 * (rdata.length + 1);
    }
    doc.setFontType("bold");
    doc.setFontSize(10);
    doc.text(142, height, "TOTAL BALANCE DUE:");
    let total_dollar = data.amount_due;

    if (isNaN(data.amount_due) || data.amount_due === undefined)
      total_dollar = "$0.00";
    const formatDecimal = (number) => {
      let numind = number.indexOf(".");
      let numlen = number.slice(numind);
      if (numlen.length > 2) return `${number.toString()}`;
      return `${number.toString()}0`;
    };

    if(data.amount_due == null) total_dollar = "0";
    if (data.amount_due !== undefined && data.amount_due !== null) {
      total_dollar =
        total_dollar.toString().indexOf(".") !== -1
          ? formatDecimal(total_dollar.toString())
          : total_dollar.toString() + ".00";

      if (total_dollar.indexOf("$") !== -1) {
        total_dollar =
          typeof total_dollar === "string"
            ? `${Number(total_dollar)}`
            : `${total_dollar}`;
      } else {
        total_dollar =
          typeof total_dollar === "string"
            ? `$${Number(total_dollar)}`
            : `$${total_dollar}`;
      }
    }

    doc.text(200 - 1.9 * total_dollar.length, height, total_dollar);
    doc.setFontSize(8);

    height = 217;
    doc.setDrawColor(70, 70, 70);
    for (let i = 1; i < 39; i += 1) {
      if (i >= 4 && i <= 8) continue;
      if (i >= 17 && i <= 22) continue;
      if (i >= 31 && i <= 35) continue;
      doc.line(8 + i * 5, height, i * 5 + 5, height);
    }
    doc.setFontSize(6.5);
    doc.text(28, height + 1, "DETACH HERE");
    doc.text(96, height + 1, "DETACH HERE");
    doc.text(164, height + 1, "DETACH HERE");
    doc.setFontSize(8);
    doc.setDrawColor(80, 80, 80);
    doc.setFontType("normal");
    height += 9;
    doc.setFontSize(9);
    doc.text(50, height, "IF paying by");
    doc.text(78, height, ", PLEASE RETURN this ");
    doc.text(130, height, " with your Payment");
    doc.setFontType("bold");
    doc.text(68, height, "Check");
    doc.text(113, height, "Remittance");
    doc.setFontSize(8);
    doc.setFontType("normal");
    height += 12;
    doc.text(18, height, "BILL TO:");
    doc.text(
      18,
      height + 4,
      data["name"] === undefined ? "" : toUpperCase(data["name"])
    );
    doc.text(
      18,
      height + 8,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["street"])
    );
    doc.text(
      18,
      height + 12,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["city"]) +
            ", " +
            toUpperCase(data["billing_address"]["state"]) +
            " " +
            toUpperCase(data["billing_address"]["zipcode"])
    );

    doc.text(85, height, "SERVICE AT:");
    doc.text(
      85,
      height + 4,
      data["name"] === undefined ? "" : toUpperCase(data["name"])
    );
    doc.text(
      85,
      height + 8,
      data["service_address"] === undefined
        ? ""
        : toUpperCase(data["service_address"]["street"])
    );
    doc.text(
      85,
      height + 12,
      data["service_address"] === undefined
        ? ""
        : toUpperCase(data["service_address"]["city"]) +
            ", " +
            toUpperCase(data["service_address"]["state"]) +
            " " +
            toUpperCase(data["service_address"]["zipcode"])
    );

    doc.text(140, height, "STATEMENT DATE:");
    doc.text(186, height, statement_date.toString());
    doc.text(140, height + 4, "DUE DATE:");
    doc.text(186, height + 4, formatted_due_date.toString());
    doc.text(140, height + 8, "CUSTOMER No:");
    doc.text(
      182,
      height + 8,
      customer_NO === undefined ? "" : toUpperCase(customer_NO.toString())
    );
    doc.text(140, height + 12.5, "CHECK No:");
    doc.line(170, height + 12.5, 200, height + 12.5);
    doc.text(140, height + 18, "AMOUNT");
    doc.text(140, height + 21, "ENCLOSED:");
    doc.line(170, height + 21, 200, height + 21);
    doc.setFontType("bold");
    doc.text(140, height + 29, "TOTAL DUE:");
    doc.text(200 - 1.9 * total_dollar.length, height + 29, total_dollar);

    height += 22;

    height += 15;

    height += 7;
    doc.setFontType("normal");
    doc.setFontSize(8);
    doc.text(
      30,
      height,
      "IMPORTANT NOTICE: PAYMENTS NOT RECEIVED BY THE DUE DATE WILL RESULT IN A DISRUPTION OF SERVICE"
    );
    // doc.text(66, height, 'Recurring Credit Card Program')
    doc.text(
      28,
      height + 3,
      "If you are already enrolled in the Recurring Credit Card Program there is no need to remit payment. If you would like to enroll or"
    );
    doc.text(
      55,
      height + 6,
      "make changes for a Recurring Credit Card, please contact our office at (724) 758-9400. "
    );
    doc.setFontType("bold");
    doc.text(85, height + 9, " Thank you for your business!");
    if (it !== customers.length - 1) {
      doc.addPage();
    }
  }
  if (name === "base64") {
    return doc.output("datauristring");
  } else {
    let fileName = "";
    if (name === "montly")
      fileName = `Invoice montly - ${customers[0]["name"]}.pdf`;
    else fileName = `Invoice ${name}.pdf`;
    doc.save(fileName);
  }

  closeUserPrompt();
}

async function westmayfield_customer_invoice_pdf_download(
  customers,
  name,
  due_date = "",
  state_date = "",
  customer_number = "",
  setProgress
) {
  // if(due_date === ''){
  //   const current = moment();
  //   due_date = current.add(10, 'days').format('MM/DD/YYYY');
  // }

  showUserPrompt('Downloading please do not close or refresh window...');

    const customer_ids = customers.map((customer) => customer.uid);
    console.log(customer_ids, 'customer ids');
    const interval = setInterval(() => {
      if (setProgress)
        setProgress((prev) => {
          if (prev < 100) return prev + 10;
          return prev;
        });
    }, 10000);
    const res = await axiosInstance.post("/api/customers/histories", {
      customer_ids,
    });
    if (res.status === 200) {
      const histories = res.data.histories;
      console.log(histories.length, 'total histories');
      if (histories.length === customers.length)
        for (let i = 0; i < customers.length; i++) {
          customers[i].history = histories[i] || [];
        }
    }
    clearInterval(interval);
    if (setProgress)
      setProgress(100);


  let formatted_due_date = moment(due_date, "MM/DD/YYYY").format("MM/DD/YYYY");
  let statement_date = moment().format("MM/DD/YYYY");
  if (state_date.length) statement_date = state_date;
  var doc = new jsPDF();
  for (let it = 0; it < customers.length; it += 1) {
    var data = customers[it];
    let customer_NO = data["Account Number"];
    if (customer_number.length) customer_NO = customer_number;
    var height = 3;
    doc.addImage(logoImage, "JPEG", 15, height + 5, 40, 15);
    doc.setFontType("bold");
    doc.setFontSize(8);
    doc.setDrawColor(80, 80, 80);
    height += 25;
    doc.text(18, height, "AIKEN REFUSE, Inc.");
    doc.setFontType("normal");
    height += 4;
    doc.text(18, height, "1613 Wampum Road - Ellwood City, PA 16117");
    doc.setFontType("bold");
    doc.text(18, height + 5.5, "724-758-9400 -- info@aikenrefuse.com");

    doc.setFontType("normal");
    doc.text(140, height, "STATEMENT DATE:");
    doc.text(186, height, statement_date.toString());
    doc.text(140, height + 4, "DUE DATE:");
    doc.text(186, height + 4, formatted_due_date.toString());
    doc.text(140, height + 8, "CUSTOMER No:");
    doc.text(
      182,
      height + 8,
      customer_NO === undefined ? "" : toUpperCase(customer_NO.toString())
    );
    doc.text(140, height + 13, "CHECK No:");
    doc.line(170, height + 13, 200, height + 13);
    doc.text(140, height + 18, "AMOUNT");
    doc.text(140, height + 21, "ENCLOSED:");
    doc.line(170, height + 21, 200, height + 21);

    height += 35;
    doc.text(
      18,
      height,
      data["name"] === undefined ? "" : toUpperCase(data["name"])
    );
    doc.text(
      18,
      height + 4,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["street"])
    );
    doc.text(
      18,
      height + 8,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["city"]) +
            ", " +
            toUpperCase(data["billing_address"]["state"]) +
            " " +
            toUpperCase(data["billing_address"]["zipcode"])
    );

    height -= 3;
    doc.setFontType("bold");
    doc.text(140, height, "FOR SERVICE AT:");
    doc.setFontType("normal");
    doc.text(
      140,
      height + 4,
      data["name"] === undefined ? "" : toUpperCase(data["name"])
    );
    doc.text(
      140,
      height + 8,
      data["service_address"] === undefined
        ? ""
        : toUpperCase(data["service_address"]["street"])
    );
    doc.text(
      140,
      height + 12,
      data["service_address"] === undefined
        ? ""
        : toUpperCase(data["service_address"]["city"]) +
            ", " +
            toUpperCase(data["service_address"]["state"]) +
            " " +
            toUpperCase(data["service_address"]["zipcode"])
    );

    const {
      dumpsters,
      charged,
      charges
    } = await charged_items(data);
    var rdata = charges;

    height += 30;
    doc.setFontType("bold");
    doc.setFontSize(10);
    doc.text(10, height, "SERVICE DESCRIPTION");
    doc.setFontSize(8);
    if (rdata.length <= 4) {
      doc.rect(8, height + 3, 193, 45);
    } else {
      doc.rect(8, height + 3, 193, 9 * (rdata.length + 1));
    }
    doc.line(8, height + 12, 201, height + 12);
    doc.setDrawColor(180, 180, 180);
    if (rdata.length <= 4) {
      doc.line(8, height + 21, 201, height + 21);
      doc.line(8, height + 30, 201, height + 30);
      doc.line(8, height + 39, 201, height + 39);
    } else {
      for (let i = 0; i < rdata.length; i += 1) {
        doc.line(8, height + 12 + 9 * (i + 1), 201, height + 12 + 9 * (i + 1));
      }
    }

    var width_table = [8, 40, 100];
    if (rdata.length <= 4) {
      doc.line(width_table[1], height + 3, width_table[1], height + 48);
      doc.line(width_table[2], height + 3, width_table[2], height + 48);
    } else {
      doc.line(
        width_table[1],
        height + 3,
        width_table[1],
        height + 12 + 9 * rdata.length
      );
      doc.line(
        width_table[2],
        height + 3,
        width_table[2],
        height + 12 + 9 * rdata.length
      );
    }

    doc.text(20, height + 8.5, "DATE");
    doc.text(60, height + 8.5, "SERVICE SIZE");
    doc.text(142, height + 8.5, "DESCRIPTION");

    doc.setFontType("normal");
    for (let i = 0; i < rdata.length; i += 1) {
      for (let j = 0; j < 3; j += 1) {
        doc.text(
          width_table[j] + 3,
          height + 18 + 9 * i,
          rdata[i][j] === undefined ? "" : toUpperCase(rdata[i][j])
        );
      }
    }

    if (rdata.length <= 4) {
      height += 56;
    } else {
      height += 12 + 9 * (rdata.length + 1);
    }
    doc.setFontType("bold");
    doc.setFontSize(10);
    doc.text(142, height, "TOTAL BALANCE DUE:");
    let total_dollar = data.amount_due;

    if (isNaN(data.amount_due) || data.amount_due === undefined)
      total_dollar = "$0.00";
    const formatDecimal = (number) => {
      let numind = number.indexOf(".");
      let numlen = number.slice(numind);
      if (numlen.length > 2) return `${number.toString()}`;
      return `${number.toString()}0`;
    };

    if(data.amount_due == null) total_dollar = "0";
    if (data.amount_due !== undefined && data.amount_due !== null) {
      total_dollar =
        total_dollar.toString().indexOf(".") !== -1
          ? formatDecimal(total_dollar.toString())
          : total_dollar.toString() + ".00";

      if (total_dollar.indexOf("$") !== -1) {
        total_dollar =
          typeof total_dollar == "string"
            ? `${Number(total_dollar)}`
            : `${total_dollar}`;
      } else {
        total_dollar =
          typeof total_dollar == "string"
            ? `$${Number(total_dollar)}`
            : `$${total_dollar}`;
      }
    }

    doc.text(200 - 1.9 * total_dollar.length, height, total_dollar);
    doc.setFontSize(8);

    height = 217;
    doc.setDrawColor(70, 70, 70);
    for (let i = 1; i < 39; i += 1) {
      if (i >= 4 && i <= 8) continue;
      if (i >= 17 && i <= 22) continue;
      if (i >= 31 && i <= 35) continue;
      doc.line(8 + i * 5, height, i * 5 + 5, height);
    }
    doc.setFontSize(6.5);
    doc.text(28, height + 1, "DETACH HERE");
    doc.text(96, height + 1, "DETACH HERE");
    doc.text(164, height + 1, "DETACH HERE");
    doc.setFontSize(8);
    doc.setDrawColor(80, 80, 80);
    doc.setFontType("normal");
    height += 9;
    doc.setFontSize(9);
    doc.text(50, height, "IF paying by");
    doc.text(78, height, ", PLEASE RETURN this ");
    doc.text(130, height, " with your Payment");
    doc.setFontType("bold");
    doc.text(68, height, "Check");
    doc.text(113, height, "Remittance");
    doc.setFontSize(8);
    doc.setFontType("normal");
    height += 12;
    doc.text(18, height, "BILL TO:");
    doc.text(
      18,
      height + 4,
      data["name"] === undefined ? "" : toUpperCase(data["name"])
    );
    doc.text(
      18,
      height + 8,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["street"])
    );
    doc.text(
      18,
      height + 12,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["city"]) +
            ", " +
            toUpperCase(data["billing_address"]["state"]) +
            " " +
            toUpperCase(data["billing_address"]["zipcode"])
    );

    doc.text(85, height, "SERVICE AT:");
    doc.text(
      85,
      height + 4,
      data["name"] === undefined ? "" : toUpperCase(data["name"])
    );
    doc.text(
      85,
      height + 8,
      data["service_address"] === undefined
        ? ""
        : toUpperCase(data["service_address"]["street"])
    );
    doc.text(
      85,
      height + 12,
      data["service_address"] === undefined
        ? ""
        : toUpperCase(data["service_address"]["city"]) +
            ", " +
            toUpperCase(data["service_address"]["state"]) +
            " " +
            toUpperCase(data["service_address"]["zipcode"])
    );

    doc.text(140, height, "STATEMENT DATE:");
    doc.text(186, height, statement_date.toString());
    doc.text(140, height + 4, "DUE DATE:");
    doc.text(186, height + 4, formatted_due_date.toString());
    doc.text(140, height + 8, "CUSTOMER No:");
    doc.text(
      182,
      height + 8,
      customer_NO === undefined ? "" : toUpperCase(customer_NO.toString())
    );
    doc.text(140, height + 12.5, "CHECK No:");
    doc.line(170, height + 12.5, 200, height + 12.5);
    doc.text(140, height + 18, "AMOUNT");
    doc.text(140, height + 21, "ENCLOSED:");
    doc.line(170, height + 21, 200, height + 21);
    doc.setFontType("bold");
    doc.text(140, height + 29, "TOTAL DUE:");
    doc.text(200 - 1.9 * total_dollar.length, height + 29, total_dollar);

    height += 22;

    height += 15;

    height += 7;
    doc.setFontType("normal");
    doc.setFontSize(8);
    doc.text(
      30,
      height,
      "IMPORTANT NOTICE: PAYMENTS NOT RECEIVED BY THE DUE DATE WILL RESULT IN A DISRUPTION OF SERVICE"
    );
    // doc.text(66, height, 'Recurring Credit Card Program')
    doc.text(
      28,
      height + 3,
      "If you are already enrolled in the Recurring Credit Card Program there is no need to remit payment. If you would like to enroll or"
    );
    doc.text(
      55,
      height + 6,
      "make changes for a Recurring Credit Card, please contact our office at (724) 758-9400. "
    );
    doc.setFontType("bold");
    doc.text(85, height + 9, " Thank you for your business!");
    if (it !== customers.length - 1) {
      doc.addPage();
    }
  }
  if (name === "base64") {
    return doc.output("datauristring");
  } else {
    let fileName = "";
    if (name === "montly")
      fileName = `Invoice montly - ${customers[0]["name"]}.pdf`;
    else fileName = `Invoice ${name}.pdf`;
    doc.save(fileName);
  }

  closeUserPrompt();
}

const newcastle_customer_invoice_pdf_download = async (
  customers,
  statementDate = "",
  dueDate = "",
  setProgress
) => {
  console.log(
    customers,
    statementDate,
    dueDate,
    setProgress,
    "newcastle invoice"
  );

  const isBlank = (customer) => {
    const keys = [
      "Address",
      "Resident Name",
      "Location ZIP Code",
      "City",
      "State",
      "Physical Address",
      "Physical City",
      "Physical State",
      "Physical ZIP",
    ];
    let res = true;
    keys.forEach((key) => (res = res && !customer[key]));
    return res;
  };

  const print = (doc, customer, statementDate, dueDate) =>
    new Promise((resolve) => {
      const customerNo = customer["Account Number"];

      let height = 3;
      doc.addImage(logoImage, "PNG", 25, height + 5, 40, 15);
      doc.setFontType("bold").setFontSize(8).setDrawColor(80, 80, 80);

      height += 25;

      doc.text(25, height, "AIKEN REFUSE, Inc.");
      doc.setFontType("normal");
      doc.text(25, height + 4, "1613 Wampum Road - Ellwood City, PA 16117");
      doc.setFontType("bold");
      doc.text(25, height + 9.5, "724-758-9400 -- info@aikenrefuse.com");

      height += 4;

      doc.setFontType("normal");
      doc.text(140, height, "STATEMENT DATE:");
      doc.text(182, height, statementDate);
      doc.text(140, height + 4, "DUE DATE:");
      doc.text(182, height + 4, dueDate);
      doc.text(140, height + 8, "CUSTOMER No:");
      doc.text(
        182,
        height + 8,
        customerNo !== undefined ? toUpperCase(customerNo) : ""
      );

      height += 30;
      doc.text(
        25,
        height,
        customer["Resident Name"] !== undefined ? customer["Resident Name"] : ""
      );
      doc.text(25, height + 4, "OR CURRENT RESIDENT");
      doc.text(25, height + 8, customer["Address"] || "");
      doc.text(
        25,
        height + 12,
        `${customer["City"] || ""}, ${customer["State"] || ""}, ${
          customer["Location ZIP Code"] || ""
        }`
      );

      height += 30;

      doc.setFontType("bold").setFontSize(10);
      doc.text(10, height, "SERVICE LEVEL OPTIONS");

      height += 3;
      doc
        .setFontSize(8)
        .setDrawColor(140, 140, 140)
        .rect(8, height, 193, 28)
        .setDrawColor(180, 180, 180);
      [7, 14, 21].forEach((h) => doc.line(8, height + h, 201, height + h));
      [20, 90, 160].forEach((w) => doc.line(w + 8, height, w + 8, height + 28));

      doc
        .setFontType("bold")
        .text(12, height + 3, "SERVICE")
        .text(13, height + 6, "LEVEL")
        .text(47, height + 4.5, "STICKER AMOUNT")
        .text(130, height + 4.5, "PRICE")
        .text(177, height + 4.5, "SELECT ONE");

      doc
        .setDrawColor(80, 80, 80)
        .setLineWidth(0.4)
        .rect(170, height + 1, 5, 5)
        .line(171, height + 3.5, 172.5, height + 5)
        .line(174, height + 2, 172.5, height + 5);

      height += 11.5;

      const levelOptions = [
        {
          level: 1,
          stickerPerMonth: 5,
          stickerPerQuarter: 15,
          pricePerMonth: "16.00",
          pricePerQuarter: "48.00",
        },
        {
          level: 2,
          stickerPerMonth: 10,
          stickerPerQuarter: 30,
          pricePerMonth: "23.50",
          pricePerQuarter: "70.50",
        },
        {
          level: 3,
          stickerPerMonth: 15,
          stickerPerQuarter: 45,
          pricePerMonth: "31.00",
          pricePerQuarter: "93.00",
        },
      ];
      levelOptions.forEach(
        ({
          level,
          stickerPerMonth,
          stickerPerQuarter,
          pricePerMonth,
          pricePerQuarter,
        }) => {
          doc.text(12, height, `LEVEL ${level}`);
          doc.setFontType("normal");
          doc.text(
            34,
            height,
            `${stickerPerMonth} STICKERS per MONTH / ${stickerPerQuarter} per Quarter`
          );
          doc.text(101, height, `$${pricePerMonth} per month, billed @`);
          doc.setFontType("bold");
          doc.text(137, height, `$${pricePerQuarter} per Quarter`);
          doc.rect(183, height - 3.5, 5, 5);

          height += 7;
        }
      );

      doc
        .text(34, height, "GO PAPERLESS - ")
        .text(100, height, "www.AIKENREFUSE.com/NEW-CASTLE-RESIDENTS")
        .setFontType("normal")
        .text(60, height, "ENROLL ON OUR WEBSITE: ");

      height += 15;
      doc.setDrawColor(70, 70, 70);
      for (let w = 8, i = 0; w < 200; w += 6, i++) {
        if ([2, 3, 15, 16, 28, 29].includes(i)) continue;
        doc.line(w, height, w + 3, height);
      }

      doc.setFontSize(4.5);
      for (let i = 0; i < 3; i++) {
        doc.text(i * 77 + 20, height + 0.5, "DETACH HERE");
      }

      height += 15;
      doc.setFontSize(8);

      doc
        .text(60, height, "IF PAYING by")
        .text(97, height, "PLEASE RETURN this")
        .text(144, height, "with your Payment.")
        .setFontType("bold")
        .text(79, height, "Credit Card,")
        .text(127, height, "Remittance");

      height += 14;

      doc
        .setFontType("normal")
        .text(18, height, "BILL TO")
        .text(18, height + 3, customer["Resident Name"] || "")
        .text(18, height + 6, customer["Address"] || "")
        .text(
          18,
          height + 9,
          `${customer["City"] || ""}, ${customer["State"] || ""}, ${
            customer["Location ZIP Code"] || ""
          }`
        )
        .text(68, height, "SERVICE AT:")
        .text(68, height + 3, customer["Physical Address"] || "")
        .text(
          68,
          height + 6,
          `${customer["Physical City"] || ""}, ${
            customer["Physical State"] || ""
          }, ${customer["Physical ZIP"] || ""}`
        );

      doc
        .setFontType("normal")
        .text(130, height, "STATEMENT DATE:")
        .text(166, height, statementDate)
        .text(130, height + 4, "DUE DATE:")
        .text(166, height + 4, dueDate)
        .text(130, height + 8, "CUSTOMER No.:")
        .text(
          166,
          height + 8,
          customerNo !== undefined ? toUpperCase(customerNo) : ""
        );

      height += 25;

      doc
        .setFontType("bold")
        .text(22, height, "PLEASE REMIT")
        .text(18, height + 3, "PAYMENT IN FULL PER")
        .text(25, height + 6, "QUARTER")
        .text(24, height + 14, "WE ACCEPT")
        .addImage(visaMasterImage, "PNG", 22, height + 18, 20, 6);

      height -= 3;
      doc
        .setLineWidth(0.1)
        .setDrawColor(140, 140, 140)
        .rect(55, height, 140, 28)
        .setDrawColor(180, 180, 180);

      [7, 14, 21].forEach((h) => doc.line(55, height + h, 195, height + h));
      [20, 70, 110].forEach((w) =>
        doc.line(55 + w, height, 55 + w, height + 28)
      );

      doc
        .text(59, height + 3, "SERVICE")
        .text(60, height + 6, "LEVEL")
        .text(87, height + 4.5, "STICKER AMOUNT")
        .text(140, height + 4.5, "PRICE")
        .text(174, height + 4.5, "SELECT ONE");

      doc
        .setDrawColor(80, 80, 80)
        .setLineWidth(0.4)
        .rect(167, height + 1, 5, 5)
        .line(168, height + 3.5, 169.5, height + 5)
        .line(171, height + 2, 169.5, height + 5);

      height += 11.5;

      levelOptions.forEach(({ level, stickerPerQuarter, pricePerQuarter }) => {
        doc
          .text(59, height, `LEVEL ${level}`)
          .setFontType("normal")
          .text(83, height, `${stickerPerQuarter} STICKERS per Quarter`)
          .text(143, height, "per Quarter")
          .setFontType("bold")
          .text(134, height, `$${pricePerQuarter}`)
          .rect(177, height - 3.5, 5, 5);

        height += 7;
      });

      height += 10;

      doc
        .setLineWidth(0.1)
        .setDrawColor(180, 180, 180)
        .rect(8, height, 193, 45)
        .setDrawColor(80, 80, 80);

      height += 7;

      doc.text(12, height, "CREDIT CARD REMITTANCE").setFontType("normal");

      doc
        .text(12, (height += 4), "CARD HOLDER NAME:")
        .line(45, height, 197, height);

      doc
        .text(12, (height += 4), "CARD HOLDER ADDRESS:")
        .line(50, height, 197, height)
        .line(50, (height += 4), 197, height)
        .line(50, (height += 4), 197, height);

      doc
        .text(12, (height += 5), "CREDIT CARD #:")
        .line(35, height, 137, height)
        .text(139, height, "EXPIRATION DATE:")
        .line(166, height, 197, height);

      doc
        .text(12, (height += 4), "3-DIGIT CODE ON BACK:")
        .line(48, height, 100, height)
        .text(102, height, "ZIP CODE from which Card Originates:")
        .line(152, height, 197, height);

      doc
        .setFontSize(6)
        .text(40, (height += 6), "If you are already enrolled in the")
        .text(
          104,
          height,
          "there is no need to remit payment. If you would like to enroll or"
        )
        .setFontType("bold")
        .text(71.5, height, "Recurring Credit Card Program")
        .setFontType("normal")
        .text(
          40,
          (height += 2.5),
          "make changes for a Recurring Credit Card, Please contact our office at (724) 758-9400."
        )
        .text(
          28,
          (height += 2),
          "IMPORTANT NOTICE: PAYMENTS NOT RECEIVED BY THE DUE DATE WILL RESULT IN A DISRUPTION OF SERVICE. Thank you"
        );

      height += 10;

      doc
        .text(30, height, "TO PAY THIS BILL ON-LINE, VISIT:")
        .text(119, height, "and CLICK")
        .setFontType("bold")
        .text(65, height, "www.AIKENREFUSE.com/NEW-CASTLE-RESIDENTS")
        .text(130, height, "return this remittance with your Payment");

      setTimeout(resolve, 1);
    });

  const customersToPrint = customers.filter((c) => !isBlank(c));

  const chunkSize = 1000;
  const total = customersToPrint.length;
  const filenamePrefix = `new_castle_invoice_${moment().format("MMDDYYYY")}`;

  if (setProgress) setProgress(0);

  let doc,
    it = 0;

  for (let i = 0; i < total; i++) {
    if (i % chunkSize === 0) {
      if (i) {
        doc.save(`${filenamePrefix}_${it++}.pdf`);
      }
      doc = new jsPDF();
    } else {
      doc.addPage();
    }

    await print(doc, customersToPrint[i], statementDate, dueDate);

    const progress = Math.floor((100 * (i + 1)) / total);
    if (setProgress) setProgress(progress);
  }

  doc.save(`${filenamePrefix}_${it++}.pdf`);
};

const newcastle_customer_coverletter_pdf_download = async (
  customers,
  dueDate = "",
  setProgress
) => {
  console.log(customers, dueDate, setProgress, "newcastle invoice");

  const isBlank = (customer) => {
    const keys = [
      "Address",
      "Resident Name",
      "Location ZIP Code",
      "City",
      "State",
      "Physical Address",
      "Physical City",
      "Physical State",
      "Physical ZIP",
    ];
    let res = true;
    keys.forEach((key) => (res = res && !customer[key]));
    return res;
  };

  const print = (doc, customer, dueDate) =>
    new Promise((resolve) => {
      const customerNo = customer["Account Number"];
      let totalWidth = doc.internal.pageSize.getWidth();
      let totalHeight = doc.internal.pageSize.getHeight();
      // const imgData = 'data:image/png;base64,'+ Base64.encode('./bg.png');
      doc.addImage(
        newCastleCustomerCoverLetterBackgroundImage,
        "PNG",
        0,
        0,
        totalWidth,
        totalHeight
      );
      let height = 3;
      doc.addImage(logoImage, "PNG", 25, height + 5, 40, 15);
      doc.setFontType("bold").setFontSize(8).setDrawColor(80, 80, 80);

      height += 25;

      doc.text(25, height, "AIKEN REFUSE, Inc.");
      doc.setFontType("normal");
      doc.text(25, height + 4, "1613 Wampum Road - Ellwood City, PA 16117");
      doc.setFontType("bold");
      doc.text(25, height + 9.5, "724-758-9400 -- info@aikenrefuse.com");

      height += 4;

      doc.setFontType("normal");
      // doc.text(140, height, 'STATEMENT DATE:')
      // doc.text(182, height, statementDate)
      doc.text(140, height + 4, "DATE:");
      doc.text(182, height + 4, dueDate);
      doc.text(140, height + 8, "CUSTOMER No:");
      doc.text(
        182,
        height + 8,
        customerNo !== undefined ? toUpperCase(customerNo) : ""
      );

      height += 30;
      doc.text(
        25,
        height,
        customer["Resident Name"] !== undefined ? customer["Resident Name"] : ""
      );
      doc.text(25, height + 4, "OR CURRENT RESIDENT");
      doc.text(25, height + 8, customer["Address"] || "");
      doc.text(
        25,
        height + 12,
        `${customer["City"] || ""}, ${customer["State"] || ""}, ${
          customer["Location ZIP Code"] || ""
        }`
      );

      // height += 30;
      // doc.text(85, height, 'SERVICE AT:')
      // doc.text(85, height + 4, data['name'] === undefined ? '':toUpperCase(data['name']))
      // doc.text(85, height + 8, data['service_address'] === undefined ? '':toUpperCase(data['service_address']['street']))
      // doc.text(85, height + 12, data['service_address'] === undefined ? '':toUpperCase(data['service_address']['city']) + ', ' + toUpperCase(data['service_address']['state']) + ' ' + toUpperCase(data['service_address']['zipcode']))

      doc
        .text(140, height, "SERVICE AT:")
        .text(140, height + 3, customer["Physical Address"] || "")
        .text(
          140,
          height + 6,
          `${customer["Physical City"] || ""}, ${
            customer["Physical State"] || ""
          }, ${customer["Physical ZIP"] || ""}`
        );
      // doc.setFontType('bold').setFontSize(10);
      // doc.text(10, height, 'SERVICE LEVEL OPTIONS');

      // height += 3;
      // doc.setFontSize(8)
      //   .setDrawColor(140, 140, 140)
      //   .rect(8, height, 193, 28)
      //   .setDrawColor(180, 180, 180);
      // [7, 14, 21].forEach(h => doc.line(8, height + h, 201, height + h));
      // [20, 90, 160].forEach(w => doc.line(w + 8, height, w + 8, height + 28));

      // doc.setFontType('bold')
      //   .text(12, height + 3, 'SERVICE')
      //   .text(13, height + 6, 'LEVEL')
      //   .text(47, height + 4.5, 'STICKER AMOUNT')
      //   .text(130, height + 4.5, 'PRICE')
      //   .text(177, height + 4.5, 'SELECT ONE');

      // doc.setDrawColor(80, 80, 80)
      //   .setLineWidth(.4)
      //   .rect(170, height + 1, 5, 5)
      //   .line(171, height + 3.5, 172.5, height + 5)
      //   .line(174, height + 2, 172.5, height + 5);

      // height += 11.5;

      // const levelOptions = [
      //   { level: 1, stickerPerMonth: 5, stickerPerQuarter: 15, pricePerMonth: '16.00', pricePerQuarter: '48.00' },
      //   { level: 2, stickerPerMonth: 10, stickerPerQuarter: 30, pricePerMonth: '23.50', pricePerQuarter: '70.50' },
      //   { level: 3, stickerPerMonth: 15, stickerPerQuarter: 45, pricePerMonth: '31.00', pricePerQuarter: '93.00' },
      // ];
      // levelOptions.forEach(({ level, stickerPerMonth, stickerPerQuarter, pricePerMonth, pricePerQuarter }) => {
      //   doc.text(12, height, `LEVEL ${level}`);
      //   doc.setFontType('normal');
      //   doc.text(34, height, `${stickerPerMonth} STICKERS per MONTH / ${stickerPerQuarter} per Quarter`);
      //   doc.text(101, height, `$${pricePerMonth} per month, billed @`);
      //   doc.setFontType('bold');
      //   doc.text(137, height, `$${pricePerQuarter} per Quarter`);
      //   doc.rect(183, height - 3.5, 5, 5);

      //   height += 7;
      // });

      // doc.text(34, height, 'GO PAPERLESS - ')
      //   .text(100, height, 'www.AIKENREFUSE.com/NEW-CASTLE-RESIDENTS')
      //   .setFontType('normal')
      //   .text(60, height, 'ENROLL ON OUR WEBSITE: ');

      // height += 15
      // doc.setDrawColor(70, 70, 70);
      // for (let w = 8, i = 0; w < 200; w += 6, i++) {
      //   if ([2, 3, 15, 16, 28, 29].includes(i)) continue;
      //   doc.line(w, height, w + 3, height);
      // }

      // doc.setFontSize(4.5);
      // for (let i = 0; i < 3; i++) {
      //   doc.text(i * 77 + 20, height + .5, 'DETACH HERE');
      // }

      // height += 15;
      // doc.setFontSize(8);

      // doc.text(60, height, 'IF PAYING by')
      //   .text(97, height, 'PLEASE RETURN this')
      //   .text(144, height, 'with your Payment.')
      //   .setFontType('bold')
      //   .text(79, height, 'Credit Card,')
      //   .text(127, height, 'Remittance');

      // height += 14;

      // doc.setFontType('normal')
      //   .text(18, height, 'BILL TO')
      //   .text(18, height + 3, customer['Resident Name'] || '')
      //   .text(18, height + 6, customer['Address'] || '')
      //   .text(18, height + 9, `${customer['City'] || ''}, ${customer['State'] || ''}, ${customer['Location ZIP Code'] || ''}`)
      //   .text(68, height, 'SERVICE AT:')
      //   .text(68, height + 3, customer['Physical Address'] || '')
      //   .text(68, height + 6, `${customer['Physical City'] || ''}, ${customer['Physical State'] || ''}, ${customer['Physical ZIP'] || ''}`)

      // doc.setFontType('normal')
      //   .text(130, height, 'STATEMENT DATE:')
      //   .text(166, height, statementDate)
      //   .text(130, height + 4, 'DUE DATE:')
      //   .text(166, height + 4, dueDate)
      //   .text(130, height + 8, 'CUSTOMER No.:')
      //   .text(166, height + 8, customerNo !== undefined ? toUpperCase(customerNo) : '');

      // height += 25;

      // doc.setFontType('bold')
      //   .text(22, height, 'PLEASE REMIT')
      //   .text(18, height + 3, 'PAYMENT IN FULL PER')
      //   .text(25, height + 6, 'QUARTER')
      //   .text(24, height + 14, 'WE ACCEPT')
      //   .addImage(visaMasterImage, 'PNG', 22, height + 18, 20, 6);

      // height -= 3;
      // doc.setLineWidth(.1)
      //   .setDrawColor(140, 140, 140)
      //   .rect(55, height, 140, 28)
      //   .setDrawColor(180, 180, 180);

      // [7, 14, 21].forEach(h => doc.line(55, height + h, 195, height + h));
      // [20, 70, 110].forEach(w => doc.line(55 + w, height, 55 + w, height + 28));

      // doc.text(59, height + 3, 'SERVICE')
      //   .text(60, height + 6, 'LEVEL')
      //   .text(87, height + 4.5, 'STICKER AMOUNT')
      //   .text(140, height + 4.5, 'PRICE')
      //   .text(174, height + 4.5, 'SELECT ONE')

      // doc.setDrawColor(80, 80, 80)
      //   .setLineWidth(.4)
      //   .rect(167, height + 1, 5, 5)
      //   .line(168, height + 3.5, 169.5, height + 5)
      //   .line(171, height + 2, 169.5, height + 5);

      // height += 11.5;

      // levelOptions.forEach(({ level, stickerPerQuarter, pricePerQuarter}) => {
      //   doc.text(59, height, `LEVEL ${level}`)
      //     .setFontType('normal')
      //     .text(83, height, `${stickerPerQuarter} STICKERS per Quarter`)
      //     .text(143, height, 'per Quarter')
      //     .setFontType('bold')
      //     .text(134, height, `$${pricePerQuarter}`)
      //     .rect(177, height - 3.5, 5, 5);

      //   height += 7;
      // });

      // height += 10;

      // doc.setLineWidth(.1)
      //   .setDrawColor(180, 180, 180)
      //   .rect(8, height, 193, 45)
      //   .setDrawColor(80, 80, 80);

      // height += 7;

      // doc.text(12, height, 'CREDIT CARD REMITTANCE')
      //   .setFontType('normal')

      // doc.text(12, height += 4, 'CARD HOLDER NAME:')
      //   .line(45, height, 197, height)

      // doc.text(12, height += 4, 'CARD HOLDER ADDRESS:')
      //   .line(50, height, 197, height)
      //   .line(50, height += 4, 197, height)
      //   .line(50, height += 4, 197, height)

      // doc.text(12, height += 5, 'CREDIT CARD #:')
      //   .line(35, height, 137, height)
      //   .text(139, height, 'EXPIRATION DATE:')
      //   .line(166, height, 197, height)

      // doc.text(12, height += 4, '3-DIGIT CODE ON BACK:')
      //   .line(48, height, 100, height)
      //   .text(102, height, 'ZIP CODE from which Card Originates:')
      //   .line(152, height, 197, height)

      // doc.setFontSize(6)
      //   .text(40, height += 6, 'If you are already enrolled in the')
      //   .text(104, height, 'there is no need to remit payment. If you would like to enroll or')
      //   .setFontType('bold')
      //   .text(71.5, height, 'Recurring Credit Card Program')
      //   .setFontType('normal')
      //   .text(40, height += 2.5, 'make changes for a Recurring Credit Card, Please contact our office at (724) 758-9400. Thank you.');

      // height += 10;

      // doc.text(30, height, 'TO PAY THIS BILL ON-LINE, VISIT:')
      // .text(119, height, 'and CLICK')
      // .setFontType('bold')
      // .text(65, height, 'www.AIKENREFUSE.com/NEW-CASTLE-RESIDENTS')
      // .text(130, height, 'return this remittance with your Payment');

      setTimeout(resolve, 1);
    });

  const customersToPrint = customers.filter((c) => !isBlank(c));

  const chunkSize = 1000;
  const total = customersToPrint.length;
  const filenamePrefix = `new_castle_invoice_${moment().format("MMDDYYYY")}`;

  if (setProgress) setProgress(0);

  let doc,
    it = 0;

  for (let i = 0; i < total; i++) {
    if (i % chunkSize === 0) {
      if (i) {
        doc.save(`${filenamePrefix}_${it++}.pdf`);
      }
      doc = new jsPDF();
    } else {
      doc.addPage();
    }

    await print(doc, customersToPrint[i], dueDate);

    const progress = Math.floor((100 * (i + 1)) / total);
    if (setProgress) setProgress(progress);
  }
  doc.save(`${filenamePrefix}_${it++}.pdf`);
};

const individual_route_coverletter_pdf_download = async (
  customers,
  note,
  dueDate = "",
  setProgress,
  underline = false,
  bold = false,
  italicize = false
) => {
  console.log(customers, dueDate, setProgress, "newcastle invoice");

  const isBlank = (customer) => {
    const keys = [
      // 'Account Number',
      "service_address",
      // 'Address',
      // 'Resident Name',
      // 'Location ZIP Code',
      // 'City',
      // 'State',
      // 'Physical Address',
      // 'Physical City',
      // 'Physical State',
      // 'Physical ZIP',
    ];
    let res = true;
    keys.forEach((key) => (res = res && !customer[key]));
    return res;
  };

  const print = (doc, customer, dueDate) =>
    new Promise((resolve) => {
      const customerNo = customer["Account Number"];
      const serviceAddress = customer?.service_address;
      // ?customer.service_address:customer?.dumpster_addresses[0]?customer.dumpster_addresses[0]:{
      //   street: '',
      //   city: '',
      //   state: '',
      //   zipcode: '',
      // }
      let totalWidth = doc.internal.pageSize.getWidth();
      let totalHeight = doc.internal.pageSize.getHeight();
      // const imgData = 'data:image/png;base64,'+ Base64.encode('./bg.png');
      doc.addImage(
        newCastleCustomerCoverLetterBackgroundImage,
        "PNG",
        0,
        0,
        totalWidth,
        totalHeight
      );
      let height = 3;
      doc.addImage(logoImage, "PNG", 25, height + 5, 40, 15);
      doc.setFontType("bold").setFontSize(8).setDrawColor(80, 80, 80);

      height += 25;

      doc.text(25, height, "AIKEN REFUSE, Inc.");
      doc.setFontType("normal");
      doc.text(25, height + 4, "1613 Wampum Road - Ellwood City, PA 16117");
      doc.setFontType("bold");
      doc.text(25, height + 9.5, "724-758-9400 -- info@aikenrefuse.com");

      height += 4;

      doc.setFontType("normal");
      // doc.text(140, height, 'STATEMENT DATE:')
      // doc.text(182, height, statementDate)
      doc.text(140, height + 4, "DATE:");
      doc.text(182, height + 4, dueDate);
      doc.text(140, height + 8, "CUSTOMER No:");
      doc.text(
        182,
        height + 8,
        customerNo !== undefined ? toUpperCase(customerNo) : ""
      );

      height += 30;
      doc.text(
        25,
        height,
        customer["name"] !== undefined ? customer["name"] : ""
      );
      doc.text(25, height + 4, "OR CURRENT RESIDENT");
      doc.text(
        25,
        height + 8,
        customer["billing_address"]
          ? customer["billing_address"]["street"] || ""
          : ""
      );
      doc.text(
        25,
        height + 12,
        customer["billing_address"]
          ? `${customer["billing_address"]["city"] || ""}, ${
              customer["billing_address"]["state"] || ""
            }, ${customer["billing_address"]["zipcode"] || ""}`
          : ""
      );

      doc
        .text(140, height, "SERVICE AT:")
        .text(140, height + 3, serviceAddress["street"] || "")
        .text(
          140,
          height + 6,
          `${serviceAddress["city"] || ""}, ${serviceAddress["state"] || ""}, ${
            serviceAddress["zipcode"] || ""
          }`
        );

      if (note) {
        height += 100; // Adjust the height according to your requirements
        // doc.setFontType('bold').setFontSize(10);
        // doc.text(25, height, 'Note:');
        // doc.setFontType('normal').setFontSize(8);

        // Split the note into multiple lines
        const noteLines = doc.splitTextToSize(note, 160); // Adjust the width as needed
        if (bold) {
          doc.setFontType("bold");
        }
        if (italicize) {
          doc.setFontStyle("italic");
        }
        // Print each line of the note
        noteLines.forEach((line, index) => {
          const x = 25;
          const y = height + 5 + index * 5;

          doc.text(x, y, line); // Adjust the line spacing as needed
          if (underline) {
            // Calculate the width of the text
            const textWidth = doc.getTextWidth(line);

            // Draw a line beneath the text
            doc.line(x, y + 2, x + textWidth, y + 2);
          }
        });
        doc.setFontStyle("normal");
        doc.setFontType("normal");
      }

      setTimeout(resolve, 1);
    });

  const customersToPrint = customers.filter((c) => !isBlank(c));

  const chunkSize = 1000;
  const total = customersToPrint.length;
  const filenamePrefix = `route_cover_letters_${moment().format("MMDDYYYY")}`;

  if (setProgress) setProgress(0);

  let doc,
    it = 0;

  for (let i = 0; i < total; i++) {
    if (i % chunkSize === 0) {
      if (i) {
        doc.save(`${filenamePrefix}_${it++}.pdf`);
      }
      doc = new jsPDF();
    } else {
      doc.addPage();
    }

    await print(doc, customersToPrint[i], dueDate);

    const progress = Math.floor((100 * (i + 1)) / total);
    if (setProgress) setProgress(progress);
  }
  doc.save(`${filenamePrefix}_${it++}.pdf`);
};

async function newcastle2_customer_invoice_pdf_download(
  customers,
  name,
  due_date = "",
  state_date = "",
  customer_number = "",
  setProgress
) {
  
    showUserPrompt('Downloading please do not close or refresh window...');
  // if(due_date === ''){
  //   const current = moment();
  //   due_date = current.add(30, 'days').format('MM/DD/YYYY');
  // }

    

      const customer_ids = customers.map((customer) => customer.uid);
      console.log(customer_ids, 'customer ids');
      const interval = setInterval(() => {
        if (setProgress)
          setProgress((prev) => {
            if (prev < 100) return prev + 10;
            return prev;
          });
      }, 10000);
      const res = await axiosInstance.post("/api/customers/histories", {
        customer_ids,
      });
      if (res.status === 200) {
        const histories = res.data.histories;
        console.log(histories.length, 'total histories');
        if (histories.length === customers.length)
          for (let i = 0; i < customers.length; i++) {
            customers[i].history = histories[i] || [];
          }
      }
      clearInterval(interval);
      if (setProgress)
        setProgress(100);

  let formatted_due_date = moment(due_date, "MM/DD/YYYY").format("MM/DD/YYYY");
  let statement_date = moment().format("MM/DD/YYYY");
  if (state_date.length) statement_date = state_date;
  var doc = new jsPDF();
  for (let it = 0; it < customers.length; it += 1) {
    var data = customers[it];
    let customer_NO = data["Account Number"];
    if (customer_number.length) customer_NO = customer_number;
    var height = 3;
    doc.addImage(logoImage, "JPEG", 15, height + 5, 40, 15);
    doc.setFontType("bold");
    doc.setFontSize(8);
    doc.setDrawColor(80, 80, 80);
    height += 25;
    doc.text(18, height, "AIKEN REFUSE, Inc.");
    doc.setFontType("normal");
    height += 4;
    doc.text(18, height, "1613 Wampum Road - Ellwood City, PA 16117");
    doc.setFontType("bold");
    doc.text(18, height + 5.5, "724-758-9400 -- info@aikenrefuse.com");

    doc.setFontType("normal");
    doc.text(140, height, "STATEMENT DATE:");
    doc.text(186, height, statement_date.toString());
    doc.text(140, height + 4, "DUE DATE:");
    doc.text(186, height + 4, formatted_due_date.toString());
    doc.text(140, height + 8, "CUSTOMER No:");
    doc.text(
      182,
      height + 8,
      customer_NO === undefined ? "" : toUpperCase(customer_NO.toString())
    );
    doc.text(140, height + 13, "CHECK No:");
    doc.line(170, height + 13, 200, height + 13);
    doc.text(140, height + 18, "AMOUNT");
    doc.text(140, height + 21, "ENCLOSED:");
    doc.line(170, height + 21, 200, height + 21);

    height += 35;
    doc.text(
      18,
      height,
      data["name"] === undefined ? "" : toUpperCase(data["name"])
    );
    doc.text(
      18,
      height + 4,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["street"])
    );
    doc.text(
      18,
      height + 8,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["city"]) +
            ", " +
            toUpperCase(data["billing_address"]["state"]) +
            " " +
            toUpperCase(data["billing_address"]["zipcode"])
    );

    height -= 3;
    doc.setFontType("bold");
    doc.text(140, height, "FOR SERVICE AT:");
    doc.setFontType("normal");
    doc.text(
      140,
      height + 4,
      data["name"] === undefined ? "" : toUpperCase(data["name"])
    );
    doc.text(
      140,
      height + 8,
      data["service_address"] === undefined
        ? ""
        : toUpperCase(data["service_address"]["street"])
    );
    doc.text(
      140,
      height + 12,
      data["service_address"] === undefined
        ? ""
        : toUpperCase(data["service_address"]["city"]) +
            ", " +
            toUpperCase(data["service_address"]["state"]) +
            " " +
            toUpperCase(data["service_address"]["zipcode"])
    );

    const {
      dumpsters,
      charged,
      charges
    } = await charged_items(data);
    var rdata = charges;

    height += 30;
    doc.setFontType("bold");
    doc.setFontSize(10);
    doc.text(10, height, "SERVICE DESCRIPTION");
    doc.setFontSize(8);
    if (rdata.length <= 4) {
      doc.rect(8, height + 3, 193, 45);
    } else {
      doc.rect(8, height + 3, 193, 9 * (rdata.length + 1));
    }
    doc.line(8, height + 12, 201, height + 12);
    doc.setDrawColor(180, 180, 180);
    if (rdata.length <= 4) {
      doc.line(8, height + 21, 201, height + 21);
      doc.line(8, height + 30, 201, height + 30);
      doc.line(8, height + 39, 201, height + 39);
    } else {
      for (let i = 0; i < rdata.length; i += 1) {
        doc.line(8, height + 12 + 9 * (i + 1), 201, height + 12 + 9 * (i + 1));
      }
    }

    var width_table = [8, 40, 100];
    if (rdata.length <= 4) {
      doc.line(width_table[1], height + 3, width_table[1], height + 48);
      doc.line(width_table[2], height + 3, width_table[2], height + 48);
    } else {
      doc.line(
        width_table[1],
        height + 3,
        width_table[1],
        height + 12 + 9 * rdata.length
      );
      doc.line(
        width_table[2],
        height + 3,
        width_table[2],
        height + 12 + 9 * rdata.length
      );
    }

    doc.text(20, height + 8.5, "DATE");
    doc.text(60, height + 8.5, "SERVICE SIZE");
    doc.text(142, height + 8.5, "DESCRIPTION");

    doc.setFontType("normal");
    for (let i = 0; i < rdata.length; i += 1) {
      for (let j = 0; j < 3; j += 1) {
        doc.text(
          width_table[j] + 3,
          height + 18 + 9 * i,
          rdata[i][j] === undefined ? "" : toUpperCase(rdata[i][j])
        );
      }
    }

    if (rdata.length <= 4) {
      height += 56;
    } else {
      height += 12 + 9 * (rdata.length + 1);
    }
    doc.setFontType("bold");
    doc.setFontSize(10);
    doc.text(142, height, "TOTAL BALANCE DUE:");
    let total_dollar = data.amount_due;

    if (isNaN(data.amount_due) || data.amount_due === undefined)
      total_dollar = "$0.00";
    const formatDecimal = (number) => {
      let numind = number.indexOf(".");
      let numlen = number.slice(numind);
      if (numlen.length > 2)
        return `${parseFloat(number).toFixed(2).toString()}`;
      return `${number.toString()}0`;
    };

    if(data.amount_due == null) total_dollar = "0";
    if (data.amount_due !== undefined && data.amount_due !== null) {
      total_dollar =
        total_dollar.toString().indexOf(".") !== -1
          ? formatDecimal(total_dollar.toString())
          : total_dollar.toString() + ".00";

      if (total_dollar.indexOf("$") !== -1) {
        total_dollar =
          typeof total_dollar == "string"
            ? `${Number(total_dollar)}`
            : `${total_dollar}`;
      } else {
        total_dollar =
          typeof total_dollar == "string"
            ? `$${Number(total_dollar)}`
            : `$${total_dollar}`;
      }
    }

    doc.text(200 - 1.9 * total_dollar.length, height, total_dollar);
    doc.setFontSize(8);

    height = 217;
    doc.setDrawColor(70, 70, 70);
    for (let i = 1; i < 39; i += 1) {
      if (i >= 4 && i <= 8) continue;
      if (i >= 17 && i <= 22) continue;
      if (i >= 31 && i <= 35) continue;
      doc.line(8 + i * 5, height, i * 5 + 5, height);
    }
    doc.setFontSize(6.5);
    doc.text(28, height + 1, "DETACH HERE");
    doc.text(96, height + 1, "DETACH HERE");
    doc.text(164, height + 1, "DETACH HERE");
    doc.setFontSize(8);
    doc.setDrawColor(80, 80, 80);
    doc.setFontType("normal");
    height += 9;
    doc.setFontSize(9);
    doc.text(50, height, "IF paying by");
    doc.text(78, height, ", PLEASE RETURN this ");
    doc.text(130, height, " with your Payment");
    doc.setFontType("bold");
    doc.text(68, height, "Check");
    doc.text(113, height, "Remittance");
    doc.setFontSize(8);
    doc.setFontType("normal");
    height += 12;
    doc.text(18, height, "BILL TO:");
    doc.text(
      18,
      height + 4,
      data["name"] === undefined ? "" : toUpperCase(data["name"])
    );
    doc.text(
      18,
      height + 8,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["street"])
    );
    doc.text(
      18,
      height + 12,
      data["billing_address"] === undefined
        ? ""
        : toUpperCase(data["billing_address"]["city"]) +
            ", " +
            toUpperCase(data["billing_address"]["state"]) +
            " " +
            toUpperCase(data["billing_address"]["zipcode"])
    );

    doc.text(85, height, "SERVICE AT:");
    doc.text(
      85,
      height + 4,
      data["name"] === undefined ? "" : toUpperCase(data["name"])
    );
    doc.text(
      85,
      height + 8,
      data["service_address"] === undefined
        ? ""
        : toUpperCase(data["service_address"]["street"])
    );
    doc.text(
      85,
      height + 12,
      data["service_address"] === undefined
        ? ""
        : toUpperCase(data["service_address"]["city"]) +
            ", " +
            toUpperCase(data["service_address"]["state"]) +
            " " +
            toUpperCase(data["service_address"]["zipcode"])
    );

    doc.text(140, height, "STATEMENT DATE:");
    doc.text(186, height, statement_date.toString());
    doc.text(140, height + 4, "DUE DATE:");
    doc.text(186, height + 4, formatted_due_date.toString());
    doc.text(140, height + 8, "CUSTOMER No:");
    doc.text(
      182,
      height + 8,
      customer_NO === undefined ? "" : toUpperCase(customer_NO.toString())
    );
    doc.text(140, height + 12.5, "CHECK No:");
    doc.line(170, height + 12.5, 200, height + 12.5);
    doc.text(140, height + 18, "AMOUNT");
    doc.text(140, height + 21, "ENCLOSED:");
    doc.line(170, height + 21, 200, height + 21);
    doc.setFontType("bold");
    doc.text(140, height + 29, "TOTAL DUE:");
    doc.text(200 - 1.9 * total_dollar.length, height + 29, total_dollar);

    height += 22;

    height += 15;

    height += 7;
    doc.setFontType("normal");
    doc.setFontSize(8);
    doc.text(
      30,
      height,
      "IMPORTANT NOTICE: PAYMENTS NOT RECEIVED BY THE DUE DATE WILL RESULT IN A DISRUPTION OF SERVICE"
    );
    // doc.text(66, height, 'Recurring Credit Card Program')
    doc.text(
      28,
      height + 3,
      "If you are already enrolled in the Recurring Credit Card Program there is no need to remit payment. If you would like to enroll or"
    );
    doc.text(
      55,
      height + 6,
      "make changes for a Recurring Credit Card, please contact our office at (724) 758-9400. "
    );
    doc.setFontType("bold");
    doc.text(85, height + 9, " Thank you for your business!");
    if (it !== customers.length - 1) {
      doc.addPage();
    }
  }
  if (name === "base64") {
    return doc.output("datauristring");
  } else {
    let fileName = "";
    if (name === "montly")
      fileName = `Invoice montly - ${customers[0]["name"]}.pdf`;
    else fileName = `Invoice ${name}.pdf`;
    doc.save(fileName);
  }

  closeUserPrompt();
}

async function commercial_customer_receipt_pdf_download(
  customer,
  name,
  state_time,
  customer_number,
  billFrequencyLabel,
  additionalText,
  invoice_no,
  receiptsToPrintFinal
) {
  let statement_time =
    state_time == "" ? moment().format("MM/DD/YYYY") : state_time.toString();

  if (state_time.length) statement_time = state_time;
  var doc = new jsPDF();

  var data = customer[0];
  let customer_NO = data["Account Number"];
  if (customer_number.length) customer_NO = customer_number;
  var height = 3;
  doc.addImage(logoImage, "JPEG", 15, height + 5, 40, 15);
  doc.setFontType("bold");
  doc.setFontSize(8);
  doc.setDrawColor(80, 80, 80);
  height += 25;
  doc.text(18, height, "AIKEN REFUSE, Inc.");
  doc.setFontType("normal");
  height += 4;
  doc.text(18, height, "1613 Wampum Road - Ellwood City, PA 16117");
  doc.setFontType("bold");
  doc.text(18, height + 5.5, "724-758-9400 -- info@aikenrefuse.com");

  doc.setFontType("normal");
  doc.text(140, height, "INVOICE DATE:");
  doc.text(186, height, statement_time.toString());
  doc.text(140, height + 8, "CUSTOMER No:");
  doc.text(
    184.5,
    height + 8,
    customer_NO === undefined ? "" : toUpperCase(customer_NO.toString())
  );

  doc.text(140, height + 13, "INVOICE No:");
  doc.text(
    184.5,
    height + 13,
    invoice_no === undefined ? "" : toUpperCase(invoice_no.toString())
  );

  doc.text(140, height + 17, "CHECK No:");
  doc.line(170, height + 17, 200, height + 17);

  doc.text(140, height + 22, "AMOUNT");
  doc.text(140, height + 25, "ENCLOSED:");
  doc.line(170, height + 25, 200, height + 25);

  height += 35;
  doc.text(
    18,
    height,
    data["name"] === undefined ? "" : toUpperCase(data["name"])
  );
  doc.text(
    18,
    height + 4,
    data["billing_address"] === undefined
      ? ""
      : toUpperCase(data["billing_address"]["street"])
  );
  doc.text(
    18,
    height + 8,
    data["billing_address"] === undefined
      ? ""
      : toUpperCase(data["billing_address"]["city"]) +
          ", " +
          toUpperCase(data["billing_address"]["state"]) +
          " " +
          toUpperCase(data["billing_address"]["zipcode"])
  );
  const service_address = data?.dumpster_addresses[0];
  data.service_address = service_address;
  doc.setFontType("bold");
  doc.text(18, height - 20, "SERVICE ADDRESS:");
  doc.setFontType("normal");

  doc.text(
    18,
    height - 16,
    data["service_address"] === undefined
      ? ""
      : toUpperCase(data["service_address"]["street"])
  );
  doc.text(
    18,
    height - 12,
    data["service_address"] === undefined
      ? ""
      : toUpperCase(data["service_address"]["city"]) +
          ", " +
          toUpperCase(data["service_address"]["state"]) +
          " " +
          toUpperCase(data["service_address"]["zipcode"])
  );

  height -= 3;
  doc.setFontType("bold");
  let service_label = "SERVICE MONTH OR QUARTER:";
  doc.text(140, height, service_label);
  doc.setFontType("normal");
  doc.text(140, height + 4, toUpperCase(billFrequencyLabel));

  height += 30;
  doc.setFontType("bold");
  doc.setFontSize(10);
  doc.text(10, height, "SERVICE DESCRIPTION");
  doc.setFontSize(8);
  var receiptdata = receiptsToPrintFinal;

  // doc.rect(6, height + 3, 198, 9 * (receiptdata.length + 1));
  doc.line(6, height + 12, 204.25, height + 12);
  for (let i = 0; i < receiptdata.length; i += 1) {}

  for (let i = 0; i < receiptdata.length; i += 1) {
    // doc.line(8, height + 12 + 9 * (i + 1), 201, height + 12 + 9 * (i + 1));
  }

  var width_table2 = [8, 32, 80, 170];

  // doc.line(
  //   width_table2[1],
  //   height + 3,
  //   width_table2[1],
  //   height + 12 + 9 * receiptdata.length
  // );
  // doc.line(
  //   width_table2[2],
  //   height + 3,
  //   width_table2[2],
  //   height + 12 + 9 * receiptdata.length
  // );
  // doc.line(
  //   width_table2[3],
  //   height + 3,
  //   width_table2[3],
  //   height + 12 + 9 * receiptdata.length
  // );

  doc.text(15, height + 8.5, "DATE");
  doc.text(50, height + 8.5, "STATUS");
  doc.text(116, height + 8.5, "DESCRIPTION");
  doc.text(181, height + 8.5, "AMOUNT");

  doc.setFontType("normal");
  const maxWidthDescription = width_table2[3] - width_table2[2] - 8;
  let extraLines = 0;
  for (let i = 0; i < receiptdata.length; i += 1) {
    if (receiptdata[i]) {
      doc.text(
        width_table2[0] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i]?.time?.toUpperCase()
      );
      doc.text(
        width_table2[1] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i].status ? receiptdata[i].status.toUpperCase() : ""
      );
      // doc.text(
      //   width_table2[2] + 4,
      //   height + 18 + 9 * i + 5 * extraLines,
      //   receiptdata[i]?.description?.toUpperCase()
      // );
      // Wrap the description text
      const wrappedDescription = doc.splitTextToSize(
        receiptdata[i]?.description?.toUpperCase() || "",
        maxWidthDescription
      );
      doc.text(
        width_table2[2] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        wrappedDescription
      );
      doc.text(
        width_table2[3] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i].amount ? receiptdata[i].amount.toUpperCase() : ""
      );
      extraLines += wrappedDescription.length - 1;
      doc.line(
        8,
        height + 12 + 9 * (i + 1) + 5 * extraLines,
        201,
        height + 12 + 9 * (i + 1) + 5 * extraLines
      );
    } else {
      // Handle the case where history with the given key is not found
      console.log("failure to capture data, try again");
    }
    for (let j = 0; j < receiptdata[i].length; j++) {
      // console.log(receiptdata[i][j], "my jsskjflsdjfl");
    }
  }
  doc.rect(6, height + 3, 198, 9 * (receiptdata.length + 1) + 5 * extraLines);
  doc.line(
    width_table2[1],
    height + 3,
    width_table2[1],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  doc.line(
    width_table2[2],
    height + 3,
    width_table2[2],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  doc.line(
    width_table2[3],
    height + 3,
    width_table2[3],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  if (receiptdata.length <= 4) {
    height += 56 + 5 * extraLines;
  } else {
    height += 12 + 9 * (receiptdata.length + 1) + 5 * extraLines;
  }

  doc.setFontType("bold");
  doc.setFontSize(10);
  doc.text(10, height, "Additional Notes: ");
  doc.setFontSize(8);
  doc.text(10, height + 5, additionalText);

  doc.setFontType("normal");
  doc.setFontSize(8);
  doc.text(
    37,
    height + 16,
    "If you would like to enroll in the Recurring Credit Card Program or make changes for a Recurring Credit Card,"
  );
  doc.text(73, height + 19, "please contact our office at (724) 758-9400.");
  doc.setFontType("bold");
  doc.text(80, height + 22, " Thank you for your business!");

  if (name === "base64") {
    return doc.output("datauristring");
  } else {
    let fileName = "";
    if (name === "montly")
      fileName = `Invoice monthly - ${customer["name"]}.pdf`;
    else fileName = `Invoice ${name}.pdf`;
    doc.save(fileName);
  }
}

async function residential_customer_receipt_pdf_download(
  customer,
  name,
  state_time,
  customer_number,
  billFrequencyLabel,
  additionalText,
  invoice_no,
  receiptsToPrintFinal
) {
  let statement_time =
    state_time == "" ? moment().format("MM/DD/YYYY") : state_time.toString();

  if (state_time.length) statement_time = state_time;
  var doc = new jsPDF();

  var data = customer[0];
  let customer_NO = data["Account Number"];
  if (customer_number.length) customer_NO = customer_number;
  var height = 3;
  doc.addImage(logoImage, "JPEG", 15, height + 5, 40, 15);
  doc.setFontType("bold");
  doc.setFontSize(8);
  doc.setDrawColor(80, 80, 80);
  height += 25;
  doc.text(18, height, "AIKEN REFUSE, Inc.");
  doc.setFontType("normal");
  height += 4;
  doc.text(18, height, "1613 Wampum Road - Ellwood City, PA 16117");
  doc.setFontType("bold");
  doc.text(18, height + 5.5, "724-758-9400 -- info@aikenrefuse.com");

  doc.setFontType("normal");
  doc.text(140, height, "INVOICE DATE:");
  doc.text(186, height, statement_time.toString());
  doc.text(140, height + 8, "CUSTOMER No:");
  doc.text(
    184.5,
    height + 8,
    customer_NO === undefined ? "" : toUpperCase(customer_NO.toString())
  );

  doc.text(140, height + 13, "INVOICE No:");
  doc.text(
    184.5,
    height + 13,
    invoice_no === undefined ? "" : toUpperCase(invoice_no.toString())
  );

  doc.text(140, height + 17, "CHECK No:");
  doc.line(170, height + 17, 200, height + 17);

  doc.text(140, height + 22, "AMOUNT");
  doc.text(140, height + 25, "ENCLOSED:");
  doc.line(170, height + 25, 200, height + 25);

  height += 35;

  doc.text(
    18,
    height,
    data["name"] === undefined ? "" : toUpperCase(data["name"])
  );
  doc.text(
    18,
    height + 4,
    data["billing_address"] === undefined
      ? ""
      : toUpperCase(data["billing_address"]["street"])
  );
  doc.text(
    18,
    height + 8,
    data["billing_address"] === undefined
      ? ""
      : toUpperCase(data["billing_address"]["city"]) +
          ", " +
          toUpperCase(data["billing_address"]["state"]) +
          " " +
          toUpperCase(data["billing_address"]["zipcode"])
  );

  doc.setFontType("bold");
  doc.text(18, height - 20, "SERVICE ADDRESS:");
  doc.setFontType("normal");

  doc.text(
    18,
    height - 16,
    data["service_address"] === undefined
      ? ""
      : toUpperCase(data["service_address"]["street"])
  );
  doc.text(
    18,
    height - 12,
    data["service_address"] === undefined
      ? ""
      : toUpperCase(data["service_address"]["city"]) +
          ", " +
          toUpperCase(data["service_address"]["state"]) +
          " " +
          toUpperCase(data["service_address"]["zipcode"])
  );

  height -= 3;
  doc.setFontType("bold");
  let service_label = "SERVICE MONTH OR QUARTER:";
  doc.text(140, height, service_label);
  doc.setFontType("normal");
  doc.text(140, height + 4, toUpperCase(billFrequencyLabel));

  height += 30;
  doc.setFontType("bold");
  doc.setFontSize(10);
  doc.text(10, height, "SERVICE DESCRIPTION");
  doc.setFontSize(8);
  var receiptdata = receiptsToPrintFinal;

  // doc.rect(6, height + 3, 198, 9 * (receiptdata.length + 1));
  doc.line(6, height + 12, 204.25, height + 12);
  for (let i = 0; i < receiptdata.length; i += 1) {}

  // for (let i = 0; i < receiptdata.length; i += 1) {
  //   doc.line(8, height + 12 + 9 * (i + 1), 201, height + 12 + 9 * (i + 1));
  // }

  var width_table2 = [8, 32, 80, 170];

  // doc.line(
  //   width_table2[1],
  //   height + 3,
  //   width_table2[1],
  //   height + 12 + 9 * receiptdata.length
  // );
  // doc.line(
  //   width_table2[2],
  //   height + 3,
  //   width_table2[2],
  //   height + 12 + 9 * receiptdata.length
  // );
  // doc.line(
  //   width_table2[3],
  //   height + 3,
  //   width_table2[3],
  //   height + 12 + 9 * receiptdata.length
  // );

  doc.text(15, height + 8.5, "DATE");
  doc.text(50, height + 8.5, "STATUS");
  doc.text(116, height + 8.5, "DESCRIPTION");
  doc.text(181, height + 8.5, "AMOUNT");

  doc.setFontType("normal");
  const maxWidthDescription = width_table2[3] - width_table2[2] - 8;
  let extraLines = 0;
  for (let i = 0; i < receiptdata.length; i += 1) {
    if (receiptdata[i]) {
      doc.text(
        width_table2[0] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i]?.time?.toUpperCase()
      );
      doc.text(
        width_table2[1] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i].status ? receiptdata[i].status.toUpperCase() : ""
      );
      // doc.text(
      //   width_table2[2] + 4,
      //   height + 18 + 9 * i + 5 * extraLines,
      //   receiptdata[i]?.description?.toUpperCase()
      // );
      // Wrap the description text
      const wrappedDescription = doc.splitTextToSize(
        receiptdata[i]?.description?.toUpperCase() || "",
        maxWidthDescription
      );
      doc.text(
        width_table2[2] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        wrappedDescription
      );
      doc.text(
        width_table2[3] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i].amount ? receiptdata[i].amount.toUpperCase() : ""
      );
      extraLines += wrappedDescription.length - 1;
      doc.line(
        8,
        height + 12 + 9 * (i + 1) + 5 * extraLines,
        201,
        height + 12 + 9 * (i + 1) + 5 * extraLines
      );
    } else {
      // Handle the case where history with the given key is not found
      console.log("failure to capture data, try again");
    }
    for (let j = 0; j < receiptdata[i].length; j++) {
      // console.log(receiptdata[i][j], "my jsskjflsdjfl");
    }
  }
  doc.rect(6, height + 3, 198, 9 * (receiptdata.length + 1) + 5 * extraLines);

  doc.line(
    width_table2[1],
    height + 3,
    width_table2[1],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  doc.line(
    width_table2[2],
    height + 3,
    width_table2[2],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  doc.line(
    width_table2[3],
    height + 3,
    width_table2[3],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  if (receiptdata.length <= 4) {
    height += 56 + 5 * extraLines;
  } else {
    height += 12 + 9 * (receiptdata.length + 1) + 5 * extraLines;
  }

  doc.setFontType("bold");
  doc.setFontSize(10);
  doc.text(10, height, "Additional Notes: ");
  doc.setFontSize(8);
  doc.text(10, height + 5, additionalText);

  doc.setFontType("normal");
  doc.setFontSize(8);
  doc.text(
    37,
    height + 16,
    "If you would like to enroll in the Recurring Credit Card Program or make changes for a Recurring Credit Card,"
  );
  doc.text(73, height + 19, "please contact our office at (724) 758-9400.");
  doc.setFontType("bold");
  doc.text(80, height + 22, " Thank you for your business!");

  if (name === "base64") {
    return doc.output("datauristring");
  } else {
    let fileName = "";
    if (name === "montly")
      fileName = `Invoice monthly - ${customer["name"]}.pdf`;
    else fileName = `Invoice ${name}.pdf`;
    doc.save(fileName);
  }
}

async function EastButler_customer_receipt_pdf_download(
  customer,
  name,
  state_time,
  customer_number,
  billFrequencyLabel,
  additionalText,
  invoice_no,
  receiptsToPrintFinal
) {
  let statement_time =
    state_time == "" ? moment().format("MM/DD/YYYY") : state_time.toString();

  if (state_time.length) statement_time = state_time;
  var doc = new jsPDF();

  var data = customer[0];
  let customer_NO = data["Account Number"];
  if (customer_number.length) customer_NO = customer_number;
  var height = 3;
  doc.addImage(logoImage, "JPEG", 15, height + 5, 40, 15);
  doc.setFontType("bold");
  doc.setFontSize(8);
  doc.setDrawColor(80, 80, 80);
  height += 25;
  doc.text(18, height, "AIKEN REFUSE, Inc.");
  doc.setFontType("normal");
  height += 4;
  doc.text(18, height, "1613 Wampum Road - Ellwood City, PA 16117");
  doc.setFontType("bold");
  doc.text(18, height + 5.5, "724-758-9400 -- info@aikenrefuse.com");

  doc.setFontType("normal");
  doc.text(140, height, "INVOICE DATE:");
  doc.text(186, height, statement_time.toString());
  doc.text(140, height + 8, "CUSTOMER No:");
  doc.text(
    184.5,
    height + 8,
    customer_NO === undefined ? "" : toUpperCase(customer_NO.toString())
  );

  doc.text(140, height + 13, "INVOICE No:");
  doc.text(
    184.5,
    height + 13,
    invoice_no === undefined ? "" : toUpperCase(invoice_no.toString())
  );

  doc.text(140, height + 17, "CHECK No:");
  doc.line(170, height + 17, 200, height + 17);

  doc.text(140, height + 22, "AMOUNT");
  doc.text(140, height + 25, "ENCLOSED:");
  doc.line(170, height + 25, 200, height + 25);

  height += 35;
  doc.text(
    18,
    height,
    data["name"] === undefined ? "" : toUpperCase(data["name"])
  );
  doc.text(
    18,
    height + 4,
    data["billing_address"] === undefined
      ? ""
      : toUpperCase(data["billing_address"]["street"])
  );
  doc.text(
    18,
    height + 8,
    data["billing_address"] === undefined
      ? ""
      : toUpperCase(data["billing_address"]["city"]) +
          ", " +
          toUpperCase(data["billing_address"]["state"]) +
          " " +
          toUpperCase(data["billing_address"]["zipcode"])
  );

  doc.setFontType("bold");
  doc.text(18, height - 20, "SERVICE ADDRESS:");
  doc.setFontType("normal");

  doc.text(
    18,
    height - 16,
    data["service_address"] === undefined
      ? ""
      : toUpperCase(data["service_address"]["street"])
  );
  doc.text(
    18,
    height - 12,
    data["service_address"] === undefined
      ? ""
      : toUpperCase(data["service_address"]["city"]) +
          ", " +
          toUpperCase(data["service_address"]["state"]) +
          " " +
          toUpperCase(data["service_address"]["zipcode"])
  );

  height -= 3;
  doc.setFontType("bold");
  let service_label = "SERVICE MONTH OR QUARTER:";
  doc.text(140, height, service_label);
  doc.setFontType("normal");
  doc.text(140, height + 4, toUpperCase(billFrequencyLabel));

  height += 30;
  doc.setFontType("bold");
  doc.setFontSize(10);
  doc.text(10, height, "SERVICE DESCRIPTION");
  doc.setFontSize(8);
  var receiptdata = receiptsToPrintFinal;

  // doc.rect(6, height + 3, 198, 9 * (receiptdata.length + 1));
  doc.line(6, height + 12, 204.25, height + 12);
  for (let i = 0; i < receiptdata.length; i += 1) {}

  // for (let i = 0; i < receiptdata.length; i += 1) {
  //   doc.line(8, height + 12 + 9 * (i + 1), 201, height + 12 + 9 * (i + 1));
  // }

  var width_table2 = [8, 32, 80, 170];

  // doc.line(
  //   width_table2[1],
  //   height + 3,
  //   width_table2[1],
  //   height + 12 + 9 * receiptdata.length
  // );
  // doc.line(
  //   width_table2[2],
  //   height + 3,
  //   width_table2[2],
  //   height + 12 + 9 * receiptdata.length
  // );
  // doc.line(
  //   width_table2[3],
  //   height + 3,
  //   width_table2[3],
  //   height + 12 + 9 * receiptdata.length
  // );

  doc.text(15, height + 8.5, "DATE");
  doc.text(50, height + 8.5, "STATUS");
  doc.text(116, height + 8.5, "DESCRIPTION");
  doc.text(181, height + 8.5, "AMOUNT");

  doc.setFontType("normal");
  const maxWidthDescription = width_table2[3] - width_table2[2] - 8;
  let extraLines = 0;
  for (let i = 0; i < receiptdata.length; i += 1) {
    if (receiptdata[i]) {
      doc.text(
        width_table2[0] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i]?.time?.toUpperCase()
      );
      doc.text(
        width_table2[1] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i].status ? receiptdata[i].status.toUpperCase() : ""
      );
      // doc.text(
      //   width_table2[2] + 4,
      //   height + 18 + 9 * i + 5 * extraLines,
      //   receiptdata[i]?.description?.toUpperCase()
      // );
      // Wrap the description text
      const wrappedDescription = doc.splitTextToSize(
        receiptdata[i]?.description?.toUpperCase() || "",
        maxWidthDescription
      );
      doc.text(
        width_table2[2] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i].amount ? receiptdata[i].amount.toUpperCase() : ""
      );
      doc.text(
        width_table2[3] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i].amount ? receiptdata[i].amount.toUpperCase() : ""
      );
      extraLines += wrappedDescription.length - 1;
      doc.line(
        8,
        height + 12 + 9 * (i + 1) + 5 * extraLines,
        201,
        height + 12 + 9 * (i + 1) + 5 * extraLines
      );
    } else {
      // Handle the case where history with the given key is not found
      console.log("failure to capture data, try again");
    }
    for (let j = 0; j < receiptdata[i].length; j++) {
      // console.log(receiptdata[i][j], "my jsskjflsdjfl");
    }
  }
  doc.rect(6, height + 3, 198, 9 * (receiptdata.length + 1) + 5 * extraLines);
  doc.line(
    width_table2[1],
    height + 3,
    width_table2[1],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  doc.line(
    width_table2[2],
    height + 3,
    width_table2[2],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  doc.line(
    width_table2[3],
    height + 3,
    width_table2[3],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  if (receiptdata.length <= 4) {
    height += 56 + 5 * extraLines;
  } else {
    height += 12 + 9 * (receiptdata.length + 1) + 5 * extraLines;
  }

  doc.setFontType("bold");
  doc.setFontSize(10);
  doc.text(10, height, "Additional Notes: ");
  doc.setFontSize(8);
  doc.text(10, height + 5, additionalText);

  doc.setFontType("normal");
  doc.setFontSize(8);
  doc.text(
    37,
    height + 16,
    "If you would like to enroll in the Recurring Credit Card Program or make changes for a Recurring Credit Card,"
  );
  doc.text(73, height + 19, "please contact our office at (724) 758-9400.");
  doc.setFontType("bold");
  doc.text(80, height + 22, " Thank you for your business!");

  if (name === "base64") {
    return doc.output("datauristring");
  } else {
    let fileName = "";
    if (name === "montly")
      fileName = `Invoice monthly - ${customer["name"]}.pdf`;
    else fileName = `Invoice ${name}.pdf`;
    doc.save(fileName);
  }
}

async function SlipperyRock_customer_receipt_pdf_download(
  customer,
  name,
  state_time,
  customer_number,
  billFrequencyLabel,
  additionalText,
  invoice_no,
  receiptsToPrintFinal
) {
  let statement_time =
    state_time == "" ? moment().format("MM/DD/YYYY") : state_time.toString();

  if (state_time.length) statement_time = state_time;
  var doc = new jsPDF();

  var data = customer[0];
  let customer_NO = data["Account Number"];
  if (customer_number.length) customer_NO = customer_number;
  var height = 3;
  doc.addImage(logoImage, "JPEG", 15, height + 5, 40, 15);
  doc.setFontType("bold");
  doc.setFontSize(8);
  doc.setDrawColor(80, 80, 80);
  height += 25;
  doc.text(18, height, "AIKEN REFUSE, Inc.");
  doc.setFontType("normal");
  height += 4;
  doc.text(18, height, "1613 Wampum Road - Ellwood City, PA 16117");
  doc.setFontType("bold");
  doc.text(18, height + 5.5, "724-758-9400 -- info@aikenrefuse.com");

  doc.setFontType("normal");
  doc.text(140, height, "INVOICE DATE:");
  doc.text(186, height, statement_time.toString());
  doc.text(140, height + 8, "CUSTOMER No:");
  doc.text(
    184.5,
    height + 8,
    customer_NO === undefined ? "" : toUpperCase(customer_NO.toString())
  );

  doc.text(140, height + 13, "INVOICE No:");
  doc.text(
    184.5,
    height + 13,
    invoice_no === undefined ? "" : toUpperCase(invoice_no.toString())
  );

  doc.text(140, height + 17, "CHECK No:");
  doc.line(170, height + 17, 200, height + 17);

  doc.text(140, height + 22, "AMOUNT");
  doc.text(140, height + 25, "ENCLOSED:");
  doc.line(170, height + 25, 200, height + 25);

  height += 35;
  doc.text(
    18,
    height,
    data["name"] === undefined ? "" : toUpperCase(data["name"])
  );
  doc.text(
    18,
    height + 4,
    data["billing_address"] === undefined
      ? ""
      : toUpperCase(data["billing_address"]["street"])
  );
  doc.text(
    18,
    height + 8,
    data["billing_address"] === undefined
      ? ""
      : toUpperCase(data["billing_address"]["city"]) +
          ", " +
          toUpperCase(data["billing_address"]["state"]) +
          " " +
          toUpperCase(data["billing_address"]["zipcode"])
  );

  doc.setFontType("bold");
  doc.text(18, height - 20, "SERVICE ADDRESS:");
  doc.setFontType("normal");

  doc.text(
    18,
    height - 16,
    data["service_address"] === undefined
      ? ""
      : toUpperCase(data["service_address"]["street"])
  );
  doc.text(
    18,
    height - 12,
    data["service_address"] === undefined
      ? ""
      : toUpperCase(data["service_address"]["city"]) +
          ", " +
          toUpperCase(data["service_address"]["state"]) +
          " " +
          toUpperCase(data["service_address"]["zipcode"])
  );

  height -= 3;
  doc.setFontType("bold");
  let service_label = "SERVICE MONTH OR QUARTER:";
  doc.text(140, height, service_label);
  doc.setFontType("normal");
  doc.text(140, height + 4, toUpperCase(billFrequencyLabel));

  height += 30;
  doc.setFontType("bold");
  doc.setFontSize(10);
  doc.text(10, height, "SERVICE DESCRIPTION");
  doc.setFontSize(8);
  var receiptdata = receiptsToPrintFinal;

  // doc.rect(6, height + 3, 198, 9 * (receiptdata.length + 1));
  doc.line(6, height + 12, 204.25, height + 12);
  for (let i = 0; i < receiptdata.length; i += 1) {}

  // for (let i = 0; i < receiptdata.length; i += 1) {
  //   doc.line(8, height + 12 + 9 * (i + 1), 201, height + 12 + 9 * (i + 1));
  // }

  var width_table2 = [8, 32, 80, 170];

  // doc.line(
  //   width_table2[1],
  //   height + 3,
  //   width_table2[1],
  //   height + 12 + 9 * receiptdata.length
  // );
  // doc.line(
  //   width_table2[2],
  //   height + 3,
  //   width_table2[2],
  //   height + 12 + 9 * receiptdata.length
  // );
  // doc.line(
  //   width_table2[3],
  //   height + 3,
  //   width_table2[3],
  //   height + 12 + 9 * receiptdata.length
  // );

  doc.text(15, height + 8.5, "DATE");
  doc.text(50, height + 8.5, "STATUS");
  doc.text(116, height + 8.5, "DESCRIPTION");
  doc.text(181, height + 8.5, "AMOUNT");

  doc.setFontType("normal");
  const maxWidthDescription = width_table2[3] - width_table2[2] - 8;
  let extraLines = 0;
  for (let i = 0; i < receiptdata.length; i += 1) {
    if (receiptdata[i]) {
      doc.text(
        width_table2[0] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i]?.time?.toUpperCase()
      );
      doc.text(
        width_table2[1] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i].status ? receiptdata[i].status.toUpperCase() : ""
      );
      // doc.text(
      //   width_table2[2] + 4,
      //   height + 18 + 9 * i + 5 * extraLines,
      //   receiptdata[i]?.description?.toUpperCase()
      // );
      // Wrap the description text
      const wrappedDescription = doc.splitTextToSize(
        receiptdata[i]?.description?.toUpperCase() || "",
        maxWidthDescription
      );
      doc.text(
        width_table2[2] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        wrappedDescription
      );
      doc.text(
        width_table2[3] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i].amount ? receiptdata[i].amount.toUpperCase() : ""
      );
      extraLines += wrappedDescription.length - 1;
      doc.line(
        8,
        height + 12 + 9 * (i + 1) + 5 * extraLines,
        201,
        height + 12 + 9 * (i + 1) + 5 * extraLines
      );
    } else {
      // Handle the case where history with the given key is not found
      console.log("failure to capture data, try again");
    }
    for (let j = 0; j < receiptdata[i].length; j++) {
      // console.log(receiptdata[i][j], "my jsskjflsdjfl");
    }
  }
  doc.rect(6, height + 3, 198, 9 * (receiptdata.length + 1) + 5 * extraLines);
  doc.line(
    width_table2[1],
    height + 3,
    width_table2[1],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  doc.line(
    width_table2[2],
    height + 3,
    width_table2[2],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  doc.line(
    width_table2[3],
    height + 3,
    width_table2[3],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  if (receiptdata.length <= 4) {
    height += 56 + 5 * extraLines;
  } else {
    height += 12 + 9 * (receiptdata.length + 1) + 5 * extraLines;
  }

  doc.setFontType("bold");
  doc.setFontSize(10);
  doc.text(10, height, "Additional Notes: ");
  doc.setFontSize(8);
  doc.text(10, height + 5, additionalText);

  doc.setFontType("normal");
  doc.setFontSize(8);
  doc.text(
    37,
    height + 16,
    "If you would like to enroll in the Recurring Credit Card Program or make changes for a Recurring Credit Card,"
  );
  doc.text(73, height + 19, "please contact our office at (724) 758-9400.");
  doc.setFontType("bold");
  doc.text(80, height + 22, " Thank you for your business!");

  if (name === "base64") {
    return doc.output("datauristring");
  } else {
    let fileName = "";
    if (name === "montly")
      fileName = `Invoice monthly - ${customer["name"]}.pdf`;
    else fileName = `Invoice ${name}.pdf`;
    doc.save(fileName);
  }
}

async function westmayfield_customer_receipt_pdf_download(
  customer,
  name,
  state_time,
  customer_number,
  billFrequencyLabel,
  additionalText,
  invoice_no,
  receiptsToPrintFinal
) {
  let statement_time =
    state_time == "" ? moment().format("MM/DD/YYYY") : state_time.toString();

  if (state_time.length) statement_time = state_time;
  var doc = new jsPDF();

  var data = customer[0];
  let customer_NO = data["Account Number"];
  if (customer_number.length) customer_NO = customer_number;
  var height = 3;
  doc.addImage(logoImage, "JPEG", 15, height + 5, 40, 15);
  doc.setFontType("bold");
  doc.setFontSize(8);
  doc.setDrawColor(80, 80, 80);
  height += 25;
  doc.text(18, height, "AIKEN REFUSE, Inc.");
  doc.setFontType("normal");
  height += 4;
  doc.text(18, height, "1613 Wampum Road - Ellwood City, PA 16117");
  doc.setFontType("bold");
  doc.text(18, height + 5.5, "724-758-9400 -- info@aikenrefuse.com");

  doc.setFontType("normal");
  doc.text(140, height, "INVOICE DATE:");
  doc.text(186, height, statement_time.toString());
  doc.text(140, height + 8, "CUSTOMER No:");
  doc.text(
    184.5,
    height + 8,
    customer_NO === undefined ? "" : toUpperCase(customer_NO.toString())
  );

  doc.text(140, height + 13, "INVOICE No:");
  doc.text(
    184.5,
    height + 13,
    invoice_no === undefined ? "" : toUpperCase(invoice_no.toString())
  );

  doc.text(140, height + 17, "CHECK No:");
  doc.line(170, height + 17, 200, height + 17);

  doc.text(140, height + 22, "AMOUNT");
  doc.text(140, height + 25, "ENCLOSED:");
  doc.line(170, height + 25, 200, height + 25);

  height += 35;
  doc.text(
    18,
    height,
    data["name"] === undefined ? "" : toUpperCase(data["name"])
  );
  doc.setFontType("bold");
  doc.text(18, height - 20, "SERVICE ADDRESS:");
  doc.setFontType("normal");

  doc.text(
    18,
    height - 16,
    data["service_address"] === undefined
      ? ""
      : toUpperCase(data["service_address"]["street"])
  );
  doc.text(
    18,
    height - 12,
    data["service_address"] === undefined
      ? ""
      : toUpperCase(data["service_address"]["city"]) +
          ", " +
          toUpperCase(data["service_address"]["state"]) +
          " " +
          toUpperCase(data["service_address"]["zipcode"])
  );
  doc.text(
    18,
    height + 4,
    data["billing_address"] === undefined
      ? ""
      : toUpperCase(data["billing_address"]["street"])
  );
  doc.text(
    18,
    height + 8,
    data["billing_address"] === undefined
      ? ""
      : toUpperCase(data["billing_address"]["city"]) +
          ", " +
          toUpperCase(data["billing_address"]["state"]) +
          " " +
          toUpperCase(data["billing_address"]["zipcode"])
  );

  height -= 3;
  doc.setFontType("bold");
  let service_label = "SERVICE MONTH OR QUARTER:";
  doc.text(140, height, service_label);
  doc.setFontType("normal");
  doc.text(140, height + 4, toUpperCase(billFrequencyLabel));

  height += 30;
  doc.setFontType("bold");
  doc.setFontSize(10);
  doc.text(10, height, "SERVICE DESCRIPTION");
  doc.setFontSize(8);
  var receiptdata = receiptsToPrintFinal;

  // doc.rect(6, height + 3, 198, 9 * (receiptdata.length + 1));
  doc.line(6, height + 12, 204.25, height + 12);
  for (let i = 0; i < receiptdata.length; i += 1) {}

  for (let i = 0; i < receiptdata.length; i += 1) {
    // doc.line(8, height + 12 + 9 * (i + 1), 201, height + 12 + 9 * (i + 1)); //this is the line
  }

  var width_table2 = [8, 32, 80, 170];

  // doc.line(
  //   width_table2[1],
  //   height + 3,
  //   width_table2[1],
  //   height + 12 + 9 * receiptdata.length
  // );
  // doc.line(
  //   width_table2[2],
  //   height + 3,
  //   width_table2[2],
  //   height + 12 + 9 * receiptdata.length
  // );
  // doc.line(
  //   width_table2[3],
  //   height + 3,
  //   width_table2[3],
  //   height + 12 + 9 * receiptdata.length
  // );

  doc.text(15, height + 8.5, "DATE");
  doc.text(50, height + 8.5, "STATUS");
  doc.text(116, height + 8.5, "DESCRIPTION");
  doc.text(181, height + 8.5, "AMOUNT");

  doc.setFontType("normal");
  const maxWidthDescription = width_table2[3] - width_table2[2] - 8;
  let extraLines = 0;
  for (let i = 0; i < receiptdata.length; i += 1) {
    if (receiptdata[i]) {
      doc.text(
        width_table2[0] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i]?.time?.toUpperCase()
      );
      doc.text(
        width_table2[1] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i].status ? receiptdata[i].status.toUpperCase() : ""
      );
      // doc.text(
      //   width_table2[2] + 4,
      //   height + 18 + 9 * i,
      //   receiptdata[i]?.description?.toUpperCase()
      // );
      // Wrap the description text
      const wrappedDescription = doc.splitTextToSize(
        receiptdata[i]?.description?.toUpperCase() || "",
        maxWidthDescription
      );
      doc.text(
        width_table2[2] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        wrappedDescription
      );
      doc.text(
        width_table2[3] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i].amount ? receiptdata[i].amount.toUpperCase() : ""
      );
      extraLines += wrappedDescription.length - 1;
      doc.line(
        8,
        height + 12 + 9 * (i + 1) + 5 * extraLines,
        201,
        height + 12 + 9 * (i + 1) + 5 * extraLines
      );
    } else {
      // Handle the case where history with the given key is not found
      console.log("failure to capture data, try again");
    }
    for (let j = 0; j < receiptdata[i].length; j++) {
      // console.log(receiptdata[i][j], "my jsskjflsdjfl");
    }
  }
  doc.rect(6, height + 3, 198, 9 * (receiptdata.length + 1) + 5 * extraLines);
  doc.line(
    width_table2[1],
    height + 3,
    width_table2[1],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  doc.line(
    width_table2[2],
    height + 3,
    width_table2[2],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  doc.line(
    width_table2[3],
    height + 3,
    width_table2[3],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );

  if (receiptdata.length <= 4) {
    height += 56 + 5 * extraLines;
  } else {
    height += 12 + 9 * (receiptdata.length + 1) + 5 * extraLines;
  }
  doc.setFontType("bold");
  doc.setFontSize(10);
  doc.text(10, height, "Additional Notes: ");
  doc.setFontSize(8);
  doc.text(10, height + 5, additionalText);

  doc.setFontType("normal");
  doc.setFontSize(8);
  doc.text(
    37,
    height + 16,
    "If you would like to enroll in the Recurring Credit Card Program or make changes for a Recurring Credit Card,"
  );
  doc.text(73, height + 19, "please contact our office at (724) 758-9400.");
  doc.setFontType("bold");
  doc.text(80, height + 22, " Thank you for your business!");

  if (name === "base64") {
    return doc.output("datauristring");
  } else {
    let fileName = "";
    if (name === "montly")
      fileName = `Invoice monthly - ${customer["name"]}.pdf`;
    else fileName = `Invoice ${name}.pdf`;
    doc.save(fileName);
  }
}

async function newcastle2_customer_receipt_pdf_download(
  customer,
  name,
  state_time,
  customer_number,
  billFrequencyLabel,
  additionalText,
  invoice_no,
  receiptsToPrintFinal
) {
  let statement_time =
    state_time == "" ? moment().format("MM/DD/YYYY") : state_time.toString();

  if (state_time.length) statement_time = state_time;
  var doc = new jsPDF();

  var data = customer[0];
  let customer_NO = data["Account Number"];
  if (customer_number.length) customer_NO = customer_number;
  var height = 3;
  doc.addImage(logoImage, "JPEG", 15, height + 5, 40, 15);
  doc.setFontType("bold");
  doc.setFontSize(8);
  doc.setDrawColor(80, 80, 80);
  height += 25;
  doc.text(18, height, "AIKEN REFUSE, Inc.");
  doc.setFontType("normal");
  height += 4;
  doc.text(18, height, "1613 Wampum Road - Ellwood City, PA 16117");
  doc.setFontType("bold");
  doc.text(18, height + 5.5, "724-758-9400 -- info@aikenrefuse.com");

  doc.setFontType("normal");
  doc.text(140, height, "INVOICE DATE:");
  doc.text(186, height, statement_time.toString());
  doc.text(140, height + 8, "CUSTOMER No:");
  doc.text(
    184.5,
    height + 8,
    customer_NO === undefined ? "" : toUpperCase(customer_NO.toString())
  );

  doc.text(140, height + 13, "INVOICE No:");
  doc.text(
    184.5,
    height + 13,
    invoice_no === undefined ? "" : toUpperCase(invoice_no.toString())
  );

  doc.text(140, height + 17, "CHECK No:");
  doc.line(170, height + 17, 200, height + 17);

  doc.text(140, height + 22, "AMOUNT");
  doc.text(140, height + 25, "ENCLOSED:");
  doc.line(170, height + 25, 200, height + 25);

  height += 35;
  doc.text(
    18,
    height,
    data["name"] === undefined ? "" : toUpperCase(data["name"])
  );
  doc.text(
    18,
    height + 4,
    data["billing_address"] === undefined
      ? ""
      : toUpperCase(data["billing_address"]["street"])
  );
  doc.text(
    18,
    height + 8,
    data["billing_address"] === undefined
      ? ""
      : toUpperCase(data["billing_address"]["city"]) +
          ", " +
          toUpperCase(data["billing_address"]["state"]) +
          " " +
          toUpperCase(data["billing_address"]["zipcode"])
  );

  doc.setFontType("bold");
  doc.text(18, height - 20, "SERVICE ADDRESS:");
  doc.setFontType("normal");

  doc.text(
    18,
    height - 16,
    data["service_address"] === undefined
      ? ""
      : toUpperCase(data["service_address"]["street"])
  );
  doc.text(
    18,
    height - 12,
    data["service_address"] === undefined
      ? ""
      : toUpperCase(data["service_address"]["city"]) +
          ", " +
          toUpperCase(data["service_address"]["state"]) +
          " " +
          toUpperCase(data["service_address"]["zipcode"])
  );

  height -= 3;
  doc.setFontType("bold");
  let service_label = "SERVICE MONTH OR QUARTER:";
  doc.text(140, height, service_label);
  doc.setFontType("normal");
  doc.text(140, height + 4, toUpperCase(billFrequencyLabel));

  height += 30;
  doc.setFontType("bold");
  doc.setFontSize(10);
  doc.text(10, height, "SERVICE DESCRIPTION");
  doc.setFontSize(8);
  var receiptdata = receiptsToPrintFinal;

  // doc.rect(6, height + 3, 198, 9 * (receiptdata.length + 1));
  doc.line(6, height + 12, 204.25, height + 12);
  for (let i = 0; i < receiptdata.length; i += 1) {}

  // for (let i = 0; i < receiptdata.length; i += 1) {
  //   doc.line(8, height + 12 + 9 * (i + 1), 201, height + 12 + 9 * (i + 1));
  // }

  var width_table2 = [8, 32, 80, 170];

  // doc.line(
  //   width_table2[1],
  //   height + 3,
  //   width_table2[1],
  //   height + 12 + 9 * receiptdata.length
  // );
  // doc.line(
  //   width_table2[2],
  //   height + 3,
  //   width_table2[2],
  //   height + 12 + 9 * receiptdata.length
  // );
  // doc.line(
  //   width_table2[3],
  //   height + 3,
  //   width_table2[3],
  //   height + 12 + 9 * receiptdata.length
  // );

  doc.text(15, height + 8.5, "DATE");
  doc.text(50, height + 8.5, "STATUS");
  doc.text(116, height + 8.5, "DESCRIPTION");
  doc.text(181, height + 8.5, "AMOUNT");

  doc.setFontType("normal");
  const maxWidthDescription = width_table2[3] - width_table2[2] - 8;
  let extraLines = 0;
  for (let i = 0; i < receiptdata.length; i += 1) {
    if (receiptdata[i]) {
      doc.text(
        width_table2[0] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i]?.time?.toUpperCase()
      );
      doc.text(
        width_table2[1] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i].status ? receiptdata[i].status.toUpperCase() : ""
      );
      // doc.text(
      //   width_table2[2] + 4,
      //   height + 18 + 9 * i,
      //   receiptdata[i]?.description?.toUpperCase()
      // );
      // Wrap the description text
      const wrappedDescription = doc.splitTextToSize(
        receiptdata[i]?.description?.toUpperCase() || "",
        maxWidthDescription
      );
      doc.text(
        width_table2[2] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        wrappedDescription
      );
      doc.text(
        width_table2[3] + 4,
        height + 18 + 9 * i + 5 * extraLines,
        receiptdata[i].amount ? receiptdata[i].amount.toUpperCase() : ""
      );
      extraLines += wrappedDescription.length - 1;
      doc.line(
        8,
        height + 12 + 9 * (i + 1) + 5 * extraLines,
        201,
        height + 12 + 9 * (i + 1) + 5 * extraLines
      );
    } else {
      // Handle the case where history with the given key is not found
      console.log("failure to capture data, try again");
    }
    for (let j = 0; j < receiptdata[i].length; j++) {
      // console.log(receiptdata[i][j], "my jsskjflsdjfl");
    }
  }
  doc.rect(6, height + 3, 198, 9 * (receiptdata.length + 1) + 5 * extraLines);
  doc.line(
    width_table2[1],
    height + 3,
    width_table2[1],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  doc.line(
    width_table2[2],
    height + 3,
    width_table2[2],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  doc.line(
    width_table2[3],
    height + 3,
    width_table2[3],
    height + 12 + 9 * receiptdata.length + 5 * extraLines
  );
  if (receiptdata.length <= 4) {
    height += 56 + 5 * extraLines;
  } else {
    height += 12 + 9 * (receiptdata.length + 1) + 5 * extraLines;
  }

  doc.setFontType("bold");
  doc.setFontSize(10);
  doc.text(10, height, "Additional Notes: ");
  doc.setFontSize(8);
  doc.text(10, height + 5, additionalText);

  doc.setFontType("normal");
  doc.setFontSize(8);
  doc.text(
    37,
    height + 16,
    "If you would like to enroll in the Recurring Credit Card Program or make changes for a Recurring Credit Card,"
  );
  doc.text(73, height + 19, "please contact our office at (724) 758-9400.");
  doc.setFontType("bold");
  doc.text(80, height + 22, " Thank you for your business!");

  if (name === "base64") {
    return doc.output("datauristring");
  } else {
    let fileName = "";
    if (name === "montly")
      fileName = `Invoice monthly - ${customer["name"]}.pdf`;
    else fileName = `Invoice ${name}.pdf`;
    doc.save(fileName);
  }
}

export const pdfService = {
  commercial_customer_receipt_pdf_download,
  commercial_customer_invoice_pdf_download,
  residential_customer_invoice_pdf_download,
  residential_customer_receipt_pdf_download,
  westmayfield_customer_invoice_pdf_download,
  westmayfield_customer_receipt_pdf_download,
  newcastle_customer_invoice_pdf_download,
  newcastle_customer_coverletter_pdf_download,
  individual_route_coverletter_pdf_download,
  newcastle2_customer_invoice_pdf_download,
  newcastle2_customer_receipt_pdf_download,
  EastButler_customer_receipt_pdf_download,
  SlipperyRock_customer_receipt_pdf_download,
  charged_items,
  current_month_quarter,
};
