import axios from 'axios';
import * as AxiosLogger from 'axios-logger';

import { HOST_API } from './config';

const axiosInstance = axios.create({
  baseURL: HOST_API
});
axiosInstance.interceptors.request.use(AxiosLogger.requestLogger);
axiosInstance.interceptors.response.use(
  AxiosLogger.responseLogger,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const isAuthError = (authError) => {
  return (
    typeof authError === 'object' &&
    'error' in authError &&
    'message' in authError &&
    'statusCode' in authError
  );
};