/**
 * /* @format - for Prettier
 *
 * @format
 */

import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { connect } from "react-redux";
import { Table, Modal, Button } from "react-bootstrap";
import { Customers as CustomersActions, SlipperyRockCustomers } from "../../actions2";
import { ResidentialCustomers } from "../../actions2";
import { GreenBagCustomers } from "../../actions2";
import { NewCastleCustomers2 } from "../../actions2";
import { WestMayfieldCustomers } from "../../actions2";
import { EastButlerCustomers } from "../../actions2";
import { DeniedWrapper } from "../../styled";
import Select from "react-select";
import { CustomerTypes } from "../../utils/customer";

const CustomersWrapper = styled.div`
	width: 90%;
	max-width: 1168px;
	margin: 0 auto;
`;

const CloseButton = styled(Button)`
	float: left;
`;

const ToolButton = styled(Button)`
	padding: 10px 20px;
	height: 40px;
	float: right;
`;

const Header = styled.div`
	display: flex;
`;

const Search = styled.input`
	width: 200px;
	padding: 6px;
	border-radius: 4px;
	border: 1px solid lightgrey;
`;

class Customers extends React.Component {
	state = {
		show: false,
		customer: null,
		searchText: "",
		typeCustomer: "",
		typeCustomers: [
			{
				value: "Commercial Customers",
				label: "Commercial Customers",
			},
			{
				value: "Residential Customers",
				label: "Residential Customers",
			},
			{
				value: "Green Bag Customers",
				label: "Green Bag Customers",
			},
			{
				value: "New Castle 2.0 Customers",
				label: "New Castle 2.0 Customers",
			},
			{
				value: "West Mayfield Customers",
				label: "West Mayfield Customers",
			},
		],
	};

	componentDidMount(next) {
		this.props.getClosedAccounts();
		window.scrollTo(0, 0);
	}

	reactiveAccount = (customer) => {
		this.setState({
			customer: customer,
			show: true,
		});
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	handleSearchInput = (evt) => {
		this.setState({ searchText: evt.target.value });
	};

	typeCustomerChanged = (e) => {
		this.setState({
			typeCustomer: e.value,
		});
	};

	handleReactivate = () => {
		this.setState({ show: false });
		this.props.reopenClosedCustomer(this.state.customer.uid);
	};

	filterResult = (customer, searchText) => {
		const searchTextL = searchText.toLowerCase();
		try {
			if (
				customer["Account Number"] &&
				customer["Account Number"].toLowerCase().includes(searchTextL)
			)
				return true;
			if (customer.name && customer.name.toLowerCase().includes(searchTextL))
				return true;
			if (customer.service_address) {
				let s_address = `${customer.service_address.street} ${customer.service_address.city}, ${customer.service_address.state}, ${customer.service_address.zipcode}`;
				if (s_address.toLowerCase().indexOf(searchTextL) > -1) return true;
			}
			let result = false;
			if (customer.dumpster_addresses) {
				for (let d = 0; d < customer.dumpster_addresses.length; d++) {
					let dumpster_address = customer.dumpster_addresses[d];
					let d_address = `${dumpster_address.street} ${dumpster_address.city}, ${dumpster_address.state}, ${dumpster_address.zipcode}`;
					if (d_address.toLowerCase().indexOf(searchTextL) > -1) {
						result = true;
						break;
					}
				}
			}
			return result;
		} catch (error) {
			return false;
		}
	};

	render() {
		const { searchText } = this.state;
		const filteredCustomers = _.filter(this.props.customers, (customer) =>
			this.filterResult(customer, searchText.toLocaleLowerCase()),
		);
		if (this.props.authorized) {
			return (
				<CustomersWrapper>
					<label>
						Closed Number of Accounts: {this.props.customers.length}
					</label>
					<Header>
						<div style={{ margin: "10px 0px", display: "flex" }}>
							<label
								htmlFor='search-input'
								style={{ marginRight: "5px", paddingTop: "10px" }}>
								Search:{" "}
							</label>
							<Search id='search-input' onChange={this.handleSearchInput} />
						</div>
					</Header>

					<Table responsive hover bordered id='print'>
						<thead>
							<tr>
								<th>Account Number</th>
								<th>Customer Name</th>
								<th>Customer Address</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{_.map(filteredCustomers, (customer) => {
								if (customer.uid !== undefined) {
									return (
										<tr key={customer.uid}>
											<td
												onClick={() =>
													this.props.setCustomer(customer)
												}>
												<div>{customer["Account Number"]}</div>
											</td>
											<td
												onClick={() =>
													this.props.setCustomer(customer)
												}>
												<div>{customer.name}</div>
											</td>
											<td
												className='dumpster-address'
												onClick={() =>
													this.props.setCustomer(customer)
												}>
												<div>
													{customer.dumpster_addresses &&
													customer.dumpster_addresses[0] &&
													customer.dumpster_addresses[0].street
														.length > 0 ? (
														<div>
															<div>
																{
																	customer
																		.dumpster_addresses[0]
																		.street
																}
															</div>
															<div>{`${customer.dumpster_addresses[0].city}, ${customer.dumpster_addresses[0].state}, ${customer.dumpster_addresses[0].zipcode}`}</div>
														</div>
													) : customer.service_address ? (
														<div>
															<div>
																{
																	customer
																		.service_address
																		.street
																}
															</div>
															<div>{`${customer.service_address.city}, ${customer.service_address.state}, ${customer.service_address.zipcode}`}</div>
														</div>
													) : (
														""
													)}
												</div>
											</td>
											<td
												className='d-flex justify-content-center mt-0'
												onClick={() =>
													this.reactiveAccount(customer)
												}>
												<ToolButton
													onClick={() =>
														this.reactiveAccount(customer)
													}
													bsStyle='primary'>
													Reactivate Account
												</ToolButton>
											</td>
										</tr>
									);
								}
							})}
						</tbody>
					</Table>
					<Modal
						bsSize='small'
						show={this.state.show}
						onHide={this.handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>
								<span>Reactivate Account</span>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Select
								options={this.state.typeCustomers}
								value={this.state.select_serviceLevel}
								onChange={this.typeCustomerChanged}
								getOptionLabel={(option) => option.label}
								getOptionValue={(option) => option.value}
							/>
						</Modal.Body>
						<Modal.Footer>
							<CloseButton onClick={this.handleClose}>Close</CloseButton>
							<ToolButton
								disabled={this.state.typeCustomer.length === 0}
								bsStyle='primary'
								onClick={this.handleReactivate}>
								Reactivate
							</ToolButton>
						</Modal.Footer>
					</Modal>
				</CustomersWrapper>
			);
		} else {
			return <DeniedWrapper> Access Denied </DeniedWrapper>;
		}
	}
}

const mapStateToProps = (state) => ({
	customers: state.closedAccounts,
	authorized: state.user?.pages?.customers,
});

const mapDispatchToProps = (dispatch) => ({
	setCustomer: (customer) => {
		customer["isClosed"] = true;
		if (customer.type === CustomerTypes.residential) {
			dispatch(ResidentialCustomers.setClosedAccountCustomer(customer));
		}
		if (customer.type === CustomerTypes.commercial) {
			dispatch(CustomersActions.setClosedAccountCustomer(customer));
		}
		if (customer.type === CustomerTypes.west_mayfield) {
			dispatch(WestMayfieldCustomers.setClosedAccountCustomer(customer));
		}
		if (customer.type === CustomerTypes.slippery_rock) {
			dispatch(SlipperyRockCustomers.setClosedAccountCustomer(customer));
		}
		if (customer.type === CustomerTypes.east_butler) {
			dispatch(EastButlerCustomers.setClosedAccountCustomer(customer));
		}
		if (customer.type === CustomerTypes.new_castle_2) {
			dispatch(NewCastleCustomers2.setClosedAccountCustomer(customer));
		}
	},
	// removeClosedCustomer: (customerId) =>
	// 	dispatch(CustomersActions.removeClosedCustomer(customerId)),
	getCustomers: () => dispatch(CustomersActions.getCustomers()),
	getClosedAccounts: () => dispatch(CustomersActions.getClosedAccounts()),
	reopenClosedCustomer: (customerId) =>
		dispatch(CustomersActions.reopenClosedCustomer(customerId)),
	// addResidentialClosedCustomer: (customer) =>
	// 	dispatch(ResidentialCustomers.reopenClosedCustomer(customer)),
	// addGreenBagClosedCustomer: (customer) =>
	// 	dispatch(GreenBagCustomers.reopenClosedCustomer(customer)),
	// addNewCastle2ClosedCustomer: (customer) =>
	// 	dispatch(NewCastleCustomers2.reopenClosedCustomer(customer)),
	// addWestMayfieldClosedCustomer: (customer) =>
	// 	dispatch(WestMayfieldCustomers.reopenClosedCustomer(customer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
