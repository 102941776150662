/* @format - for Prettier */
import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Field } from 'redux-form'
import { Table, Button, Grid, Row, Col } from 'react-bootstrap'
import {
  renderState,
  renderSelect,
  renderSelectCustomer,
  required
} from '../../../renderComponents'
import { ErrorText } from '../../../styled'
import { checkExisting } from '../../../utils/customer'

const AddButton = styled(Button)`
  width: 100px;
  margin: 1rem 0;
`

const StyledInput = styled.input`
  width: 100%;
`
const SmallTH = styled.th`
  width: 150px;
`
const renderField = ({
  className,
  input,
  label,
  disabled,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledInput
        {...input}
        type={type}
        placeholder=""
        disabled={disabled}
        className={className}
      />
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
)

const StopForm = ({
  fields,
  meta: { error, submitFailed },
  customers,
  dumpsterAddresses,
  adding,
  stops,
  changeFullAddress,
  editingUID,
  changeEditingItem
}) => (
  <div>    
    {/* {
      adding&&
            <AddButton onClick={() => fields.splice(0, 0, {})} bsStyle="primary">
        + Add Stop
      </AddButton>
    } */}
    <Table responsive condensed hover bordered>
      <thead>
        <tr>
          {adding && <th>#</th>}
          <th>Name</th>
          <th>Customer</th>
          <th>Address</th>
          <SmallTH>Coordinates</SmallTH>
          <SmallTH>Status</SmallTH>
        </tr>
      </thead>
      <tbody>
        {fields.map((stop, index) => {
          let addresses = []
          let UID = ''
          UID = stops[index] && stops[index].uid
          if (UID === '' || UID === undefined) UID = 'new'
          if (UID === editingUID) {
            const customer = customers.find((customer) => (customer.uid === stops[index]?.customer?.id));
            if(customer) {
              if (customer.billing_address !== undefined) {
                if (checkExisting(addresses, customer.billing_address) === -1)
                  addresses.push(customer.billing_address)
              }
              if (customer.service_address !== undefined) {
                if (checkExisting(addresses, customer.service_address) === -1)
                  addresses.push(customer.service_address)
              }
              const customerDumpsterAddresses = dumpsterAddresses.filter(
                (dumpster) => dumpster.customer_id === customer.uid
              )
              _.forEach(customerDumpsterAddresses, (address) => {
                if (checkExisting(addresses, address) === -1)
                  addresses.push(address)
              })
            }
          }
          return (
            <tr key={index}>
              {adding && <td> {index + 1} </td>}
              <td>
                <Field
                  name={`${stop}.name`}
                  type="text"
                  component={renderField}
                  validate={[required]}
                />
              </td>
              <td>
                {UID === editingUID ? (
                  <Field
                    name={`${stop}.customer`}
                    component={renderSelectCustomer}
                    validate={[required]}
                    parse={(value) => {
                      let data = {}
                      _.forEach(customers, (customer) => {
                        // eslint-disable-next-line
                        if (value == customer.uid) {
                          data.customer_name = customer.name
                          data.id = customer.uid
                        }
                      })
                      if (data.id === undefined) return ''
                      else return data
                    }}
                  >
                    <option />
                    {_.map(customers, (customer, i) => {
                      return (
                        <option value={customer.uid} key={i}>
                          {customer.name}
                        </option>
                      )
                    })}
                  </Field>
                ) : (
                  <div>
                    <input
                      type="text"
                      value={
                        stops[index] &&
                        stops[index].customer &&
                        stops[index].customer.name
                      }
                      disabled={true}
                      style={{ width: '100%' }}
                    />
                    <button
                      onClick={() => changeEditingItem(UID)}
                      style={{ marginTop: '10px' }}
                    >
                      Edit
                    </button>
                  </div>
                )}
              </td>
              <td>
                {UID === editingUID && (
                  <Field
                    name={`${stop}.address.full`}
                    component={renderSelect}
                    label="Select Address:"
                    onChange={(e) => changeFullAddress(index, e.target.value)}
                  >
                    <option />
                    {_.map(addresses, (address, aindex) => {
                      return (
                        <option
                          value={`${address.street}--${address.city}--${address.state}--${address.zipcode}`}
                          key={aindex}
                          name={aindex}
                        >
                          {`${address.street} ${address.city}, ${address.state} ${address.zipcode}`}
                        </option>
                      )
                    })}
                  </Field>
                )}
                <Field
                  name={`${stop}.address.street`}
                  type="text"
                  component={renderField}
                  label="Street:"
                  validate={[required]}
                />
                <Field
                  name={`${stop}.address.city`}
                  type="text"
                  component={renderField}
                  label="City:"
                  validate={[required]}
                />
                <Grid className="FormCol">
                  <Row>
                    <Col md={5} className="FormCol">
                      <Field
                        name={`${stop}.address.state`}
                        type="text"
                        component={renderState}
                        label="State:"
                        validate={[required]}
                        className="State"
                      />
                    </Col>
                    <Col md={7} className="FormCol">
                      <Field
                        name={`${stop}.address.zipcode`}
                        type="text"
                        component={renderField}
                        label="Zipcode:"
                        validate={[required]}
                        className="Zipcode"
                      />
                    </Col>
                  </Row>
                </Grid>
              </td>
              <td>
                <Field
                  name={`${stop}.coordinates.Latitude`}
                  type="number"
                  component={renderField}
                  label="Latitude:"
                  validate={[required]}
                />
                <Field
                  name={`${stop}.coordinates.Longitude`}
                  type="number"
                  component={renderField}
                  label="Longitude:"
                  validate={[required]}
                />
              </td>
              <td>
                <Field
                  name={`${stop}.status`}
                  component={renderSelect}
                  validate={[required]}
                >
                  <option />
                  <option value="completed">Completed</option>
                  <option value="suspended">Suspended</option>
                  <option value="pending">Pending</option>
                  <option value="not out">Not Out</option>
                </Field>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  </div>
)

export default StopForm
