import _ from "lodash";

const initialState = {
  customers: [],
  customer: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_NEWCASTLE_2_CUSTOMERS":
      return _.assign({}, state, { customers: action.customers });
    case "SET_CURRENT_NEWCASTLE_2_CUSTOMER":
      return _.assign({}, state, { customer: action.customer });
    default:
      return state;
  }
};
