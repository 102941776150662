/* @format - for Prettier */
import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {
  Modal,
  ControlLabel,
  Button,
  FormGroup,
  FormControl,
} from 'react-bootstrap';
import {Users} from '../../../actions2';
import {firebase} from '../../../firebase';

const StyledForm = styled.form`
  margin: 0vh 10vw 1vh 10vw;
`;

const StyledButton = styled(Button)`
  margin: 10px 15% 10px 15%;
  width: 70%;
  height: 40px;
`;

const TitleWrapper = styled.span`
  vertical-align: sub;
`;

const Error = styled.div`
  text-align: center;
  padding-bottom: 20px;
  color: red;
  font-weight: bold;
`;

class CreateUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      error: '',
    };
  }
  save = e => {
    e.preventDefault();
    const user = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      password: this.state.password,
      pages: {
        admin: false,
        customers: true,
        history: false,
        invoices: false,
        routes: true,
      },
    };
    console.log('user', user);
    this.props.saveUser(user);
    this.props.handleClose();
    this.setState({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      error: '',
    });
  };
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <TitleWrapper>Create New User</TitleWrapper>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Error> {this.state.error} </Error>
          <StyledForm onSubmit={this.save}>
            <FormGroup>
              <ControlLabel>First Name</ControlLabel>
              <FormControl
                type="text"
                label="Text"
                onChange={e => this.setState({first_name: e.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Last Name</ControlLabel>
              <FormControl
                type="text"
                label="Text"
                onChange={e => this.setState({last_name: e.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Email Address</ControlLabel>
              <FormControl
                type="email"
                label="Email address"
                onChange={e => this.setState({email: e.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Password</ControlLabel>
              <FormControl
                type="password"
                label="Password"
                onChange={e => this.setState({password: e.target.value})}
              />
            </FormGroup>
            <div>
              <StyledButton type="submit" bsStyle="primary">
                Create User
              </StyledButton>
            </div>
          </StyledForm>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  saveUser: user => dispatch(Users.saveUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateUserModal);
