/* @format - for Prettier */
import React from "react";
import _ from "lodash";
import styled from "styled-components";
import Select from "react-select";
import { connect } from "react-redux";
import ReactExport from "react-data-export";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Table,
  Button,
  Glyphicon,
  Modal,
  Row,
  Checkbox,
  Pagination,
  ControlLabel,
  Col, 
  Label
} from "react-bootstrap";
import { Customers as CustomersActions } from "../../actions2";
import { NewCastleCustomers as NewCastleCustomersActions } from "../../actions2";
import { firebase } from '../../firebase';
import toastr from 'toastr';

import { DeniedWrapper } from "../../styled";
import moment from "moment";
import InputMoment from "input-moment";
// import Select from "react-select";
import printHtmlElement from "print-html-element";
import PrintOpenInvoices from "./PrintOpenInvoices";
import PrintSelectedInvoices from "./PrintSelectedInvoices";
import MarkPaidDropDown from "./MarkPaidDropDown";
import PrintSingleInvoice from "./PrintSingleInvoice";
import { pdfService } from "../../services/pdf";
import axiosInstance from "../../api/axios";
import { CustomerTypes } from "../../utils/customer";

const CustomersWrapper = styled.div`
  width: 80vw;
  margin: 0 auto;
`;

const GlyphButton = styled(Button)`
  width: 100%;
  color: red !important;
  border: none;
`;

const GlyphTd = styled.div`
  width: 50px;
`;

const DeleteButton = styled(Button)`
  float: right;
`;

const CloseButton = styled(Button)`
  float: left;
`;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  margin: 10px 0px 10px 10px;
`;

const Toolbar = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
`;

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 20px !important;
`;

const AccordionContainer = styled.div`
  margin: 20px 0px !important;
`;

const DatePickerContainer = styled.span`
  margin-left: 20px;
`;

const StartEndDatePickerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDatePicker = styled(InputMoment)`
  width: 100% !important;
`;

const OpenChargeToggle = styled(Checkbox)`
  display: inline-block;
  margin-left: 15px;
`;

const StatusCol = styled.td`
  width: 150px;
  text-align: center;
  vertical-align: middle;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    padding: 5px;
  }
`;

const ExportWrapper = styled.div`
  span {
    width: 100%;
  }
`;

const Search = styled.input`
  width: 200px;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid lightgrey;
`;

const FilterOptionsWrapper = styled.div``;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const PAGE_SIZE = 25;

class NewCastleInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      startDate: new Date(),
      endDate: new Date(),
      start: moment().startOf("quarter"),
      end: moment().endOf("quarter"),
      which: "",
      customer: null,
      goodStandingCustomerList:null,
      onlyOpenCharges: true,
      customersNC: [],
      page: 0,
      searchText: "",
      customerCount: 0,
      showBillAll: false,
      showExportContactInfo: false,
      showExportQuarter: false,
      editable_current_billing_quarter: false,
      printCoverLettersDisabled: false,
      billingAll: false,
      modal_quarter: '',
      modal_year: '',
      quarter: '',
      select_quarter: { value: "1st Quarter", label: "1st Quarter" },
      select_year: { value: "2020", label: "2020" },
      modal_Exportquarter: '',
      modal_Exportyear: '',
      select_Exportquarter: '',
      select_Exportyear: '',
      yearOptions: [
        {
          value: "2020",
          label: "2020"
        },
        {
          value: "2021",
          label: "2021"
        },
        {
          value: "2022",
          label: "2022"
        },
        {
          value: "2023",
          label: "2023"
        }
      ],
      quarterOptions: [
        {
          value: "1st Quarter",
          label: "1st Quarter"
        },
        {
          value: "2nd Quarter",
          label: "2nd Quarter"
        },
        {
          value: "3rd Quarter",
          label: "3rd Quarter"
        },
        {
          value: "4th Quarter",
          label: "4th Quarter"
        },
      ]
    };
    this.handleStartDateChange = this.handleStartDateChange.bind(this)
    this.handleEndDateChange = this.handleEndDateChange.bind(this)
    this.handleBillFilter = this.handleBillFilter.bind(this)
    this.billAllHandle = this.billAllHandle.bind(this)
    this.quarterChanged = this.quarterChanged.bind(this)
    this.yearChanged = this.yearChanged.bind(this)
    this.quarterExportChanged = this.quarterExportChanged.bind(this)
    this.yearExportChanged = this.yearExportChanged.bind(this)
    this.handleBillClose = this.handleBillClose.bind(this)
    this.handleExportQuarterClose = this.handleExportQuarterClose.bind(this)
    this.checkQuarter = this.checkQuarter.bind(this)
    this.checkPaidHistory = this.checkPaidHistory.bind(this)
    this.ExportQuarter = this.ExportQuarter.bind(this)
    this.chargedQuarterCustomers = this.chargedQuarterCustomers.bind(this)
  }

  handleBillFilter = () => {
    const modal_quarter = this.state.modal_quarter
    const modal_year = this.state.modal_year
    this.setState({
      showBillAll: false,
      quarter: modal_quarter,
      year: modal_year
    })
    this.billAllNewCastleCustomers(modal_quarter, modal_year)
    this.props.saveCurrentBillingPeriod(this.state.select_quarter.value, this.state.select_year.value)
  }

  billAllNewCastleCustomers = async (quarter, year) => {
    try {
      this.setState({ billingAll: true });
      const res = await axiosInstance.post('/api/customers/bill/'+CustomerTypes.new_castle, {quarter, year});
      if (res.status === 200) {
        toastr.success('Successfully charged customers. You may need to refresh the page.', 'Success');
        this.setState({ billingAll: false });
      } else {
        toastr.error('Unexpected Error Happened!. Please try again', 'Error');
        this.setState({ billingAll: false });
      }
    } catch (error) {
      console.log(error);
      toastr.error('Unexpected Error Happened!. Please try again', 'Error');
      this.setState({ billingAll: false });
    }
  }

  billAllHandle = () => {
    this.setState({
      showBillAll: true
    })
  }

  ExportQuarter = () => {
    this.setState({
      showExportQuarter: true
    })
  }

  handleBillClose = () => {
    this.setState({
      showBillAll: false,
    })
  }

  handleExportQuarterClose = () => {
    this.setState({
      showExportQuarter: false,
    })
  }

  quarterChanged = e => {
    this.setState({
      modal_quarter: e.value,
      select_quarter: e
    });
  }

  yearChanged = e => {
    this.setState({
      modal_year: e.value,
      select_year: e
    });
  }

  quarterExportChanged = e => {
    this.setState({
      modal_Exportquarter: e.value,
      select_Exportquarter: e
    });
  } 

  yearExportChanged = e => {
    this.setState({
      modal_Exportyear: e.value,
      select_Exportyear: e
    });
  } 

  currentBillingQuarterChanged = e => {
    this.props.changeCurrentBillingQuarter(e)
  }

  currentBillingYearChanged = e => {
    this.props.changeCurrentBillingYear(e)
  }

   getLastTwelveQuarters=(e)=> {
    const currentQuarter = Math.floor((new Date().getMonth()) / 3) + 1;
    const quarterSuffix=['st','nd','rd','th']
    const currentYear = new Date().getFullYear();
    const quarters = [];
    let year = currentYear;
    let quarter = currentQuarter;
    for (let i = 0; i < 12; i++) {
      quarters.push({
        quarter:`${quarter}${quarterSuffix[quarter-1]} Quarter`,
        year:`${year}`
      })
      quarter--;
      if (quarter === 0) {
        quarter = 4;
        year--;
      }
    }
    return quarters.reverse();
  }
  getGoodStandingCustomerList = e =>{
    let lastTwelveQuarters=this.getLastTwelveQuarters();
    let lastTwelveQuartersStatic=[

      {
          quarter: "1st Quarter",
          year: "2023"
      },
      {
          quarter: "4th Quarter",
          year: "2022"
      },
      {
          quarter: "3rd Quarter",
          year: "2022"
      },
      {
          quarter: "2nd Quarter",
          year: "2022"
      },
      {
          quarter: "1st Quarter",
          year: "2022"
      },
      {
          quarter: "4th Quarter",
          year: "2021"
      },
      {
          quarter: "3rd Quarter",
          year: "2021"
      },
      {
          quarter: "2nd Quarter",
          year: "2021"
      },
      {
          quarter: "1st Quarter",
          year: "2021"
      },
      {
        quarter: "3rd Quarter",
        year: "2020"
      },
      {
          quarter: "2nd Quarter",
          year: "2020"
      },
      {
          quarter: "1st Quarter",
          year: "2020"
      }
  ]
 
    const filteredData = this.props.customers?.filter(item => {
      return lastTwelveQuartersStatic.every(val => {
        if(item && item.history && Array.isArray(item.history) && item.history.length>0){
          return item.history.some(child => child.quarter === val.quarter && child.year === val.year);
        }
      });
    });
    console.log(filteredData,"filtered data");
    return filteredData
  }

  componentDidMount() {
    this.props.getCustomers();
    this.state.goodStandingCustomerList=this.getGoodStandingCustomerList();
    this.setState({
      searchText: localStorage.getItem('remember_newCastle_search_key'),
      select_quarter: this.props.current_billing_quarter,
      select_year: this.props.current_billing_year,
      modal_quarter:this.props.current_billing_quarter.value,
      modal_year: this.props.current_billing_year.value,
      select_Exportquarter: this.props.current_billing_quarter,
      select_Exportyear: this.props.current_billing_year,
      modal_Exportquarter: this.props.current_billing_quarter.value,
      modal_Exportyear: this.props.current_billing_year.value,
    })
    document.getElementById("search-input").value = localStorage.getItem('remember_newCastle_search_key');
  }

  handlePrint = () => {
    var element = document.getElementById("print");
    printHtmlElement.printElement(element, { pageTitle: "Invoices" });
  };

  handleSelectDate = which => {
    this.setState({
      which,
      show: true
    });
  };

  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  };

  handleShowDelete = customer => {
    this.setState({ showDelete: true, customer });
  };

  handleCloseDelete = () => {
    this.setState({ showDelete: false });
  };

  handleRemove = () => {
    this.props.deleteCustomer(this.state.customer.uid);
    this.setState({ showDelete: false });
  };

  handleChange = moment => {
    if (this.state.which === "start") {
      this.setState({ start: moment.startOf("day") });
    } else {
      this.setState({ end: moment.startOf("day") });
    }
  };

  handleOpenCharge = () => {
    this.setState({ onlyOpenCharges: !this.state.onlyOpenCharges });
  };

  handleSelectedCustomer = e => {
    this.setState({ customer: e.value });
  };

  handleSave = () => {
    this.setState({ show: false });
  };

  toggleBillPay = uid => {
    this.props.billToggle(uid, "Paid");
  };

  customerDropdownOptions = () => {
    return _.map(this.props.customers, customer => ({
      value: customer,
      label: customer["Company Name"]
    }));
  };

  paidCustomers = () => {
    return _.filter(this.props.customers, customer => {
      return customer.Status === "Paid";
    });
  };

  paidQuarterCustomers = () => {
    return _.filter(this.props.customers, customer => {
      return this.checkExportQuarter(customer) && (customer["isExempt"] === undefined | null || customer["isExempt"] === "No") && (customer["Resident Name"]?.toLowerCase().includes(this.state.searchText) || customer["Account Number"]?.toString().toLowerCase().includes(this.state.searchText) || customer["Physical Address"]?.toString().toLowerCase().includes(this.state.searchText) || customer["Address"]?.toString().toLowerCase().includes(this.state.searchText));
    });
  };

  checkQuarter = customer => {
    if (this.state.quarter === "") return false;
    if (customer.history === undefined) return false;
    if(!Array.isArray(customer.history)) return false;
    for (let item of customer.history) {
      if (item['quarter'] === this.state.quarter) return true
    }
    return false
  }

  checkExportQuarter = customer => {
    if (customer.history === undefined) return false;
    for (var item in customer.history) {
      if (customer.history[item]['quarter'] === this.state.modal_Exportquarter && customer.history[item]['year'] === this.state.modal_Exportyear) return true
    }
    return false
  }

  chargedCustomers = () => {
    return _.filter(this.props.customers, customer => {
      return !this.checkQuarter(customer) && customer.Status === "Charged" && (customer["isExempt"] === undefined | null || customer["isExempt"] === "No")&& (customer["isRecurringCustomer"] === undefined | null || customer["isRecurringCustomer"] === "No") && (customer["Resident Name"]?.toLowerCase().includes(this.state.searchText) || customer["Account Number"]?.toString().toLowerCase().includes(this.state.searchText) || customer["Physical Address"]?.toString().toLowerCase().includes(this.state.searchText) || customer["Address"]?.toString().toLowerCase().includes(this.state.searchText));
    });
  };

  chargedQuarterCustomers = () => {
    return _.filter(this.props.customers, customer => {
      return !this.checkExportQuarter(customer) && (customer["isExempt"] === undefined | null || customer["isExempt"] === "No") && (customer["Resident Name"]?.toLowerCase().includes(this.state.searchText) || customer["Account Number"]?.toString().toLowerCase().includes(this.state.searchText) || customer["Physical Address"]?.toString().toLowerCase().includes(this.state.searchText) || customer["Address"]?.toString().toLowerCase().includes(this.state.searchText));
    });
  };

  checkPaidHistory = customer => {
    if (customer.history === undefined) return false;
    if(!Array.isArray(customer.history)) return false;
    for (var item of customer.history) {
      const start = moment(this.state.startDate).format("YYYY-MM-DD")
      const current_date = new Date(item['time'])
      const current = moment(current_date).format("YYYY-MM-DD")
      const payment_source = item['paymentSource']
      if ( start === current && payment_source === "API") return item;
      if ( start === current && payment_source === "Recurring") return item;
    }
    return false
  }

  getRangePaidCustomers = () => {
    let result = []
    for (let i = 0 ; i < this.props.customers.length ; i += 1) {
      let customer = this.props.customers[i];
      const status = this.checkPaidHistory(customer)
      if (status !== false) {
        customer['paid'] = status
        result.push(customer) 
      }
    }
    return result;
  }

  getRangeExemptCustomers = () => {
    return _.filter(this.props.customers, customer => {
      return (customer["isExempt"]?.toLowerCase().includes("yes"));
    });
  }

  getRangeRecurringCustomers = () => {
    return _.filter(this.props.customers, customer => {

      return (customer["isRecurringCustomer"]?.toLowerCase().includes("yes"));
    });
  }

  allCustomers = () => {
    return _.filter(this.props.customers, customer => {
        
      return (customer["Resident Name"]?.toLowerCase().includes(this.state.searchText) || customer["Account Number"]?.toString().toLowerCase().includes(this.state.searchText) || customer["Physical Address"]?.toString().toLowerCase().includes(this.state.searchText) || customer["Address"]?.toString().toLowerCase().includes(this.state.searchText));
    });
  };

  handleSearchInput = evt => {
    this.setState({ searchText: evt.target.value.toLowerCase() });
    localStorage.setItem('remember_newCastle_search_key', evt.target.value.toLowerCase())
  };
 

  changeEditableCurrentBillingQuarter = e => {
    e.preventDefault();
    this.setState({
      editable_current_billing_quarter: true
    })
  }

  changeEditableCurrentBillingQuarter_Cancel = e => {
    e.preventDefault();
    this.setState({
      editable_current_billing_quarter: false
    })
    this.props.getCurrentBillingPeriod()
  }

  changeEditableCurrentBillingQuarter_Save = e => {
    e.preventDefault();
    this.setState({
      editable_current_billing_quarter: false
    })
    this.props.saveCurrentBillingPeriod(this.props.current_billing_quarter.value, this.props.current_billing_year.value)
  }

  printAllCoverLetters = async () => {
    toastr.info("Printing all cover letters may take some time...", "Downloading...");
    this.setState({
      printCoverLettersDisabled: true
    })
    await pdfService.newcastle_customer_coverletter_pdf_download(
      this.props.customers,
      moment().format('MM/DD/YYYY'),
    );
    toastr.success("All cover letters printed!", "Success!")
    this.setState({
      printCoverLettersDisabled: false
    })
  };

  render() {
    
    const filteredCustomers = this.state.onlyOpenCharges
      ? this.chargedCustomers()
      : this.allCustomers();
    
    const visibleCustomers = filteredCustomers.slice(
      this.state.page * PAGE_SIZE,
      this.state.page * PAGE_SIZE + PAGE_SIZE
    );

    const rangePaidCustomers = this.getRangePaidCustomers();
    const rangeExemptCustomers = this.getRangeExemptCustomers();
    const rangeRecuringCustomers = this. getRangeRecurringCustomers();

    const shouldPaginate = filteredCustomers.length > 0;
    const currentPage = this.state.page;
    const numPages = Math.ceil(filteredCustomers.length / PAGE_SIZE);
    const previousPage = Math.max(0, this.state.page - 1);
    const nextPage = Math.min(numPages - 1, this.state.page + 1);
    const isFirst = this.state.page === 0;
    const isLast = this.state.page === numPages - 1;

    if (this.props.authorized) {
      return (
        <CustomersWrapper>
          <label>Number of Customers: {this.props.customers.length}</label>
          <div id="topbar">
            <Toolbar>
              <div style={{display:"flex",flexWrap:"wrap"}}>
              <ExportWrapper>
                <ExcelFile
                  filename="NEW CASTLE Customer Contact Info"
                  element={<ToolButton bsStyle="primary">Export Contact Info</ToolButton>}
                >
                  <ExcelSheet data={this.props.customers} name="New Castle Customers Contact Info">
                    <ExcelColumn label="Account Number" value="Account Number" />
                    <ExcelColumn label="Name" value="Resident Name" />
                    <ExcelColumn label="Email" value="Email" />
                    <ExcelColumn label="Phone" value="Phone" />
                  </ExcelSheet>
                </ExcelFile>
              </ExportWrapper>
              <ExportWrapper>
                <ExcelFile
                  filename="NEW CASTLE Good-Standing Customers Contact Info"
                  element={<ToolButton bsStyle="primary">Export Good-Standing Customers List</ToolButton>}
                >
                  <ExcelSheet data={this.state.goodStandingCustomerList} name="NEW CASTLE Good-Standing Customers Contact Info">
                    <ExcelColumn label="Account Number" value="Account Number" />
                    <ExcelColumn label="Name" value="Resident Name" />
                    <ExcelColumn label="Email" value="Email" />
                    <ExcelColumn label="Phone" value="Phone" />
                    <ExcelColumn label="Billing Address"  value={col =>
                        `${col["Address"]} ${col["City"]}, ${col["State"]}  ${
                        col["Location ZIP Code"]
                        } ${col["ZIP+4"] ? `-${col["ZIP+4"]}` : ""}`
                      } />
                    <ExcelColumn label="Service Address" 
                     value={col =>
                      `${col["Physical Address"]} ${col["Physical City"]}, ${col["Physical State"]}  ${
                      col["Physical ZIP"]
                      } ${col["Physical ZIP+4"] ? `-${col["Physical ZIP+4"]}` : ""}`
                    }
                    
                    />
                  </ExcelSheet>
                </ExcelFile>
              </ExportWrapper>
              <ToolButton bsStyle="primary" onClick={this.ExportQuarter}>Export Payments</ToolButton>
              <ToolButton
                        onClick={this.printAllCoverLetters}
                        bsStyle="primary"
                        disabled={this.state.printCoverLettersDisabled}
                      >
                        Print Cover Letters
                      </ToolButton>
              <PrintOpenInvoices invoices={this.chargedCustomers()} />

              <ToolButton
                onClick={this.handlePrint}
                bsStyle="primary"
                id="printBtn"
              >
                <AddGlyph glyph="print" />
                Print
              </ToolButton>
              <ToolButton
                onClick={this.billAllHandle}
                bsStyle="primary"
                id="printBtn"
              >
                <AddGlyph glyph="send" />
                Bill All For Quarter
              </ToolButton>
              <ToolButton onClick={this.props.addCustomer} bsStyle="primary">
                <AddGlyph glyph="plus" />
                Add Customer
              </ToolButton>
              {/* <ToolButton onClick={this.props.yearUpdate} bsStyle="primary">
                2020 Year
              </ToolButton> */}
              {/* <ToolButton onClick={this.props.quarterUpdate} bsStyle="primary">
                1st quarter
              </ToolButton> */}
              </div>
            </Toolbar>
            <div style={{ margin: '10px 0px', display: 'flex' }}>
                <label htmlFor="search-input" style={{ marginRight: '5px', paddingTop: '10px' }}>Search: </label>
                <Search id="search-input" onBlur={this.handleSearchInput} />
                <Button style={{marginLeft: 10}} bsStyle="primary">
                  <AddGlyph glyph="search" />
                  Search
                </Button>
              </div>
            <div className="d-flex align-items-center mt-2">
              <label className="mb-0">
                Current Billing Year & Quarter:
              </label>
              {
                this.state.editable_current_billing_quarter ?
                <div className="ml-2 d-flex align-items-center">
                  <div className="current_billing_quarter_select">
                    <Select
                      options={this.state.yearOptions}
                      value={this.props.current_billing_year}
                      onChange={this.currentBillingYearChanged}
                      getOptionLabel={option => option.label}
                      getOptionValue={option => option.value}
                    />
                  </div>
                  <div className="current_billing_quarter_select ml-2">
                    <Select
                      options={this.state.quarterOptions}
                      value={this.props.current_billing_quarter}
                      onChange={this.currentBillingQuarterChanged}
                      getOptionLabel={option => option.label}
                      getOptionValue={option => option.value}
                    />
                  </div>
                  <a href='/' className="ml-2" onClick={this.changeEditableCurrentBillingQuarter_Save}>Save</a>
                  <a href='/' className="ml-2" onClick={this.changeEditableCurrentBillingQuarter_Cancel}>Cancel</a>
                </div>
                :
                <div className="ml-2 d-flex align-items-center">
                  <p>{this.props.current_billing_year.value} / {this.props.current_billing_quarter.value}</p>
                  <a href='/' className="ml-2" onClick={this.changeEditableCurrentBillingQuarter}>Edit</a>
                </div>
              }
            </div>
            <AccordionContainer>
              <Accordion
                allowZeroExpanded="true"
              >
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <label>Paid Customers (API & Recurring)</label>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <StartEndDatePickerContainer>
                      <div>
                        <ControlLabel>Select Date</ControlLabel>
                        <DatePickerContainer>
                          <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleStartDateChange}
                          />
                        </DatePickerContainer>
                      </div>
                      <PrintSelectedInvoices invoices={this.getRangePaidCustomers()} downloadReceipts newCastle />
                      {/* <EndDatePickerContainer>
                        <FormGroup>
                          <ControlLabel>End Date</ControlLabel>
                          <DatePickerContainer>
                            <DatePicker
                              selected={this.state.endDate}
                              onChange={this.handleEndDateChange}
                            />
                          </DatePickerContainer>
                        </FormGroup>
                      </EndDatePickerContainer> */}
                    </StartEndDatePickerContainer>
                    <Table id="print" hover bordered>
                      <thead>
                        <tr>
                          <th>Date Paid</th>
                          <th>Amount</th>
                          <th>Account Number</th>
                          <th>Transaction ID</th>
                          <th>Customer</th>
                          <th>Service Address</th>
                          <th>Status & Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(rangePaidCustomers, customer => {
                          return (
                            <tr key={customer.uid}>
                              <td onClick={() => this.props.setCustomer(customer.uid)}>{customer.history[customer.paid]['time']}</td>
                              <td onClick={() => this.props.setCustomer(customer.uid)}>{customer.history[customer.paid]['amount']}</td>
                              <td onClick={() => this.props.setCustomer(customer.uid)}>{customer['Account Number']}</td>
                              <td onClick={() => this.props.setCustomer(customer.uid)}>{customer.history[customer.paid]['transaction_id']}</td>
                              <td onClick={() => this.props.setCustomer(customer.uid)}>
                                <div>{customer["Resident Name"]}</div>
                                <small>{customer.Address}</small>
                              </td>
                              <td onClick={() => this.props.setCustomer(customer.uid)}>
                                {customer['Physical Address']} {customer['Physical City'] + ", "} {customer['Physical State']} {customer['Physical ZIP']}
                              </td>
                              <StatusCol>
                                <div>
                                  <span>{customer.Status}</span>
                                  {customer.Status !== "Paid" && (
                                    <>
                                      <PrintSingleInvoice customer={customer}></PrintSingleInvoice>
                                    </>
                                  )}
                                </div>
                              </StatusCol>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </AccordionContainer>
            {/* New work here */}
            <AccordionContainer>
              <Accordion
                allowZeroExpanded="true"
              >
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <label>Exempt Customers</label>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Table id="print" hover bordered>
                      <thead>
                        <tr>
                          <th>Account Number</th>
                          <th>Customer</th>
                          <th>Service Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(rangeExemptCustomers, customer => {
                          return (
                            <tr key={customer.uid} onClick={() => this.props.setCustomer(customer.uid)}>
                              <td>{customer['Account Number']}</td>
                              <td>
                                <div>{customer["Resident Name"]}</div>
                                <small>{customer.Address}</small>
                              </td>
                              <td>
                                {customer['Physical Address']} {customer['Physical City'] + ", "} {customer['Physical State']} {customer['Physical ZIP']}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </AccordionContainer>

            {/*recurring */}
            <AccordionContainer>
              <Accordion
                allowZeroExpanded="true"
              >
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <label>Recurring Customers</label>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Table id="print" hover bordered>
                      <thead>
                        <tr>
                          <th>Account Number</th>
                          <th>Customer</th>
                          <th>Service Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(rangeRecuringCustomers, customer => {
                          return (
                            <tr key={customer.uid} onClick={() => this.props.setCustomer(customer.uid)}>
                              <td>{customer['Account Number']}</td>
                              <td>
                                <div>{customer["Resident Name"]}</div>
                                <small>{customer.Address}</small>
                              </td>
                              <td>
                                {customer['Physical Address']} {customer['Physical City'] + ", "} {customer['Physical State']} {customer['Physical ZIP']}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </AccordionContainer>


            <FilterOptionsWrapper>
              {/* <Base>From</Base>
              <Date onClick={() => this.handleSelectDate("start")}>
                {this.state.start.format("L")}
              </Date>
              <Base>To</Base>
              <Date onClick={() => this.handleSelectDate("end")}>
                {this.state.end.format("L")}
              </Date>
              <Base>Customer</Base>
              <div
                style={{
                  display: "inline-block",
                  width: "250px",
                  margin: "10px 0px"
                }}
              >
                <Select
                  options={this.customerDropdownOptions()}
                  onChange={this.handleSelectedCustomer}
                />
              </div> */}
              <OpenChargeToggle
                checked={this.state.onlyOpenCharges}
                onChange={this.handleOpenCharge}
              >
                Show Only Open Charges
              </OpenChargeToggle>
            </FilterOptionsWrapper>
          </div>
          <span>
            {this.state.onlyOpenCharges ? <h5>The results below only show customers with open charges. Those exempt from regular quarterly payments are not shown.
            You may view these customers in the Exempt Customers list, or by searching for them.
          </h5> : ""}
          </span>
          <Table id="print" hover bordered>
            <thead>
              <tr>
                <th>Account Number</th>
                <th>Customer</th>
                <th>Service Address</th>
                <th>Status & Actions</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {_.map(visibleCustomers, customer => {
                return (
                  <tr key={customer.uid}>
                    <td onClick={() => this.props.setCustomer(customer.uid)}>{customer['Account Number']}</td>
                    <td onClick={() => this.props.setCustomer(customer.uid)}>
                      <div>{customer["Resident Name"]}</div>
                      <small>{customer.Address}</small>
                    </td>
                    <td onClick={() => this.props.setCustomer(customer.uid)}>
                      {customer['Physical Address']} {customer['Physical City'] + ", "} {customer['Physical State']} {customer['Physical ZIP']}
                    </td>
                    <StatusCol>
                      <div>
                        <span>{customer.Status}</span>
                        {customer.Status !== "Paid" && (
                          <>
                            <MarkPaidDropDown customer={customer}></MarkPaidDropDown>
                            <PrintSingleInvoice customer={customer}></PrintSingleInvoice>
                          </>
                        )}
                      </div>
                    </StatusCol>
                    <StatusCol>
                      <GlyphTd className="hidden-print">
                        <GlyphButton onClick={() => this.handleShowDelete(customer)}>
                          <Glyphicon glyph="remove" />
                        </GlyphButton>
                      </GlyphTd>
                    </StatusCol>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {shouldPaginate && (
            <StyledRow>
              <Pagination>
                <Pagination.First onClick={() => this.setState({ page: 0 })} />

                <Pagination.Prev
                  onClick={() => this.setState({ page: previousPage })}
                />

                {isLast && (
                  <Pagination.Item
                    onClick={() => this.setState({ page: previousPage - 1 })}
                  >
                    {previousPage - 2}
                  </Pagination.Item>
                )}
                {!isFirst && (
                  <Pagination.Item
                    onClick={() => this.setState({ page: previousPage })}
                  >
                    {previousPage + 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active={true}>
                  {currentPage + 1}
                </Pagination.Item>

                {!isLast && (
                  <Pagination.Item
                    onClick={() => this.setState({ page: nextPage })}
                  >
                    {nextPage + 1}
                  </Pagination.Item>
                )}
                {isFirst && (
                  <Pagination.Item
                    onClick={() => this.setState({ page: nextPage + 1 })}
                  >
                    {nextPage + 2}
                  </Pagination.Item>
                )}
                {!isLast && (
                  <Pagination.Next
                    onClick={() => this.setState({ page: nextPage })}
                  />
                )}
                <Pagination.Last
                  onClick={() => this.setState({ page: numPages - 1 })}
                />
              </Pagination>
            </StyledRow>
          )}

          <Modal show={this.state.show} bsSize="sm">
            <Modal.Body>
              <StyledRow>
                <StyledDatePicker
                  moment={
                    this.state.which === "start"
                      ? this.state.start
                      : this.state.end
                  }
                  onChange={this.handleChange}
                  onSave={this.handleSave}
                />
              </StyledRow>
            </Modal.Body>
          </Modal>   
          {/* Export for Quarter */}
          <Modal
            show={this.state.showExportQuarter}
            onHide={this.handleExportQuarterClose}
          >
            <Modal.Header><h3>Export for Quarter</h3></Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={6}>
                  <Label>Year Level:</Label>
                  <Select
                    options={this.state.yearOptions}
                    value={this.state.select_Exportyear}
                    onChange={this.yearExportChanged}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                  />
                </Col>
                <Col md={6}>
                  <Label>Quarter Level:</Label>
                  <Select
                    options={this.state.quarterOptions}
                    value={this.state.select_Exportquarter}
                    onChange={this.quarterExportChanged}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <ExportWrapper>
                <ExcelFile
                  filename={`newcastle-${moment().format("YYYY-MM-DD")}-${this.state.modal_Exportquarter}`}
                  element={<ToolButton bsStyle="primary" onClick={this.handleExportQuarterClose}>Export</ToolButton>}
                >
                  <ExcelSheet data={this.chargedQuarterCustomers()} name="Unpaid">
                    <ExcelColumn label="Account Number" value="Account Number" />
                    <ExcelColumn label="Resident Name" value="Resident Name" />
                    <ExcelColumn
                      label="Address"
                      value={col =>
                        `${col["Address"]} ${col["City"]}, ${col["State"]}  ${
                        col["Location ZIP Code"]
                        } ${col["ZIP+4"] ? `-${col["ZIP+4"]}` : ""}`
                      }
                    />
                    <ExcelColumn
                      label="Service Address"
                      value={col =>
                        `${col["Physical Address"]} ${col["Physical City"]}, ${col["Physical State"]}  ${
                        col["Physical ZIP"]
                        } ${col["Physical ZIP+4"] ? `-${col["Physical ZIP+4"]}` : ""}`
                      }
                    />
                  </ExcelSheet>
                  <ExcelSheet data={this.paidQuarterCustomers()} name="Paid">
                    <ExcelColumn label="Account Number" value="Account Number" />
                    <ExcelColumn label="Resident Name" value="Resident Name" />
                    <ExcelColumn
                      label="Address"
                      value={col =>
                        `${col["Address"]} ${col["City"]}, ${col["State"]}  ${
                        col["Location ZIP Code"]
                        } ${col["ZIP+4"] ? `-${col["ZIP+4"]}` : ""}`
                      }
                    />
                    <ExcelColumn
                      label="Service Address"
                      value={col =>
                        `${col["Physical Address"]} ${col["Physical City"]}, ${col["Physical State"]}  ${
                        col["Physical ZIP"]
                        } ${col["Physical ZIP+4"] ? `-${col["Physical ZIP+4"]}` : ""}`
                      }
                    />
                  </ExcelSheet>
                </ExcelFile>
              </ExportWrapper>
            </Modal.Footer>
          </Modal>       
          {/* Bill All For Quarter */}
          <Modal
            show={this.state.showBillAll}
            onHide={this.handleBillClose}
          >
            <Modal.Header><h3>Bill All for Quarter</h3></Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={6}>
                  <Label>Year Level:</Label>
                  <Select
                    options={this.state.yearOptions}
                    value={this.state.select_year}
                    onChange={this.yearChanged}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                  />
                </Col>
                <Col md={6}>
                  <Label>Quarter Level:</Label>
                  <Select
                    options={this.state.quarterOptions}
                    value={this.state.select_quarter}
                    onChange={this.quarterChanged}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                disabled={!this.state.modal_quarter.length || !this.state.modal_year.length}
                bsStyle="primary"
                onClick={this.handleBillFilter}
                id="printBtn"
              >
                Bill
              </ToolButton>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.billingAll}>
            <Modal.Header><h3>Bill All for Quarter</h3></Modal.Header>
            <Modal.Body><p>Marking appropriate customers as "charged" for the selected quarter. Please wait for this window to close and then refresh the page.</p></Modal.Body>
          </Modal>
          {/* show delete customer modal here */}
          <Modal
            bsSize="small"
            show={this.state.showDelete}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <span>Delete </span>
                <span>
                  {this.state.customer ? this.state.customer["Resident Name"] : " "}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                Are you sure you want to delete this customer?
              </div>
            </Modal.Body>
            <Modal.Footer>
              <CloseButton onClick={this.handleCloseDelete}>Close</CloseButton>
              <DeleteButton bsStyle="danger" onClick={this.handleRemove}>
                Delete
              </DeleteButton>
            </Modal.Footer>
          </Modal>
        </CustomersWrapper>
      );
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = state => ({
  customers: state.newcastle.customers,
  invoices: state.invoices,
  authorized: state.user?.pages?.customers,
  current_billing_quarter: state.current_billing_quarter,
  current_billing_year: state.current_billing_year,
});

const mapDispatchToProps = dispatch => ({
  showInvoice: (customerId, historyId) =>
    dispatch(CustomersActions.showInvoice(customerId, historyId)),
  getCustomers: () =>
    dispatch(NewCastleCustomersActions.getCustomersNewCastle()),
  quarterUpdate: () =>
    dispatch(NewCastleCustomersActions.quarterUpdate()),
  yearUpdate: () =>
    dispatch(NewCastleCustomersActions.yearUpdate()),
  billToggle: (uid, type) =>
    dispatch(NewCastleCustomersActions.billToggleNewCastle(uid, type)),
  setCustomer: uid =>
    dispatch(NewCastleCustomersActions.setNewCastleCustomer(uid)),
  addCustomer: () => dispatch(NewCastleCustomersActions.addNewCastleCustomer()),
  deleteCustomer: uid =>
    dispatch(NewCastleCustomersActions.removeNewCastleCustomer(uid)),
  changeCurrentBillingQuarter: (quarter) =>
    dispatch(NewCastleCustomersActions.changeCurrentBillingQuarter(quarter)),
  changeCurrentBillingYear: (year) =>
    dispatch(NewCastleCustomersActions.changeCurrentBillingYear(year)),
  getCurrentBillingPeriod: () => 
    dispatch(NewCastleCustomersActions.getCurrentBillingPeriod()),  
  saveCurrentBillingPeriod: (quarter, year) => 
    dispatch(NewCastleCustomersActions.updateCurrentBillingPeriod(quarter, year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCastleInvoices);
