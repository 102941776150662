/* @format - for Prettier */
import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { reduxForm } from "redux-form";
import { GreenBagCustomers } from "../../actions2";
import { CustomerData, CustomerForm, Header } from "./components";
import { DeniedWrapper } from "../../styled";

const CustomerWrapper = styled.div`
  margin: 2vh 12vw 0vh 12vw;
`;

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "view"
    };
  }

  changeMode = mode => {
    this.props.reset();
    this.setState({ mode });
  };

  save = formValues => {
    const { uid } = this.props.customer;
    let values = {};

    _.forEach(this.props.fields, (value, key) => {
      if (key === 'service_address') {
        _.forEach(this.props.fields[key], value => {
          if (value !== undefined && value.touched) {
            values[key] = formValues[key];
          }
        });
      }
      else{
        if (value && value.touched) {
          values[key] = formValues[key];
        }
      }
    });

    this.props.saveCustomer(values, uid);
    this.changeMode("view");
  };

  render() {
    const { mode } = this.state;
    if (this.props.authorized) {
      if (this.props.customer === null) {
        return <Redirect to="/GreenBagCustomers" />;
      }
      if (mode === "view") {
        return (
          <CustomerWrapper>
            <Header type="view" changeMode={this.changeMode} />
            <CustomerData />
          </CustomerWrapper>
        );
      } else if (mode === "edit") {
        return (
          <CustomerWrapper>
            <form onSubmit={this.props.handleSubmit(this.save)}>
              <Header
                type="form"
                changeMode={this.changeMode}
                submitting={this.props.submitting}
              />
              <CustomerForm meta={this.props} />
            </form>
          </CustomerWrapper>
        );
      } else if (mode === 'back') {
        return <Redirect to="/GreenBagCustomers" />;
      } else {
        return <CustomerWrapper> Customer Not Found </CustomerWrapper>;
      }
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = state => ({
  authorized: state.user?.pages?.customers,
  fields: _.get(state, "form.GreenBagCustomerForm.fields"),
  customer: state.greenBag.customer
});

const mapDispatchToProps = dispatch => ({
  saveCustomer: (customer, customerId) =>
    dispatch(GreenBagCustomers.saveCustomer(customer, customerId))

    
});

Customer = reduxForm({
  form: "GreenBagCustomerForm",
  enableReinitialize: true,
  touchOnChange: true,
  touchOnBlur: false
})(Customer);

Customer = connect(state => ({
  initialValues: state.greenBag.customer
}))(Customer);

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
