/* @format - for Prettier */
import _ from 'lodash';
import {db} from '../firebase';

export const setUsers = users => ({
  type: 'SET_USERS',
  users,
});

export const setUser = user => ({
  type: 'SET_USER',
  user,
});

/* Gets all the users in the database */
export const getUsers = () => {
  return function(dispatch) {
    db.readUsers()
      .then(snapshot => {
        dispatch(setUsers(snapshot.val()));
      })
      .catch(error => console.log('error in getUsers', error));
  };
};

export const saveUser = user => {
  return function(dispatch) {
    db.doAddUser(user)
      .then(snapshot => {
        dispatch(getUsers());
      })
      .catch(error => console.log('error in saveUser', error));
  };
};

export const saveUsers = (values, touched) => {
  return function(dispatch) {
    let updates = {};
    let path = '/users';
    _.forEach(values.users, (user, i) => {
      _.forEach(touched.users[i], (val, key) => {
        if (key === 'pages') {
          _.forEach(val, (value, page) => {
            if (value.touched) {
              updates[`${path}/${user.uid}/${key}/${page}`] = user[key][page];
            }
          });
        } else if (val.touched) {
          updates[`${path}/${user.uid}/${key}`] = user[key];
        }
      });
    });
    db.doUpdate(updates)
      .then(() => {
        dispatch(getUsers());
      })
      .catch(error => console.log('error in save users', error));
  };
};

export const removeUser = userId => {
  return function(dispatch) {
    db.deleteUser(userId)
      .then(() => {
        dispatch(getUsers());
      })
      .catch(error => console.log('error in delete user', error));
  };
};
