import React from 'react';
import _ from 'lodash';
import { Table } from 'react-bootstrap';

export default class RouteList extends React.PureComponent {
	state = {
		selectedRoutes: []
	}

	toggleRoute = (select) => () => {
		let { selectedRoutes } = this.state;
		const findIndex = selectedRoutes.findIndex(route => route.uid === select.uid);
		if (findIndex === -1) {
			selectedRoutes.push(select);
		} else {
			selectedRoutes = selectedRoutes.filter(route => route.uid !== select.uid);
		}


		this.setState({ selectedRoutes: [...selectedRoutes] });
		this.props.onSelectRoutes(selectedRoutes);
	}

	toggleAll = (e) => {
		const value = e.target.checked;
		const selectedRoutes = value ? _.map(this.props.routes, route => route): [];
		this.setState({ selectedRoutes: [...selectedRoutes] });
		this.props.onSelectRoutes(selectedRoutes);
	}

	render() {
		return (
			<Table responsive hover>
				<thead>
					<tr>
						<th><input type="checkbox" onChange={this.toggleAll}/></th>
						<th>Route Name</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					{_.map(this.props.routes, route => {
						const isChecked = this.state.selectedRoutes.findIndex(item => item.uid === route.uid) !== -1 ? true : false;
						return (
							<tr key={route.uid} htmlFor={route.uid} onClick={this.toggleRoute(route)}>
								<td>
									<input type="checkbox" id={route.uid} checked={isChecked} readOnly></input>
								</td>
								<td>
									<div>{route.name}</div>
								</td>
								<td>
									{route.status}
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		)
	}
}