import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Table, ControlLabel } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import _ from 'lodash';

import PrintSelectedInvoices from '../NewCastle/PrintSelectedInvoices';
import axiosInstance from '../../api/axios';

const AccordionContainer = styled.div`
  margin: 20px 0px !important;
`;

const StartEndDatePickerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DatePickerContainer = styled.span`
  margin-left: 20px;
`;

const AccordionPaidCustomers = ({ type, setCustomer, ...props }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [Customers, setCustomers] = useState([]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  useEffect(() => { 
    const date = moment(startDate).format('YYYY-MM-DD');
    axiosInstance.get(`/api/customers/paid/${type}/${date}`).then((response) => {
      setCustomers(response.data.customers);
    })
    .catch((error) => {
      console.log(error);
    });
  
  }, [startDate]);

  return (
    <AccordionContainer>
      <Accordion allowZeroExpanded="true">
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <label>Paid Customers (API & Recurring)</label>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <StartEndDatePickerContainer>
              <div>
                <ControlLabel>Select Date</ControlLabel>
                <DatePickerContainer>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                  />
                </DatePickerContainer>
              </div>
              <PrintSelectedInvoices {...props} />
            </StartEndDatePickerContainer>
            <Table id="print" hover bordered>
              <thead>
                <tr>
                  <th>Date Paid</th>
                  <th>Amount</th>
                  <th>Account Number</th>
                  <th>Transaction ID</th>
                  <th>Customer</th>
                  <th>Service Address</th>
                  {/* <th>Status & Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {
                  _.map(Customers, ({
                        uid,
                        name,
                        acc_number,
                        billing_address,
                        transaction_id,
                        amount,
                        time
                      }) => {
                        const address = JSON.parse(billing_address);
                  return (
                    <tr key={uid}>
                      <td onClick={() => setCustomer(uid)}>
                        {time}
                      </td>
                      <td onClick={() => setCustomer(uid)}>
                        {amount}
                      </td>
                      <td onClick={() => setCustomer(uid)}>
                        {acc_number}
                      </td>
                      <td onClick={() => setCustomer(uid)}>
                        {transaction_id}
                      </td>
                      <td onClick={() => setCustomer(uid)}>
                        <div>{name}</div>
                        {/* <small>{customer.Address}</small> */}
                      </td>
                      <td onClick={() => setCustomer(uid)}>
                        {[address?.street,address?.city,address?.state,address?.zipcode].filter(item => !!item).join(' ')}
                      </td>
                      {/* <StatusCol>
                        <div>
                          <span>{customer.Status}</span>
                          {customer.Status !== 'paid' && (
                            <PrintSingleInvoice customer={customer} />
                          )}
                        </div>
                      </StatusCol> */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </AccordionContainer>
  );
};

export default AccordionPaidCustomers;
