/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {ModeButton, ButtonRow} from '../../../styled';

const Obj = {
  view: {
    back: 'Back to customer list',
    edit: 'Edit',
  },
  edit: {
    save: 'Save',
    view: 'Cancel',
  },
};

const Wrapper = styled.div`
  height: 40px;
`;

const Header = ({type, changeMode, submitting}) => (
  <Wrapper>
    {type === 'form' ? (
      <ButtonRow>
        <ModeButton bsStyle="link" type="submit" disabled={submitting}>
          Save
        </ModeButton>
        <ModeButton
          bsStyle="link"
          type="button"
          onClick={() => changeMode('view')}>
          Cancel
        </ModeButton>
      </ButtonRow>
    ) : (
      <div className="d-flex justify-content-between">
        {_.map(Obj[type], (text, mode) => {
          return (
            <div>
              <ModeButton
                key={mode}
                bsStyle="link"
                onClick={() => changeMode(mode)}>
                {text}
              </ModeButton>
            </div>
          );
        })}
      </div>
    )}
  </Wrapper>
);
export default Header;
