/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import {Table, Image, Button, Glyphicon} from 'react-bootstrap';

const RouteWrapper = styled.div`
  margin: 2vh 10vw 0vh 10vw;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  max-height: 50%;
`;

const ImageRow = styled.span`
  display: flex;
  flex-wrap: wrap;
`;

const Print = styled(Button)`
  float: right;
`;

const PrintRoute = ({route}) => {
  let componentRef = null;
  console.log('Route =====>',route);
  if (route === undefined || route.name === undefined) {
    return <Redirect to="Routes" />;
  } else {
    return (
      <RouteWrapper>
        <ReactToPrint
          trigger={() => (
            <Print bsStyle="primary" bsSize="large">
              {' '}
              <Glyphicon glyph="print" />{' '}
            </Print>
          )}
          content={() => componentRef}
        />
        <div ref={el => (componentRef = el)}>
          <h3>{route.name}</h3>
          <div>{route.status}</div>
          <Table condensed bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Customer</th>
                <th width="25%">Address</th>
                <th width="12%">Coordinates</th>
                <th>Status</th>
              </tr>
            </thead>
            {_.map(route.stops, (stop, i) => (
              <tbody key={stop.uid}>
                <tr key={stop.uid}>
                  {
                    stop.notes ? (
                      <td width="30px" rowSpan="2"> {i + 1} </td>
                    ) : (
                      <td width="30px"> {i + 1} </td>
                    )
                  }
                  
                  <td> {stop.name} </td>
                  <td> {stop.customer.customer_name} </td>
                  <td>
                    <div> {stop.address.street} </div>
                    <div>
                      {' '}
                      {stop.address.city}, {stop.address.state}{' '}
                      {stop.address.zipcode}{' '}
                    </div>
                  </td>
                  <td>
                    ({stop.coordinates.Latitude}, {stop.coordinates.Longitude})
                  </td>
                  <td width="60px">{stop.status}</td>
                </tr>
                {
                  stop.notes ? (
                    <tr>
                      <td colSpan="5">Notes: {stop.notes} </td>
                    </tr>
                  ) : ('')
                }
                {
                  stop.photos ? (
                    <tr>
                      <td colSpan="6">
                        <ImageRow>
                          {_.map(stop.photos, (url, i) => (
                            <ImageWrapper key={i}>
                              <Image responsive src={url} />
                            </ImageWrapper>
                          ))}
                        </ImageRow>
                      </td>
                    </tr>
                  ) : ('')
                }
                
                
              </tbody>
            ))}
          </Table>
        </div>
      </RouteWrapper>
    );
  }
};

const mapStateToProps = state => ({
  route: state.currentRoute,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrintRoute);
