import moment from 'moment';
import { monthOptions, quarterOptions } from './index';

/**
 *
 * @param {string} monthString 'JAN' | 'FEB' | ... | 'DEC'
 * @param {moment} momentData
 * @return {moment} 'MM/DD/YYYY' 1/30 | 2/30 | 3/30
 */
const getMonthlyDueDate = (monthString, momentData) => {
  const current = momentData || moment();

  let month = monthOptions.findIndex((i) => i.value === monthString);
  if (month === -1) month = current.month();

  const date = month === 1 ? 28 : 30;

  return current.month(month).date(date).format('L');
};

/**
 *
 * @param {string} quarterString '1st Quarter' | ... | '4th Quarter'
 * @param {moment} momentData
 * @return {moment} 'MM/DD/YYYY' 1/30 | 4/30 | 7/30 | 10/30
 */
const getQuarterlyDueDate = (quarterString, momentData) => {
  const current = moment();
  return current.add(30, 'days').format('MM/DD/YYYY');
};

export { getMonthlyDueDate, getQuarterlyDueDate };
