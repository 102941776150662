import React, { useState } from 'react';

import styled from 'styled-components';
import { Button, Glyphicon, Modal, ProgressBar } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { pdfService } from '../../services/pdf';

const Wrapper = styled.div`
display: inline-flex;`;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  margin: 10px;
  margin-right: 0;
`;

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`;

const PrintProgress = styled(ProgressBar)`
  margin-top: 20px;
`;

const PrintAllInvoices = ({
    customers,
    quarter
  }) => {
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);
  const [exporting, setExporting] = useState(false);
  const [dueDate, setDueDate] = useState(new Date());

  const handlePrint = async () => {
    setExporting(true)

    const quarterlyCustomers = customers.filter((customer) => {
      if (customer["billFrequency"] === "monthly") return false;
      if (customer["isExempt"] === "Yes") return false;
      if (customer['isInactive'] === 'Yes') return false;
      if (customer["recurringCustomer"] === "Yes") return false;
      if (customer["wantsEInvoice"] === "Yes") return false;
      return true;
    });
    console.log("quarterlyCustomers", quarterlyCustomers);
    await pdfService.commercial_customer_invoice_pdf_download(
      quarterlyCustomers,
      "all quarterly",
      dueDate,
      "",
      "",
      quarter,
      "",
      "",
      "",
      setProgress,
    );
    setExporting(false)
    setShow(false)
  };

  const handleHide = () => {
    if (!exporting) setShow(false);
  };

  return (
    <Wrapper>
      <Modal show={show} onHide={handleHide}>
        <Modal.Body>
          {exporting ? (
            <>
              <h3>Downloading...</h3>
              <p>This may take some time.</p>
              <PrintProgress active striped now={progress} label={`${progress}%`} />
            </>
          ) : (
            <>
            <div className="form-group" style={{marginTop:20}}>
              <label>Which due date option would you like to use?</label>
              <br />
              <DatePicker 
                selected={dueDate}
                onChange={(date) => {setDueDate(date)}}
                required
              />
            </div>
            </>
          )}
          <ToolButton disabled={!dueDate} bsStyle="primary" onClick={handlePrint} id="printBtn">
            Print All Invoices
          </ToolButton>
        </Modal.Body>
      </Modal>
      <ToolButton
        disabled={exporting}
        bsStyle="primary"
        id="printBtn"
        onClick={() => setShow(true)}
      >
        <AddGlyph glyph="print" />
        Print All Invoices
      </ToolButton>
    </Wrapper>
  );
};

export default PrintAllInvoices;
