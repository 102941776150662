import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {Link} from 'react-router-dom';
import {ModeButton, ButtonRow, Name} from '../../../styled';
import AddStop from './AddStop';

const Obj = {
  view: {
    edit: 'Edit',
    move: 'Move Stops',
    delete: 'Delete Stops',
  },
  move: {
    confirm: 'Confirm',
    view: 'Cancel'
  },
  reorder: {
    save: 'Save',
    view: 'Cancel',
  },
  delete: {
    view: 'Done',
  },
};

const renderField = ({input, label, type, meta: {touched, error}}) => (
  <div>
    <label>{label}</label>
    <div>
      <input {...input} type={type} placeholder={label} />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

const Header = ({type, changeMode, name, status, submitting, mode}) => (
  <div>
    <Link to="/PrintRoute"> Print this Route </Link>
    {mode === 'view' && <AddStop />}
    {type === 'form' ? (
      <div>
        <ButtonRow>
          <ModeButton bsStyle="link" type="submit" disabled={submitting}>
            Save
          </ModeButton>
          <ModeButton
            bsStyle="link"
            type="button"
            onClick={() => changeMode('view')}>
            Cancel
          </ModeButton>
        </ButtonRow>
        <Name>
          <Field
            name="name"
            type="text"
            component={renderField}
            label="Route Name"
          />
        </Name>
        <Field
          name="status"
          type="text"
          component={renderField}
          label="Route Status"
        />
      </div>
    ) : (
      <div>
        <ButtonRow>
          {_.map(Obj[type], (text, mode) => {
            return (
              <ModeButton
                key={mode}
                bsStyle="link"
                onClick={() => changeMode(mode)}>
                {text}
              </ModeButton>
            );
          })}
        </ButtonRow>
        <Name>{name}</Name>
        <div>{status}</div>
      </div>
    )}
  </div>
);

const mapStateToProps = state => ({
  name: state.currentRoute.name,
  status: state.currentRoute.status
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
