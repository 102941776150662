import React from 'react';
import PropTypes from 'prop-types';

import SelectModal from './SelectModal';
import { monthOptions, quarterOptions,dueDateOptions } from '../../utils/index';
import { getMonthlyDueDate, getQuarterlyDueDate } from '../../utils/invoice';
import moment from 'moment';
import { getFormattedDate } from '../../helpers';

const DueDateSelectModal = ({ show, title, duration, okayLabel, onOkay,isWestMayfield=false,isResidentialCustomer=false }) => {
  const options =isResidentialCustomer?dueDateOptions: duration === 'MONTH' ? monthOptions : quarterOptions;

  const getDueDate = duration === 'MONTH' ? getMonthlyDueDate : getQuarterlyDueDate;

  const handleOkay = (period,duration=null) => {
    let quarter;
    if(isWestMayfield){
      if(period==="1st Quarter"){
        quarter='01/01/'+ (new Date().getFullYear()+1)
      }
      else if(period==="2nd Quarter"){
        quarter='04/01/'+new Date().getFullYear()
      }
      else if(period==="3rd Quarter"){
        quarter='07/01/'+new Date().getFullYear()
      }
      else if(period==="4th Quarter"){
        quarter='10/01/'+new Date().getFullYear()
      }
    }
    const dueDate =isWestMayfield?quarter:(period=="customDueDate" && isResidentialCustomer && duration!=null)?getFormattedDate(duration): getDueDate(period)
    onOkay(dueDate);
  };

  return (
    <SelectModal
      show={show}
      title={title}
      options={options}
      okayLabel={okayLabel}
      onOkay={handleOkay}
      isResidentialCustomer={isResidentialCustomer}
    />
  );
};

DueDateSelectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired, // duration: 'QURATER' | 'MONTH'
  okayLabel: PropTypes.string.isRequired,
  onOkay: PropTypes.func.isRequired,
};

DueDateSelectModal.defaultProps = {
  duration: 'MONTH',
  okayLabel: 'Okay',
};

export default DueDateSelectModal;
