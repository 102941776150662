/* @format - for Prettier */
import styled from 'styled-components';
import {Table} from 'react-bootstrap';

const StyledForm = styled.form`
  margin: 2vh 7vw 0vh 7vw;
`;

const StyledTable = styled(Table)`
  margin: 2vh 23vw 0vh 23vw;
`;

export {StyledForm, StyledTable};
