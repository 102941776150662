/* @format - for Prettier */
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Label, Button } from 'react-bootstrap'
import NotesModal from './NotesModal'
import Time from './Time'
import { Routes } from '../../../actions2'

const status = {
  suspended: 'danger',
  completed: 'success',
  pending: 'default',
  'not out': 'warning'
}

const Row = styled.tr`
  /* stylelint-disable comment-empty-line-before */
  ${(props) =>
    props.isDragging
      ? `
    background: rgb(185, 244, 188);

    /* maintain cell width while dragging */
    display: table;
  `
      : ''}/* stylelint-enable */;
`

class Stop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      status: 'edit'
    }
  }

  handleShow = () => {
    const { notes, photos, uid } = this.props.stop
    this.props.setNotes(notes, photos, uid)
    this.setState({ show: true })
  }

  handleClose = () => {
    this.props.getRoute(this.props.routeId)
    this.setState({ show: false })
  }

  render() {
    const { index, stop, snapshot, provided, isSelected } = this.props
    const customer_name = stop.customer ? stop.customer.name : ''
    return (
      <Row
        ref={provided.innerRef}
        isDragging={snapshot.isDragging}
        onClick={() => this.props.onToggleStop(stop)}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <td>
          <input type="checkbox" checked={isSelected} readOnly />
        </td>
        <td> {index + 1} </td>
        <td> {stop.name} </td>
        <td> {customer_name} </td>
        <td>
          <div> {stop.address !== undefined ? stop.address.street : null} </div>
          <div>
            {' '}
            {stop.address !== undefined ? stop.address.city : null}, {stop.address !== undefined ? stop.address.state : null} {stop.address !== undefined ? stop.address.zipcode : null}{' '}
          </div>
        </td>
        <td>
          ({stop.coordinates !== undefined ? stop.coordinates.Latitude : null}, {stop.coordinates !== undefined ? stop.coordinates.Longitude : null})
        </td>
        <td>
          <div>
            Added On: <i>{stop.added_timestamp}</i>
          </div>
          <div>
            Last Picked Up On: <i>{stop.last_picked_up_timestamp}</i>
          </div>
          <Time index={index} />
          <div>
            {stop.status === 'not out' && (
              <i>Last Not Out On: {stop.last_not_out_timestamp}</i>
            )}
          </div>
        </td>
        <td>
          <Label bsStyle={status[stop.status]}>{stop.status}</Label>
        </td>
        <td>
          <Button onClick={this.handleShow} bsStyle="link">
            Notes
          </Button>
        </td>
        <NotesModal
          show={this.state.show}
          stopId={stop.uid}
          handleClose={this.handleClose}
        />
      </Row>
    )
  }
}
const mapStateToProps = (state) => ({
  routeId: state.currentRoute.uid
})

const mapDispatchToProps = (dispatch) => ({
  getRoute: (id) => dispatch(Routes.getRoute(id)),
  setNotes: (notes, photos, stopId) =>
    dispatch(Routes.setNotes(notes, photos, stopId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Stop)
