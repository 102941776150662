import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { Col, Grid, Row } from "react-bootstrap";
import { DeniedWrapper } from "../../styled";

const ApplicantWrapper = styled.div`
  margin: 2vh 12vw 0vh 12vw;
`;
const StyledRow = styled(Row)`
  margin-bottom: 0px !important;
`;
const StyledCol = styled(Col)`
  padding: 0px;
  margin-bottom: 20px;
`;

class JobSubmissionDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { authorized, applicant } = this.props;

    if (authorized) {
      if (applicant === null) {
        return <Redirect to="/JobSubmissions" />;
      }
      return (
        <ApplicantWrapper>
          <Grid>
            <StyledRow>
              <StyledCol sm={6} md={6}>
                <h4>Applicant Information</h4>
                {applicant.first_name && applicant.last_name && (
                  <div>{`Full Name: ${applicant.first_name} ${applicant.last_name}`}</div>
                )}
                {applicant.dob && <div>Date of Birth: {applicant.dob}</div>}
                {applicant.street_address && (
                  <div>Street Address: {applicant.street_address}</div>
                )}
                {applicant.apartment_unit && (
                  <div>Apartment/Unit: {applicant.apartment_unit}</div>
                )}
                {applicant.city && <div>City: {applicant.city}</div>}
                {applicant.state && <div>State: {applicant.state}</div>}
                {applicant.phone && <div>Phone: {applicant.phone}</div>}
                {applicant.email && <div>Email: {applicant.email}</div>}
                {applicant.applicant_date && (
                  <div>Date of Availability: {applicant.applicant_date}</div>
                )}
                {applicant.security_number && (
                  <div>Social Security No: {applicant.security_number}</div>
                )}
                {applicant.desired_salary && (
                  <div>Desired Salary: ${applicant.desired_salary}</div>
                )}
                {applicant.position_applied && (
                  <div>Position Applied For: {applicant.position_applied}</div>
                )}
                {applicant.is_us_citizen && (
                  <div>
                    Is United States Citizen:{" "}
                    {applicant.is_us_citizen.charAt(0).toUpperCase() +
                      applicant.is_us_citizen.slice(1)}
                  </div>
                )}
                {applicant.is_us_citizen && (
                  <div>
                    Is Authorized To Work In United States:{" "}
                    {applicant.is_us_authorized.charAt(0).toUpperCase() +
                      applicant.is_us_authorized.slice(1)}
                  </div>
                )}
                {applicant.have_worked_in_current_company && (
                  <div>
                    Have Ever Worked For AIKEN REFUSE:{" "}
                    {applicant.have_worked_in_current_company
                      .charAt(0)
                      .toUpperCase() +
                      applicant.have_worked_in_current_company.slice(1)}
                  </div>
                )}
                {applicant.have_worked_in_current_company === "yes" &&
                  applicant.if_so_when && (
                    <div>
                      Worked For AIKEN REFUSE In: {applicant.if_so_when}
                    </div>
                  )}
                {applicant.have_convited_of_felony && (
                  <div>
                    Have Ever Been Convicted of A Felony:{" "}
                    {applicant.have_convited_of_felony.charAt(0).toUpperCase() +
                      applicant.have_convited_of_felony.slice(1)}
                  </div>
                )}
                {applicant.have_convited_of_felony === "yes" &&
                  applicant.convited_of_felony_explanation && (
                    <div>
                      Convicted of A Felony Explanation:{" "}
                      {applicant.convited_of_felony_explanation}
                    </div>
                  )}
                {applicant.have_driver_license && (
                  <div>
                    Have Valid Driver’s License:{" "}
                    {applicant.have_driver_license.charAt(0).toUpperCase() +
                      applicant.have_driver_license.slice(1)}
                  </div>
                )}
                {applicant.have_driver_license === "yes" &&
                  applicant.license_number && (
                    <div>Driver’s License No: {applicant.license_number}</div>
                  )}
                {applicant.is_valid_commercial_driver_license && (
                  <div>
                    Have Valid Commercial Driver’s License:{" "}
                    {applicant.is_valid_commercial_driver_license
                      .charAt(0)
                      .toUpperCase() +
                      applicant.is_valid_commercial_driver_license.slice(1)}
                  </div>
                )}
                {applicant.is_valid_commercial_driver_license === "yes" &&
                  applicant.driver_commercial_license_class && (
                    <div>
                      Commercial Driver’s License Class:{" "}
                      {applicant.driver_commercial_license_class}
                    </div>
                  )}
              </StyledCol>
              <StyledCol sm={6} md={6}>
                <h4>Applicant Education</h4>
                {applicant.high_school && (
                  <div>High School Name: {applicant.high_school}</div>
                )}
                {applicant.school_address && (
                  <div>High School Address: {applicant.school_address}</div>
                )}
                {applicant.school_from && applicant.school_to && (
                  <div>{`High School Duration: ${applicant.school_from} to ${applicant.school_to}`}</div>
                )}
                {applicant.have_school_graduated && (
                  <div>
                    Have Graduated From High School:{" "}
                    {applicant.have_school_graduated.charAt(0).toUpperCase() +
                      applicant.have_school_graduated.slice(1)}
                  </div>
                )}
                {applicant.have_school_graduated === "yes" &&
                  applicant.school_degree_title && (
                    <div>
                      High School Degree: {applicant.school_degree_title}
                    </div>
                  )}
                {applicant.college_name && (
                  <div>College Name: {applicant.college_name}</div>
                )}
                {applicant.college_address && (
                  <div>College Address: {applicant.college_address}</div>
                )}
                {applicant.college_from && applicant.college_to && (
                  <div>{`College Duration: ${applicant.college_from} to ${applicant.college_to}`}</div>
                )}
                {applicant.have_college_graduated && (
                  <div>
                    Have Graduated From College:{" "}
                    {applicant.have_college_graduated.charAt(0).toUpperCase() +
                      applicant.have_college_graduated.slice(1)}
                  </div>
                )}
                {applicant.have_college_graduated === "yes" &&
                  applicant.college_degree_title && (
                    <div>College Degree: {applicant.college_degree_title}</div>
                  )}
                {applicant.other_degree_title && (
                  <div>Other Education: {applicant.other_degree_title}</div>
                )}
                {applicant.other_education_address && (
                  <div>
                    Other Education Adrress: {applicant.other_education_address}
                  </div>
                )}
                {applicant.other_education_from &&
                  applicant.other_education_to && (
                    <div>
                      {`Other Education Duration: ${applicant.other_education_from} to ${applicant.other_education_to}`}
                    </div>
                  )}
                {applicant.have_other_education_graduated && (
                  <div>
                    Have Graduated From Other Education:{" "}
                    {applicant.have_other_education_graduated
                      .charAt(0)
                      .toUpperCase() +
                      applicant.have_other_education_graduated.slice(1)}
                  </div>
                )}
                {applicant.have_other_education_graduated === "yes" &&
                  applicant.other_degree_title && (
                    <div>
                      Other Education Degree: {applicant.other_degree_title}
                    </div>
                  )}
              </StyledCol>
            </StyledRow>
            <StyledRow>
              <StyledCol sm={6} md={6}>
                <h4>Applicant Professional Refrences</h4>
                {applicant.ref_full_name_1 && (
                  <div>1st Referral Name: {applicant.ref_full_name_1}</div>
                )}
                {applicant.ref_relationship_1 && (
                  <div>
                    1st Referral Relationship: {applicant.ref_relationship_1}
                  </div>
                )}
                {applicant.ref_company_1 && (
                  <div>1st Referral Company: {applicant.ref_company_1}</div>
                )}
                {applicant.ref_phone_1 && (
                  <div>1st Referral Phone No: {applicant.ref_phone_1}</div>
                )}
                {applicant.ref_address_1 && (
                  <div>1st Referral Adrress: {applicant.ref_address_1}</div>
                )}
                {applicant.ref_full_name_2 && (
                  <div>2nd Referral Name: {applicant.ref_full_name_2}</div>
                )}
                {applicant.ref_relationship_2 && (
                  <div>
                    2nd Referral Relationship: {applicant.ref_relationship_2}
                  </div>
                )}
                {applicant.ref_company_2 && (
                  <div>2nd Referral Company: {applicant.ref_company_2}</div>
                )}
                {applicant.ref_phone_2 && (
                  <div>2nd Referral Phone No: {applicant.ref_phone_2}</div>
                )}
                {applicant.ref_address_2 && (
                  <div>2nd Referral Adrress: {applicant.ref_address_2}</div>
                )}
                {applicant.ref_full_name_3 && (
                  <div>3rd Referral Name: {applicant.ref_full_name_3}</div>
                )}
                {applicant.ref_relationship_3 && (
                  <div>
                    3rd Referral Relationship: {applicant.ref_relationship_3}
                  </div>
                )}
                {applicant.ref_company_3 && (
                  <div>3rd Referral Company: {applicant.ref_company_3}</div>
                )}
                {applicant.ref_phone_3 && (
                  <div>3rd Referral Phone No: {applicant.ref_phone_3}</div>
                )}
                {applicant.ref_address_3 && (
                  <div>3rd Referral Adrress: {applicant.ref_address_3}</div>
                )}
              </StyledCol>
              <StyledCol sm={6} md={6}>
                <h4>Applicant Previous Employment</h4>
                {applicant.pre_company_1 && (
                  <div>
                    1st Previous Company Name: {applicant.pre_company_1}
                  </div>
                )}
                {applicant.pre_phone_1 && (
                  <div>
                    1st Previous Company Phone No: {applicant.pre_phone_1}
                  </div>
                )}
                {applicant.pre_address_1 && (
                  <div>
                    1st Previous Company Address: {applicant.pre_address_1}
                  </div>
                )}
                {applicant.pre_supervisor_1 && (
                  <div>
                    1st Previous Company Supervisor:{" "}
                    {applicant.pre_supervisor_1}
                  </div>
                )}
                {applicant.pre_job_title_1 && (
                  <div>1st Previous Job Title: {applicant.pre_job_title_1}</div>
                )}
                {applicant.pre_start_salary_1 && applicant.pre_end_salary_1 && (
                  <div>{`1st Previous Job Salary Range: ${applicant.pre_start_salary_1} - ${applicant.pre_end_salary_1}`}</div>
                )}
                {applicant.pre_responsibilities_1 && (
                  <div>
                    1st Previous Job Responsibilities:{" "}
                    {applicant.pre_responsibilities_1}
                  </div>
                )}
                {applicant.pre_employment_from_1 &&
                  applicant.pre_employment_to_1 && (
                    <div>{`1st Previous Job Duration: ${applicant.pre_employment_from_1} to ${applicant.pre_employment_to_1}`}</div>
                  )}
                {applicant.can_contact_pre_supervisor_1 && (
                  <div>
                    Can AIKEN REFUSE contact 1st Supervisor:{" "}
                    {applicant.can_contact_pre_supervisor_1
                      .charAt(0)
                      .toUpperCase() +
                      applicant.can_contact_pre_supervisor_1.slice(1)}
                  </div>
                )}
                {applicant.pre_company_2 && (
                  <div>
                    2nd Previous Company Name: {applicant.pre_company_2}
                  </div>
                )}
                {applicant.pre_phone_2 && (
                  <div>
                    2nd Previous Company Phone No: {applicant.pre_phone_2}
                  </div>
                )}
                {applicant.pre_address_2 && (
                  <div>
                    2nd Previous Company Address: {applicant.pre_address_2}
                  </div>
                )}
                {applicant.pre_supervisor_2 && (
                  <div>
                    2nd Previous Company Supervisor:{" "}
                    {applicant.pre_supervisor_2}
                  </div>
                )}
                {applicant.pre_job_title_2 && (
                  <div>2nd Previous Job Title: {applicant.pre_job_title_2}</div>
                )}
                {applicant.pre_start_salary_2 && applicant.pre_end_salary_2 && (
                  <div>{`2nd Previous Job Salary Range: ${applicant.pre_start_salary_2} - ${applicant.pre_end_salary_2}`}</div>
                )}
                {applicant.pre_responsibilities_2 && (
                  <div>
                    2nd Previous Job Responsibilities:{" "}
                    {applicant.pre_responsibilities_2}
                  </div>
                )}
                {applicant.pre_employment_from_2 &&
                  applicant.pre_employment_to_2 && (
                    <div>{`2nd Previous Job Duration: ${applicant.pre_employment_from_2} to ${applicant.pre_employment_to_2}`}</div>
                  )}
                {applicant.can_contact_pre_supervisor_2 && (
                  <div>
                    Can AIKEN REFUSE contact 2nd Supervisor:{" "}
                    {applicant.can_contact_pre_supervisor_2
                      .charAt(0)
                      .toUpperCase() +
                      applicant.can_contact_pre_supervisor_2.slice(1)}
                  </div>
                )}
                {applicant.pre_company_3 && (
                  <div>
                    3rd Previous Company Name: {applicant.pre_company_3}
                  </div>
                )}
                {applicant.pre_phone_3 && (
                  <div>
                    3rd Previous Company Phone No: {applicant.pre_phone_3}
                  </div>
                )}
                {applicant.pre_address_3 && (
                  <div>
                    3rd Previous Company Address: {applicant.pre_address_3}
                  </div>
                )}
                {applicant.pre_supervisor_3 && (
                  <div>
                    3rd Previous Company Supervisor:{" "}
                    {applicant.pre_supervisor_3}
                  </div>
                )}
                {applicant.pre_job_title_3 && (
                  <div>3rd Previous Job Title: {applicant.pre_job_title_3}</div>
                )}
                {applicant.pre_start_salary_3 && applicant.pre_end_salary_3 && (
                  <div>{`3rd Previous Job Salary Range: ${applicant.pre_start_salary_3} - ${applicant.pre_end_salary_3}`}</div>
                )}
                {applicant.pre_responsibilities_3 && (
                  <div>
                    3rd Previous Job Responsibilities:{" "}
                    {applicant.pre_responsibilities_3}
                  </div>
                )}
                {applicant.pre_employment_from_3 &&
                  applicant.pre_employment_to_3 && (
                    <div>{`3rd Previous Job Duration: ${applicant.pre_employment_from_3} to ${applicant.pre_employment_to_3}`}</div>
                  )}
                {applicant.can_contact_pre_supervisor_3 && (
                  <div>
                    Can AIKEN REFUSE contact 3rd Supervisor:{" "}
                    {applicant.can_contact_pre_supervisor_3
                      .charAt(0)
                      .toUpperCase() +
                      applicant.can_contact_pre_supervisor_3.slice(1)}
                  </div>
                )}
              </StyledCol>
            </StyledRow>
            <StyledRow>
              <StyledCol sm={6} md={6}>
                <h4>Applicant Disclaimer</h4>
                {applicant.disclaimer_sign && (
                  <div>
                    Disclaimer Accepted Signature: {applicant.disclaimer_sign}
                  </div>
                )}
                {applicant.disclaimer_date && (
                  <div>
                    Disclaimer Accepted Date: {applicant.disclaimer_date}
                  </div>
                )}
              </StyledCol>
              <StyledCol sm={6} md={6}>
                <h4>Applicant Company Policy</h4>
                {applicant.applicant_sign && (
                  <div>
                    Policy Accepted Signature: {applicant.applicant_sign}
                  </div>
                )}
                {applicant.applicant_date && (
                  <div>Policy Accepted Date: {applicant.applicant_date}</div>
                )}
              </StyledCol>
            </StyledRow>
          </Grid>
        </ApplicantWrapper>
      );
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    authorized: state.user?.pages?.customers,
    applicant: state.jobSubmissions.applicant,
  };
};

export default connect(mapStateToProps, null)(JobSubmissionDetails);
