/* @format - for Pretter */
import {storage} from './firebase';

export const storePhoto = async (photo, stopId) => {
  const path = '/images/' + stopId + '/' + photo.name;
  return storage
    .ref()
    .child(path)
    .put(photo)
    .then(() => {
      return storage
        .ref()
        .child(path)
        .getDownloadURL();
    });
};

export const deletePhoto = (photo, stopId) => {
  return storage.refFromURL(photo).delete();
};

export const fileName = photo => {
  return storage.refFromURL(photo).name;
};
