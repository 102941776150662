import _ from "lodash";

const initialState = {
  jobs: [],
  applicant: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_JOB_APPLICANTS":
      return _.assign({}, state, { jobs: action.jobs });
    case "SET_CURRENT_JOB_APPLICANT":
      return _.assign({}, state, { applicant: action.applicant });
    default:
      return state;
  }
};
