/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {ModeButton, ButtonRow} from '../../../styled';
import { withRouter } from "react-router-dom";

const Wrapper = styled.div`
  height: 40px;
  align: left;
`;

class Header extends React.Component {
  state = {
    Obj: {
      view: {
        edit: 'edit',
      },
      edit: {
        save: 'Save',
        view: 'Cancel',
      },
    }
  }

  componentDidMount() {
    const search = this.props.location.search
    if (search.length > 0 && search.indexOf('Reports/due_accounts') !== -1) {
      this.setState({
        Obj: {
          view: {
            //past_due: 'Back to Past Due Accounts',
            edit: 'Edit',
          },
          edit: {
            save: 'Save',
            view: 'Cancel',
          },
        }
      })
    }
  }

  render() {
    const {type, changeMode, submitting} = this.props
    return (
      <Wrapper>
        {type === 'form' ? (
          <ButtonRow>
            <ModeButton bsStyle="link" type="submit" disabled={submitting}>
              Save
            </ModeButton>
            <ModeButton
              bsStyle="link"
              type="button"
              onClick={() => changeMode('view')}>
              Cancel
            </ModeButton>
          </ButtonRow>
        ) : (
          <div className="">
            {_.map(this.state.Obj[type], (text, mode) => {
              return (
                <div>
                  <ModeButton
                    key={mode}
                    bsStyle="link"
                    onClick={() => changeMode(mode)}>
                    {text}
                  </ModeButton>
                </div>
              );
            })}
          </div>
        )}
      </Wrapper>
    )
  }
}

export default withRouter(Header);
