/* @format - for Prettier */
import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { connect } from "react-redux";
import { Table, Button, Glyphicon } from "react-bootstrap";
import { ResidentialCustomers as ResidentialCustomersActions } from "../../actions2";
import { DeniedWrapper } from "../../styled";
import moment from 'moment';
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const CustomersWrapper = styled.div`
  width: 80vw;
  margin: 0 auto;
  margin-top: 30px;
`;

const ToolButton = styled(Button)`
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 15px;
`;

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`;

const Header = styled.div`
  marginTop: '10px';
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ExportButton = (
  <ToolButton bsStyle="primary">
    <AddGlyph glyph="export" />
    Export to Excel
  </ToolButton>
);
const ExportButton2 = (
  <ToolButton bsStyle="primary">
    <AddGlyph glyph="export" />
    Export for New Customer Pricing
  </ToolButton>
);

class Profit extends React.Component {
  state = {
    show: false,
    customer: null,
    searchText: ""
  };

  componentDidMount(next) {
    this.props.getCustomers();
  }

  renderField(customer, type, excel = 0) {
    if (customer.dumpsters) {
      if (excel) {
        let rlt = ''
        customer.dumpsters.map((item, index) => {
          rlt += item[type]
          rlt += `\n`
          return true
        })
        return rlt
      } else {
        return (
          customer.dumpsters.map((item, index) => {
            return (
              <li key={index}>
                {item[type]}
              </li>
            )
          })
        )
      }
    } 
    return ''
  }

  render() {
    if (this.props.authorized) {
      return (
        <CustomersWrapper>
          <Header>
            <ExcelFile element={ExportButton} filename={`Residential Customer Pricing ` + moment().format('L')}>
                <ExcelSheet data={this.props.customers} name="Residential Customer Pricing">
                    <ExcelColumn label="Customer" value="name"/>
                    <ExcelColumn label="Account #" value="Account Number"/>
                    <ExcelColumn label="Rate" value="rate"/>
                </ExcelSheet>
            </ExcelFile>
            <ExcelFile element={ExportButton2} filename={`New RES Customer Pricing ` + moment().format('L')}>
                <ExcelSheet data={this.props.customers} name="Residential Customer Pricing">
                    <ExcelColumn label="Account #" value="Account Number"/>
                    <ExcelColumn label="Customer" value="name"/>
                    <ExcelColumn label="Rate" value="rate"/>
                    <ExcelColumn label="NEW RATE **(THIS MUST BE FILLED OUT)**" value=""/>
                </ExcelSheet>
            </ExcelFile>
          </Header>

          <Table hover bordered>
            <thead>
              <tr>
                <th>Customer</th>
                <th>Account #</th>
                <th>Rate</th>
              </tr>
            </thead>
            <tbody>
              {_.map(this.props.customers, customer => {
                return (
                  <tr key={customer.uid} onClick={() => this.props.setCustomer(customer.uid)}>
                    <td>{customer.name}</td>
                    <td>{customer["Account Number"]}</td>
                    <td>{customer.rate}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CustomersWrapper>
      );
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = state => ({
  customers: state.residential.customers,
  authorized: state.user?.pages?.customers
});

const mapDispatchToProps = dispatch => ({
  setCustomer: uid => dispatch(ResidentialCustomersActions.setCustomer(uid)),
  getCustomers: () => dispatch(ResidentialCustomersActions.getCustomers())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profit);
