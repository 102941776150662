/* @format - for Prettier */
import _ from 'lodash';
import {
  db
} from '../firebase';
import {
  ApiService
} from '../api/apiService';
import axiosInstance from '../api/axios';
import { _sortBy } from '../utils/customer';

export const setUsers = users => ({
  type: 'SET_USERS',
  users,
});

export const setUser = user => ({
  type: 'SET_USER',
  user,
});

/* Gets all the users in the database */
export const getUsers = () => {
  return function (dispatch) {
    ApiService.readAll('users')
      .then(data => {
        if (data){
          console.log('data in getUsers', data);
          const users = _sortBy(data.users, ['first_name']);
          dispatch(setUsers(users || []));
        }
      })
      .catch(error => console.log('error in getUsers', error));
  };
};

export const saveUser = user => {
  return function (dispatch) {
    ApiService.create('users', {user})
      .then(res => {
        if(res && res.success)
          dispatch(getUsers());
      })
      .catch(error => console.log('error in saveUser', error));
  };
};

export const saveUsers = (values, touched) => {
  return function (dispatch) {
    const updated = [];
    console.log('values', values);
    console.log('touched', touched);
    _.forEach(values.users, (user, i) => {
      let isUpdated = false;
      _.forEach(touched.users[i], (val, key) => {
        if (key === 'pages') {
          _.forEach(val, (value, page) => {
            if (value.touched && value.visited) {
              isUpdated = true;
            }
          }); 
        } else if (val.touched) {
          isUpdated = true;
        }
      });
      if (isUpdated) {
        updated.push(user);
      }
    });
    console.log('updated', updated);
    axiosInstance.post('/api/users/update/many', {users: updated})
      .then(() => {
        dispatch(getUsers());
      })
      .catch(error => console.log('error in save users', error));
  };
};

export const removeUser = userId => {
  return function (dispatch) {
    ApiService.delete('users', userId)
      .then((res) => {
          dispatch(getUsers());
      })
      .catch(error => console.log('error in delete user', error));
  };
};