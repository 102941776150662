/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {Table, Button, Glyphicon} from 'react-bootstrap';

const Wrapper = styled.div`
  margin: 1vh 23vw 0vh 23vw;
`;

const GlyphButton = styled(Button)`
  width: 40px;
  border: none;
`;

const GlyphTd = styled.td`
  width: 91px;
`;

const GlyphTh = styled.th`
  width: 91px;
`;

var start = 0;
const StopReorder = ({stops, move}) => (
  <Wrapper>
    <Table responsive condensed hover bordered>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <GlyphTh>Move</GlyphTh>
        </tr>
      </thead>
      <tbody>
        {_.map(stops, (stop, i) => {
          return (
            <tr key={i}>
              <td> {i + 1} </td>
              <td draggable="true" onDragOver={() => move(stop.uid, i - start)} onClick={() => start = i}> {stop.name}</td>
              <GlyphTd>
                <GlyphButton onClick={() => move(stop.uid, -1)}>
                  <Glyphicon glyph="menu-up" />
                </GlyphButton>
                <GlyphButton onClick={() => move(stop.uid, 1)}>
                  <Glyphicon glyph="menu-down" />
                </GlyphButton>
              </GlyphTd>
            </tr>
          );
        })}
      </tbody>
    </Table>
  </Wrapper>
);

export default StopReorder;