/* @format - for Prettier */
import { applyMiddleware, createStore, combineReducers, compose } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import thunkMiddleware from "redux-thunk";
import newcastle from "./newcastle";
import residential from "./residential";
import greenBag from "./greenBag";
import jobSubmissions from "./jobSubmissions";
import westmayfield from "./westmayfield";
import eastbutler from "./eastbutler";
import slipperyrock from "./slipperyrock";
import newcastle2 from "./newcastle2";
const users = (state = [], action) => {
  switch (action.type) {
    case "SET_USERS":
      return action.users;
    default:
      return state;
  }
};

const user = (state = {}, action) => {
  switch (action.type) {
    case "SET_USER":
      return action.user;
    default:
      return state;
  }
};

const customers = (state = [], action) => {
  switch (action.type) {
    case "SET_CUSTOMERS":
      return action.customers;
    default:
      return state;
  }
};

const closedAccounts = (state = {}, action) => {
  switch (action.type) {
    case "SET_CLOSED_ACCOUNTS":
      return action.customers;
    default:
      return state;
  }
};

const current_billing_quarter = (state = {
  value: "1st Quarter",
  label: "1st Quarter"
}, action) => {
  switch (action.type) {
    case "SET_CURRENT_BILLING_QUARTER":
      return action.quarter;
    default:
      return state;
  }
}

const current_billing_year = (state = {
  value: "2020",
  label: "2020"
}, action) => {
  switch (action.type) {
    case "SET_CURRENT_BILLING_YEAR":
      return action.year;
    default:
      return state;
  }
}



const pastDueCustomers = (state = {}, action) => {
  switch (action.type) {
    case "SET_PAST_DUE_CUSTOMERS":
      return action.pastDueCustomers;
    default:
      return state;
  }
};

const expiringCustomers = (state = {}, action) => {
  switch (action.type) {
    case "SET_EXPIRING_CUSTOMERS":
      return action.expiringCustomers;
    default:
      return state;
  }
};

const invoices = (state = {}, action) => {
  switch (action.type) {
    case "SET_INVOICES":
      return action.invoices;
    default:
      return state;
  }
};

const currentCustomer = (state = {}, action) => {
  switch (action.type) {
    case "SET_CURRENT_CUSTOMER":
      return action.customer;
    default:
      return state;
  }
};

const routes = (state = [], action) => {
  switch (action.type) {
    case "SET_ROUTES":
      return action.routes;
    default:
      return state;
  }
};

const currentRoute = (state = {}, action) => {
  switch (action.type) {
    case "SET_CURRENT_ROUTE":
      return action.route;
    default:
      return state;
  }
};

const currentRouteUid = (state = {}, action) => {
  switch (action.type) {
    case "SET_CURRENT_ROUTE_UID":
      return action.uid;
    default:
      return state;
  }
};

const notes = (state = {}, action) => {
  switch (action.type) {
    case "SET_NOTES":
      return action.notes;
    default:
      return state;
  }
};

const customerList = (state = {}, action) => {
  switch (action.type) {
    case "SET_CUSTOMER_LIST":
      return action.customers;
    default:
      return state;
  }
};
const dumpsterAddresses = (state = {}, action) => {
  switch (action.type) {
    case "SET_DUMSPTER_ADDRESSES":
      return action.addresses;
    default:
      return state;
  }
};

const reducer = combineReducers({
  users,
  user,
  customers,
  closedAccounts,
  pastDueCustomers,
  expiringCustomers,
  invoices,
  currentCustomer,
  routes,
  currentRoute,
  currentRouteUid,
  notes,
  customerList,
  dumpsterAddresses,
  newcastle,
  residential,
  greenBag,
  jobSubmissions,
  westmayfield,
  eastbutler,
  slipperyrock,
  newcastle2,
  current_billing_quarter,
  current_billing_year,
  form: reduxFormReducer // mounted under "form"
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;
