/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import styled from 'styled-components';
import {reduxForm} from 'redux-form';
import {NewCastleCustomers2} from '../../actions2';
import {CustomerData, CustomerForm, Header} from './components';
import {DeniedWrapper} from '../../styled';

const CustomerWrapper = styled.div`
  margin: 2vh 12vw 0vh 12vw;
`;

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'view',
      credit_card: {
        number: '',
        expiry: '',
        cvc: '',
      },
      card_touched: false,
    };
  }

  componentDidMount() {
    this.setState({credit_card: this.props.credit_card});
  }
  changeMode = mode => {
    this.props.reset();
    this.setState({mode});
  };

  save = formValues => {
    const {phone_numbers, uid} = this.props.customer;
    let values = {};
    if (this.state.card_touched) {
      values.credit_card = {};
      _.forEach(this.state.credit_card, (el, key) => {
        values.credit_card[key] = el;
      });
    }
    _.forEach(this.props.fields, (value, key) => {
      if (
        key === 'service_address' ||
        key === 'dumpster' ||
        key === 'billing_address'
      ) {
        _.forEach(this.props.fields[key], value => {
          if (value !== undefined && value.touched) {
            values[key] = formValues[key];
          }
        });
      } else if (key === 'phone_numbers') {
        values.phone_numbers = formValues.phone_numbers.slice();
      } else {
        if (value.touched) {
          values[key] = formValues[key];
        }
      }
    });
    if (
      phone_numbers &&
      phone_numbers.length > formValues.phone_numbers.length
    ) {
      values.phone_numbers = formValues.phone_numbers;
    }
    if (formValues.same) {
      values.billing_address = formValues.service_address;
    }
    this.props.saveCustomer(values, uid, formValues.contact_name);
    this.changeMode('view');
  };

  handleCard = (value, type) => {
    let credit_card = Object.assign({}, this.state.credit_card);
    credit_card[type] = value;
    this.setState({credit_card, card_touched: true});
  };

  render() {
    const {mode, credit_card} = this.state;
    if (this.props.authorized) {
      if(!this.props.customer?.name) {
        return <Redirect to="/NewCastle2/Customers" />;
      }
      if (mode === 'view') {
        return (
          <CustomerWrapper>
            {/* <Header type="view" changeMode={this.changeMode} /> */}
            <CustomerData addInfo={this.addInfo} addAddress={this.addAddress} changeMode={this.changeMode} />
          </CustomerWrapper>
        );
      } else if (mode === 'edit') {
        //if closed account don't let user edit
        if(this.props.customer.isClosed !== undefined && this.props.customer.isClosed !== null && this.props.customer.isClosed === true){
          alert("This account is closed. If you want to edit it, please reactivate the account first.");
          return <Redirect to="/ClosedAccounts" />;
        }
        else{
          /* Allows the Route to be editted */
          return (
            <CustomerWrapper>
              <form onSubmit={this.props.handleSubmit(this.save)}>
                <Header
                  type="form"
                  changeMode={this.changeMode}
                  submitting={this.props.submitting}
                />
                <CustomerForm
                  checked={this.props.checked}
                  credit_card={credit_card}
                  handleCard={this.handleCard}
                  meta={this.props}
                />
              </form>
            </CustomerWrapper>
          );
        }
      } else if (mode === 'back') {
        if(this.props.customer.isClosed === undefined || this.props.customer.isClosed === null || this.props.customer.isClosed === false)
          return <Redirect to="/NewCastle2/Customers" />;
        else
          return <Redirect to="/ClosedAccounts" />;
      } else if (mode === 'past_due') {
        return <Redirect to="/Reports/due_accounts" />;
      } else {
        return <CustomerWrapper> Customer Not Found </CustomerWrapper>;
      }
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = state => ({
  authorized: state.user?.pages?.customers,
  fields: _.get(state, "form.NewCastleCustomerForm.fields"),
  customer: state.newcastle.customer,
  checked: state.newcastle.same,
  credit_card: state.newcastle.customer.credit_card,
});

const mapDispatchToProps = dispatch => ({
  saveCustomer: (customer, customerId, name) =>
  dispatch(NewCastleCustomers2.saveCustomer(customer, customerId))
    //  dispatch(NewCastleCustomers.saveNewCastleCustomer(customer, customerId))
});

Customer = reduxForm({
  form: "NewCastleCustomerForm",
  enableReinitialize: true,
  touchOnChange: true,
  touchOnBlur: false
})(Customer);

Customer = connect(state => ({
  initialValues: state.newcastle.customer
}))(Customer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Customer);
