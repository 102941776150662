/* @format - for Prettier */
import _ from 'lodash';
import moment from 'moment';
import {
  db,
  storage
} from '../firebase';
import * as firebase from '../firebase/firebase';
import {
  history
} from '../App';
import {
  ApiService
} from '../api/apiService';
import { CustomerTypes } from '../utils/customer';
import axiosInstance from '../api/axios';

export const setRoutes = routes => ({
  type: 'SET_ROUTES',
  routes,
});

export const setCurrentRoute = route => ({
  type: 'SET_CURRENT_ROUTE',
  route,
});

export const setCurrentRouteUid = uid => ({
  type: 'SET_CURRENT_ROUTE_UID',
  uid,
});

export const setNotes = (notes, photos, stopId) => ({
  type: 'SET_NOTES',
  notes: {
    notes,
    photos,
    stopId,
  },
});
export const setCustomerList = (customers) => ({
  type: 'SET_CUSTOMER_LIST',
  customers,
});
export const setDumspterAddresses = (addresses) => ({
  type: 'SET_DUMSPTER_ADDRESSES',
  addresses,
});



/* Gets all the routes in the database */
export const getRoutes = () => {
  return function (dispatch) {
    ApiService.readAll('routes')
      .then((data) => {
        if(!data) return;
        console.log('data in getRoutes', data);
        const routes = _.orderBy(data.routes, ["priority"], ["asc"]);
        // const sortByloadSide = routes.slice(0);
        // sortByloadSide.sort((a, b) => {
        //   let x = a.load_side.toLowerCase();
        //   let y = b.load_side.toLowerCase();
        //   return x < y ? -1 : x > y ? 1 : 0;
        // });
        dispatch(setRoutes(routes));
      })
  };
};

/* Gets all the routes in the database */
export const getCustomerList = () => {
  return function (dispatch) {
      axiosInstance.post(`/api/customers/all/fields`, {
        fields: ['customers.uid', 'name', 'billing_address', 'service_address']
      })
      .then((res) => {
        if(!res.data) return;
        let customers = res.data.customers;
        console.log('data in getCustomerList', customers);
        // for (let i = 0; i < customers.length; i += 1) {
        //   let customer = customers[i]
        //   let addresses = []
        //   if (customer.billing_address !== undefined) {
        //     if (checkExisting(addresses, customer.billing_address) === -1)
        //       addresses.push(customer.billing_address)
        //   }
        //   if (customer.service_address !== undefined) {
        //     if (checkExisting(addresses, customer.service_address) === -1)
        //       addresses.push(customer.service_address)
        //   }
          // if (customer.dumpster_addresses !== undefined) {
          //   for (let j = 0; j < customer.dumpster_addresses.length; j += 1) {
          //     if (checkExisting(addresses, customer.dumpster_addresses[j]) === -1)
          //       addresses.push(customer.dumpster_addresses[j])
          //   }
          // }
        //   customers[i]['addresses'] = addresses
        // }
        dispatch(setCustomerList(customers));
      })
      .catch((error) => {
        console.log('error in getCustomerList', error);
      });
  };
};

/* Gets all the routes in the database */
export const getDumpsterAddresseses = () => {
  return function (dispatch) {
      axiosInstance.get(`/api/customers/dumpster_address/all`)
      .then((res) => {
        if(!res.data) return;
        let addresses = res.data.addresses;
        console.log('data in getDumpsterAddresseses', addresses);
        dispatch(setDumspterAddresses(addresses));
      })
      .catch((error) => {
        console.log('error in getCustomerList', error);
      });
  };
};

/* Gets a route with stop objects included    */
/* (id) is the route id                        */
/* note this function only needs the route id */
/* to get the whole route object with stops   */
// export const getRoute = (id) => {
//   return function (dispatch) {
//     db.readRoute(id).then((db_route) => {
//       let route = Object.assign({}, db_route.val());
//       route.uid = id;
//       const stopPromises = _.map(route.stops, (stopId) => db.readStop(stopId));
//       Promise.all(stopPromises).then((res) => {
//         route.stops = _.map(res, (snapshot) => snapshot.val());
//         route.stops = _.compact(route.stops);
//         dispatch(setCurrentRoute(route));
//         dispatch(setCurrentRouteUid(id));
//         history.push("/Route");
//       });
//     });
//   };
// };

export const getRoute = (id) => {
  return function (dispatch) {
    ApiService.readOne('routes', id).then((data) => {
      if(!data) return;
      let route = data.route;
      dispatch(setCurrentRoute(route));
      dispatch(setCurrentRouteUid(id));
      history.push("/Route");
    });
  };
};


/* Stores a route in the database                     */
/* (route) is the whole route object including stops  */
/* (touched) is data from redux forms of which field  */
/* of the route was changed                           */
export const saveRoute = (route, touched, priority) => {
  return function (dispatch) {
    // Set touched stops to all the stops, to make sure all of them
    // are updated. Added for StopForm to update order of stops
    console.log('route', route, 'touched', touched, 'priority', priority);
    const stops = route.stops.slice();
    if (touched) touched.stops = stops;

    let updates = { 
      ...route,
      priority: priority
     };

    for (let key in touched) {
      if (key === "stops") {
        const updatedStops = [];
        touched.stops.forEach((stop, i) => {
          if (!stops[i].uid) {
            const newStop = { ...stops[i] };
            newStop["added_timestamp"] = moment().format("llll");
            newStop["notes"] = "";
            newStop["last_picked_up_timestamp"] = "";
            newStop["route_id"] = route.uid;
            newStop["customer_id"] = stops[i].customer.id;
            updatedStops.push(newStop);
          } else if (stop !== undefined) {
            updatedStops.push(stops[i]);
          }
        });
        updates['stops'] = updatedStops;
      } else {
        updates[key] = route[key];
      }
    }
    ApiService.update('routes', route.uid, { route: updates });
    dispatch(setCurrentRoute(route));
    dispatch(setCurrentRouteUid(route.uid));
    history.push("/Route");
  };
};

// testing
// export const saveRoutes = (routes) => {
//     return function (dispatch) {
//         let updates = {};
//         Object.values(routes).forEach((route) => updates[`/routes/${route.uid}`] = route)
//         db.doUpdate(updates);
//     };
// };

export const saveRoutes = (routes) => {
  return function (dispatch) {
    for (const route of routes) {
      const newRoute = {
        name: route.name,
        status: route.status,
        load_side: route.load_side,
        priority: route.priority,
      }
      ApiService.update('routes', route.uid, { route: newRoute });    
    }
    dispatch(setRoutes(routes));
  };
};


export const resetStops = (stops) => {
  return function (dispatch) {
    let updates = {};
    // let priority = routes.length;
    _.forEach(stops, stop => {
      if (stop === undefined || stop === null) {
        console.log('stop is null: ', stop)
      } else {
        if (stop.status !== 'suspended') {
          ApiService.update('stops', stop.uid, { stop: { status: 'pending' } });
        }
      }
    });
    history.push("/Routes");
  };
};

// testing
// export const addRoute = () => {
//     return function (dispatch) {
//         const id = db.doAddRoute();
//         let data = {
//             name: 'New Route',
//             stops: [],
//             status: 'In Progress',
//             uid: id,
//             load_side: 'FL',
//         };
//         dispatch(setCurrentRoute(data));
//         dispatch(setCurrentRouteUid(id))
//         history.push('/Route');
//     };
// };

export const addRoute = (priority) => {
  return function (dispatch) {
    const data = {
      name: "New Route",
      status: "In Progress",
      load_side: "FL",
      priority: priority,
    }
    ApiService.create('routes', { route: data }).then((data) => {
      if (data && data.route) {
        dispatch(setCurrentRoute(data.route));
        dispatch(setCurrentRouteUid(data.route.uid));
        history.push("/Route");
      }
    });
  };
};

export const removeRoute = (route) => {
  return function (dispatch) {
    ApiService.delete('routes', route.uid).then(() => {
      dispatch(getRoutes());
      history.push("/Routes");
    });
  };
};

/* Saves the stops in the new order in the route object  */
/* (stop) is a array of stop objects                     */
/* (route) is a route object                             */
export const reorderStop = (stops, route) => {
  return function (dispatch) {
    route.stops = stops.slice();
    let updates = [];
    for (let i = 0; i < stops.length; i++) {
      updates.push({ uid: stops[i].uid, index: i })
    }
    console.log("reorderStop: updates", updates);
    axiosInstance.post(`/api/stops/update/many`, { stops: updates, column: 'index' })
    .then((res) => {
      if(res.status === 200) 
        dispatch(setCurrentRoute(route));
    })
    .catch((error) => {
      console.log("error in reorder stop", error);
    });
  };
};

export const saveStopsToRoutes = (stops, routes) => {
  return async function (dispatch) {
    console.log("stops", stops);
    console.log("routes", routes);
    let stop = stops[0];
    stop["added_timestamp"] = moment().format("llll");
    stop["notes"] = "";
    stop["last_picked_up_timestamp"] = "";
    stop["customer_id"] = stop.customer.id;

    const resp = await ApiService.readAll('customers', null, CustomerTypes.greenbag);
    const customers = resp.customers || [];
    const customer = customers.find((customer) => customer.uid === stop.customer.uid);
    if (customer) {
      stop["name"] = stop["name"] + " [GREEN BAG]";
    }
    const route_ids = routes.map(route => route.uid);
    console.log("stop", stop, "route_ids", route_ids);
    axiosInstance.post('api/stops/add/many',{ stops: [stop], route_ids })
    .then((res) => {
      if(res.status === 201)
        dispatch(getRoutes());
    });
  };
};

export const saveStopToRoute = (stops, routeId) => {
  return async function (dispatch) {
    let stop = stops[0];
    stop["added_timestamp"] = moment().format("llll");
    stop["notes"] = "";
    stop["last_picked_up_timestamp"] = "";
    stop["route_id"] = routeId;
    stop["customer_id"] = stop.customer.id;
    const resp = await ApiService.readAll('customers', null, CustomerTypes.greenbag);
    const customers = resp.customers || [];
    const customer = customers.find((customer) => customer.uid === stop.customer.uid);
    if (customer) {
      stop["name"] = stop["name"] + " [GREEN BAG]";
    }
    ApiService.create('stops', { stop }).then((data) => {
      if(data && data.stop) 
        ApiService.readOne('routes', routeId).then((data) => {
          if (data && data.route) {
            dispatch(setCurrentRoute(data.route));
          }
        });
    });
  };
};

export const moveStops = (routes, stops, currentRouteId) => {
  return async function (dispatch) {
    console.log(routes, stops);
    const route_ids = routes.map(route => route.uid);
    const _stops = stops.map(stop => ({
      ...stop,
      added_timestamp: moment().format("llll"),
      customer_id: stop.customer.uid,
    }));
    console.log("stops", _stops);
    axiosInstance.post('api/stops/add/many', {
        stops: _stops,
        route_ids: route_ids
      })
      .then((res) => {
        if (res.status === 201){
          ApiService.readOne('routes', currentRouteId).then((data) => {
            if (data && data.route) {
              dispatch(setCurrentRoute(data.route));
            }
          });
          // dispatch(getRoutes());
        }
      });
  }
};

/* Removes the selected Stop                  */
/* (stopId) is the id of the stop to delete   */
/* (index) is the position the stopId in the  */
/* stops array on the route                   */
/* (routeId) is the id of the route           */

export const fixRouteStops = () => {
  return function (dispatch) {
    let updates = {};
    db.readRoutes().then(async (snapshot) => {
      const routes = _.orderBy(snapshot.val(), ["priority"], ["asc"]);

      for (var routeId in routes) {
        console.log("Route Id = ", routeId, routes[routeId].name);
        let newStops = [];
        const stops = routes[routeId].stops;
        const stopPromises = _.map(stops, (stop) => db.readStop(stop));
        const snapshots = await Promise.all(stopPromises);
        for (let i = 0; i < snapshots.length; i += 1) {
          const snapshot = snapshots[i];
          const key = snapshot.key;
          let stop = await snapshot.val();
          if (stop === null) {
            console.log("Stop Id = ", key);
          } else {
            if (stop.uid === undefined) {
              console.log("Making Uid for stop key = ", key);
              stop.uid = key;
              updates[`/stops/${key}`] = stop;
            }
            newStops.push(key);
          }
        }
        updates[`/routes/${routeId}/stops`] = newStops;
      }
      db.doUpdate(updates)
        .then(() => {
          console.log("Successfully Done in Clean Up Routes And Stops");
        })
        .catch((error) => {
          console.log("Error in Clean Up Routes And Stops", error);
        });
    });
  };
};

export const removeStop = (stopId, routeId) => {
  return function (dispatch) {
    ApiService.delete('stops', stopId).then((deleted) => {
      if(deleted)
        ApiService.readOne('routes', routeId).then((data) => {
          if (!data) return;
          let route = data.route;
          dispatch(setCurrentRoute(route));
        });
    });
  };
};

/* Saves the notes for this stop and stores the photos         */
/* (notes) is the text in the note object                      */
/* (newPhotos) is the photos to add to the database            */
/* (oldPhotos) is photos already in the database for this stop */
/* (stopId) is the id of this stop                             */
export const saveNotes = (notes, newPhotos, oldPhotos, stopId) => {
  return function (dispatch) {
    ApiService.update('stops', stopId, { stop: { notes } });
  };
};

/* Deletes a photo from the storage on firebase */
/* (photo) is the URL to delete                 */
/* (stopId) is the id of the stop                 */
export const removePhoto = (photo, stopId) => {
  return function (dispatch) {
    storage
      .deletePhoto(photo, stopId)
      .catch((error) => console.log("error in remove photo", error));
  };
};

export const fixDuplicateStopReferences = () => {
  console.log("start fixing duplicate stops");
  return function (dispatch) {
    let iteratedStopIds = [];
    let updates = {};

    db.readStops()
      .then((stopsData) => {
        const allStops = Object.values(stopsData.val());
        db.readRoutes()
          .then((routesData) => {
            const routesVal = Object.values(routesData.val());
            const routes = _.orderBy(routesVal, ["priority"], ["asc"]);
            routes.forEach((route) => {
              let routeStopIds = route.stops;
              if (routeStopIds) {
                routeStopIds.forEach((stopId) => {
                  // UID is not used somewhere else
                  if (!iteratedStopIds.find((d) => d == stopId)) {
                    iteratedStopIds.push(stopId);
                  }
                  // UID is already used somewhere else
                  else {
                    console.log(
                      "duplicate stop found id: " +
                      stopId +
                      " in route id: " +
                      route.uid
                    );

                    // Remove current reference from route
                    const stopIndex = routeStopIds.indexOf(stopId);
                    const stop = allStops.find((d) => d.uid == stopId);

                    if (!stop) console.error("Stop not found. UID:", stopId);
                    else {
                      const key = firebase.db.ref().child("stops").push().key;
                      //stop.uid = key;
                      updates[`/routes/${route.uid}/stops/${stopIndex}`] = key;
                      updates[`/stops/${key}`] = {
                        added_timestamp: stop.added_timestamp,
                        address: stop.address,
                        coordinates: stop.coordinates,
                        customer: stop.customer,
                        last_picked_up_timestamp: stop.last_picked_up_timestamp,
                        name: stop.name,
                        status: stop.status,
                        uid: key,
                      };
                    }

                    //stop.uid = stopId;
                  }
                });
              }
            });
            console.log("updates", Object.keys(updates).length);
            console.log(updates);
            db.doUpdate(updates)
              .then(() => {
                console.log("updates stops in all routes", updates);
                console.log(
                  `total ${
                    Object.keys(updates).length
                  } updates done in fixDuplicateStopReferences`
                );
                console.log(`iterated stopds: ${iteratedStopIds.length}`);
              })
              .catch((error) => {
                console.log(
                  "error while updating stops in fixDuplicateStopReferences",
                  error
                );
              });
          })
          .catch((error) => console.log("error while reading routes", error));
      })
      .catch((error) => console.log("error while reading stops", error));
  };
};

export const setRoutesOrder = (routes) => {
  return function (dispatch) {
    var i = 0;
    routes.forEach((route) => {
      route.priority = i;
      i++;
    });
    dispatch(saveRoutes(routes));
  };
};