/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {Table, Button, Glyphicon} from 'react-bootstrap';

const Wrapper = styled.div`
  margin: 1vh 23vw 0vh 23vw;
`;

const GlyphButton = styled(Button)`
  width: 100%;
  color: red !important;
  border: none;
`;

const GlyphTd = styled.td`
  width: 50px;
`;

const GlyphTh = styled.th`
  width: 50px;
`;

const StopRemove = ({stops, remove}) => (
  <Wrapper>
    <Table responsive condensed hover bordered>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Address</th>
          <GlyphTh>Delete</GlyphTh>
        </tr>
      </thead>
      <tbody>
        {_.map(stops, (stop, i) => {
          return (
            <tr key={i}>
              <td> {i + 1} </td>
              <td> {stop.name} </td>
              <td> {stop.address.street + `, ` + stop.address.city + `, ` + stop.address.state + ` ` + stop.address.zipcode}</td>
              <GlyphTd>
                <GlyphButton onClick={() => remove(stop.uid)}>
                  <Glyphicon glyph="remove" />
                </GlyphButton>
              </GlyphTd>
            </tr>
          );
        })}
      </tbody>
    </Table>
  </Wrapper>
);
export default StopRemove;
