/* @format - for Prettier */
import React from 'react'
import styled from 'styled-components'
import CreditCardInput from 'react-credit-card-input'
import { Field, FieldArray } from 'redux-form'

import { Grid, Row, Col, Button, Glyphicon } from 'react-bootstrap'

import {
  renderField,
  renderState,
  renderRate,
  renderNotes,
  renderPhoneNumber,
  normalizePhone,
  required,
  number,
  renderExemptField,
  renderBillFrequencyField,
  renderExpirationMonth,
  renderExpirationDay,
  renderExpirationYear,
  email,
  renderTextAreaField
} from '../../../renderComponents'
import { ErrorText } from '../../../styled'

const StyledGrid = styled(Grid)`
  padding: 0px;
`

const StyledRow = styled(Row)`
  margin-bottom: 7px !important;
`

const StyledCol = styled(Col)`
  padding: 0px;
`

const GlyphButton = styled(Button)`
  width: 40px;
  color: red !important;
  border: none;
`

const AddButton = styled(Button)`
  width: 130px;
`

const AddAddressButton = styled(Button)`
  width: 200px;
`

const StyledSelect = styled.select`
  width: 200px;
  height: 26px;
  margin-left: 0px;
`

const StyledInput = styled.input`
  width: 200px;
`

const Checkbox = styled.div`
  display: inline-block;
  margin-left: 10px;
`

const renderSmall = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledInput {...input} type={type} placeholder="" />
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
)

const renderSelect = ({
  input,
  label,
  type,
  meta: { touched, error },
  children
}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledSelect {...input}>{children}</StyledSelect>
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
)

const renderDumpsters = ({
  fields,
  dumpster_addresses,
  add_key,
  changeAddKey,
  meta: { error }
}) => {
  if (add_key === 'info') {
    changeAddKey()
    setTimeout(() => {
      fields.push('')
    }, 500)
  }
  return (
    <StyledRow>
      {fields.map((dumpster, index) => (
        <StyledRow key={index}>
          <StyledRow>
            <label>Dumpster Info {index + 1}:</label>
          </StyledRow>
          <StyledRow>
            <Field
              name={`${dumpster}.number`}
              label="Number:"
              type="text"
              component={renderSmall}
              validate={[required, number]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name={`${dumpster}.type`}
              label="Type:"
              type="text"
              component={renderSmall}
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name={`${dumpster}.frequency`}
              label="Frequency:"
              type="text"
              component={renderSmall}
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              label="Dumpster Address:"
              name={`${dumpster}.address`}
              component={renderSelect}
            >
              {dumpster_addresses &&
                dumpster_addresses.map((dumpster_address, index) => (
                  <option value={Number(index)} key={index}>
                    {`${dumpster_address.street} ${dumpster_address.city}, ${dumpster_address.state} ${dumpster_address.zipcode}`}
                  </option>
                ))}
            </Field>
          </StyledRow>
          <GlyphButton type="button" onClick={() => fields.remove(index)}>
            <Glyphicon glyph="remove" />
          </GlyphButton>
        </StyledRow>
      ))}
      <AddAddressButton onClick={() => fields.push('')} bsStyle="primary">
        + Add Dumpster Info
      </AddAddressButton>
    </StyledRow>
  )
}

const renderDumpsterAddresses = ({
  fields,
  add_key,
  changeAddKey,
  meta: { error }
}) => {
  if (add_key === 'address') {
    changeAddKey()
    setTimeout(() => {
      fields.push('')
    }, 500)
  }
  return (
    <StyledRow>
      {fields.map((dumpster_address, index) => (
        <StyledRow key={index}>
          <StyledRow>
            <label>Dumpster Address {index + 1}:</label>
          </StyledRow>
          <StyledRow>
            <Field
              name={`${dumpster_address}.street`}
              type="text"
              component={renderField}
              label="Street:"
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name={`${dumpster_address}.city`}
              type="text"
              component={renderField}
              label="City:"
              className="large"
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <StyledCol sm={4} md={4}>
              <Field
                name={`${dumpster_address}.state`}
                type="text"
                component={renderState}
                label="State:"
                validate={[required]}
                className="State"
              />
            </StyledCol>
            <StyledCol sm={5} md={5}>
              <Field
                name={`${dumpster_address}.zipcode`}
                type="text"
                component={renderField}
                label="Zipcode:"
                validate={[required]}
                className="Zipcode"
              />
            </StyledCol>
          </StyledRow>
          <GlyphButton type="button" onClick={() => fields.remove(index)}>
            <Glyphicon glyph="remove" />
          </GlyphButton>
        </StyledRow>
      ))}
      <AddAddressButton onClick={() => fields.push('')} bsStyle="primary">
        + Add Dumpster Address
      </AddAddressButton>
    </StyledRow>
  )
}

const renderPhoneNumbers = ({ fields, meta: { error } }) => (
  <StyledRow>
    {fields.map((phone_number, index) => (
      <StyledRow key={index}>
        <Field
          name={`${
            fields.get(index) && fields.get(index).number
              ? `${phone_number}.number`
              : `${phone_number}.number`
          }`}
          component={renderPhoneNumber}
          type="text"
          normalize={normalizePhone}
          validate={[required]}
        />
        <Field name={`${phone_number}.type`} component={renderSelect}>
          <option value="Business">Business</option>
          <option value="Mobile">Mobile</option>
          <option value="Home">Home</option>
          <option value="Fax">Fax</option>
        </Field>
        <GlyphButton type="button" onClick={() => fields.remove(index)}>
          <Glyphicon glyph="remove" />
        </GlyphButton>
      </StyledRow>
    ))}
    <AddButton onClick={() => fields.push('')} bsStyle="primary">
      + Add Number
    </AddButton>
  </StyledRow>
)
const CustomerForm = ({

  checked,
  credit_card,
  handleCard,
  customer,
  add_key,
  changeAddKey,
  isAutoRenewed,
  meta: { error, submitFailed }
}) => (
  <div className="customer_information_edit">
    <StyledGrid>
      <StyledRow>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Customer Information:</h4>
          </StyledRow>
          <StyledRow>
            <Field
              name="name"
              label="Name:"
              type="text"
              component={renderField}
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="Account Number"
              label="Account Number:"
              type="text"
              component={renderField}
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="recurringCustomer"
              label="Recurring Customer:"
              type="text"
              component={renderExemptField}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="isExempt"
              label="Is Exempt:"
              type="text"
              component={renderExemptField}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="isInactive"
              label="Is Inactive:"
              type="text"
              component={renderExemptField}
            />
          </StyledRow>

          <StyledRow>
            <Field
              name="billFrequency"
              label="Bill Frequency:"
              type="text"
              component={renderBillFrequencyField}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="wantsEInvoice"
              label="Wants E-Invoice?"
              type="text"
              component={renderExemptField}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="rate"
              label="Rate:"
              type="text"
              component={renderRate}
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="Contract Term Length"
              label="Contract Term Length:"
              type="text"
              component={renderField}
              validate={[required]}
            />
          </StyledRow>
          <StyledRow className={checked}>
                <label>Auto-Renew : </label>
                {/* <input type="checkbox" name="isAutoRenewed" /> */}
                 <Checkbox>
                  <Field name="isAutoRenewed"
                   component="input" 
                   type="checkbox"
                   onChange={e=>(e.target.checked=="true")? "true" : "false"}
                   />
                </Checkbox> 
              </StyledRow>
          <StyledRow>
            <h4>Expiration Date: </h4>
          </StyledRow>
          <StyledRow>
            <Field
              name="expirationMonth"
              label="Month:"
              type="text"
              component={renderExpirationMonth}
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="expirationDay"
              label="Day:"
              type="text"
              component={renderExpirationDay}
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="expirationYear"
              label="Year:"
              type="text"
              component={renderExpirationYear}
              validate={[required]}
            />
          </StyledRow>
        </StyledCol>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Contact Information: </h4>
          </StyledRow>
          <StyledRow>
            <Field
              name="contact_name"
              label="Name:"
              type="text"
              component={renderField}
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="email"
              label="Email:"
              type="text"
              component={renderField}
              validate={[email]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="otherContactNames"
              label="Other Contact Names:"
              type="text"
              component={renderTextAreaField}
            />
          </StyledRow>
        </StyledCol>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Phone Numbers:</h4>
          </StyledRow>
          <StyledRow>
            <FieldArray name="phone_numbers" component={renderPhoneNumbers} />
          </StyledRow>
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Dumpster Address:</h4>
          </StyledRow>
          <StyledRow>
            <FieldArray
              changeAddKey={changeAddKey}
              add_key={add_key}
              name="dumpster_addresses"
              component={renderDumpsterAddresses}
            />
          </StyledRow>
        </StyledCol>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Billing Address:</h4>
          </StyledRow>
          <StyledRow>
            <Field
              name={`billing_address.street`}
              type="text"
              component={renderField}
              label="Street:"
            />
          </StyledRow>
          <StyledRow>
            <Field
              name={`billing_address.city`}
              type="text"
              component={renderField}
              label="City:"
              className="large"
            />
          </StyledRow>
          <StyledRow>
            <StyledCol sm={4} md={4}>
              <Field
                name={`billing_address.state`}
                type="text"
                component={renderState}
                label="State:"
                className="State"
              />
            </StyledCol>
            <StyledCol sm={5} md={5}>
              <Field
                name={`billing_address.zipcode`}
                type="text"
                component={renderField}
                label="Zipcode:"
                className="Zipcode"
              />
            </StyledCol>
          </StyledRow>
          {customer.dumpster_addresses &&
            customer.dumpster_addresses.length === 1 && (
              <StyledRow className={checked}>
                <label>Same as Dumpster Address</label>
                <Checkbox>
                  <Field name="same" component="input" type="checkbox" />
                </Checkbox>
              </StyledRow>
            )}
        </StyledCol>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Dumpster Info:</h4>
          </StyledRow>
          <FieldArray
            add_key={add_key}
            changeAddKey={changeAddKey}
            dumpster_addresses={customer.dumpster_addresses}
            name="dumpsters"
            component={renderDumpsters}
          />
        </StyledCol>
      </StyledRow>
    </StyledGrid>

    
  </div>
)
export default CustomerForm
