import axiosInstance from "./axios";

export const ApiService = {
    create: async (entityName, entity, callback) => {
        console.log('entity', entity);
        try {
            const res = await axiosInstance.post(`/api/${entityName}/add`, entity)
            if (res.status === 201) {
                callback && callback(res.data);
                return res.data;
            } else {
                callback && callback(res.data);
                return null;
            }
        } catch (err) {
            console.log(err);
            callback && callback(null);
            return null;
        }
    },
    readAll: async (entityName, callback, param='') => {
        try {
            const res = await axiosInstance.get(`/api/${entityName}/all/${param}`);
            callback && callback(res.data);
            return res.data;
        } catch (err) {
            console.log(err);
            callback && callback(null);
            return null;
        }
    },
    readOne: async (entityName, id, callback) => {
        try {
            const res = await axiosInstance.get(`/api/${entityName}/${id}`);
            callback && callback(res.data);
            return res.data;
        } catch (err) {
            console.log(err);
            callback && callback(null);
            return null;
        }
    },
    update: async (entityName, id, entity) => {
        if (!id || !entity) return;
        console.log('entity', entity);
        const res = await axiosInstance.patch(`/api/${entityName}/update/${id}`, entity);
        return res.status === 200;
    },
    delete: async (entityName, id) => {
        console.log('id', id);
        if (!id) return;
        const res = await axiosInstance.delete(`/api/${entityName}/delete/${id}`);
        if (res.status === 200) {
            return true;
        } else {
            return false;
        }
    },

};