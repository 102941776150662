/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import styled from 'styled-components';
import {reduxForm} from 'redux-form';
import {Customers} from '../../actions2';
import {CustomerData, CustomerForm, Header} from './components';
import {DeniedWrapper} from '../../styled';

const CustomerWrapper = styled.div`
  margin: 2vh 12vw 0vh 12vw;
`;

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'view',
      credit_card: {
        number: '',
        expiry: '',
        cvc: '',
      },
      card_touched: false,
      add_key: ''
    };
    this.addInfo = this.addInfo.bind(this)
    this.addAddress = this.addAddress.bind(this)
    this.changeAddKey = this.changeAddKey.bind(this)
  }

  changeAddKey() {
    this.setState({
      add_key: ''
    })
  }

  addInfo() {
    this.setState({
      mode: 'edit',
      add_key: 'info'
    })
  }

  addAddress() {
    this.setState({
      mode: 'edit',
      add_key: 'address'
    })
  }

  componentDidMount() {
    if(this.props.credit_card !== undefined)
      this.setState({credit_card: this.props.credit_card});
  }
  changeMode = mode => {
    this.props.reset();
    this.setState({mode});
  };

  save = formValues => {
    const {phone_numbers, dumpster_addresses, dumpsters, uid, list_id} = this.props.customer;
    let values = { ...this.props.customer };
    if (this.state.card_touched) {
      values.credit_card = {};
      _.forEach(this.state.credit_card, (el, key) => {
        values.credit_card[key] = el;
      });
    }
    _.forEach(this.props.fields, (value, key) => {
      if (
        key === 'dumpster_address' ||
        key === 'dumpster' ||
        key === 'billing_address'
      ) {
        _.forEach(this.props.fields[key], value => {
          if (value !== undefined && value.touched) {
            values[key] = formValues[key];
          }
        });
      } else if (key === 'phone_numbers') {
        values.phone_numbers = formValues.phone_numbers.slice();
      } else if (key === 'dumpster_addresses') {
        values.dumpster_addresses = formValues.dumpster_addresses.slice();
      } else if (key === 'dumpsters') {
        values.dumpsters = formValues.dumpsters.slice();
      } else {
        if (value.touched) {
          values[key] = formValues[key];
        }
      }
    });
    if (
      phone_numbers &&
      phone_numbers.length > formValues.phone_numbers.length
    ) {
      values.phone_numbers = formValues.phone_numbers;
    }
    if (
      dumpster_addresses &&
      dumpster_addresses.length > formValues.dumpster_addresses.length
    ) {
      values.dumpster_addresses = formValues.dumpster_addresses;
    }
    if (
      dumpsters &&
      dumpsters.length > formValues.dumpsters.length
    ) {
      values.dumpsters = formValues.dumpsters;
    }
    if (formValues.same && formValues.dumpster_addresses && formValues.dumpster_addresses.length === 1) {
      values.billing_address = formValues.dumpster_addresses[0];
    }
    this.props.saveCustomers(values, uid, list_id, formValues.contact_name);
    this.changeMode('view');
  };

  handleCard = (value, type) => {
    let credit_card = Object.assign({}, this.state.credit_card);
    credit_card[type] = value;
    this.setState({credit_card, card_touched: true});
  };

  render() {
    const {mode, credit_card} = this.state;
    if (this.props.authorized) {
      if(!this.props.customer?.name) {

        return <Redirect to="Customers" />;
      }
      if (mode === 'view') {
        return (
          <CustomerWrapper>
            {/* <Header type="view" changeMode={this.changeMode} /> */}
            <CustomerData addInfo={this.addInfo} addAddress={this.addAddress} changeMode={this.changeMode}/>
          </CustomerWrapper>
        );
      //The below code could be turned into a modal to eliminate the ugly looking edit and make it active
      //on a button click
      } else if (mode === 'edit') {
        console.log(this.props.customer);
        //if closed account don't let user edit
        if(this.props.customer.isClosed !== undefined && this.props.customer.isClosed !== null && this.props.customer.isClosed === true){
          alert("This account is closed. If you want to edit it, please reactivate the account first.");
          return <Redirect to="/ClosedAccounts" />;
        }
        else{
          /* Allows the Route to be editted */
          return (
            <CustomerWrapper>
              <form onSubmit={this.props.handleSubmit(this.save)}>
                <Header
                  type="form"
                  changeMode={this.changeMode}
                  submitting={this.props.submitting}
                />
                <CustomerForm
                  changeAddKey={this.changeAddKey}
                  add_key={this.state.add_key}
                  checked={this.props.checked}
                  credit_card={credit_card}
                  handleCard={this.handleCard}
                  customer = {this.props.customer}
                  meta={this.props}
                />
              </form>
            </CustomerWrapper>
          );
        }
      } else if (mode === 'back') {
        if(this.props.customer.isClosed === undefined || this.props.customer.isClosed === null || this.props.customer.isClosed === false)
          return <Redirect to="Customers" />;
        else
          return <Redirect to="ClosedAccounts" />;
      } else if (mode === 'past_due') {
        return <Redirect to="/Reports/due_accounts" />;
      } else {
        return <CustomerWrapper> Customer Not Found </CustomerWrapper>;
      }
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = state => ({
  authorized: state.user?.pages?.customers,
  fields: state.form.CustomerForm ? state.form.CustomerForm.fields : undefined,
  customer: state.currentCustomer,
  checked: state.currentCustomer.same,
  credit_card: state.currentCustomer?.credit_card,
});

const mapDispatchToProps = dispatch => ({
  saveCustomers: (customer, customerId, list_id, name) =>
    dispatch(Customers.saveCustomer(customer, customerId, list_id, name)),
});

Customer = reduxForm({
  form: 'CustomerForm',
  enableReinitialize: true,
  touchOnChange: true,
  touchOnBlur: false,
})(Customer);

Customer = connect(state => ({
  initialValues: state.currentCustomer,
}))(Customer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Customer);
