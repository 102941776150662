/* @format - for Prettier */
import _ from "lodash";
import moment from "moment";
import { db } from "../firebase";
import { history } from "../App";
import { ApiService } from "../api/apiService";
import { _sortBy, CustomerTypes, DBCustomer, AppCustomer } from "../utils/customer";
import axiosInstance from "../api/axios";

export const setCustomers = customers => ({
  type: "SET_SLIPPERYROCK_CUSTOMERS",
  customers
});

export const setClosedAccounts = customers => ({
  type: "SET_CLOSED_ACCOUNTS",
  customers
});

export const setPastDueCustomers = pastDueCustomers => ({
  type: "SET_PAST_DUE_CUSTOMERS",
  pastDueCustomers
});

export const setCurrentCustomer = customer => ({
  type: "SET_CURRENT_SLIPPERYROCK_CUSTOMER",
  customer
});

export const setInvoices = invoices => ({
  type: "SET_INVOICES",
  invoices
});

export const changeAmountDue = (uid, amount_due) => {
  return function (dispatch) {
    axiosInstance.post(`/api/customers/update/amount_due`, {
      customerId: uid,
      amount_due
    })
    .then((res) => {
      if(res.status === 200)
        dispatch(getCustomer(uid));
    });
  }
}

/* Gets all the customers in the database */
export const getCustomers = () => {
  return function(dispatch) {
    ApiService.readAll('customers', null, CustomerTypes.slippery_rock)
      .then(data => {
        console.log('data in getCustomers', data);
        if (data) {
          console.log('data in getCustomers', data.customers);
          let customers = _.map(data.customers, (customer) => AppCustomer(customer, true));
          customers = _sortBy(customers, ["Account Number"]);
          dispatch(setCustomers(customers));
        }
      })
  };
};

/* Gets all the closed Accounts in the database */
export const getClosedAccounts = () => {
  return function(dispatch) {
    db.getClosedAccounts()
      .then(snapshot => {
        const customers = _.sortBy(snapshot.val(), ["name"]);
        dispatch(setClosedAccounts(customers));
      })
      .catch(error => console.log("error in getCustomers", error));
  };
};

export const getPastDueCustomers = () => {
  return function(dispatch) {
    db.readPastDueCustomers()
      .then(snapshot => {
        let pastDueCustomers = [];
        _.map(snapshot.val(), (customer, i) => {
          if (customer.history) {
            let past = false;
            _.map(customer.history, history => {
              if (
                history.due_date &&
                moment(history.due_date, "MM/DD/YYYY").diff(
                  moment().startOf("day")
                ) < 0
              ) {
                if (!history.paid) {
                  past = true;
                  return;
                }
              }
            });
            if (past) pastDueCustomers.push(customer);
          }
        });
        pastDueCustomers = _.sortBy(pastDueCustomers, ["name"]);
        dispatch(setPastDueCustomers(pastDueCustomers));
      })
      .catch(error => console.log("error in getPastDueCustomers", error));
  };
};

export const getInvoices = () => {
  return function(dispatch) {
    db.readCustomers()
      .then(snapshot => {
        let invoices = [];
        _.map(snapshot.val(), (customer, i) => {
          if (customer.history) {
            _.map(customer.history, history => {
              if (history.status === "Invoice Sent") {
                history.customer = customer;
                // history.customerId = customer.uid;
                // history.customerStatus = customer.status;
                invoices.push(history);
              }
            });
          }
        });
        invoices = _.sortBy(invoices, ["customer"]);
        dispatch(setInvoices(invoices));
      })
      .catch(error => console.log("error in getInvoices", error));
  };
};

export const saveCustomer = (values, customerId, name) => {
  return function(dispatch) {
    let updates = {};
    _.forEach(values, (value, key) => {
      if (value !== undefined)
        updates[key] = value;
    });
    ApiService.update('customers', customerId, {
      customer: DBCustomer(updates)
    })
    .then((updated) => {
      if (updated) {
        dispatch(getCustomer(customerId));
        history.push("/SlipperyRock/Customer");
      }
    });
  };
};

/* Gets a the customer in the database */
export const getCustomer = uid => {
  return function (dispatch) {
    ApiService.readOne('customers', uid)
    .then(data => {
      let customer = data?.customer;
      console.log('customer in getCustomer', customer);
      if(customer)
        dispatch(setCurrentCustomer(AppCustomer(customer, true)));
    })
  };
};

/* Gets a the customer in the database then goes to that page */
export const setCustomer = (uid, redirect = '') => {
  return function (dispatch) {
  ApiService.readOne('customers', uid)
    .then(data => {
      let customer = data?.customer;
      console.log('customer in getCustomer', customer);
      if (customer){
        dispatch(setCurrentCustomer(AppCustomer(customer, true)));
            let url = '/SlipperyRock/Customer'
            if (redirect.length) url = `/SlipperyRock/Customer?redirect=${redirect}`
        history.push(url);
      }
    });
  };
};

/* Gets a the customer in the database then goes to that page */
export const setClosedAccountCustomer = (customer, redirect='') => {
  return function(dispatch) {
    ApiService.readOne('customers', customer.uid)
    .then(data => {
      let customer = data?.customer;
      if (customer) {
        dispatch(setCurrentCustomer(customer));
        let url = '/ClosedAccount/SlipperyRock';
        if (redirect.length) url = `/ClosedAccount/SlipperyRock?redirect=${redirect}`
        history.push(url);
      }
    });
  };
};

export const addClosedCustomer = customer => {
  return async function(dispatch) {
    db.doSlipperyRockAddCustomer(customer);
  }
}

export const addCustomer = () => {
  return async function(dispatch) {
    let data = {
      name: "New SRB Customer",
      contact_name: "",
      email: "",
      type: CustomerTypes.slippery_rock,
      rate: "",
      amount_due: 0,
      status: "Not paid",
      recurringCustomer: "No",
      isExempt: "No",
      isAutoRenewed: false,
      isInactive: "No"
    };
    const {
      customer
    } = await ApiService.create('customers', {
      customer: DBCustomer(data)
    })
    dispatch(setCurrentCustomer(AppCustomer(customer, true)));
    history.push("/SlipperyRock/Customer");
  };
};

export const removeClosedCustomer = customerId => {
  return function(dispatch) {
    db.deleteClosedCustomer(customerId).then(() => {
      dispatch(getClosedAccounts());
    }).catch(error => {
    });
  }
}

export const billAllSlipperyRockCustomers = (quarter,year) => {
  return function(dispatch) {
    axiosInstance.post(`/api/customers/bill/${CustomerTypes.slippery_rock}`, {
        quarter,
        year
      })
      .then((res) => {
        if (res.status === 200)
          dispatch(getCustomers());
      })
      .catch(error => console.log("error in billAllrecuuringCustomers", error));
  };
};
export const billAllSlipperyRockCustomersRecuring = (quarter,year) => {
  return function(dispatch) {
    axiosInstance.post(`/api/customers/bill/recurring/${CustomerTypes.slippery_rock}`, { quarter, year })
      .then((res) => {
        if (res.status === 200)
          dispatch(getCustomers());
      })
      .catch(error => console.log("error in billAllrecuuringCustomers", error));
  };
};

export const billMonthSlipperyRockCustomer = (customer, month) => {
  return function(dispatch) {
    axiosInstance.post(`/api/customers/bill_monthly`, {
        customerId: customer.uid,
        month,
      })
      .then((res) => {
        if (res.status === 200)
          dispatch(getCustomers());
      })
      .catch(error => console.log("error in billMonthCommercialCustomer", error));
  };
};

export const removeCustomer = customerId => {
  return function (dispatch) {
    ApiService.delete('customers', customerId)
      .then((deleted) => {
        if (deleted)
          dispatch(getCustomers());
      });
  };
};

export const sendInvoice = (
  customerId,
  historyId,
  date,
  due_date,
  sent,
  amount,
  invoiceName
) => {
  return function(dispatch) {
    axiosInstance.post(`/api/customers/send_invoice`, {
        customerId,
        historyId,
        time: moment(date, "MM/DD/YYYY").toISOString(),
        due_date,
        sent,
        amount,
        invoiceName,
      })
      .then((res) => {
        if (res.status === 200)
          dispatch(getCustomer(customerId));
      })
      .catch(error => console.log("error in send invoice"));
  };
};

export const showInvoice = (customerId, historyId) => {
  return function(dispatch) {
    db.readCustomer(customerId)
      .then(snapshot => {
        let customer = snapshot.val();
        db.readCustomerStops(customerId).then(stop_snapshot => {
          customer.stops = stop_snapshot.val();
          dispatch(setCurrentCustomer(customer));
          history.push(`/Invoice/${historyId}`);
        });
      })
      .catch(error => console.log("error in getCustomer", error));
  };
};

export const suspendCustomer = (customer, note, type_of_reason) => {
  return function(dispatch) {

      // Get the current date and time
      let now = new Date();
      let time = now; 
      // Get the date from the string
      let timeString = time;
      let dateObject = new Date(timeString);

      // Set the date object's year, month, and day to match the current time
      dateObject.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());

      // Now dateObject has both the specific date and the exact current time
      let timestamp = dateObject.getTime();
      let dateTimeString = dateObject.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      time = dateTimeString;

    axiosInstance.post(`/api/customers/account/suspend`, {
        customerId: customer.uid,
        status: 'Suspended',
        reason: type_of_reason,
        time: time,
        note
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCustomer(customer.uid));
        }
      })
      .catch(error => console.log("error in supsend customer"));
  };
};

export const unsuspendCustomer = (customer, note, amount_due, type_of_reason) => {
  return function(dispatch) {
      let status = "";
      if (amount_due <= 0 && (type_of_reason === undefined || type_of_reason !== "Other: Keep Suspended")) {
        status = "Paid";
      } else {
        status = "Charged";
      }

        // Get the current date and time
        let now = new Date();
        let time = now;
        // Get the date from the string
        let timeString = time;
        let dateObject = new Date(timeString);
  
        // Set the date object's year, month, and day to match the current time
        dateObject.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
  
        // Now dateObject has both the specific date and the exact current time
        let timestamp = dateObject.getTime();
        let dateTimeString = dateObject.toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
        time = dateTimeString;
      axiosInstance.post(`/api/customers/account/suspend`, {
          customerId: customer.uid,
          status: status,
          reason: type_of_reason,
          time: time,
          note
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(getCustomer(customer.uid));
          }
        })
        .catch(error => console.log("error in unsupsend customer", error));
  };
};

export const makePayment = (
  customer,
  time,
  amount_paid,
  old_amount,
  description,
  historyId,
  apply_to_charge,
  type_of_payment,
  type_of_transaction
) => {

        // Get the current date and time
        let now = new Date();

      // Get the date from the string
      let timeString = time;
      let dateObject = new Date(timeString);

      // Set the date object's year, month, and day to match the current time
      dateObject.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());

      // Now dateObject has both the specific date and the exact current time
      let timestamp = dateObject.getTime();
      let dateTimeString = dateObject.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      time = dateTimeString; //time is now a timestamp


  return function(dispatch) {
    const amount_due = parseFloat(customer.amount_due, 10) - amount_paid + old_amount;
    console.log(customer.type_of_reason)
    axiosInstance.post('api/customers/make_payment', {
        customerId: customer.uid,
        historyId: Number(historyId),
        amount_due,
        amount_paid,
        description,
        reason: customer.type_of_reason,
        type_of_payment,
        type_of_transaction,
        time,
        apply_to_charge,
      })
      .then(() => {
        dispatch(getCustomer(customer.uid));
      })
      .catch(error => console.log("error in make payment", error));
  };
};

export const chargeCustomer = (
  customer,
  time,
  amount_charged,
  old_amount,
  service_description,
  description,
  historyId,
  type_of_payment,
  type_of_transaction
) => {
  return function(dispatch) {
    let amount_due = customer.amount_due;
    amount_due =
      parseFloat(amount_due, 10) +
      parseFloat(amount_charged, 10) -
      parseFloat(old_amount, 10);
      
      // Get the current date and time
      let now = new Date();

      // Get the date from the string
      let timeString = time;
      let dateObject = new Date(timeString);

      // Set the date object's year, month, and day to match the current time
      dateObject.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());

      // Now dateObject has both the specific date and the exact current time
      let timestamp = dateObject.getTime();
      let dateTimeString = dateObject.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      time = dateTimeString; //time is now a timestamp
    axiosInstance.post('api/customers/charge', {
        customerId: customer.uid,
        historyId: Number(historyId),
        amount_due: amount_due.toFixed(2),
        status: customer.status,
        amount_charged,
        description,
        service_description,
        type_of_payment,
        type_of_transaction,
        time: time,
      })
      .then((res) => {
        if (res.status === 200)
          dispatch(getCustomer(customer.uid));
      })
      .catch(error => console.log("error in charge customer", error));
  };
};

export const updateInvoiceDates = (customer, time, due_date, historyId) => {
  console.log("historyId", historyId);
  return function (dispatch) {
    ApiService.update('customers', 'history/' + historyId, {
        history: {
          time,
          due_date
        }
      })
      .then((res) => {
        if (res.status === 200)
          dispatch(getCustomer(customer.uid));
      })
      .catch(error => console.log("error in update invoice dates", error));
  };
};


export const deleteChargeOrPayment = (customer, amount, historyId) => {
  return function (dispatch) {
    const {
      uid,
      amount_due,
      type_of_reason
    } = customer;
    let updates = {};
    console.log("amount_due", amount_due, "amount", amount);
    const amountDue = parseFloat(amount_due) - parseFloat(amount);
    

    updates[`amount_due`] = amountDue.toFixed(2);

    if (type_of_reason !== "Other: Keep Suspended") {
      updates[`status`] = amountDue <= 0 ? "Paid" : "Charged";
    }
    ApiService.delete('customers', 'history/' + historyId)
      .then((deleted) => {
        if (deleted)
          ApiService.update('customers', uid, {
            customer: updates
          }).then((updated) => {
            if (updated)
              dispatch(getCustomer(uid));
          });
      })
      .catch(error => console.log("error in delete charge or payment"));
  };
};

export const closeAccount = (customer) => {
  return function (dispatch) {
    axiosInstance.post(`/api/customers/account/close`, {
        customerId: customer.uid,
        is_closed: 1
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCustomers());
        }
      })
  };
}
