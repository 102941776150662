import React, { useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";

const ToolButton = styled(Button)`
  padding: 10px 20px;
  height: 40px;
  margin: 10px 0px 0px 10px;
  float: right;
`;

const SelectModal = ({ show, title, options, okayLabel, onOkay,isResidentialCustomer }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  let currentDate = new Date();
  // add a day
  currentDate.setDate(currentDate.getDate() + 30);
  const [dueDate, setDueDate] = useState(currentDate);
  const handleDueDateChange = (date) => {
    setDueDate(date);
  };
  const handleChange = (option) => {
    setSelectedOption(option);
  };

  const handleClick = () => {
    onOkay(selectedOption.value,dueDate);
    setSelectedOption(null)
  };

  return (
    <Modal show={show}>
      <Modal.Body>
        <h3>{title}</h3>
       
        <Select
          options={options}
          value={selectedOption}
          onChange={handleChange}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
        />
         {isResidentialCustomer && selectedOption?.value=="customDueDate" &&
        <div className="form-group" style={{marginTop:10}}>
              <label>Which due date option would you like to use?</label>
              <br />
              <DatePicker
                selected={dueDate}
                onChange={handleDueDateChange}
                required
              />
            </div>
}
      </Modal.Body>
      <Modal.Footer>
        <ToolButton
          disabled={!selectedOption}
          bsStyle="primary"
          onClick={handleClick}
          id="printBtn"
        >
          {okayLabel}
        </ToolButton>
      </Modal.Footer>
    </Modal>
  );
};

SelectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  okayLabel: PropTypes.string.isRequired,
  onOkay: PropTypes.func.isRequired,
};

SelectModal.defaultProps = {
  okayLabel: 'Okay',
};

export default SelectModal;
