/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Routes as RoutesActions} from '../../../actions2';
import {ModeButton, ButtonRow, Name} from '../../../styled';
import {Button, Modal} from 'react-bootstrap';
import StopForm from './StopForm';
import {reduxForm, FieldArray, formValueSelector, change, reset} from 'redux-form';
import toastr from 'toastr';

const StyledForm = styled.form`
  margin: 10px;
`;

class AddStop extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.state = {
      showStop: false
    }
    this.handleCloseStop = this.handleCloseStop.bind(this)
    this.save = this.save.bind(this)
    this.changeFullAddress = this.changeFullAddress.bind(this);
  }

  
  handleCloseStop() {
    this.setState({
      showStop: false
    })
    this.props.resetAddForm()
  }

  changeFullAddress(index, address) {
    let params = address.split('--')
    let stops = this.props.stops
    stops[index]['address'] = {}
    stops[index]['address'].street = params[0]
    stops[index]['address'].city = params[1]
    stops[index]['address'].state = params[2]
    stops[index]['address'].zipcode = params[3]
    this.props.change("RoutesForm2", "stops", stops);
  }

  save() {
    this.props.saveStopToRoute(this.props.formValues.values.stops, this.props.uid)
    toastr.success('Successfully added the Stop to the current Route!', 'Success')
    this.setState({
      showStop: false,
      disableStop: true
    })
  }
  
  render() {
    let {
      type, changeMode, name, status, customerList,
      submitting, handleSubmit
    } = this.props;

    return (
      <>
        <Button onClick={() => this.setState({showStop: true})} 
            className="ml-2"> + Add Stop </Button>
        <Modal
          dialogClassName="stop-add-modal"
          show={this.state.showStop}
          onHide={this.handleCloseStop}>
          <Modal.Header closeButton>
            <Modal.Title>
              Add Stop
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ marginTop: '40px' }}>
              <StyledForm onSubmit={handleSubmit(this.save)}>
                <FieldArray
                  name="stops"
                  adding={false}
                  customers={customerList}
                  dumpsterAddresses = {this.props.dumpsterAddresses}
                  stops={this.props.stops}
                  changeFullAddress={(index, address) => this.changeFullAddress(index, address)}
                  editingUID="new"
                  component={StopForm}
                />
                <button ref="submit2" style={{ display: 'none' }}></button>
              </StyledForm>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div style={{ marginTop: '40px' }}>
              <Button type="button" onClick={() => this.refs.submit2.click() }>Add Stop</Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  route: state.currentRoute,
  uid: state.currentRouteUid,
  formValues: state.form.RoutesForm2,
  customerList: state.customerList,
  dumpsterAddresses: state.dumpsterAddresses,
});

const mapDispatchToProps = dispatch => ({
  change,
  resetAddForm: () => dispatch(reset('RoutesForm2')),
  saveStopToRoute: (stops, route) => dispatch(RoutesActions.saveStopToRoute(stops, route)),
  setRoute: uid => dispatch(RoutesActions.getRoute(uid)),
});

AddStop = reduxForm({
  form: 'RoutesForm2',
  enableReinitialize: true,
})(AddStop);

const selector = formValueSelector('RoutesForm2')

AddStop = connect(
  state => {
    const stops = selector(state, 'stops')
    return {
      stops: stops,
      initialValues: {
        stops: [{
          added_timestamp: "",
          address: {city: "", full: "", state: "", street: "", zipcode: ""},
          coordinates: {Latitude: '', Longitude: ''},
          last_picked_up_timestamp: "",
          name: "",
          notes: "",
          status: ""
        }]
      },
    }
  }
)(AddStop);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddStop);
