/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { reduxForm, FieldArray } from 'redux-form';
import { Table, Button, Modal, Glyphicon } from 'react-bootstrap';
import { firebase } from '../../firebase';
import { Users, init } from '../../actions2';
import { DeniedWrapper, ModeButton, ButtonRow, Name } from '../../styled';
import { UsersForm, CreateUserModal } from './components';

const AdminWrapper = styled.div`
  margin: 2vh 10vw 0vh 10vw;
`;

const Page = styled.div`
  padding-right: 7px;
  display: inline-grid;
`;

const StyledTD = styled.td`
  text-align: center;
`;

const GlyphTd = styled.td`
  width: 50px;
`;

const GlyphTh = styled.th`
  width: 50px;
`;

const AddButton = styled(Button)`
  width: 100px;
`;

export const GlyphButton = styled(Button)`
  width: 100%;
  color: red !important;
  border: none;
`;

const DeleteButton = styled(Button)`
  float: right;
`;

const CloseButton = styled(Button)`
  float: left;
`;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'view',
      show: false,
      showDelete: false,
      user: null,
    };
  }
  componentDidMount() {
    // if (this.props.authorized) {
    //   this.props.getUsers();
    // }
    if(this.props.loginId) {
      this.props.init(this.props.loginId)
    }
  }
  handleDeleteShow = uid => {
    this.setState({ showDelete: true, user: this.props.users[uid] });
  };
  handleDeleteOpen = () => {
    this.setState({ show: true });
  };
  handleOpen = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false, showDelete: false });
  };
  changeMode = mode => {
    this.setState({ mode });
  };
  handleRemove = () => {
    this.props.removeUser(this.state.user.uid);
    this.setState({
      showDelete: false,
      user: null
    });
  };
  save = () => {
    this.props.reset();
    this.props.saveUsers(
      this.props.formValues.values,
      this.props.formValues.fields,
    );
    this.changeMode('view');
  };
  render() {
    console.log('this.props.users', this.props.users);
    if (this.props.authorized) {
      if (this.state.mode === 'view') {
        /* Just displays the Users */
        return (
          <AdminWrapper>
            <ButtonRow>
              <ModeButton
                bsStyle="link"
                onClick={() => this.changeMode('edit')}>
                edit
              </ModeButton>
            </ButtonRow>
            <Name> Users </Name>
            <Table responsive condensed hover bordered>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email Address</th>
                  <th>Privileges</th>
                  <GlyphTh>Delete</GlyphTh>
                </tr>
              </thead>
              <tbody>
                {_.map(this.props.users, (user, uid) => {
                  let pages = [];
                  _.forEach(user.pages, (page, key) => {
                    if (page) {
                      pages.push(key);
                    }
                  });
                  pages = _.map(
                    pages,
                    page => page.charAt(0).toUpperCase() + page.slice(1),
                  );
                  return (
                    <tr key={uid}>
                      <td> {user.first_name} </td>
                      <td> {user.last_name} </td>
                      <td> {user.email} </td>
                      <StyledTD>
                        {_.map(pages, (page, i) => {
                          return (
                            <Page key={i}>
                              <span>
                                {page}
                                {i === pages.length - 1 ? '' : ','}
                              </span>
                            </Page>
                          );
                        })}
                      </StyledTD>
                      <GlyphTd>
                        <GlyphButton onClick={() => this.handleDeleteShow(uid)}>
                          <Glyphicon glyph="remove" />
                        </GlyphButton>
                      </GlyphTd>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <AddButton onClick={this.handleOpen} bsStyle="primary">
              + Add User
            </AddButton>
            <CreateUserModal
              show={this.state.show}
              handleClose={this.handleClose}
            />
            <Modal
              bsSize="small"
              show={this.state.showDelete}
              onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span>Delete </span>
                  <span>
                    {this.state.user
                      ? `${this.state.user.first_name} ${this.state.user.last_name
                      }`
                      : ' '}
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>Are you sure you want to delete this user?</div>
              </Modal.Body>
              <Modal.Footer>
                <CloseButton onClick={this.handleClose}>Close</CloseButton>
                <DeleteButton bsStyle="danger" onClick={this.handleRemove}>
                  Delete
                </DeleteButton>
              </Modal.Footer>
            </Modal>
            <div>
              {' '}
              In order to disable an account, please do so in the firebase
              console{' '}
            </div>
          </AdminWrapper>
        );
      } else if (this.state.mode === 'edit') {
        /* Allows the Route to be editted */
        return (
          <AdminWrapper>
            <form onSubmit={this.props.handleSubmit(this.save)}>
              <ButtonRow>
                <ModeButton
                  bsStyle="link"
                  type="submit"
                  disabled={this.props.submitting}>
                  Save
                </ModeButton>
                <ModeButton
                  bsStyle="link"
                  type="button"
                  onClick={() => this.changeMode('view')}>
                  Cancel
                </ModeButton>
              </ButtonRow>
              <Name> Users </Name>
              <FieldArray name="users" component={UsersForm} />
            </form>
            <div>
              {' '}
              In order to disable an account, please do so in the firebase
              console{' '}
            </div>
          </AdminWrapper>
        );
      }
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = state => ({
  formValues: state.form.UsersForm,
  users: state.users,
  loginId: state.user?.uid,
  authorized: state.user?.pages?.admin,
});

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(Users.getUsers()),
  saveUsers: (users, touched) => dispatch(Users.saveUsers(users, touched)),
  removeUser: uid => dispatch(Users.removeUser(uid)),
  init: (userId) => dispatch(init(userId)),
});

Admin = reduxForm({
  form: 'UsersForm',
  enableReinitialize: true,
})(Admin);

Admin = connect(state => ({
  initialValues: {
    users: _.map(state.users, user => user),
  },
}))(Admin);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Admin);
