/* @format - for Prettier */
import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import InputMoment from 'input-moment';
import 'input-moment/dist/input-moment.css';
import moment from 'moment';
import {Button, Modal} from 'react-bootstrap';
import {db} from '../../../firebase';
import {Routes} from '../../../actions2';

const StyledDatePicker = styled(InputMoment)`
  width: 100% !important;
`;

class Time extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      moment: moment(),
    };
  }

  handleShow = () => {
    this.setState({show: true});
  };

  handleClose = () => {
    this.setState({show: false});
  };

  handleChange = moment => {
    this.setState({moment});
  };

  handleSave = () => {
    let updates = {};
    const stopId = this.props.route.stops[this.props.index].uid;
    updates[
      `/stops/${stopId}/last_picked_up_timestamp`
    ] = this.state.moment.format('llll');
    db.doUpdate(updates)
      .then(() => {
        this.props.getRoute(this.props.route.uid);
        this.handleClose();
      })
      .catch(error => console.log('error in saveNotes', error));
  };

  render() {
    return (
      <div>
        {this.props.index >= this.props.route.stops.length ? (
          <div> Save Stop Before Adding Time </div>
        ) : (
          <Button onClick={this.handleShow}>Add Pickup Time</Button>
        )}
        <Modal show={this.state.show} onHide={this.handleClose} bsSize="sm">
          <Modal.Body>
            <StyledDatePicker
              moment={this.state.moment}
              onChange={this.handleChange}
              onSave={this.handleSave}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  route: state.currentRoute,
});

const mapDispatchToProps = dispatch => ({
  getRoute: id => dispatch(Routes.getRoute(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Time);
