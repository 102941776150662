/* @format - for Prettier */
import React, { Component } from 'react';
import styled from 'styled-components';
import {Table} from 'react-bootstrap';
import Stop from './Stop';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const TBody = styled.tbody`
  border: 0px;
`

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default class StopTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stops: this.props.stops || [],
      selectedStops: []
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stops !== this.props.stops) {      
      this.setState({stops: this.props.stops})
    }
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const orderedStops = reorder(
      this.state.stops,
      result.source.index,
      result.destination.index
    );

    this.setState({
      stops: orderedStops
    });

    this.props.changeStopsOrder(orderedStops)
  }

  onToggleStop = (stop) => {
    const { selectedStops } = this.state ;
    const stopIndex = selectedStops.findIndex(item => item.uid === stop.uid)
    
    if (stopIndex === -1) {
      selectedStops.push(stop);
    } else {
      selectedStops.splice(stopIndex, 1);
    }

    this.setState({ selectedStops: [...selectedStops] });
    this.props.onSelectStops(selectedStops);
  }

  onToggleAllStops = (e) => {
    const selectedStops = e.target.checked ? this.state.stops: [];
    this.setState({ selectedStops: [...selectedStops]});
    this.props.onSelectStops(selectedStops);
  } 

  render() {
    const { stops, selectedStops } = this.state;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Table responsive condensed hover bordered>
          <thead>
            <tr>
              <th>
                <input type="checkbox" onChange={this.onToggleAllStops} />
              </th>
              <th>#</th>
              <th>Name</th>
              <th>Customer</th>
              <th>Address</th>
              <th>Coordinates</th>
              <th>Time</th>
              <th>Status</th>
              <th>Notes</th>
            </tr>
          </thead>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <TBody
                ref={(ref) => {
                  this.tableRef = ref;
                  provided.innerRef(ref);
                }}
                {...provided.droppableProps}
              >
                {stops.map(
                  (item, index) =>
                    item.uid && (
                      <Draggable
                        key={item.uid}
                        draggableId={item.uid}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Stop
                            stop={item}
                            index={index}
                            provided={provided}
                            snapshot={snapshot}
                            onToggleStop={this.onToggleStop}
                            isSelected={
                              selectedStops.filter(
                                (stop) => item.uid === stop.uid
                              ).length
                            }
                          />
                        )}
                      </Draggable>
                    )
                )}
                {provided.placeholder}
              </TBody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
    );
  }
}
