/* @format - for Pretter */
import { db } from "./firebase";
import _ from "lodash";
import moment from "moment";
import readXlsxFile from "read-excel-file";
import toastr from "toastr";

export const doUpdate = (data) => {
  return db.ref().update(data);
};

export const readUsers = () => {
  return db.ref(`/users`).once("value");
};

export const readUser = (id) => {
  return db.ref(`/users/${id}`).once("value");
};

export const readResidentialCurrentAccountNumber = () => {
  return db.ref(`/Last_Residential_Customer_Account_Number`).once("value");
};

export const readWestMayfieldCurrentAccountNumber = () => {
  return db.ref(`/Last_WestMayfield_Customer_Account_Number`).once("value");
};

export const readEastButlerCurrentAccountNumber = () => {
  return db.ref(`/Last_EastButler_Customer_Account_Number`).once("value");
};

export const readSlipperyRockCurrentAccountNumber = () => {
  return db.ref(`/Last_SlipperyRock_Customer_Account_Number`).once("value");
};

export const readCurrentAccountNumber = () => {
  return db.ref(`/Last_Customer_Account_Number`).once("value");
};

export const readResidentialCustomers = () => {
  return db.ref(`/customersResidential`).once("value");
};

export const readWestMayfieldCustomers = () => {
  return db.ref(`/customersWestMayfield`).once("value");
};

export const readEastButlerCustomers = () => {
  return db.ref(`/customersEastButler`).once("value");
};

export const readSlipperyRockCustomers = () => {
  return db.ref(`/customersSlipperyRock`).once("value");
};

export const readCustomers = () => {
  return db.ref(`/customers`).once("value");
};
// export const recuringCustomers = () => {
//   return db.ref(`/`).once("value");
// };

export const readCustomersGreenBag = () => {
  return db.ref(`/customersGreenBag`).once("value");
};

export const getClosedAccounts = () => {
  return db.ref(`/closedAccounts`).once("value");
};

export const readCustomersNewCastle = () => {
  return db.ref(`/customersNewCastle`).once("value");
};

export const readCustomersNewCastle2 = () => {
  return db.ref(`/customersNewCastle2`).once("value");
};

export const readPastDueCustomers = () => {
  return db.ref(`customers`).orderByChild(`/status`).once("value");
};

export const readCustomer = (id) => {
  return db.ref(`customers/${id}`).once("value");
};

export const readCustomerFromAll = (id) => {
  const collections = [
    "customers",
    "customersResidential",
    "customersNewCastle2",
    "customersGreenBag",
    "customersNewCastle",
    "customersWestMayfield",
    "customersEastButler",
    "customersSlipperyRock",
  ];

  const promises = collections.map((collection) => {
    return db
      .ref(`/${collection}/${id}`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          return snapshot;
        } else {
          throw new Error(
            `Customer not found in the '${collection}' collection`
          );
        }
      })
      .catch((error) => {
        console.log(error.message);
        return Promise.resolve(); // Resolve with an empty value to keep Promise.allSettled happy
      });
  });

  return Promise.allSettled(promises).then((results) => {
    const foundCustomer = results.find(
      (result) => result.status === "fulfilled" && result.value
    );
    if (foundCustomer) {
      return foundCustomer.value;
    } else {
      console.log("Customer not found in any collection");
      // throw new Error("Customer not found in any collection");
    }
  });
};

export const readClosedCustomer = (id) => {
  return db.ref(`closedAccounts/${id}`).once("value");
};

export const readGreenBagCustomer = (id) => {
  return db.ref(`customersGreenBag/${id}`).once("value");
};

/* Job Submissions Requests */
export const readjobSubmissions = () => {
  return db.ref(`/jobSubmissions`).once("value");
};

/* Customer Portal User Requests */
export const readPortalUsers = () => {
  return db.ref(`/usersCommRes`).once("value");
};

export const readApplicantDetails = (id) => {
  return db.ref(`jobSubmissions/${id}`).once("value");
};
/* Job Submissions Requests */

export const readResidentialCustomer = (id) => {
  return db.ref(`customersResidential/${id}`).once("value");
};
export const readWestMayfieldCustomer = (id) => {
  return db.ref(`customersWestMayfield/${id}`).once("value");
};
export const readEastButlerCustomer = (id) => {
  return db.ref(`customersEastButler/${id}`).once("value");
};
export const readSlipperyRockCustomer = (id) => {
  return db.ref(`customersSlipperyRock/${id}`).once("value");
};

export const readCustomerNewCastle = (id) => {
  return db.ref(`customersNewCastle/${id}`).once("value");
};

export const readCustomerNewCastle2 = (id) => {
  return db.ref(`customersNewCastle2/${id}`).once("value");
};

export const getCurrentBillingQuarter = () => {
  return db.ref(`currentBillingQuarter`).once("value");
};

export const getCurrentBillingYear = () => {
  return db.ref(`currentBillingYear`).once("value");
};

export const readCustomerStops = (id) => {
  return db.ref("stops").orderByChild(`/customer/id`).equalTo(id).once("value");
};

export const readRoutes = () => {
  return db.ref("/routes").once("value");
};

export const readRoute = (id) => {
  return db.ref(`/routes/${id}`).once("value");
};

export const readStop = (id) => {
  return db.ref(`/stops/${id}`).once("value");
};

export const readStops = () => {
  return db.ref("/stops").once("value");
};

export const readStopIds = (id) => {
  const path = `/routes/${id}/stops`;
  return db.ref(path).once("value");
};

export const doAddUser = (user) => {
  let updates = {};
  updates[`/users/${user.uid}`] = user;
  return db.ref().update(updates);
};

export const doResidentialMakePayment = async (
  id,
  time,
  amount_due,
  amount_paid,
  old_amount,
  description,
  key,
  apply_to_charge,
  type_of_payment,
  type_of_transaction,
  type_of_reason
) => {
  const path = `/customersResidential/${id}/history`;
  let updates = {};

  amount_due = parseFloat(amount_due, 10) - amount_paid + old_amount;
  amount_due = parseFloat(amount_due).toFixed(2);
  console.log(type_of_reason);
  if (
    amount_due <= 0 &&
    (type_of_reason === undefined || type_of_reason !== "Other: Keep Suspended")
  ) {
    updates[`/customersResidential/${id}/status`] = "Paid";
  }
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}/uid`] = key;
  }
  let amount = 0;
  let flag = false;

  db.ref(`customersResidential/${id}`)
    .once("value")
    .then((snapshot) => {
      // Get all histories of current customer and sort them by date
      let histories = snapshot.val().history;
      histories = _.sortBy(histories, (history) => {
        let data = history.time.split("/");
        return [data[2], data[0], data[1]].join("-");
      }).reverse();
      // Loop the invoices and check if it's paid.
      _.map(histories, (history) => {
        if (history.status === "Invoice Sent" && history.sent) {
          if (history.paid === true) return true;
          if (
            amount >= parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10)
          ) {
            flag = true;
          }
          updates[`${path}/${history.uid}/paid`] = flag;
          updates[`${path}/${history.uid}/description`] = "Completed invoice";
          db.ref().update(updates);
        } else if (history.status === "paid") {
          amount += parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10);
        }
      });
    });
  updates[`/customersResidential/${id}/amount_due`] = amount_due;
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "paid";
  updates[`/customersResidential/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/amount`] = `$${amount_paid}`;
  updates[`${path}/${key}/description`] = `${description}`;
  updates[`${path}/${key}/type_of_payment`] = `${type_of_payment}`;
  updates[`${path}/${key}/type_of_transaction`] = `${type_of_transaction}`;

  if (apply_to_charge) {
    const charge = await (
      await db.ref(`${path}/${apply_to_charge}`).once("value")
    ).val();
    updates[`${path}/${apply_to_charge}/description`] =
      (charge.description ? `${charge.description}\n` : "") +
      `${moment().format("MM/DD/YYYY")} Paid $${amount_paid}`;
  }

  return db.ref().update(updates);
};

export const doNewCastle2MakePayment = async (
  id,
  time,
  amount_due,
  amount_paid,
  old_amount,
  description,
  key,
  apply_to_charge,
  type_of_payment,
  type_of_transaction,
  type_of_reason
) => {
  const path = `/customersNewCastle2/${id}/history`;
  let updates = {};

  amount_due = parseFloat(amount_due, 10) - amount_paid + old_amount;
  amount_due = parseFloat(amount_due).toFixed(2);
  if (
    amount_due <= 0 &&
    (type_of_reason === undefined || type_of_reason !== "Other: Keep Suspended")
  ) {
    updates[`/customersNewCastle2/${id}/status`] = "Paid";
  }
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}/uid`] = key;
  }
  let amount = 0;
  let flag = false;

  db.ref(`customersNewCastle2/${id}`)
    .once("value")
    .then((snapshot) => {
      // Get all histories of current customer and sort them by date
      let histories = snapshot.val().history;
      histories = _.sortBy(histories, (history) => {
        let data = history.time.split("/");
        return [data[2], data[0], data[1]].join("-");
      }).reverse();
      // Loop the invoices and check if it's paid.
      _.map(histories, (history) => {
        if (history.status === "Invoice Sent" && history.sent) {
          if (history.paid === true) return true;
          if (
            amount >= parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10)
          ) {
            flag = true;
          }
          updates[`${path}/${history.uid}/paid`] = flag;
          updates[`${path}/${history.uid}/description`] = "Completed invoice";
          db.ref().update(updates);
        } else if (history.status === "paid") {
          amount += parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10);
        }
      });
    });
  updates[`/customersNewCastle2/${id}/amount_due`] = amount_due;
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "paid";
  updates[`${path}/${key}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/amount`] = `$${amount_paid}`;
  updates[`${path}/${key}/description`] = `${description}`;
  updates[`${path}/${key}/type_of_payment`] = `${type_of_payment}`;
  updates[`${path}/${key}/type_of_transaction`] = `${type_of_transaction}`;

  if (apply_to_charge) {
    const charge = await (
      await db.ref(`${path}/${apply_to_charge}`).once("value")
    ).val();
    updates[`${path}/${apply_to_charge}/description`] =
      (charge.description ? `${charge.description}\n` : "") +
      `${moment().format("MM/DD/YYYY")} Paid $${amount_paid}`;
  }

  return db.ref().update(updates);
};

export const doWestMayfieldMakePayment = async (
  id,
  time,
  amount_due,
  amount_paid,
  old_amount,
  description,
  key,
  apply_to_charge,
  type_of_payment,
  type_of_transaction,
  type_of_reason
) => {
  const path = `/customersWestMayfield/${id}/history`;
  let updates = {};

  amount_due = parseFloat(amount_due, 10) - amount_paid + old_amount;
  amount_due = parseFloat(amount_due).toFixed(2);
  if (
    amount_due <= 0 &&
    (type_of_reason === undefined || type_of_reason !== "Other: Keep Suspended")
  ) {
    updates[`/customersWestMayfield/${id}/status`] = "Paid";
  }
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}/uid`] = key;
  }
  let amount = 0;
  let flag = false;

  db.ref(`customersWestMayfield/${id}`)
    .once("value")
    .then((snapshot) => {
      // Get all histories of current customer and sort them by date
      let histories = snapshot.val().history;
      histories = _.sortBy(histories, (history) => {
        let data = history.time.split("/");
        return [data[2], data[0], data[1]].join("-");
      }).reverse();
      // Loop the invoices and check if it's paid.
      _.map(histories, (history) => {
        if (history.status === "Invoice Sent" && history.sent) {
          if (history.paid === true) return true;
          if (
            amount >= parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10)
          ) {
            flag = true;
          }
          updates[`${path}/${history.uid}/paid`] = flag;
          updates[`${path}/${history.uid}/description`] = "Completed invoice";
          db.ref().update(updates);
        } else if (history.status === "paid") {
          amount += parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10);
        }
      });
    });
  updates[`/customersWestMayfield/${id}/amount_due`] = amount_due;
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "paid";
  updates[`/customersWestMayfield/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/amount`] = `$${amount_paid}`;
  updates[`${path}/${key}/description`] = `${description}`;
  updates[`${path}/${key}/type_of_payment`] = `${type_of_payment}`;
  updates[`${path}/${key}/type_of_transaction`] = `${type_of_transaction}`;

  if (apply_to_charge) {
    const charge = await (
      await db.ref(`${path}/${apply_to_charge}`).once("value")
    ).val();
    updates[`${path}/${apply_to_charge}/description`] =
      (charge.description ? `${charge.description}\n` : "") +
      `${moment().format("MM/DD/YYYY")} Paid $${amount_paid}`;
  }

  return db.ref().update(updates);
};

export const doEastButlerMakePayment = async (
  id,
  time,
  amount_due,
  amount_paid,
  old_amount,
  description,
  key,
  apply_to_charge,
  type_of_payment,
  type_of_transaction,
  type_of_reason
) => {
  const path = `/customersEastButler/${id}/history`;
  let updates = {};

  amount_due = parseFloat(amount_due, 10) - amount_paid + old_amount;
  amount_due = parseFloat(amount_due).toFixed(2);
  if (
    amount_due <= 0 &&
    (type_of_reason === undefined || type_of_reason !== "Other: Keep Suspended")
  ) {
    updates[`/customersEastButler/${id}/status`] = "Paid";
  }
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}/uid`] = key;
  }
  let amount = 0;
  let flag = false;

  db.ref(`customersEastButler/${id}`)
    .once("value")
    .then((snapshot) => {
      // Get all histories of current customer and sort them by date
      let histories = snapshot.val().history;
      histories = _.sortBy(histories, (history) => {
        let data = history.time.split("/");
        return [data[2], data[0], data[1]].join("-");
      }).reverse();
      // Loop the invoices and check if it's paid.
      _.map(histories, (history) => {
        if (history.status === "Invoice Sent" && history.sent) {
          if (history.paid === true) return true;
          if (
            amount >= parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10)
          ) {
            flag = true;
          }
          updates[`${path}/${history.uid}/paid`] = flag;
          updates[`${path}/${history.uid}/description`] = "Completed invoice";
          db.ref().update(updates);
        } else if (history.status === "paid") {
          amount += parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10);
        }
      });
    });
  updates[`/customersEastButler/${id}/amount_due`] = amount_due;
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "paid";
  updates[`/customersEastButler/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/amount`] = `$${amount_paid}`;
  updates[`${path}/${key}/description`] = `${description}`;
  updates[`${path}/${key}/type_of_payment`] = `${type_of_payment}`;
  updates[`${path}/${key}/type_of_transaction`] = `${type_of_transaction}`;

  if (apply_to_charge) {
    const charge = await (
      await db.ref(`${path}/${apply_to_charge}`).once("value")
    ).val();
    updates[`${path}/${apply_to_charge}/description`] =
      (charge.description ? `${charge.description}\n` : "") +
      `${moment().format("MM/DD/YYYY")} Paid $${amount_paid}`;
  }

  return db.ref().update(updates);
};

export const doSlipperyRockMakePayment = async (
  id,
  time,
  amount_due,
  amount_paid,
  old_amount,
  description,
  key,
  apply_to_charge,
  type_of_payment,
  type_of_transaction,
  type_of_reason
) => {
  const path = `/customersSlipperyRock/${id}/history`;
  let updates = {};

  amount_due = parseFloat(amount_due, 10) - amount_paid + old_amount;
  amount_due = parseFloat(amount_due).toFixed(2);
  if (
    amount_due <= 0 &&
    (type_of_reason === undefined || type_of_reason !== "Other: Keep Suspended")
  ) {
    updates[`/customersSlipperyRock/${id}/status`] = "Paid";
  }
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}/uid`] = key;
  }
  let amount = 0;
  let flag = false;

  db.ref(`customersSlipperyRock/${id}`)
    .once("value")
    .then((snapshot) => {
      // Get all histories of current customer and sort them by date
      let histories = snapshot.val().history;
      histories = _.sortBy(histories, (history) => {
        let data = history.time.split("/");
        return [data[2], data[0], data[1]].join("-");
      }).reverse();
      // Loop the invoices and check if it's paid.
      _.map(histories, (history) => {
        if (history.status === "Invoice Sent" && history.sent) {
          if (history.paid === true) return true;
          if (
            amount >= parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10)
          ) {
            flag = true;
          }
          updates[`${path}/${history.uid}/paid`] = flag;
          updates[`${path}/${history.uid}/description`] = "Completed invoice";
          db.ref().update(updates);
        } else if (history.status === "paid") {
          amount += parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10);
        }
      });
    });
  updates[`/customersSlipperyRock/${id}/amount_due`] = amount_due;
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "paid";
  updates[`/customersSlipperyRock/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/amount`] = `$${amount_paid}`;
  updates[`${path}/${key}/description`] = `${description}`;
  updates[`${path}/${key}/type_of_payment`] = `${type_of_payment}`;
  updates[`${path}/${key}/type_of_transaction`] = `${type_of_transaction}`;

  if (apply_to_charge) {
    const charge = await (
      await db.ref(`${path}/${apply_to_charge}`).once("value")
    ).val();
    updates[`${path}/${apply_to_charge}/description`] =
      (charge.description ? `${charge.description}\n` : "") +
      `${moment().format("MM/DD/YYYY")} Paid $${amount_paid}`;
  }

  return db.ref().update(updates);
};

export const doMakePayment = async (
  id,
  time,
  amount_due,
  amount_paid,
  old_amount,
  description,
  key,
  apply_to_charge,
  type_of_payment,
  type_of_transaction,
  type_of_reason
) => {
  const path = `/customers/${id}/history`;
  let updates = {};

  amount_due = parseFloat(amount_due, 10) - amount_paid + old_amount;
  amount_due = parseFloat(amount_due).toFixed(2);
  if (
    amount_due <= 0 &&
    (type_of_reason === undefined || type_of_reason !== "Other: Keep Suspended")
  ) {
    updates[`/customers/${id}/status`] = "Paid";
  }
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}/uid`] = key;
  }
  let amount = 0;
  let flag = false;

  db.ref(`customers/${id}`)
    .once("value")
    .then((snapshot) => {
      // Get all histories of current customer and sort them by date
      let histories = snapshot.val().history;
      histories = _.sortBy(histories, (history) => {
        let data = history.time.split("/");
        return [data[2], data[0], data[1]].join("-");
      }).reverse();
      // Loop the invoices and check if it's paid.
      _.map(histories, (history) => {
        if (history.status === "Invoice Sent" && history.sent) {
          if (history.paid === true) return true;
          if (
            amount >= parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10)
          ) {
            flag = true;
          }
          updates[`${path}/${history.uid}/paid`] = flag;
          updates[`${path}/${history.uid}/description`] = "Completed invoice";
          db.ref().update(updates);
        } else if (history.status === "paid") {
          amount += parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10);
        }
      });
    });
  updates[`/customers/${id}/amount_due`] = amount_due;
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "paid";
  updates[`/customers/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/amount`] = `$${amount_paid}`;
  updates[`${path}/${key}/description`] = `${description}`;
  updates[`${path}/${key}/type_of_payment`] = `${type_of_payment}`;
  updates[`${path}/${key}/type_of_transaction`] = `${type_of_transaction}`;

  if (apply_to_charge) {
    const charge = await (
      await db.ref(`${path}/${apply_to_charge}`).once("value")
    ).val();
    updates[`${path}/${apply_to_charge}/description`] =
      (charge.description ? `${charge.description}\n` : "") +
      `${moment().format("MM/DD/YYYY")} Paid $${amount_paid}`;
  }

  return db.ref().update(updates);
};

export const doResidentialChargeCustomer = (
  id,
  time,
  amount_due,
  amount_charged,
  old_amount,
  service_description,
  description,
  key,
  type_of_payment,
  type_of_transaction,
  type_of_reason,
  status
) => {
  const path = `/customersResidential/${id}/history`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}/uid`] = key;
  }

  updates[`/customersResidential/${id}/amount_due`] =
    parseFloat(amount_due, 10) +
    parseFloat(amount_charged, 10) -
    parseFloat(old_amount, 10);
  updates[`${path}/${key}/time`] = time;
  console.log(status);
  if (status.toLowerCase() !== "Suspended".toLowerCase()) {
    updates[`/customersResidential/${id}/status`] = "charged";
  }
  updates[`/customersResidential/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/amount`] = `- $${amount_charged}`;
  updates[`${path}/${key}/description`] = `${description}`;
  updates[`${path}/${key}/service_description`] = `${service_description}`;
  updates[`${path}/${key}/type_of_payment`] = `${type_of_payment}`;
  updates[`${path}/${key}/type_of_transaction`] = `${type_of_transaction}`;
  //Need this still even if not suspended for history purposes only
  updates[`${path}/${key}/status`] = "charged";

  return db.ref().update(updates);
};

export const doNewCastle2ChargeCustomer = (
  id,
  time,
  amount_due,
  amount_charged,
  old_amount,
  service_description,
  description,
  key,
  type_of_payment,
  type_of_transaction,
  type_of_reason,
  status
) => {
  const path = `/customersNewCastle2/${id}/history`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}/uid`] = key;
  }
  updates[`/customersNewCastle2/${id}/amount_due`] =
    parseFloat(amount_due, 10) +
    parseFloat(amount_charged, 10) -
    parseFloat(old_amount, 10);
  updates[`${path}/${key}/time`] = time;
  console.log(status);
  if (status.toLowerCase() !== "Suspended".toLowerCase()) {
    updates[`/customersNewCastle2/${id}/status`] = "charged";
  }
  updates[`/customersNewCastle2/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/amount`] = `- $${amount_charged}`;
  updates[`${path}/${key}/description`] = `${description}`;
  updates[`${path}/${key}/service_description`] = `${service_description}`;
  updates[`${path}/${key}/type_of_payment`] = `${type_of_payment}`;
  updates[`${path}/${key}/type_of_transaction`] = `${type_of_transaction}`;
  //Need this still even if not suspended for history purposes only
  updates[`${path}/${key}/status`] = "charged";

  return db.ref().update(updates);
};

export const doWestMayfieldChargeCustomer = (
  id,
  time,
  amount_due,
  amount_charged,
  old_amount,
  service_description,
  description,
  key,
  type_of_payment,
  type_of_transaction,
  type_of_reason,
  status
) => {
  const path = `/customersWestMayfield/${id}/history`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}/uid`] = key;
  }
  updates[`/customersWestMayfield/${id}/amount_due`] =
    parseFloat(amount_due, 10) +
    parseFloat(amount_charged, 10) -
    parseFloat(old_amount, 10);
  updates[`${path}/${key}/time`] = time;
  console.log(status);
  if (status.toLowerCase() !== "Suspended".toLowerCase()) {
    updates[`/customersWestMayfield/${id}/status`] = "charged";
  }
  updates[`/customersWestMayfield/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/amount`] = `- $${amount_charged}`;
  updates[`${path}/${key}/description`] = `${description}`;
  updates[`${path}/${key}/service_description`] = `${service_description}`;
  updates[`${path}/${key}/type_of_payment`] = `${type_of_payment}`;
  updates[`${path}/${key}/type_of_transaction`] = `${type_of_transaction}`;
  //Need this still even if not suspended for history purposes only
  updates[`${path}/${key}/status`] = "charged";

  return db.ref().update(updates);
};

export const doEastButlerChargeCustomer = (
  id,
  time,
  amount_due,
  amount_charged,
  old_amount,
  service_description,
  description,
  key,
  type_of_payment,
  type_of_transaction,
  type_of_reason,
  status
) => {
  const path = `/customersEastButler/${id}/history`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}/uid`] = key;
  }
  updates[`/customersEastButler/${id}/amount_due`] =
    parseFloat(amount_due, 10) +
    parseFloat(amount_charged, 10) -
    parseFloat(old_amount, 10);
  updates[`${path}/${key}/time`] = time;
  console.log(status);
  if (status.toLowerCase() !== "Suspended".toLowerCase()) {
    updates[`/customersEastButler/${id}/status`] = "charged";
  }
  updates[`/customersEastButler/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/amount`] = `- $${amount_charged}`;
  updates[`${path}/${key}/description`] = `${description}`;
  updates[`${path}/${key}/service_description`] = `${service_description}`;
  updates[`${path}/${key}/type_of_payment`] = `${type_of_payment}`;
  updates[`${path}/${key}/type_of_transaction`] = `${type_of_transaction}`;
  //Need this still even if not suspended for history purposes only
  updates[`${path}/${key}/status`] = "charged";

  return db.ref().update(updates);
};

export const doSlipperyRockChargeCustomer = (
  id,
  time,
  amount_due,
  amount_charged,
  old_amount,
  service_description,
  description,
  key,
  type_of_payment,
  type_of_transaction,
  type_of_reason,
  status
) => {
  const path = `/customersSlipperyRock/${id}/history`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}/uid`] = key;
  }
  updates[`/customersSlipperyRock/${id}/amount_due`] =
    parseFloat(amount_due, 10) +
    parseFloat(amount_charged, 10) -
    parseFloat(old_amount, 10);
  updates[`${path}/${key}/time`] = time;
  console.log(status);
  if (status.toLowerCase() !== "Suspended".toLowerCase()) {
    updates[`/customersSlipperyRock/${id}/status`] = "charged";
  }
  updates[`/customersSlipperyRock/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/amount`] = `- $${amount_charged}`;
  updates[`${path}/${key}/description`] = `${description}`;
  updates[`${path}/${key}/service_description`] = `${service_description}`;
  updates[`${path}/${key}/type_of_payment`] = `${type_of_payment}`;
  updates[`${path}/${key}/type_of_transaction`] = `${type_of_transaction}`;
  //Need this still even if not suspended for history purposes only
  updates[`${path}/${key}/status`] = "charged";

  return db.ref().update(updates);
};

export const doChargeCustomer = (
  id,
  time,
  amount_due,
  amount_charged,
  old_amount,
  service_description,
  description,
  key,
  type_of_payment,
  type_of_transaction,
  type_of_reason,
  status
) => {
  const path = `/customers/${id}/history`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}/uid`] = key;
  }
  updates[`/customers/${id}/amount_due`] =
    parseFloat(amount_due, 10) +
    parseFloat(amount_charged, 10) -
    parseFloat(old_amount, 10);
  updates[`${path}/${key}/time`] = time;
  console.log(status);
  if (status.toLowerCase() !== "Suspended".toLowerCase()) {
    updates[`/customers/${id}/status`] = "charged";
  }
  updates[`/customers/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/amount`] = `- $${amount_charged}`;
  updates[`${path}/${key}/description`] = `${description}`;
  updates[`${path}/${key}/service_description`] = `${service_description}`;
  updates[`${path}/${key}/type_of_payment`] = `${type_of_payment}`;
  updates[`${path}/${key}/type_of_transaction`] = `${type_of_transaction}`;
  //Need this still even if not suspended for history purposes only
  updates[`${path}/${key}/status`] = "charged";

  return db.ref().update(updates);
};

export const doSendInvoice = (
  id,
  key,
  time,
  due_date,
  sent,
  amount,
  invoiceName,
  type_of_reason
) => {
  const path = `/customers/${id}/history`;
  if (key === "new") {
    key = db.ref().child(path).push().key;

    db.ref(`/users/${id}`).once("value").then();
  }
  let updates = {};
  updates[`/customers/${id}/status`] = "Charged";
  updates[`${path}/${key}/uid`] = key;
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "Invoice Sent";
  updates[`/customers/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/due_date`] = due_date;
  updates[`${path}/${key}/sent`] = sent;
  updates[`${path}/${key}/invoiceName`] = invoiceName;
  if (sent) {
    updates[`${path}/${key}/sent_date`] = moment().format("L");
    updates[`${path}/${key}/amount`] = `$${amount}`;
  }
  return db.ref().update(updates);
};

export const doUpdateInvoiceDates = (id, time, due_date, key) => {
  const path = `/customers/${id}/history`;
  let updates = {};
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/due_date`] = due_date;
  return db.ref().update(updates);
};

export const doResidentialSuspendAccount = (
  id,
  time,
  currentNotes,
  note,
  type_of_reason
) => {
  console.log(type_of_reason);
  const path = `/customersResidential/${id}/history`;
  const key = db.ref().child(path).push().key;
  let updates = {};
  updates[`/customersResidential/${id}/status`] = "Suspended";
  updates[`/customersResidential/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "Account Suspended";
  updates[
    `${path}/${key}/description`
  ] = `Account suspended because: ${note} - Suspended on date: ${time}`;

  let obj = {
    date: moment().format(),
    note: `Account suspended because: ${note} - Suspended on date: ${time}`,
  };
  doAddNotesResidential(id, obj, "");

  return db.ref().update(updates);
};

export const doNewCastleSuspendAccount = (
  id,
  time,
  currentNotes,
  note,
  type_of_reason
) => {
  console.log(type_of_reason);
  const path = `/customersNewCastle2/${id}/history`;
  const key = db.ref().child(path).push().key;
  let updates = {};
  updates[`/customersNewCastle2/${id}/status`] = "Suspended";
  updates[`/customersNewCastle2/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "Account Suspended";
  updates[
    `${path}/${key}/description`
  ] = `Account suspended because: ${note} - Suspended on date: ${time}`;

  let obj = {
    date: moment().format(),
    note: `Account suspended because: ${note} - Suspended on date: ${time}`,
  };
  doAddNotesNewCastle2(id, obj, "");

  return db.ref().update(updates);
};

export const doWestMayfieldSuspendAccount = (
  id,
  time,
  currentNotes,
  note,
  type_of_reason
) => {
  console.log(type_of_reason);
  const path = `/customersWestMayfield/${id}/history`;
  const key = db.ref().child(path).push().key;
  let updates = {};
  updates[`/customersWestMayfield/${id}/status`] = "Suspended";
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "Account Suspended";
  updates[`/customersWestMayfield/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[
    `${path}/${key}/description`
  ] = `Account suspended because: ${note} - Suspended on date: ${time}`;

  let obj = {
    date: moment().format(),
    note: `Account suspended because: ${note} - Suspended on date: ${time}`,
  };
  doAddNotesWestMayfield(id, obj, "");

  return db.ref().update(updates);
};

export const doEastButlerSuspendAccount = (
  id,
  time,
  currentNotes,
  note,
  type_of_reason
) => {
  console.log(type_of_reason);
  const path = `/customersEastButler/${id}/history`;
  const key = db.ref().child(path).push().key;
  let updates = {};
  updates[`/customersEastButler/${id}/status`] = "Suspended";
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "Account Suspended";
  updates[`/customersEastButler/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[
    `${path}/${key}/description`
  ] = `Account suspended because: ${note} - Suspended on date: ${time}`;

  let obj = {
    date: moment().format(),
    note: `Account suspended because: ${note} - Suspended on date: ${time}`,
  };
  doAddNotesEastButler(id, obj, "");

  return db.ref().update(updates);
};

export const doSlipperyRockSuspendAccount = (
  id,
  time,
  currentNotes,
  note,
  type_of_reason
) => {
  console.log(type_of_reason);
  const path = `/customersSlipperyRock/${id}/history`;
  const key = db.ref().child(path).push().key;
  let updates = {};
  updates[`/customersSlipperyRock/${id}/status`] = "Suspended";
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "Account Suspended";
  updates[`/customersSlipperyRock/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[
    `${path}/${key}/description`
  ] = `Account suspended because: ${note} - Suspended on date: ${time}`;

  let obj = {
    date: moment().format(),
    note: `Account suspended because: ${note} - Suspended on date: ${time}`,
  };
  doAddNotesSlipperyRock(id, obj, "");

  return db.ref().update(updates);
};

export const doSuspendAccount = (
  id,
  time,
  currentNotes,
  note,
  type_of_reason
) => {
  console.log(type_of_reason);
  const path = `/customers/${id}/history`;
  const key = db.ref().child(path).push().key;
  let updates = {};
  updates[`/customers/${id}/status`] = "Suspended";
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "Account Suspended";
  updates[`/customers/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[
    `${path}/${key}/description`
  ] = `Account suspended because: ${note} - Suspended on date: ${time}`;

  let obj = {
    date: moment().format(),
    note: `Account suspended because: ${note} - Suspended on date: ${time}`,
  };
  doAddNotes(id, obj, "");

  return db.ref().update(updates);
};

export const doWestMayfieldUnsuspendAccount = (
  id,
  time,
  currentNotes,
  note,
  amount_due,
  type_of_reason
) => {
  const path = `/customersWestMayfield/${id}/history`;
  const key = db.ref().child(path).push().key;
  let updates = {};
  if (
    amount_due <= 0 &&
    (type_of_reason === undefined || type_of_reason !== "Other: Keep Suspended")
  ) {
    updates[`/customersWestMayfield/${id}/status`] = "Paid";
  } else {
    updates[`/customersWestMayfield/${id}/status`] = "Charged";
  }
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "Account Unsuspended";
  updates[`/customersWestMayfield/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/description`] = `Unsuspended on date: ${time}`;

  let obj = {
    date: moment().format(),
    note: `Unsuspended on date: ${time}`,
  };
  doAddNotesWestMayfield(id, obj, "");

  return db.ref().update(updates);
};

export const doEastButlerUnsuspendAccount = (
  id,
  time,
  currentNotes,
  note,
  amount_due,
  type_of_reason
) => {
  const path = `/customersEastButler/${id}/history`;
  const key = db.ref().child(path).push().key;
  let updates = {};
  if (
    amount_due <= 0 &&
    (type_of_reason === undefined || type_of_reason !== "Other: Keep Suspended")
  ) {
    updates[`/customersEastButler/${id}/status`] = "Paid";
  } else {
    updates[`/customersEastButler/${id}/status`] = "Charged";
  }
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "Account Unsuspended";
  updates[`/customersEastButler/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/description`] = `Unsuspended on date: ${time}`;

  let obj = {
    date: moment().format(),
    note: `Unsuspended on date: ${time}`,
  };
  doAddNotesEastButler(id, obj, "");

  return db.ref().update(updates);
};

export const doSlipperyRockUnsuspendAccount = (
  id,
  time,
  currentNotes,
  note,
  amount_due,
  type_of_reason
) => {
  const path = `/customersSlipperyRock/${id}/history`;
  const key = db.ref().child(path).push().key;
  let updates = {};
  if (
    amount_due <= 0 &&
    (type_of_reason === undefined || type_of_reason !== "Other: Keep Suspended")
  ) {
    updates[`/customersSlipperyRock/${id}/status`] = "Paid";
  } else {
    updates[`/customersSlipperyRock/${id}/status`] = "Charged";
  }
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "Account Unsuspended";
  updates[`/customersSlipperyRock/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/description`] = `Unsuspended on date: ${time}`;

  let obj = {
    date: moment().format(),
    note: `Unsuspended on date: ${time}`,
  };
  doAddNotesSlipperyRock(id, obj, "");

  return db.ref().update(updates);
};

export const doResidentialUnsuspendAccount = (
  id,
  time,
  currentNotes,
  note,
  amount_due,
  type_of_reason
) => {
  const path = `/customersResidential/${id}/history`;
  const key = db.ref().child(path).push().key;
  let updates = {};
  if (
    amount_due <= 0 &&
    (type_of_reason === undefined || type_of_reason !== "Other: Keep Suspended")
  ) {
    updates[`/customersResidential/${id}/status`] = "Paid";
  } else {
    updates[`/customersResidential/${id}/status`] = "Charged";
  }
  updates[`/customersResidential/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "Account Unsuspended";
  updates[`${path}/${key}/description`] = `Unsuspended on date: ${time}`;

  let obj = {
    date: moment().format(),
    note: `Unsuspended on date: ${time}`,
  };
  doAddNotesResidential(id, obj, "");

  return db.ref().update(updates);
};
export const doNewCastleUnsuspendAccount = (
  id,
  time,
  currentNotes,
  note,
  amount_due,
  type_of_reason
) => {
  const path = `/customersNewCastle2/${id}/history`;
  const key = db.ref().child(path).push().key;
  let updates = {};
  if (
    amount_due <= 0 &&
    (type_of_reason === undefined || type_of_reason !== "Other: Keep Suspended")
  ) {
    updates[`/customersNewCastle2/${id}/status`] = "Paid";
  } else {
    updates[`/customersNewCastle2/${id}/status`] = "Charged";
  }
  updates[`/customersNewCastle2/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "Account Unsuspended";
  updates[`${path}/${key}/description`] = `Unsuspended on date: ${time}`;

  let obj = {
    date: moment().format(),
    note: `Unsuspended on date: ${time}`,
  };
  doAddNotesNewCastle2(id, obj, "");

  return db.ref().update(updates);
};

export const doUnsuspendAccount = (
  id,
  time,
  currentNotes,
  note,
  amount_due,
  type_of_reason
) => {
  const path = `/customers/${id}/history`;
  const key = db.ref().child(path).push().key;
  let updates = {};
  if (
    amount_due <= 0 &&
    (type_of_reason === undefined || type_of_reason !== "Other: Keep Suspended")
  ) {
    updates[`/customers/${id}/status`] = "Paid";
  } else {
    updates[`/customers/${id}/status`] = "Charged";
  }
  updates[`/customers/${id}/type_of_reason`] = `${type_of_reason}`;
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/status`] = "Account Unsuspended";
  updates[`${path}/${key}/description`] = `Unsuspended on date: ${time}`;

  let obj = {
    date: moment().format(),
    note: `Unsuspended on date: ${time}`,
  };
  doAddNotes(id, obj, "");

  return db.ref().update(updates);
};

export const doSuspendStop = (id) => {
  const path = `/stops/${id}/status`;
  let updates = {};
  updates[path] = "suspended";
  return db.ref().update(updates);
};

export const doResumeStop = (id) => {
  const path = `/stops/${id}/status`;
  let updates = {};
  updates[path] = "pending";
  return db.ref().update(updates);
};

export const doUpdateStop = (id, data) => {
  const path = `/stops/${id}`;
  let updates = {};
  updates[path] = data;
  return db.ref().update(updates);
};

export const doResidentialAddCustomer = (customer) => {
  const key = db.ref().child("customersResidential").push().key;
  customer.uid = key;

  let updates = {};
  updates[`/customersResidential/${key}`] = customer;
  db.ref().update(updates);
  return key;
};
export const doNewCastleAddCustomer = (customer) => {
  const key = db.ref().child("customersNewCastle").push().key;
  customer.uid = key;

  let updates = {};
  updates[`/customersNewCastle/${key}`] = customer;
  db.ref().update(updates);
  return key;
};

export const doNewCastle2AddCustomer = (customer) => {
  const key = db.ref().child("customersNewCastle2").push().key;
  customer.uid = key;

  let updates = {};
  updates[`/customersNewCastle2/${key}`] = customer;
  db.ref().update(updates);
  return key;
};

export const doWestMayfieldAddCustomer = (customer) => {
  const key = db.ref().child("customersWestMayfield").push().key;
  customer.uid = key;

  let updates = {};
  updates[`/customersWestMayfield/${key}`] = customer;
  db.ref().update(updates);
  return key;
};

export const doEastButlerAddCustomer = (customer) => {
  const key = db.ref().child("customersEastButler").push().key;
  customer.uid = key;

  let updates = {};
  updates[`/customersEastButler/${key}`] = customer;
  db.ref().update(updates);
  return key;
};

export const doSlipperyRockAddCustomer = (customer) => {
  const key = db.ref().child("customersSlipperyRock").push().key;
  customer.uid = key;

  let updates = {};
  updates[`/customersSlipperyRock/${key}`] = customer;
  db.ref().update(updates);
  return key;
};

export const doAddGreenBagCustomer = (customer) => {
  const key = db.ref().child("customersGreenBag").push().key;
  customer.uid = key;

  let updates = {};
  updates[`/customersGreenBag/${key}`] = customer;
  db.ref().update(updates);
  return key;
};

export const doAddCustomer = (customer) => {
  const key = db.ref().child("customers").push().key;
  customer.uid = key;

  let updates = {};
  updates[`/customers/${key}`] = customer;
  db.ref().update(updates);
  return key;
};

// testing

// export const doAddRoute = () => {
//   const key = db
//     .ref()
//     .child("routes")
//     .push().key;
//   let data = {
//     name: "New Route",
//     stops: [],
//     status: "In Progress",
//     load_side: "FL"
//   };
//   let updates = {};
//   data.uid = key;
//   updates[`/routes/${key}`] = data;
//   db.ref().update(updates);
//   return key;
// };
export const doAddRoute = (priority) => {
  const key = db.ref().child("routes").push().key;
  let data = {
    name: "New Route",
    stops: [],
    status: "In Progress",
    load_side: "FL",
    priority: priority,
  };
  let updates = {};
  data.uid = key;
  updates[`/routes/${key}`] = data;
  db.ref().update(updates);
  return key;
};

export const doAddStop = (routeId, index, data) => {
  const key = db.ref().child("stops").push().key;

  let updates = {};
  let stop = data;

  stop.uid = key;
  updates[`/routes/${routeId}/stops/${index}`] = key;

  // stopIds.unshift(key)
  // updates[`/routes/${routeId}/stops`] = stopIds;
  updates[`/stops/${key}`] = stop;
  return db.ref().update(updates);
};

// db.doAddStopToRoutes(uidLengths, stops[0]);

export const doAddStopToRoutes = (uidLengths, data) => {
  let updates = {};
  let stop = data;
  uidLengths.forEach(({ uid, stopIds }) => {
    const key = db.ref().child("stops").push().key;

    stop.uid = key;
    stopIds.unshift(key);

    updates[`/stops/${key}`] = stop;
    updates[`/routes/${uid}/stops`] = stopIds;
  });
  return db.ref().update(updates);
};

export const doReorderStop = (routeId, order) => {
  const path = "/routes/" + routeId;
  return db
    .ref(path)
    .once("value")
    .then((snapshot) => {
      const stops = snapshot.val().stops;
      let updatedOrder = [];
      stops.map((stop) => {
        updatedOrder.push(stop);
        return true;
      });
      let updates = {};
      updates[`${path}/stops`] = updatedOrder;
      return db.ref().update(updates);
    })
    .catch((error) => {
      console.log("error in reorder stop", error);
    });
};

export const deleteUser = (id) => {
  const path = `/users/${id}`;
  return db.ref(path).remove();
};

export const addClosedAccounts = (customer) => {
  let updates = {};
  updates[`/closedAccounts/${customer.uid}`] = customer;
  db.ref().update(updates);
};

export const deleteClosedCustomer = (id) => {
  const path = `/closedAccounts/${id}`;
  return db.ref(path).remove();
};

export const deleteCustomer = (id) => {
  const path = `/customers/${id}`;
  return db.ref(path).remove();
};

export const deleteResidentialCustomer = (id) => {
  const path = `/customersResidential/${id}`;
  return db.ref(path).remove();
};

export const deleteWestMayfieldCustomer = (id) => {
  const path = `/customersWestMayfield/${id}`;
  return db.ref(path).remove();
};

export const deleteEastButlerCustomer = (id) => {
  const path = `/customersEastButler/${id}`;
  return db.ref(path).remove();
};

export const deleteSlipperyRockCustomer = (id) => {
  const path = `/customersSlipperyRock/${id}`;
  return db.ref(path).remove();
};

export const deleteNewCastleCustomer = (id) => {
  const path = `/customersNewCastle/${id}`;
  return db.ref(path).remove();
};

export const deleteNewCastleCustomer2 = (id) => {
  const path = `/customersNewCastle2/${id}`;
  return db.ref(path).remove();
};

export const deleteGreenBagCustomer = (id) => {
  const path = `/customersGreenBag/${id}`;
  return db.ref(path).remove();
};

export const deleteInvoiceHistory = (historyId, customer_id) => {
  console.log("firebase", historyId, customer_id);
  const path = `/customersNewCastle/${customer_id}/history`;
  return db.ref(`${path}/${historyId}`).remove();
};

export const deleteRoute = (id) => {
  const path = `/routes/${id}`;
  return db.ref(path).remove();
};

export const deleteStop = (id) => {
  const path = `/stops/${id}`;
  return db.ref(path).remove();
};

export const deleteStopFromRoute = (stopIndex, routeId) => {
  const path = `/routes/${routeId}/stops/${stopIndex}`;
  return db.ref(path).remove();
};

export const doAddNewCastleCustomer = (customer) => {
  const key = db.ref().child("customersNewCastle").push().key;

  customer.uid = key;
  let updates = {};
  updates[`/customersNewCastle/${key}`] = customer;
  db.ref().update(updates);
  return key;
};

export const updateCustomerPrices = async (file, path) => {
  try {
    var snapshot;
    var updates = {};
    var updatePath = "";
    let invalidFormatting = false;

    if (path === "COMMERCIAL") {
      updatePath = "customers";
      snapshot = await readCustomers();
    } else if (path === "RESIDENTIAL") {
      updatePath = "customersResidential";
      snapshot = await readResidentialCustomers();
    } else if (path === "WESTMAYFIELD") {
      updatePath = "customersWestMayfield";
      snapshot = await readWestMayfieldCustomers();
    } else if (path === "EASTBUTLER") {
      updatePath = "customersEastButler";
      snapshot = await readEastButlerCustomers();
    } else if (path === "SLIPPERYROCK") {
      updatePath = "customersSlipperyRock";
      snapshot = await readSlipperyRockCustomers();
    } else if (path === "NEWCASTLE") {
      updatePath = "customersNewCastle2";
      snapshot = await readCustomerNewCastle2();
    }

    const customers = snapshot.val();
    readXlsxFile(file).then((rows) => {
      invalidFormatting = rows.length === 0;
      rows.forEach((col, index) => {
        Object.keys(customers).forEach((key) => {
          let customer = customers[key];
          if (customer["Account Number"] === col[0] && index !== 0) {
            let newPrice = col[3];
            if (newPrice !== undefined && typeof newPrice === "number") {
              customer.rate = newPrice.toFixed(2).toString();
              updates[`/${updatePath}/${key}`] = customer;
              db.ref().update(updates);
              updates = {};
            } else {
              invalidFormatting = true;
            }
          }
        });
      });
      if (invalidFormatting) {
        toastr.warning(
          "It seems there are some errors with formatting in the spreadsheet. Some records might not have updated correctly. Please double check the sheet.",
          "Warning"
        );
      } else {
        toastr.success("Customer prices updated successfully.", "Success");
      }
      return db.ref().update(updates);
    });
  } catch (e) {
    return e;
  }
};

export const doChangePaymentHistoryNewCastle = (
  customer_uid,
  history_uid,
  amount,
  paymentSource,
  year,
  quarter,
  description,
  time,
  type_of_payment,
  type_of_transaction,
  type_of_reason
) => {
  const path = `/customersNewCastle/${customer_uid}/history`;
  let updates = {};
  updates[`${path}/${history_uid}/amount`] = `$${amount}`;
  updates[`${path}/${history_uid}/paymentSource`] = paymentSource;
  updates[`${path}/${history_uid}/year`] = year;
  updates[`${path}/${history_uid}/quarter`] = quarter;
  updates[`${path}/${history_uid}/description`] = description;
  updates[`${path}/${history_uid}/time`] = time;
  updates[`${path}/${history_uid}/type_of_payment`] = `${type_of_payment}`;
  updates[
    `${path}/${history_uid}/type_of_transaction`
  ] = `${type_of_transaction}`;
  return db.ref().update(updates);
};

export const doMakePaymentNewCastle = (
  id,
  time,
  amount_due,
  amount_paid,
  old_amount,
  description,
  key,
  quarter,
  serviceLevel,
  year,
  type_of_payment,
  type_of_transaction,
  type_of_reason
) => {
  const path = `/customersNewCastle/${id}/history`;
  let updates = {};

  amount_due = parseFloat(amount_due, 10) - amount_paid;
  amount_due = parseFloat(amount_due).toFixed(2);
  if (
    amount_due <= 0 &&
    (type_of_reason === "Unpaid Bill" || type_of_reason === "")
  ) {
    updates[`/customersNewCastle/${id}/Status`] = "Paid";
  }
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}/uid`] = key;
  }
  let amount = 0;
  let flag = false;
  db.ref(`customersNewCastle/${id}`)
    .once("value")
    .then((snapshot) => {
      // Get all histories of current customer and sort them by date
      let histories = snapshot.val().history;
      histories = _.sortBy(histories, (history) => {
        let data = history.time.split("/");
        return [data[2], data[0], data[1]].join("-");
      }).reverse();
      // Loop the invoices and check if it's paid.
      _.map(histories, (history) => {
        if (history.Status === "Invoice Sent" && history.sent) {
          if (history.paid === true) return true;
          if (
            amount >= parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10)
          ) {
            flag = true;
          }
          updates[`${path}/${history.uid}/paid`] = flag;
          updates[`${path}/${history.uid}/description`] = "Completed invoice";
          db.ref().update(updates);
        } else if (history.Status === "paid") {
          amount += parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10);
        }
      });
    });
  updates[`/customersNewCastle/${id}/amount_due`] = amount_due;
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/Status`] = "paid";
  updates[`${path}/${key}/amount`] = `$${amount_paid}`;
  updates[`${path}/${key}/description`] = `${description}`;
  updates[`${path}/${key}/quarter`] = `${quarter}`;
  updates[`${path}/${key}/year`] = `${year}`;
  updates[`${path}/${key}/servicelevel`] = `${serviceLevel}`;
  updates[`${path}/${key}/type_of_payment`] = `${type_of_payment}`;
  updates[`${path}/${key}/type_of_transaction`] = `${type_of_transaction}`;
  return db.ref().update(updates);
};

export const doChargeCustomerNewCastle = (
  id,
  time,
  amount_due,
  amount_charged,
  old_amount,
  description,
  key,
  type_of_payment,
  type_of_transaction
) => {
  const path = `/customersNewCastle/${id}/history`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}/uid`] = key;
  }
  updates[`/customersNewCastle/${id}/amount_due`] =
    parseFloat(amount_due, 10) +
    parseFloat(amount_charged, 10) -
    parseFloat(old_amount, 10);
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/Status`] = "charged";
  updates[`${path}/${key}/amount`] = `- $${amount_charged}`;
  updates[`${path}/${key}/description`] = `${description}`;
  updates[`${path}/${key}/type_of_payment`] = `${type_of_payment}`;
  updates[`${path}/${key}/type_of_transaction`] = `${type_of_transaction}`;
  return db.ref().update(updates);
};

export const doUpdateInvoiceDatesNewCastle = (id, time, due_date, key) => {
  const path = `/customersNewCastle/${id}/history`;
  let updates = {};
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/due_date`] = due_date;
  return db.ref().update(updates);
};

export const doSuspendAccountNewCastle = (id, time, currentNotes, note) => {
  const path = `/customersNewCastle/${id}/history`;
  const key = db.ref().child(path).push().key;
  let updates = {};
  updates[`/customersNewCastle/${id}/Status`] = "Suspended";
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/Status`] = "Account Suspended";
  updates[
    `${path}/${key}/description`
  ] = `Account suspended because: ${note} - Suspended on date: ${time}`;

  let obj = {
    date: moment().format(),
    note: `Account suspended because: ${note} - Suspended on date: ${time}`,
  };
  doAddNotesNewCastle(id, obj, "");

  return db.ref().update(updates);
};

export const doUnsuspendAccountNewCastle = (
  id,
  time,
  currentNotes,
  note,
  amount_due
) => {
  const path = `/customersNewCastle/${id}/history`;
  const key = db.ref().child(path).push().key;
  let updates = {};
  if (amount_due <= 0) {
    updates[`/customersNewCastle/${id}/Status`] = "Paid";
  } else {
    updates[`/customersNewCastle/${id}/Status`] = "Charged";
  }
  updates[`${path}/${key}/time`] = time;
  updates[`${path}/${key}/Status`] = "Account Unsuspended";
  updates[`${path}/${key}/description`] = `Unsuspended on date: ${time}`;

  let obj = {
    date: moment().format(),
    note: `Unsuspended on date: ${time}`,
  };
  doAddNotesNewCastle(id, obj, "");

  return db.ref().update(updates);
};

export const billMonthResidentialCustomer = async (customer, month, year) => {
  try {
    let new_customer = { ...customer };
    if (customer.status === "Suspended" || customer.status === "suspended") {
      new_customer.status = "Suspended";
    } else {
      new_customer.status = "Charged";
    }
    let history = customer.history !== undefined ? customer.history : {};
    let timestamp = moment().unix();
    let amount_due =
      customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
    amount_due += Number(customer.rate);
    new_customer = Object.assign(new_customer, {
      amount_due: amount_due.toString(),
    });
    history = Object.assign(history, {
      [timestamp]: {
        amount: `- $${customer.rate}`,
        description: `TRASH PICKUP FOR ${month} ${year}`,
        service_description: "AIKEN TRASH",
        status: "charged",
        time: moment().format("MM/DD/YYYY"),
        uid: timestamp,
      },
    });
    new_customer.history = history;
    let updates = {};
    updates[`/customersResidential/${customer.uid}`] = new_customer;
    return db.ref().update(updates);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billMonthNewCastleCustomer = async (customer, month, year) => {
  try {
    let new_customer = { ...customer };
    if (customer.status === "Suspended" || customer.status === "suspended") {
      new_customer.status = "Suspended";
    } else {
      new_customer.status = "Charged";
    }
    let history = customer.history !== undefined ? customer.history : {};
    let timestamp = moment().unix();
    let amount_due =
      customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
    amount_due += Number(customer.rate);
    new_customer = Object.assign(new_customer, {
      amount_due: amount_due.toString(),
    });
    history = Object.assign(history, {
      [timestamp]: {
        amount: `- $${customer.rate}`,
        description: `TRASH PICKUP FOR ${month} ${year}`,
        service_description: "AIKEN TRASH",
        status: "charged",
        time: moment().format("MM/DD/YYYY"),
        uid: timestamp,
      },
    });
    new_customer.history = history;
    let updates = {};
    updates[`/customersNewCastle2/${customer.uid}`] = new_customer;
    return db.ref().update(updates);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billMonthWestMayfieldCustomer = async (customer, month) => {
  try {
    let new_customer = { ...customer };
    if (customer.status === "Suspended" || customer.status === "suspended") {
      new_customer.status = "Suspended";
    } else {
      new_customer.status = "Charged";
    }
    let history = customer.history !== undefined ? customer.history : {};
    let timestamp = moment().unix();
    let amount_due =
      customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
    amount_due += Number(customer.rate);
    new_customer = Object.assign(new_customer, {
      amount_due: amount_due.toString(),
    });
    history = Object.assign(history, {
      [timestamp]: {
        amount: `- $${customer.rate}`,
        description: `TRASH PICKUP FOR ${month}`,
        service_description: "AIKEN TRASH",
        status: "charged",
        time: moment().format("MM/DD/YYYY"),
        uid: timestamp,
      },
    });
    new_customer.history = history;
    let updates = {};
    updates[`/customersWestMayfield/${customer.uid}`] = new_customer;
    return db.ref().update(updates);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billMonthEastButlerCustomer = async (customer, month) => {
  try {
    let new_customer = { ...customer };
    if (customer.status === "Suspended" || customer.status === "suspended") {
      new_customer.status = "Suspended";
    } else {
      new_customer.status = "Charged";
    }
    let history = customer.history !== undefined ? customer.history : {};
    let timestamp = moment().unix();
    let amount_due =
      customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
    amount_due += Number(customer.rate);
    new_customer = Object.assign(new_customer, {
      amount_due: amount_due.toString(),
    });
    history = Object.assign(history, {
      [timestamp]: {
        amount: `- $${customer.rate}`,
        description: `TRASH PICKUP FOR ${month}`,
        service_description: "AIKEN TRASH",
        status: "charged",
        time: moment().format("MM/DD/YYYY"),
        uid: timestamp,
      },
    });
    new_customer.history = history;
    let updates = {};
    updates[`/customersEastButler/${customer.uid}`] = new_customer;
    return db.ref().update(updates);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billMonthSlipperyRockCustomer = async (customer, month) => {
  try {
    let new_customer = { ...customer };
    if (customer.status === "Suspended" || customer.status === "suspended") {
      new_customer.status = "Suspended";
    } else {
      new_customer.status = "Charged";
    }
    let history = customer.history !== undefined ? customer.history : {};
    let timestamp = moment().unix();
    let amount_due =
      customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
    amount_due += Number(customer.rate);
    new_customer = Object.assign(new_customer, {
      amount_due: amount_due.toString(),
    });
    history = Object.assign(history, {
      [timestamp]: {
        amount: `- $${customer.rate}`,
        description: `TRASH PICKUP FOR ${month}`,
        service_description: "AIKEN TRASH",
        status: "charged",
        time: moment().format("MM/DD/YYYY"),
        uid: timestamp,
      },
    });
    new_customer.history = history;
    let updates = {};
    updates[`/customersSlipperyRock/${customer.uid}`] = new_customer;
    return db.ref().update(updates);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billMonthCommercialCustomer = async (customer, month, year) => {
  try {
    let new_customer = { ...customer };
    if (customer.status === "Suspended" || customer.status === "suspended") {
      new_customer.status = "Suspended";
    } else {
      new_customer.status = "Charged";
    }
    let history = customer.history !== undefined ? customer.history : {};
    let timestamp = moment().unix();
    let amount_due =
      customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
    amount_due += Number(customer.rate);
    new_customer = Object.assign(new_customer, {
      amount_due: amount_due.toString(),
    });
    history = Object.assign(history, {
      [timestamp]: {
        amount: `- $${customer.rate}`,
        description: month,
        service_description:
          customer.dumpsters !== undefined &&
          customer.dumpsters[0].type !== undefined &&
          customer.dumpsters[0].frequency !== undefined
            ? `${customer.dumpsters[0].type},${customer.dumpsters[0].frequency}`
            : "",
        description: `TRASH PICKUP FOR ${month} ${year}`,
        status: "charged",
        time: moment().format("MM/DD/YYYY"),
        uid: timestamp,
      },
    });
    new_customer.history = history;
    let updates = {};
    updates[`/customers/${customer.uid}`] = new_customer;
    return db.ref().update(updates);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billAllResidentialCustomers = async (quarter, year) => {
  try {
    const snapshot = await readResidentialCustomers();
    const customers = snapshot.val();

    var customersNew = {};

    const mapping_description = {
      "1st Quarter": "JAN, FEB, and MAR",
      "2nd Quarter": "APR, MAY, and JUN",
      "3rd Quarter": "JUL, AUG, and SEP",
      "4th Quarter": "OCT, NOV, and DEC",
    };

    Object.keys(customers).forEach((key) => {
      let customer = customers[key];
      if (
        customer.billFrequency !== "monthly" &&
        customer.isExempt !== "Yes" &&
        customer.recurringCustomer !== "Yes" &&
        customer.isInactive !== "Yes"
      ) {
        if (
          customer.status === "Suspended" ||
          customer.status === "suspended"
        ) {
          customer.status = "Suspended";
        } else {
          customer.status = "Charged";
        }
        let history = customer.history !== undefined ? customer.history : {};
        let timestamp = moment().unix();
        let amount_due =
          customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
        amount_due += Number(3 * Number(customer.rate));
        customer = Object.assign(customer, {
          amount_due: amount_due.toString(),
        });
        history = Object.assign(history, {
          [timestamp]: {
            amount: `- $${3 * Number(customer.rate)}`,
            description: `TRASH PICKUP FOR ${mapping_description[quarter]} ${year}`,
            service_description: "AIKEN TRASH",
            status: "charged",
            time: moment().format("MM/DD/YYYY"),
            uid: timestamp,
          },
        });
        customer.history = history;
      }
      customersNew[key] = customer;
      //Switched to individual update calls because number of customers in the database node is now to large for a single update.
      //We might have to do this small change to other bill all tabs if they too become to large.
      db.ref(`/customersResidential`).update(customersNew);
      customersNew = {};
    });

    //return db.ref(`/customersResidential`).update(customersNew);
    console.log("DONE");
  } catch (e) {
    return Promise.reject(e);
  }
};
export const billAllWestMayfieldCustomers = async (quarter, year) => {
  try {
    const snapshot = await readWestMayfieldCustomers();
    const customers = snapshot.val();

    const customersNew = {};

    const mapping_description = {
      "1st Quarter": "JAN, FEB, and MAR",
      "2nd Quarter": "APR, MAY, and JUN",
      "3rd Quarter": "JUL, AUG, and SEP",
      "4th Quarter": "OCT, NOV, and DEC",
    };

    Object.keys(customers).forEach((key) => {
      let customer = customers[key];
      if (
        customer.billFrequency !== "monthly" &&
        customer.isExempt !== "Yes" &&
        customer.recurringCustomer !== "Yes" &&
        customer.isInactive !== "Yes"
      ) {
        if (
          customer.status === "Suspended" ||
          customer.status === "suspended"
        ) {
          customer.status = "Suspended";
        } else {
          customer.status = "Charged";
        }
        let history = customer.history !== undefined ? customer.history : {};
        let timestamp = moment().unix();
        let amount_due =
          customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
        amount_due += Number(3 * Number(customer.rate));
        customer = Object.assign(customer, {
          amount_due: amount_due.toString(),
        });
        history = Object.assign(history, {
          [timestamp]: {
            amount: `- $${3 * Number(customer.rate)}`,
            description: `TRASH PICKUP FOR ${mapping_description[quarter]} ${year}`,
            service_description: "AIKEN TRASH",
            status: "charged",
            time: moment().format("MM/DD/YYYY"),
            uid: timestamp,
          },
        });
        customer.history = history;
      }
      customersNew[key] = customer;
    });
    return db.ref(`/customersWestMayfield`).update(customersNew);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billAllEastButlerCustomers = async (quarter, year) => {
  try {
    const snapshot = await readEastButlerCustomers();
    const customers = snapshot.val();

    const customersNew = {};

    const mapping_description = {
      "1st Quarter": "JAN, FEB, and MAR",
      "2nd Quarter": "APR, MAY, and JUN",
      "3rd Quarter": "JUL, AUG, and SEP",
      "4th Quarter": "OCT, NOV, and DEC",
    };

    Object.keys(customers).forEach((key) => {
      let customer = customers[key];
      if (
        customer.billFrequency !== "monthly" &&
        customer.isExempt !== "Yes" &&
        customer.recurringCustomer !== "Yes" &&
        customer.isInactive !== "Yes"
      ) {
        if (
          customer.status === "Suspended" ||
          customer.status === "suspended"
        ) {
          customer.status = "Suspended";
        } else {
          customer.status = "Charged";
        }
        let history = customer.history !== undefined ? customer.history : {};
        let timestamp = moment().unix();
        let amount_due =
          customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
        amount_due += Number(3 * Number(customer.rate));
        customer = Object.assign(customer, {
          amount_due: amount_due.toString(),
        });
        history = Object.assign(history, {
          [timestamp]: {
            amount: `- $${3 * Number(customer.rate)}`,
            description: `TRASH PICKUP FOR ${mapping_description[quarter]} ${year}`,
            service_description: "AIKEN TRASH",
            status: "charged",
            time: moment().format("MM/DD/YYYY"),
            uid: timestamp,
          },
        });
        customer.history = history;
      }
      customersNew[key] = customer;
    });
    return db.ref(`/customersEastButler`).update(customersNew);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billAllSlipperyRockCustomers = async (quarter, year) => {
  try {
    const snapshot = await readSlipperyRockCustomers();
    const customers = snapshot.val();

    const customersNew = {};

    const mapping_description = {
      "1st Quarter": "JAN, FEB, and MAR",
      "2nd Quarter": "APR, MAY, and JUN",
      "3rd Quarter": "JUL, AUG, and SEP",
      "4th Quarter": "OCT, NOV, and DEC",
    };

    Object.keys(customers).forEach((key) => {
      let customer = customers[key];
      if (
        customer.billFrequency !== "monthly" &&
        customer.isExempt !== "Yes" &&
        customer.recurringCustomer !== "Yes" &&
        customer.isInactive !== "Yes"
      ) {
        if (
          customer.status === "Suspended" ||
          customer.status === "suspended"
        ) {
          customer.status = "Suspended";
        } else {
          customer.status = "Charged";
        }
        let history = customer.history !== undefined ? customer.history : {};
        let timestamp = moment().unix();
        let amount_due =
          customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
        amount_due += Number(3 * Number(customer.rate));
        customer = Object.assign(customer, {
          amount_due: amount_due.toString(),
        });
        history = Object.assign(history, {
          [timestamp]: {
            amount: `- $${3 * Number(customer.rate)}`,
            description: `TRASH PICKUP FOR ${mapping_description[quarter]} ${year}`,
            service_description: "AIKEN TRASH",
            status: "charged",
            time: moment().format("MM/DD/YYYY"),
            uid: timestamp,
          },
        });
        customer.history = history;
      }
      customersNew[key] = customer;
    });
    return db.ref(`/customersSlipperyRock`).update(customersNew);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billAllNewCastle2Customers = async (quarter, year) => {
  try {
    const snapshot = await readCustomersNewCastle2();
    const customers = snapshot.val();

    const customersNew = {};

    const mapping_description = {
      "1st Quarter": "JAN, FEB, and MAR",
      "2nd Quarter": "APR, MAY, and JUN",
      "3rd Quarter": "JUL, AUG, and SEP",
      "4th Quarter": "OCT, NOV, and DEC",
    };

    Object.keys(customers).forEach((key) => {
      let customer = customers[key];
      if (
        customer.billFrequency !== "monthly" &&
        customer.isExempt !== "Yes" &&
        customer.recurringCustomer !== "Yes" &&
        customer.isInactive !== "Yes"
      ) {
        if (
          customer.status === "Suspended" ||
          customer.status === "suspended"
        ) {
          customer.status = "Suspended";
        } else {
          customer.status = "Charged";
        }
        let history = customer.history !== undefined ? customer.history : {};
        let timestamp = moment().unix();
        let amount_due =
          customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
        amount_due += Number(3 * Number(customer.rate));
        customer = Object.assign(customer, {
          amount_due: amount_due.toString(),
        });
        history = Object.assign(history, {
          [timestamp]: {
            amount: `- $${3 * Number(customer.rate)}`,
            description: `TRASH PICKUP FOR ${mapping_description[quarter]} ${year}`,
            service_description: "AIKEN TRASH",
            status: "charged",
            time: moment().format("MM/DD/YYYY"),
            uid: timestamp,
          },
        });
        customer.history = history;
      }
      customersNew[key] = customer;
    });
    return db.ref(`/customersNewCastle2`).update(customersNew);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billAllResidentialCustomersRecuring = async (quarter, year) => {
  try {
    const snapshot = await readResidentialCustomers();
    const customers = snapshot.val();

    const customersNew = {};

    const mapping_description = {
      "1st Quarter": "JAN, FEB, and MAR",
      "2nd Quarter": "APR, MAY, and JUN",
      "3rd Quarter": "JUL, AUG, and SEP",
      "4th Quarter": "OCT, NOV, and DEC",
    };

    Object.keys(customers).forEach((key) => {
      let customer = customers[key];
      if (customer.recurringCustomer == "Yes") {
        if (
          customer.status === "Suspended" ||
          customer.status === "suspended"
        ) {
          customer.status = "Suspended";
        } else {
          customer.status = "Charged";
        }
        let history = customer.history !== undefined ? customer.history : {};
        let timestamp = moment().unix();
        let amount_due =
          customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
        amount_due += Number(3 * Number(customer.rate));
        customer = Object.assign(customer, {
          amount_due: amount_due.toString(),
        });
        history = Object.assign(history, {
          [timestamp]: {
            amount: `- $${3 * Number(customer.rate)}`,
            description: `TRASH PICKUP FOR ${mapping_description[quarter]} ${year}`,
            service_description: "AIKEN TRASH",
            status: "charged",
            time: moment().format("MM/DD/YYYY"),
            uid: timestamp,
          },
        });
        customer.history = history;
      }
      customersNew[key] = customer;
      db.ref(`/customersResidential/${key}`).update(customer);
    });
    return true; //db.ref(`/customersResidential`).update(customersNew);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billAllWestMayfieldCustomersRecuring = async (quarter, year) => {
  try {
    const snapshot = await readWestMayfieldCustomers();
    const customers = snapshot.val();

    const customersNew = {};

    const mapping_description = {
      "1st Quarter": "JAN, FEB, and MAR",
      "2nd Quarter": "APR, MAY, and JUN",
      "3rd Quarter": "JUL, AUG, and SEP",
      "4th Quarter": "OCT, NOV, and DEC",
    };

    Object.keys(customers).forEach((key) => {
      let customer = customers[key];
      if (customer.recurringCustomer == "Yes") {
        if (
          customer.status === "Suspended" ||
          customer.status === "suspended"
        ) {
          customer.status = "Suspended";
        } else {
          customer.status = "Charged";
        }
        let history = customer.history !== undefined ? customer.history : {};
        let timestamp = moment().unix();
        let amount_due =
          customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
        amount_due += Number(3 * Number(customer.rate));
        customer = Object.assign(customer, {
          amount_due: amount_due.toString(),
        });
        history = Object.assign(history, {
          [timestamp]: {
            amount: `- $${3 * Number(customer.rate)}`,
            description: `TRASH PICKUP FOR ${mapping_description[quarter]} ${year}`,
            service_description: "AIKEN TRASH",
            status: "charged",
            time: moment().format("MM/DD/YYYY"),
            uid: timestamp,
          },
        });
        customer.history = history;
      }
      customersNew[key] = customer;
    });
    return db.ref(`/customersWestMayfield`).update(customersNew);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billAllEastButlerCustomersRecuring = async (quarter, year) => {
  try {
    const snapshot = await readEastButlerCustomers();
    const customers = snapshot.val();

    const customersNew = {};

    const mapping_description = {
      "1st Quarter": "JAN, FEB, and MAR",
      "2nd Quarter": "APR, MAY, and JUN",
      "3rd Quarter": "JUL, AUG, and SEP",
      "4th Quarter": "OCT, NOV, and DEC",
    };

    Object.keys(customers).forEach((key) => {
      let customer = customers[key];
      if (customer.recurringCustomer == "Yes") {
        if (
          customer.status === "Suspended" ||
          customer.status === "suspended"
        ) {
          customer.status = "Suspended";
        } else {
          customer.status = "Charged";
        }
        let history = customer.history !== undefined ? customer.history : {};
        let timestamp = moment().unix();
        let amount_due =
          customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
        amount_due += Number(3 * Number(customer.rate));
        customer = Object.assign(customer, {
          amount_due: amount_due.toString(),
        });
        history = Object.assign(history, {
          [timestamp]: {
            amount: `- $${3 * Number(customer.rate)}`,
            description: `TRASH PICKUP FOR ${mapping_description[quarter]} ${year}`,
            service_description: "AIKEN TRASH",
            status: "charged",
            time: moment().format("MM/DD/YYYY"),
            uid: timestamp,
          },
        });
        customer.history = history;
      }
      customersNew[key] = customer;
    });
    return db.ref(`/customersEastButler`).update(customersNew);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billAllSlipperyRockCustomersRecuring = async (quarter, year) => {
  try {
    const snapshot = await readSlipperyRockCustomers();
    const customers = snapshot.val();

    const customersNew = {};

    const mapping_description = {
      "1st Quarter": "JAN, FEB, and MAR",
      "2nd Quarter": "APR, MAY, and JUN",
      "3rd Quarter": "JUL, AUG, and SEP",
      "4th Quarter": "OCT, NOV, and DEC",
    };

    Object.keys(customers).forEach((key) => {
      let customer = customers[key];
      if (customer.recurringCustomer == "Yes") {
        if (
          customer.status === "Suspended" ||
          customer.status === "suspended"
        ) {
          customer.status = "Suspended";
        } else {
          customer.status = "Charged";
        }
        let history = customer.history !== undefined ? customer.history : {};
        let timestamp = moment().unix();
        let amount_due =
          customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
        amount_due += Number(3 * Number(customer.rate));
        customer = Object.assign(customer, {
          amount_due: amount_due.toString(),
        });
        history = Object.assign(history, {
          [timestamp]: {
            amount: `- $${3 * Number(customer.rate)}`,
            description: `TRASH PICKUP FOR ${mapping_description[quarter]} ${year}`,
            service_description: "AIKEN TRASH",
            status: "charged",
            time: moment().format("MM/DD/YYYY"),
            uid: timestamp,
          },
        });
        customer.history = history;
      }
      customersNew[key] = customer;
    });
    return db.ref(`/customersSlipperyRock`).update(customersNew);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billAllRecuringCustomers = async (quarter, year) => {
  try {
    const snapshot = await readCustomers();
    const customers = snapshot.val();

    const customersNew = {};

    const mapping_description = {
      "1st Quarter": "JAN, FEB, MAR",
      "2nd Quarter": "APR, MAY, JUN",
      "3rd Quarter": "JUL, AUG, SEP",
      "4th Quarter": "OCT, NOV, DEC",
    };

    Object.keys(customers).forEach((key) => {
      let customer = customers[key];
      if (customer.recurringCustomer == "Yes") {
        if (
          customer.status === "Suspended" ||
          customer.status === "suspended"
        ) {
          customer.status = "Suspended";
        } else {
          customer.status = "Charged";
        }
        let history = customer.history !== undefined ? customer.history : {};
        let timestamp = moment().unix();
        let amount_due =
          customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
        amount_due += Number(3 * Number(customer.rate));
        customer = Object.assign(customer, {
          amount_due: amount_due.toString(),
        });
        history = Object.assign(history, {
          [timestamp]: {
            amount: `- $${3 * Number(customer.rate)}`,
            description: `TRASH PICKUP FOR ${mapping_description[quarter]} ${year}`,
            service_description:
              customer.dumpsters !== undefined &&
              customer.dumpsters[0].type !== undefined &&
              customer.dumpsters[0].frequency !== undefined
                ? `${customer.dumpsters[0].type},${customer.dumpsters[0].frequency}`
                : "",
            status: "charged",
            time: moment().format("MM/DD/YYYY"),
            uid: timestamp,
          },
        });
        customer.history = history;
      }
      customersNew[key] = customer;
    });

    return db.ref(`/customers`).update(customersNew);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billAllCommercialCustomers = async (quarter, year) => {
  try {
    const snapshot = await readCustomers();
    const customers = snapshot.val();

    const customersNew = {};

    const mapping_description = {
      "1st Quarter": "JAN, FEB, MAR",
      "2nd Quarter": "APR, MAY, JUN",
      "3rd Quarter": "JUL, AUG, SEP",
      "4th Quarter": "OCT, NOV, DEC",
    };

    Object.keys(customers).forEach((key) => {
      let customer = customers[key];
      if (
        customer.billFrequency !== "monthly" &&
        customer.isExempt !== "Yes" &&
        customer.recurringCustomer !== "Yes" &&
        customer.isInactive !== "Yes"
      ) {
        if (
          customer.status === "Suspended" ||
          customer.status === "suspended"
        ) {
          customer.status = "Suspended";
        } else {
          customer.status = "Charged";
        }
        let history = customer.history !== undefined ? customer.history : {};
        let timestamp = moment().unix();
        let amount_due =
          customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
        amount_due += Number(3 * Number(customer.rate));
        customer = Object.assign(customer, {
          amount_due: amount_due.toString(),
        });
        history = Object.assign(history, {
          [timestamp]: {
            amount: `- $${3 * Number(customer.rate)}`,
            description: `TRASH PICKUP FOR ${mapping_description[quarter]} ${year}`,
            service_description:
              customer.dumpsters !== undefined &&
              customer.dumpsters[0].type !== undefined &&
              customer.dumpsters[0].frequency !== undefined
                ? `${customer.dumpsters[0].type},${customer.dumpsters[0].frequency}`
                : "",
            status: "charged",
            time: moment().format("MM/DD/YYYY"),
            uid: timestamp,
          },
        });
        customer.history = history;
      }
      customersNew[key] = customer;
    });
    console.log(customersNew);
    return db.ref(`/customers`).update(customersNew);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billAllNewCastleCustomersRecuring = async (quarter, year) => {
  try {
    const snapshot = await readCustomersNewCastle2();
    const customers = snapshot.val();
    const customersNew = {};

    const mapping_description = {
      "1st Quarter": "JAN, FEB, and MAR",
      "2nd Quarter": "APR, MAY, and JUN",
      "3rd Quarter": "JUL, AUG, and SEP",
      "4th Quarter": "OCT, NOV, and DEC",
    };

    Object.keys(customers).forEach((key) => {
      let customer = customers[key];
      if (customer.recurringCustomer == "Yes") {
        if (
          customer.status === "Suspended" ||
          customer.status === "suspended"
        ) {
          customer.status = "Suspended";
        } else {
          customer.status = "Charged";
        }
        console.log("customer:", key);
        let history = customer.history !== undefined ? customer.history : {};
        let timestamp = moment().unix();
        let amount_due =
          customer.amount_due !== undefined ? Number(customer.amount_due) : 0;
        amount_due += Number(3 * Number(customer.rate));
        customer = Object.assign(customer, {
          amount_due: amount_due.toString(),
        });
        history = Object.assign(history, {
          [timestamp]: {
            amount: `- $${3 * Number(customer.rate)}`,
            description: `TRASH PICKUP FOR ${mapping_description[quarter]} ${year}`,
            service_description: "AIKEN TRASH",
            status: "charged",
            time: moment().format("MM/DD/YYYY"),
            uid: timestamp,
          },
        });
        customer.history = history;
      }
      customersNew[key] = customer;
    });
    return db.ref(`/customersNewCastle2`).update(customersNew);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const billAllNewCastleCustomers = async (quarter, year) => {
  try {
    const snapshot = await readCustomersNewCastle();
    const customers = snapshot.val();

    const customersNew = {};

    Object.keys(customers).forEach((key) => {
      const customer = customers[key];
      let existance = 0;
      if (customer.history !== undefined) {
        for (var item in customer.history) {
          if (
            customer.history[item]["quarter"] === quarter &&
            customer.history[item]["year"] === year
          )
            existance = 1;
        }
      }
      if (existance === 0) customer.Status = "Charged";
      customersNew[key] = customer;
    });

    return db.ref(`/customersNewCastle`).update(customersNew);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteChargeOrPayment = async (customer, amount, historyId) => {
  try {
    if (customer.status === "Suspended" || customer.status === "suspended") {
      customer.status = "Suspended";
    } else {
      customer.status = "Charged";
    }
    const { uid } = customer;
    const path = `/customers/${uid}/history`;
    let updates = {};
    const amount_due = customer.amount_due - amount;
    updates[`/customers/${uid}/amount_due`] = amount_due;

    if (customer.type_of_reason !== "Other: Keep Suspended") {
      updates[`/customers/${uid}/status`] =
        amount_due <= 0 ? "Paid" : "Charged";
    }

    await db.ref(`${path}/${historyId}`).remove();
    return db.ref().update(updates);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteWestMayfieldChargeOrPayment = async (
  customer,
  amount,
  historyId
) => {
  try {
    const { uid } = customer;
    const path = `/customersWestMayfield/${uid}/history`;
    let updates = {};
    const amount_due = customer.amount_due - amount;
    updates[`/customersWestMayfield/${uid}/amount_due`] = amount_due;
    if (customer.type_of_reason !== "Other: Keep Suspended") {
      updates[`/customersWestMayfield/${uid}/status`] =
        amount_due <= 0 ? "Paid" : "Charged";
    }
    await db.ref(`${path}/${historyId}`).remove();
    return db.ref().update(updates);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteEastButlerChargeOrPayment = async (
  customer,
  amount,
  historyId
) => {
  try {
    const { uid } = customer;
    const path = `/customersEastButler/${uid}/history`;
    let updates = {};
    const amount_due = customer.amount_due - amount;
    updates[`/customersEastButler/${uid}/amount_due`] = amount_due;
    if (customer.type_of_reason !== "Other: Keep Suspended") {
      updates[`/customersEastButler/${uid}/status`] =
        amount_due <= 0 ? "Paid" : "Charged";
    }
    await db.ref(`${path}/${historyId}`).remove();
    return db.ref().update(updates);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteSlipperyRockChargeOrPayment = async (
  customer,
  amount,
  historyId
) => {
  try {
    const { uid } = customer;
    const path = `/customersSlipperyRock/${uid}/history`;
    let updates = {};
    const amount_due = customer.amount_due - amount;
    updates[`/customersSlipperyRock/${uid}/amount_due`] = amount_due;
    if (customer.type_of_reason !== "Other: Keep Suspended") {
      updates[`/customersSlipperyRock/${uid}/status`] =
        amount_due <= 0 ? "Paid" : "Charged";
    }
    await db.ref(`${path}/${historyId}`).remove();
    return db.ref().update(updates);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteResidentialChargeOrPayment = async (
  customer,
  amount,
  historyId
) => {
  try {
    const { uid } = customer;
    const path = `/customersResidential/${uid}/history`;
    let updates = {};
    const amount_due = customer.amount_due - amount;
    updates[`/customersResidential/${uid}/amount_due`] = amount_due;
    if (customer.type_of_reason !== "Other: Keep Suspended") {
      updates[`/customersResidential/${uid}/status`] =
        amount_due <= 0 ? "Paid" : "Charged";
    }
    await db.ref(`${path}/${historyId}`).remove();
    return db.ref().update(updates);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const deleteNewCastle2ChargeOrPayment = async (
  customer,
  amount,
  historyId
) => {
  try {
    const { uid } = customer;
    const path = `/customersNewCastle2/${uid}/history`;
    let updates = {};
    const amount_due = customer.amount_due - amount;
    updates[`/customersNewCastle2/${uid}/amount_due`] = amount_due;
    if (customer.type_of_reason !== "Other: Keep Suspended") {
      updates[`/customersNewCastle2/${uid}/status`] =
        amount_due <= 0 ? "Paid" : "Charged";
    }
    await db.ref(`${path}/${historyId}`).remove();
    return db.ref().update(updates);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const doCallHistory = (id, data, key) => {
  let values = data;
  const path = `/customers/${id}/callHistory`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};
export const deleteCallHistory = async (id, callId) => {
  const path = `/customers/${id}/callHistory`;
  const res = await db.ref(`${path}/${callId}`).remove();
  console.log(res, "delete response");
  return res;
};

export const doAddNotes = (id, data, key) => {
  let values = data;
  const path = `/customers/${id}/notes`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};

export const doDeleteNote = async (id, noteId) => {
  const path = `/customers/${id}/notes`;
  const res = await db.ref(`${path}/${noteId}`).remove();
  console.log(res, "delete response");
  return res;
};

// customersResidential

export const doResidentialCallHistory = (id, data, key) => {
  let values = data;
  const path = `/customersResidential/${id}/callHistory`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};
export const deleteResidentialCallHistory = async (id, callId) => {
  const path = `/customersResidential/${id}/callHistory`;
  const res = await db.ref(`${path}/${callId}`).remove();
  console.log(res, "delete response");
  return res;
};

export const doAddNotesResidential = (id, data, key) => {
  let values = data;
  const path = `/customersResidential/${id}/notes`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};

export const doDeleteNoteResidential = async (id, noteId) => {
  const path = `/customersResidential/${id}/notes`;
  const res = await db.ref(`${path}/${noteId}`).remove();
  console.log(res, "delete response");
  return res;
};

//green bag customers

export const doGreenBagCallHistory = (id, data, key) => {
  let values = data;
  const path = `/customersGreenBag/${id}/callHistory`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};
export const deleteGreenBagCallHistory = async (id, callId) => {
  const path = `/customersGreenBag/${id}/callHistory`;
  const res = await db.ref(`${path}/${callId}`).remove();
  console.log(res, "delete response");
  return res;
};

export const doAddNotesGreenBag = (id, data, key) => {
  let values = data;
  const path = `/customersGreenBag/${id}/notes`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};

export const doDeleteNoteGreenBag = async (id, noteId) => {
  const path = `/customersGreenBag/${id}/notes`;
  const res = await db.ref(`${path}/${noteId}`).remove();
  console.log(res, "delete response");
  return res;
};

//customer westmayfield

export const doWestMayfieldCallHistory = (id, data, key) => {
  let values = data;
  const path = `/customersWestMayfield/${id}/callHistory`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};
export const deleteWestMayfieldlCallHistory = async (id, callId) => {
  const path = `/customersWestMayfield/${id}/callHistory`;
  const res = await db.ref(`${path}/${callId}`).remove();
  console.log(res, "delete response");
  return res;
};

export const doAddNotesWestMayfield = (id, data, key) => {
  let values = data;
  const path = `/customersWestMayfield/${id}/notes`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};

export const doDeleteNoteWestMayfield = async (id, noteId) => {
  const path = `/customersWestMayfield/${id}/notes`;
  const res = await db.ref(`${path}/${noteId}`).remove();
  console.log(res, "delete response");
  return res;
};

//customer EastButler

export const doEastButlerCallHistory = (id, data, key) => {
  let values = data;
  const path = `/customersEastButler/${id}/callHistory`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};
export const deleteEastButlerlCallHistory = async (id, callId) => {
  const path = `/customersEastButler/${id}/callHistory`;
  const res = await db.ref(`${path}/${callId}`).remove();
  console.log(res, "delete response");
  return res;
};

export const doAddNotesEastButler = (id, data, key) => {
  let values = data;
  const path = `/customersEastButler/${id}/notes`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};

export const doDeleteNoteEastButler = async (id, noteId) => {
  const path = `/customersEastButler/${id}/notes`;
  const res = await db.ref(`${path}/${noteId}`).remove();
  console.log(res, "delete response");
  return res;
};

//customer SlipperyRock

export const doSlipperyRockCallHistory = (id, data, key) => {
  let values = data;
  const path = `/customersSlipperyRock/${id}/callHistory`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};
export const deleteSlipperyRocklCallHistory = async (id, callId) => {
  const path = `/customersSlipperyRock/${id}/callHistory`;
  const res = await db.ref(`${path}/${callId}`).remove();
  console.log(res, "delete response");
  return res;
};

export const doAddNotesSlipperyRock = (id, data, key) => {
  let values = data;
  const path = `/customersSlipperyRock/${id}/notes`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};

export const doDeleteNoteSlipperyRock = async (id, noteId) => {
  const path = `/customersSlipperyRock/${id}/notes`;
  const res = await db.ref(`${path}/${noteId}`).remove();
  console.log(res, "delete response");
  return res;
};

export const doClosedCallHistory = (id, data, key) => {
  let values = data;
  const path = `/closedAccounts/${id}/callHistory`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};

export const deleteClosedCallHistory = async (id, callId) => {
  const path = `/closedAccounts/${id}/callHistory`;
  const res = await db.ref(`${path}/${callId}`).remove();
  console.log(res, "delete response");
  return res;
};

export const doAddNotesClosed = (id, data, key) => {
  let values = data;
  const path = `/closedAccounts/${id}/notes`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};

export const doDeleteNoteClosed = async (id, noteId) => {
  const path = `/closedAccounts/${id}/notes`;
  const res = await db.ref(`${path}/${noteId}`).remove();
  console.log(res, "delete response");
  return res;
};

//customersNewCastle

export const doNewCastleCallHistory = (id, data, key) => {
  let values = data;
  const path = `/customersNewCastle/${id}/callHistory`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};
export const deleteNewCastleCallHistory = async (id, callId) => {
  const path = `/customersNewCastle/${id}/callHistory`;
  const res = await db.ref(`${path}/${callId}`).remove();
  console.log(res, "delete response");
  return res;
};

export const doAddNotesNewCastle = (id, data, key) => {
  let values = data;
  const path = `/customersNewCastle/${id}/notes`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};

export const doDeleteNoteNewCastle = async (id, noteId) => {
  const path = `/customersNewCastle/${id}/notes`;
  const res = await db.ref(`${path}/${noteId}`).remove();
  console.log(res, "delete response");
  return res;
};

//customersNewCastle2

export const doNewCastle2CallHistory = (id, data, key) => {
  let values = data;
  const path = `/customersNewCastle2/${id}/callHistory`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};
export const deleteNewCastle2CallHistory = async (id, callId) => {
  const path = `/customersNewCastle2/${id}/callHistory`;
  const res = await db.ref(`${path}/${callId}`).remove();
  console.log(res, "delete response");
  return res;
};

export const doAddNotesNewCastle2 = (id, data, key) => {
  let values = data;
  const path = `/customersNewCastle2/${id}/notes`;
  let updates = {};
  if (!key) {
    key = db.ref().child(path).push().key;
    updates[`${path}/${key}`] = key;
  }
  values.uid = key;
  updates[`${path}/${key}`] = values;
  db.ref().update(updates);
  return key;
};

export const doDeleteNoteNewCastle2 = async (id, noteId) => {
  const path = `/customersNewCastle2/${id}/notes`;
  const res = await db.ref(`${path}/${noteId}`).remove();
  console.log(res, "delete response");
  return res;
};
