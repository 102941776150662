/* @format - for Prettier */
import React from "react";

import styled from "styled-components";
import { connect } from "react-redux";
import { Button, Glyphicon, Modal, Row, Col, Label } from "react-bootstrap";
import Select from "react-select";

import moment from "moment";

import { NewCastleCustomers as NewCastleCustomersActions } from "../../actions2";

import worker from "workerize-loader!./worker"; // eslint-disable-line import/no-webpack-loader-syntax

const Wrapper = styled.div``;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  margin: 10px;
  margin-right: 0;
`;

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`;

class MarkPaidDropDown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      present_uid: '',
      show: false,
      generatingPDF: false,
      progress: 0,
      moment: moment(),
      old_amount: 0,
      default_amount: 1.0,
      form: "",
      form_description: "",

      // state for Invoice Modal
      show_invoice_dialog: false,
      dueDate: moment().format("MM/DD/YYYY"),

      // states for Suspended Modal
      show_suspended_modal: false,
      note: "",
      selectedHistory: {
        uid: null
      },
      quarter: "",
      year: "",
      recieptAddress: "",
      serviceLevel: "",
      select_quarter: "",
      select_year: "",
      select_serviceLevel: "",
      select_recieptAddress: "",
      input_recieptAddress: "",
      other: false,
      recieptOptions: []
    };
    this.checkDisableMark = this.checkDisableMark.bind(this)
    this.serviceLevelChanged = this.serviceLevelChanged.bind(this)
    this.quarterChanged = this.quarterChanged.bind(this)
    this.yearChanged = this.yearChanged.bind(this)
    this.recieptAddressChanged = this.recieptAddressChanged.bind(this)
    this.input_recieptAddressChanged = this.input_recieptAddressChanged.bind(this)
  }

  checkDisableMark() {
    if (this.state.year.length && this.state.quarter.length && this.state.recieptAddress.length && this.state.serviceLevel) {
      return false
    }
    else {
      return true
    }
  }

  input_recieptAddressChanged(e) {
    this.setState({
      recieptAddress: e.target.value
    })
    localStorage.setItem(`aiken-refuse-other-recieptAddress-${this.props.customer.uid}`, e.target.value)
  }

  serviceLevelChanged = e => {
    this.setState({ 
      serviceLevel: e.value,
      select_serviceLevel: e
    });
    localStorage.setItem(`aiken-refuse-servicelevel-${this.props.customer.uid}`, JSON.stringify(e))
  };

  quarterChanged = e => {
    this.setState({ 
      quarter: e.value,
      select_quarter: e
    });
  }

  yearChanged = e => {
    this.setState({ 
      year: e.value,
      select_year: e
    });
  }

  recieptAddressChanged = e => {
    this.setState({
      recieptAddress: e.value === "3" ? "" : e.value,
      select_recieptAddress: e,
      other: e.value === "3" ? true : false
    });
    localStorage.setItem(`aiken-refuse-recieptAddress-${this.props.customer.uid}`, JSON.stringify(e))
  };
  
  componentDidMount() {
    if (localStorage.getItem(`aiken-refuse-recieptAddress-${this.props.customer.uid}`) != null) {
      const data = JSON.parse(localStorage.getItem(`aiken-refuse-recieptAddress-${this.props.customer.uid}`))
      this.setState({
        select_recieptAddress: data,
        recieptAddress: data.value === "3" ? localStorage.getItem(`aiken-refuse-other-recieptAddress-${this.props.customer.uid}`) : data.value,
        other: data.value === "3" ? true : false
      })
    }
    // if (localStorage.getItem("aiken-refuse-servicelevel") != null) {
    //   const data = JSON.parse(localStorage.getItem("aiken-refuse-servicelevel"))
    //   this.setState({
    //     serviceLevel: data.value,
    //     select_serviceLevel: data
    //   })
    // }
    this.setState({
      yearOptions: [
        {
          value: "2020",
          label: "2020"
        },
        {
          value: "2021",
          label: "2021"
        },
        {
          value: "2022",
          label: "2022"
        },
        {
          value: "2023",
          label: "2023"
        }
      ],
      quarterOptions: [
        {
          value: "1st Quarter",
          label: "1st Quarter"
        },
        {
          value: "2nd Quarter",
          label: "2nd Quarter"
        },
        {
          value: "3rd Quarter",
          label: "3rd Quarter"
        },
        {
          value: "4th Quarter",
          label: "4th Quarter"
        },
      ],
      serviceOptions: [
        {
            value: "48.00 - LEVEL 1",
            label: "LEVEL 1 - $48.00"
        },
        {
            value: "70.50 - LEVEL 2",
            label: "LEVEL 2 - $70.50"
        },
        {
            value: "93.00 - LEVEL 3",
            label: "LEVEL 3 - $93.00"
        }
      ],
      recieptOptions: [
        {
          value: this.props.customer["Physical Address"] + "\n" + this.props.customer["Physical City"] + ", " + this.props.customer["Physical State"] + " " + this.props.customer["Physical ZIP"] + " ",
          label: "Service Address - " + this.props.customer["Physical Address"] + "\n" + this.props.customer["Physical City"] + ", " + this.props.customer["Physical State"] + " " + this.props.customer["Physical ZIP"]
        },
        {
          value: this.props.customer["Address"] + "\n" + this.props.customer["City"] + ", " + this.props.customer["State"] + " " + this.props.customer["Location ZIP Code"],
          label: "Billing Address - " + this.props.customer["Address"] + "\n" + this.props.customer["City"] + ", " + this.props.customer["State"] + " " + this.props.customer["Location ZIP Code"]
        },
        {
          value: "3",
          label: "Other"
        }
      ]
    })
    let e = this.props.current_billing_quarter
    let e1 = this.props.current_billing_year
    this.setState({ 
      quarter: e.value,
      select_quarter: e,
      year: e1.value,
      select_year: e1
    });
  }

  onMessage = message => {

    if (message.data.done) {
      this.onProcessComplete();
    }
    
    if (message.data.file) {
      this.setState({
        progress: message.data.progress
      });

      this.onPdfGenerate(message.data.file, message.data.batch);
    }
  };

  onPdfGenerate = (blob, batch) => {
    const data = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = data;
    link.download = `newcastle_receipt_${moment().format("MMDDYYYY")}.pdf`;
    link.click();
    link.remove();
  };

  onProcessComplete = () => {
    this.props.billToggle(this.state.present_uid, "Paid"); //Marks customer paid

    // Update Customer Payment History 
    this.props.makePayment(
        this.props.customer,
        this.state.moment.format("YYYY-MM-DD"),
        parseFloat(this.state.serviceLevel),
        parseFloat(this.state.old_amount),
        ("Paid: $" + this.state.serviceLevel + " - Reciept Address: " + this.state.recieptAddress),
        this.state.selectedHistory.uid,
        this.state.quarter,
        this.state.serviceLevel.split(' - ')[1],
        this.state.year
    );

    // remove the worker
    this.worker.removeEventListener("message", this.onMessage);

    setTimeout(() => {
      this.setState({
        exporting: false,
        progress: 0,
        show: false,
        dueDate: null
      });
    }, 2000);
  };

  print = () => {
    this.setState({ exporting: true });
    this.worker.exportReceiptsToPDF(
      [this.props.customer],
      moment().format("MM/DD/YYYY"),
      this.state.dueDate,
      this.state.serviceLevel.split(' - ')[1],
      ' $' + this.state.serviceLevel.split(' - ')[0],
      this.state.recieptAddress,
      this.props.customer["Physical Address"] + "\n" + this.props.customer["Physical City"] + ", " + this.props.customer["Physical State"] + " " + this.props.customer["Physical ZIP"]
    );
  };

  toggleBillPay = uid => {

    // Creates the worker -- originally was in componentDidMount(), but was causing web page to crash so moved it down here. 
    this.worker = worker();
    this.worker.addEventListener("message", this.onMessage);
    
    this.setState({
      present_uid: uid
    })
    //TODO: Generate PDF For Download
    this.print();

    //Closes the Modal
    //this.hidePrintModal();
  };

  showPrintModal = () => {
    this.setState({
      show: true
    });
  };

  hidePrintModal = () => {
    this.setState({
      show: false
    });
  };

  render() { 
      return (
        <Wrapper>
                <Modal
                    show={this.state.show}
                    onHide={this.state.exporting ? () => {} : this.hidePrintModal}
                >
                  {
                    this.state.exporting ? (
                      <Modal.Body>
                        <h3>Downloading...</h3>
                        <p>This may take some time.</p>
                      </Modal.Body>
                    ) : (
                      <>
                        <Modal.Header>Please Choose</Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={12}>
                                    <Label>Service Level:</Label>
                                    <Select
                                        disabled={this.state.exporting}
                                        options={this.state.serviceOptions}
                                        value ={this.state.select_serviceLevel}
                                        onChange={this.serviceLevelChanged}
                                        getOptionLabel={option => option.label}
                                        getOptionValue={option => option.value}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Label>Year Level:</Label>
                                    <Select
                                        disabled={this.state.exporting}
                                        options={this.state.yearOptions}
                                        value ={this.state.select_year}
                                        onChange={this.yearChanged}
                                        getOptionLabel={option => option.label}
                                        getOptionValue={option => option.value}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Label>Quarter Level:</Label>
                                    <Select
                                        disabled={this.state.exporting}
                                        options={this.state.quarterOptions}
                                        value ={this.state.select_quarter}
                                        onChange={this.quarterChanged}
                                        getOptionLabel={option => option.label}
                                        getOptionValue={option => option.value}
                                    />
                                </Col>
                                <Col md={12}>
                                    <Label>Address For Reciept:</Label>
                                    <Select
                                        disabled={this.state.exporting}
                                        options={this.state.recieptOptions}
                                        value ={this.state.select_recieptAddress}
                                        onChange={this.recieptAddressChanged}
                                        getOptionLabel={option => option.label}
                                        getOptionValue={option => option.value}
                                    />
                                  <input type="text" name="address" id="address" style={{display: this.state.other ? 'block' : 'none'}} placeholder={"Custom Address"} onChange={this.input_recieptAddressChanged} value={this.state.recieptAddress} />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button disabled={this.checkDisableMark()} onClick={() => this.toggleBillPay(this.props.customer.uid)}> Mark Paid </Button>
                        </Modal.Footer>
                      </>
                    )
                  }
                
            </Modal>
            <ToolButton
                disabled={this.state.exporting}
                bsStyle="primary"
                id="printBtn"
                onClick={this.showPrintModal}
            >
                <AddGlyph glyph="ok" />
                Mark As Paid
            </ToolButton>
        </Wrapper>
      );
  }
}

const mapStateToProps = state => ({
  current_billing_quarter: state.current_billing_quarter,
  current_billing_year: state.current_billing_year
});

const mapDispatchToProps = dispatch => ({
    getCustomers: () =>
      dispatch(NewCastleCustomersActions.getCustomersNewCastle()),
    billToggle: (uid, type) =>
      dispatch(NewCastleCustomersActions.billToggleNewCastle(uid, type)),
    setCustomer: uid =>
      dispatch(NewCastleCustomersActions.setNewCastleCustomer(uid)),
    addCustomer: () => dispatch(NewCastleCustomersActions.addNewCastleCustomer()),
    makePayment: (
        customer,
        time,
        new_amount,
        old_amount,
        description,
        historyId,
        quarter,
        serviceLevel,
        year
      ) =>
        dispatch(
          NewCastleCustomersActions.makePayment(
            customer,
            time,
            new_amount,
            old_amount,
            description,
            historyId,
            quarter,
            serviceLevel,
            year
          )
        )
  });

export default connect(mapStateToProps, mapDispatchToProps)(MarkPaidDropDown);
