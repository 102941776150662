/* @format - for Prettier */
import React from 'react'
import styled from 'styled-components'
import { FormControl, FormGroup } from 'react-bootstrap'
import { ErrorText } from './styled'

const StyledInput = styled.input`
  width: 80%;
`
const StyledTextArea = styled.textarea`
  width: 80%;
`

const StyledSelect = styled.select`
  width: 80%;
  height: 26px;
`

const PhoneInput = styled.input`
  width: 100px;
`

const TextareaWrapper = styled(FormControl)`
  height: 119px !important;
  overflow: scroll;
  padding: 5px 10px 7px 10px;
`

export const renderField = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledInput {...input} type={type} placeholder="" />
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
)
export const renderTextAreaField = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledTextArea {...input} type={type} placeholder="" rows={3}/>
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
)

export const renderStatusField = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledSelect {...input} type={type}>
        <option value="Charged">Charged</option>
        <option value="Paid">Paid</option>
      </StyledSelect>
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
)

export const renderBillFrequencyField = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledSelect {...input} type={type}>
        <option value="quarterly">quarterly</option>
        <option value="monthly">monthly</option>
      </StyledSelect>
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
)

export const renderExemptField = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledSelect {...input} type={type}>
        <option value="No">No</option>
        <option value="Yes">Yes</option>
      </StyledSelect>
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
)

export const renderWeekField = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledSelect {...input} type={type}>
        <option value="Monday">Monday</option>
        <option value="Tuesday">Tuesday</option>
        <option value="Wednesday">Wednesday</option>
        <option value="Thursday">Thursday</option>
        <option value="Friday">Friday</option>
        <option value="Saturday">Saturday</option>
        <option value="Sunday">Sunday</option>
      </StyledSelect>
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
)

export const renderState = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledSelect {...input} type={type}>
        <option />
        <option value="AL">AL</option>
        <option value="AK">AK</option>
        <option value="AR">AR</option>
        <option value="AZ">AZ</option>
        <option value="CA">CA</option>
        <option value="CO">CO</option>
        <option value="CT">CT</option>
        <option value="DC">DC</option>
        <option value="DE">DE</option>
        <option value="FL">FL</option>
        <option value="GA">GA</option>
        <option value="HI">HI</option>
        <option value="IA">IA</option>
        <option value="ID">ID</option>
        <option value="IL">IL</option>
        <option value="IN">IN</option>
        <option value="KS">KS</option>
        <option value="KY">KY</option>
        <option value="LA">LA</option>
        <option value="MA">MA</option>
        <option value="MD">MD</option>
        <option value="ME">ME</option>
        <option value="MI">MI</option>
        <option value="MN">MN</option>
        <option value="MO">MO</option>
        <option value="MS">MS</option>
        <option value="MT">MT</option>
        <option value="NC">NC</option>
        <option value="NE">NE</option>
        <option value="NH">NH</option>
        <option value="NJ">NJ</option>
        <option value="NM">NM</option>
        <option value="NV">NV</option>
        <option value="NY">NY</option>
        <option value="ND">ND</option>
        <option value="OH">OH</option>
        <option value="OK">OK</option>
        <option value="OR">OR</option>
        <option value="PA">PA</option>
        <option value="RI">RI</option>
        <option value="SC">SC</option>
        <option value="SD">SD</option>
        <option value="TN">TN</option>
        <option value="TX">TX</option>
        <option value="UT">UT</option>
        <option value="VA">VA</option>
        <option value="VT">VT</option>
        <option value="WA">WA</option>
        <option value="WI">WI</option>
        <option value="WV">WV</option>
        <option value="WY">WY</option>
        <option value="AS">AS</option>
        <option value="GU">GU</option>
        <option value="MP">MP</option>
        <option value="PR">PR</option>
        <option value="UM">UM</option>
        <option value="VI">VI</option>
        <option value="AA">AA</option>
        <option value="AP">AP</option>
        <option value="AE">AE</option>
      </StyledSelect>
    </div>
    {touched && error && <ErrorText>{error}</ErrorText>}
  </div>
)

export const renderRate = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <span> $ </span>
      <input {...input} type={type} placeholder="" />
      <span> per Month</span>
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
)

export const renderPhoneNumber = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <PhoneInput {...input} type={type} placeholder="" />
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
)

export const renderNotes = ({ input }) => (
  <FormGroup>
    <TextareaWrapper componentClass="textarea" {...input} />
  </FormGroup>
)

export const renderSelect = ({
  input,
  label,
  type,
  meta: { touched, error },
  children
}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledSelect {...input}>{children}</StyledSelect>
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
)

export const renderSelectCustomer = ({
  input,
  label,
  type,
  meta: { touched, error },
  children
}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledSelect {...input} value={input.value.id}>
        {children}
      </StyledSelect>
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
)

export const renderExpirationMonth = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledSelect {...input} type={type}>
        <label>Month</label>
        <option />
        <option value="01">01</option>
        <option value="02">02</option>
        <option value="03">03</option>
        <option value="04">04</option>
        <option value="05">05</option>
        <option value="06">06</option>
        <option value="07">07</option>
        <option value="08">08</option>
        <option value="09">09</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
      </StyledSelect>
    </div>
    {touched && error && <ErrorText>{error}</ErrorText>}
  </div>
)

export const renderExpirationDay = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledSelect {...input} type={type}>
        <label>Day</label>
        <option />
        <option value="00">00</option>
        <option value="01">01</option>
        <option value="02">02</option>
        <option value="03">03</option>
        <option value="04">04</option>
        <option value="05">05</option>
        <option value="06">06</option>
        <option value="07">07</option>
        <option value="08">08</option>
        <option value="09">09</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
        <option value="17">17</option>
        <option value="18">18</option>
        <option value="19">19</option>
        <option value="20">20</option>
        <option value="21">21</option>
        <option value="22">22</option>
        <option value="23">23</option>
        <option value="24">24</option>
        <option value="25">25</option>
        <option value="26">26</option>
        <option value="27">27</option>
        <option value="28">28</option>
        <option value="29">29</option>
        <option value="30">30</option>
        <option value="31">31</option>
      </StyledSelect>
    </div>
    {touched && error && <ErrorText>{error}</ErrorText>}
  </div>
)

export const renderExpirationYear = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledSelect {...input} type={type}>
        <label>Year</label>
        <option />
        <option value="00">00</option>
        <option value="2020">2020</option>
        <option value="2021">2021</option>
        <option value="2022">2022</option>
        <option value="2023">2023</option>
        <option value="2024">2024</option>
        <option value="2025">2025</option>
        <option value="2026">2026</option>
        <option value="2027">2027</option>
        <option value="2028">2028</option>
        <option value="2029">2029</option>
        <option value="2030">2030</option>
        <option value="2031">2031</option>
      </StyledSelect>
    </div>
    {touched && error && <ErrorText>{error}</ErrorText>}
  </div>
)

export const normalizePhone = (value) => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`
}

export const required = (value) => {
  if (typeof value == undefined || value == null) return undefined

  if (value.toString().trim().length < 1) return 'Required'

  return undefined
}
export const number = (value) =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined
export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined
