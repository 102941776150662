/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import styled from 'styled-components';
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  Image,
  Carousel,
  Glyphicon,
} from 'react-bootstrap';
import {Routes} from '../../../actions2';
import {storage} from '../../../firebase';
import {ButtonRow} from '../../../styled';

const RemoveButton = styled(Button)`
  color: red !important;
  padding: 0px 0px 0px 10px;
  border: 0px;
`;

const StyledButton = styled(Button)`
  padding: 0px 15px 0px 0px;
  vertical-align: sub;
  text-decoration: underline;
`;

const FileWrapper = styled.div`
  margin-bottom: 10px;
`;

const TitleWrapper = styled.span`
  vertical-align: sub;
`;
const TextWrapper = styled.div`
  max-height: 33vh;
  overflow: scroll;
  padding: 5px 10px 7px 10px;
`;

const StyledCarousel = styled(Carousel)`
  margin-top: 20px;
`;

const TextareaWrapper = styled(FormControl)`
  height: 32vh !important;
  overflow: scroll;
  padding: 5px 10px 7px 10px;
`;

const renderField = ({input}) => (
  <FormGroup>
    <TextareaWrapper componentClass="textarea" {...input} />
  </FormGroup>
);

let NotesForm = () => (
  <form>
    <Field name="notes" component={renderField} />
  </form>
);

NotesForm = reduxForm({form: 'NotesForm'})(NotesForm);
NotesForm = connect(state => ({
  initialValues: state.notes,
  enableReinitialize: true,
}))(NotesForm);

class NotesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'view',
      photos: [],
      removed: {},
    };
  }
  save = () => {
    const {notes} = this.props.formNotes.values;
    /* if there are already photos use those if not use empty array */
    const oldPhotos = this.props.photos ? this.props.photos.slice() : [];
    const newPhotos = _.flattenDeep(this.state.photos);
    /* get rid of removed photos */
    _.remove(oldPhotos, (photo, index) => {
      const removed = this.state.removed[index] === true;
      if (removed) {
        this.props.removePhoto(photo, this.props.stopId);
      }
      return removed;
    });
    this.props.saveNotes(notes, newPhotos, oldPhotos, this.props.stopId);
    this.changeMode('view');
  };
  changeMode = mode => {
    this.setState({mode, photos: [], removed: {}});
  };
  uploadPhotos = () => {
    const photos = document.getElementById('image_uploads').files;
    this.setState({photos});
  };
  removePhoto = index => {
    let removed = this.state.removed;
    removed[index] = true;
    this.setState({removed});
  };
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        bsSize="large">
        <Modal.Header closeButton>
          <Modal.Title>
            <TitleWrapper>Notes</TitleWrapper>
            {this.state.mode === 'edit' ? (
              <ButtonRow>
                <StyledButton bsStyle="link" onClick={this.save}>
                  Save
                </StyledButton>
                <StyledButton
                  bsStyle="link"
                  onClick={() => this.changeMode('view')}>
                  Cancel
                </StyledButton>
              </ButtonRow>
            ) : (
              <ButtonRow>
                <StyledButton
                  bsStyle="link"
                  onClick={() => this.changeMode('edit')}>
                  Edit
                </StyledButton>
              </ButtonRow>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.mode === 'edit' ? (
            <div>
              <NotesForm />
              <div>
                {_.map(this.props.photos, (image, i) => {
                  return (
                    <div key={i}>
                      {this.state.removed[i] ? (
                        <div />
                      ) : (
                        <FileWrapper>
                          <span>{storage.fileName(image)}</span>
                          <span>
                            <RemoveButton
                              bsSize="xsmall"
                              onClick={() => this.removePhoto(i)}>
                              <Glyphicon glyph="remove" />
                            </RemoveButton>
                          </span>
                        </FileWrapper>
                      )}
                    </div>
                  );
                })}
              </div>
              <div>
                <div>Choose images to upload (PNG, JPG)</div>
                <input
                  type="file"
                  id="image_uploads"
                  name="image_uploads"
                  accept=".jpg, .jpeg, .png"
                  multiple
                  onChange={this.uploadPhotos}
                />
              </div>
            </div>
          ) : (
            <div>
              <TextWrapper>{this.props.notes}</TextWrapper>
              {this.props.photos === undefined ? (
                <div />
              ) : (
                <StyledCarousel controls={this.props.photos.length !== 1}>
                  {_.map(this.props.photos, (url, i) => {
                    return (
                      <Carousel.Item key={i}>
                        <Image responsive src={url} />
                      </Carousel.Item>
                    );
                  })}
                </StyledCarousel>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  formNotes: state.form.NotesForm,
  photos: state.notes.photos,
  notes: state.notes.notes,
});

const mapDispatchToProps = dispatch => ({
  saveNotes: (notes, oldPhotos, newPhotos, stopId) =>
    dispatch(Routes.saveNotes(notes, oldPhotos, newPhotos, stopId)),
  removePhoto: (photo, stopId) => dispatch(Routes.removePhoto(photo, stopId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotesModal);
