export const monthOptions = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
].map((item) => ({
  value: item,
  label: item,
}));

export const quarterOptions = [
  {
    value: '1st Quarter',
    label: '1st Quarter',
  },
  {
    value: '2nd Quarter',
    label: '2nd Quarter',
  },
  {
    value: '3rd Quarter',
    label: '3rd Quarter',
  },
  {
    value: '4th Quarter',
    label: '4th Quarter',
  },
];

export const yearOptions = [
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030',
  '2031',
  '2032',
  '2033',
  '2034',
  '2035',
  '2036',
  '2037',
  '2038',
  '2039',
  '2040',
].map((item) => ({
  value: item,
  label: item,
}));

export const dueDateOptions=[
  {
  value:'fixedThirtyDays',
  label:'30 days from today',
},
{
  value:'customDueDate',
  label:'Choose a due date',
}
]

export const sleep = (ms) =>
  new Promise((resolve, reject) => setTimeout(resolve, ms));
