/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import 'input-moment/dist/input-moment.css';
import moment from 'moment';
import styled from 'styled-components';
import toastr from 'toastr';
import {DeniedWrapper} from '../../styled';
import {Customers, WestMayfieldCustomers} from '../../actions2';
import {history} from '../../App';
import {
  Glyphicon,
  Button,
  Modal,
  Row,
  FormGroup,
  InputGroup,
  FormControl,
  ProgressBar
} from 'react-bootstrap'; 
import InputMoment from 'input-moment';
import pdf1 from './pdf-1.jpg'
import pdf2 from './pdf-2.jpg'
import './index.css'
import { getMonthlyDueDate, getQuarterlyDueDate } from '../../utils/invoice'
import { isQuarterlyCustomer } from '../../utils/customer'
import axiosInstance from '../../api/axios';
const axios = require('axios');
const pdfService = require('../../services/pdf').pdfService

const PrintProgress = styled(ProgressBar)`
  margin-top: 20px;
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 860px;
  margin: 0 auto;
`;

var receiptsToPrintFinal2 = [];

const ButtonRow = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 20px !important;
`;

const StyledDatePicker = styled(InputMoment)`
  width: 100% !important;
`;

const BottomButton = styled(Button)`
  margin: 10px 0px;
  margin-right: 20px;
`;

//const receiptsToPrintString = localStorage.getItem("receiptsToPrint");
//const receiptsToPrint = receiptsToPrintString.split(",");
var receiptsToPrintFinal = [];

const newHistory = {
  uid: null,
  invoiceName: '',
  time: null,
  due_date: null,
}

class WestMayfieldReceipt extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      history: newHistory,
      show: false,
      showDateModal: false,
      showDueDateModal: false,
      showInvoiceName: false,
      confirmSent: false,
      which: '',
      item: {},
      amount1: 0, // old amount
      amount2: 0, // new amount
      showEditHistoryDialog: false,
      state_date: moment().format("MM/DD/YYYY"),
      customer_no: '',
      quarter_progressing_show: false,
      quarter_progress: 0,
      additionalText:'',
      invoice_no: '',
      billFrequencyLabel: '',
      ReceiptsArray: [],
    }
    this.billFrequencyLabelChange = this.billFrequencyLabelChange.bind(this)
  }

  componentDidMount() {
    newHistory.time = moment().format('L')
    newHistory.due_date = this.getDueDate()
    this.setState({
      history: newHistory
    })

  }
  
  componentWillMount() {
    const { match: { params } } = this.props;
    let history =  _.find(this.props.customer.history, (item) => item.uid === params.historyId);
    if (!history) {
      history = newHistory;
      history.uid = params.historyId;
    }
    console.log(params.ReceiptArray)
    const historyIds = params.ReceiptArray.split(',').map(item => Number(item)).filter(item => !isNaN(item))
    const receiptsToPrintFinal2 = this.props.customer.history.filter(item => historyIds.includes(item.uid));
    this.setState({
      ReceiptsArray: receiptsToPrintFinal2,
      history,
      customer_no: this.props.customer['Account Number']
    })
  }

  printInvoice = () => {
    pdfService.westmayfield_customer_receipt_pdf_download(
      [this.props.customer], 
      this.props.customer['Account Number'], 
      this.state.history.time, 
      this.state.customer_no, 
      this.state.billFrequencyLabel,
      this.state.additionalText,
      this.state.invoice_no,
      this.state.ReceiptsArray
      )
  };

  getDueDate = isQuarterlyCustomer(this.props.customer) ? getQuarterlyDueDate : getMonthlyDueDate

  onClick = async () => {
    // this.props.updateDB(this.props.customer.uid, this.state.history.uid, this.state.history.time, this.state.history.due_date, sent, this.props.customer.amount_due, this.state.history.invoiceName);
    this.setState({
      quarter_progressing_show: true,
    })
    const base64 = await pdfService.westmayfield_customer_receipt_pdf_download(
      [this.props.customer], 
      'base64', 
      this.state.history.time, 
      this.state.customer_no,
      this.state.billFrequencyLabel,
      this.state.additionalText,
      this.state.invoice_no,
      this.state.ReceiptsArray
      )
    const sender_email = process.env.REACT_APP_ENVIRONMENT === "development" ? "afebbraro@atsapllc.com" : this.props.customer.email
    let data = {
      index: 0,
      email: sender_email,
      content: base64
    }
    axiosInstance.post('/api/customers/send_eInvoiceMail', {
      ...data
    })
    .then(res => {
      if (res.data.success)
        toastr.success('Successfully Sent E-mail.', 'Success')
      else
        toastr.error('Unexpected Error Happend!. Please try again', 'Error')
    }).catch(_err => {
      toastr.error('Unexpected Error Happend!. Please try again', 'Error')
    }).finally(() => {
      this.setState({
        quarter_progress: 100
      })
      setTimeout(() => {
        this.setState({
          quarter_progressing_show: false,
        })
      }, 1000)
    })
  }
  
  handleChange = moment => {
    if (this.state.which === 'date') {
      let history = this.state.history;
      history.time = moment.format('L');
      //history.due_date = this.getDueDate(null, moment)
      this.setState({history});
    } else {
      let history = this.state.history;
      //history.due_date = this.getDueDate(null, moment)
      this.setState({history});
    }    
  };
  handleChangeDueDate = moment => {
    let history = this.state.history;
    //history.time = moment.format('L');
    history.due_date = moment.format('L');
    this.setState({history});
  };

  handleNameChange = () => {
    let history = this.state.history;
    history.invoiceName = this.myInput.value;
    this.setState({history});
    this.setState({showInvoiceName: false});
    localStorage.clear()
  }

  additionalTextChange = e => {
    this.setState({
      additionalText: e.target.value
    })
  }

  billFrequencyLabelChange(e) {
    this.setState({
      billFrequencyLabel: e.target.value
    })
  }

  handleHideName = () => {
    this.setState({showInvoiceName: false});
    localStorage.clear()
  }

  handleShow = which => {
    if (which === "date") {
    this.setState({
      showDateModal: true,
      which,
    })
    } else {
      this.setState({
        showDueDateModal: true,
        which,
      })
    }
  }

  handleShowInvoiceName = which => {
    this.setState({
      showInvoiceName: true,
      which,
    });
  }

  handleSave = () => {
    this.setState({
      show: false,
      showDueDateModal: false,
      showDateModal: false
    });
  }
  
  handleSentInvoice = async () => {
    this.setState({confirmSent: false});
  }

  handleCloseAlert = (sent) => {  
    this.props.updateDB(this.props.customer.uid, this.state.history.uid, this.state.history.time, this.state.history.due_date, sent, this.props.customer.amount_due, this.state.history.invoiceName);
    this.setState({confirmSent: false});
    history.push('/Customer');
  }
  
  // Edit History
  handleEditHistory = item => { // When click the row
    const { dumpster } = this.props.customer;
    item.service_description = item.service_description || `${dumpster.number} ${dumpster.type}, ${dumpster.frequency}`

    this.setState({
      item: Object.assign({}, item),
      amount1: !item.amount ? '0' : item.amount.replace(/[^0-9.]/g, ""),
      amount2: !item.amount ? '0': item.amount.replace(/[^0-9.]/g, ""),
      showEditHistoryDialog: true,
    }); 
  }

  handleAmountChange = e => { // when change amount
    const item = this.state.item;
    item.amount = `- $${e.target.value}`;  
    this.setState({amount2: e.target.value, item });
  }

  handleHistoryTimeChange = moment => { // when select date
    const item = this.state.item;
    item.time = moment.format('L')
    this.setState({ item });
  }

  handleHistoryDescription = e => { // when input description
    const item = this.state.item;
    item.description = e.target.value;
    this.setState({ item });
  }

  handleHistoryService = e => {
    const item = this.state.item;
    item.service_description = e.target.value;
    this.setState({ item });
  }

  handleHistorySave = () => { // when click save buttuon
    this.setState({showEditHistoryDialog: false});
    this.props.charge(
      this.props.customer,
      this.state.item.time,
      parseFloat(this.state.amount2, 10),
      parseFloat(this.state.amount1, 10),
      this.state.item.service_description || '',
      this.state.item.description || '',
      this.state.item.uid,
    );
  }

  customerNoChange = e => {
    this.setState({
      customer_no: e.target.value
    })
  }

  handleCloseEditHistoryDialgo = item => {
    this.setState({ item: {}, showEditHistoryDialog: false });
  }
  formatDecimal = (number) =>{
                        let numind = number.indexOf('.')
                        let numlen = number.slice(numind)
                        if (numlen.length > 2) return `${number.toString()}`
                        return `${number.toString()}0`
                      }
  handleAmountCurrencyFormat = (amount) =>{
    if(amount !== undefined){
      amount =
         amount?.toString().indexOf(".") !== -1
           ? this.formatDecimal(amount.toString())
           : amount.toString() + ".00";

       amount =
         typeof amount === String
           ? Number(amount)
           : amount;
           return amount
    } 
    return 0.00
  }

  handleSetDate = (start, end) => {
    let history = this.state.history;
    history.time = start.time;
    history.due_date = end.time;
    this.setState({history});
  }

  render() {
    if (this.props.authorized) {
      if (
        !this.props.customer?.name
      ) {
        return <Link to="Customer" />;
      } else {
        const {customer} = this.props
        console.log(customer,'my customer')
        // let addtion_table_row = []
        // const min_len =  customer.dumpster_addresses && customer.dumpster_addresses.length > 1 ? this.state.dumpsters.length:this.state.charges.length 
        // for (let i = 0 ; i < 4 - min_len ; i += 1) {
        //   addtion_table_row.push(i)
        // }
       return (
          <Wrapper>
            <div id="Invoce">
              <div className="in-container">
                <div className="in-pdf-container">
                  <div className="in-section1">
                    <div className="in-logo-container">
                      <img src={pdf1} className="in-logo-image" alt="pdf1image"/>
                    </div>
                    <div className="in-section1-sub1 d-flex justify-content-between">
                      <div className="in-info">
                        <p className="font-weight-bold">AIKEN REFUSE, Inc.</p>
                        <p>1613 Wampum Road - Ellwood City, PA 16117</p>
                        <p className="font-weight-bold in-mt-2">724-758-9400 -- info@aikenrefuse.com</p>
                      </div>
                      <div className="in-date">
                      <div className="in-statement d-flex justify-content-between">
                          <p>RECEIPT DATE:</p>
                          <p className="in-state-date pointer" onClick={() => this.handleShow('date')}>{this.state.history.time}</p>
                        </div>
                        <div className="in-customerNo d-flex justify-content-between">
                          <p>CUSTOMER No:</p>
                          {/* <p className="in-state-date">{this.state.customer_no}</p> */}
                          <input className="in-customer-no" onChange={this.customerNoChange} value={this.state.customer_no}></input>
                        </div>
                        <div className="in-customerNo d-flex justify-content-between">
                          <p>INVOICE No:</p>
                          {/* <p className="in-state-date">{this.state.customer_no}</p> */}
                          <input className="in-customer-no" onChange={(e) => this.setState({invoice_no: e.target.value})} value={this.state.invoice_no}></input>
                        </div>
                        <div className="in-checkNo d-flex justify-content-between">
                          <p>CHECK No:</p>
                          <p className="border-bottom pointer" style={{width: '125px'}}></p>
                        </div>
                        <div className="in-AmountEnclosed d-flex justify-content-between">
                          <p>AMOUNT<br/>
                            ENCLOSED:</p>
                          <p className="border-bottom" style={{width: "125px"}}></p>
                        </div>
                      </div>
                    </div>
                    <div className="in-section1-sub2 d-flex justify-content-between in-mt-3">
                      <div className="in-info" style={{paddingTop: "10px"}}>
                        <p className="uppercase">{customer['name'] && customer['name']}</p>
                        <p className="uppercase">{customer['billing_address'] && customer['billing_address']['street']}</p>
                        <p className="uppercase">{customer['billing_address'] && customer['billing_address']['city'] + ', ' + customer['billing_address']['state'] + ' ' + customer['billing_address']['zipcode']}</p>
                      </div>
                      <div className="in-date">
                        <div className="in-statement">
                          <div>
                            <p className="font-weight-bold">
                              {'SERVICE MONTH OR QUARTER:'}
                            </p>
                            <input className="in-customer-no" onChange={this.billFrequencyLabelChange} value={this.state.billFrequencyLabel}></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="in-section2 in-mt-5">
                    <div className="in-table-container">
                      <p className="in-table-des in-fontsize1 font-weight-bold in-pl-1">SERVICE DESCRIPTION</p>
                      <table className="in-table in-mt-2">
                        <thead>
                          {
                            <tr>
                              <th className="in-th th-date">DATE</th>
                              <th className="in-th th-service">STATUS</th>
                              <th className="in-th th-description">DESCRIPTION</th>
                              <th className="in-th th-amount">AMOUNT</th>
                            </tr>
                          }
                        </thead>
                        <tbody>
                        {this.state.ReceiptsArray && this.state.ReceiptsArray.length > 0 ? (
                            _.map(this.state.ReceiptsArray, (history, index) => (
                              //link between history in the props and history we have stored.
                              <tr key={history.uid}>
                                <td className="in-td uppercase">{history.time}</td>
                                <td className="in-td uppercase">{history.status}</td>
                                <td className="in-td uppercase">{history.description}</td>
                                <td className="in-td uppercase">{history.amount}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data available in local storage</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* <p className="text-right font-weight-bold in-fontsize1 in-pr-1 in-mt-3">TOTAL BALANCE DUE: ${this.handleAmountCurrencyFormat(this.props.customer.amount_due)}</p> */}
                      <p className="in-table-des in-fontsize1 font-weight-bold in-pl-1">Additional Notes: </p>
                      <p className='text-left in-mt-3' > <input className="in-customer-no" onChange={this.additionalTextChange} value={this.state.additionalText} /> </p>
                      <div className="credit-card in-p-2 in-mt-5">
                        <p className="text-center in-mt-3">
                          If you would like to enroll in the Recurring Credit Card Program or 
                          make changes for a Recurring Credit Card, please contact our office at (724) 758-9400.<span className="font-weight-bold">Thank you for your business!</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ButtonRow>
              <BottomButton onClick={this.onClick} bsStyle="primary" id="sendBtn">
                  <Glyphicon glyph="save" />
                  <span> Send E-Receipt</span>
                </BottomButton>
              <BottomButton onClick={this.printInvoice} bsStyle="primary" id="printBtn">
                <Glyphicon glyph="print" />
                <span padding="240px"> Print Receipt</span>
              </BottomButton>
              <Link to="/WestMayfield/History" style={{margin: '10px 0px', display: 'inline-block'}}>
                {'<--'} Back To History
              </Link>
            </ButtonRow>
            <Modal show={this.state.showDateModal} onHide={this.handleCloseDateModal} bsSize="sm">
              <Modal.Body>
                <StyledRow>
                  <StyledDatePicker
                    moment={this.state.which === 'date' ? moment(this.state.history.time, 'MM/DD/YYYY') : moment(this.state.history.due_date, 'MM/DD/YYYY')}
                    onChange={this.handleChange}
                    onSave={this.handleSave}
                  />
                </StyledRow>                
              </Modal.Body>
            </Modal>
            <Modal show={this.state.showDueDateModal} onHide={this.handleCloseDueDateModal} bsSize="sm">
              <Modal.Body>
                <StyledRow>
                  <StyledDatePicker
                    moment={this.state.which === 'due_date' ? moment(this.state.history.due_date, 'MM/DD/YYYY') : moment(this.state.history.time, 'MM/DD/YYYY')}
                    onChange={this.handleChangeDueDate}
                    onSave={this.handleSave}
                  />
                </StyledRow>                
              </Modal.Body>
            </Modal>
            <Modal show={this.state.showInvoiceName} onHide={this.handleClose} bsSize="sm">
              <Modal.Body>
                <StyledRow>
                  <label htmlFor="invoiceName">INVOICE NAME:</label>
                  <InputGroup>
                    <FormControl 
                      id="invoiceName"
                      placeholder='Enter in a name'
                      inputRef={ref => { this.myInput = ref; }}
                    />
                    <Button style={{ marginTop: '20px' }} variant="primary" onClick={this.handleNameChange}>Save</Button>
                    <Button style={{ marginTop: '20px', marginLeft: '10px' }} variant="primary" onClick={this.handleHideName}>Cancel</Button>
                  </InputGroup>
                </StyledRow>                
              </Modal.Body>
            </Modal>
            <Modal show={this.state.confirmSent}>
              <Modal.Body>
                <p>Would you send the E-Invoice to the customer? Clicking Yes will send email invoice to customer. Clickng No will not send E-Invoice and will mark the invoice status as 'Open'</p>               
              </Modal.Body>
              <Modal.Footer>
                <Button bsStyle="primary" onClick={this.handleSentInvoice}>Yes</Button>
                <Button onClick={() => this.handleCloseAlert(true)}>No</Button>                
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showEditHistoryDialog} onHide={this.handleCloseEditHistoryDialgo} bsSize="sm">
              <Modal.Body>
                <StyledRow>
                  <div>Amount </div>
                  <FormGroup>
                    <InputGroup>
                      <InputGroup.Addon>$</InputGroup.Addon>
                      <FormControl
                        type="text"
                        value={this.state.amount2}
                        onChange={this.handleAmountChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </StyledRow>
                <StyledRow>
                  <div>Service</div>
                  <FormControl
                    type="text"
                    onChange={this.handleHistoryService}
                    value={this.state.item.service_description}
                  />
                </StyledRow>
                <StyledRow>
                  <div>Notes to Customer Service for extra fees</div>
                  <FormControl 
                    type="text"
                    componentclassname="textarea"
                    rows="3"
                    onChange={this.handleHistoryDescription}
                    value={this.state.item.description}
                  />
                </StyledRow>
                <StyledRow>
                  <StyledDatePicker
                    moment={moment(this.state.item.time, 'MM/DD/YYYY')}
                    onChange={this.handleHistoryTimeChange}
                    onSave={this.handleHistorySave}
                  />
                </StyledRow>
                
              </Modal.Body>
            </Modal>
            {/* Progressing Modal For Quarter*/}
          <Modal
            show={this.state.quarter_progressing_show}
          >
            <Modal.Body>
              <>
                <h3>Sending Email...</h3>
                <p>This may take some time.</p>
              </>
              <PrintProgress
                active
                striped
                now={this.state.quarter_progress}
                label={`${this.state.quarter_progress}%`}
              />
            </Modal.Body>
          </Modal>
          </Wrapper>
        );
      }
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = state => ({
  authorized: state.user?.pages?.invoices,
  customer: state.westmayfield.customer,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  updateDB: (id, historyId, date, dueDate, sent, amount, invoiceName) => dispatch(WestMayfieldCustomers.sendInvoice(id, historyId, date, dueDate, sent, amount, invoiceName)),
  charge: (customer, time, new_amount, amount1, service_description, description, historyId) =>
    dispatch(WestMayfieldCustomers.chargeCustomer(customer, time, new_amount, amount1, service_description, description, historyId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WestMayfieldReceipt);
