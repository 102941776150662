/* @format - for Prettier */
import { db } from "../firebase";
import * as Routes from "./Routes";
import * as Customers from "./Customers";
import * as ResidentialCustomers from "./ResidentialCustomers";
import * as WestMayfieldCustomers from "./WestMayfieldCustomers";
import * as EastButlerCustomers from "./EastButlerCustomers";
import * as SlipperyRockCustomers from "./SlipperyRockCustomers";
import * as NewCastleCustomers2 from './NewCastleCustomers2'
import * as Users from "./Users";
import * as NewCastleCustomers from "./NewCastleCustomers";
import * as GreenBagCustomers from "./GreenBagCustomers";
import * as JobSubmissions from "./JobSubmissions";
import { history } from "../App";
import { ApiService } from "../api/apiService";

export const init = userId => {
  return function(dispatch) {
    ApiService.readOne('users', userId)
      .then(res => {
        if (!res.success) {
          history.push("/Un_authorized");
        } else {
          const user = res.user;
          dispatch(Users.setUser(user));
          dispatch(Users.getUsers());
          dispatch(Routes.getRoutes());
        }
      })
      .catch(error => console.log("error in init", error));
  };
};

export { Routes, Customers, ResidentialCustomers,WestMayfieldCustomers, EastButlerCustomers, SlipperyRockCustomers, Users, NewCastleCustomers, NewCastleCustomers2,GreenBagCustomers, JobSubmissions };
