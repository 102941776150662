/* @format - for Prettier */
import React from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Glyphicon,
  Modal,
  ProgressBar,
  ControlLabel,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import toastr from 'toastr';

import worker from 'workerize-loader!./worker'; // eslint-disable-line import/no-webpack-loader-syntax

import moment from 'moment-timezone';

import { sleep } from '../../utils/index';
import { firebase } from '../../firebase';
import axiosInstance from '../../api/axios';

const Wrapper = styled.div``;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  margin: 10px;
  margin-right: 0;
`;

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`;

const PrintProgress = styled(ProgressBar)`
  margin-top: 20px;
`;

const DatePickerContainer = styled.span`
  margin-left: 20px;
`;

class PrintSelectedInvoices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      generatingPDF: false,
      progress: 0,
      exporting: false,
      runningDailyScripts: false,
      showDailyScriptsModal: false,
      dailyScriptsDate: new Date(),
    };
  }
  componentDidMount() {
    this.worker = worker();
    this.worker.addEventListener('message', this.onMessage);
  }

  componentWillUnmount() {
    this.worker.removeEventListener('message', this.onMessage);
  }

  onMessage = (message) => {
    if (message.data.done) {
      this.onProcessComplete();
    }

    if (message.data.file) {
      this.setState({
        progress: message.data.progress,
      });

      this.onPdfGenerate(message.data.file, message.data.batch);
    }
  };

  onPdfGenerate = (blob, batch) => {
    const data = URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = data;
    link.download = `newcastle_${moment().format('MMDDYYYY')}_${batch}.pdf`;
    link.click();
    link.remove();
  };

  onProcessComplete = () => {
    setTimeout(() => {
      this.setState({
        exporting: false,
        progress: 0,
        show: false,
        dueDate: null,
      });
    }, 2000);
  };

  print = () => {
    this.setState({
      show: true,
      exporting: true,
    });
    this.worker.exportSelectedReceiptsToPDF(this.props.invoices);
  };

  showPrintModal = () => {
    this.setState({
      show: true,
    });
  };

  hidePrintModal = () => {
    this.setState({
      show: false,
    });
  };

  showDailyScriptsModal = () => {
    this.setState({ showDailyScriptsModal: true });
  };

  hideDailyScriptModal = () => {
    this.setState({ showDailyScriptsModal: false });
  };

  handleDailyScriptDateChange = (date) => {
    this.setState({ dailyScriptsDate: date });
  };

  runDailyScript = async () => {
    this.setState({ runningDailyScripts: true });
    await sleep(1);
    const api_endpoint = this.props.newCastle
        ? 'daily_scripts/new_castle'
        : 'daily_scripts/comm_res';
    try {
      const date = moment(this.state.dailyScriptsDate)
        .tz('America/New_York')
        .format('MM/DD/YYYY');
      const res = await axiosInstance.post(`/api/customers/${api_endpoint}`, {
        date,
        isCommercial: this.props.isCommercial,
        isResidential: this.props.isResidential,
        isWestMayfield: this.props.isWestMayfield
      });
      const success = res.data.success;
      if (success) {
        toastr.success('Successfully Run Daily Scripts.', 'Success');
      } else {
        toastr.error('Unexpected Error Happend!. Please try again', 'Error');
      }
    } catch (error) {
      console.error(error);
      toastr.error('Unexpected Error Happend!. Please try again', 'Error');
    }
    this.setState({ runningDailyScripts: false });
  };

  render() {
    if (this.props.invoices === undefined && this.props.newCastle) {
      return <Redirect to="Reports/invoices" />;
    } else {
      return (
        <Wrapper>
          <Modal
            show={this.state.show}
            onHide={this.state.exporting ? () => {} : this.hidePrintModal}
          >
            <Modal.Body>
              {this.state.exporting ? (
                <>
                  <h3>Downloading...</h3>
                  <p>This may take some time.</p>
                </>
              ) : (
                <h3>Download Open Invoices as PDF</h3>
              )}
              {!this.state.exporting && (
                <>
                  <Select
                    disabled={this.state.exporting}
                    onChange={(v) => this.setState({ dueDate: v.value })}
                    options={[
                      {
                        value: moment().month(11).date(15).format('MM/DD/YYYY'),
                        label: '1st Quarter',
                      },
                      {
                        value: moment().month(2).date(15).format('MM/DD/YYYY'),
                        label: '2nd Quarter',
                      },
                      {
                        value: moment().month(5).date(15).format('MM/DD/YYYY'),
                        label: '3rd Quarter',
                      },
                      {
                        value: moment().month(8).date(15).format('MM/DD/YYYY'),
                        label: '4th Quarter',
                      },
                    ]}
                  />
                  <ToolButton
                    disabled={!this.state.dueDate}
                    bsStyle="primary"
                    onClick={this.print}
                  >
                    Print
                  </ToolButton>
                </>
              )}
              {this.state.exporting && (
                <PrintProgress
                  active
                  striped
                  now={this.state.progress}
                  label={`${this.state.progress}%`}
                />
              )}
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.showDailyScriptsModal}
            onHide={
              this.state.runningDailyScripts
                ? () => {}
                : this.hideDailyScriptModal
            }
          >
            <Modal.Body>
              {this.state.runningDailyScripts ? (
                <>
                  <h3>Running Daily Script...</h3>
                  <p>This may take some time</p>
                </>
              ) : (
                <>
                  <ControlLabel>Select Date</ControlLabel>
                  <DatePickerContainer>
                    <DatePicker
                      selected={this.state.dailyScriptsDate}
                      onChange={this.handleDailyScriptDateChange}
                    />
                  </DatePickerContainer>
                  <ToolButton
                    disabled={!this.state.dailyScriptsDate}
                    bsStyle="primary"
                    onClick={this.runDailyScript}
                  >
                    Run Daily API Scripts
                  </ToolButton>
                </>
              )}
            </Modal.Body>
          </Modal>
          {this.props.downloadReceipts ? (
            <ToolButton
              disabled={this.state.exporting}
              bsStyle="primary"
              onClick={this.print}
            >
              <AddGlyph glyph="print" />
              Download Receipts (Paid)
            </ToolButton>
          ) : null}
          <ToolButton bsStyle="primary" onClick={this.showDailyScriptsModal}>
            <AddGlyph glyph="ok-circle" />
            Run Daily API Scripts
          </ToolButton>
        </Wrapper>
      );
    }
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintSelectedInvoices);
