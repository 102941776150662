/* @format - for Prettier */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Row, Col, Table, Modal, ControlLabel, Glyphicon } from "react-bootstrap";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { Customers } from "../../../actions2";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import DatePicker from "react-datepicker";
import { Field } from "redux-form";
import { renderNotes } from "../../../renderComponents";
import { deleteCallHistory, deleteClosedCallHistory, doAddNewPath, doAddNotesClosed, doCallHistory, doClosedCallHistory, doDeleteNoteClosed } from "../../../firebase/db";
import { getCustomer } from "../../../actions/Customers";
import { history } from "../../../App";
import moment from "moment";
import { GlyphButton } from "../../Admin";

const StyledRow = styled(Row)`
  margin-bottom: 0px !important;
`;;
const StyledCol = styled(Col)`
  padding: 0px;
  margin-bottom: 20px;
`;;
const Expiry = styled.span`
  padding-right: 10px;
`;;

const StyledNotes = styled.div`
  border: 1px solid black;
  height: 60px;
  padding: 5px 10px 5px 10px;
  font-style: italic;
`;

const Header = styled.div`
  display: flex;
`;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  height: 40px;
  margin: 10px 0px 0px 10px;
  float: right;
`;

const EditButton = styled(Button)`
padding: 5px 20px;
  height: 35px;
  margin: 0px 0px 0px 10px;
  float: right;
`

const AccordionContainer = styled.div`
  margin: 20px 0px !important;
`;

const AddAddressButton = styled(Button)`
  width: 200px;
  margin-top: 20px;
`;

const DeleteButton = styled(Button)`
  float: right;
`;;
const CloseButton = styled(Button)`
  float: left;
`;

/*eslint-disable */
const CustomerData = ({
  setCustomer,
  routes,
  addAddress,
  addInfo,
  add_key,
  customer: {
    ["acc_number"]: acc_number,
    ["recurringCustomer"]: recurringCustomer,
    ["isExempt"]: isExempt,
    ["billFrequency"]: billFrequency,
    ["wantsEInvoice"]: wantsEInvoice,
    ["Contract Term Length"]: contractLength,
    ["expirationMonth"]: expirationMonth,
    ["expirationDay"]: expirationDay,
    ["expirationYear"]: expirationYear,
    ["isInactive"]: isInactive,
    ["isAutoRenewed"]: isAutoRenewed,
    name,
    rate,
    status,
    contact_name,
    email,
    otherContactNames,
    phone_numbers,
    dumpster_addresses,
    billing_address,
    dumpsters,
    credit_card,
    credit_card_name,
    pickup_day,
    notes,
    call_history,
    uid,
    stops,
  },
}) => {
  const autoRenew = isAutoRenewed ? "true" : "false";
  if(notes){
    notes = Object.entries(notes).sort((a,b) => a[1].date > b[1].date ? 1 : -1);
  }
  if(call_history){
    call_history = Object.entries(call_history).sort((a,b) => a[1].date > b[1].date ? 1 : -1);
  }
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [modalTitle, setModalTitle] = useState("Add");
  const [noteValue, setNoteValue] = useState("");
  const [noteUid, setNoteUid] = useState("");
  const [callShow, setCallShow] = useState(false);
  const [startCallDate, setStartCallDate] = useState(new Date());
  const [modalCallTitle, setModalCallTitle] = useState("Add");
  const [noteCallValue, setNoteCallValue] = useState("");
  const [noteCallUid, setNoteCallUid] = useState("");
  const [route, setRoute] = useState([]);
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  useEffect(() => {
    if (stops && routes) {
      const arr=routes.filter(route=>route?.stops?.indexOf(Object.keys(stops)[0])>=0);
      setRoute(arr)
    }
  }, [stops])

  const handleClose = () => {
    setShow(!show);
    setStartDate("");
    setNoteValue("");
    setNoteUid("");
  };
  const handleStartDateCAllChange = (date) => {
    setStartCallDate(date);
  };
  const handleCallClose = () => {
    setCallShow(!callShow);
    setStartCallDate("");
    setNoteCallValue("");
    setNoteCallUid("");
  };

  const saveCallNotes = async () => {

    if (!startCallDate) {
      alert("Please choose the date")
      return
    }
    else {
      let obj = {
        date: startCallDate,
        note: noteCallValue,
      };

      let res = doClosedCallHistory(uid, obj, noteCallUid);
      // console.log(res, uid);
      setCustomer(uid);
      setCallShow(false);
      setStartCallDate("");
      setNoteCallValue("");
      setNoteCallUid("");
      let url = '/ClosedAccounts'
      history.push(url);
    }
  };

  const deleteCallNote = async (id) => {
    let res = await deleteClosedCallHistory(uid, id);
    setCustomer(uid);
    handleDeleteCallClose();
    let url = '/ClosedAccounts'
    history.push(url);
  };

  const doUpdateCallNote = (customer) => {
    let date = customer.date ? moment(customer.date).toDate() : "";
    setModalCallTitle("Update");
    setStartCallDate(date);
    setNoteCallValue(customer.note);
    setNoteCallUid(customer.uid);
    setCallShow(true);
  };

  const saveNotes = async () => {
    let obj = {
      date: startDate,
      note: noteValue,
    };

    let res = doAddNotesClosed(uid, obj, noteUid);
    console.log(res, uid);
    setCustomer(uid);
    setShow(false);
    setStartDate("");
    setNoteValue("");
    setNoteUid("");
    let url = '/ClosedAccounts'
    history.push(url);
  };

  const deleteNote = async (id) => {
    let res = await doDeleteNoteClosed(uid, id);
    setCustomer(uid);
    handleDeleteClose();
    let url = '/ClosedAccounts'
    history.push(url);
  };

  const doUpdateNote = (customer) => {
    let date = customer.date ? moment(customer.date).toDate() : "";
    setModalTitle("Update");
    setStartDate(date);
    setNoteValue(customer.note);
    setNoteUid(customer.uid);
    setShow(true);  
  };

  const [showCallDelete, setShowCallDelete] = useState({
    key: false,
    uid: "",
  });

  const handleDeleteCallClose = () => {
    setShowCallDelete({
      key: false,
      uid: "",
    });
  };

  const [showDelete, setShowDelete] = useState({
    key: false,
    uid: "",
  });

  const handleDeleteClose = () => {
    setShowDelete({
      key: false,
      uid: "",
    });
  };

  return (
    <Grid>
      <StyledRow>
        <StyledCol sm={4} md={4}>
          <h4>Customer Information:</h4>
          <div>
            Name: <span className="uppercase">{name}</span>
          </div>
          <div>
            Rate: <span className="uppercase">${rate} per month</span>
          </div>
          <div>
            Account Number: <span className="uppercase">{acc_number}</span>
          </div>
          <div>
            Recurring Customer:{" "}
            <span className="uppercase">
              {(recurringCustomer === undefined) | null
                ? "No"
                : recurringCustomer}
            </span>
          </div>
          <div>
            Is Exempt:{" "}
            <span className="uppercase">
              {(isExempt === undefined) | null ? "No" : isExempt}
            </span>
          </div>

          <div>
            Is Inactive:{" "}
            <span className="uppercase">
              {(isInactive === undefined) | null ? "No" : isInactive}
            </span>
          </div>

          <div>
            Bill Frequency:{" "}
            <span className="uppercase">
              {(billFrequency === undefined) | null
                ? "quarterly"
                : billFrequency}
            </span>
          </div>
          <div>
            Wants E-Invoice?{" "}
            <span className="uppercase">
              {(wantsEInvoice === undefined) | null ? "No" : wantsEInvoice}
            </span>
          </div>
          <div>
            Status: <span className="uppercase">{status}</span>
          </div>
          <div>
            Contract Term Length:{" "}
            <span className="uppercase">{contractLength}</span>
          </div>
          <div>
            Expiration Date:{" "}
            <span className="uppercase">{expirationMonth}</span> /{" "}
            <span className="uppercase">{expirationDay}</span> /{" "}
            <span className="uppercase">{expirationYear}</span>
          </div>
          <div>
            Auto-Renew:
            <span className="uppercase">{autoRenew}</span>
          </div>
        </StyledCol>
        <StyledCol sm={4} md={4} smOffset={1} mdOffset={1}>
          <h4>Contact Information: </h4>
          <div>
            Name: <span className="uppercase">{contact_name}</span>
          </div>
          <div>
            Email: <span className="uppercase">{email}</span>
          </div>
          <div>
          Other Contact Names: <span className="uppercase">{otherContactNames}</span>
          </div>
        </StyledCol>
        <StyledCol sm={2} md={2} smOffset={1} mdOffset={1}>
          <h4>Phone Numbers:</h4>
          {_.map(phone_numbers, (phone_number, index) => {
            if (!phone_number.type && phone_number.number) {
              return (
                <li key={index}>
                  Phone:{" "}
                  <span className="uppercase">{phone_number.number}</span>
                </li>
              );
            } else if (!phone_number.type && !phone_number.number) {
              return (
                <li key={index}>
                  Phone:{" "}
                  <span className="uppercase">{phone_numbers[index]}</span>
                </li>
              );
            } else {
              return (
                <li key={index}>
                  {phone_number.type}:{" "}
                  <span className="uppercase">{phone_number.number}</span>
                </li>
              );
            }
          })}
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Dumpster Address:</h4>
          </StyledRow>
          <StyledRow>
            {_.map(dumpster_addresses, (dumpster_address, index) => {
              if (dumpster_address.street) {
                return (
                  <li key={index} className="uppercase">
                    {`${dumpster_address.street} ${dumpster_address.city}, ${dumpster_address.state} ${dumpster_address.zipcode}`}
                  </li>
                );
              }
            })}
          </StyledRow>
          <AddAddressButton onClick={addAddress} bsStyle="primary">
            + Add Dumpster Address
          </AddAddressButton>
        </StyledCol>
        <StyledCol sm={4} md={4} smOffset={1} mdOffset={1}>
          <StyledRow>
            <h4>Billing Address:</h4>
          </StyledRow>
          <StyledRow>
            <div className="uppercase"> {billing_address.street} </div>
            <div className="uppercase">
              {" "}
              {billing_address.city}, {billing_address.state}{" "}
              {billing_address.zipcode}{" "}
            </div>
          </StyledRow>
        </StyledCol>
        <StyledCol sm={2} md={2} smOffset={1} mdOffset={1}>
          <h4>Dumpster Info:</h4>
          {_.map(dumpsters, (dumpster, index) => {
            return (
              <li className="uppercase" key={index}>
                {dumpster.number} / {dumpster.type} / {dumpster.frequency}
              </li>
            );
          })}
          <AddAddressButton onClick={addInfo} bsStyle="primary">
            + Add Dumpster Info
          </AddAddressButton>
        </StyledCol>
      </StyledRow>
      <StyledCol sm={4} md={4}>
        <StyledRow>
          <h4>Routes:</h4>
        </StyledRow>
        <StyledRow>
          {route.map((each,index)=> (
            <div>
              <li key={index}><span className="uppercase">{each.name} <b>, Associated with {billing_address.street}  {" "}
              {billing_address.city}, {billing_address.state}{" "}
              {billing_address.zipcode}{" "}</b></span>
              </li>
            </div>
          ))}
        </StyledRow>
      </StyledCol>
      <StyledRow>
        <AccordionContainer>
              <Accordion allowZeroExpanded="true">
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <label>Notes ({notes !== undefined ? notes?.length : 0})</label>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Header>
                      <div style={{ width: "100%" }}>
                        <ToolButton
                          onClick={() => {setShow(true); setModalTitle("Add");}}
                          bsStyle="primary"
                        >
                          + New
                        </ToolButton>
                      </div>
                    </Header>
                    <br />
                    <Table responsive hover bordered id="print">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Note</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(notes, (note) => {
                          if (note[1]?.uid !== undefined) {
                            return (
                              <tr key={note[1]?.uid}>
                                <td>
                                  <div>
                                    {note[1].date
                                      ? moment.parseZone(new Date(note[1].date)).format("LL")
                                      : "---"}
                                  </div>
                                </td>
                                <td>
                                  <div>{note[1].note}</div>
                                </td>
                                <td>
                                  <div className="d-flex gap-2">
                                    <div>
                                      <EditButton
                                        onClick={() => doUpdateNote(note[1])}
                                        bsStyle="primary"
                                      >
                                        Edit
                                      </EditButton>
                                    </div>
                                    <div>
                                      <GlyphButton
                                        onClick={() =>
                                          setShowDelete({
                                            key: true,
                                            uid: note[1].uid,
                                          })
                                        }
                                      >
                                        <Glyphicon glyph="remove" />
                                      </GlyphButton>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        }).reverse(notes)}
                      </tbody>
                    </Table>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </AccordionContainer>
      </StyledRow>
      <StyledRow>
        <AccordionContainer>
          <Accordion allowZeroExpanded="true">
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <label>Call History ({call_history !== undefined ? call_history?.length : 0})</label>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Header>
                  <div style={{ width: "100%" }}>
                    <ToolButton
                      onClick={() => { setCallShow(true); setModalCallTitle("Add"); }}
                      bsStyle="primary"
                    >
                      + New
                    </ToolButton>
                  </div>
                </Header>
                <br />
                <Table responsive hover bordered id="print">
                  <thead>
                    <tr>
                      <th>Call Date</th>
                      <th>Note</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(call_history, (call) => {
                      if (call[1]?.uid !== undefined) {
                        return (
                          <tr key={call[1]?.uid}>
                            <td>
                              <div>
                                {call[1].date
                                  ? moment.parseZone(new Date(call[1].date)).format("LL")
                                  : "---"}
                              </div>
                            </td>
                            <td>
                              <div>{call[1].note}</div>
                            </td>
                            <td>
                              <div className="d-flex gap-2">
                                <div>
                                  <EditButton
                                    onClick={() => doUpdateCallNote(call[1])}
                                    bsStyle="primary"
                                  >
                                    Edit
                                  </EditButton>
                                </div>
                                <div>
                                  <GlyphButton
                                    onClick={() =>
                                      setShowCallDelete({
                                        key: true,
                                        uid: call[1].uid,
                                      })
                                    }
                                  >
                                    <Glyphicon glyph="remove" />
                                  </GlyphButton>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    }).reverse(call_history)}
                  </tbody>
                </Table>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </AccordionContainer>
      </StyledRow>
      <StyledRow>
        <Link to="/History">History and Invoices</Link>
      </StyledRow>
      <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span>{modalTitle} Notes</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="form-group">
            <label>Select Date (required)</label>
            <br />
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="noteArea">Enter Note (required)</label>

            <textarea
              className="form-control"
              id="noteArea"
              rows="3"
              value={noteValue}
              onChange={(e) => setNoteValue(e.target.value)}
            ></textarea>
          </div>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <CloseButton onClick={() => handleClose()}>Cancel</CloseButton>
        <ToolButton
          onClick={saveNotes}
          bsStyle="primary"
          disabled={noteValue && startDate ? false : true}
        >
          Save
        </ToolButton>
      </Modal.Footer>
    </Modal>

    <Modal bsSize="small" show={showDelete.key} onHide={handleDeleteClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span>Delete Note </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Are you sure you want to delete this Note?</div>
      </Modal.Body>
      <Modal.Footer>
        <CloseButton onClick={handleDeleteClose}>Close</CloseButton>
        <DeleteButton
          bsStyle="danger"
          onClick={() => deleteNote(showDelete.uid)}
        >
          Delete
        </DeleteButton>
      </Modal.Footer>
    </Modal>
      <Modal show={callShow} onHide={() => handleCallClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>{modalCallTitle} Notes</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label>Select Call Date (required)</label>
              <br />
              <DatePicker
                selected={startCallDate}
                onChange={handleStartDateCAllChange}
                required
              />
            </div>

            <div class="form-group">
              <label htmlFor="noteArea">Enter Note (required)</label>

              <textarea
                className="form-control"
                id="noteArea"
                rows="3"
                value={noteCallValue}
                onChange={(e) => setNoteCallValue(e.target.value)}
              ></textarea>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={() => handleCallClose()}>Cancel</CloseButton>
          <ToolButton
            onClick={saveCallNotes}
            bsStyle="primary"
            disabled={noteCallValue && startCallDate? false : true}
          >
            Save
          </ToolButton>
        </Modal.Footer>
      </Modal>

      <Modal bsSize="small" show={showCallDelete.key} onHide={handleDeleteCallClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Delete Note </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to delete this Note?</div>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={handleDeleteCallClose}>Close</CloseButton>
          <DeleteButton
            bsStyle="danger"
            onClick={() => deleteCallNote(showCallDelete.uid)}
          >
            Delete
          </DeleteButton>
        </Modal.Footer>
      </Modal>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  customer: state.currentCustomer,
  routes:state.routes,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomer: (uid) => dispatch(Customers.getCustomer(uid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerData);