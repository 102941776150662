/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import 'input-moment/dist/input-moment.css';
import moment from 'moment';
import styled from 'styled-components';
import {
  Table,
} from 'react-bootstrap'; 

const Wrapper = styled.div`
  width: 90%;
  max-width: 860px;
  margin: 0 auto;
`;

const StyledSpan = styled.span`
  width: 45% !important;
`;

class Invoice extends React.Component {
  render() {
    const {
      name,
      // amount_due,
      billing_address,
      dumpster,
    } = this.props.invoice.customer;


    let charges = [], pays=[];
    let paid = 0, charged = 0;
    let amount_due = this.props.invoice.amount;

    let all_histories = _.sortBy(this.props.invoice.customer.history, history => {
      let data = history.time.split('/');
      return [data[2], data[0], data[1]].join('-');
    }).reverse();
    
    let flag = false;
    for(let i = 0; i < all_histories.length; i++) {
      let history = all_histories[i];
      if (flag) {
        if (history.status === 'Invoice Sent' && !history.paid) {
          paid -= parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10);
          console.log('return true');
          break;
        }
        if (history.status === 'charged') {
          charged += parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10);
          charges.push(history);
        } else if (history.status === 'paid') {
          pays.push(history);
          paid += parseFloat(history.amount.replace(/[^0-9.]/g, ""), 10);
        }
      }
      
      if (!flag && history.uid === this.props.invoice.uid) flag = true;
    }
    charges.reverse();
    pays.reverse();

    while (true) {
      if (charges.length === 0) break;
      let am = parseFloat(charges[0].amount.replace(/[^0-9.]/g, ""));
      if (charged >= am && paid > 0) {
        paid -= am;
        charged -= am;
        charges.shift();
      } else break;
    }        
    
    return (
      <Wrapper>
        <div className="page-break">
          <div
            style={{
              marginTop: '2vh',
              fontSize: '14px',
            }}>
            <div
              style={{
                padding: '0px',
              }}>
              <StyledSpan
                style={{
                  display: 'inline-block',
                  paddingTop: '20px',
                  paddingLeft: '25px',
                  borderBottom: '7px solid black',
                }}>
                <div style={{paddingBottom: '35px'}}>
                  <div>
                    <b>Aiken Refuse Inc.</b>
                  </div>
                  <div>615 Kate Houk Rd</div>
                  <div>New Castle,PA 16101</div>
                  <div>info@aikenrefuse.com</div>
                </div>
                <div style={{paddingBottom: '15px'}}>
                  <div>
                    <b>BILL TO</b>
                  </div>
                  <div>{name}</div>
                  <div>{billing_address.street}</div>
                  <div>
                    {billing_address.city}, {billing_address.state}{' '}
                    {billing_address.zipcode}
                  </div>
                </div>
              </StyledSpan>
              <span
                style={{
                  display: 'inline-block',
                  paddingLeft: '15px',
                  textAlign: 'right',
                }}>
                <h1>AIKEN</h1>
                <h2
                  style={{
                    margin: 'auto',
                    fontSize: '20px',
                  }}>
                  724-758-9400
                </h2>
                <div
                  style={{
                    background: 'black',
                    color: 'white',
                    textAlign: 'left',
                    padding: '5px',
                    marginTop: '20px',
                  }}>
                  INVOICE:{' '}
                  {moment(this.props.invoice.time, 'MM/DD/YYYY').format(
                    'MMMM YYYY',
                  )}{' '}
                  -{' '}
                  {moment(
                    this.props.invoice.due_date,
                    'MM/DD/YYYY',
                  ).format('MMMM YYYY')}
                </div>
                <div
                  style={{
                    background: 'black',
                    color: 'white',
                    textAlign: 'left',
                    padding: '5px',
                    marginTop: '20px',
                  }}
                  onClick={() => this.handleShow('date')}
                >
                  DATE: {this.props.invoice.time}
                </div>
                <div
                  style={{
                    background: 'black',
                    color: 'white',
                    textAlign: 'left',
                    padding: '5px',
                    marginTop: '20px',
                  }}
                  onClick={() => this.handleShow('due_date')}  
                >
                  DUE DATE: {this.props.invoice.due_date}
                </div>
              </span>
            </div>
            <div style={{marginTop: '20px'}}>
              <Table condensed responsive striped bordered style={{width: '100%'}}>
                <thead>
                  <tr
                    style={{
                      background: 'black',
                      color: 'white',
                    }}>
                    <th>Date</th>
                    <th>Service</th>
                    <th
                      style={{
                        width: 'auto',
                      }}>
                      QTY
                    </th>
                    <th
                      style={{
                        width: '100px',
                        paddingRight: '5px',
                        textAlign: 'right',
                      }}>
                      Amount
                    </th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(charges, (charge, i) => {
                    return (
                      <tr key={i} onClick={() => this.handleEditHistory(charge)}>
                        <td>{charge.time}</td>
                        <td>
                          {charge.service_description || `${dumpster.number} ${dumpster.type}, ${dumpster.frequency}`}
                        </td>
                        <td>1</td>
                        <td>
                          <span style={{float: 'right'}}>
                            {charge.amount && charge.amount[0] === '-' ? (charge.amount.slice(1)) : '' }
                          </span>
                        </td>
                        {charge.description ? (
                          <td> {charge.description} </td>
                        ) : (
                          <td />
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div
                style={{
                  padding: '0px',
                  marginBottom: '30px',
                  marginLeft: '65%',
                }}>
                <div
                  style={{
                    padding: '5px',
                    marginTop: '10px',
                  }}>
                  <span>TOTAL CHARGED</span>
                  <span style={{float: 'right'}}>{`$${Math.round(charged*1000)/1000}`}</span>
                </div>
                <div
                  style={{
                    padding: '5px',
                    marginTop: '10px',
                  }}>
                  <span>PAYMENT</span>
                  <span style={{float: 'right'}}>{`$${Math.round(paid*1000)/1000}`}</span>
                </div>
                
                <div
                  style={{
                    background: 'black',
                    color: 'white',
                    textAlign: 'left',
                    padding: '5px',
                    marginTop: '10px',
                    fontSize: '10px'
                  }}>
                  <span style={{color: 'white', fontSize: '16px'}}>Total Due</span>
                  <span style={{float: 'right'}}>
                    <span style={{color: 'white', fontSize: '16px'}}>${amount_due}</span>
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                verticalAlign: 'bottom',
                textAlign: 'center',
                width: '100%',
              }}>
              Thank you for your service. 
            </div>
          </div>
        </div>
      </Wrapper>
    );
      
  }
}

export default Invoice;
