const isQuarterlyCustomer = (customer) => {
  if (customer['billFrequency'] === 'monthly') return false;
  if (customer['isExempt'] === 'Yes') return false;
  if (customer['isInactive'] === 'Yes') return false;
  if (customer['recurringCustomer'] === 'Yes') return false;
  return true;
};

const isMonthlyCustomer = (customer) => {
  if (customer['isExempt'] === 'Yes') return false;
  if (customer['isInactive'] === 'Yes') return false;
  if (customer['recurringCustomer'] === 'Yes') return false;
  if (customer['billFrequency'] === 'monthly') return true;
  return false;
};

const isQuarterlyCustomerWithEInvoice = (customer) => {
  if (customer['billFrequency'] === 'monthly') return false;
  if (customer['isExempt'] === 'Yes') return false;
  if (customer['isInactive'] === 'Yes') return false;
  if (customer['recurringCustomer'] === 'Yes') return false;
  if (customer['wantsEInvoice'] === undefined || customer['wantsEInvoice'] === 'No') return false;
  return true;
};

const isQuarterlyCustomerWithoutEInvoice = (customer) => {
  if (customer['wantsEInvoice'] === undefined || customer['wantsEInvoice'] === 'No') return true;
  return false;
};

const KeyMap = new Map([  
      ['uid', 'uid'],
      ['name', 'name'],
      ['email', 'email'],
      ['type', 'type'],
      ['acc_number', 'Account Number'],
      ['bill_frequency', 'billFrequency'],
      ['status', 'status'],
      ['contract_term_length', 'Contract Term Length'],
      ['amount_due', 'amount_due'],
      ['rate', 'rate'],
      ['exp_day', 'expirationDay'],
      ['exp_month', 'expirationMonth'],
      ['exp_year', 'expirationYear'],
      ['credit_card', 'credit_card'],
      ['credit_card_name', 'credit_card_name'],
      ['contact_name', 'contact_name'],
      ['company_name', 'companyName'],
      ['other_contact_names', 'otherContactNames'],
      ['toter_serial_numbers', 'toterSerialNumbers'],
      ['phone_numbers', 'phone_numbers'],
      ['billing_address', 'billing_address'],
      ['service_address', 'service_address'],
      ['dumpster_addresses', 'dumpster_addresses'],
      ['dumpsters', 'dumpsters'],
      ['pickup_day', 'pickup_day'],
      ['wants_einvoice', 'wantsEInvoice'],
      ['is_exempt', 'isExempt'],
      ['is_inactive', 'isInactive'],
      ['is_closed', 'isClosed'],
      ['recurring_customer', 'recurringCustomer'],
      ['recycling_customer', 'recycling_customer'],
      ['auto_renew', 'isAutoRenewed'],
      ['mail_list_id', 'list_id'],
      ["stops","stops"],
      ["history","history"],
      ["notes","notes"],
      ["call_history", "callHistory"],
      ["type_of_reason", "type_of_reason"],
]);
const DBCustomer = (customer) => {
  const dbCustomer = {};
  for (const [key1, key2] of KeyMap) {
    if(customer[key2] !== undefined){
      dbCustomer[key1] = customer[key2];
      if(dbCustomer[key1] === 'Yes')
        dbCustomer[key1] = 1;
      else if(dbCustomer[key1] === 'No')
        dbCustomer[key1] = 0;
      else if(dbCustomer[key1] === true)
        dbCustomer[key1] = 1;
      else if(dbCustomer[key1] === false)
        dbCustomer[key1] = 0;
  }
}
  return dbCustomer;
  // return {
  //   name: customer['name'],
  //   email: customer['email'],
  //   type: customer['type'],
  //   acc_number: customer['Account Number'],
  //   bill_frequency: customer['billFrequency'],
  //   status: customer['status'],
  //   contract_term_length: customer['Contract Term Length'],
  //   amount_due: customer['amount_due'],
  //   rate: customer['rate'],
  //   exp_day: customer['expirationDay'],
  //   exp_month: customer['expirationMonth'],
  //   exp_year: customer['expirationYear'],
  //   credit_card: customer['credit_card'],
  //   credit_card_name: customer['credit_card_name'],
  //   contact_name: customer['contact_name'],
  //   company_name: customer['companyName'],
  //   toter_serial_numbers: customer['toterSerialNumbers'],
  //   phone_numbers: customer['phone_numbers'],
  //   billing_address: customer['billing_address'],
  //   service_address: customer['service_address'],
  //   dumpster_addresses: customer['dumpster_addresses'],
  //   dumpsters: customer['dumpsters'],
  //   pickup_day: customer['pickup_day'] || 'Monday',
  //   wants_einvoice: customer['wantsEInvoice'] === 'Yes' ? 1 : 0,
  //   is_exempt: customer['isExempt'] === 'Yes' ? 1 : 0,
  //   is_inactive: customer['isInactive'] === 'Yes' ? 1 : 0,
  //   is_closed: customer['isClosed'] === true ? 1 : 0,
  //   recurring_customer: customer['recurringCustomer'] === 'Yes' ? 1 : 0,
  //   recycling_customer: customer['recycling_customer'] === 'Yes' ? 1 : 0,
  //   auto_renew: customer['isAutoRenewed'] === true ? 1 : 0,
  //   mail_list_id: customer['list_id'],
  // };
};
const defaultValues = {
  "uid": "",
  "name": "",
  "email": "",
  "type": "commercial",
  "Account Number": "",
  "billFrequency": "quarterly",
  "wantsEInvoice": "No",
  "isExempt": "No",
  "isInactive": "No",
  "isClosed": false,
  "recurringCustomer": "No",
  "recycling_customer": "No",
  "type_of_reason": "",
  "Contract Term Length": "",
  "amount_due": 0,
  "rate": "",
  "isAutoRenewed": false,
  "expirationDay": "",
  "expirationMonth": "",
  "expirationYear": "",
  "credit_card": {
    number: "",
    cvc: "",
    expiry: ""
  },
  "credit_card_name": "",
  "contact_name": "",
  "companyName": "",
  "toterSerialNumbers": "",
  "phone_numbers": [],
  "dumpster_addresses": [{
    street: "",
    city: "",
    state: "",
    zipcode: ""
  }],
  "billing_address": {
    street: "",
    city: "",
    state: "",
    zipcode: ""
  },
  "service_address": {
    street: "",
    city: "",
    state: "",
    zipcode: ""
  },
  "dumpsters": [{
    number: "",
    type: "",
    frequency: "",
    address: ""
  }],
  "pickup_day": "Monday",
  "stops": [],
  "history": [],
  "notes": [],
  "callHistory": [],
  "list_id": "",
};
const AppCustomer = (dbCustomer, useDefault=false) => {
    const appCustomer = {};
    for (const [key1, key2] of KeyMap) {
      if (dbCustomer[key1] !== undefined){
        appCustomer[key2] = dbCustomer[key1];
        if(key2 === 'isAutoRenewed' || key2 === 'isClosed')
          appCustomer[key2] = appCustomer[key2] === 1;
        else if(key2 === 'wantsEInvoice' || key2 === 'isExempt' || key2 === 'isInactive' || key2 === 'recurringCustomer' || key2 === 'recycling_customer')
          appCustomer[key2] = appCustomer[key2] === 1 ? 'Yes' : 'No';
      }else {
        if(useDefault)
          appCustomer[key2] = defaultValues[key2];
      }
    }
    return appCustomer;
  // return {
  //   "uid": customer.uid,
  //   "name": customer.name || '',
  //   "email": customer.email || '',
  //   "type": customer.type || "commercial", // "commercial", "residential", "greenbag", "west_mayfield", "new_castle", "new_castle_2", "east_butler", "slippery_rock
  //   "Account Number": customer.acc_number || '',
  //   "billFrequency": customer.bill_frequency || 'quarterly',
  //   "wantsEInvoice": customer.wants_einvoice === 1 ? 'Yes' : 'No',
  //   "isExempt": customer.is_exempt === 1 ? 'Yes' : 'No',
  //   "isInactive": customer.is_inactive === 1 ? 'Yes' : 'No',
  //   "isClosed": customer.is_closed === 1,
  //   "recurringCustomer": customer.recurring_customer === 1 ? 'Yes' : 'No',
  //   "recycling_customer": customer.recycling_customer === 1 ? 'Yes' : 'No',
  //   "status": customer.status || 'Not Paid',
  //   "type_of_reason": customer.reason || '',
  //   "Contract Term Length": customer.contract_term_length || '',
  //   "amount_due": customer.amount_due || 0,
  //   "rate": customer.rate || '',
  //   "isAutoRenewed": customer.auto_renew === 1,
  //   "expirationDay": customer.exp_day || '',
  //   "expirationMonth": customer.exp_month || '',
  //   "expirationYear": customer.exp_year || '',
  //   "credit_card": customer.credit_card || {
  //     number: "",
  //     cvc: "",
  //     expiry: ""
  //   },
  //   "credit_card_name": customer.credit_card_name || '',
  //   "contact_name": customer.contact_name || '',
  //   "companyName": customer.company_name || '',
  //   "toterSerialNumbers": customer.toter_serial_numbers || '',
  //   "phone_numbers": customer.phone_numbers || [],
  //   "dumpster_addresses": customer.dumpster_addresses || [{
  //     street: "",
  //     city: "",
  //     state: "",
  //     zipcode: ""
  //   }],
  //   "billing_address": customer.billing_address || {
  //     street: "",
  //     city: "",
  //     state: "",
  //     zipcode: ""
  //   },
  //   "service_address": customer.service_address || {
  //     street: "",
  //     city: "",
  //     state: "",
  //     zipcode: ""
  //   },
  //   "dumpsters": customer.dumpsters || [{
  //     number: "",
  //     type: "",
  //     frequency: "",
  //     address: ""
  //   }],
  //   "pickup_day": customer.pickup_day || 'Monday',
  //   "stops": customer.stops || [],
  //   "history": customer.history || [],
  //   "notes": customer.notes || [],
  //   "callHistory": customer.call_history || [],
  //   "list_id": customer.mail_list_id || "",
  // };
};

const NewCastleKeyMap = new Map([
  ['uid', 'uid'],
    ['name', 'Resident Name'],
    ['email', 'Email'],
    ['type', 'type'],
    ['acc_number', 'Account Number'],
    ['contract_term_length', 'Employee Size'],
    ['status', 'Status'],
    ['amount_due', 'amount_due'],
    ['rate', 'rate'],
    ['credit_card_name', 'Executive Last Name'],
    ['contact_name', 'Executive First Name'],
    ['company_name', 'Executive Title'],
    ['toter_serial_numbers', 'Fax Number Combined'],
    ['phone_numbers','Phone'],
    ['is_exempt', 'isExempt'],
    ['is_inactive', 'isInactive'],
    ['is_closed', 'isClosed'],
    ['recurring_customer', 'isRecurringCustomer'],
    ['recycling_customer', 'recycling_customer'],
    ['auto_renew', 'isAutoRenewed'],
    ['mail_list_id', 'list_id'],
    ["stops", "stops"],
    ["history", "history"],
    ["notes", "notes"],
    ["call_history", "callHistory"],
]);
const NewCastleDefaultValues = {
  "Resident Name": "",
  "Email": "",
  "type": "new_castle",
  "Account Number": "",
  "Employee Size": "",
  "isExempt": "No",
  "isInactive": "No",
  "isClosed": false,
  "isRecurringCustomer": "No",
  "recycling_customer": "No",
  "Status": "Not Paid",
  "type_of_reason": "",
  "amount_due": 0,
  "rate": "",
  "isAutoRenewed": false,
  "credit_card": {
    number: "",
    cvc: "",
    expiry: ""
  },
  "Executive Last Name": "",
  "Executive First Name": "",
  "Executive Title": "",
  "Fax Number Combined": "",
  "Phone": "",
  "Address": "",
  "City": "",
  "State": "",
  "Location ZIP Code": "",
  "ZIP+4": "",
  "Physical Address": "",
  "Physical City": "",
  "Physical State": "",
  "Physical ZIP": "",
  "Physical ZIP+4": "",
  "stops": [],
  "history": [],
  "notes": [],
  "callHistory": [],
  "list_id": "",
};
const NewCastletoDBCustomer = (customer) => {
  const dbCustomer = {};
  let billing_address = {};
  let service_address = {};
  let phone_numbers = [];
  for (const [key1, key2] of NewCastleKeyMap) {
    if(customer[key2] !== undefined){
      dbCustomer[key1] = customer[key2];
      if(dbCustomer[key1] === 'Yes')
        dbCustomer[key1] = 1;
      else if(dbCustomer[key1] === 'No')
        dbCustomer[key1] = 0;
      else if(dbCustomer[key1] === true)
        dbCustomer[key1] = 1;
      else if(dbCustomer[key1] === false)
        dbCustomer[key1] = 0;
    }
  }
  if (customer["Phone"] !== undefined) {
    phone_numbers.push({
      number: customer["Phone"]
    });
    dbCustomer["phone_numbers"] = phone_numbers;
  }
  if (customer["Address"] !== undefined){
    billing_address = {
      "Address": customer['Address'],
      "City": customer['City'],
      "State": customer['State'],
      "Location ZIP Code": customer['Location ZIP Code'],
      "ZIP+4": customer['ZIP+4']
    }
    dbCustomer["billing_address"] = billing_address;
  }
  if (customer["Physical Address"] !== undefined){
    service_address = {
      "Physical Address": customer['Physical Address'],
      "Physical City": customer['Physical City'],
      "Physical State": customer['Physical State'],
      "Physical ZIP": customer['Physical ZIP'],
      "Physical ZIP+4": customer['Physical ZIP+4']
    }
    dbCustomer["service_address"] = service_address;
  }
  return dbCustomer;
}

const toNewCastleCustomerObj = (customer, useDefault = false) => {
  const newCastleCustomer = {};
  for (const [key1, key2] of NewCastleKeyMap) {
    if (customer[key1] !== undefined){
      newCastleCustomer[key2] = customer[key1];
      if(key2 === 'isAutoRenewed' || key2 === 'isClosed')
        newCastleCustomer[key2] = newCastleCustomer[key2] === 1;
      else if(key2 === 'isExempt' || key2 === 'isInactive' || key2 === 'isRecurringCustomer' || key2 === 'recycling_customer')
        newCastleCustomer[key2] = newCastleCustomer[key2] === 1 ? 'Yes' : 'No';
    }else {
      if(useDefault)
        newCastleCustomer[key2] = NewCastleDefaultValues[key2];
    }
  }
  if (customer['phone_numbers'] !== undefined && customer['phone_numbers'].length > 0) {
    newCastleCustomer['Phone'] = customer['phone_numbers'][0].number;
  }
  if (customer['billing_address'] !== undefined) {
    newCastleCustomer['Address'] = customer['billing_address'].Address;
    newCastleCustomer['City'] = customer['billing_address'].City;
    newCastleCustomer['State'] = customer['billing_address'].State;
    newCastleCustomer['Location ZIP Code'] = customer['billing_address']['Location ZIP Code'];
    newCastleCustomer['ZIP+4'] = customer['billing_address']['ZIP+4'];
  }
  if (customer['service_address'] !== undefined) {
    newCastleCustomer['Physical Address'] = customer['service_address']['Physical Address'];
    newCastleCustomer['Physical City'] = customer['service_address']['Physical City'];
    newCastleCustomer['Physical State'] = customer['service_address']['Physical State'];
    newCastleCustomer['Physical ZIP'] = customer['service_address']['Physical ZIP'];
    newCastleCustomer['Physical ZIP+4'] = customer['service_address']['Physical ZIP+4'];
  }
  return newCastleCustomer;
};

export const CustomerTypes = {
  commercial: "commercial",
  residential: "residential",
  greenbag: "greenbag",
  west_mayfield: "west_mayfield",
  new_castle: "new_castle",
  new_castle_2: "new_castle_2",
  east_butler: "east_butler",
  slippery_rock: "slippery_rock",
}

export const _sortBy = (data=[], keys=[]) => {
  // const beforeSort = [...data]
  // console.log('data before sort: ', beforeSort);
  let sortable = [];
  let unsortable = [];
  let sortedData = [];
  for (const key of keys) {
    // console.log('key: ', key);
    unsortable = data.filter((item) => item[key] === undefined || item[key] === null || (typeof item[key] === 'string' && item[key].length === 0));
    sortable = data.filter((item) => !(item[key] === undefined || item[key] === null || (typeof item[key] === 'string' && item[key].length === 0)));
    sortable.sort((a, b) => {
      if(a[key] < b[key]) return -1;
      if(a[key] > b[key]) return 1;
      return 0;
    });
    // console.log('sortable: ', sortable);
    // console.log('unsortable: ', unsortable);
    sortedData = [...sortable, ...unsortable];
  }
    // console.log('data after sort: ', sortedData);

  return sortedData;
}

  const checkExisting = (items, item) => items.findIndex((d) => (
      item.city === d.city &&
      item.state === d.state &&
      item.street === d.street &&
      item.zipcode === d.zipcode
  ));

export {
  isQuarterlyCustomer,
  isMonthlyCustomer,
  isQuarterlyCustomerWithEInvoice,
  isQuarterlyCustomerWithoutEInvoice,
  AppCustomer,
  DBCustomer,
  toNewCastleCustomerObj,
  NewCastletoDBCustomer,
  checkExisting
};