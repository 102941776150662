/* @format - for Prettier */
import React from "react";
import styled from "styled-components";
import { Field } from "redux-form";
import { Grid, Row, Col } from "react-bootstrap";
import {
  renderField,
  renderStatusField,
  renderExemptField,
  renderPhoneNumber,
  normalizePhone,
  required,
  number,
  renderNotes
} from "../../../../renderComponents";

const StyledGrid = styled(Grid)`
  padding: 0px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 7px !important;
`;

const StyledCol = styled(Col)`
  padding: 0px;
`;

const CustomerForm = ({ meta: { error, submitFailed } }) => (
  <StyledGrid>
    <StyledRow>
      <StyledCol sm={4} md={4}>
        <StyledRow>
          <h4>Customer Information</h4>
        </StyledRow>
        <StyledRow>
          <Field
            name="Resident Name"
            label="Resident Name:"
            type="text"
            component={renderField}
            validate={[required]}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name="Account Number"
            label="Account Number:"
            type="text"
            component={renderField}
            validate={[required, number]}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name="Employee Size"
            label="Employee Size:"
            type="text"
            component={renderField}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name="Status"
            label="Status:"
            type="text"
            component={renderStatusField}
            validate={[required]}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name="isExempt"
            label="Is Exempt:"
            type="text"
            component={renderExemptField}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name="isRecurringCustomer"
            label="Is Recurring:"
            type="text"
            component={renderExemptField}
          />
        </StyledRow>
      </StyledCol>
      <StyledCol sm={4} md={4}>
        <StyledRow>
          <h4>Contact Information: </h4>
        </StyledRow>
        <StyledRow>
          <Field
            name="Phone"
            label="Phone Number:"
            type="text"
            component={renderPhoneNumber}
            normalize={normalizePhone}
            //validate={[required]}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name="Email"
            label="Email:"
            type="text"
            component={renderField}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name="Fax Number Combined"
            label="Fax Number:"
            type="text"
            component={renderPhoneNumber}
            normalize={normalizePhone}
            //validate={[required]}
          />
        </StyledRow>
        <h4>Executive</h4>
        <StyledRow>
          <Field
            name="Executive Title"
            label="Title:"
            type="text"
            component={renderField}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name="Executive First Name"
            label="First Name:"
            type="text"
            component={renderField}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name="Executive Last Name"
            label="Last Name:"
            type="text"
            component={renderField}
          />
        </StyledRow>
      </StyledCol>
    </StyledRow>
    <StyledRow>
      <StyledCol sm={4} md={4}>
        <StyledRow>
          <h4>Billing Address:</h4>
        </StyledRow>
        <StyledRow>
          <Field
            name="Address"
            type="text"
            component={renderField}
            label="Street:"
            validate={[required]}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name="City"
            type="text"
            component={renderField}
            label="City:"
            className="large"
            validate={[required]}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name="State"
            type="text"
            component={renderField}
            label="State:"
            className="large"
            validate={[required]}
          />
        </StyledRow>
        <StyledRow>
          <StyledCol sm={4} md={4}>
            <Field
              name="Location ZIP Code"
              type="text"
              component={renderField}
              label="ZIP:"
              validate={[required, number]}
              className="Zipcode"
            />
          </StyledCol>
          <StyledCol sm={5} md={5}>
            <Field
              name="ZIP+4"
              type="text"
              component={renderField}
              label="ZIP+4:"
              validate={[number]}
              className="Zipcode"
            />
          </StyledCol>
        </StyledRow>
      </StyledCol>
      <StyledCol sm={4} md={4}>
        <StyledRow>
          <h4>Service Address:</h4>
        </StyledRow>
        <StyledRow>
          <Field
            name="Physical Address"
            type="text"
            component={renderField}
            label="Street:"
            validate={[required]}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name="Physical City"
            type="text"
            component={renderField}
            label="City:"
            className="large"
            validate={[required]}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name="Physical State"
            type="text"
            component={renderField}
            label="State:"
            className="large"
            validate={[required]}
          />
        </StyledRow>
        <StyledRow>
          <StyledCol sm={4} md={4}>
            <Field
              name="Physical ZIP"
              type="text"
              component={renderField}
              label="ZIP:"
              validate={[required, number]}
              className="Zipcode"
            />
          </StyledCol>
          <StyledCol sm={5} md={5}>
            <Field
              name="Physical ZIP+4"
              type="text"
              component={renderField}
              label="ZIP+4:"
              validate={[number]}
              className="Zipcode"
            />
          </StyledCol>
        </StyledRow>
      </StyledCol>
      {/* <StyledCol sm={4} md={4}>
        <StyledRow>
          <h4>SIC Info:</h4>
        </StyledRow>
        <StyledRow>
          <Field
            name="Primary SIC Code"
            label="Primary SIC Code:"
            type="text"
            component={renderSmall}
            //validate={[required, number]}
          />
        </StyledRow>
        <StyledRow>
          <Field
            name="Primary SIC Description"
            label="Primary SIC Description:"
            type="text"
            component={renderSmall}
            //validate={[required]}
          />
        </StyledRow>
      </StyledCol> */}
    </StyledRow>
  </StyledGrid>
);

export default CustomerForm;
