/* @format - for Prettier */
import React from "react";
import _ from "lodash";
import styled from "styled-components";
import Select from "react-select";
import { connect } from "react-redux";
import {
  Table,
  Button,
  Glyphicon,
  Modal,
  ProgressBar,
  FormControl,
  Row,
} from "react-bootstrap";
import toastr from "toastr";
import DatePicker from "react-datepicker";
import { db } from "../../firebase";

import {
  Customers as CustomersActions,
  ResidentialCustomers as ResidentialCustomersActions,
  WestMayfieldCustomers as WestMayfieldCustomersActions,
  NewCastleCustomers2 as NewCastleCustomers2Actions,
  EastButlerCustomers as EastButlerCustomersActions,
  SlipperyRockCustomers as SlipperyRockCustomersActions
} from "../../actions2";
import { DeniedWrapper } from "../../styled";
import printHtmlElement from "print-html-element";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import ReactExport from "react-data-export";
import moment from "moment";
import { quarterOptions } from "../../utils";
import { _sortBy, CustomerTypes } from "../../utils/customer";
import axiosInstance from "../../api/axios";

const axios = require("axios");
const pdfService = require("../../services/pdf").pdfService;

const AccordionContainer = styled.div`
  margin: 20px 0px !important;
`;

const StyledRow = styled(Row)`
  margin-bottom: 20px !important;
`;

const StyledSelect = styled.select`
  margin-bottom: 10px;
`;

const ReasonTypes = ["Unpaid Bill", "Other: Keep Suspended"];

const ReasonOptions = ReasonTypes.map((reasonType) => {
  return {
    label: reasonType,
    value: reasonType,
  };
});

const Header = styled.div`
  display: flex;
`;

const CustomersWrapper = styled.div`
  width: 80vw;
  margin: 0 auto;
  margin-top: 30px;
`;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  margin: 10px 0px 0px 10px;
  float: right;
`;

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`;
const ActionButton = styled(Button)`
  margin: 0px 0px 0px 10px;
  float: right;
`;
const PrintProgress = styled(ProgressBar)`
  margin-top: 20px;
`;
const CloseMoadlButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ExportButton = (
  <ToolButton bsStyle="primary">
    <AddGlyph glyph="export" />
    Export to Excel
  </ToolButton>
);
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DueAccounts extends React.Component {
  state = {
    Rcustomers: [],
    Wcustomers: [],
    NCcustomers2: [],
    EBcustomers: [],
    SRcustomers: [],
    print_quarterly_modal_show: false,
    print_quarterly_einvoice_modal_show: false,
    modal_quarter: "",
    quarter_progress: 0,
    progress: 0,
    select_quarter: "",
    dueDate: new Date(),
    quarter_progressing_show: false,
    eInvoicesForQuarterlyTotal: 0,
    selected_month_customer: {},
    print_month_einvoice_modal_show: false,
    month_modal_quarter: "",
    eInvoicesForQuarterlyResults: [],
    print_month_modal_show: false,
    serviceQuarterOptions: [
      {
        value: "JAN, FEB, MAR",
        label: "JAN, FEB, MAR",
      },
      {
        value: "APR, MAY, JUN",
        label: "APR, MAY, JUN",
      },
      {
        value: "JUL, AUG, SEP",
        label: "JUL, AUG, SEP",
      },
      {
        value: "OCT, NOV, DEC",
        label: "OCT, NOV, DEC",
      },
    ],
    monthOptions: [],
    month_select_quarter: "",
  };

  componentDidMount(next) {
    this.props.getPastDueCustomers();
    this.props.getRCustomers();
    this.props.getWCustomers();
    this.props.getNCCustomers2();
    this.props.getEBCustomers();
    this.props.getSRCustomers();
    let mapping_months = [
      "",
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    let months = [];
    for (let i = 1; i <= 12; i += 1) {
      months.push({
        value: mapping_months[i],
        label: mapping_months[i],
      });
    }
    this.setState({
      monthOptions: months,
    });
  }

  //  Commercial Functions
  quarterChanged = (e) => {
    this.setState({
      modal_quarter: e.value,
      select_quarter: e,
    });
  };
  onMessage = (message) => {
    if (message.data.done) {
      this.onProcessComplete();
    }
    if (message.data.file) {
      this.setState({
        progress: message.data.progress,
      });
      this.onPdfGenerate(message.data.file, message.data.batch);
    }
  };
  onProcessComplete = () => {
    setTimeout(() => {
      this.setState({
        progress: 0,
        progressing_show: false,
      });
    }, 2000);
  };

  closeQuarterlyEmailModal = () => {
    this.setState({
      quarter_progressing_show: false,
      quarter_progress: 0,
      eInvoicesForQuarterlyResults: [],
    });
  };
  //handlePrint handles printing the customers currently not printing their invoices
  handlePrint = () => {
    var element = document.getElementById("print");
    printHtmlElement.printElement(element, { pageTitle: "Past Due Cusotmers" });
  };

  RhandlePrint = () => {
    var element = document.getElementById("Rprint");
    printHtmlElement.printElement(element, { pageTitle: "Past Due Cusotmers" });
  };

  printForQuarterlyEinvoice = (value) => {
    this.setState({
      print_quarterly_einvoice_modal_show: true,
      selectedLocationValue: value,
    });
  };
  printForQuarterly = (value) => {
    this.setState({
      print_quarterly_modal_show: true,
      selectedLocationValue: value,
    });
  };
  printForMonth = (customer, value) => {
    this.setState({
      print_month_modal_show: true,
      selected_month_customer: customer,
      selectedLocationValue: value,
    });
  };

  handleNoteChanged = (e) => {
    //console.log(e.target.value);
    this.setState({ note: e.target.value });
  };

  setTypeOfReason = (e) => {
    this.setState({ type_of_reason: e.target.value });
  };

  handleSuspendAccount = (customer, value) => {
    //We do this because normal query does not get the customer's stops that are also needed to be suspended.
      this.setState({ 
        show_suspended_modal: true,
        selected_month_customer: customer,
        selectedLocationValue: value,
      });
  };

  handleSaveSuspended = (customer, value) => {
    if (value === "comm") {
      if (
        (this.state.type_of_reason === false) |
        (this.state.type_of_reason === "")
      ) {
        alert("Please select a valid reason.");
        return;
      } else {
        this.props.commSuspend(
          customer,
          this.state.note,
          this.state.type_of_reason
        );
        console.log(customer.name, value);
        this.handleCloseSuspendModal();
      }
    } else if (value === "nc2") {
      if (
        (this.state.type_of_reason === false) |
        (this.state.type_of_reason === "")
      ) {
        alert("Please select a valid reason.");
        return;
      } else {
        this.props.newCastle2Suspend(
          customer,
          this.state.note,
          this.state.type_of_reason
        );
        console.log(customer.name, value);
        this.handleCloseSuspendModal();
      }
    } else if (value === "bwm") {
      if (
        (this.state.type_of_reason === false) |
        (this.state.type_of_reason === "")
      ) {
        alert("Please select a valid reason.");
        return;
      } else {
        this.props.westMayfieldSuspend(
          customer,
          this.state.note,
          this.state.type_of_reason
        );
        console.log(customer.name, value);
        this.handleCloseSuspendModal();
      }
    } else if (value === "res") {
      if (
        (this.state.type_of_reason === false) |
        (this.state.type_of_reason === "")
      ) {
        alert("Please select a valid reason.");
        return;
      } else {
        this.props.residentialSuspend(
          customer,
          this.state.note,
          this.state.type_of_reason
        );
        console.log(customer.name, value);
        this.handleCloseSuspendModal();
      }
    } else if (value === "EB") {
      if (
        (this.state.type_of_reason === false) |
        (this.state.type_of_reason === "")
      ) {
        alert("Please select a valid reason.");
        return;
      } else {
        this.props.eastButlerSuspend(
          customer,
          this.state.note,
          this.state.type_of_reason
        );
        console.log(customer.name, value);
        this.handleCloseSuspendModal();
      }
    } else if (value === "SR") {
      if (
        (this.state.type_of_reason === false) |
        (this.state.type_of_reason === "")
      ) {
        alert("Please select a valid reason.");
        return;
      } else {
        this.props.slipperyRockSuspend(
          customer,
          this.state.note,
          this.state.type_of_reason
        );
        console.log(customer.name, value);
        this.handleCloseSuspendModal();
      }
    } else {
      console.log("Error: something is amiss");
    }
  };

  handleCancelSuspend = () => {
    this.handleCloseSuspendModal();
  };

  handleCloseSuspendModal = () => {
    this.setState({ show_suspended_modal: false });
  };

  timer = (ms) => {
    return new Promise((res) => setTimeout(res, ms));
  };
  handleQuarterlyPrint = async (value) => {
    this.setState({
      selectedLocationValue: value,
      exporting: true,
      printProgress: 0,
    });
    let dataWithCustomerType =
      value === "nc2"
        ? this.state.NCcustomers2
        : value === "comm"
        ? this.props.pastDueCustomers
        : value === "bwm"
        ? this.state.Wcustomers
        : this.state.Rcustomers;

    if (value === "eb") {
      dataWithCustomerType = this.state.EBcustomers;
    } else if (value === "srb") {
      dataWithCustomerType = this.state.SRcustomers;
    }
    const quarterlyCustomers = _.filter(dataWithCustomerType, (customer) => {
      if (customer["billFrequency"] === "monthly") return false;
      if (customer["isExempt"] === "Yes") return false;
      if (customer["recurringCustomer"] === "Yes") return false;
      if (customer["wantsEInvoice"] === "Yes") return false;
      return true;
    });
    if (value === "comm") {
      await pdfService.commercial_customer_invoice_pdf_download(
        quarterlyCustomers,
        "all quarterly",
        this.state.dueDate,
        "",
        "",
        this.state.select_quarter.value,
        "",
        "",
      );
    } else if (value === "bwm") {
      await pdfService.westmayfield_customer_invoice_pdf_download(
        quarterlyCustomers,
        "all quarterly",
        this.state.dueDate,
        "",
        "",
        "",
        "",
        ""
      );
    } else if (value === "srb") {
      await pdfService.westmayfield_customer_invoice_pdf_download(
        quarterlyCustomers,
        "all quarterly",
        this.state.dueDate,
        "",
        "",
        "",
        "",
        ""
      );
    } else if (value === "eb") {
      await pdfService.westmayfield_customer_invoice_pdf_download(
        quarterlyCustomers,
        "all quarterly",
        this.state.dueDate,
        "",
        "",
        "",
        "",
        ""
      );
    } else {
      await pdfService.residential_customer_invoice_pdf_download(
        quarterlyCustomers,
        "all quarterly",
        this.state.dueDate,
        "",
        "",
        "",
        "",
        ""
      );
    }
    this.setState({
      exporting: false,
      printProgress: 100,
      print_quarterly_modal_show: false,

    });
  };
  eInvoicesForQuarterlyWithEInvoice = async (value) => {
    this.setState({
      print_quarterly_einvoice_modal_show: false,
      selectedLocationValue: value,
    });
    var dataWithCustomerType =
      value === "nc2"
        ? this.state.NCcustomers2
        : value === "comm"
        ? this.props.pastDueCustomers
        : value === "bwm"
        ? this.state.Wcustomers
        : this.state.Rcustomers;
    if (value === "srb") {
      dataWithCustomerType = this.state.SRcustomers;
    } else if (value === "eb") {
      dataWithCustomerType = this.state.EBcustomers;
    }
    const eInvoicesForQuarterlyWithEInvoice = _.filter(
      dataWithCustomerType,
      (customer) => {
        if (customer["billFrequency"] === "monthly") return false;
        if (customer["isExempt"] === "Yes") return false;
        if (customer["recurringCustomer"] === "Yes") return false;
        if (
          customer["wantsEInvoice"] === undefined ||
          customer["wantsEInvoice"] === "No"
        )
          return false;
        return true;
      }
    );
    //this code below is for creating a loading bar when e-invoices are called
    this.setState({
      quarter_progressing_show: true,
      quarter_progress: 0,
    });

    const Length = eInvoicesForQuarterlyWithEInvoice.length;
    let eInvoicesForQuarterlyResults = [];
    this.setState({
      eInvoicesForQuarterlyTotal: Length,
    });
    for (let i = 0; i < Length; i += 1) {
      await this.timer(200);
      const customer = eInvoicesForQuarterlyWithEInvoice[i];
      var base64;
      if (value === "comm") {
        base64 = await pdfService.commercial_customer_invoice_pdf_download(
          [customer],
          "base64",
          this.state.dueDate,
          "",
          "",
          "",
          "",
          ""
        ); //this.state.select_quarter.value - used to be third from bottom
      } else if (value === "bwm") {
        base64 = await pdfService.westmayfield_customer_invoice_pdf_download(
          [customer],
          "base64",
          this.state.dueDate,
          "",
          ""
        );
      } else if (value === "nc2") {
        base64 = await pdfService.newcastle2_customer_invoice_pdf_download(
          [customer],
          "base64",
          this.state.dueDate,
          "",
          ""
        );
      } else if (value === "srb") {
        base64 = await pdfService.newcastle2_customer_invoice_pdf_download(
          [customer],
          "base64",
          this.state.dueDate,
          "",
          ""
        );
      } else if (value === "eb") {
        base64 = await pdfService.newcastle2_customer_invoice_pdf_download(
          [customer],
          "base64",
          this.state.dueDate,
          "",
          ""
        );
      } else {
        base64 = await pdfService.residential_customer_invoice_pdf_download(
          [customer],
          "base64",
          this.state.dueDate,
          "",
          ""
        );
      }
      let sender_email = customer.email;
      if (process.env.REACT_APP_ENVIRONMENT === "development")
        sender_email = "afebbraro@atsapllc.com";
      let data = {
        index: i,
        email: sender_email,
        content: base64,
      };
        axiosInstance.post('/api/customers/send_eInvoiceMail', {
          ...data
        })
        .then((res) => {
          eInvoicesForQuarterlyResults.push({
            customer: eInvoicesForQuarterlyWithEInvoice[res.data.id],
            success: res.data.success,
          });
        })
        .catch((err) => {
          eInvoicesForQuarterlyResults.push({
            customer: customer,
            success: 0,
          });
        })
        .finally(() => {
          this.setState({
            eInvoicesForQuarterlyResults: eInvoicesForQuarterlyResults,
            quarter_progress: parseInt(
              (eInvoicesForQuarterlyResults.length * 100) / Length
            ),
          });
          if (eInvoicesForQuarterlyResults.length === Length)
            toastr.success("Successfully Sent E-mails.", "Success");
        });
    }
  };
  //  Residential Functions

  //initially calls the modal and updates the "location" meaning res,comm,nc2, or bmw
  printEInvoiceMonth = (customer, value) => {
    this.setState({
      print_month_einvoice_modal_show: true,
      selected_month_customer: customer,
      selectedLocationValue: value,
    });
  };
  monthChanged = (e) => {
    this.setState({
      month_modal_quarter: e.value,
      month_select_quarter: e,
    });
  };
  handleEInvoiceForMonth = async (customer, value) => {
    this.setState({
      print_month_einvoice_modal_show: false,
    });
    this.setState({
      quarter_progressing_show: true,
      quarter_progress: 0,
    });
    var base64;
    if (value === "comm") {
      base64 = await pdfService.commercial_customer_invoice_pdf_download(
        [customer],
        "base64",
        this.state.dueDate,
        "",
        "",
        this.state.month_modal_quarter,
        "",
        ""
      );
    } else if (value === "nc2") {
      base64 = await pdfService.newcastle2_customer_invoice_pdf_download(
        [customer],
        "base64",
        this.state.dueDate,
        "",
        ""
      );
    } else if (value === "bwm") {
      base64 = await pdfService.westmayfield_customer_invoice_pdf_download(
        [customer],
        "base64",
        this.state.dueDate,
        "",
        ""
      );
    } else {
      base64 = await pdfService.residential_customer_invoice_pdf_download(
        [customer],
        "base64",
        this.state.dueDate,
        "",
        ""
      );
    }

    this.setState({
      quarter_progress: 50,
    });
    let sender_email = customer.email;
    if (process.env.REACT_APP_ENVIRONMENT === "development")
      sender_email = "afebbraro@atsapllc.com";
    let data = {
      index: 0,
      email: sender_email,
      content: base64,
    };
    axiosInstance.post('/api/customers/send_eInvoiceMail', {
      ...data
    })
      .then((res) => {
        if (res.data.success)
          toastr.success("Successfully Sent E-mail.", "Success");
        else
          toastr.error("Unexpected Error Happend!. Please try again", "Error");
      })
      .catch((err) => {
        toastr.error("Unexpected Error Happend!. Please try again", "Error");
      })
      .finally(() => {
        this.setState({
          quarter_progress: 100,
        });
        setTimeout(() => {
          this.setState({
            quarter_progressing_show: false,
          });
        }, 1000);
      });
  };
  //this is for the button to call this initially, pull the modal and update the "location" selectedLocationValue
  printMonthInvoice = async (customer, value) => {
    this.setState({
      print_month_modal_show: true,
      selectedLocationValue: value,
    });
  };

  handlePrintMonth = async (customer, value) => {
    this.setState({
      print_month_modal_show: false,
    });
    if (value === "comm") {
      pdfService.commercial_customer_invoice_pdf_download(
        [customer],
        "montly",
        this.state.dueDate,
        "",
        "",
        this.state.month_modal_quarter,
        "",
        ""
      );
    } else if (value === "nc2") {
      pdfService.newcastle2_customer_invoice_pdf_download(
        [customer],
        "montly",
        this.state.dueDate,
        "",
        ""
      );
    } else if (value === "bwm") {
      pdfService.westmayfield_customer_invoice_pdf_download(
        [customer],
        "montly",
        this.state.dueDate,
        "",
        ""
      );
    } else {
      pdfService.residential_customer_invoice_pdf_download(
        [customer],
        "montly",
        this.state.dueDate,
        "",
        ""
      );
    }
  };

  render() {
    this.state.Rcustomers = _.filter(this.props.Rcustomers, (customer) => {
      if (
        customer.status === "Charged" ||
        customer.status === "charged" ||
        customer.status === "Suspended" ||
        (customer.status === "suspended" && customer.amount_due > 0)
      ) {
        return true;
      }
      return false;
    });
    this.state.Wcustomers = _.filter(this.props.Wcustomers, (customer) => {
      if (
        customer.status === "Charged" ||
        customer.status === "charged" ||
        customer.status === "Suspended" ||
        (customer.status === "suspended" && customer.amount_due > 0)
      ) {
        return true;
      }
      return false;
    });
    this.state.NCcustomers2 = _.filter(this.props.NCcustomers2, (customer) => {
      if (
        customer.status === "Charged" ||
        customer.status === "charged" ||
        customer.status === "Suspended" ||
        (customer.status === "suspended" && customer.amount_due > 0)
      ) {
        return true;
      }
      return false;
    });
    this.state.EBcustomers = _.filter(this.props.EBcustomers, (customer) => {
      if (
        customer.status === "Charged" ||
        customer.status === "charged" ||
        customer.status === "Suspended" ||
        (customer.status === "suspended" && customer.amount_due > 0)
      ) {
        return true;
      }
      return false;
    });

    this.state.SRcustomers = _.filter(this.props.SRcustomers, (customer) => {
      if (
        customer.status === "Charged" ||
        customer.status === "charged" ||
        customer.status === "Suspended" ||
        (customer.status === "suspended" && customer.amount_due > 0)
      ) {
        return true;
      }
      return false;
    });
    const quarterlyCommCustomers = _.filter(
      this.props.pastDueCustomers,
      (customer) => {
        if(customer["type"] !== CustomerTypes.commercial.valueOf()) return false;
        if (customer["billFrequency"] === "monthly") return false;
        if (customer["isExempt"] === "Yes") return false;
        if (customer["recurringCustomer"] === "Yes") return false;
        return true;
      }
    );
    const exportQuarterlyCommCustomers =
      quarterlyCommCustomers &&
      quarterlyCommCustomers.map((value, index) => {
        const data = {
          account: value["Account Number"],
          customer: value.name,
          email: value.email,
          phoneNumber: value.phone_numbers
            ? value.phone_numbers.length
              ? value.phone_numbers.reduce((acc, item, ind) => {
                  if (item && item.number) {
                    acc +=
                      ind !== value.phone_numbers.length - 1
                        ? item.number + ","
                        : item.number;
                    return acc;
                  } else if (item && !item.number) {
                    acc +=
                      ind !== value.phone_numbers.length - 1
                        ? item + ","
                        : item;
                    return acc;
                  }
                  return acc;
                }, "")
              : value.phone_numbers
            : "",
        };
        return data;
      });

    const monthlyCommCustomers = _.filter(
      this.props.pastDueCustomers,
      (customer) => {
        if (customer["type"] !== CustomerTypes.commercial.valueOf()) return false;
        if (customer["isExempt"] === "Yes") return false;
        if (customer["recurringCustomer"] === "Yes") return false;
        if (customer["billFrequency"] === "monthly") return true;
        if (customer.status === "Suspended" || customer.status === "suspended")
          return true;
        return false;
      }
    );
    const exportMonthlyCommCustomers =
      monthlyCommCustomers &&
      monthlyCommCustomers.map((value, index) => {
        const data = {
          account: value["Account Number"],
          customer: value.name,
          email: value.email,
          phoneNumber: value.phone_numbers
            ? value.phone_numbers.length
              ? value.phone_numbers.reduce((acc, item, ind) => {
                  if (item && item.number) {
                    acc +=
                      ind !== value.phone_numbers.length - 1
                        ? item.number + ","
                        : item.number;
                    return acc;
                  } else if (item && !item.number) {
                    acc +=
                      ind !== value.phone_numbers.length - 1
                        ? item + ","
                        : item;
                    return acc;
                  }
                  return acc;
                }, "")
              : value.phone_numbers
            : "",
        };
        return data;
      });

    let quarterlyResCustomers = _.filter(
      this.state.Rcustomers,
      (customer) => {
        if (customer["amount_due"] === "NaN") return false;
        if (customer["billFrequency"] === "monthly") return false;
        if (customer["isExempt"] === "Yes") return false;
        if (customer["recurringCustomer"] === "Yes") return false;
        if (
          (customer["billFrequency"] !== "monthly" &&
            customer["status"] === "Suspended") ||
          customer["status"] === "suspended"
        )
          return true;

        return true;
      }
    );
    quarterlyResCustomers = _sortBy(quarterlyResCustomers, ["Account Number"]);
    const exportQuarterlyResCustomers =
      quarterlyResCustomers &&
      quarterlyResCustomers.map((value, index) => {
        const data = {
          account: value["Account Number"],
          customer: value.name,
          day: value.pickup_day,
          phoneNumber: value.phone_numbers
            ? value.phone_numbers.length
              ? value.phone_numbers.reduce((acc, item, ind) => {
                  if (item && item.number) {
                    acc +=
                      ind !== value.phone_numbers.length - 1
                        ? item.number + ","
                        : item.number;
                    return acc;
                  } else if (item && !item.number) {
                    acc +=
                      ind !== value.phone_numbers.length - 1
                        ? item + ","
                        : item;
                    return acc;
                  }
                  return acc;
                }, "")
              : value.phone_numbers
            : "",
        };
        return data;
      });

    const monthlyResustomers = _.filter(this.state.Rcustomers, (customer) => {
      if (customer["isExempt"] === "Yes") return false;
      if (customer["recurringCustomer"] === "Yes") return false;
      //if (customer["billFrequency"] === "monthly") return true;
      if (
        customer["billFrequency"] === "monthly" &&
        (customer["status"] === "Suspended" ||
          customer["status"] === "suspended" ||
          customer["status"] === "Charged" ||
          customer["status"] === "charged")
      )
        return true;
      return false;
    });
    const exportMonthlyResustomers =
      monthlyResustomers &&
      monthlyResustomers.map((value, index) => {
        const data = {
          account: value["Account Number"],
          customer: value.name,
          day: value.pickup_day,
          phoneNumber: value.phone_numbers
            ? value.phone_numbers.length
              ? value.phone_numbers.reduce((acc, item, ind) => {
                  if (item && item.number) {
                    acc +=
                      ind !== value.phone_numbers.length - 1
                        ? item.number + ","
                        : item.number;
                    return acc;
                  } else if (item && !item.number) {
                    acc +=
                      ind !== value.phone_numbers.length - 1
                        ? item + ","
                        : item;
                    return acc;
                  }
                  return acc;
                }, "")
              : value.phone_numbers
            : "",
        };
        return data;
      });

    const monthlyBWMcustomers = _.filter(this.state.Wcustomers, (customer) => {
      if (customer["isExempt"] === "Yes") return false;
      if (customer["recurringCustomer"] === "Yes") return false;
      if (customer["billFrequency"] === "monthly") return true;
      if (
        customer["billFrequency"] === "monthly" &&
        (customer["status"] === "Suspended" ||
          customer["status"] === "suspended" ||
          customer["status"] === "Charged" ||
          customer["status"] === "charged")
      )
        return true;
      return false;
    });

    const monthlyNCcustomers2 = _.filter(
      this.state.NCcustomers2,
      (customer) => {
        if (customer["isExempt"] === "Yes") return false;
        if (customer["recurringCustomer"] === "Yes") return false;
        if (customer["billFrequency"] === "monthly") return true;
        if (
          customer["billFrequency"] === "monthly" &&
          (customer["status"] === "Suspended" ||
            customer["status"] === "suspended" ||
            customer["status"] === "Charged" ||
            customer["status"] === "charged")
        )
          return true;
        return false;
      }
    );

    const monthlyEastButler = _.filter(this.state.EBcustomers, (customer) => {
      if (customer["isExempt"] === "Yes") return false;
      if (customer["recurringCustomer"] === "Yes") return false;
      //if (customer["billFrequency"] === "monthly") return true;
      //if (customer["billFrequency"] === "monthly" && customer["status"] === "Suspended" || customer["status"] === "suspended" || customer["status"] === "Charged" || customer["status"] === "charged") return true;
      if (
        customer["status"] === "Suspended" ||
        customer["status"] === "suspended" ||
        customer["status"] === "Charged" ||
        customer["status"] === "charged"
      )
        return true;
      return false;
    });

    const monthlySlipperyRock = _.filter(this.state.SRcustomers, (customer) => {
      if (customer["isExempt"] === "Yes") return false;
      if (customer["recurringCustomer"] === "Yes") return false;
      //if (customer["billFrequency"] === "monthly") return true;
      //if (customer["billFrequency"] === "monthly" && customer["status"] === "Suspended" || customer["status"] === "suspended" || customer["status"] === "Charged" || customer["status"] === "charged") return true;
      if (
        customer["status"] === "Suspended" ||
        customer["status"] === "suspended" ||
        customer["status"] === "Charged" ||
        customer["status"] === "charged"
      )
        return true;
      return false;
    });

    const exportMonthlyBWMcustomers =
      monthlyBWMcustomers &&
      monthlyBWMcustomers.map((value, index) => {
        const data = {
          account: value["Account Number"],
          customer: value.name,
          day: value.pickup_day,
          phoneNumber: value.phone_numbers
            ? value.phone_numbers.length
              ? value.phone_numbers.reduce((acc, item, ind) => {
                  if (item && item.number) {
                    acc +=
                      ind !== value.phone_numbers.length - 1
                        ? item.number + ","
                        : item.number;
                    return acc;
                  } else if (item && !item.number) {
                    acc +=
                      ind !== value.phone_numbers.length - 1
                        ? item + ","
                        : item;
                    return acc;
                  }
                  return acc;
                }, "")
              : value.phone_numbers
            : "",
        };
        return data;
      });

    const quarterlyBWMCustomers = _.filter(
      this.state.Wcustomers,
      (customer) => {
        if (customer["billFrequency"] === "monthly") return false;
        if (customer["isExempt"] === "Yes") return false;
        if (customer["recurringCustomer"] === "Yes") return false;
        if (customer.status === "Suspended" || customer.status === "suspended")
          return true;

        return true;
      }
    );

    const quarterlyNCCustomers2 = _.filter(
      this.state.NCcustomers2,
      (customer) => {
        if (customer["billFrequency"] === "monthly") return false;
        if (customer["isExempt"] === "Yes") return false;
        if (customer["recurringCustomer"] === "Yes") return false;
        if (customer.status === "Suspended" || customer.status === "suspended")
          return true;

        return true;
      }
    );
    const exportQuarterlyBWMCustomers =
      quarterlyBWMCustomers &&
      quarterlyBWMCustomers.map((value, index) => {
        const data = {
          account: value["Account Number"],
          customer: value.name,
          day: value.pickup_day,
          phoneNumber: value.phone_numbers
            ? value.phone_numbers.length
              ? value.phone_numbers.reduce((acc, item, ind) => {
                  if (item && item.number) {
                    acc +=
                      ind !== value.phone_numbers.length - 1
                        ? item.number + ","
                        : item.number;
                    return acc;
                  } else if (item && !item.number) {
                    acc +=
                      ind !== value.phone_numbers.length - 1
                        ? item + ","
                        : item;
                    return acc;
                  }
                  return acc;
                }, "")
              : value.phone_numbers
            : "",
        };
        return data;
      });

    if (this.props.authorized) {
      return (
        <CustomersWrapper>
          {this.props.Rcustomers.length &&
          this.props.Wcustomers.length &&
          this.props.NCcustomers2.length &&
          this.props.pastDueCustomers.length ? (
            <Header>
              <ExcelFile element={ExportButton} filename="Past Due Reports">
                <ExcelSheet
                  data={exportQuarterlyCommCustomers}
                  name="Commercial Customers (Quarterly)"
                >
                  <ExcelColumn label="Account #" value="account" />
                  <ExcelColumn label="Customer Name" value="customer" />
                  <ExcelColumn label="Customer Address" value="address" />
                  <ExcelColumn label="Email Address" value="email" />
                  <ExcelColumn label="Phone Number(s)" value="phoneNumber" />
                  <ExcelColumn label="Balance" value="amount_due" />
                  <ExcelColumn label="Status" value="status" />
                </ExcelSheet>
                <ExcelSheet
                  data={exportMonthlyCommCustomers}
                  name="Commercial Customers (Monthly)"
                >
                  <ExcelColumn label="Account #" value="account" />
                  <ExcelColumn label="Customer Name" value="customer" />
                  <ExcelColumn label="Customer Address" value="address" />
                  <ExcelColumn label="Email Address" value="email" />
                  <ExcelColumn label="Phone Number(s)" value="phoneNumber" />
                  <ExcelColumn label="Balance" value="amount_due" />
                  <ExcelColumn label="Status" value="status" />
                </ExcelSheet>
                <ExcelSheet
                  data={exportQuarterlyBWMCustomers}
                  name="West Mayfield Customers (Quarterly)"
                >
                  <ExcelColumn label="Account #" value="account" />
                  <ExcelColumn label="Customer Name" value="customer" />
                  <ExcelColumn label="Customer Address" value="address" />
                  <ExcelColumn label="Pick Up Day" value="day" />
                  <ExcelColumn label="Phone Number(s)" value="phoneNumber" />
                  <ExcelColumn label="Balance" value="amount_due" />
                  <ExcelColumn label="Status" value="status" />
                </ExcelSheet>
                <ExcelSheet
                  data={exportMonthlyBWMcustomers}
                  name="West Mayfield Customers (Monthly)"
                >
                  <ExcelColumn label="Account #" value="account" />
                  <ExcelColumn label="Customer Name" value="customer" />
                  <ExcelColumn label="Customer Address" value="address" />
                  <ExcelColumn label="Pick Up Day" value="day" />
                  <ExcelColumn label="Phone Number(s)" value="phoneNumber" />
                  <ExcelColumn label="Balance" value="amount_due" />
                  <ExcelColumn label="Status" value="status" />
                </ExcelSheet>
                <ExcelSheet
                  data={exportQuarterlyResCustomers}
                  name="Residential Customers (Quarterly)"
                >
                  <ExcelColumn label="Account #" value="account" />
                  <ExcelColumn label="Customer Name" value="customer" />
                  <ExcelColumn label="Customer Address" value="address" />
                  <ExcelColumn label="Pick Up Day" value="day" />
                  <ExcelColumn label="Phone Number(s)" value="phoneNumber" />
                  <ExcelColumn label="Balance" value="amount_due" />
                  <ExcelColumn label="Status" value="status" />
                </ExcelSheet>
                <ExcelSheet
                  data={exportMonthlyResustomers}
                  name="Residential Customers (Monthly)"
                >
                  <ExcelColumn label="Account #" value="account" />
                  <ExcelColumn label="Customer Name" value="customer" />
                  <ExcelColumn label="Customer Address" value="address" />
                  <ExcelColumn label="Pick Up Day" value="day" />
                  <ExcelColumn label="Phone Number(s)" value="phoneNumber" />
                  <ExcelColumn label="Balance" value="amount_due" />
                  <ExcelColumn label="Status" value="status" />
                </ExcelSheet>
              </ExcelFile>
            </Header>
          ) : (
            ""
          )}

          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>Commercial Customers (Quarterly) </label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Header>
                    <div style={{ width: "100%" }}>
                      <ToolButton
                        onClick={() => this.printForQuarterlyEinvoice("comm")}
                        bsStyle="primary"
                      >
                        Send E-Invoices
                      </ToolButton>
                      <ToolButton
                        onClick={() => this.printForQuarterly("comm")} //changed from printForQuarterly
                        bsStyle="primary"
                      >
                        Print All Invoices
                      </ToolButton>
                    </div>
                  </Header>
                  <div style={{ width: "100%" }}>
                    <label className="mb-2">
                      Number of Commercial Customers:{" "}
                      {quarterlyCommCustomers.length}
                    </label>
                  </div>
                  <Table id="print" hover bordered>
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer</th>
                        <th>Address</th>
                        <th>Email Address</th>
                        <th>Phone Number(s)</th>
                        <th>Balance Due</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(quarterlyCommCustomers, (customer) => {
                        // console.log(customer,'my customers quarterly')
                        return (
                          <tr key={customer.uid}>
                            <td
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <div>{customer["Account Number"]}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <div>{customer.name}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <div>{billingAddress(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <div>{customer.email}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <div>
                                {_.map(
                                  customer.phone_numbers,
                                  (phone_number, index) => {
                                    if (
                                      !phone_number.type &&
                                      phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${phone_number.number}`}
                                        </li>
                                      );
                                    } else if (
                                      !phone_number.type &&
                                      !phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${customer.phone_numbers[index]}`}
                                        </li>
                                      );
                                    } else {
                                      return (
                                        <li key={index}>
                                          {`${phone_number.type}: ${phone_number.number}`}
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <div>{billingAmountDue(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <div>{customer.status}</div>
                            </td>
                            <td>
                              <ActionButton
                                onClick={() =>
                                  this.printEInvoiceMonth(customer, "comm")
                                }
                                bsStyle="primary"
                              >
                                Send E-Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.printForMonth(customer, "comm")
                                }
                                bsStyle="primary"
                              >
                                Print Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.handleSuspendAccount(customer, "comm")
                                }
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                              >
                                Suspend
                              </ActionButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>Commercial Customers (Monthly)</label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Header>
                    <div style={{ width: "100%" }}>
                      <ToolButton
                        onClick={this.handlePrint}
                        bsStyle="primary"
                        id="printBtn"
                      >
                        <AddGlyph glyph="print" />
                        Print Customers
                      </ToolButton>
                    </div>
                  </Header>
                  <div style={{ width: "100%" }}>
                    <label className="mb-2">
                      Number of Commercial Customers:{" "}
                      {monthlyCommCustomers.length}
                    </label>
                  </div>
                  <Table id="print" hover bordered>
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer</th>
                        <th>Address</th>
                        <th>Email Address</th>
                        <th>Phone Number(s)</th>
                        <th>Balance Due</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(monthlyCommCustomers, (customer) => {
                        return (
                          <tr key={customer.uid}>
                            <td
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <div>{customer["Account Number"]}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <div>{customer.name}</div>
                            </td>
                            <td>
                              <div>{billingAddress(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <div>{customer.email}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <div>
                                {_.map(
                                  customer.phone_numbers,
                                  (phone_number, index) => {
                                    if (
                                      !phone_number.type &&
                                      phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${phone_number.number}`}
                                        </li>
                                      );
                                    } else if (
                                      !phone_number.type &&
                                      !phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${customer.phone_numbers[index]}`}
                                        </li>
                                      );
                                    } else {
                                      return (
                                        <li key={index}>
                                          {`${phone_number.type}: ${phone_number.number}`}
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <div>{billingAmountDue(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <div>{customer.status}</div>
                            </td>
                            <td>
                              <ActionButton
                                onClick={() =>
                                  this.printEInvoiceMonth(customer, "comm")
                                }
                                bsStyle="primary"
                              >
                                Send E-Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.printForMonth(customer, "comm")
                                }
                                bsStyle="primary"
                              >
                                Print Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.handleSuspendAccount(customer, "comm")
                                }
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                              >
                                Suspend
                              </ActionButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>Residential Customers (Quarterly)</label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Header>
                    <div style={{ width: "100%" }}>
                      <ToolButton
                        onClick={() => this.printForQuarterlyEinvoice("res")} //changed from eInvoicesForQuarterlyWithEInvoice
                        bsStyle="primary" //to be able to access the modal before sending
                      >
                        Send E-Invoices
                      </ToolButton>
                      <ToolButton
                        onClick={() => this.printForQuarterly("res")}
                        bsStyle="primary"
                      >
                        Print All Invoices
                      </ToolButton>
                    </div>
                  </Header>
                  <div style={{ width: "100%" }}>
                    <label className="mb-2">
                      Number of Residential Customers:{" "}
                      {quarterlyResCustomers.length}
                    </label>
                  </div>
                  <Table id="Rprint" hover bordered>
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer</th>
                        <th>Address</th>
                        <th>Pick Up Day</th>
                        <th>Phone Number(s)</th>
                        <th>Balance Due</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(quarterlyResCustomers, (customer) => {
                        return (
                          <tr key={customer.uid}>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer["Account Number"]}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.name}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{serviceAddress(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.pickup_day}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>
                                {_.map(
                                  customer.phone_numbers,
                                  (phone_number, index) => {
                                    if (
                                      !phone_number.type &&
                                      phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${phone_number.number}`}
                                        </li>
                                      );
                                    } else if (
                                      !phone_number.type &&
                                      !phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${customer.phone_numbers[index]}`}
                                        </li>
                                      );
                                    } else {
                                      return (
                                        <li key={index}>
                                          {`${phone_number.type}: ${phone_number.number}`}
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{billingAmountDue(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.status}</div>
                            </td>
                            <td>
                              <ActionButton
                                onClick={() =>
                                  this.printEInvoiceMonth(customer, "res")
                                }
                                bsStyle="primary"
                              >
                                Send E-Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.printForMonth(customer, "res")
                                }
                                bsStyle="primary"
                              >
                                Print Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.handleSuspendAccount(customer, "res")
                                }
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                              >
                                Suspend
                              </ActionButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>Residential Customers (Monthly) </label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Header>
                    <div style={{ width: "100%" }}>
                      <ToolButton
                        onClick={this.RhandlePrint}
                        bsStyle="primary"
                        id="printBtn"
                      >
                        <AddGlyph glyph="print" />
                        Print Customers
                      </ToolButton>
                    </div>
                  </Header>
                  <div style={{ width: "100%" }}>
                    <label className="mb-2">
                      Number of Residential Customers:{" "}
                      {monthlyResustomers.length}
                    </label>
                  </div>
                  <Table id="Rprint" hover bordered>
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer</th>
                        <th>Address</th>
                        <th>Pick Up Day</th>
                        <th>Phone Number(s)</th>
                        <th>Balance Due</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(monthlyResustomers, (customer) => {
                        return (
                          <tr key={customer.uid}>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer["Account Number"]}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.name}</div>
                            </td>
                            <td>
                              <div>{serviceAddress(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.pickup_day}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>
                                {_.map(
                                  customer.phone_numbers,
                                  (phone_number, index) => {
                                    if (
                                      !phone_number.type &&
                                      phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${phone_number.number}`}
                                        </li>
                                      );
                                    } else if (
                                      !phone_number.type &&
                                      !phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${customer.phone_numbers[index]}`}
                                        </li>
                                      );
                                    } else {
                                      return (
                                        <li key={index}>
                                          {`${phone_number.type}: ${phone_number.number}`}
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{billingAmountDue(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.status}</div>
                            </td>
                            <td>
                              <ActionButton
                                onClick={() =>
                                  this.printEInvoiceMonth(customer, "res")
                                }
                                bsStyle="primary"
                              >
                                Send E-Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.printForMonth(customer, "res")
                                }
                                bsStyle="primary"
                              >
                                Print Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.handleSuspendAccount(customer, "res")
                                }
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                              >
                                Suspend
                              </ActionButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>West Mayfield Customers (Quarterly)</label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Header>
                    <div style={{ width: "100%" }}>
                      <ToolButton
                        onClick={() => this.printForQuarterlyEinvoice("bwm")}
                        bsStyle="primary"
                      >
                        Send E-Invoices
                      </ToolButton>
                      <ToolButton
                        onClick={() => this.printForQuarterly("bwm")}
                        bsStyle="primary"
                      >
                        Print All Invoices
                      </ToolButton>
                    </div>
                  </Header>
                  <div style={{ width: "100%" }}>
                    <label className="mb-2">
                      Number of West Mayfield Customers:{" "}
                      {quarterlyBWMCustomers.length}
                    </label>
                  </div>
                  <Table id="Rprint" hover bordered>
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer</th>
                        <th>Address</th>
                        <th>Pick Up Day</th>
                        <th>Phone Number(s)</th>
                        <th>Balance Due</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(quarterlyBWMCustomers, (customer) => {
                        return (
                          <tr key={customer.uid}>
                            <td
                              onClick={() =>
                                this.props.WsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer["Account Number"]}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.WsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.name}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.WsetCustomer(customer.uid)
                              }
                            >
                              <div
                                onClick={() =>
                                  this.props.WsetCustomer(customer.uid)
                                }
                              >
                                {serviceAddress(customer)}
                              </div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.WsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.pickup_day}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.WsetCustomer(customer.uid)
                              }
                            >
                              <div>
                                {_.map(
                                  customer.phone_numbers,
                                  (phone_number, index) => {
                                    if (
                                      !phone_number.type &&
                                      phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${phone_number.number}`}
                                        </li>
                                      );
                                    } else if (
                                      !phone_number.type &&
                                      !phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${customer.phone_numbers[index]}`}
                                        </li>
                                      );
                                    } else {
                                      return (
                                        <li key={index}>
                                          {`${phone_number.type}: ${phone_number.number}`}
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.WsetCustomer(customer.uid)
                              }
                            >
                              <div>{billingAmountDue(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.WsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.status}</div>
                            </td>
                            <td>
                              <ActionButton
                                onClick={() =>
                                  this.printEInvoiceMonth(customer, "bwm")
                                }
                                bsStyle="primary"
                              >
                                Send E-Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.printForMonth(customer, "bwm")
                                }
                                bsStyle="primary"
                              >
                                Print Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.handleSuspendAccount(customer, "bwm")
                                }
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                              >
                                Suspend
                              </ActionButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>West Mayfield Customers (Monthly) </label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Header>
                    <div style={{ width: "100%" }}>
                      <ToolButton
                        onClick={this.RhandlePrint}
                        bsStyle="primary"
                        id="printBtn"
                      >
                        <AddGlyph glyph="print" />
                        Print Customers
                      </ToolButton>
                    </div>
                  </Header>
                  <div style={{ width: "100%" }}>
                    <label className="mb-2">
                      Number of West Mayfield Customers:{" "}
                      {monthlyBWMcustomers.length}
                    </label>
                  </div>
                  <Table id="Rprint" hover bordered>
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer</th>
                        <th>Address</th>
                        <th>Pick Up Day</th>
                        <th>Phone Number(s)</th>
                        <th>Balance Due</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(monthlyBWMcustomers, (customer) => {
                        return (
                          <tr key={customer.uid}>
                            <td
                              onClick={() =>
                                this.props.WsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer["Account Number"]}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.WsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.name}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.WsetCustomer(customer.uid)
                              }
                            >
                              <div>{serviceAddress(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.WsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.pickup_day}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.WsetCustomer(customer.uid)
                              }
                            >
                              <div>
                                {_.map(
                                  customer.phone_numbers,
                                  (phone_number, index) => {
                                    if (
                                      !phone_number.type &&
                                      phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${phone_number.number}`}
                                        </li>
                                      );
                                    } else if (
                                      !phone_number.type &&
                                      !phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${customer.phone_numbers[index]}`}
                                        </li>
                                      );
                                    } else {
                                      return (
                                        <li key={index}>
                                          {`${phone_number.type}: ${phone_number.number}`}
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{billingAmountDue(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.WsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.status}</div>
                            </td>
                            <td>
                              <ActionButton
                                onClick={() =>
                                  this.printEInvoiceMonth(customer, "bwm")
                                }
                                bsStyle="primary"
                              >
                                Send E-Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.printForMonth(customer, "bwm")
                                }
                                bsStyle="primary"
                              >
                                Print Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.handleSuspendAccount(customer, "bwm")
                                }
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                              >
                                Suspend
                              </ActionButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>New Castle Customers 2.0 (Quarterly)</label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Header>
                    <div style={{ width: "100%" }}>
                      <ToolButton
                        onClick={() => this.printForQuarterlyEinvoice("nc2")}
                        bsStyle="primary"
                      >
                        Send E-Invoices
                      </ToolButton>
                      <ToolButton
                        onClick={() => this.printForQuarterly("nc2")}
                        bsStyle="primary"
                      >
                        Print All Invoices
                      </ToolButton>
                    </div>
                  </Header>
                  <div style={{ width: "100%" }}>
                    <label className="mb-2">
                      Number of New Castle Customers:{" "}
                      {quarterlyNCCustomers2.length}
                    </label>
                  </div>
                  <Table id="Rprint" hover bordered>
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer</th>
                        <th>Address</th>
                        <th>Pick Up Day</th>
                        <th>Phone Number(s)</th>
                        <th>Balance Due</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(quarterlyNCCustomers2, (customer) => {
                        return (
                          <tr key={customer.uid}>
                            <td
                              onClick={() =>
                                this.props.NCsetCustomer2(customer.uid)
                              }
                            >
                              <div>{customer["Account Number"]}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.NCsetCustomer2(customer.uid)
                              }
                            >
                              <div>{customer.name}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.NCsetCustomer2(customer.uid)
                              }
                            >
                              <div>{serviceAddress(customer)}</div>
                            </td>
                            <td>
                              <div>{customer.pickup_day}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.NCsetCustomer2(customer.uid)
                              }
                            >
                              <div>
                                {_.map(
                                  customer.phone_numbers,
                                  (phone_number, index) => {
                                    if (
                                      !phone_number.type &&
                                      phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${phone_number.number}`}
                                        </li>
                                      );
                                    } else if (
                                      !phone_number.type &&
                                      !phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${customer.phone_numbers[index]}`}
                                        </li>
                                      );
                                    } else {
                                      return (
                                        <li key={index}>
                                          {`${phone_number.type}: ${phone_number.number}`}
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.NCsetCustomer2(customer.uid)
                              }
                            >
                              <div>{billingAmountDue(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.NCsetCustomer2(customer.uid)
                              }
                            >
                              <div>{customer.status}</div>
                            </td>
                            <td>
                              <ActionButton
                                onClick={() =>
                                  this.printEInvoiceMonth(customer, "nc2")
                                }
                                bsStyle="primary"
                              >
                                Send E-Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.printForMonth(customer, "nc2")
                                }
                                bsStyle="primary"
                              >
                                Print Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.handleSuspendAccount(customer, "nc2")
                                }
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                              >
                                Suspend
                              </ActionButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>New Castle Customers 2.0 (Monthly) </label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Header>
                    <div style={{ width: "100%" }}>
                      <ToolButton
                        onClick={this.RhandlePrint}
                        bsStyle="primary"
                        id="printBtn"
                      >
                        <AddGlyph glyph="print" />
                        Print Customers
                      </ToolButton>
                    </div>
                  </Header>
                  <div style={{ width: "100%" }}>
                    <label className="mb-2">
                      Number of New Castle 2.0 Customers:{" "}
                      {monthlyNCcustomers2.length}
                    </label>
                  </div>
                  <Table id="Rprint" hover bordered>
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer</th>
                        <th>Address</th>
                        <th>Pick Up Day</th>
                        <th>Phone Number(s)</th>
                        <th>Balance Due</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(monthlyNCcustomers2, (customer) => {
                        return (
                          <tr key={customer.uid}>
                            <td
                              onClick={() =>
                                this.props.NCsetCustomer2(customer.uid)
                              }
                            >
                              <div>{customer["Account Number"]}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.NCsetCustomer2(customer.uid)
                              }
                            >
                              <div>{customer.name}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.NCsetCustomer2(customer.uid)
                              }
                            >
                              <div>{billingAddress(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.NCsetCustomer2(customer.uid)
                              }
                            >
                              <div>{customer.pickup_day}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.NCsetCustomer2(customer.uid)
                              }
                            >
                              <div>
                                {_.map(
                                  customer.phone_numbers,
                                  (phone_number, index) => {
                                    if (
                                      !phone_number.type &&
                                      phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${phone_number.number}`}
                                        </li>
                                      );
                                    } else if (
                                      !phone_number.type &&
                                      !phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${customer.phone_numbers[index]}`}
                                        </li>
                                      );
                                    } else {
                                      return (
                                        <li key={index}>
                                          {`${phone_number.type}: ${phone_number.number}`}
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{billingAmountDue(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.NCsetCustomer2(customer.uid)
                              }
                            >
                              <div>{customer.status}</div>
                            </td>
                            <td>
                              <ActionButton
                                onClick={() =>
                                  this.printEInvoiceMonth(customer, "nc2")
                                }
                                bsStyle="primary"
                              >
                                Send E-Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.printForMonth(customer, "nc2")
                                }
                                bsStyle="primary"
                              >
                                Print Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.handleSuspendAccount(customer, "nc2")
                                }
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                              >
                                Suspend
                              </ActionButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>

          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>East Butler (Quarterly) </label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Header>
                    <div style={{ width: "100%" }}>
                      <ToolButton
                        onClick={this.RhandlePrint}
                        bsStyle="primary"
                        id="printBtn"
                      >
                        <AddGlyph glyph="print" />
                        Print Customers
                      </ToolButton>
                      <ToolButton
                        onClick={() => this.printForQuarterlyEinvoice("eb")}
                        bsStyle="primary"
                      >
                        Send E-Invoices
                      </ToolButton>
                      <ToolButton
                        onClick={() => this.printForQuarterly("eb")}
                        bsStyle="primary"
                      >
                        Print All Invoices
                      </ToolButton>
                    </div>
                  </Header>
                  <div style={{ width: "100%" }}>
                    <label className="mb-2">
                      Number of East Butler Customers:{" "}
                      {monthlyEastButler.length}
                    </label>
                  </div>
                  <Table id="Rprint" hover bordered>
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer</th>
                        <th>Address</th>
                        <th>Pick Up Day</th>
                        <th>Phone Number(s)</th>
                        <th>Balance Due</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(monthlyEastButler, (customer) => {
                        return (
                          <tr key={customer.uid}>
                            <td
                              onClick={() =>
                                this.props.EBsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer["Account Number"]}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.EBsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.name}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.EBsetCustomer(customer.uid)
                              }
                            >
                              <div>{serviceAddress(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.EBsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.pickup_day}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.EBsetCustomer(customer.uid)
                              }
                            >
                              <div>
                                {_.map(
                                  customer.phone_numbers,
                                  (phone_number, index) => {
                                    if (
                                      !phone_number.type &&
                                      phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${phone_number.number}`}
                                        </li>
                                      );
                                    } else if (
                                      !phone_number.type &&
                                      !phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${customer.phone_numbers[index]}`}
                                        </li>
                                      );
                                    } else {
                                      return (
                                        <li key={index}>
                                          {`${phone_number.type}: ${phone_number.number}`}
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{billingAmountDue(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.EBsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.status}</div>
                            </td>
                            <td>
                              <ActionButton
                                onClick={() =>
                                  this.printEInvoiceMonth(customer, "nc2")
                                }
                                bsStyle="primary"
                              >
                                Send E-Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.printForMonth(customer, "nc2")
                                }
                                bsStyle="primary"
                              >
                                Print Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.handleSuspendAccount(customer, "EB")
                                }
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                              >
                                Suspend
                              </ActionButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>

          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>Slippery Rock (Quarterly) </label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Header>
                    <div style={{ width: "100%" }}>
                      <ToolButton
                        onClick={this.RhandlePrint}
                        bsStyle="primary"
                        id="printBtn"
                      >
                        <AddGlyph glyph="print" />
                        Print Customers
                      </ToolButton>
                      <ToolButton
                        onClick={() => this.printForQuarterlyEinvoice("srb")}
                        bsStyle="primary"
                      >
                        Send E-Invoices
                      </ToolButton>
                      <ToolButton
                        onClick={() => this.printForQuarterly("srb")}
                        bsStyle="primary"
                      >
                        Print All Invoices
                      </ToolButton>
                    </div>
                  </Header>
                  <div style={{ width: "100%" }}>
                    <label className="mb-2">
                      Number of Slippery Rock Customers:{" "}
                      {monthlySlipperyRock.length}
                    </label>
                  </div>
                  <Table id="Rprint" hover bordered>
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer</th>
                        <th>Address</th>
                        <th>Pick Up Day</th>
                        <th>Phone Number(s)</th>
                        <th>Balance Due</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(monthlySlipperyRock, (customer) => {
                        return (
                          <tr key={customer.uid}>
                            <td
                              onClick={() =>
                                this.props.SRsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer["Account Number"]}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.SRsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.name}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.SRsetCustomer(customer.uid)
                              }
                            >
                              <div>{serviceAddress(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.SRsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.pickup_day}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.SRsetCustomer(customer.uid)
                              }
                            >
                              <div>
                                {_.map(
                                  customer.phone_numbers,
                                  (phone_number, index) => {
                                    if (
                                      !phone_number.type &&
                                      phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${phone_number.number}`}
                                        </li>
                                      );
                                    } else if (
                                      !phone_number.type &&
                                      !phone_number.number
                                    ) {
                                      return (
                                        <li key={index}>
                                          {`Phone: ${customer.phone_numbers[index]}`}
                                        </li>
                                      );
                                    } else {
                                      return (
                                        <li key={index}>
                                          {`${phone_number.type}: ${phone_number.number}`}
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.RsetCustomer(customer.uid)
                              }
                            >
                              <div>{billingAmountDue(customer)}</div>
                            </td>
                            <td
                              onClick={() =>
                                this.props.SRsetCustomer(customer.uid)
                              }
                            >
                              <div>{customer.status}</div>
                            </td>
                            <td>
                              <ActionButton
                                onClick={() =>
                                  this.printEInvoiceMonth(customer, "nc2")
                                }
                                bsStyle="primary"
                              >
                                Send E-Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.printForMonth(customer, "nc2")
                                }
                                bsStyle="primary"
                              >
                                Print Invoice
                              </ActionButton>
                              <ActionButton
                                onClick={() =>
                                  this.handleSuspendAccount(customer, "SR")
                                }
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                              >
                                Suspend
                              </ActionButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          {/* Print For Quarterly */}
          <Modal show={this.state.print_quarterly_modal_show}>
            <Modal.Body>
              {/* <h3>Choose a Service Quarter</h3>
              <Select
                options={this.state.serviceQuarterOptions}
                value={this.state.select_quarter}
                onChange={this.quarterChanged}
                getOptionLabel={(option) => option.label} 
                getOptionValue={(option) => option.value}
              /> */}
            {this.state.exporting ? (
            <>
              <h3>Downloading...</h3>
              <p>This may take some time.</p>
              <PrintProgress active striped now={this.state.printProgress} label={`${this.state.printProgress}%`} />
            </>
            ) : (
              <>
              <div className="form-group" style={{ marginTop: 10 }}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker
                  selected={this.state.dueDate}
                  onChange={(date) => {
                    this.setState({ dueDate: date });
                  }}
                  required
                />
              </div>
              </>
            )}
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.modal_quarter.length}
                bsStyle="primary"
                onClick={() =>
                  this.handleQuarterlyPrint(this.state.selectedLocationValue)
                } //"comm" needs to be changed to accept values of res, nc2, bwm
                id="printBtn"
              >
                Print All Invoices
              </ToolButton>
            </Modal.Footer>
          </Modal>
          {/* Print For Quarterly E-Invoice */}
          <Modal show={this.state.print_quarterly_einvoice_modal_show}>
            <Modal.Body>
              {/* <h3>Choose a Service Quarter</h3>
              <Select
                options={this.state.serviceQuarterOptions}
                value={this.state.select_quarter}
                onChange={this.quarterChanged}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              /> */}
              <div className="form-group" style={{ marginTop: 10 }}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker
                  selected={this.state.dueDate}
                  onChange={(date) => {
                    this.setState({ dueDate: date });
                  }}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.modal_quarter.length}
                bsStyle="primary"
                onClick={() =>
                  this.eInvoicesForQuarterlyWithEInvoice(
                    this.state.selectedLocationValue
                  )
                }
                id="printBtn"
              >
                Send E-Invoices
              </ToolButton>
            </Modal.Footer>
          </Modal>

          {/* Suspend Modal */}
          <Modal
            show={this.state.show_suspended_modal}
            // onHide={this.handleCloseSuspendModal}
          >
            <Modal.Body>
              <StyledRow>
                <div>Reason For Suspension</div>
                <StyledSelect
                  onChange={this.setTypeOfReason}
                  value={this.state.type_of_reason}
                >
                  <option value={false}>Select Reason</option>

                  {ReasonOptions.map((reasonType) => (
                    <option key={reasonType.value} value={reasonType.value}>
                      {reasonType.label}
                    </option>
                  ))}
                </StyledSelect>
              </StyledRow>
              <StyledRow>
                <div>
                  Please enter a note as to why account is being suspended
                </div>
                <FormControl
                  type="text"
                  componentClass="textarea"
                  rows="3"
                  onChange={this.handleNoteChanged}
                  value={this.state.note}
                />
              </StyledRow>
            </Modal.Body>
            <Modal.Footer>
              <Button
                bsStyle="primary"
                onClick={() =>
                  this.handleSaveSuspended(
                    this.state.selected_month_customer,
                    this.state.selectedLocationValue
                  )
                }
              >
                Save
              </Button>
              <Button onClick={this.handleCancelSuspend}>Cancel</Button>
            </Modal.Footer>
          </Modal>
          {/* Progressing Modal For Quarter*/}
          <Modal show={this.state.quarter_progressing_show}>
            <Modal.Body>
              <>
                <h3>Sending Email...</h3>
                <p>This may take some time.</p>
              </>
              <PrintProgress
                active
                striped
                now={this.state.quarter_progress}
                label={`${this.state.quarter_progress}%`}
              />
              {this.state.quarter_progress === 100 && (
                <div className="mt-2">
                  <label className="mb-2">
                    Number Of E-Invoices Customers:{" "}
                    {this.state.eInvoicesForQuarterlyTotal}
                  </label>
                  <Table responsive hover bordered id="print">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Account Number</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.eInvoicesForQuarterlyResults.map(
                        (item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.customer["Account Number"]}</td>
                              <td>
                                {item.success === 1 ? "Success" : "Failed"}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                  <CloseMoadlButtonContainer>
                    <Button
                      onClick={this.closeQuarterlyEmailModal}
                      bsStyle="primary"
                    >
                      Close
                    </Button>
                  </CloseMoadlButtonContainer>
                </div>
              )}
            </Modal.Body>
          </Modal>
          {/* Print For Month E-Invoice*/}
          <Modal show={this.state.print_month_einvoice_modal_show}>
            <Modal.Body>
              {/* <h3>Choose a Service Month</h3>
              <Select
                options={this.state.monthOptions}
                value={this.state.month_select_quarter}
                onChange={this.monthChanged}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              /> */}
              <div className="form-group" style={{ marginTop: 10 }}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker
                  selected={this.state.dueDate}
                  onChange={(date) => {
                    this.setState({ dueDate: date });
                  }}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.month_modal_quarter.length}
                bsStyle="primary"
                onClick={() =>
                  this.handleEInvoiceForMonth(
                    this.state.selected_month_customer,
                    this.state.selectedLocationValue
                  )
                }
                id="printBtn"
              >
                Send E-Invoice
              </ToolButton>
            </Modal.Footer>
          </Modal>
          {/* Print For Month */}
          <Modal show={this.state.print_month_modal_show}>
            <Modal.Body>
              {/* <h3>Choose a Service Month</h3>
              <Select
                options={this.state.monthOptions}
                value={this.state.month_select_quarter}
                onChange={this.monthChanged}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              /> */}
              <div className="form-group" style={{ marginTop: 10 }}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker
                  selected={this.state.dueDate}
                  onChange={(date) => {
                    this.setState({ dueDate: date });
                  }}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.month_modal_quarter.length}
                bsStyle="primary"
                onClick={() =>
                  this.handlePrintMonth(
                    this.state.selected_month_customer,
                    this.state.selectedLocationValue
                  )
                }
                id="printBtn"
              >
                Print
              </ToolButton>
            </Modal.Footer>
          </Modal>
        </CustomersWrapper>
      );
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const billingAddress = (customer) => {
  //console.log(customer);
  return `${customer.billing_address?.street}, 
  ${customer.billing_address?.city}, 
  ${customer.billing_address?.state}, 
  ${customer.billing_address?.zipcode}`;
};
const serviceAddress = (customer) => {
  //console.log(customer);
  return `${customer.service_address?.street}, 
  ${customer.service_address?.city}, 
  ${customer.service_address?.state}, 
  ${customer.service_address?.zipcode}`;
};
const billingAmountDue = (customer) => {
  //converts amount to two decimals to the right
  var number = !isNaN(parseFloat(customer.amount_due))
    ? parseFloat(customer.amount_due)
    : 0.0;
  return `$${number.toFixed(2)}`;
};

const mapStateToProps = (state) => ({
  pastDueCustomers: state.pastDueCustomers,
  authorized: state.user?.pages?.customers,
  Rcustomers: state.residential.customers,
  Wcustomers: state.westmayfield.customers,
  NCcustomers2: state.newcastle.customers,
  NCcustomer2: state.newcastle.customer,
  EBcustomers: state.eastbutler.customers,
  EBcustomer: state.eastbutler.customer,
  SRcustomers: state.slipperyrock.customers,
  SRcustomer: state.slipperyrock.customer,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomer: (uid) =>
    dispatch(CustomersActions.setCustomer(uid, "/Reports/due_accounts")),
  RsetCustomer: (uid) =>
    dispatch(
      ResidentialCustomersActions.setCustomer(uid, "/Reports/due_accounts")
    ),
  WsetCustomer: (uid) =>
    dispatch(
      WestMayfieldCustomersActions.setCustomer(uid, "/Reports/due_accounts")
    ),
  NCsetCustomer2: (uid) =>
    dispatch(
      NewCastleCustomers2Actions.setCustomer(uid, "/Reports/due_accounts")
    ),
  EBsetCustomer: (uid) =>
    dispatch(
      EastButlerCustomersActions.setCustomer(uid, "/Reports/due_accounts")
    ),
  SRsetCustomer: (uid) =>
    dispatch(
      SlipperyRockCustomersActions.setCustomer(uid, "/Reports/due_accounts")
    ),
  getPastDueCustomers: () => dispatch(CustomersActions.getPastDueCustomers()),
  getRCustomers: () => dispatch(ResidentialCustomersActions.getCustomers()),
  getWCustomers: () => dispatch(WestMayfieldCustomersActions.getCustomers()),
  getNCCustomers2: () => dispatch(NewCastleCustomers2Actions.getCustomers()),
  getEBCustomers: () => dispatch(EastButlerCustomersActions.getCustomers()),
  getSRCustomers: () => dispatch(SlipperyRockCustomersActions.getCustomers()),
  commSuspend: (customer, note, type_of_reason) =>
    dispatch(CustomersActions.suspendCustomer(customer, note, type_of_reason)),
  residentialSuspend: (customer, note, type_of_reason) =>
    dispatch(ResidentialCustomersActions.suspendCustomer(customer, note, type_of_reason)),
  newCastle2Suspend: (customer, note, type_of_reason) =>
    dispatch(NewCastleCustomers2Actions.suspendCustomer(customer, note, type_of_reason)),
  westMayfieldSuspend: (customer, note, type_of_reason) =>
    dispatch(WestMayfieldCustomersActions.suspendCustomer(customer, note, type_of_reason)),
  eastButlerSuspend: (customer, note, type_of_reason) =>
    dispatch(EastButlerCustomersActions.suspendCustomer(customer, note, type_of_reason)),
  slipperyRockSuspend: (customer, note, type_of_reason) =>
    dispatch(SlipperyRockCustomersActions.suspendCustomer(customer, note, type_of_reason)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DueAccounts);
