/* @format - for Prettier */
import React from "react";
import styled from "styled-components";
import CreditCardInput from "react-credit-card-input";
import { Field, FieldArray } from "redux-form";
import { Grid, Row, Col, Button, Glyphicon } from "react-bootstrap";
import {
  renderField,
  renderState,
  renderRate,
  renderNotes,
  renderPhoneNumber,
  normalizePhone,
  required,
  renderExemptField,
  renderWeekField,
  renderBillFrequencyField,
  email,
  renderTextAreaField
} from "../../../renderComponents";
import { ErrorText } from "../../../styled";

const StyledGrid = styled(Grid)`
  padding: 0px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 7px !important;
`;

const StyledCol = styled(Col)`
  padding: 0px;
`;

const GlyphButton = styled(Button)`
  width: 40px;
  color: red !important;
  border: none;
`;

const AddButton = styled(Button)`
  width: 130px;
`;

const Checkbox = styled.div`
  display: inline-block;
  margin-left: 10px;
`;

const StyledSelect = styled.select`
  width: 30%;
  height: 26px;
  margin-left: 10px;
`;

const StyledInput = styled.input`
  width: 200px;
`;

const renderSmall = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div>
      <StyledInput {...input} type={type} placeholder="" />
      {touched && error && <ErrorText>{error}</ErrorText>}
    </div>
  </div>
);
const renderSelect = ({ input, type, meta: { touched, error }, children }) => (
  <span>
    <StyledSelect {...input}>{children}</StyledSelect>
    {touched && error && <ErrorText>{error}</ErrorText>}
  </span>
);



const renderPhoneNumbers = ({ fields, meta: { error } }) => (
  <StyledRow>
    {fields.map((phone_number, index) => (
      <StyledRow key={index}>
        <Field
          name={`${
            fields.get(index) && fields.get(index).number ? `${phone_number}.number` : `${phone_number}.number`
          }`}
          component={renderPhoneNumber}
          type="text"
          normalize={normalizePhone}
          validate={[required]}
        />
        <Field name={`${phone_number}.type`} component={renderSelect}>
          <option value="Business">Business</option>
          <option value="Mobile">Mobile</option>
          <option value="Home">Home</option>
          <option value="Fax">Fax</option>
        </Field>
        <GlyphButton type="button" onClick={() => fields.remove(index)}>
          <Glyphicon glyph="remove" />
        </GlyphButton>
      </StyledRow>
    ))}
    <AddButton onClick={() => fields.push('')} bsStyle="primary">
      + Add Number
    </AddButton>
  </StyledRow>
);

const CustomerForm = ({
  checked,
  credit_card,
  handleCard,
  meta: { error, submitFailed }
}) => (
  <div className="customer_information_edit">
    <StyledGrid>
      <StyledRow>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Customer Information:</h4>
          </StyledRow>
          <StyledRow>
            <Field
              name="name"
              label="Name:"
              type="text"
              component={renderField}
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="Account Number"
              label="Account Number:"
              type="text"
              component={renderField}
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="recurringCustomer"
              label="Recurring Customer:"
              type="text"
              component={renderExemptField}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="isExempt"
              label="Is Exempt:"
              type="text"
              component={renderExemptField}
            />
          </StyledRow>
           
          <StyledRow>
            <Field
              name="isInactive"
              label="Is Inactive:"
              type="text"
              component={renderExemptField}
            />
          </StyledRow>

          <StyledRow>
            <Field
              name="billFrequency"
              label="Bill Frequency:"
              type="text"
              component={renderBillFrequencyField}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="wantsEInvoice"
              label="Wants E-Invoice?"
              type="text"
              component={renderExemptField}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="recycling_customer"
              label="Recycling Customer?"
              type="text"
              component={renderExemptField}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="rate"
              label="Rate:"
              type="text"
              component={renderRate}
              validate={[required]}
            />
          </StyledRow>
        </StyledCol>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Contact Information: </h4>
          </StyledRow>
          <StyledRow>
            <Field
              name="contact_name"
              label="Name:"
              type="text"
              component={renderField}
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="email"
              label="Email:"
              type="text"
              component={renderField}
              validate={[email]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="companyName"
              label="Company Name:"
              type="text"
              component={renderTextAreaField}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name="toterSerialNumbers"
              label="Toter Serial Numbers:"
              type="text"
              component={renderTextAreaField}
            />
          </StyledRow>
        </StyledCol>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Phone Numbers:</h4>
          </StyledRow>
          <StyledRow>
            <FieldArray name="phone_numbers" component={renderPhoneNumbers} />
          </StyledRow>
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Service Address:</h4>
          </StyledRow>
          <StyledRow>
            <Field
              name={`service_address.street`}
              type="text"
              component={renderField}
              label="Street:"
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <Field
              name={`service_address.city`}
              type="text"
              component={renderField}
              label="City:"
              className="large"
              validate={[required]}
            />
          </StyledRow>
          <StyledRow>
            <StyledCol sm={4} md={4}>
              <Field
                name={`service_address.state`}
                type="text"
                component={renderState}
                label="State:"
                validate={[required]}
                className="State"
              />
            </StyledCol>
            <StyledCol sm={5} md={5}>
              <Field
                name={`service_address.zipcode`}
                type="text"
                component={renderField}
                label="Zipcode:"
                validate={[required]}
                className="Zipcode"
              />
            </StyledCol>
          </StyledRow>
        </StyledCol>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Billing Address:</h4>
          </StyledRow>
          <StyledRow>
            <Field
              name={`billing_address.street`}
              type="text"
              component={renderField}
              label="Street:"
            />
          </StyledRow>
          <StyledRow>
            <Field
              name={`billing_address.city`}
              type="text"
              component={renderField}
              label="City:"
              className="large"
            />
          </StyledRow>
          <StyledRow>
            <StyledCol sm={4} md={4}>
              <Field
                name={`billing_address.state`}
                type="text"
                component={renderState}
                label="State:"
                className="State"
              />
            </StyledCol>
            <StyledCol sm={5} md={5}>
              <Field
                name={`billing_address.zipcode`}
                type="text"
                component={renderField}
                label="Zipcode:"
                className="Zipcode"
              />
            </StyledCol>
          </StyledRow>
          <StyledRow className={checked}>
            <label>Same as Service Address</label>
            <Checkbox>
              <Field name="same" component="input" type="checkbox" />
            </Checkbox>
          </StyledRow>
        </StyledCol>
        <StyledCol sm={4} md={4}>
          <StyledRow>
            <h4>Pick-Up Info:</h4>
          </StyledRow>
          <StyledRow>
            <Field
              name={`pickup_day`}
              label="Pickup Day:"
              type="text"
              component={renderWeekField}
            />
          </StyledRow>
        </StyledCol>
      </StyledRow>
    </StyledGrid> 
  </div>
);

export default CustomerForm;
