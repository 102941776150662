/* @format - for Prettier */
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Stepper from 'react-js-stepper'
import toastr from 'toastr';
import {connect} from 'react-redux';
import {Table, Button, Glyphicon, Modal} from 'react-bootstrap';
import {Routes as RoutesActions} from '../../actions2';
import {reduxForm, FieldArray, formValueSelector, change, reset} from 'redux-form';
import { ResidentialCustomers as ResidentialCustomersActions } from "../../actions2";
import { GreenBagCustomers as GreenBagCustomersActions } from "../../actions2";
import { WestMayfieldCustomers as WestMayfieldCustomersActions } from "../../actions2";
import { NewCastleCustomers2 as NewCastleCustomers2Actions} from "../../actions2";
import { EastButlerCustomers as EastButlerCustomerActions} from "../../actions2";
import { SlipperyRockCustomers as SlipperyRockCustomerActions} from "../../actions2";
import {DeniedWrapper} from '../../styled';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  RouteList,
  StopForm
} from '../Route/components';

const RoutesWrapper = styled.div`
  margin-top: 2vh;
  width: 90%;
  //max-width: 600px;
  margin: 0 auto;
`;

const StyledForm = styled.form`
  margin: 10px;
`;

const GlyphButton = styled(Button)`
  width: 100%;
  border: none
`;

const GlyphTd = styled.td`
  width: 50px;
`;

const GlyphTh = styled.th`
  width: 50px;
`;

const DeleteButton = styled(Button)`
  float: right;
`;

const CloseButton = styled(Button)`
  float: left;
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const steps = [{ title: "Step - 1" }, { title: "Step - 2" }];

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      mode: "view",
      showAlert: false,
      showStop: false,
      disableStop: false,
      activeStep: 1,
      route: null,
      editingUID: "new",
      selectedRoutes: [],
      // testing
      // priority: null
      routes: this.props.routes,
    };
    this.addStop = this.addStop.bind(this);
    this.editRoutes = this.editRoutes.bind(this);
    this.cancelEditRoutes = this.cancelEditRoutes.bind(this);
    this.handleOnClickFinish = this.handleOnClickFinish.bind(this);
    this.handleCloseStop = this.handleCloseStop.bind(this);
    this.handleOnClickStepper = this.handleOnClickStepper.bind(this);
    this.handleOnClickBack = this.handleOnClickBack.bind(this);
    this.handleOnClickNext = this.handleOnClickNext.bind(this);
    this.onSelectRoutes = this.onSelectRoutes.bind(this);
    this.changeFullAddress = this.changeFullAddress.bind(this);
    this.renderErrModal = this.renderErrModal.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.fixRouteStops = this.fixRouteStops.bind(this);
    this.fixDuplicateStops = this.fixDuplicateStops.bind(this);
  }

  handleCloseAlert = () => {
    this.setState({ showAlert: false });
  };

  renderErrModal = () => (
    <Modal
      bsSize="small"
      show={this.state.showAlert}
      onHide={this.handleCloseAlert}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span>No item hasn't chosen</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{this.state.errMsg}</div>
      </Modal.Body>
      <Modal.Footer>
        <CloseButton onClick={this.handleCloseAlert}>Close</CloseButton>
      </Modal.Footer>
    </Modal>
  );

  handleOnClickFinish() {
    if (!this.state.selectedRoutes.length) {
      this.setState({
        showAlert: true,
        errMsg: "You should select at least 1 route",
      });
    } else {
      this.props.saveStopsToRoutes(
        this.props.formValues.values.stops,
        this.state.selectedRoutes
      );
      toastr.success("Successfully added the Stop to Routes!", "Success");
      this.setState({
        showStop: false,
        disableStop: true,
      });
    }
  }

  changeFullAddress(index, address) {
    let params = address.split("--");
    let stops = this.props.stops;
    stops[index]["address"] = {};
    stops[index]["address"].street = params[0];
    stops[index]["address"].city = params[1];
    stops[index]["address"].state = params[2];
    stops[index]["address"].zipcode = params[3];
    this.props.change("RoutesForm", "stops", stops);
  }

  onSelectRoutes = (routes) => {
    this.setState({ selectedRoutes: [...routes] });
  };

  handleOnClickStepper = (step) => {
    this.setState({ activeStep: step });
  };

  handleOnClickNext = () => {
    this.refs.submit.click();
  };

  handleOnClickBack = () => {
    let prevStep = this.state.activeStep - 1;
    this.setState({ activeStep: prevStep });
  };

  addStop() {
    this.setState({
      showStop: true,
    });
  }

  editRoutes() {
    this.setState({
      mode: "edit",
    });
  }

  cancelEditRoutes() {
    this.props.getRoutes();
    this.props.reset();
    this.setState({
      mode: "view",
    });
  }

  saveRoutes = () => {
    const { routes } = this.state;
    for (let i = 0; i < routes.length; i++) {
      routes[i].priority = i + 1;
    }
    this.props.saveRoutes(routes);
    this.cancelEditRoutes();
    // testting
    // const p = Object.keys(this?.props?.routes).length;
    // this.setState({priority: p})
  };
  addRouteClick = () => {
    const priority = (Object.keys(this.props.routes).length||0) + 1;
    this.props.addRoute(priority);
  };
  handleCloseStop() {
    this.setState({
      showStop: false,
    });
    this.props.resetAddForm();
  }

  fixRouteStops() {
    this.props.fixRouteStops();
  }

  fixDuplicateStops() {
    this.props.fixDuplicateStops();
  }

  handleShow = (route) => {
    this.setState({ show: true, route });
  };

  handleMove = (routeId, load_side) => {
    const { routes } = this.state;
    let index = routes.findIndex((item) => item.uid == routeId);
    routes[index].load_side = load_side;
    this.setState({ show: false, routes: routes });
  };

  handleClose() {
    this.setState({ show: false });
  }

  handleRemove = () => {
    const { routes, route } = this.state;
    const updated = routes.filter((item) => item.uid !== route.uid);

    this.props.removeRoute(this.state.route);
    this.props.setRouteOrder(updated);
    this.setState({ show: false, routes: updated });
  };


  save = () => {
    let nextStep = this.state.activeStep + 1;
    this.setState({ activeStep: nextStep });
  };


  componentDidMount() {
    // console.log('TEST componentDidMount')
    this.props.getCustomerList();
    this.props.getDumpsterAddresseses();
    setTimeout(() => {
      this.props.getRoutes();
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.routes !== this.props.routes) {
      this.setState({ routes: this.props.routes });
    }
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { routes } = this.state;
    const routesVal = Object.values(routes);

    const orderedroutes = reorder(
      routesVal,
      result.source.index,
      result.destination.index
    );

    this.setState({
      routes: orderedroutes,
    });
  };

  onDragEndRear  = (result) => {
    if (!result.destination) {
      return;
    }

    const { routes } = this.state;
    const routesVal = Object.values(routes);

    const lastIndexOfFL = routes.filter((item) => item.load_side == "FL").length  
    console.log(lastIndexOfFL);

    const destinationIndex = lastIndexOfFL + result.destination.index


    const orderedroutes = reorder(
      routesVal,
      result.source.index,
      destinationIndex
    );
    this.setState({
      routes: orderedroutes,
    });
  };

  render() {
    const { routes } = this.state;
    console.log('state.routes: ', routes);
    const routesFL = routes.filter((item) => item.load_side == "FL");
    const routesRL = routes.filter((item) => item.load_side == "RL");

    if (this.props.authorized) {
      return (
        <RoutesWrapper>
          <div className="d-flex">
            {/* testting */}
            {/* <Button onClick={this.props.addRoute} className="mb-3" hidden={this.state.mode === 'edit'}> + Add Route </Button> */}
            <Button
              onClick={this.addRouteClick}
              className="mb-3"
              hidden={this.state.mode === "edit"}
            >
              {" "}
              + Add Route{" "}
            </Button>

            <Button
              onClick={this.addStop}
              className="mb-3 ml-2"
              disabled={this.state.disableStop}
              hidden={this.state.mode === "edit"}
            >
              {" "}
              + Add Stop{" "}
            </Button>
            <Button
              onClick={this.editRoutes}
              className="mb-3 ml-2"
              hidden={this.state.mode === "edit"}
            >
              {" "}
              Edit Routes{" "}
            </Button>
            {/* <Button onClick={this.fixDuplicateStops} className="mb-3 ml-2" hidden={this.state.mode === 'edit'}> Fix Duplicate Stops </Button> */}
            <Button
              onClick={this.saveRoutes}
              className="mb-3 ml-2"
              hidden={this.state.mode !== "edit"}
            >
              {" "}
              Save
            </Button>
            <Button
              onClick={this.cancelEditRoutes}
              className="mb-3 ml-2"
              hidden={this.state.mode !== "edit"}
            >
              {" "}
              Cancel{" "}
            </Button>
            {/* <Button onClick={this.fixRouteStops} className="mb-3 ml-2">Clean Up Routes And Stops</Button> */}
          </div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="col-md-6">
              <h3>Front Load</h3>
              <hr />
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Route Name</th>
                    <th>Status</th>
                    <th hidden={this.state.mode == "view"}>Move</th>
                    <GlyphTh>Delete</GlyphTh>
                  </tr>
                </thead>

                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <tbody
                      ref={(ref) => {
                        this.tableRef = ref;
                        provided.innerRef(ref);
                      }}
                      {...provided.droppableProps}
                    >
                      {routesFL &&
                        routesFL.map((route, index) => {
                          return (
                              <Draggable
                                key={route.uid}
                                draggableId={route.uid}
                                index={index}

                                // index={Math.floor((Math.random() * Math.random()*100)+1)}
                              >
                                {(provided, snapshot) => (
                                  <tr
                                    key={route.uid}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <td
                                      onClick={() =>
                                        this.props.setRoute(route.uid)
                                      }
                                    >
                                      <div>{route.name}</div>
                                    </td>
                                    <td
                                      onClick={() =>
                                        this.props.setRoute(route.uid)
                                      }
                                    >
                                      {route.status}
                                    </td>
                                    <td hidden={this.state.mode == "view"}>
                                      <Button
                                        onClick={() =>
                                          this.handleMove(route.uid, "RL")
                                        }
                                      >
                                        Move to RL
                                      </Button>
                                    </td>
                                    <GlyphTd>
                                      {route.name.includes("LOST") ? null : (
                                        <GlyphButton
                                          onClick={() => this.handleShow(route)}
                                        >
                                          <Glyphicon
                                            className="text-danger"
                                            glyph="remove"
                                          />
                                        </GlyphButton>
                                      )}
                                    </GlyphTd>
                                  </tr>
                                )}
                              </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </Table>
            </div>
          </DragDropContext>
          <DragDropContext onDragEnd={this.onDragEndRear}>
            <div className="col-md-6">
              <h3>Rear Load</h3>
              <hr />
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Route Name</th>
                    <th>Status</th>
                    <th hidden={this.state.mode == "view"}>Move</th>
                    <GlyphTh>Delete</GlyphTh>
                  </tr>
                </thead>

                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <tbody
                      ref={(ref) => {
                        this.tableRef = ref;
                        provided.innerRef(ref);
                      }}
                      {...provided.droppableProps}
                    >
                      {routesRL &&
                        routesRL.map((route, index) => {
                          return (                           
                            <Draggable
                              key={route.uid}
                              draggableId={route.uid}
                              index={index}

                              // index={Math.floor((Math.random() * Math.random()*100)+1)}
                              >
                              {(provided, snapshot) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={route.uid}
                                >
                                  <td
                                    onClick={() =>
                                      this.props.setRoute(route.uid)
                                    }
                                  >
                                    <div>{route.name}</div>
                                  </td>
                                  <td
                                    onClick={() =>
                                      this.props.setRoute(route.uid)
                                    }
                                  >
                                    {route.status}
                                  </td>
                                  <td hidden={this.state.mode == "view"}>
                                    <Button
                                      onClick={() =>
                                        this.handleMove(route.uid, "FL")
                                      }
                                    >
                                      Move to FL
                                    </Button>
                                  </td>
                                  <GlyphTd>
                                    {route.name.includes("LOST") ? null : (
                                      <GlyphButton
                                        onClick={() => this.handleShow(route)}
                                      >
                                        <Glyphicon
                                          className="text-danger"
                                          glyph="remove"
                                        />
                                      </GlyphButton>
                                    )}
                                  </GlyphTd>
                                </tr>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </Table>
            </div>
          </DragDropContext>

          {/* Add Stop Modal */}
          <Modal
            dialogClassName="stop-add-modal"
            show={this.state.showStop}
            onHide={this.handleCloseStop}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Stop</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Stepper
                steps={steps}
                activeStep={this.state.activeStep}
                onSelect={this.handleOnClickStepper}
                showNumber={false}
              />
              <div style={{ marginTop: "40px" }}>
                {this.state.activeStep === 1 ? (
                  <StyledForm onSubmit={this.props.handleSubmit(this.save)}>
                    <FieldArray
                      name="stops"
                      adding={false}
                      customers={this.props.customerList}
                      dumpsterAddresses = {
                        this.props.dumpsterAddresses
                      }
                      stops={this.props.stops}
                      changeFullAddress={(index, address) =>
                        this.changeFullAddress(index, address)
                      }
                      editingUID={this.state.editingUID}
                      component={StopForm}
                    />
                    <button ref="submit" style={{ display: "none" }}></button>
                  </StyledForm>
                ) : (
                  <div>
                    <RouteList
                      onSelectRoutes={this.onSelectRoutes}
                      routes={this.props.routes}
                    />
                    {this.renderErrModal()}
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div style={{ marginTop: "40px" }}>
                <Button
                  type="button"
                  onClick={
                    this.state.activeStep === steps.length
                      ? this.handleOnClickFinish
                      : this.handleOnClickNext
                  }
                >
                  {this.state.activeStep === steps.length ? "Finish" : "Next"}
                </Button>
                {this.state.activeStep === 1 ? (
                  ""
                ) : (
                  <Button type="button" onClick={this.handleOnClickBack}>
                    Back
                  </Button>
                )}
              </div>
            </Modal.Footer>
          </Modal>
          <Modal
            bsSize="small"
            show={this.state.show}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <span>Route </span>
                <span>{this.state.route ? this.state.route.name : " "}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>Are you sure you want to delete this route?</div>
            </Modal.Body>
            <Modal.Footer>
              <CloseButton onClick={this.handleClose}>Close</CloseButton>
              <DeleteButton bsStyle="danger" onClick={this.handleRemove}>
                Delete
              </DeleteButton>
            </Modal.Footer>
          </Modal>
        </RoutesWrapper>
      );
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = (state) => ({
  authorized: state.user?.pages?.routes,
  routes: state.routes,
  customers: state.customers,
  customerList: state.customerList,
  dumpsterAddresses: state.dumpsterAddresses,
  formValues: state.form.RoutesForm,
});

const mapDispatchToProps = (dispatch) => ({
  change,
  resetAddForm: () => dispatch(reset("RoutesForm")),
  setRoute: (uid) => dispatch(RoutesActions.getRoute(uid)),
  // testting
  // addRoute: () => dispatch(RoutesActions.addRoute(),
  addRoute: (priority) => dispatch(RoutesActions.addRoute(priority)),
  fixRouteStops: () => dispatch(RoutesActions.fixRouteStops()),
  removeRoute: (route) => dispatch(RoutesActions.removeRoute(route)),
  getRoutes: () => dispatch(RoutesActions.getRoutes()),
  saveRoutes: (routes) => dispatch(RoutesActions.saveRoutes(routes)),
  saveStopsToRoutes: (stops, routes) =>
    dispatch(RoutesActions.saveStopsToRoutes(stops, routes)),
  fixDuplicateStops: () => dispatch(RoutesActions.fixDuplicateStopReferences()),
  setRouteOrder: (list) => dispatch(RoutesActions.setRoutesOrder(list)),
  getCustomerList: () => dispatch(RoutesActions.getCustomerList()),
  getDumpsterAddresseses: () => dispatch(RoutesActions.getDumpsterAddresseses()),

});

Routes = reduxForm({
  form: 'RoutesForm',
  enableReinitialize: true,
})(Routes);

const selector = formValueSelector('RoutesForm')

Routes = connect(
  state => {
    const stops = selector(state, 'stops')
    return {
      stops: stops,
      initialValues: {
        stops: [{
          added_timestamp: "",
          address: {city: "", full: "", state: "", street: "", zipcode: ""},
          coordinates: {Latitude: '', Longitude: ''},
          last_picked_up_timestamp: "",
          name: "",
          notes: "",
          status: ""
        }]
      },
    }
  }
)(Routes);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Routes);
