/* @format - for Prettier */
import _ from "lodash";
import moment from "moment";
import { db } from "../firebase";
import { history } from "../App";
import { ApiService } from "../api/apiService";
import { CustomerTypes, NewCastletoDBCustomer, toNewCastleCustomerObj } from "../utils/customer";
import axiosInstance from "../api/axios";

export const setCustomersNewCastle = customers => ({
  type: "SET_NEWCASTLE_CUSTOMERS",
  customers
});

export const setCurrentNewCastleCustomer = customer => ({
  type: "SET_CURRENT_NEWCASTLE_CUSTOMER",
  customer
});

export const changeCurrentBillingQuarter = quarter => ({
  type: "SET_CURRENT_BILLING_QUARTER",
  quarter
})

export const changeCurrentBillingYear = year => ({
  type: "SET_CURRENT_BILLING_YEAR",
  year
})

export const updateCurrentBillingPeriod = (quarter, year) => {
  return function(dispatch) {
    axiosInstance.post("/api/customers/update/bill_period", {
      quarter: quarter,
      year: year
    })
      .then(() => {
        dispatch(changeCurrentBillingQuarter({
          value: quarter,
          label: quarter
        }));
        dispatch(changeCurrentBillingYear({
          value: year,
          label: year
        }));
      })
      .catch(error => console.log("error in changeCurrentBillingPeriod", error));
  };
}

export const getCurrentBillingPeriod = () => {
  return function (dispatch) {
    axiosInstance.post("/api/customers/get/bill_period")
      .then(data => {
        if (data && data.quarter && data.year) {
          dispatch(changeCurrentBillingQuarter({
            value: data.quarter,
            label: data.quarter
          }));
          dispatch(changeCurrentBillingYear({
            value: data.year,
            label: data.year
          }));
        }
      })
      .catch(error => console.log("error in getNewCastleCustomers", error));
  };
}

export const yearUpdate = () => {
  return function(dispatch) {
    db.readCustomersNewCastle()
    .then(snapshot => {
      let customersNC = {};
      console.log('processing...')
      snapshot.forEach(child => {
        let customer = child.val();
        const uid = child.key;
        const history = customer.history
        let new_history = {}
        for (var key in history) {
          let item = history[key]
          if (item.year === '' || item.year === undefined) {
            item = Object.assign(item, {
              year: '2020'
            })
          }
          new_history = Object.assign(new_history, {
            [item.uid]: item
          })
        }
        customer.history = new_history
        customersNC = Object.assign(customersNC, {
          [uid]: customer
        })
      })
      let updates = {};
      updates[`/customersNewCastle`] = customersNC;
      db.doUpdate(updates)
      console.log('success in yearUpdate')
    })
    .catch(error => console.log("error in yearUpdate", error));
  }
}

export const quarterUpdate = () => {
  return function(dispatch) {
    db.readCustomersNewCastle()
    .then(snapshot => {
      let customersNC = {};
      console.log('processing...')
      snapshot.forEach(child => {
        let customer = child.val();
        const uid = child.key;
        const history = customer.history
        let new_history = {}
        for (var key in history) {
          let item = history[key]
          if (item.quarter === '' || item.quarter === undefined) {
            item = Object.assign(item, {
              quarter: '1st Quarter'
            })
          }
          new_history = Object.assign(new_history, {
            [item.uid]: item
          })
        }
        customer.history = new_history
        customersNC = Object.assign(customersNC, {
          [uid]: customer
        })
      })
      let updates = {};
      updates[`/customersNewCastle`] = customersNC;
      db.doUpdate(updates)
      console.log('success in quarterUpdate')
    })
    .catch(error => console.log("error in quarterUpdate", error));
  }
}

/* Gets all the new castle customers in the database */
export const getCustomersNewCastle = () => {
  return function(dispatch) {
    ApiService.readAll("customers", null, CustomerTypes.new_castle)
      .then(data => {
        if (data) {
          console.log('data in getCustomers', data.customers);
          let customers = _.map(data.customers, (customer) => toNewCastleCustomerObj(customer));
          customers = _.sortBy(customers, ["Account Number"]);
          dispatch(setCustomersNewCastle(customers));
        }
      })
  };
};

/* Gets the customer in the database*/
export const getNewCastleCustomer = uid => {
  return function(dispatch) {
    ApiService.readOne('customers', uid)
    .then(data => {
      let customer = data?.customer;
      console.log('customer in getCustomer', customer);
      if(customer)
        dispatch(setCurrentNewCastleCustomer(toNewCastleCustomerObj(customer)));
    })
  };
};

export const billToggleNewCastle = (uid, billToggle) => {
  return function(dispatch) {
    ApiService.update("customers", uid, { customer: { status: billToggle } })
      .then(updated => {
        if (updated) {
          dispatch(getCustomersNewCastle());
        }
      })
      .catch(error => console.log("error in getNewCastleCustomers", error));
  };
};

/* Gets the customer in the database then goes to that page */
export const setNewCastleCustomer = uid => {
  return function(dispatch) {
    ApiService.readOne('customers', uid)
    .then(data => {
      let customer = data?.customer;
      // console.log('customer in getCustomer', customer);
      if(customer){
        console.log('customer in getCustomer', toNewCastleCustomerObj(customer));
        dispatch(setCurrentNewCastleCustomer(toNewCastleCustomerObj(customer)));
        history.push("/NewCastle/Customer");
      }
    })

  };
};

export const saveNewCastleCustomer = (values, customerId) => {
  return function(dispatch) {
        let updates = {};
        _.forEach(values, (value, key) => {
          if (value !== undefined) {
            updates[key] = value;
          }
        });
        console.log('updates', updates);
        let data = NewCastletoDBCustomer(updates);
        console.log('data', data);
        ApiService.update('customers', customerId, {
            customer: data
          })
          .then((updated) => {
            if (updated) {
              dispatch(getNewCastleCustomer(customerId));
              history.push("/NewCastle/Customer");
            }
          })
  };
};

export const addNewCastleCustomer = () => {
  return async function(dispatch) {
    let data = {
      name: "",
      type: CustomerTypes.new_castle,
      status: "Charged",
    };
    const resData = await ApiService.create('customers', {
      customer: data
    })
    if (resData && resData.customer) {
      dispatch(setCurrentNewCastleCustomer(toNewCastleCustomerObj(resData.customer)));
      history.push("/NewCastle/Customer");
    }
  };
};

export const suspendCustomer = (customer, note) => {
  return function(dispatch) {
    db.doSuspendAccountNewCastle(customer.uid, moment().format("L"), customer.notes, note)
      .then(() => {
        dispatch(getNewCastleCustomer(customer.uid));
      })
      .catch(error => console.log("error in supsend customer"));
  };
};

export const unsuspendCustomer = (customer, note, amount_due) => {
  return function(dispatch) {
    db.doUnsuspendAccountNewCastle(
      customer.uid,
      moment().format("L"),
      customer.notes,
      note,
      amount_due
    )
      .then(() => {
        dispatch(getNewCastleCustomer(customer.uid));
      })
      .catch(error => console.log("error in supsend customer"));
  };
};

export const changePaymentHistory = (
  customer_uid,
  history_uid,
  amount,
  paymentSource,
  year,
  quarter,
  description,
  time,
) => {
  return function(dispatch) {
    let updates = {};
      updates[`amount`] = `$${amount}`;
      updates[`payment_source`] = paymentSource;
      updates[`year`] = year;
      updates[`quarter`] = quarter;
      updates[`description`] = description;
      updates[`time`] = time;
      ApiService.update(`customers`, 'history/'+history_uid, updates)
      .then(() => {
        dispatch(getNewCastleCustomer(customer_uid));
      })
      .catch(error => console.log("error in changePaymentHistory", error));
  };
};

export const makePayment = (
  customer,
  time,
  amount_paid,
  old_amount,
  description,
  historyId,
  quarter,
  serviceLevel,
  year,
) => {
  return function(dispatch) {
    axiosInstance.post('api/customers/make_payment/new_castle', {
        customerId: customer.uid,
        historyId: Number(historyId || "0"),
        amount_due: customer.amount_due,
        amount_paid,
        description,
        reason: customer.type_of_reason,
        time,
        quarter,
        year,
        service_level: serviceLevel,
      })
      .then((res) => {
        if (res.status === 200)
          dispatch(getCustomersNewCastle(customer.uid));
      })
      .catch(error => console.log("error in make payment", error));
    };
};

export const chargeCustomer = (
  customer,
  time,
  amount_charged,
  old_amount,
  description,
  historyId
) => {
  return function(dispatch) {
    let amount_due = customer.amount_due;
    amount_due =
      parseFloat(amount_due, 10) +
      parseFloat(amount_charged, 10) -
      parseFloat(old_amount, 10);

      // Get the current date and time
      let now = new Date();

      // Get the date from the string
      let timeString = time;
      let dateObject = new Date(timeString);

      // Set the date object's year, month, and day to match the current time
      dateObject.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());

      // Now dateObject has both the specific date and the exact current time
      let timestamp = dateObject.getTime();
      let dateTimeString = dateObject.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      time = dateTimeString; //time is now a timestamp      

    axiosInstance.post('api/customers/charge', {
        customerId: customer.uid,
        historyId: Number(historyId),
        amount_due: amount_due.toFixed(2),
        status: customer.status,
        amount_charged,
        description,
        time: time,
      })
      .then((res) => {
        if (res.status === 200)
          dispatch(getCustomersNewCastle(customer.uid));
      })
      .catch(error => console.log("error in charge customer", error));
  };
};

export const updateInvoiceDates = (customer, time, due_date, historyId) => {
  console.log("historyId", historyId);
  return function(dispatch) {
    db.doUpdateInvoiceDatesNewCastle(customer.uid, time, due_date, historyId)
      .then(() => {
        dispatch(getNewCastleCustomer(customer.uid));
      })
      .catch(error => console.log("error in update invoice dates"));
  };
};

export const removeInvoiceHistory = (invoiceId, customerId) => {
  return function(dispatch) {
    db.deleteInvoiceHistory(invoiceId, customerId).then(() => {
      db.readCustomerNewCastle(customerId)
      .then(snapshot => {
        let customer = snapshot.val();
        customer.uid = customerId;
        dispatch(setCurrentNewCastleCustomer(customer));
      }).catch(error => console.log("error in delete Invoice History"));
    })
    .catch(error => console.log("error in delete Invoice History"));
  };
}

export const removeNewCastleCustomer = customerId => {
  return function(dispatch) {
    ApiService.delete("customers", customerId)
      .then((deleted) => {
        if (deleted)
        dispatch(getCustomersNewCastle());
      })
      .catch(error => console.log("error in delete New Castle customers"));
  };
};