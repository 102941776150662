/* @format - for Prettier */
import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  Table, Button, Glyphicon, Modal, Row, Checkbox,
} from "react-bootstrap";
import { Customers as CustomersActions } from "../../actions2";
import { DeniedWrapper } from "../../styled";
import moment from 'moment';
import InputMoment from 'input-moment';
import Select from 'react-select'
import printHtmlElement from 'print-html-element';
import PrintOpenInvoices from './PrintOpenInvoices';

const CustomersWrapper = styled.div`
  width: 80vw;
  margin: 0 auto;
`;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  margin: 10px 0px 10px 10px;
  float: right;
`;

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`;

const Base = styled.div`
  margin: 10px 5px 0;
  display: inline-flex;
`;

const Date = styled(Base)`
  margin-right: 20px;
  padding: 10px 50px 10px 10px;
  font-size: 16px;
  border: 1px solid lightgrey;
  border-radius: 4px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 20px !important;
`;

const StyledDatePicker = styled(InputMoment)`
  width: 100% !important;
`;

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      start: moment(),
      end: moment().startOf('day').add(1, 'month'),
      which: '',
      customer: null,
      onlyOpenCharges: false,
    };
  }

  componentDidMount(next) {
    this.props.getInvoices();
    this.props.getCustomers();
  }

  handlePrint = () => {
    var element = document.getElementById('print');
    printHtmlElement.printElement(element, {pageTitle: 'Invoices'});
  }; 

  handleSelectDate = (which) => {
    this.setState({
      which,
      show: true,
    });
  }
  
  handleChange = (moment) => {
    if (this.state.which === 'start') {
      this.setState({start: moment.startOf('day')});
    } else {
      this.setState({end: moment.startOf('day')});
    }
  }

  handleOpenCharge = () => {
    this.setState({onlyOpenCharges: !this.state.onlyOpenCharges});
  }
  
  handleSelectedCustomer = (e) => {
    this.setState({customer: e.value});
  }

  handleSave = () => {
    this.setState({show: false})
  }

  render() {
    const filteredInvoices = _.filter(
      this.props.invoices, invoice => {
        let invoice_date = moment(invoice.time, 'MM/DD/YYYY');
        let invoice_due_date = moment(invoice.due_date, 'MM/DD/YYYY');
        if (invoice_date.diff(this.state.start) < 0 || invoice_date.diff(this.state.end) > 0) return false;
        if (invoice_due_date.diff(this.state.end) >= 0) return false;
        if (this.state.customer && invoice.customer.uid !== this.state.customer.uid) return false;
        if (this.state.onlyOpenCharges) {
          if(invoice.sent && !invoice.paid) return true;
          else return false;
        }
        return true;
      }
    );
    let customers = [{
      value: null,
      label: 'All',
    }];
    // eslint-disable-next-line
    {_.map(this.props.customers, customer => { 
      customers.push({
        value: customer, 
        label: customer.name,
      });
    })}
    if (this.props.authorized) {

      return (
        <CustomersWrapper>
          <div id="topbar">
            <Base>From</Base>
            <Date onClick={() => this.handleSelectDate('start')}>{this.state.start.format('L')}</Date>
            <Base>To</Base>
            <Date onClick={() => this.handleSelectDate('end')}>{this.state.end.format('L')}</Date>
            <Base>Customer</Base>
            <div style={{ display: 'inline-block', width: '250px', margin: '10px 0px' }}>
              <Select options={customers} onChange={this.handleSelectedCustomer}/>
            </div>
            <div style={{ display: 'inline-block', margin: '10px' }}>
              <Checkbox onChange={this.handleOpenCharge}>
                Show Only Open Charges
              </Checkbox>
            </div>
            {
              this.state.onlyOpenCharges ? (
                <PrintOpenInvoices invoices = {filteredInvoices} />
              ) : ('')
            }
            <ToolButton onClick={this.handlePrint} bsStyle="primary" id="printBtn">
              <AddGlyph glyph="print" />
              Print
            </ToolButton>
          </div>

          <Table id="print" hover bordered>
            <thead>
              <tr>
                <th>Customer</th>
                <th>Date</th>
                <th>Due Date</th>
                <th>Status</th>
                <th>Due Amount</th>
              </tr>
            </thead>
            <tbody>
              {_.map(filteredInvoices, (invoice, i) => {
                return (
                  <tr key={i} onClick={() => this.props.showInvoice(invoice.customer.uid, invoice.uid)}>
                    <td>{invoice.customer.name}</td>
                    <td>{invoice.time}</td>
                    <td>{invoice.due_date}</td>
                    <td>{invoice.sent ? `Sent (${invoice.sent_date})` : 'Waiting to send invoice'}</td>
                    <td>{invoice.amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Modal show={this.state.show} bsSize="sm">
              <Modal.Body>
                <StyledRow>
                  <StyledDatePicker
                    moment={this.state.which === 'start' ? this.state.start : this.state.end }
                    onChange={this.handleChange}
                    onSave={this.handleSave}
                  />
                </StyledRow>
                
              </Modal.Body>
            </Modal>
        </CustomersWrapper>
      );
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = state => ({
  customers: state.customers,
  invoices: state.invoices,
  authorized: state.user?.pages?.customers
});

const mapDispatchToProps = dispatch => ({
  showInvoice: (customerId, historyId) => dispatch(CustomersActions.showInvoice(customerId, historyId)),
  getCustomers: () => dispatch(CustomersActions.getCustomers()),
  getInvoices: () => dispatch(CustomersActions.getInvoices()),  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invoices);
