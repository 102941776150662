/* @format - for Prettier */
import {Button} from 'react-bootstrap';
import styled from 'styled-components';

export const ModeButton = styled(Button)`
  padding-right: 0px;
  text-decoration: underline;
`;

export const ButtonRow = styled.div`
  float: right;
`;

export const Name = styled.h3`
  align: center;
  margin-bottom: 10px;
`;

export const ErrorText = styled.div`
  color: red;
  font-weight: bold;
`;

export const DeniedWrapper = styled.div`
  text-align: center;
`;
