/* @format - for Prettier */
import React, {Component} from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {
  Grid,
  Row,
  Col,
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
} from 'react-bootstrap';
import axiosInstance from '../../api/axios';
import LocalStorage from '../../services/LocalStorage';
import { init } from '../../actions2';
import { connect } from 'react-redux';

const Errors = {
  InvalidEmail: "Invalid email.",
  InvalidPassword: `Invalid password`,
}

const renderField = ({input, label, type, meta: {touched, error}}) => (
  <div>
    <ControlLabel>{label}</ControlLabel>
    <div>
      <FormControl {...input} placeholder={label} type={type} />
      {touched && error && <div className="error">{error}</div>}
    </div>
  </div>
);

class Login extends Component {
  constructor (props) {
    super(props)    
  }

  submit = values => {
    const {
      email,
      password
    } = values;
    return axiosInstance.post('api/auth/login', {
        email,
        password
      })
      .then(res => {
        const {
          success,
          user,
          error
        } = res.data;
        if (success && user) {
          this.props.init(user.uid)
          LocalStorage.storeData('eiken_user', user);
          window.dispatchEvent(new Event('storage'));

        } else {
          alert(error);

        }
      })
      .catch(errRes => {
        console.log('login error: ', errRes);
        if (errRes.error && errRes.error === Errors.InvalidEmail) {
          throw new SubmissionError({
            email: errRes.error,
            _error: errRes.error,
          });
        } else if (errRes.error && errRes.error === Errors.InvalidPassword) {
          throw new SubmissionError({
            password: errRes.error,
            _error: errRes.error,
          });
        } else {
          alert(errRes.error);
        }
      });
  };

  render() {
      const {
        handleSubmit,
        submitting
      } = this.props;

    return (
    <Grid>
      <Row>
        <Col sm={6} smOffset={3} md={4} mdOffset={4}>
          <form className="LoginForm" onSubmit={handleSubmit(this.submit)}>
            <FormGroup>
              <Row>
                <Field
                  name="email"
                  type="text"
                  component={renderField}
                  label="Email Address"
                />
              </Row>
              <Row>
                <Field
                  name="password"
                  type="password"
                  component={renderField}
                  label="Password"
                />
              </Row>
              <Row>
                <Col sm={4} smOffset={4} md={6} mdOffset={3}>
                  <Button type="submit" disabled={submitting}>
                    Login
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </form>
        </Col>
      </Row>
    </Grid>
    )
  }
}

// LoginForm = reduxForm({
//   form: 'LoginForm', // a unique identifier for this form
// })(LoginForm);

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  init: (userId) => dispatch(init(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'LoginForm', // a unique identifier for this form
})(Login));