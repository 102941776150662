/* @format - for Prettier */
import React from "react";
import ReactExport from "react-data-export";
import _ from "lodash";
import styled from "styled-components";
import { connect } from "react-redux";
import toastr from 'toastr';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Table, Modal, Button, Glyphicon, ProgressBar } from "react-bootstrap";
import { NewCastleCustomers2 as NewCastleCustomers2Actions } from "../../actions2";
import { Customers as CustomersActions } from "../../actions2";
import { DeniedWrapper } from "../../styled";
import printHtmlElement from 'print-html-element';
import worker from "workerize-loader!../NewCastle/worker"; // eslint-disable-line import/no-webpack-loader-syntax

import AccordionPaidCustomers from '../Customers/AccordionPaidCustomers'
import SelectModal from '../core/SelectModal';
import DueDateSelectModal from '../core/DueDateSelectModal';
import moment from "moment";
import { isQuarterlyCustomer, isMonthlyCustomer, isQuarterlyCustomerWithEInvoice, isQuarterlyCustomerWithoutEInvoice, CustomerTypes } from '../../utils/customer'
import { monthOptions, quarterOptions, yearOptions } from '../../utils/index'
import { object } from "prop-types";
import DatePicker from "react-datepicker";
import axiosInstance from "../../api/axios";

const axios = require("axios");
const pdfService = require("../../services/pdf").pdfService;

const CustomersWrapper = styled.div`
  width: 90%;
  max-width: 1168px;
  margin: 0 auto;
`;
const StyledSelect = styled.select`
  margin-bottom: 10px;
`;

const PrintProgress = styled(ProgressBar)`
  margin-top: 20px;
`;

const AccordionContainer = styled.div`
  margin: 20px 0px !important;
`;

const GlyphButton = styled(Button)`
  width: 100%;
  color: red !important;
  border: none;
`;

const GlyphTd = styled.td`
  width: 50px;
`;

const GlyphTh = styled.th`
  width: 50px;
`;

const DeleteButton = styled(Button)`
  float: right;
`;

const CloseButton = styled(Button)`
  float: left;
`;

const CloseModalButton = styled(Button)``;

const ToolButton = styled(Button)`
  padding: 10px 20px;
  height: 40px;
  margin: 10px 0px 0px 10px;
  float: right;
`;

const ActionButton = styled(Button)`
  margin: 0px 0px 0px 10px;
  float: right;
`;

const AddGlyph = styled(Glyphicon)`
  margin-right: 5px;
`;

const Header = styled.div`
  display: flex;
`;

const CloseMoadlButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Search = styled.input`
  width: 200px;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid lightgrey;
`;

const ExportWrapper = styled.div`
  span {
    width: 100%;
  }
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class NewCastle2Customers extends React.Component {
  state = {
    spreadsheet_upload: false,
    selectedFile: null,
    show: false,
    dueDate: new Date(),
    customer: null,
    searchText: "",
    progress: 0,
    quarter_progress: 0,
    progressing_show: false,
    quarter_progressing_show: false,
    selected_month_customer: {},
    selected_year: {},
    selected_month: {},
    eInvoicesForQuarterlyResults: [],
    eInvoicesForQuarterlyTotal: 0,
    showMonthlyBillModal: false,
    showBillAll: false,
    showBillAll1: false,
    showPrintAllInvoicesModal: false,
    showMonthlyPrintModal: false,
    showQuarterlyEInvoicesModal: false,
    showMonthlyEInvoiceModal: false,
    customers:[]
  };

  billForMonth = (customer) => {
    this.setState({
      showMonthlyBillModal: true,
      selected_month_customer: customer,
    });
  };

  handleBillMonth = (month, year) => {
    this.setState({
      showMonthlyBillModal: false,
    });
    this.props.billMonthNewCastleCustomer(
      this.state.selected_month_customer,
      this.state.selected_month,
      this.state.selected_year
    );
  };

  handleBillFilter = () => {
    this.setState({
      showBillAll: false,
    });
    this.props.billAllNewCastleCustomers(this.state.selected_month,this.state.selected_year);
  };

  spreadsheetUpload = () => {
    this.setState({
      spreadsheet_upload: true
    })
  }

  handleSpreadsheetUploadClose = () => {
    this.setState({
      spreadsheet_upload: false
    })
  }

  // On file select (from the pop up) 
  onFileChange = event => { 
    // Update the state 
    this.setState({ selectedFile: event.target.files[0] }); 
  }; 

  // On file upload (from the pop up) 
  onFileUpload = event => { 
    this.setState({
      spreadsheet_upload: false
    })
    this.props.updateCustomerPrices(this.state.selectedFile);
  }; 

  handleBillFilter1 = () => {
    this.setState({
      showBillAll1: false,
    });
    this.props.billAllNewCastleCustomersRecuring(this.state.selected_month,this.state.selected_year);
  };

  componentDidMount() {
    this.props.getCustomers();
    this.worker = worker();
    this.worker.addEventListener("message", this.onMessage);
    this.setState({
      searchText: localStorage.getItem("remember_newcastle_search_key"),
    });
    document.getElementById("search-input").value = localStorage.getItem('remember_newcastle_search_key');
  }

  componentWillUnmount() {
    this.worker.removeEventListener("message", this.onMessage);
  }

  handleShow = (customer) => {
    this.setState({ show: true, customer });
  };

  handleQuarterlyPrint = async () => {
    this.setState({
      showPrintAllInvoicesModal: false,
    });
    const quarterlyCustomers = _.filter(
      this.props.customers,
      isQuarterlyCustomer
    );
    
    const customersForQuarterlyWithoutEInvoice = _.filter(
      quarterlyCustomers,
      isQuarterlyCustomerWithoutEInvoice
    );
    pdfService.newcastle2_customer_invoice_pdf_download(
      customersForQuarterlyWithoutEInvoice,
      "all quarterly",
      this.state.dueDate //quarterlyDueDate
      // quarterlyCustomers,
      // moment().format('MM/DD/YYYY'),
      // quarterlyDueDate,
      // this.handleProgress
      // "all quarterly",
    );
  };
  // handleProgress=(progress)=>{
  //   this.setState({
  //     progress
  //   })
  // }
  handleShowPrintAllInvoicesModal = () => {
    this.setState({
      showPrintAllInvoicesModal: true,
    });
  };

  handleMonthlyPrint = () => {
    this.setState({ showMonthlyPrintModal: false });
    pdfService.newcastle2_customer_invoice_pdf_download(
      [this.state.selected_month_customer],
      "montly",
      this.state.dueDate //monthlyDueDate
    );
  };

  handleShowMonthlyPrintModal = (customer) => {
    this.setState({
      showMonthlyPrintModal: true,
      selected_month_customer: customer,
    });
  };

  timer = (ms) => {
    return new Promise((res) => setTimeout(res, ms));
  };

  handleShowQuarterlyEInvoicesModal = () => {
    this.setState({ showQuarterlyEInvoicesModal: true });
  };

  eInvoicesForQuarterlyWithEInvoice = async (dueDate) => {
    this.setState({ showQuarterlyEInvoicesModal: false });

    const eInvoicesForQuarterlyWithEInvoice = _.filter(
      this.props.customers,
      isQuarterlyCustomerWithEInvoice
    );

    this.setState({
      quarter_progressing_show: true,
      quarter_progress: 0,
    });

    const Length = eInvoicesForQuarterlyWithEInvoice.length;
    let eInvoicesForQuarterlyResults = [];
    this.setState({
      eInvoicesForQuarterlyTotal: Length,
    });
    for (let i = 0; i < Length; i += 1) {
      await this.timer(200);
      const customer = eInvoicesForQuarterlyWithEInvoice[i];
      const base64 = await pdfService.newcastle2_customer_invoice_pdf_download(
        [customer],
        "base64",
        this.state.dueDate //dueDate
      );
      let sender_email = customer.email;
      if (process.env.REACT_APP_ENVIRONMENT === "development")
        sender_email = "afebbraro@atsapllc.com";
      let data = {
        index: i,
        email: sender_email,
        content: base64,
      };
        axiosInstance.post('/api/customers/send_eInvoiceMail', {
          ...data
        })
        .then((res) => {
          eInvoicesForQuarterlyResults.push({
            customer: eInvoicesForQuarterlyWithEInvoice[res.data.id],
            success: res.data.success,
          });
        })
        .catch((err) => {
          eInvoicesForQuarterlyResults.push({
            customer: customer,
            success: 0,
          });
        })
        .finally(() => {
          this.setState({
            eInvoicesForQuarterlyResults: eInvoicesForQuarterlyResults,
            quarter_progress: parseInt(
              (eInvoicesForQuarterlyResults.length * 100) / Length
            ),
          });
          if (eInvoicesForQuarterlyResults.length === Length)
            toastr.success("Successfully Sent E-mails.", "Success");
        });
    }
  };

  closeQuarterlyEmailModal = () => {
    this.setState({
      quarter_progressing_show: false,
      quarter_progress: 0,
      eInvoicesForQuarterlyResults: [],
    });
  };

  handleShowMonthlyEInvoiceModal = (customer) => {
    this.setState({
      selected_month_customer: customer,
      showMonthlyEInvoiceModal: true,
    });
  };

  eInvoiceForMonth = async (dueDate) => {
    this.setState({
      quarter_progressing_show: true,
      quarter_progress: 0,
      showMonthlyEInvoiceModal: false,
    });
    const base64 = await pdfService.newcastle2_customer_invoice_pdf_download(
      [this.state.selected_month_customer],
      "base64",
      this.state.dueDate //dueDate
    );
    let sender_email = this.state.selected_month_customer.email;
    if (process.env.REACT_APP_ENVIRONMENT === "development")
      sender_email = "afebbraro@atsapllc.com";
    let data = {
      index: 0,
      email: sender_email,
      content: base64,
    };
    if(sender_email.length==0){
      toastr.error("Customer email not found.", "Error");
      this.setState({
        quarter_progressing_show: false,
      });
      return;
    }
    this.setState({
      quarter_progress: 50,
    });
    axiosInstance.post('/api/customers/send_eInvoiceMail', {
      ...data
    })
      .then((res) => {
        if (res.data.success)
          toastr.success("Successfully Sent E-mail.", "Success");
      })
      .catch((err) => {
        console.log(err);
        toastr.error(err.error, "Error");
      })
      .finally(() => {
        this.setState({
          quarter_progress: 100,
        });
        setTimeout(() => {
          this.setState({
            quarter_progressing_show: false,
          });
        }, 1000);
      });
  };

  onMessage = (message) => {
    if (message.data.done) {
      this.onProcessComplete();
    }
    if (message.data.file) {
      this.setState({
        progress: message.data.progress,
      });
      this.onPdfGenerate(message.data.file, message.data.batch);
    }
  };

  onProcessComplete = () => {
    setTimeout(() => {
      this.setState({
        progress: 0,
        progressing_show: false,
      });
    }, 2000);
  };

  onPdfGenerate = (blob, batch) => {
    const data = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = data;
    link.download = `newcastle2_${moment().format("MMDDYYYY")}_${batch}.pdf`;
    link.click();
    link.remove();
  };

  billAllHandle = () => {
    this.setState({
      showBillAll: true,
    });
  };
  billAllHandle1 = () => {
    this.setState({
      showBillAll1: true,
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleRemove = () => {
    this.props.removeCustomer(this.state.customer.uid);
    this.setState({ show: false });
  };

  handleSearchInput = (evt) => {
    this.setState({ searchText: evt.target.value.toLowerCase() });
    localStorage.setItem("remember_newcastle_search_key", evt.target.value.toLowerCase());
  };

  handlePrint = () => {
    var element = document.getElementById("print");
    printHtmlElement.printElement(element, { pageTitle: "Customers" });
  };

  filterResult = (customer, searchText) => {
    try {
      if (
        customer["Account Number"] &&
        customer["Account Number"].toString().includes(searchText)
      )
        return true;
      if (customer.name && customer.name.toLowerCase().includes(searchText))
        return true;
      if (customer.status && customer.status.toLowerCase().includes(searchText))
        return true;
      if (
        customer.contact_name &&
        customer.contact_name.toLowerCase().includes(searchText)
      )
        return true;
      if (customer.email && customer.email.toLowerCase().includes(searchText))
        return true;
      if (
        customer.service_address.city &&
        customer.service_address.city.toLowerCase().includes(searchText)
      )
        return true;
      if (
        customer.service_address.street &&
        customer.service_address.street.toLowerCase().includes(searchText)
      )
        return true;
      if (
        customer.service_address.zipcode &&
        customer.service_address.zipcode.toString().includes(searchText)
      )
        return true;
      if (
        customer.billing_address.city &&
        customer.billing_address.city.toLowerCase().includes(searchText)
      )
        return true;
      if (
        customer.billing_address.street &&
        customer.billing_address.street.toLowerCase().includes(searchText)
      )
        return true;
      if (
        customer.billing_address.zipcode &&
        customer.billing_address.zipcode.toString().includes(searchText)
      )
        return true;
      if (
        customer.credit_card &&
        customer.credit_card.number.includes(searchText)
      )
        return true;
      if (
        customer.credit_card &&
        customer.credit_card.expiry.includes(searchText)
      )
        return true;
      if (customer.credit_card && customer.credit_card.cvc.includes(searchText))
        return true;
      if (
        customer.credit_card_name &&
        customer.credit_card_name.toLowerCase().includes(searchText)
      )
        return true;
      // if (customer.notes && customer.notes.toLowerCase().includes(searchText))
      //   return true;
      let result = false;
      return result;
    } catch (error) {
      console.log(error);
      return true;
    }
  };

  customerFun = () => {
    const customer = this.props.customers;
    customer.forEach((item) => {
      var z = "";
      var b = "";
      var d = "";
  
      // Handle phone numbers
      if (item.phone_numbers) {
        item.phone_numbers.forEach((elem) => {
          z += elem.number + ",";
        });
        item.phoneNumber = z.replace(/,\s*$/, "");
      }
  
      // Handle billing addresses
      if (item.billing_address) {
        // Concatenate the address parts into a single string
        b = [
          item.billing_address.street,
          item.billing_address.city,
          item.billing_address.state,
          item.billing_address.zipcode
        ].filter(part => part).join(", "); // Filters out any undefined or null parts
        item.BillingAddress = b;
      }

      // Handle service addresses
      if (item.service_address) {
        // Concatenate the address parts into a single string
        d = [
          item.service_address.street,
          item.service_address.city,
          item.service_address.state,
          item.service_address.zipcode
        ].filter(part => part).join(", "); // Filters out any undefined or null parts
        item.ServiceAddress = b;
      }
    });
  
    return customer; // Ensure you return the modified customer array
  };

  paidCustomers = () => {
    const paidCustomers = _.filter(this.props.customers, customer => {
      return customer.status=="Paid";
    });
    console.log(paidCustomers.length);
    return paidCustomers;
  };
  unpaidCustomers = () => {
    const unpaidCustomers =  _.filter(this.props.customers, customer => {
      return customer.status=="Not paid";
    });
    console.log(unpaidCustomers.length);
    return unpaidCustomers;
  };

  getLastTwelveQuarters=(e)=> {
    const currentQuarter = Math.floor((new Date().getMonth()) / 3) + 1;
    const quarterSuffix=['st','nd','rd','th']
    const currentYear = new Date().getFullYear();
    const quarters = [];
    let year = currentYear;
    let quarter = currentQuarter;
    for (let i = 0; i < 12; i++) {
      quarters.push({
        quarter:`${quarter}${quarterSuffix[quarter-1]} Quarter`,
        year:`${year}`
      })
      quarter--;
      if (quarter === 0) {
        quarter = 4;
        year--;
      }
    }
    return quarters.reverse();
  }
  getGoodStandingCustomerList = e =>{
    let lastTwelveQuarters=this.getLastTwelveQuarters();
    let lastTwelveQuartersStatic=[

      // {
      //     quarter: "1st Quarter",
      //     year: "2023"
      // },
      // {
      //     quarter: "4th Quarter",
      //     year: "2022"
      // },
      // {
      //     quarter: "3rd Quarter",
      //     year: "2022"
      // },
      // {
      //     quarter: "2nd Quarter",
      //     year: "2022"
      // },
      // {
      //     quarter: "1st Quarter",
      //     year: "2022"
      // },
      // {
      //     quarter: "4th Quarter",
      //     year: "2021"
      // },
      // {
      //     quarter: "3rd Quarter",
      //     year: "2021"
      // },
      // {
      //     quarter: "2nd Quarter",
      //     year: "2021"
      // },
      // {
      //     quarter: "1st Quarter",
      //     year: "2021"
      // },
      {
        quarter: "3rd Quarter",
        year: "2020"
      },
      {
          quarter: "2nd Quarter",
          year: "2020"
      },
      {
          quarter: "1st Quarter",
          year: "2020"
      }
  ]
 
    const filteredData = this.props.customers?.filter(item => {
      return lastTwelveQuartersStatic.every(val => {
        if(item?.history){
        return Object.entries(item.history).some(child => child[1].quarter === val.quarter && child[1].year === val.year);
        }
      });
    });
    console.log(filteredData,"filtered data");
    return filteredData
  }
  render() {
    const { searchText } = this.state;
    // this.state.customers=this.getGoodStandingCustomerList()
    const filteredCustomers = searchText?_.filter(this.props.customers, (customer) =>
      this.filterResult(customer, searchText?.toLocaleLowerCase())
    ):this.props.customers;
    const quarterlyCustomers = _.filter(
      this.props.customers,
      isQuarterlyCustomer
    );
    
    console.log(this.props.customers.length)
    const montlyCustomers = _.filter(this.props.customers, isMonthlyCustomer);
    const exemptCustomers = _.filter(this.props.customers, (customer) => {
      if (customer["isExempt"] === "Yes") return true;
      return false;
    });

    const InactiveCustomers = _.filter(this.props.customers, (customer) => {
      if (customer["isInactive"] === "Yes") return true;
      return false;
    });

    

   

    const recurringCustomers = _.filter(this.props.customers, (customer) => {
      if (customer["recurringCustomer"] === "Yes") return true;
      return false;
    });

    if (this.props.authorized) {
      return (
        <CustomersWrapper>
          <label>Number of Customers: {this.props.customers.length}</label>
          <Header>
            <div style={{ margin: "10px 0px", display: "flex" }}>
              <label
                htmlFor="search-input"
                style={{ marginRight: "5px", paddingTop: "10px" }}
              >
                Search:{" "}
              </label>
              <Search
                id="search-input"
                onBlur={this.handleSearchInput}
              />
              <Button style={{marginLeft: 10}} bsStyle="primary">
                <AddGlyph glyph="search" />
                Search
              </Button>
            </div>
            <div style={{ width: "100%" }}>
              <ExportWrapper>
                <ExcelFile
                  filename="New Castle Customer Contact Info"
                  element={
                    <ToolButton bsStyle="primary">
                      Export Contact Info
                    </ToolButton>
                  }
                >
                  <ExcelSheet
                    data={() => this.customerFun()}
                    name="New Castle Customers Contact Info"
                  >
                    <ExcelColumn
                      label="Account Number"
                      value="Account Number"
                    />
                    <ExcelColumn label="Name" value="name" />
                    <ExcelColumn label="Email" value="email" />
                    <ExcelColumn label="Phone" value="phoneNumber" />
                    <ExcelColumn label="Rate" value="rate" />
                    <ExcelColumn label="BillingAddress" value="BillingAddress" />
                    <ExcelColumn label="ServiceAddress" value="ServiceAddress" />
                    <ExcelColumn label="Serial Numbers" value="toterSerialNumbers" />
                  </ExcelSheet>
                </ExcelFile>
              </ExportWrapper>
              <ToolButton onClick={this.props.addCustomer} bsStyle="primary">
                <AddGlyph glyph="plus" />
                Add Customer
              </ToolButton>
              <ToolButton onClick={this.handlePrint} bsStyle="primary">
                <AddGlyph glyph="print" />
                Print
              </ToolButton>
              <ExportWrapper>
                <ExcelFile
                  filename={`newcastle-${moment().format("YYYY-MM-DD")}`}
                  element={<ToolButton bsStyle="primary">Export Payments</ToolButton>}
                >
                  <ExcelSheet data={this.unpaidCustomers()} name="Unpaid">
                    <ExcelColumn label="Account Number" value="Account Number" />
                    <ExcelColumn label="Resident Name" value="name" />
                    <ExcelColumn
                      label="Billing Street"
                      value={col => col['billing_address']['street']}
                    />
                    <ExcelColumn
                      label="Billing City"
                      value={col => col['billing_address']['city']}
                    />
                    <ExcelColumn
                      label="Billing State"
                      value={col => col['billing_address']['state']}
                    />
                    <ExcelColumn
                      label="Billing ZIP"
                      value={col => col['billing_address']['zipcode']}
                    />
                    <ExcelColumn
                      label="Service Street"
                      value={col => col['service_address']['street']}
                    />
                    <ExcelColumn
                      label="Service City"
                      value={col => col['service_address']['city']}
                    />
                    <ExcelColumn
                      label="Service State"
                      value={col => col['service_address']['state']}
                    />
                    <ExcelColumn
                      label="Service ZIP"
                      value={col => col['service_address']['zipcode']}
                    />
                  </ExcelSheet>
                  <ExcelSheet data={this.paidCustomers()} name="Paid">
                    <ExcelColumn label="Account Number" value="Account Number" />
                    <ExcelColumn label="Resident Name" value="name" />
                    <ExcelColumn
                      label="Billing Street"
                      value={col => col['billing_address']['street']}
                    />
                    <ExcelColumn
                      label="Billing City"
                      value={col => col['billing_address']['city']}
                    />
                    <ExcelColumn
                      label="Billing State"
                      value={col => col['billing_address']['state']}
                    />
                    <ExcelColumn
                      label="Billing ZIP"
                      value={col => col['billing_address']['zipcode']}
                    />
                    <ExcelColumn
                      label="Service Street"
                      value={col => col['service_address']['street']}
                    />
                    <ExcelColumn
                      label="Service City"
                      value={col => col['service_address']['city']}
                    />
                    <ExcelColumn
                      label="Service State"
                      value={col => col['service_address']['state']}
                    />
                    <ExcelColumn
                      label="Service ZIP"
                      value={col => col['service_address']['zipcode']}
                    />
                  </ExcelSheet>
                </ExcelFile>
              </ExportWrapper>
              <ToolButton onClick={this.spreadsheetUpload} bsStyle="primary">
                <AddGlyph glyph="pencil" />
                Update Customer Prices
              </ToolButton>
            </div>
          </Header>

          <AccordionPaidCustomers
            type={CustomerTypes.new_castle_2}
            // isResidential={true}
            setCustomer={this.props.setCustomer}
          />
          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>Quarterly Customers</label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Header>
                    <div style={{ width: "100%" }}>
                      <ToolButton
                        onClick={this.handleShowQuarterlyEInvoicesModal}
                        bsStyle="primary"
                      >
                        Send E-Invoices
                      </ToolButton>
                      <ToolButton
                        onClick={this.handleShowPrintAllInvoicesModal}
                        bsStyle="primary"
                      >
                        Print All Invoices
                      </ToolButton>
                      <ToolButton
                        onClick={this.billAllHandle}
                        bsStyle="primary"
                      >
                        Bill All For Quarter
                      </ToolButton>
                    </div>
                  </Header>
                  <div style={{ width: "100%" }}>
                    <label className="mb-2">
                      Number of Quarterly Customers: {quarterlyCustomers.length}
                    </label>
                    <Table responsive hover bordered id="print">
                      <thead>
                        <tr>
                          <th>Account Number</th>
                          <th>Customer Name</th>
                          <th>Email Address</th>
                          <th>E-Invoice</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(quarterlyCustomers, (customer) => {
                          if (customer.uid !== undefined) {
                            return (
                              <tr
                                key={customer.uid}
                                onClick={() =>
                                  this.props.setCustomer(customer.uid)
                                }
                              >
                                <td>
                                  <div>{customer["Account Number"]}</div>
                                </td>
                                <td>
                                  <div>{customer.name}</div>
                                </td>
                                <td>
                                  <div>{customer.email}</div>
                                </td>
                                <td>
                                  <div>
                                    {customer["wantsEInvoice"] === undefined
                                      ? "No"
                                      : customer["wantsEInvoice"]}
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    {customer.status
                                      ? customer.status
                                      : "Not paid"}
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </Table>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>Monthly Customers</label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <label className="mb-2">
                    Number of Monthly Customers: {montlyCustomers.length}
                  </label>
                  <Table responsive hover bordered id="print">
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer Name</th>
                        <th>Email Address</th>
                        <th>E-Invoice</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(montlyCustomers, (customer) => {
                        if (customer.uid !== undefined) {
                          return (
                            <tr key={customer.uid}>
                              <td
                                onClick={() =>
                                  this.props.setCustomer(customer.uid)
                                }
                              >
                                <div>{customer["Account Number"]}</div>
                              </td>
                              <td
                                onClick={() =>
                                  this.props.setCustomer(customer.uid)
                                }
                              >
                                <div>{customer.name}</div>
                              </td>
                              <td
                                onClick={() =>
                                  this.props.setCustomer(customer.uid)
                                }
                              >
                                <div>{customer.email}</div>
                              </td>
                              <td
                                onClick={() =>
                                  this.props.setCustomer(customer.uid)
                                }
                              >
                                <div>
                                  {customer["wantsEInvoice"] === undefined
                                    ? "No"
                                    : customer["wantsEInvoice"]}
                                </div>
                              </td>
                              <td
                                onClick={() =>
                                  this.props.setCustomer(customer.uid)
                                }
                              >
                                <div>
                                  {customer.status ? customer.status : "Unpaid"}
                                </div>
                              </td>
                              <td>
                                <ActionButton
                                  onClick={() =>
                                    this.handleShowMonthlyEInvoiceModal(
                                      customer
                                    )
                                  }
                                  bsStyle="primary"
                                >
                                  Send E-Invoice
                                </ActionButton>
                                {/* <ActionButton onClick={() => this.printForMonth(customer)} bsStyle="primary"> */}
                                <ActionButton
                                  onClick={() =>
                                    this.handleShowMonthlyPrintModal(customer)
                                  }
                                  bsStyle="primary"
                                >
                                  Print
                                </ActionButton>
                                <ActionButton
                                  onClick={() => this.billForMonth(customer)}
                                  bsStyle="primary"
                                >
                                  Bill For Month
                                </ActionButton>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>Exempt Customers</label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <label className="mb-2">
                    Number of Exempt Customers: {exemptCustomers.length}
                  </label>
                  <Table responsive hover bordered id="print">
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer Name</th>
                        <th>Email Address</th>
                        <th>E-Invoice</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(exemptCustomers, (customer) => {
                        if (customer.uid !== undefined) {
                          return (
                            <tr
                              key={customer.uid}
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <td>
                                <div>{customer["Account Number"]}</div>
                              </td>
                              <td>
                                <div>{customer.name}</div>
                              </td>
                              <td>
                                <div>{customer.email}</div>
                              </td>
                              <td>
                                <div>
                                  {customer["wantsEInvoice"] === undefined
                                    ? "No"
                                    : customer["wantsEInvoice"]}
                                </div>
                              </td>
                              <td>
                                <div>
                                  {customer.status ? customer.status : "Unpaid"}
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          
          <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <label>Inactive Customers</label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <label className="mb-2">
                    Number of Exempt Customers: {InactiveCustomers.length}
                  </label>
                  <Table responsive hover bordered id="print">
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer Name</th>
                        <th>Email Address</th>
                        <th>E-Invoice</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(InactiveCustomers, (customer) => {
                        if (customer.uid !== undefined) {
                          return (
                            <tr
                              key={customer.uid}
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <td>
                                <div>{customer["Account Number"]}</div>
                              </td>
                              <td>
                                <div>{customer.name}</div>
                              </td>
                              <td>
                                <div>{customer.email}</div>
                              </td>
                              <td>
                                <div>
                                  {customer["wantsEInvoice"] === undefined
                                    ? "No"
                                    : customer["wantsEInvoice"]}
                                </div>
                              </td>
                              <td>
                                <div>
                                  {customer.status ? customer.status : "Unpaid"}
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>


        <AccordionContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>

                    <label>Recurring Customers</label>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <Header>
                    <div style={{ width: "100%" }}>
                     
                      <ToolButton
                        onClick={this.billAllHandle1}
                        bsStyle="primary"
                      >
                        Bill All For Quarter
                      </ToolButton>
                    </div>
                  </Header>
                  <label className="mb-2">
                    Number of Recurring Customers: {recurringCustomers.length}
                  </label>
                  <Table responsive hover bordered id="print">
                    <thead>
                      <tr>
                        <th>Account Number</th>
                        <th>Customer Name</th>
                        <th>Email Address</th>
                        <th>E-Invoice</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(recurringCustomers, (customer) => {
                        if (customer.uid !== undefined) {
                          return (
                            <tr
                              key={customer.uid}
                              onClick={() =>
                                this.props.setCustomer(customer.uid)
                              }
                            >
                              <td>
                                <div>{customer["Account Number"]}</div>
                              </td>
                              <td>
                                <div>{customer.name}</div>
                              </td>
                              <td>
                                <div>{customer.email}</div>
                              </td>
                              <td>
                                <div>
                                  {customer["wantsEInvoice"] === undefined
                                    ? "No"
                                    : customer["wantsEInvoice"]}
                                </div>
                              </td>
                              <td>
                                <div>
                                  {customer.status ? customer.status : "Unpaid"}
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
          <Table responsive hover bordered id="print">
            <thead>
              <tr>
                <th>Account Number</th>
                <th>Customer Name</th>
                <th>Service Address</th>
                <th width="200px">Phone Number(s)</th>
                <th>Pick Up Day</th>
                <th>Outstanding Balance</th>
                <th>Status</th>
                <GlyphTh className="hidden-print">Delete</GlyphTh>
              </tr>
            </thead>
            <tbody>
              {_.map(filteredCustomers, (customer) => {
                if (customer.uid !== undefined) {
                  return (
                    <tr key={customer.uid}>
                      <td onClick={() => this.props.setCustomer(customer.uid)}>
                        <div>{customer["Account Number"]}</div>
                      </td>
                      <td onClick={() => this.props.setCustomer(customer.uid)}>
                        <div>{customer.name}</div>
                      </td>
                      <td
                        className="dumpster-address"
                        onClick={() => this.props.setCustomer(customer.uid)}
                      >
                        <div>
                          {customer.service_address ? (
                            <div>
                              <div>{customer.service_address.street}</div>
                              <div>{`${customer.service_address.city}, ${customer.service_address.state} ${customer.service_address.zipcode}`}</div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                      <td
                        className="phone-number"
                        onClick={() => this.props.setCustomer(customer.uid)}
                      >
                        <div>
                          {_.map(
                            customer.phone_numbers,
                            (phone_number, index) => {
                              if (!phone_number.type && phone_number.number) {
                                return (
                                  <li key={index}>
                                    {`Phone: ${phone_number.number}`}
                                  </li>
                                );
                              } else if (phone_number.number === undefined) {
                                return <li key={index}>{`Phone: `}</li>;
                              } else if (
                                !phone_number.type &&
                                !phone_number.number
                              ) {
                                return (
                                  <li key={index}>
                                    {`Phone: ${customer.phone_numbers[index]}`}
                                  </li>
                                );
                              } else {
                                return (
                                  <li key={index}>
                                    {`${phone_number.type}: ${phone_number.number}`}
                                  </li>
                                );
                              }
                            }
                          )}
                        </div>
                      </td>
                      <td onClick={() => this.props.setCustomer(customer.uid)}>
                        <div>{customer.pickup_day && customer.pickup_day}</div>
                      </td>
                      <td>
                        { customer.amount_due < 0 &&
                          <div className={'success'}>(${parseFloat(customer.amount_due)?.toFixed(2)})</div>
                        }
                        { customer.amount_due > 0 &&
                          <div className={'error'}>${parseFloat(customer.amount_due)?.toFixed(2)}</div>
                        }
                        { customer.amount_due === '0' &&
                          <div>${parseFloat(customer.amount_due)?.toFixed(2)}</div>
                        }
                      </td>
                      <td onClick={() => this.props.setCustomer(customer.uid)}>
                        <div>
                          {customer.status ? customer.status : "Unpaid"}
                        </div>
                      </td>
                      <GlyphTd className="hidden-print">
                        <GlyphButton onClick={() => this.handleShow(customer)}>
                          <Glyphicon glyph="remove" />
                        </GlyphButton>
                      </GlyphTd>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <Modal
            bsSize="small"
            show={this.state.show}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <span>Delete </span>
                <span>
                  {this.state.customer ? this.state.customer.name : " "}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                Are you sure you want to delete this customer and all of their
                Stops?
              </div>
            </Modal.Body>
            <Modal.Footer>
              <CloseButton onClick={this.handleClose}>Close</CloseButton>
              <DeleteButton bsStyle="danger" onClick={this.handleRemove}>
                Delete
              </DeleteButton>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.spreadsheet_upload} onHide={this.handleSpreadsheetUploadClose}>
            <Modal.Body>
              <h3>Spreadsheet Upload</h3>
              <p>Please upload a spreadsheet containing the customer account numbers and new prices.</p>
              <br/>
              <div> 
                  <input type="file" onChange={this.onFileChange} accept=".xlsx"/> 
                  <br/>
              </div> 
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                disabled={!this.state.selectedFile}
                bsStyle="primary"
                onClick={this.onFileUpload}
                id="printBtn"
              >
                Update Customer Prices
              </ToolButton>
            </Modal.Footer>
          </Modal>


          {/* Bill For Month */}
          <Modal show={this.state.showMonthlyBillModal}>
            <Modal.Body>

                <h2>Bill for Month</h2>
              
              <div className="form-group" style={{marginTop:20}}>
                    <label>Select Month & Year For Records</label>
                    <br />
                    
                    <StyledSelect onChange={(month) => {this.setState({selected_month: month.target.value})}}>
                      <option value={false}>
                        Select Month
                      </option>

                      {monthOptions.map((monthOption) => (
                        <option
                          title="Print Monthly Invoice"
                          key={monthOption.value}
                          value={monthOption.value}
                        >
                          {monthOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                    <StyledSelect onChange={(year) => {this.setState({selected_year: year.target.value})}}>
                      <option value={false}>
                        Select Year
                      </option>

                      {yearOptions.map((yearOption) => (
                        <option
                          key={yearOption.value}
                          value={yearOption.value}
                        >
                          {yearOption.label}
                        </option>
                      ))}
                    </StyledSelect>
              </div>

              {/* <div className="form-group" style={{marginTop:20}}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker 
                  selected={this.state.dueDate}
                  onChange={(date) => {this.setState({dueDate: date})}}
                  required
                />
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.month_modal_quarter.length}
                bsStyle="primary"
                onClick={this.handleBillMonth}
                id="printBtn"
                okayLabel="Bill"
                duration="MONTH"
              >
                Bill
              </ToolButton>
            </Modal.Footer>
          </Modal>
          {/* <SelectModal
            show={this.state.showMonthlyBillModal}
            title="Bill for Month"
            options={monthOptions}
            okayLabel="Bill"
            onOkay={this.handleBillMonth}
          /> */}

          {/* Bill All For Quarter */}
          <Modal show={this.state.showBillAll}>
            <Modal.Body>

                <h2>Bill All for Quarter</h2>

              <div className="form-group" style={{marginTop:20}}>
                    <label>Select Quarter & Year For Records</label>
                    <br />
                    
                    <StyledSelect onChange={(month) => {this.setState({selected_month: month.target.value})}}>
                      <option value={false}>
                        Select Quarter
                      </option>

                      {quarterOptions.map((quarterOption) => (
                        <option
                          title="Bill All for Quarter"
                          key={quarterOption.value}
                          value={quarterOption.value}
                        >
                          {quarterOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                    <StyledSelect onChange={(year) => {this.setState({selected_year: year.target.value})}}>
                      <option value={false}>
                        Select Year
                      </option>

                      {yearOptions.map((yearOption) => (
                        <option
                          key={yearOption.value}
                          value={yearOption.value}
                        >
                          {yearOption.label}
                        </option>
                      ))}
                    </StyledSelect>
              </div>
              <div className="form-group" style={{marginTop:20}}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker 
                  selected={this.state.dueDate}
                  onChange={(date) => {this.setState({dueDate: date})}}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.month_modal_quarter.length}
                bsStyle="primary"
                onClick={this.handleBillFilter}
                id="printBtn"
                okayLabel="Bill"
                duration="QUARTER"
              >
                Bill
              </ToolButton>
            </Modal.Footer>
          </Modal>
          {/* <SelectModal
            show={this.state.showBillAll}
            title="Bill All for Quarter"
            options={quarterOptions}
            okayLabel="Bill"
            onOkay={this.handleBillFilter}
          /> */}

          {/* Bill All1 For Quarter */}
          <Modal show={this.state.showBillAll1}>
            <Modal.Body>

                <h2>Bill All for Quarter</h2>

                <div className="form-group" style={{marginTop:20}}>
                    <label>Select Quarter & Year For Records</label>
                    <br />
                    
                    <StyledSelect onChange={(month) => {this.setState({selected_month: month.target.value})}}>
                      <option value={false}>
                        Select Quarter
                      </option>

                      {quarterOptions.map((quarterOption) => (
                        <option
                          title="Bill All for Quarter"
                          key={quarterOption.value}
                          value={quarterOption.value}
                        >
                          {quarterOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                    <StyledSelect onChange={(year) => {this.setState({selected_year: year.target.value})}}>
                      <option value={false}>
                        Select Year
                      </option>

                      {yearOptions.map((yearOption) => (
                        <option
                          key={yearOption.value}
                          value={yearOption.value}
                        >
                          {yearOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                </div>
              
              <div className="form-group" style={{marginTop:20}}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker 
                  selected={this.state.dueDate}
                  onChange={(date) => {this.setState({dueDate: date})}}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.month_modal_quarter.length}
                bsStyle="primary"
                onClick={this.handleBillFilter1}
                id="printBtn"
                okayLabel="Bill"
                duration="QUARTER"
              >
                Bill
              </ToolButton>
            </Modal.Footer>
          </Modal>
            {/* <SelectModal
            show={this.state.showBillAll1}
            title="Bill All for Quarter"
            options={quarterOptions}
            okayLabel="Bill"
            onOkay={this.handleBillFilter1}
          /> */}

          {/* Print Monthly Invoice Modal */}
          <Modal show={this.state.showMonthlyPrintModal}>
            <Modal.Body>

                <h2>Print Monthly Invoice</h2>

              <div className="form-group" style={{marginTop:20}}>
                    <label>Select Month & Year For Records</label>
                    <br />
                    
                    <StyledSelect onChange={(month) => {this.setState({selected_month: month.target.value})}}>
                      <option value={false}>
                        Select Month
                      </option>

                      {monthOptions.map((monthOption) => (
                        <option
                          title="Print Monthly Invoice"
                          key={monthOption.value}
                          value={monthOption.value}
                        >
                          {monthOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                    <StyledSelect onChange={(year) => {this.setState({selected_year: year.target.value})}}>
                      <option value={false}>
                        Select Year
                      </option>

                      {yearOptions.map((yearOption) => (
                        <option
                          key={yearOption.value}
                          value={yearOption.value}
                        >
                          {yearOption.label}
                        </option>
                      ))}
                    </StyledSelect>
              </div>
              <div className="form-group" style={{marginTop:20}}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker 
                  selected={this.state.dueDate}
                  onChange={(date) => {this.setState({dueDate: date})}}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.month_modal_quarter.length}
                bsStyle="primary"
                onClick={this.handleMonthlyPrint}
                id="printBtn"
                okayLabel="Print"
                duration="MONTH"
              >
                Print
              </ToolButton>
            </Modal.Footer>
          </Modal>
          {/* <DueDateSelectModal
            show={this.state.showMonthlyPrintModal}
            title="Print Monthly Invoice"
            duration="MONTH"
            okayLabel="Print"
            onOkay={this.handleMonthlyPrint}
          /> */}

          {/* Print All Invoices Modal */}
          <Modal show={this.state.showPrintAllInvoicesModal}>
            <Modal.Body>

                <h2>Print All Invoices</h2>

              <div className="form-group" style={{marginTop:20}}>
                    <label>Select Quarter & Year For Records</label>
                    <br />
                    
                    <StyledSelect onChange={(month) => {this.setState({selected_month: month.target.value})}}>
                      <option value={false}>
                        Select Quarter
                      </option>

                      {quarterOptions.map((quarterOption) => (
                        <option
                          title="Bill All for Quarter"
                          key={quarterOption.value}
                          value={quarterOption.value}
                        >
                          {quarterOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                    <StyledSelect onChange={(year) => {this.setState({selected_year: year.target.value})}}>
                      <option value={false}>
                        Select Year
                      </option>

                      {yearOptions.map((yearOption) => (
                        <option
                          key={yearOption.value}
                          value={yearOption.value}
                        >
                          {yearOption.label}
                        </option>
                      ))}
                    </StyledSelect>
              </div>
              <div className="form-group" style={{marginTop:20}}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker 
                  selected={this.state.dueDate}
                  onChange={(date) => {this.setState({dueDate: date})}}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.month_modal_quarter.length}
                bsStyle="primary"
                onClick={this.handleQuarterlyPrint}
                id="printBtn"
                isResidentialCustomer={true}
                okayLabel="Print"
                duration="QUARTER"
              >
                Print
              </ToolButton>
            </Modal.Footer>
          </Modal>
          {/* <DueDateSelectModal
            show={this.state.showPrintAllInvoicesModal}
            title="Print All Invoices"
            isResidentialCustomer={true}
            duration="QUARTER"
            okayLabel="Print"
            onOkay={this.handleQuarterlyPrint}
          /> */}
          
          {/* Quarterly E-Invoices Modal */}
          <Modal show={this.state.showQuarterlyEInvoicesModal}>
            <Modal.Body>
              <div>
                <h2>Send E-Invoices</h2>
              </div>

              <div className="form-group" style={{marginTop:20}}>
                    <label>Select Quarter & Year For Records</label>
                    <br />
                    
                    <StyledSelect onChange={(month) => {this.setState({selected_month: month.target.value})}}>
                      <option value={false}>
                        Select Quarter
                      </option>

                      {quarterOptions.map((quarterOption) => (
                        <option
                          title="Bill All for Quarter"
                          key={quarterOption.value}
                          value={quarterOption.value}
                        >
                          {quarterOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                    <StyledSelect onChange={(year) => {this.setState({selected_year: year.target.value})}}>
                      <option value={false}>
                        Select Year
                      </option>

                      {yearOptions.map((yearOption) => (
                        <option
                          key={yearOption.value}
                          value={yearOption.value}
                        >
                          {yearOption.label}
                        </option>
                      ))}
                    </StyledSelect>
                </div>
              <div className="form-group" style={{marginTop:20}}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker 
                  selected={this.state.dueDate}
                  onChange={(date) => {this.setState({dueDate: date})}}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.month_modal_quarter.length}
                bsStyle="primary"
                onClick={this.eInvoicesForQuarterlyWithEInvoice}
                id="printBtn"
                okayLabel="Send"
                duration="QUARTER"
              >
                Print
              </ToolButton>
            </Modal.Footer>
          </Modal>
          {/* <DueDateSelectModal
            show={this.state.showQuarterlyEInvoicesModal}
            title="Send E-Invoices"
            // isResidentialCustomer={true}
            duration="QUARTER"
            okayLabel="Send"
            onOkay={this.eInvoicesForQuarterlyWithEInvoice}
          /> */}

          {/* Monthly E-Invoices Modal */}
          <Modal show={this.state.showMonthlyEInvoiceModal}>
            <Modal.Body>
              <div>
                <h2>Send E-Invoice</h2>
              </div>
              <div className="form-group" style={{marginTop:20}}>
                      <label>Select Month & Year For Records</label>
                      <br />
                      
                      <StyledSelect onChange={(month) => {this.setState({selected_month: month.target.value})}}>
                        <option value={false}>
                          Select Month
                        </option>

                        {monthOptions.map((monthOption) => (
                          <option
                            title="Print Monthly Invoice"
                            key={monthOption.value}
                            value={monthOption.value}
                          >
                            {monthOption.label}
                          </option>
                        ))}
                      </StyledSelect>
                      <StyledSelect onChange={(year) => {this.setState({selected_year: year.target.value})}}>
                        <option value={false}>
                          Select Year
                        </option>

                        {yearOptions.map((yearOption) => (
                          <option
                            key={yearOption.value}
                            value={yearOption.value}
                          >
                            {yearOption.label}
                          </option>
                        ))}
                      </StyledSelect>
                </div>
              <div className="form-group" style={{marginTop:20}}>
                <label>Which due date option would you like to use?</label>
                <br />
                <DatePicker 
                  selected={this.state.dueDate}
                  onChange={(date) => {this.setState({dueDate: date})}}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ToolButton
                // disabled={!this.state.month_modal_quarter.length}
                bsStyle="primary"
                onClick={this.eInvoiceForMonth}
                id="printBtn"
                okayLabel="Send"
                duration="MONTH"
              >
                Print
              </ToolButton>
            </Modal.Footer>
          </Modal>
          {/* <DueDateSelectModal
            show={this.state.showMonthlyEInvoiceModal}
            title="Send E-Invoice"
            duration="MONTH"
            okayLabel="Send"
            onOkay={this.eInvoiceForMonth}
          /> */}

          {/* Progressing Modal For Quarter*/}
          <Modal show={this.state.quarter_progressing_show}>
            <Modal.Body>
              <>
                <h3>Sending Email...</h3>
                <p>This may take some time.</p>
              </>
              <PrintProgress
                active
                striped
                now={this.state.quarter_progress}
                label={`${this.state.quarter_progress}%`}
              />
              {this.state.quarter_progress === 100 && (
                <div className="mt-2">
                  <label className="mb-2">
                    Number Of E-Invoices Customers:{" "}
                    {this.state.eInvoicesForQuarterlyTotal}
                  </label>
                  <Table responsive hover bordered id="print">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Account Number</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.eInvoicesForQuarterlyResults.map(
                        (item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.customer["Account Number"]}</td>
                              <td>
                                {item.success === 1 ? "Success" : "Failed"}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                  <CloseMoadlButtonContainer>
                    <CloseModalButton
                      onClick={this.closeQuarterlyEmailModal}
                      bsStyle="primary"
                    >
                      Close
                    </CloseModalButton>
                  </CloseMoadlButtonContainer>
                </div>
              )}
            </Modal.Body>
          </Modal>
          {/* Progressing Modal */}
          <Modal
            show={this.state.progressing_show}
            onHide={this.state.exporting ? () => {} : this.hidePrintModal}
          >
            <Modal.Body>
              <>
                <h3>Downloading...</h3>
                <p>This may take some time.</p>
              </>
              <PrintProgress
                active
                striped
                now={this.state.progress}
                label={`${this.state.progress}%`}
              />
            </Modal.Body>
          </Modal>
        </CustomersWrapper>
      );
    } else {
      return <DeniedWrapper> Access Denied </DeniedWrapper>;
    }
  }
}

const mapStateToProps = state => ({
  customers: state.newcastle.customers,
  authorized: state.user?.pages?.customers
});

const mapDispatchToProps = dispatch => ({
  setCustomer: uid => dispatch(NewCastleCustomers2Actions.setCustomer(uid)),
  addCustomer: () => dispatch(NewCastleCustomers2Actions.addCustomer()),
  removeCustomer: customer =>
    dispatch(NewCastleCustomers2Actions.removeCustomer(customer)),
    billAllNewCastleCustomers: (quarter,year) =>
    dispatch(NewCastleCustomers2Actions.billAllNewCastleCustomers(quarter,year)),
    billAllNewCastleCustomersRecuring: (quarter,year) =>
    dispatch(NewCastleCustomers2Actions.billAllNewCastleCustomersRecuring(quarter,year)),
  billMonthNewCastleCustomer: (customer, month,year) =>
    dispatch(NewCastleCustomers2Actions.billMonthNewCastleCustomer(customer, month,year)),
  getCustomers: () => dispatch(NewCastleCustomers2Actions.getCustomers()),
  updateCustomerPrices: (file) => dispatch(CustomersActions.updateCustomerPrices(file, "NEWCASTLE"))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCastle2Customers);
