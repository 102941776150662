/* @format - for Prettier */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { auth } from "../../firebase";
import { setUser } from "../../actions/Users";
import './index.css'
import axiosInstance from "../../api/axios";
import LocalStorage from "../../services/LocalStorage";

class Unauthorized extends React.Component {
  handleLogout = () => {
    axiosInstance.post('api/auth/logout').then(() => {
      this.props.clearUser()
      LocalStorage.storeData('eiken_user', null);
      window.dispatchEvent(new Event('storage'));
      this.props.history.push('/Login')
    });
  };

  componentDidMount() {
    // localStorage.setItem('remember_newCastle_search_key', '')
    // localStorage.setItem('remember_customer_search_key', '')
    // localStorage.setItem('remember_residential_search_key', '')
  }

  render() {
    return (
      <div className="unauthorize-container d-flex align-items-center justify-content-center flex-direction-column">
        <p className="unautorized_description">You are not authorized to view this page. Contact an administrator, or log in as someone else.</p>
        <Button className="unauthorize_logout_button" onClick={this.handleLogout}>Log Out</Button>
      </div>
      // <Navbar>
      //   <Nav pullRight>
      //     <NavItem onClick={this.handleLogout}>Logout</NavItem>
      //   </Nav>
      // </Navbar>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  clearUser: () => dispatch(setUser({
    email: null,
    first_name: null,
    last_name: null,
    pages: {admin: false, customers: false, history: false, invoices: false, routes: false},
    uid: null
  }))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Unauthorized));
